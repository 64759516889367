import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";

import { generateTestID } from "../../modules/platformUtils";
import { LoyaltyBonusType } from "../../services/api/order/model";
import GYGDollarsIcon from "../Gomex/GYGDollarsIcon";
import GYGPointsIcon from "../Gomex/GYGPointsIcon";
import { styles } from "./LoyaltyBonusCard.style";

interface BonusSectionProps {
  type: LoyaltyBonusType;
  value: number;
}
const BonusSection: React.FC<BonusSectionProps> = (props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.bonusItemWrapper}>
      {props.type === LoyaltyBonusType.GYGPoints ? (
        <GYGPointsIcon scale={2} style={styles.bonusIcon} />
      ) : (
        <GYGDollarsIcon scale={2} style={styles.bonusIcon} />
      )}
      <View style={styles.bonusValueContainer}>
        <Text
          adjustsFontSizeToFit
          numberOfLines={1}
          {...generateTestID(Platform.OS, "LoyaltyBonusCardValue")}
          style={styles.bonusValue}>
          {props.value}
        </Text>
      </View>
      <View style={styles.bonusTitleContainer}>
        <Text style={styles.bonusTitle}>{t("Loyalty:bonus")}</Text>
        <Text
          {...generateTestID(Platform.OS, "LoyaltyBonusCardBonusTitle")}
          style={styles.bonusTitleValue}>
          {props.type === LoyaltyBonusType.GYGPoints
            ? t("Loyalty:gygPoints")
            : t("Loyalty:gygDollars")}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(BonusSection);
