import branch from "branch-sdk";
import sign from "jwt-encode";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";
import { v4 as uuidv4 } from "uuid";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  BranchConst,
  CommonUtils,
  InputType,
  OrderModules,
  RegistrationAction,
  RegistrationApiModels,
  RegistrationDetailsAction,
  RegistrationModels,
  RegistrationUtils,
  RootState,
  StringBuilders,
  StringValidators,
  UserReduxAction,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { WebModalSize } from "gyg_common/components/modals/MiddleModal";
import ModalWithInput from "gyg_common/components/modals/ModalWithInput";
import { Screens } from "navigation/const";
import { Registration } from "views/components/Registration/index";

import { getDeviceId } from "@/RouterObserver/WebAuthObsever";

// interface RegistrationRouterState {
//   registrationNavigatesTo?: Screens;
// }

const RegistrationContainer: React.FC = () => {
  // hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  // redux state
  const currentSocialUser = useSelector(
    (s: RootState) => s.social.currentSocialUser
  );
  const {
    firstName,
    lastName,
    emailAddress,
    password,
    confirmPassword,
    mobileNumber,
    birthday,
    isAccepted,
    isSubscribe,
  } = useSelector((s: RootState) => s.registrationDetails);
  const {
    isVerified,
    isOTPError,
    verifyCodeErrorResponse,
    getVerificationCodeSuccess,
    registerErrorResponse,
    isMobileEmailUpdate,
    loading,
  } = useSelector((s: RootState) => s.registration);
  const { mobileAppConfig } = useSelector((s: RootState) => s.dashboard);
  const { guestOrderId, addToAccountLoading } = useSelector(
    (s: RootState) => s.order
  );
  const { orders } = useSelector((s: RootState) => s.guest);
  const { isLoading, currentUser, isAuthSuccess } = useSelector(
    (s: RootState) => s.login
  );
  const { isBrazeInitialised, existingUserMobileVerify } = useSelector(
    (s: RootState) => s.user
  );

  // local state
  const [isFirstNameError, setFirstNameError] = useState<boolean>(false);
  const [isLastNameError, setLastNameError] = useState<boolean>(false);
  const [isPhoneError, setPhoneError] = useState<boolean>(false);
  const [isBirthdayError, setBirthdayError] = useState<boolean>(false);
  const [isPasswordError, setPasswordError] = useState<boolean>(false);
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [isPasswordDiff, setPasswordDiff] = useState<boolean>(false);
  const [acceptTerms, setAccetTerms] = useState<boolean>(isAccepted);
  const [isSubbed, setSubscribe] = useState<boolean>(isSubscribe);
  const [isTermsError, setTermsError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [proceedWithVerification, setProceedWithVerification] =
    useState<boolean>(false);
  const [deviceId] = useState(getDeviceId() || uuidv4());

  // variables
  const goToTermsAndConditions =
    mobileAppConfig?.termsAndConditions || config.termsAndConditionsUrl;
  const goToPrivacyPolicyUrl =
    mobileAppConfig?.privacyPolicy || config.privacyPolicyUrl;

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "registration",
        page_type: "registration_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    const componentWillUnmount = () => {
      dispatch(RegistrationAction.clearRegistration());
    };
    return componentWillUnmount;
  }, [dispatch]);

  useEffect(() => {
    if (showModal) {
      AnalyticsInstance.trackView({
        page_name: "validate_mobile",
        page_type: AnalyticsConst.Events.ValidateMobile,
      });
    }
  }, [showModal]);

  useEffect(() => {
    const tmpPwd = CommonUtils.generateTmpPwd();
    if (currentSocialUser) {
      const payload: RegistrationModels.RegistrationStructure = {
        firstName: currentSocialUser.firstName,
        lastName: currentSocialUser.lastName,
        emailAddress: currentSocialUser.email,
        mobileNumber: "",
        password: tmpPwd,
        confirmPassword: tmpPwd,
        birthday: "",
        isAccepted: false,
        isSubscribe: false,
      };
      dispatch(RegistrationDetailsAction.updateUserDetails({ ...payload }));
    }
  }, [currentSocialUser, dispatch]);

  useEffect(() => {
    if (isVerified) {
      setShowModal(false);
      setProceedWithVerification(false);
    }
  }, [isVerified]);

  const onTextChange = (text: string, type: string) => {
    const payload: RegistrationModels.RegistrationStructure = {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      mobileNumber: mobileNumber,
      password: password,
      confirmPassword: confirmPassword,
      birthday: birthday,
      isAccepted: acceptTerms,
      isSubscribe: isSubbed,
    };

    switch (type) {
      case InputType.FIRST_NAME:
        payload.firstName = text;
        setFirstNameError(false);
        break;

      case InputType.LAST_NAME:
        payload.lastName = text;
        setLastNameError(false);
        break;

      case InputType.PHONE:
        payload.mobileNumber = text;
        setPhoneError(false);
        dispatch(UserReduxAction.setExistingUserMobileVerify(false));
        dispatch(RegistrationAction.resetMobileVerification());
        break;

      case InputType.BIRTHDAY:
        payload.birthday = StringBuilders.birthdayBuilder(
          birthday,
          text,
          config.version
        );
        setBirthdayError(false);
        break;

      case InputType.PASSWORD:
        payload.password = text;
        setPasswordError(false);
        break;

      case InputType.CONFIRM_PASSWORD:
        payload.confirmPassword = text;
        setPasswordDiff(false);
        break;

      case InputType.EMAIL:
        payload.emailAddress = text;
        setEmailError(false);
        if (!!registerErrorResponse) {
          dispatch(RegistrationAction.resetMobileVerification());
        }
        break;

      default:
        break;
    }
    dispatch(RegistrationDetailsAction.updateUserDetails({ ...payload }));
  };

  /**
   * Updates error states if any fields are invalid
   * @returns boolean, true if all fields are valid
   */
  const checkValidField = (isVerifyField: boolean): boolean => {
    const isPasswordValid = StringValidators.isPasswordValid(password);
    setFirstNameError(StringValidators.validateName(firstName));
    setLastNameError(StringValidators.validateName(lastName));
    setPhoneError(!StringValidators.validatePhone(mobileNumber)?.isValid);
    setPasswordError(!isPasswordValid);
    setEmailError(StringValidators.validateEmail(emailAddress));
    if (birthday !== "")
      setBirthdayError(StringValidators.validateBirthday(birthday));

    setPasswordDiff(confirmPassword !== password);

    if (isVerifyField) setTermsError(!acceptTerms);

    if (
      firstName === "" ||
      lastName === "" ||
      StringValidators.validateName(firstName) ||
      StringValidators.validateName(lastName) ||
      !StringValidators.validatePhone(mobileNumber)?.isValid ||
      !isPasswordValid ||
      StringValidators.validateEmail(emailAddress) ||
      confirmPassword !== password ||
      (isVerifyField && !acceptTerms) ||
      (birthday !== "" && StringValidators.validateBirthday(birthday))
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * Check whether all fields are filled
   * @returns false if fields are not fileed
   */
  const disableButton = (): boolean => {
    const basicCheck: boolean = RegistrationUtils.registrationBasicCheck(
      firstName,
      lastName,
      emailAddress,
      password,
      confirmPassword,
      mobileNumber
    );

    if (isVerified ? basicCheck && acceptTerms : basicCheck) return false;
    else return true;
  };

  const requestVerificationCode = () => {
    const payload: RegistrationApiModels.VerificationCodePayload = {
      mobile: RegistrationUtils.getMobileNumberWithDialCode(
        mobileNumber,
        config.version
      ),
      channel: RegistrationApiModels.VerificationCodeChannel.MOBILE,
    };

    dispatch(RegistrationAction.getVerificationCode(payload));
  };

  const onModalClose = () => {
    setShowModal(false);
    setVerificationCode("");
    setProceedWithVerification(false);
    dispatch(RegistrationAction.resetMobileVerification());
  };

  const onNextPress = () => {
    dispatch(RegistrationAction.resetMobileVerification());
    if (checkValidField(isVerified)) {
      requestVerificationCode();
      AnalyticsInstance.trackView({
        page_name: "validate_mobile",
        page_type: "validate_mobile_view",
      });
    }
  };

  const onSubmitPress = () => {
    if (verificationCode !== "") {
      const payload: RegistrationApiModels.VerifyCodePayload = {
        code: parseInt(verificationCode),
        mobileNumber: RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        ),
        isLoggedIn: false,
      };
      dispatch(RegistrationAction.verifyCode(payload));
    }
  };

  const onRegisterPress = () => {
    if (currentSocialUser) {
      const payload: RegistrationApiModels.SocialRegistrationPayload = {
        verificationId: currentSocialUser.verification.identifier,
        mobileNumber: RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        ),
        firstName: firstName,
        lastName: lastName,
        code: verificationCode,
        deviceId: deviceId,
        signupProgressToken: currentSocialUser.signupProgressToken,
      };
      dispatch(RegistrationAction.registerSocialUser(payload));
    } else {
      if (checkValidField(isVerified)) {
        const payload: RegistrationApiModels.RegistrationPayload = {
          emailAddress: emailAddress,
          mobileNumber: RegistrationUtils.getMobileNumberWithDialCode(
            mobileNumber,
            config.version
          ),
          firstName: firstName,
          lastName: lastName,
          password: password,
          code: parseInt(verificationCode),
          receiveEmails: isSubbed,
          dateOfBirth: birthday,
          deviceId: deviceId,
        };
        dispatch(RegistrationAction.registerUser(payload));
      }
    }
  };

  const addOrdersToAccount = useCallback(() => {
    if (!addToAccountLoading && orders.length) {
      OrderModules.OrderUtils.addOrdersToAccount(store, orders);
    }
  }, [orders, addToAccountLoading]);

  const navigateAwayFromRegister = useCallback(() => {
    if (state?.registrationNavigatesTo) {
      navigate(state.registrationNavigatesTo, {
        state: {
          previousScreen: pathname,
        },
      });
    } else {
      navigate(Screens.Dashboard);
    }
  }, [navigate, pathname, state.registrationNavigatesTo]);

  /**
   * Shows verify mobile modal only when API responded.
   */
  useEffect(() => {
    if (!verificationCode && loading && showModal) {
      setProceedWithVerification(false);
    } else {
      setProceedWithVerification(true);
    }
  }, [verificationCode, loading, showModal]);

  /**
   * If login successful, assigns guest orders to account and navigates away.
   */
  useEffect(() => {
    if (isAuthSuccess) {
      if (currentUser) {
        localStorage.removeItem("CurrentUser");
        const jwt = sign(currentUser, config.jwtSecret);
        localStorage.setItem("CurrentUser", jwt);

        branch.logEvent(BranchConst.BranchEvents.CompleteRegistration, {
          branchId: currentUser?.branchId ?? "",
        });

        if (guestOrderId && orders.length > 0) {
          addOrdersToAccount();
        }
      }
      if (existingUserMobileVerify) {
        dispatch(
          UserReduxAction.setMessageToast({
            title: t("UserManagement:existingVerifySuccessToastMessage"),
            showCloseButton: true,
            iconType: "check",
          })
        );
        dispatch(UserReduxAction.setExistingUserMobileVerify(false));
      }

      navigateAwayFromRegister();
    }
  }, [
    currentUser,
    isAuthSuccess,
    guestOrderId,
    orders.length,
    existingUserMobileVerify,
    navigateAwayFromRegister,
    addOrdersToAccount,
    dispatch,
    t,
  ]);

  useEffect(() => {
    if (
      RegistrationUtils.isInvalidMobileNumberException(verifyCodeErrorResponse)
    ) {
      setPhoneError(true);
    } else if (getVerificationCodeSuccess) {
      setShowModal(true);
      dispatch(RegistrationAction.resetMobileVerification());
    }
  }, [verifyCodeErrorResponse, getVerificationCodeSuccess, dispatch]);

  const onTermsPress = () => {
    setAccetTerms(!acceptTerms);
    setTermsError(false);
    dispatch(RegistrationDetailsAction.onToggleTerms(!acceptTerms));
  };

  const onSubsPress = () => {
    setSubscribe(!isSubbed);
    dispatch(RegistrationDetailsAction.onToggleSubscribe(!isSubbed));
  };

  return (
    <>
      <LoadingScreen loading={loading || isLoading} />
      <ModalWithInput
        webModalSize={WebModalSize.SMALL}
        isVisible={proceedWithVerification && showModal}
        testId='ModalWithInput'
        isInputError={isOTPError || !!verifyCodeErrorResponse}
        title={t("LoginRegistration:verifyMobileTitle")}
        inputLabel={t("LoginRegistration:verifyCodeLabel")}
        inputPlaceholder={t("LoginRegistration:enterCodeLabel")}
        inputErrorMessage={
          verifyCodeErrorResponse?.message ??
          verifyCodeErrorResponse?.heading ??
          ""
        }
        inputValue={verificationCode}
        verifyMobile
        messageBold={`${
          isMobileEmailUpdate
            ? t("LoginRegistration:changedInfoLabel")
            : t("LoginRegistration:verifyMobileNote")
        }\n${RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        )}`}
        message={t("LoginRegistration:verifyMobileLabel")}
        primaryButtonDisable={verificationCode.length === 0}
        primaryButton={{
          name: t("LoginRegistration:loginButton"),
          action: onSubmitPress,
        }}
        onTextChange={(text) => {
          setVerificationCode(text);
          dispatch(RegistrationAction.resetMobileVerification());
        }}
        onRequestCode={requestVerificationCode}
        onModalClose={onModalClose}
      />

      <Registration
        registerErrorResponse={RegistrationUtils.getRegistrationError(
          showModal,
          isVerified,
          verifyCodeErrorResponse,
          registerErrorResponse
        )}
        isSocialUser={currentSocialUser ? true : false}
        isMobileVerified={isVerified}
        email={{ text: emailAddress, error: isEmailError }}
        password={{ text: password, error: isPasswordError }}
        confirmPassword={{ text: confirmPassword, error: isPasswordDiff }}
        firstName={{ text: firstName, error: isFirstNameError }}
        lastName={{ text: lastName, error: isLastNameError }}
        phone={{ text: mobileNumber, error: isPhoneError }}
        birthday={{ text: birthday, error: isBirthdayError }}
        isSubsribed={isSubbed}
        acceptTerms={acceptTerms}
        isTermsError={isTermsError}
        linkPrivacyPolicy={goToPrivacyPolicyUrl}
        linkTermsAndConditions={goToTermsAndConditions}
        isEightChars={StringValidators.isEightChars(password)}
        hasLowerCase={StringValidators.hasLowerCase(password)}
        hasUpperCase={StringValidators.hasUpperCase(password)}
        hasNumber={StringValidators.hasNumber(password)}
        disableButton={disableButton()}
        onTermsPress={onTermsPress}
        onSubsPress={onSubsPress}
        onTextChange={onTextChange}
        onButtonPress={isVerified ? onRegisterPress : onNextPress}
        existingUserMobileVerify={existingUserMobileVerify}
      />
    </>
  );
};

export default RegistrationContainer;
