import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  NotificationsCenterReduxAction,
  NotificationsCenterReduxModels,
  RootState,
  UserReduxAction,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { Notifications } from "views/components/Notifications/Notifications/Notifications";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export const NotificationsContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, subscriptionGroups, isUpdateSuccess, errorResponse } =
    useSelector((s: RootState) => s.notificationsCenter);

  const [receiveEmails, setReceiveEmails] = useState(false);
  const [receivePushNotifications, setReceivePushNotifications] =
    useState(false);
  const [receiveSMS, setReceiveSMS] = useState(false);
  const [didNotificationsSettingsChanged, setDidNotificationsSettingsChanged] =
    useState(false);

  // Retrieve notification center settings
  useEffect(() => {
    dispatch(NotificationsCenterReduxAction.reset());
    dispatch(NotificationsCenterReduxAction.getNotificationsCenter());
    AnalyticsInstance.trackView({
      page_name: "notifications",
      page_type: "notifications_view",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Toast for success
  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        UserReduxAction.setMessageToast({
          iconType: "check",
          title: t("Notifications:changesUpdated"),
          showCloseButton: true,
        })
      );
      setDidNotificationsSettingsChanged(false);
      dispatch(NotificationsCenterReduxAction.getNotificationsCenter());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  // Toast for error
  useEffect(() => {
    if (errorResponse) {
      dispatch(
        UserReduxAction.setMessageToast({
          iconType: "alert",
          title: errorResponse?.message ?? t("Error:defaultErrorHeading"),
          showCloseButton: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorResponse]);

  //Helper functions
  const currentSMSSubscription = ():
    | NotificationsCenterReduxModels.BrazeSubscriptionGroup
    | undefined => {
    return subscriptionGroups.find(
      (group) =>
        group.type ===
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType.SMS
    );
  };

  const currentEmailSubscription = ():
    | NotificationsCenterReduxModels.BrazeSubscriptionGroup
    | undefined => {
    return subscriptionGroups.find(
      (group) =>
        group.type ===
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType.EMAIL
    );
  };

  const currentPushNotificationsSubscription = ():
    | NotificationsCenterReduxModels.BrazeSubscriptionGroup
    | undefined => {
    return subscriptionGroups.find(
      (group) =>
        group.type ===
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType
          .PUSH_NOTIFICATIONS
    );
  };

  const updateNotificationsSettings = (
    updatedSubscriptionsGroups: NotificationsCenterReduxModels.BrazeSubscriptionGroup[]
  ) => {
    if (updatedSubscriptionsGroups.length > 0) {
      const notificationsAnalytics: Record<string, boolean> = {};

      notificationsAnalytics[
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType.EMAIL
      ] = receiveEmails;
      notificationsAnalytics[
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType.SMS
      ] = receiveSMS;
      notificationsAnalytics[
        NotificationsCenterReduxModels.BrazeSubscriptionGroupType.PUSH_NOTIFICATIONS
      ] = receivePushNotifications;

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.UpdateNotifications,
        notificationsAnalytics
      );

      dispatch(
        NotificationsCenterReduxAction.updateNotificationsCenter(
          updatedSubscriptionsGroups
        )
      );
    }
  };

  // Only save changed settings.
  // If push notifications control status is changed to true, permissions has to be checked
  const onSaveButtonPressed = () => {
    const currentSMSSubscriptionStatus = currentSMSSubscription()?.status;
    const currentEmailSubscriptionStatus = currentEmailSubscription()?.status;
    const currentPushNotificationsSubscriptionStatus =
      currentPushNotificationsSubscription()?.status;

    const updatedSubscriptionsGroups: NotificationsCenterReduxModels.BrazeSubscriptionGroup[] =
      [];
    const smsSubscription = {
      type: NotificationsCenterReduxModels.BrazeSubscriptionGroupType.SMS,
      status: receiveSMS
        ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
        : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
            .UNSUBSCRIBED,
    };
    if (smsSubscription.status !== currentSMSSubscriptionStatus) {
      updatedSubscriptionsGroups.push(smsSubscription);
    }

    const emailSubscription = {
      type: NotificationsCenterReduxModels.BrazeSubscriptionGroupType.EMAIL,
      status: receiveEmails
        ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
        : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
            .UNSUBSCRIBED,
    };
    if (emailSubscription.status !== currentEmailSubscriptionStatus) {
      updatedSubscriptionsGroups.push(emailSubscription);
    }

    const pushNotificationsSubscription = {
      type: NotificationsCenterReduxModels.BrazeSubscriptionGroupType
        .PUSH_NOTIFICATIONS,
      status: receivePushNotifications
        ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
        : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
            .UNSUBSCRIBED,
    };

    if (
      pushNotificationsSubscription.status !==
      currentPushNotificationsSubscriptionStatus
    ) {
      updatedSubscriptionsGroups.push(pushNotificationsSubscription);
    }

    updateNotificationsSettings(updatedSubscriptionsGroups);
  };

  // Populate controls
  useEffect(() => {
    const smsSubscription = currentSMSSubscription();
    const emailSubscription = currentEmailSubscription();
    const pushNotificationsSubscription =
      currentPushNotificationsSubscription();

    if (emailSubscription) {
      setReceiveEmails(
        emailSubscription.status ===
          NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      );
    }

    if (smsSubscription) {
      setReceiveSMS(
        smsSubscription.status ===
          NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      );
    }
    if (pushNotificationsSubscription) {
      setReceivePushNotifications(
        pushNotificationsSubscription.status ===
          NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionGroups]);

  // Update Save Button Enabled Status if settings were changed
  useEffect(() => {
    const currentSMSSubscriptionStatus = currentSMSSubscription()?.status;
    const currentEmailSubscriptionStatus = currentEmailSubscription()?.status;
    const currentPushNotificationsSubscriptionStatus =
      currentPushNotificationsSubscription()?.status;

    const smsSubscriptionStatus = receiveSMS
      ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
          .UNSUBSCRIBED;

    const emailSubscriptionStatus = receiveEmails
      ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
          .UNSUBSCRIBED;

    const pushNotificationsSubscriptionStatus = receivePushNotifications
      ? NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus.SUBSCRIBED
      : NotificationsCenterReduxModels.BrazeSubscriptionGroupStatus
          .UNSUBSCRIBED;

    setDidNotificationsSettingsChanged(
      smsSubscriptionStatus !== currentSMSSubscriptionStatus ||
        emailSubscriptionStatus !== currentEmailSubscriptionStatus ||
        pushNotificationsSubscriptionStatus !==
          currentPushNotificationsSubscriptionStatus
    );
    dispatch(UserReduxAction.setMessageToast(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveEmails, receiveSMS, receivePushNotifications]);

  return (
    <>
      <HeaderContainer pageTitle={t("Dashboard:dropdownNotifications")} />
      <LoadingScreen loading={isLoading} />
      <Notifications
        onSaveButtonPressed={onSaveButtonPressed}
        isSaveButtonEnabled={didNotificationsSettingsChanged}
        receiveEmails={receiveEmails}
        setReceiveEmails={setReceiveEmails}
        setReceivePushNotifications={setReceivePushNotifications}
        receivePushNotifications={receivePushNotifications}
        setReceiveSMS={setReceiveSMS}
        receiveSMS={receiveSMS}
      />
    </>
  );
};

export default NotificationsContainer;
