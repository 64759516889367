import * as braze from "@braze/web-sdk";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { PromoReduxAction, PromotionCardModels } from "gyg_common";
import { removeDuplicateByKey } from "gyg_common/utils/array";

export const mapContentCardToPromotionCardData = (
  contentCard: braze.Card
): PromotionCardModels.PromotionCardDataProps => {
  let url: string | undefined = undefined;
  let imageUrl: string | undefined = undefined;

  if (contentCard instanceof braze.CaptionedImage) {
    url = (contentCard as braze.CaptionedImage).url;
    imageUrl = (contentCard as braze.CaptionedImage).imageUrl;
  } else if (contentCard instanceof braze.ClassicCard) {
    url = (contentCard as braze.ClassicCard).url;
    imageUrl = (contentCard as braze.ClassicCard).imageUrl;
  } else if (contentCard instanceof braze.ImageOnly) {
    url = (contentCard as braze.ImageOnly).url;
    imageUrl = (contentCard as braze.ImageOnly).imageUrl;
  }

  const promotionCardData = {
    id: contentCard.id,
    url: url,
    imageUrl: imageUrl,
    imageWideUrl: contentCard.extras?.imageWideUrl ?? "",
  } as PromotionCardModels.PromotionCardDataProps;

  return {
    ...promotionCardData,
    ...{ storeOfferId: contentCard.extras?.store_offer_id },
  };
};

export const useBrazeContentCards = (isBrazeInitialised: boolean) => {
  const [promotions, setPromotions] = useState<braze.Card[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBrazeInitialised) {
      braze.requestContentCardsRefresh();
      braze.subscribeToContentCardsUpdates(
        (cardsUpdates: braze.ContentCards) => {
          if (cardsUpdates.cards?.length >= 0) {
            const promotionCards: braze.Card[] = cardsUpdates.cards.filter(
              (card) => card.extras?.feed_type === "promotion"
            );
            setPromotions(promotionCards);
            dispatch(
              PromoReduxAction.setPromos({
                promos: removeDuplicateByKey(
                  promotionCards.map(mapContentCardToPromotionCardData),
                  "imageUrl"
                ),
                clearCurrentPromos: true,
              })
            );
          }
        }
      );
    }
  }, [isBrazeInitialised, dispatch]);

  return promotions;
};
