import React from "react";
import { useTranslation } from "react-i18next";
import {
  Animated,
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import {
  CartReduxModels,
  MenuModels,
  PlatformUtils,
  ProductConst,
} from "../../..";
import backIcon from "../../../components/assets/icons/icon_chevron_left.png";
import closeIcon from "../../../components/assets/icons/icon_close.png";
import IconButton from "../../../components/IconButton";
import { SaveAsFavouriteInputAndModal } from "../../modals/SaveAsFavouriteInputAndModal";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export interface ProductDetailStickyProps {
  name: string;
  price: number;
  customizationText: string;
  extraFillingOptions?: string;
  extraOptions?: string;
  removeOptions?: string;
  mealOptions?: string;
  onGoBack: () => void;
  gobackMode?: boolean;
  hidePrice?: boolean;
  fadeAnimationSticky?: Animated.Value;
  stickyHeaderVisible?: boolean | undefined;
  cartItems?: CartReduxModels.CartItem[];
  category?: MenuModels.Category;
  editMode?: ProductConst.EditMode;
}

export const headerStyle = (visible: boolean) =>
  StyleSheet.create({
    container: {
      zIndex: visible ? 1 : -1,
    },
  });

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    width: Platform.OS == "web" ? "100%" : Dimensions.get("screen").width,
  },
  gap: {
    marginTop: Spacing.ExtraThin,
  },
  textView: {
    marginTop: Spacing.Thin,
  },
  title: {
    width: 250,
  },
  optionText: {
    width: 75,
  },
  optionCustomizeText: {
    maxWidth: Dimensions.get("screen").width - 100,
  },
  stickerBackButton: {
    marginRight: Spacing.Regular,
    zIndex: 3,
  },
  column: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
    marginTop: Spacing.ExtraThin,
  },
  sticker: {
    backgroundColor: colours.white,
    zIndex: 2,
    marginBottom: Spacing.Light,
    paddingHorizontal: Spacing.Light,
    paddingTop: Platform.OS == "web" ? Spacing.Light : Spacing.Thick,
    shadowColor: colours.black,
    shadowOffset: {
      width: 0,
      height: Platform.OS == "web" ? 1 : Spacing.ExtraThin,
    },
    shadowOpacity: 0.1,
    elevation: 8,
  },
  stickerCustomizationText: {
    paddingTop: Spacing.Light,
    marginBottom: Spacing.Light,
  },
});

const ProductDetailSticky: React.FC<ProductDetailStickyProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Animated.View
      style={[
        styles.container,
        { opacity: props.fadeAnimationSticky ?? 1 },
        headerStyle(props.stickyHeaderVisible ?? true).container,
      ]}>
      <View
        {...PlatformUtils.generateTestID(
          Platform.OS,
          "CustomizableProductStickyHeader"
        )}
        style={styles.sticker}>
        <View style={styles.row}>
          <View style={styles.stickerBackButton}>
            <IconButton
              withShadow={true}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "CustomizableProductGoBackButton"
              )}
              onPress={props.onGoBack}
              iconImage={props.gobackMode ? backIcon : closeIcon}
            />
          </View>
          <View style={styles.column}>
            <View style={styles.title}>
              <Text style={Typography.smallProductTitle}>{props.name}</Text>
            </View>
            {!props.hidePrice && (
              <View style={styles.textView}>
                <Text style={Typography.captionOne}>
                  From ${props.price.toFixed(2)}
                </Text>
              </View>
            )}
          </View>
        </View>

        <View style={styles.stickerCustomizationText}>
          {props.customizationText?.length > 0 && (
            <>
              <View>
                <Text style={Typography.bodyTwo}>
                  {props.customizationText}
                </Text>
              </View>
              <View style={styles.gap} />
            </>
          )}

          {props.extraOptions && (
            <View style={styles.row}>
              <View style={styles.optionText}>
                <Text style={Typography.bodyTwoBold}>
                  {t("OrderManagement:customizationDescAdd")}
                </Text>
              </View>
              <View style={styles.optionCustomizeText}>
                <Text style={Typography.bodyTwo}>{props.extraOptions}</Text>
              </View>
            </View>
          )}

          {props.extraFillingOptions && (
            <View style={styles.row}>
              <View style={styles.optionText}>
                <Text style={Typography.bodyTwoBold}>
                  {t("OrderManagement:customizationDescExtras")}
                </Text>
              </View>
              <View style={styles.optionCustomizeText}>
                <Text style={Typography.bodyTwo}>
                  {props.extraFillingOptions}
                </Text>
              </View>
            </View>
          )}

          {props.removeOptions && (
            <View style={styles.row}>
              <View style={styles.optionText}>
                <Text style={Typography.bodyTwoBold}>
                  {t("OrderManagement:customizationDescRemove")}
                </Text>
              </View>
              <View style={styles.optionCustomizeText}>
                <Text style={Typography.bodyTwo}>{props.removeOptions}</Text>
              </View>
            </View>
          )}

          {props.mealOptions && (
            <View style={styles.row}>
              <View style={styles.optionText}>
                <Text style={Typography.bodyTwoBold}>
                  {t("OrderManagement:customizationDescMeal")}
                </Text>
              </View>
              <View style={styles.optionCustomizeText}>
                <Text style={Typography.bodyTwo}>{props.mealOptions}</Text>
              </View>
            </View>
          )}

          {(props.category || props.name) &&
            (props.editMode !== ProductConst.EditMode.UPDATE_TACO_ITEM ||
              !props.editMode) && (
              <SaveAsFavouriteInputAndModal
                cartItems={props.cartItems}
                categoryName={props.category ? props.category.name : props.name}
              />
            )}
        </View>
      </View>
    </Animated.View>
  );
};

export default ProductDetailSticky;
