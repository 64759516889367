import { StyleSheet, Dimensions } from "react-native";

import colours from "../../styles/colours";
import { Spacing, BorderRadius } from "../../styles/number";

export const styles = StyleSheet.create({
  applyAll: {
    marginHorizontal: Spacing.Thin,
    marginBottom: Spacing.Thin,
    borderTopColor: colours.lightGrey,
    borderTopWidth: 1,
    flex: 1,
    paddingTop: Spacing.Thin,
  },
  icon: {
    width: 110,
    height: 88,
    resizeMode: "cover",
    borderRadius: BorderRadius.Medium,
  },
  right: {
    paddingVertical: Spacing.Regular,
    width: Dimensions.get("screen").width - 200,
  },
  rightWeb: {
    paddingVertical: Spacing.Regular,
    flex: 2,
  },
  description: {
    marginTop: Spacing.ExtraThin,
    width: Dimensions.get("screen").width - 200,
  },
  descriptionWeb: {
    marginTop: Spacing.ExtraThin,
    flex: 1,
  },
  split: {
    flex: 1,
  },
  tick: {
    width: 24,
    height: 24,
    resizeMode: "cover",
  },
  gap: {
    paddingBottom: Spacing.Thin,
  },
  iconContainer: {
    marginRight: Spacing.Light,
    marginTop: Spacing.Regular,
  },
});

export const containerStyle = (
  compeleted: boolean,
  hovered: boolean | undefined
) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: hovered
        ? colours.starburstOpacity
        : compeleted
        ? colours.white
        : colours.starburst,
      flexDirection: "row",
      borderRadius: BorderRadius.Medium,
    },
  });
