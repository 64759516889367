// import { FeatureFlags } from "../../../modules/FeatureFlags/constants";
import { UpsellSection } from "../../../modules/Upsell/model";
// import { gb } from "../../../utils/growthbook";
import { httpClient } from "../../httpClient";
import { UpsellType } from "./model";

export async function getUpsellFromConfig(
  upsellType: UpsellType
): Promise<UpsellSection[]> {
  const type = () => {
    switch (upsellType) {
      case UpsellType.INCART:
        return "upsellsIncart-v1";
      case UpsellType.PRECART:
      default:
        return "upsellsPrecart-v1";
    }
  };

  try {
    // console.debug(`API: GET config/${type()}.json`);

    const response = await httpClient({
      url: `config/${type()}.json`,
      method: "GET",
    });

    // For testing growthbook only
    // changes upsell to display per feature flag config
    // instead of default
    // if (type() === ("upsellsPrecart-v1" as unknown)) {
    //   const upsellPrecartJson = gb.getFeatureValue(
    //     FeatureFlags.UPSELL_AB_TEST,
    //     response.data
    //   );
    //   return upsellPrecartJson as UpsellSection[];
    // }

    return response.data as UpsellSection[];
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, `GET config/${type()}.json`);
    throw e;
  }
}
