import { Platform, StyleSheet } from "react-native";

import { Spacing } from "../../../components/styles/number";
import { Typography } from "../../../components/styles/typography";

export const styles = StyleSheet.create({
  optionView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: Spacing.ExtraThin,
  },
  optionIcon: {
    width: 76,
    height: 76,
    marginLeft: Platform.OS === "web" ? Spacing.Thin : Spacing.Light,
    resizeMode: "cover",
  },
  textGroup: {
    padding: Spacing.Light,
    paddingRight: Platform.OS === "web" ? Spacing.ExtraLight : Spacing.Thin,
    flex: 1,
  },
  price: {
    ...Typography.captionOne,
    textAlign: "right",
    flexWrap: "wrap",
  },
});
