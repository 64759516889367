import "./styles/main.scss";

// import { datadogLogs } from "@datadog/browser-logs";
// import { datadogRum } from "@datadog/browser-rum";
import {
  FeaturesReady,
  GrowthBookProvider,
} from "@growthbook/growthbook-react";
import AsyncStore from "@react-native-async-storage/async-storage";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import React, { useEffect } from "react";
import Iterate, { IterateProvider } from "react-native-iterate";
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from "redux_store/configureReduxStore";
import { PersistGate } from "redux-persist/integration/react";

import config from "config";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import i18next from "gyg_common/modules/i18next";
import { setUpPersistorInstance } from "gyg_common/redux_store/utils/PersistorUtils";
import { gb } from "gyg_common/utils/growthbook";
import { initDatadog } from "modules/datadog";

import BaseContainer from "./views/containers/Base/BaseContainer";

declare global {
  interface Window {
    store: ToolkitStore;
  }
}

i18next.changeLanguage(config.version);
// Load features from the GrowthBook API and initialize the SDK
if (gb.getClientKey()?.length > 0) {
  gb.loadFeatures();
}

Iterate.init({
  apiKey: config.iterateKey,
  safeArea: useSafeAreaInsets,
  storage: AsyncStore,
});

initDatadog();

const App: React.FC = () => {
  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      Notification.requestPermission &&
      typeof Notification.requestPermission === "function"
    ) {
      Notification.requestPermission();
    }
    setUpPersistorInstance(persistor);
  }, []);

  return (
    <GrowthBookProvider growthbook={gb}>
      <FeaturesReady timeout={3000} fallback={<LoadingScreen loading={true} />}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <SafeAreaProvider>
              <IterateProvider>
                <BrowserRouter>
                  <BaseContainer />
                </BrowserRouter>
              </IterateProvider>
            </SafeAreaProvider>
          </PersistGate>
        </Provider>
      </FeaturesReady>
    </GrowthBookProvider>
  );
};

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

export default App;
