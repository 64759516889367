import React from "react";
import { useSelector } from "react-redux";

import { LoyaltyModels, RootState } from "gyg_common";
import GomexWithoutFutureSection from "gyg_common/components/Gomex/GomexWithoutFutureSection";
import { GomexGuest } from "views/components/Gomex/GomexGuest";

export interface IEmptyCartProps {
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
}

export const EmptyCartGomex: React.FC<IEmptyCartProps> = ({
  loyalty,
  coffeeLoyalty,
}) => {
  const { isAuthSuccess } = useSelector(({ login }: RootState) => login);
  return (
    <>
      {isAuthSuccess && loyalty ? (
        <GomexWithoutFutureSection
          loyalty={loyalty}
          coffeeLoyalty={coffeeLoyalty}
        />
      ) : (
        <GomexGuest />
      )}
    </>
  );
};
