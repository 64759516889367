import "./style.scss";
import "styles/braintree.scss";

import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import config from "config";
import {
  CartReduxModels,
  GomexModules,
  GuestDetailModels,
  LoyaltyModels,
  StringValidators,
} from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { DeliveryContactDetails } from "gyg_common/components/OrderSetup/DeliveryContactDetails";
import DeliveryInfo from "gyg_common/components/OrderSetup/DeliveryInfo";
import { OrderSetupGlobalState } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import OrderStatusReceiptTable from "gyg_common/components/OrderStatus/OrderStatusReceiptTable";
import { RoundedTitleImageContainer } from "gyg_common/components/RoundedTitleImageContainer";
import {
  DeliveryAddress,
  DeliveryOrder,
  DropOffOption,
} from "gyg_common/redux_store/order/models";
import { buildFullAddress } from "gyg_common/redux_store/utils/OrderUtils";
import { OrderResponse } from "gyg_common/services/api/order/model";
import OrderSetupHeaderContainer from "views/containers/OrderSetupHeader/OrderSetupHeaderContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export interface PaymentProps {
  isLoggedIn?: boolean;
  order: OrderResponse | null;
  fullLoyaltyPayment: boolean;
  isDisabled: boolean;
  onGoogleReCAPTCHA: (value: string | null) => void;
  onGoogleReCAPTCHAExpired?: () => void;
  onPayClick: () => void;
  isLoading: boolean;
  deliveryFee?: number;
  isDeliveryCheckout?: boolean;
  deliveryAddress?: DeliveryAddress;
  delivery?: DeliveryOrder | null;
  onGuestTextChange?: (props: GuestDetailModels.GuestDetailsState) => void;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  goToLogin?: () => void;
  goBack?: () => void;
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  guestLoyalty: GuestDetailModels.GuestLoyalty;
  getOrderLoading: boolean;
  newPointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars;
  data: CartReduxModels.CartItem[];
  redeemDollars?: number | null;
}

export const Payment: React.FC<PaymentProps> = ({
  fullLoyaltyPayment,
  isDisabled,
  order,
  onGoogleReCAPTCHA,
  onGoogleReCAPTCHAExpired,
  onPayClick,
  isLoading,
  deliveryFee,
  isDeliveryCheckout,
  deliveryAddress,
  firstName,
  lastName,
  email,
  phoneNumber,
  onGuestTextChange,
  goToLogin,
  delivery,
  isLoggedIn,
  redeemDollars,
  goBack,
}) => {
  const { t } = useTranslation();

  const [firstError, setFirstError] = useState<boolean>(false);
  const [lastError, setLastError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const [showOrderSetup, setShowOrderSetup] = useState<boolean>(false);

  const addressForDelivery = buildFullAddress(deliveryAddress, {
    businessName: delivery?.dropoffBusinessName,
    suiteAndFloor: delivery?.dropoffSuiteAndFloor,
  });

  const deliveryInstructions = [
    ...(delivery?.dropoffOption
      ? [
          {
            [DropOffOption.MEET_AT_DOOR]: t("OrderManagement:MeetAtDoor"),
            [DropOffOption.LEAVE_AT_DOOR]: t("OrderManagement:LeaveAtDoor"),
          }[delivery?.dropoffOption],
        ]
      : []),
    ...(delivery?.dropoffOption === DropOffOption.MEET_AT_DOOR &&
    delivery?.dropoffPinCodeRequired
      ? [t("OrderManagement:dropoffPinCodeRequired")]
      : []),
    ...(delivery?.dropoffNotes ? [delivery?.dropoffNotes] : []),
  ].join("\n");

  const onTextChange = (props: GuestDetailModels.GuestDetailsState) => {
    if (props.firstName !== firstName && firstError) {
      setFirstError(false);
    }
    if (props.lastName !== lastName && lastError) {
      setLastError(false);
    }
    if (props.phoneNumber !== phoneNumber && phoneError) {
      setPhoneError(false);
    }
    if (props.email !== email && emailError) {
      setEmailError(false);
    }
    if (onGuestTextChange) {
      onGuestTextChange(props);
    }
  };

  const didTapEditAddress = () => {
    OrderSetupGlobalState.getInstance().editAddress = true;
    setShowOrderSetup(true);
  };

  const orderSetupDidShow = () => {
    setShowOrderSetup(false);
  };

  const onCheckInputError = () => {
    setFirstError(StringValidators.validateName(firstName ?? ""));
    setLastError(StringValidators.validateName(lastName ?? ""));
    setEmailError(StringValidators.validateEmail(email ?? ""));
    setPhoneError(!StringValidators.validatePhone(phoneNumber ?? "")?.isValid);
  };

  const onPayButtonTap = () => {
    if (!isLoggedIn) {
      if (
        StringValidators.validateName(firstName ?? "") ||
        StringValidators.validateName(lastName ?? "") ||
        StringValidators.validateEmail(email ?? "") ||
        !StringValidators.validatePhone(phoneNumber ?? "")?.isValid
      ) {
        onCheckInputError();
      } else {
        onPayClick();
      }
    } else {
      onPayClick();
    }
  };

  return (
    <div>
      <HeaderContainer
        viewCart={true}
        pageTitle={t("CheckoutPayment:checkoutTitle")}
        bottomComponentNotScrollable={
          <OrderSetupHeaderContainer
            disable
            isShowOrderSetup={showOrderSetup}
            orderSetupDidShow={orderSetupDidShow}
          />
        }
      />
      <div className='main-wrapper'>
        <div className='main-wrapper__col'>
          {!isLoggedIn && (
            <RoundedTitleImageContainer
              title={t("OrderManagement:contactDetails")}
              image={require("gyg_common/components/assets/icons/account_icon.png")}
              topPadding={0}>
              <DeliveryContactDetails
                firstName={firstName ?? ""}
                lastName={lastName ?? ""}
                phoneNumber={phoneNumber ?? ""}
                email={email ?? ""}
                firstError={firstError}
                lastError={lastError}
                phoneError={phoneError}
                emailError={emailError}
                onLoginPress={goToLogin ? goToLogin : () => {}}
                onTextChange={onTextChange}
              />
            </RoundedTitleImageContainer>
          )}

          {isDeliveryCheckout && addressForDelivery && (
            <RoundedTitleImageContainer
              title={t("OrderManagement:deliveryDetails")}
              image={require("gyg_common/components/assets/icons/location_icon.png")}
              rightImage={require("gyg_common/components/assets/icons/services/delivery_with_uber_icon.png")}>
              <DeliveryInfo
                address={addressForDelivery}
                instructions={deliveryInstructions}
                onPress={didTapEditAddress}
              />
            </RoundedTitleImageContainer>
          )}

          {order && (
            <RoundedTitleImageContainer
              title={t("CheckoutPayment:checkoutOrderTitle")}
              image={require("gyg_common/components/assets/icons/cart_icon.png")}
              rightImage={require("gyg_common/components/assets/icons/icon_edit.png")}
              onPress={goBack}>
              <OrderStatusReceiptTable
                isPreCheckoutMode
                isShowSurchargePercent={false}
                locale={config.version}
                order={order}
                deliveryFee={deliveryFee}
                gygDollars={redeemDollars}
              />
            </RoundedTitleImageContainer>
          )}
        </div>

        <div className={"main-wrapper__col"}>
          {!fullLoyaltyPayment && (
            <div>
              <div id='dropin-container'></div>
            </div>
          )}

          {config.googleRecaptchaSiteKey && (
            <div className='recaptcha-button'>
              <ReCAPTCHA
                sitekey={config.googleRecaptchaSiteKey}
                onChange={onGoogleReCAPTCHA}
                onExpired={onGoogleReCAPTCHAExpired}
              />
            </div>
          )}

          <div className='pay-button'>
            <PrimaryBlackButton
              testId={t("CheckoutPayment:payNowButton")}
              buttonName={t("CheckoutPayment:payNowButton")}
              onClick={onPayButtonTap}
              loading={isLoading}
              disable={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
