import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  FlatList,
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Switch,
  Text,
  View,
} from "react-native";

import * as PlatformUtils from "../../../modules/platformUtils";
import * as ProductModuleModel from "../../../modules/Products/model";
import * as ProductUtils from "../../../modules/Products/utils";
import { CartItem } from "../../../redux_store/cart/models";
import * as MenuModels from "../../../redux_store/menu/models";
import Accordion from "../../Accordion";
import arrowRigthIcon from "../../assets/icons/icon_chevron_right_small.png";
import tickIcon from "../../assets/icons/icon_tick.png";
import defaultImage from "../../assets/images/no_image_available_menu.png";
import RadioItem from "../../Buttons/Radio/RadioItem";
import GradientLine from "../../GradientLine";
import MenuImage from "../../Menu/MenuImage";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export interface MakeItAMealSectionProps {
  preSelectedMiamParts?: CartItem[];
  id: string;
  error?: boolean;
  expand?: boolean;
  handleUpdateExpandList?: (key: string, expand: boolean) => void;
  multipartSection: MenuModels.MultipartSection;
  selectedId: number | undefined;
  updateSelectedId: (id: number) => void;
  updateApplyRemoveModifier: (
    value: boolean,
    removeModifierId?: number
  ) => void;
  applyRemoveModifier?: boolean;
  onCustomisableOptionSelection?: (
    customisableOptionCategory: MenuModels.Category,
    index: number
  ) => void;
}

export interface MakeItAMealSectionListItem {
  item: ProductModuleModel.OptionDataProps;
  index: number;
}

const styles = StyleSheet.create({
  titleSection: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  optionView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 3,
  },
  optionIcon: {
    width: 76,
    height: 76,
    marginLeft: Platform.OS === "web" ? Spacing.Thin : Spacing.Light,
    resizeMode: "cover",
  },
  textGroup: {
    padding: 16,
    flexShrink: 1,
  },
  wrapper: {
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
  },
  split: {
    flex: 1,
  },
  required: {
    marginTop: 8,
  },
  productRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  icon: {
    width: 32,
    height: 32,
    marginHorizontal: 16,
  },
  detailsText: {
    ...Typography.captionOne,
    flex: 2,
    marginTop: 4,
  },
});

const keyExtractor = (item: ProductModuleModel.OptionDataProps) =>
  `${item.element.id}`;

const MakeItAMealSection: React.FC<MakeItAMealSectionProps> = ({
  preSelectedMiamParts,
  id,
  error,
  expand,
  handleUpdateExpandList,
  multipartSection,
  selectedId,
  updateSelectedId,
  updateApplyRemoveModifier,
  applyRemoveModifier,
  onCustomisableOptionSelection,
}) => {
  const { t } = useTranslation();
  const hasUpdatedPreSelected = useRef(false);

  const mealOptionData = useMemo(() => {
    return ProductUtils.generateMakeMealOptionData(multipartSection);
  }, [multipartSection]);

  const updateSelectedItemId = useCallback(
    (id: number | string) => {
      updateSelectedId(id as number);
    },
    [updateSelectedId]
  );

  useEffect(() => {
    /**
     * Automatically pre-select the meal option on first mount.
     */
    if (preSelectedMiamParts?.length && !hasUpdatedPreSelected.current) {
      ProductUtils.getPreselectedMiamOptionsId(
        mealOptionData,
        preSelectedMiamParts
      ).forEach((id) => updateSelectedItemId(id));
      hasUpdatedPreSelected.current = true;
    } else {
      const singleMealOptionToSetSelected =
        ProductUtils.getAutoSelectedMealOption(mealOptionData);
      if (
        singleMealOptionToSetSelected &&
        parseInt(singleMealOptionToSetSelected.element.id as string, 10) !==
          selectedId
      ) {
        // TODO: Remove once Customizable has been optimized/ported
        setTimeout(() => {
          updateSelectedId(singleMealOptionToSetSelected.element.id as number);
        }, 0);
      }
    }
  }, [
    mealOptionData,
    updateSelectedId,
    updateSelectedItemId,
    selectedId,
    preSelectedMiamParts,
  ]);

  const renderCustomisableOption = (
    optionItem: ProductModuleModel.OptionDataProps,
    index: number
  ) => {
    const { element, categoryElement } = optionItem;
    const selected = !!categoryElement?.products?.find(
      (product: MenuModels.Product) => product.id === selectedId
    );

    return (
      <Pressable
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `MakeItAMealSection-Customisable-ChildItem-${element.id}`
        )}
        onPress={() =>
          onCustomisableOptionSelection &&
          onCustomisableOptionSelection(categoryElement, index)
        }>
        <View key={element.name + element.id} style={styles.optionView}>
          {!element.withoutImage && (
            <MenuImage
              style={styles.optionIcon}
              source={
                element.imageTopDownUrl?.length
                  ? (element.imageTopDownUrl as ImageSourcePropType)
                  : defaultImage
              }
            />
          )}
          <View style={styles.textGroup}>
            <Text style={Typography.titleThree}>{element.name}</Text>
          </View>
          <View style={styles.split} />
          {!!element.price && (
            <Text style={Typography.captionOne}>{`+ $${element.price.toFixed(
              2
            )}`}</Text>
          )}
          {
            <Image
              source={
                (selected ? tickIcon : arrowRigthIcon) as ImageSourcePropType
              }
              style={styles.icon}
            />
          }
        </View>
      </Pressable>
    );
  };

  const renderChildItem = (
    optionItem: ProductModuleModel.OptionDataItemProps
  ) => {
    return (
      <View
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `MakeItAMealSection-ChildItem-${optionItem.id}`
        )}
        key={optionItem.name + optionItem.id}
        style={styles.optionView}>
        {!optionItem.withoutImage && (
          <MenuImage
            style={styles.optionIcon}
            source={
              optionItem.imageTopDownUrl?.length
                ? (optionItem.imageTopDownUrl as ImageSourcePropType)
                : defaultImage
            }
          />
        )}
        <View style={styles.textGroup}>
          <Text style={Typography.titleThree}>{optionItem.name.trim()}</Text>
          {(optionItem.size || optionItem.nutritionalInfo) && (
            <Text style={styles.detailsText}>
              {optionItem.size && optionItem.nutritionalInfo
                ? `${optionItem.size} | ${optionItem.nutritionalInfo}${t(
                    "OrderManagement:energyUnit"
                  )}`
                : optionItem.size
                  ? `${optionItem.size}`
                  : optionItem.nutritionalInfo &&
                    `${optionItem.nutritionalInfo}${t(
                      "OrderManagement:energyUnit"
                    )}`}
            </Text>
          )}
        </View>
        <View style={styles.split} />
        {!!optionItem.price && (
          <Text style={Typography.captionOne}>{`+ $${optionItem.price.toFixed(
            2
          )}`}</Text>
        )}
      </View>
    );
  };

  const renderItem = ({ item, index }: MakeItAMealSectionListItem) => {
    const selected = item.element.id === selectedId;
    let removeModifier: MenuModels.ModifierLookup | undefined;
    if (selected) {
      const selectedProduct = multipartSection.products.find(
        (n: MenuModels.Product) => n.id === selectedId
      );
      if (selectedProduct) {
        removeModifier = multipartSection.modifierLookup?.find(
          (n: MenuModels.ModifierLookup) =>
            n.id === selectedProduct.removeModifier?.[0]
        );
      }
    }

    return (
      <View
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `MakeItAMealSection-ListItem-${item.element.id}`
        )}
        key={item.element.id}>
        {item.categoryElement ? (
          renderCustomisableOption(item, index)
        ) : (
          <RadioItem
            key={item.element.id}
            disabled={item.disabled}
            id={item.element.id}
            selected={selected}
            selectedOption={updateSelectedItemId}>
            {renderChildItem(item.element)}
          </RadioItem>
        )}
        <GradientLine />
        {removeModifier && (
          <View>
            <View style={styles.productRow} key={removeModifier.id}>
              <Text style={Typography.titleThree}>{removeModifier.name}</Text>
              <Switch
                trackColor={{
                  false: colours.switchGrey,
                  true: colours.lightGreen,
                }}
                value={applyRemoveModifier}
                onValueChange={(value) =>
                  updateApplyRemoveModifier(value, removeModifier?.id)
                }
              />
            </View>
            <GradientLine />
          </View>
        )}
      </View>
    );
  };

  const onExpandChanged = (key: string, expanded: boolean) => {
    if (handleUpdateExpandList) {
      handleUpdateExpandList(key, expanded);
    }
  };

  return (
    <Accordion
      expand={expand || false}
      onExpandChanged={onExpandChanged}
      key={id + "Accordion"}
      id={id}
      title={multipartSection.name}
      subTitle={t("OrderManagement:required")}
      subTitleStyle={error ? Typography.error : undefined}>
      <View key={multipartSection.name}>
        <View>
          <FlatList
            keyExtractor={keyExtractor}
            key={multipartSection.name}
            renderItem={renderItem}
            data={mealOptionData}
          />
          <GradientLine />
        </View>
      </View>
    </Accordion>
  );
};

export default MakeItAMealSection;
