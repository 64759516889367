import "./styles.scss";

import React from "react";
import { Modal, StyleSheet, Text, View } from "react-native";

import iconImage from "gyg_common/components/assets/icons/icon_close.svg";
import PrimaryBlackButton, {
  PrimaryBlackButtonProps,
} from "gyg_common/components/Buttons/PrimaryBlackButton";
import TertiaryButton, {
  TertiaryButtonProps,
} from "gyg_common/components/Buttons/TertiaryButton";
import IconButton from "gyg_common/components/IconButton";
import { Typography } from "styles/typography";

const style = StyleSheet.create({
  row: {
    alignSelf: "center",
    marginTop: 16,
    width: "100%",
  },
  title: {
    ...Typography.largeTitle,
    textAlign: "center",
  },
  body: {
    ...Typography.body,
    textAlign: "center",
  },
  buttons: {
    flexDirection: "row-reverse",
    justifyContent: "center",
    marginBottom: 16,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
});

export interface AlertMessageProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  body: string;
  actionButtonProps: PrimaryBlackButtonProps;
  cancelButtonProps: TertiaryButtonProps;
}

// TODO: This component not shared - change to non react-native impl
export default function AlertMessage(props: AlertMessageProps): JSX.Element {
  return (
    <Modal transparent={true} visible={props.isVisible}>
      <div className={"overlay"}>
        <div
          id={"alertMessage-modal-wrapper"}
          className={"alertMessage-modal-wrapper"}>
          <div className={"alertMessage-detail"}>
            <div className={"alertMessage-close-button-right"}>
              <IconButton
                withShadow={false}
                onPress={props.onClose}
                iconImage={iconImage}
              />
            </div>
          </div>
          <View style={style.row}>
            <Text style={style.title}>{props.title}</Text>
          </View>
          <View style={style.row}>
            <Text style={style.body}>{props.body}</Text>
          </View>
          <View style={style.row}>
            <View style={style.buttons}>
              <View style={style.buttonContainer}>
                <PrimaryBlackButton {...props.actionButtonProps} />
              </View>
              <View style={{ width: 16 }} />
              <View style={style.buttonContainer}>
                <TertiaryButton {...props.cancelButtonProps} />
              </View>
            </View>
          </View>
        </div>
      </div>
    </Modal>
  );
}
