import { Platform, StyleSheet } from "react-native";
import colours from "../../styles/colours";
import { Typography } from "../../styles/typography";
import { Spacing } from "../../styles/number";

interface StyleProps {
  hovered?: boolean;
  pressed?: boolean;
  disabled: boolean;
}

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginLeft: Spacing.ExtraLight,
    marginBottom: Spacing.Light,
    alignItems: "center",
    width: 130,
  },
  icon: {
    width: 32,
    height: 32,
  },
  animationContainer: {
    width: 40,
  },
});

export const stylesWithProps = (props: StyleProps) =>
  StyleSheet.create({
    wrapper: {
      opacity: props.hovered || props.hovered ? 0.8 : 1,
    },
    text: {
      ...Typography.titleThree,
      color: props.disabled ? colours.uiGrey : colours.darkGrey,
      marginLeft: Spacing.Light,
      marginTop: Platform.OS != "web" ? Spacing.ExtraThin : 0,
      marginBottom: Platform.OS != "web" ? 0 : Spacing.Narrow,
    },
  });

export default styles;
