import orderNowIcon from "assets/images/order_now.png";
import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import colours from "styles/colours";

interface DashboardBannerSectionProps {
  title: string | null;
  subTitle: string | null;
  bgImage: {
    desktop: string;
    mobile: string;
  };
  viewCartClick?: () => void;
}

interface StyleProps extends DashboardBannerSectionProps {
  hovered?: boolean;
  pressed?: boolean;
}

const styles = (props: StyleProps) =>
  StyleSheet.create({
    image: {
      flex: 1,
      justifyContent: "center",
    },
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: 420,
    },
    wrapper: {
      maxWidth: 570,
      paddingVertical: 110,
      alignItems: "center",
    },
    wrapperPaddingTablet: {
      paddingVertical: 65,
    },
    touchableHighlightContainer: {
      margin: 10,
      width: 190,
      flexDirection: "row",
      borderRadius: 8,
      backgroundColor: props.pressed
        ? colours.starburstOpacity
        : props.hovered
          ? colours.starburst
          : colours.yellow,
      paddingVertical: 8,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingHorizontal: 8,
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      color: "white",
      fontFamily: "Sini-Bold",
      fontSize: 60,
      fontWeight: "bold",
      textAlign: "center",
      textShadowColor: "rgba(0, 0, 0, 1)",
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 1,
      marginTop: 10,
    },
    titleSizeTablet: {
      fontSize: 48,
      paddingHorizontal: 60,
    },
    subtitle: {
      fontFamily: "Montserrat-SemiBold",
      fontStyle: "normal",
      color: "white",
      fontSize: 18,
      textAlign: "center",
      textShadowColor: "rgba(0, 0, 0, 0.5)",
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 1,
      margin: 10,
    },
    orderNowView: {
      paddingHorizontal: 8,
      paddingVertical: 10,
    },
    orderNowIcon: {
      width: 145,
      height: 20,
      resizeMode: "cover",
    },
  });

export default function DashboardBannerSection(
  props: DashboardBannerSectionProps
): JSX.Element {
  const { title, subTitle, bgImage, viewCartClick } = props;
  const { isTabletScreen } = useMediaQuery();

  return (
    <ImageBackground
      source={
        isTabletScreen
          ? (bgImage.desktop as ImageSourcePropType)
          : (bgImage.mobile as ImageSourcePropType)
      }
      resizeMode='cover'
      style={styles(props).image}>
      <View style={styles(props).container}>
        <View
          style={[
            styles(props).wrapper,
            !isTabletScreen ? styles(props).wrapperPaddingTablet : null,
          ]}>
          <Text
            style={[
              styles(props).title,
              !isTabletScreen ? styles(props).titleSizeTablet : null,
            ]}>
            {title}
          </Text>
          <Text style={styles(props).subtitle}>{subTitle}</Text>
          <Pressable
            testID='DashboardOrderNowBtn'
            onPress={viewCartClick}
            style={({ hovered, pressed }) =>
              styles({ ...props, hovered, pressed }).touchableHighlightContainer
            }>
            <View style={styles(props).buttonContainer}>
              <View style={styles(props).orderNowView}>
                <Image
                  style={styles(props).orderNowIcon}
                  source={orderNowIcon as ImageSourcePropType}
                />
              </View>
            </View>
          </Pressable>
        </View>
      </View>
    </ImageBackground>
  );
}
