import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LayoutChangeEvent, Text, View } from "react-native";

import { pointsStyle, progressBarStyle, styles } from "./styles";

export interface GomexProgressBarProps {
  loyaltyMaxPoints: number;
  loyaltyConversionRate?: number;
  isEstimatedStatus?: boolean;
  points?: number;
}

const GomexProgressBar: React.FC<GomexProgressBarProps> = ({
  loyaltyMaxPoints,
  loyaltyConversionRate,
  isEstimatedStatus = false,
  points = 0,
}) => {
  const { t } = useTranslation();
  const [progressWidth, setProgressWidth] = useState<number>(0);

  const progressBarStyleMemo = useMemo(
    () =>
      progressBarStyle(
        isEstimatedStatus,
        loyaltyMaxPoints,
        progressWidth,
        points
      ),
    [isEstimatedStatus, loyaltyMaxPoints, progressWidth, points]
  );

  const onLayoutChanges = (e: LayoutChangeEvent) => {
    setProgressWidth(e.nativeEvent.layout.width);
  };

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.progressBarContainer}>
          <View onLayout={onLayoutChanges} style={progressBarStyleMemo.back}>
            <View style={progressBarStyleMemo.front}>
              <View style={progressBarStyleMemo.triangle} />
            </View>
          </View>
          <Text style={styles.dollarSign}>$$$</Text>
        </View>

        <View style={styles.pointDetailContainer}>
          <Text style={pointsStyle(isEstimatedStatus).details}>
            {t("Loyalty:pointsIndication", {
              points,
              loyaltyMaxPoints,
            })}
          </Text>
          {!!loyaltyConversionRate && (
            <Text style={styles.pointRef}>
              {t("Loyalty:pointsConversion", {
                points: loyaltyMaxPoints,
                loyaltyConversionRate,
              })}
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default GomexProgressBar;
