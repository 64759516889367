import useAddToOrder from "hooks/useAddToOrder";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxModels,
  MenuModels,
  MenuModuleUtils,
  ProductConst,
  ProductUtils,
  RootState,
} from "gyg_common";
import AddModifyCartItem from "gyg_common/components/Products/AddModifyCartItem";
import ProductDetail from "gyg_common/components/Products/ProductDetail";
import useUpdateOrder from "gyg_common/hooks/useUpdateOrder";

interface NonCustomisableProductProps {
  product: MenuModels.Product;
  editMode: ProductConst.EditMode;
  title?: string;
  size?: string;
  cartItem?: CartReduxModels.CartItem;
  index?: number;
  goBack: () => void;
  enableGoBackMode?: boolean;
  isUpsell?: boolean;
}

const NonCustomisableProduct: React.FC<NonCustomisableProductProps> = ({
  product,
  title,
  size,
  goBack,
  cartItem,
  editMode,
  index,
  enableGoBackMode,
  isUpsell,
}) => {
  const [quantity, setQuantity] = useState<number>(cartItem?.quantity || 1);
  const { selectedStore } = useSelector((s: RootState) => s.store);

  const handleUpdateOrder = useUpdateOrder(editMode, goBack);
  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: "",
    onClose: goBack,
  });

  const getCartItem = (): CartReduxModels.CartItem => {
    return {
      productId: product.id,
      posPlu: product.posPlu,
      name: product.name,
      quantity: quantity,
      unitPrice: product.price,
      price: product.price * quantity,
      allowModify: !!ProductUtils.findTemplateRoute(
        product as unknown as MenuModels.Category
      ),
      labelForFavourite:
        MenuModuleUtils.getProductSizeAndTitle(product).title || product.name,
    };
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.SimpleProduct, {
      product_id: product?.id,
      product_name: product?.name,
      product_quantity: quantity,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='product-wrapper--small'>
      <ProductDetail
        key={product.name}
        setQuantity={setQuantity}
        onGoBack={() => {
          goBack();
        }}
        gobackMode={enableGoBackMode}
        name={title as string}
        quantity={quantity}
        imageAngleUrl={product.imageAngleUrl}
        productSize={size}
        productNutritionalInfo={product.nutritionalInfo}
        price={product.price}
        cartItems={[getCartItem()]}
        closeButtonPosition={"right"}
        showNutritionalDisclaimer={true}
        allergenText={product.allergenInfo}
      />
      <div className={"product__cart-btn-wrapper"}>
        <AddModifyCartItem
          editMode={editMode}
          cartItems={[getCartItem()]}
          index={index}
          isUpsell={isUpsell}
          onAddToOrder={handleAddToOrder}
          onEditOrder={handleUpdateOrder}
        />
      </div>
    </div>
  );
};

export default NonCustomisableProduct;
