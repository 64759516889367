import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { useNavigate } from "react-router-dom";

import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import CardSticker from "gyg_common/components/CardSticker";
import GomexHeader from "gyg_common/components/Gomex/GomexHeader";
import { Screens } from "navigation/const";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  ctaWrapper: {
    padding: 16,
  },
  ctaText: {
    ...Typography.bodyTwo,
    marginTop: 8,
    marginBottom: 16,
  },
});

export const GomexGuest: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * After successful login should navigate to Menu as GomexGuest lives in EmptyCart.
   * @returns
   */
  const goToLogin = () =>
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Menu } });

  return (
    <CardSticker>
      <View style={styles.ctaWrapper}>
        <GomexHeader headerAltColors />
        <Text style={Typography.titleThree}>{t("Dashboard:ctaTitle")}</Text>
        <Text style={styles.ctaText}>{t("Dashboard:ctaCopy")}</Text>
        <SecondaryButton
          onClick={goToLogin}
          buttonName={t("CheckoutPayment:loginOrRegister")}
          testId='GomexLoginOrRegisterButton'
        />
      </View>
    </CardSticker>
  );
};
