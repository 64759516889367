import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { InputType } from "../../modules/Components/Shared/InputType.enum";
import * as PlatformUtils from "../../modules/platformUtils";
import closeIcon from "../assets/icons/icon_close.png";
import { LinkButton } from "../Buttons/LinkButton";
import PrimaryBlackButton from "../Buttons/PrimaryBlackButton";
import FormInput from "../FormInput";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";
import MiddleModal, { WebModalSize } from "./MiddleModal";
import { ButtonProps } from "./ModalWithButton";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    flexDirection: "row",
    marginTop: Spacing.Regular,
    justifyContent: "space-between",
    width: "100%",
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: Spacing.Thin,
    marginLeft: Spacing.Thin,
    marginTop: -Spacing.ExtraThin,
  },
  heading: {
    ...Typography.largeTitle,
    color: colours.darkGrey,
    textAlign: "center",
    marginLeft: Spacing.Thick,
    flexWrap: "wrap",
    flex: 1,
  },
  messageContainer: {
    marginHorizontal: Spacing.Light,
  },
  message: {
    marginTop: Spacing.Light,
    ...Typography.body,
    color: colours.darkGrey,
    textAlign: "center",
  },
  messageBold: {
    marginTop: Spacing.Light,
    ...Typography.bodyBold,
    color: colours.darkGrey,
    textAlign: "center",
  },
  primaryButton: {
    flex: 1,
  },
  textInput: {
    marginTop: Spacing.Light,
  },
  askCode: {
    ...Typography.titleThree,
    marginBottom: Spacing.Thin,
    alignSelf: "center",
  },
  buttonContainer: {
    marginTop: Spacing.Thin,
    marginBottom: Spacing.Light,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -Spacing.ExtraThin,
  },
  linkButtonContainer: {
    alignSelf: "center",
    marginBottom: Spacing.ExtraLight,
  },
  marginBottomNeg: {
    marginBottom: -Spacing.Light,
  },
  askCodeContainer: {
    marginBottom: Spacing.Light,
  },
});

export interface ModalWithInputProps {
  testId?: string;
  webModalSize?: WebModalSize;
  isVisible: boolean;
  primaryButton: ButtonProps;
  title: string;
  message?: string | null;
  messageBold?: string;
  inputLabel: string;
  inputPlaceholder?: string | null;
  inputValue: string;
  isInputError?: boolean;
  isButtonLoading?: boolean;
  inputErrorMessage?: string;
  verifyMobile?: boolean;
  primaryButtonDisable?: boolean;
  scrollToBottomOnKeyboardOpen?: boolean;
  iOSTextContentType?:
    | "none"
    | "URL"
    | "addressCity"
    | "addressCityAndState"
    | "addressState"
    | "countryName"
    | "creditCardNumber"
    | "emailAddress"
    | "familyName"
    | "fullStreetAddress"
    | "givenName"
    | "jobTitle"
    | "location"
    | "middleName"
    | "name"
    | "namePrefix"
    | "nameSuffix"
    | "nickname"
    | "organizationName"
    | "postalCode"
    | "streetAddressLine1"
    | "streetAddressLine2"
    | "sublocality"
    | "telephoneNumber"
    | "username"
    | "password"
    | "newPassword"
    | "oneTimeCode";
  androidAutoComplete?:
    | "birthdate-day"
    | "birthdate-full"
    | "birthdate-month"
    | "birthdate-year"
    | "cc-csc"
    | "cc-exp"
    | "cc-exp-day"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-number"
    | "email"
    | "gender"
    | "name"
    | "name-family"
    | "name-given"
    | "name-middle"
    | "name-middle-initial"
    | "name-prefix"
    | "name-suffix"
    | "password"
    | "password-new"
    | "postal-address"
    | "postal-address-country"
    | "postal-address-extended"
    | "postal-address-extended-postal-code"
    | "postal-address-locality"
    | "postal-address-region"
    | "postal-code"
    | "street-address"
    | "sms-otp"
    | "tel"
    | "tel-country-code"
    | "tel-national"
    | "tel-device"
    | "username"
    | "username-new"
    | "off";
  androidImportantForAutofill?:
    | "auto"
    | "no"
    | "noExcludeDescendants"
    | "yes"
    | "yesExcludeDescendants";
  onTextChange: (text: string) => void;
  onRequestCode?: () => void;
  onModalClose: () => void;
  useOnResponderGrant?: boolean;
  /* Using onResponderGrant instead of Touchable components fixes issue when 
     TextInput is not responded for touches on web, when Modal is shown on top of another Modal. */
}
const ModalWithInput: React.FC<ModalWithInputProps> = ({
  testId,
  webModalSize = WebModalSize.AUTO,
  isButtonLoading = false,
  isVisible,
  isInputError,
  primaryButton,
  title,
  message,
  messageBold,
  inputLabel,
  inputPlaceholder,
  inputErrorMessage,
  inputValue,
  scrollToBottomOnKeyboardOpen,
  iOSTextContentType,
  androidAutoComplete,
  androidImportantForAutofill,
  verifyMobile,
  primaryButtonDisable = false,
  onTextChange,
  onRequestCode,
  onModalClose,
  useOnResponderGrant,
}) => {
  const { t } = useTranslation();

  const onPrimaryPressHandler = () => {
    primaryButton.action();
    Keyboard.dismiss();
  };

  const CloseIcon = () => {
    return (
      <Image
        {...PlatformUtils.generateTestID(Platform.OS, "close-icon-modal")}
        style={styles.icon}
        source={closeIcon as ImageSourcePropType}
      />
    );
  };

  const inputView = () => {
    return (
      <FormInput
        testId={testId}
        name={inputLabel}
        placeHolder={inputPlaceholder}
        type={verifyMobile ? InputType.NUMBER : undefined}
        handleChangeText={onTextChange}
        error={isInputError ?? false}
        value={inputValue}
        errorMsg={inputErrorMessage}
        textContentType={iOSTextContentType}
        autoComplete={androidAutoComplete}
        importantForAutofill={androidImportantForAutofill}
      />
    );
  };

  return (
    <MiddleModal
      isVisible={isVisible}
      webModalSize={webModalSize}
      scrollToBottomOnKeyboardOpen={scrollToBottomOnKeyboardOpen}
      testId={testId}>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <Text style={styles.heading}>{title}</Text>

          {useOnResponderGrant ? (
            <View
              onStartShouldSetResponder={() => true}
              onResponderGrant={onModalClose}>
              <CloseIcon />
            </View>
          ) : (
            <TouchableOpacity onPress={onModalClose}>
              <CloseIcon />
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.messageContainer}>
          {!!messageBold && (
            <Text style={styles.messageBold}>{messageBold}</Text>
          )}
          {!!message && <Text style={styles.message}>{message}</Text>}

          <View style={styles.textInput}>
            {Platform.OS === "web" ? (
              <form style={{ display: "contents" }} autoComplete='on'>
                {inputView()}
              </form>
            ) : (
              inputView()
            )}
          </View>

          {verifyMobile && (
            <View style={styles.askCodeContainer}>
              <Text style={styles.askCode}>
                {t("LoginRegistration:askCodeLabel")}
              </Text>
              <View style={{ alignSelf: "center" }}>
                <LinkButton
                  boldText={Platform.OS === "web" ? true : false}
                  testId='RequestCode'
                  buttonName={t("LoginRegistration:requestCodeLabel")}
                  onPress={onRequestCode}
                />
              </View>
            </View>
          )}

          <View style={styles.buttonContainer}>
            <View style={styles.primaryButton}>
              <PrimaryBlackButton
                loading={isButtonLoading}
                testId='PrimaryModalWithInputButton'
                buttonName={primaryButton.name}
                onClick={onPrimaryPressHandler}
                disable={primaryButtonDisable}
                useOnResponderGrant={useOnResponderGrant}
              />
            </View>
          </View>
        </View>
      </View>
    </MiddleModal>
  );
};

export default ModalWithInput;
