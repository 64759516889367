import { Card } from "@braze/web-sdk";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SwiperType from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { PromoReduxModels, RootState } from "gyg_common";

import PromotionCard from "./PromotionCard";

export interface PromotionsSectionProps {
  brazeCards: Card[];
}

export const PromotionsSection: React.FC<PromotionsSectionProps> = ({
  brazeCards,
}) => {
  const { isTabletScreen } = useMediaQuery();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const availablePromos: PromoReduxModels.Promo[] = useSelector(
    (state: RootState) => state.promo.availablePromos
  );

  const onClick = (url: string) => {
    window.open(url, window.Cypress ? "_self" : undefined);
  };

  const renderCard = (promo: PromoReduxModels.Promo, index: number) => {
    const item = brazeCards.find((card) => card.id === promo.id);
    if (item) {
      return (
        <div id={`Promotions-${item.id}`} key={`Promotions - ${item.id}`}>
          <SwiperSlide key={`key- ${index}`}>
            <div className='dashboard__promotions-card'>
              <PromotionCard
                active={activeIndex === index}
                card={item}
                onClick={onClick}
              />
            </div>
          </SwiperSlide>
        </div>
      );
    }
  };

  const onActiveIndexChange = (swiper: SwiperType) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <Swiper
      onSlideChange={onActiveIndexChange}
      observeParents
      observer
      resizeObserver
      slidesPerView={1}
      freeMode={false}
      spaceBetween={isTabletScreen ? 16 : 8}
      watchOverflow={true}
      pagination={{
        clickable: true,
      }}>
      <div>{availablePromos.map(renderCard)}</div>
    </Swiper>
  );
};
