import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import useInterval from "gyg_common/hooks/useInterval";
import { RootState } from "gyg_common/redux_store/rootReducer";
import { ScreenParams, Screens } from "navigation/const";
import { createPathWithParam } from "navigation/utils";
import NotFoundScreen from "views/components/shared/404/NotFound";
import LoginScreen from "views/containers/Auth/LoginContainer";
import DashboardScreen from "views/containers/Dashboard/DashboardContainer";
import ForgotPasswordScreen from "views/containers/ForgotPassword/ForgotPasswordContainer";
import MyGomexScreen from "views/containers/Gomex/MyGomexContainer";
import CheckoutScreen from "views/containers/GuestDetail/GuestDetailContainer";
import MenuContainer from "views/containers/Menu/MenuContainer";
import NotificationsScreen from "views/containers/Notifications/NotificationsContainer";
import OrderScreen from "views/containers/Order/OrderContainer";
import OrderDetailsScreen from "views/containers/OrderDetails/OrderDetailsContainer";
import PaymentScreen from "views/containers/Payment/PaymentContainer";
import RecentOrdersScreen from "views/containers/RecentOrders/RecentOrdersContainer";
import RegistrationScreen from "views/containers/Registration/RegistrationContainer";
import ResetPasswordScreen from "views/containers/ResetPassword/ResetPasswordContainer";
import SocialScreen from "views/containers/Social/SocialContainer";
import RestaurantsContainer from "views/containers/Stores/RestaurantsContainer";
import UpsellScreen from "views/containers/Upsell/UpsellContainer";
import UserProfileScreen from "views/containers/UserManagement/UserProfileContainer";

import OrderStatusScreen from "@/views/containers/OrderStatus/OrderStatusContainer";

export default function () {
  const { hasPendingNavigation, navigationAction } = useSelector(
    (s: RootState) => s.branch
  );

  useInterval(
    () => {
      if (hasPendingNavigation && navigationAction) {
        navigationAction();
      }
    },
    // Delay in milliseconds or null to stop it
    hasPendingNavigation ? 1000 : null
  );

  return (
    <Routes>
      <Route path={Screens.Dashboard} element={<DashboardScreen />} />
      <Route path={Screens.Order} element={<OrderScreen />} />
      <Route path={Screens.Menu} element={<MenuContainer />} />
      <Route path={Screens.OrderStatus} element={<OrderStatusScreen />} />

      <Route path={Screens.Checkout} element={<CheckoutScreen />} />
      <Route path={Screens.Payment} element={<PaymentScreen />} />

      <Route path={Screens.OrderDetails} element={<OrderDetailsScreen />} />
      <Route
        path={createPathWithParam(Screens.Login, ScreenParams.AUTH)}
        element={<LoginScreen />}
      />
      <Route path={Screens.Registration} element={<RegistrationScreen />} />
      <Route
        path={createPathWithParam(Screens.Restaurants, ScreenParams.ID)}
        element={<RestaurantsContainer />}
      />
      <Route path={Screens.UserProfile} element={<UserProfileScreen />} />
      <Route path={Screens.MyGomex} element={<MyGomexScreen />} />
      <Route path={Screens.RecentOrders} element={<RecentOrdersScreen />} />
      <Route
        path={createPathWithParam(Screens.Social, ScreenParams.CODE)}
        element={<SocialScreen />}
      />
      <Route path={Screens.Upsell} element={<UpsellScreen />} />
      <Route path={Screens.Notifications} element={<NotificationsScreen />} />
      <Route path={Screens.ForgotPassword} element={<ForgotPasswordScreen />} />
      <Route path={Screens.ResetPassword} element={<ResetPasswordScreen />} />

      {/** Keep 404 as a last route */}
      <Route path='*' element={<NotFoundScreen />} />
    </Routes>
  );
}
