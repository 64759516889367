import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  AuthStateModels,
  LoyaltyModels,
  RootState,
} from "gyg_common";
import { RewardsCarousel } from "views/components/Rewards/RewardsCarousel";
import { RewardsModalContainer } from "views/containers/Rewards/RewardsModalContainer";

export interface RewardsCarouselContainerProps {
  horizontalMode?: boolean | undefined;
}

export const RewardsCarouselContainer: React.FC<
  RewardsCarouselContainerProps
> = ({ horizontalMode }) => {
  const [rewardsDetailModalVisible, setRewardsDetailModalVisible] =
    useState(false);
  const [selectedReward, setSelectedReward] =
    useState<LoyaltyModels.IRewards | null>(null);

  const { isAuthSuccess, currentUser } = useSelector(
    (state: RootState) => state.login
  );
  const { rewards } = useSelector((state: RootState) => state.loyalty);

  const onRewardItemPressed = (
    reward: LoyaltyModels.IRewards,
    user: AuthStateModels.CurrentUser | undefined
  ) => {
    setSelectedReward(reward);
    setRewardsDetailModalVisible(true);

    const eventName =
      AnalyticsConst.Events.Rewards +
      "_" +
      reward.name.toLowerCase().replace(/\s/g, "_");

    const eventPayload = {
      master_coupon_code: reward.masterCouponCode,
      user_coupon_code: reward.userCouponCode,
      braze_cus_id: user?.brazeId,
      offer_name: reward.name,
    };

    AnalyticsInstance.trackEvent(eventName, eventPayload);
  };

  const closeModal = () => setRewardsDetailModalVisible(false);

  if (!isAuthSuccess || !rewards?.length) return null;

  return (
    <>
      <RewardsCarousel
        rewards={rewards}
        currentUser={currentUser}
        onRewardItemPressed={onRewardItemPressed}
        horizontalMode={horizontalMode}
      />
      {selectedReward && (
        <RewardsModalContainer
          {...selectedReward}
          rewardsDetailModalVisible={rewardsDetailModalVisible}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default RewardsCarouselContainer;
