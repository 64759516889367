import React from "react";
import { useTranslation } from "react-i18next";
import {
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";

import infoIcon from "../../components/assets/icons/icon_alert.png";
import TertiaryButton from "../../components/Buttons/TertiaryButton";
import CardSticker from "../../components/CardSticker";
import { GetDirections, StaticMap } from "../../components/StaticMap";
import { Typography } from "../../components/styles/typography";
import { OrderConstants } from "../../modules/Order";
import * as CheckoutModels from "../../redux_store/checkout/model";
import { Spacing } from "../styles/number";

const style = StyleSheet.create({
  mapContainer: {
    width: "100%",
    minHeight: 200,
    marginBottom: Spacing.Light,
  },
  mapDescription: {
    flex: 1,
    padding: Spacing.Light,
  },
  mapBtnsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: Spacing.Thin,
  },
  detailsBtnContainer: {
    marginRight: Spacing.Thin,
  },
  mapDescriptionStoreName: {
    ...Typography.titleTwo,
    marginBottom: Spacing.Thin,
  },
});

interface OrderStatusMapProps {
  storeAddress: CheckoutModels.Address;
  storeId: number;
  storeName: string;
  navigateToStoreDetails: () => void;
  onError?: (errMessage: string) => void;
  containerStyle?: StyleProp<ViewStyle>;
}

const OrderStatusMap: React.FC<OrderStatusMapProps> = (props) => {
  const { t } = useTranslation();
  const { storeAddress, storeName, storeId, navigateToStoreDetails, onError } =
    props;

  const onDirectionError = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onError && onError(t("StoreSearch:directErrMsg"));
  };

  return (
    <View
      style={[style.mapContainer, props.containerStyle]}
      testID={OrderConstants.OrderStatusTestingId.orderMap}>
      <CardSticker>
        {storeAddress.latitude && storeAddress.longitude && (
          <StaticMap
            lat={Number(storeAddress.latitude)}
            lng={Number(storeAddress.longitude)}
          />
        )}
        <View style={style.mapDescription}>
          <Text style={style.mapDescriptionStoreName}>{storeName}</Text>
          <Text style={Typography.bodyTwo}>
            {storeAddress.streetAddress}, {storeAddress.city},{" "}
            {storeAddress.state} {storeAddress.postcode}
          </Text>
          <View style={style.mapBtnsContainer}>
            <View style={style.detailsBtnContainer}>
              <TertiaryButton
                icon={infoIcon as ImageSourcePropType}
                onClick={navigateToStoreDetails}
                title={t("OrderManagement:moreInfoButton")}
              />
            </View>

            <GetDirections
              latitude={`${storeAddress.latitude}`}
              longitude={`${storeAddress.longitude}`}
              id={storeId}
              name={storeName}
              onError={onDirectionError}
            />
          </View>
        </View>
      </CardSticker>
    </View>
  );
};

export default OrderStatusMap;
