import React, { ReactNode, useEffect } from "react";
import {
  ErrorBoundary,
  FallbackProps,
  useErrorBoundary,
} from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { ErrorModal } from "../Error/ErrorView/ErrorModal";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  // allow user to retry render that failed originally
  const { resetBoundary } = useErrorBoundary();
  const { t } = useTranslation();

  // Report error to sentry
  // Handle failed lazy loading of a JS/CSS chunk
  useEffect(() => {
    // SentryLoggerInstance.sentryCaptureCustomError("Unhandled Error!", error);

    if (error?.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }, [error]);

  return (
    <ErrorModal
      message={t("Error:defaultErrorMessage")}
      onTryAgain={resetBoundary}
      isVisible
    />
  );
};

const CustomErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      // onReset={onReset} TODO: Consider a recovery action in future
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
