/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  AnalyticsInstance,
  // ApiOrderModel,
  MenuActions,
  OrderReduxAction,
  RootState,
  StoreModules,
  StoreReduxAction,
  StoreReduxModels,
  UserReduxAction,
} from "gyg_common";
import { getMenuChannelId } from "gyg_common/redux_store/order/models";
import { basketValueSelector } from "gyg_common/redux_store/order/selectors";

// import { ScreenParams } from "navigation/const";
import Restaurants from "@/views/components/Stores/NotWithDelivery/Restaurants";

// interface RestaurantsParams {
//   [ScreenParams.ID]: string;
// }

// interface ILocationState {
//   previousScreen: string;
//   recentOrdersToAddToCart: ApiOrderModel.BasketItem[];
// }

const RestaurantsContainer: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { locationPermissionGranted, isBrazeInitialised } = useSelector(
    (state: RootState) => state.user
  );
  const basketValue = useSelector(basketValueSelector);

  const { userConfirmedOrderSetup, getOrderResponse, orderCollectionType } =
    useSelector((state: RootState) => state.order);
  const { menuStructure } = useSelector((state: RootState) => state.menu);
  const store = useSelector((s: RootState) => s.store);

  const setMessageToast = useCallback(
    (toastMessage: string | null) => {
      if (!store.error) {
        dispatch(
          UserReduxAction.setMessageToast(
            toastMessage === null
              ? null
              : {
                  showCloseButton: true,
                  title: toastMessage,
                }
          )
        );
      }
    },
    [dispatch, store.error]
  );

  const handleSetStore = useCallback(
    (payload: StoreReduxModels.Store) => {
      batch(() => {
        dispatch(OrderReduxAction.clearOrderSetup());
        dispatch(StoreReduxAction.setStore(payload));
        dispatch(
          MenuActions.getMenuStructure({
            channelId: getMenuChannelId(orderCollectionType),
            storeId: payload.id,
          })
        );
        dispatch(
          StoreReduxAction.setStoreOrderTimeslotsAndOffset({
            storeId: payload.id,
            basketValue,
          })
        );
      });

      if (getOrderResponse && payload.id !== store.selectedStore?.id) {
        dispatch(OrderReduxAction.setOrderResponse(null));
      }
    },
    [
      dispatch,
      getOrderResponse,
      store.selectedStore?.id,
      basketValue,
      orderCollectionType,
    ]
  );

  const handleStoreSearch = useCallback(
    (payload: StoreReduxModels.StoreSearchPayload) =>
      dispatch(StoreReduxAction.storeSearch(payload)),
    [dispatch]
  );

  const handleGooglePlaceDetailSearchSuccess = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      dispatch(StoreReduxAction.googlePlaceDetailSearchSuccess(payload)),
    [dispatch]
  );

  const handleSortStoresByDistance = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      dispatch(StoreReduxAction.sortStoresByDistance(payload)),
    [dispatch]
  );

  const handleSetStoreSearchLocation = useCallback(
    (payload: StoreModules.StoreMapper.StoreCoordinates | null) =>
      dispatch(StoreReduxAction.setStoreSearchLocation(payload)),
    [dispatch]
  );

  const handleSetNearestStoreAsSelected = useCallback(
    (payload: StoreReduxModels.NearestStoreProps) =>
      dispatch(StoreReduxAction.setNearestStoreAsSelected(payload)),
    [dispatch]
  );

  const handleSetStoreInfo = useCallback(
    (payload: StoreReduxModels.Store | null) =>
      dispatch(StoreReduxAction.setStoreInfo(payload)),
    [dispatch]
  );

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "restaurants",
        page_type: "restaurants_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    const onComponentUnmount = () => {
      setMessageToast(null);
    };

    return onComponentUnmount;
  }, [setMessageToast]);

  /**
   * If store id passed in url, gets store object and sets it in the state to display store's details modal.
   */
  useEffect(() => {
    if (id && store.stores) {
      const storeDetailsToShow = StoreModules.StoreUtils.getStoreById(
        parseInt(id),
        store.stores
      );
      if (storeDetailsToShow) {
        dispatch(StoreReduxAction.setStoreInfo(storeDetailsToShow));
      }
    }
  }, [id, store.stores, dispatch]);

  return (
    <Restaurants
      previousScreen={state?.previousScreen}
      recentOrdersToAddToCart={state?.recentOrdersToAddToCart}
      menuStructure={menuStructure}
      locationPermissionGranted={locationPermissionGranted}
      userConfirmedOrderSetup={userConfirmedOrderSetup}
      store={store}
      handleSetMessageToast={setMessageToast}
      handleStoreSearch={handleStoreSearch}
      handleGooglePlaceDetailSearchSuccess={
        handleGooglePlaceDetailSearchSuccess
      }
      handleSortStoresByDistance={handleSortStoresByDistance}
      handleSetStoreInfo={handleSetStoreInfo}
      handleSetStore={handleSetStore}
      handleSetStoreSearchLocation={handleSetStoreSearchLocation}
      handleSetNearestStoreAsSelected={handleSetNearestStoreAsSelected}
    />
  );
};

export default RestaurantsContainer;
