import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";

import { GuestDetailModels, InputType } from "gyg_common";
import { LinkButton } from "gyg_common/components/Buttons/LinkButton";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import FormInput from "gyg_common/components/FormInput";
import colours from "gyg_common/components/styles/colours";
import { Spacing } from "gyg_common/components/styles/number";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  scrollview: {
    flex: 1,
    width: "100%",
  },
  form: {
    paddingHorizontal: Spacing.Light,
  },
  formContainer: {
    backgroundColor: colours.white,
    borderTopColor: colours.lightGrey,
    paddingTop: Spacing.Thin,
  },
  flexGrowScroll: {
    flexGrow: 1,
  },
});

export interface DeliveryContactDetailsProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  isAuthenticated?: boolean;
  firstError: boolean;
  lastError: boolean;
  phoneError: boolean;
  emailError: boolean;
  onLoginPress: () => void;
  onTextChange: (props: GuestDetailModels.GuestDetailsState) => void;
}

export const DeliveryContactDetails: React.FC<DeliveryContactDetailsProps> = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  onLoginPress,
  onTextChange,
  firstError,
  lastError,
  phoneError,
  emailError,
}) => {
  const { t } = useTranslation();

  const [guestLogin, setGuestLogin] = useState<boolean>(true); //SHOW GUEST FORM AS DEFAULT

  useEffect(() => {
    if (!guestLogin) {
      if (
        firstName.length > 0 &&
        lastName.length > 0 &&
        email.length > 0 &&
        phoneNumber.length > 0
      ) {
        setGuestLogin(true);
      }
    }
  }, [firstName, lastName, phoneNumber, email, guestLogin]);

  useEffect(() => {
    if ((firstError || lastError || phoneError || emailError) && !guestLogin) {
      setGuestLogin(true);
    }
  }, [firstError, lastError, phoneError, emailError, guestLogin]);

  const updateProps: GuestDetailModels.GuestDetailsState = {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    email: email,
  };

  const emailHandler = (text: string) => {
    updateProps.email = text;
    onTextChange({ ...updateProps });
  };

  const firstHandler = (text: string) => {
    updateProps.firstName = text;
    onTextChange({ ...updateProps });
  };

  const lastHandler = (text: string) => {
    updateProps.lastName = text;
    onTextChange({ ...updateProps });
  };

  const phoneHandler = (text: string) => {
    updateProps.phoneNumber = text;
    onTextChange({ ...updateProps });
  };

  const onGoAsGuest = () => {
    setGuestLogin(true);
  };

  const guestView = () => {
    return (
      <View
        style={{
          paddingHorizontal: 16,
          paddingBottom: 24,
          alignItems: "center",
          paddingTop: 4,
        }}>
        <SecondaryButton
          onClick={onLoginPress}
          buttonName={t("CheckoutPayment:loginOrRegister")}
          testId='GomexLoginOrRegisterButton'
        />
        <View style={{ paddingTop: 16 }}>
          <LinkButton
            testId={t("LoginRegistration:continueAsGuestShort")}
            buttonName={t("LoginRegistration:continueAsGuestShort")}
            onPress={onGoAsGuest}
            boldText
          />
        </View>
      </View>
    );
  };

  const formView = () => {
    return (
      <View style={styles.form}>
        <FormInput
          name={t("CheckoutPayment:checkoutFirstName")}
          handleChangeText={firstHandler}
          value={firstName}
          error={firstError}
          errorMsg={t("LoginRegistration:nameError")}
          isDisablePadding={!firstError}
        />
        <FormInput
          name={t("CheckoutPayment:checkoutLastName")}
          handleChangeText={lastHandler}
          value={lastName}
          error={lastError}
          errorMsg={t("LoginRegistration:nameError")}
          isDisablePadding={!lastError}
        />
        <FormInput
          name={t("CheckoutPayment:checkoutMobileNum")}
          handleChangeText={phoneHandler}
          value={phoneNumber}
          error={phoneError}
          type={InputType.PHONE}
          errorMsg={t("CheckoutPayment:checkoutMobileErr")}
          isDisablePadding={!phoneError}
        />
        <FormInput
          name={t("CheckoutPayment:checkoutEmail")}
          handleChangeText={emailHandler}
          value={email}
          type={InputType.EMAIL}
          errorMsg={t("CheckoutPayment:checkoutEmailErr")}
          error={emailError}
          isDisablePadding={!emailError}
        />
      </View>
    );
  };

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        keyboardVerticalOffset={Platform.OS === "ios" ? 40 : 0}
        style={styles.container}>
        <ScrollView
          style={styles.scrollview}
          contentContainerStyle={styles.flexGrowScroll}>
          <View style={styles.formContainer}>
            {!guestLogin ? (
              guestView()
            ) : Platform.OS === "web" ? (
              <form style={{ display: "contents" }} autoComplete='on'>
                {formView()}
              </form>
            ) : (
              formView()
            )}
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  );
};
