import { Platform } from "react-native";
import StyleSheet from "react-native-media-query";

import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export const { ids, styles } = StyleSheet.create({
  outer: {
    paddingBottom: Spacing.Thin,
    paddingHorizontal: Platform.OS !== "web" ? Spacing.Light : 0,

    "@media only screen and (max-width: 48em)": {
      paddingHorizontal: Spacing.Light,
      paddingVertical: Spacing.Thin,
    },
  },
  titleWrapper: {
    paddingHorizontal: Spacing.Light,
    paddingTop: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  rewardsWrapper: {
    paddingHorizontal: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  header: {
    backgroundColor: colours.black,
  },
  rewardItemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Spacing.Thin,
    marginTop: Platform.OS === "web" ? Spacing.Thin : 0,
  },
  rewardsText: {
    ...Typography.titleThree,
    paddingRight: Spacing.Thin,
  },
  topSpace: {
    paddingTop: Spacing.Light,
  },
  errorMsg: {
    ...Typography.captionOne,
    minHeight: Spacing.Light,
    marginTop: Spacing.ExtraThin,
    color: colours.red,
  },
});
