import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  ApiUpsellModel,
  CartReduxAction,
  CheckoutAction,
  CheckoutModels,
  locales,
  LoyaltyActions,
  MenuActions,
  ModuleEnum,
  NavigationReduxAction,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  PromoReduxAction,
  RootState,
  StoreReduxAction,
  UpsellReduxActions,
  UserReduxAction,
} from "gyg_common";
import errorImage from "gyg_common/components/assets/images/illustration_error.png";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { basketValueSelector } from "gyg_common/redux_store/order/selectors";
import { getUserLocation } from "modules/location";
import { updateLocalPriceWithOrderResponse } from "modules/utils";
import { Screens } from "navigation/const";

import { useBrazeContentCards } from "../../../hooks/useBrazeContentCards";
import Dashboard from "../../components/Dashboard";
import PromotionsModalContainer from "../Promotions/PromotionsModalContainer";

import { OrderUtils } from "@/../../packages/common/modules/Order";

const DashboardContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { locationPermissionGranted, isBrazeInitialised } = useSelector(
    (state: RootState) => state.user
  );

  const { selectedStore, getStoresLoading, stores } = useSelector(
    (state: RootState) => state.store
  );
  const { isAuthSuccess, currentUser } = useSelector(
    (state: RootState) => state.login
  );
  const { orderCollectionType } = useSelector(
    (state: RootState) => state.order
  );
  const { items } = useSelector((state: RootState) => state.cart);
  const basketValue = useSelector(basketValueSelector);

  const {
    recentOrders,
    currentOrders,
    userConfirmedOrderSetup,
    orderTime,
    orderInitialTime,
    getOrderResponse,
  } = useSelector((state: RootState) => state.order);
  const { favourites } = useSelector((state: RootState) => state.favourite);

  const { loyalty, rewards, coffeeLoyalty, coffeeLoyaltyLoading, isLoading } =
    useSelector((state: RootState) => state.loyalty);
  const { localGyg } = useSelector((state: RootState) => state.analytics);

  const guestOrders = useSelector((state: RootState) => state.guest.orders);
  const analyticsState = useSelector((state: RootState) => state.analytics);

  const { isPromotionsModalVisible } = useSelector((s: RootState) => s.promo);

  const [showAbandonOrder, setAbandonOrder] = useState<boolean>(false);
  const [guestRecentOrders, setGuestRecentOrders] = useState<
    CheckoutModels.CheckoutResponse[]
  >([]);
  const [guestCurrentOrders, setGuestCurrentOrders] = useState<
    CheckoutModels.CheckoutResponse[]
  >([]);

  const [selectedNearestStore, setSelectedNearestStore] = useState(false);
  const timeSlotsLoading = useRef(false);

  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const hasCoffee = useFeatureIsOn(FeatureFlags.COFFEE_LOYALTY as string);

  const promotions = useBrazeContentCards(isBrazeInitialised);
  const availablePromos = useSelector(
    (state: RootState) => state.promo.availablePromos
  );

  const onOrderClicked = useCallback(() => {
    if (pathname === Screens.Dashboard) {
      dispatch(NavigationReduxAction.setOrderSetupNavigation(Screens.Menu));
      if (items.length > 0) {
        setAbandonOrder(true);
      } else if (
        (selectedStore &&
          !userConfirmedOrderSetup &&
          !orderTime &&
          orderCollectionType == OrderReduxModels.CollectionType.PICK_UP) ||
        (selectedStore &&
          OrderUtils.shouldConfirmOrderTime(orderInitialTime) &&
          orderCollectionType == OrderReduxModels.CollectionType.PICK_UP)
      ) {
        dispatch(NavigationReduxAction.setOpenOrderSetup(true));
      } else if (selectedStore && userConfirmedOrderSetup && orderTime) {
        navigate(Screens.Menu);
      } else {
        if (hasDelivery) {
          dispatch(NavigationReduxAction.setOpenOrderSetup(true));
        } else {
          navigate(Screens.Restaurants, {
            state: {
              previousScreen: pathname,
            },
          });
        }
      }
    } else {
      navigate(Screens.Menu);
    }
  }, [
    orderCollectionType,
    dispatch,
    navigate,
    orderInitialTime,
    orderTime,
    pathname,
    selectedStore,
    userConfirmedOrderSetup,
    hasDelivery,
    items,
  ]);

  const keepCart = useCallback(() => {
    setAbandonOrder(false);
    if (selectedStore && OrderUtils.shouldConfirmOrderTime(orderInitialTime)) {
      dispatch(
        NavigationReduxAction.setNavigationState({
          showOrderSetup: true,
          orderSetupNavigation: Screens.Menu,
        })
      );
    } else if (userConfirmedOrderSetup) {
      navigate(Screens.Menu);
    } else {
      if (selectedStore) {
        dispatch(
          NavigationReduxAction.setNavigationState({
            showOrderSetup: true,
            orderSetupNavigation: Screens.Menu,
          })
        );
      } else {
        if (hasDelivery) {
          dispatch(NavigationReduxAction.setOpenOrderSetup(true));
        } else {
          navigate(Screens.Restaurants);
        }
      }
    }
  }, [
    dispatch,
    navigate,
    orderInitialTime,
    selectedStore,
    userConfirmedOrderSetup,
    hasDelivery,
  ]);

  const abandonOrder = useCallback(() => {
    setAbandonOrder(false);
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.AbandonedCart,
      AnalyticsPayloadGenerator.abandonCartPayload(analyticsState)
    );

    dispatch(CartReduxAction.clearCart());
    dispatch(OrderReduxAction.setOrderResponse(null));
    if (hasCoffee) dispatch(LoyaltyActions.getUserCoffeeLoyalty());
    if (hasDelivery) {
      dispatch(NavigationReduxAction.setOpenOrderSetup(true));
    } else {
      keepCart();
    }
  }, [analyticsState, dispatch, hasCoffee, hasDelivery, keepCart]);

  const [userLocation, setUserLocation] =
    useState<GeolocationCoordinates | null>(null);

  const goToLogin = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.LoginOrJoin, {
      screen_origin: AnalyticsConst.LoginOrJoinSource.Homepage,
    });
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Dashboard } });
  };

  const goToCoffee = useCallback(() => {
    if (userConfirmedOrderSetup) {
      navigate(Screens.Menu);
    } else {
      if (hasDelivery) {
        dispatch(NavigationReduxAction.setOpenOrderSetup(true));
      } else {
        navigate(Screens.Restaurants);
      }
    }
    dispatch(MenuActions.needScrollToHotDrinks(true));
  }, [dispatch, navigate, userConfirmedOrderSetup, hasDelivery]);

  useEffect(() => {
    dispatch(CheckoutAction.resetPayment());
    dispatch(
      UpsellReduxActions.getUpsellFromConfig(ApiUpsellModel.UpsellType.PRECART)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.Dashboard, {
        local_gyg: selectedStore?.name,
        logged_in: isAuthSuccess,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrazeInitialised]);

  useEffect(() => {
    if (localGyg && isAuthSuccess && isBrazeInitialised) {
      AnalyticsInstance.setCustomAttribute(
        AnalyticsConst.CustomAttribute.LastClosestStore,
        localGyg
      );
    }
  }, [isAuthSuccess, isBrazeInitialised, localGyg]);

  useEffect(() => {
    if (promotions.length > 0) {
      dispatch(PromoReduxAction.getStoreOffers());
    }
  }, [promotions, dispatch]);

  /**
   * Retrieves the user's location if permission is granetd
   * needed for next useEffect -> setting selected store
   */
  useEffect(() => {
    if (locationPermissionGranted) {
      getUserLocation()
        .then((location) => {
          dispatch(
            UserReduxAction.setLocation({
              latitude: location.coords.latitude,
              longitude: location.coords.longitude,
            })
          );
          setUserLocation(location.coords);
        })
        .catch(() => {
          dispatch(UserReduxAction.setLocationPermission(false));
        });
    }
  }, [dispatch, locationPermissionGranted]);

  /** Sort the stores by distance to the user and set the nearest store as selected by default */
  useEffect(() => {
    if (!getStoresLoading && !userConfirmedOrderSetup) {
      if (
        orderCollectionType != OrderReduxModels.CollectionType.TABLE_SERVICE &&
        userLocation &&
        userLocation.latitude &&
        userLocation.longitude
      ) {
        dispatch(
          StoreReduxAction.sortStoresByDistance({
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
            version: config.version,
          })
        );
        if (!hasDelivery) {
          let nearestStoreThresholdKm: number =
            config.locationConfig.nearestStoreThresholdKm;

          if (config.version === locales.US && stores.length === 1) {
            nearestStoreThresholdKm = 0;
          }

          timeSlotsLoading.current = false;
          dispatch(
            StoreReduxAction.setNearestStoreAsSelected({
              nearestStoreThresholdKm: nearestStoreThresholdKm,
            })
          );
          setSelectedNearestStore(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    userLocation,
    userConfirmedOrderSetup,
    getStoresLoading,
    stores.length,
    hasDelivery,
  ]);

  //Fetch timeslots for auto selected store by location
  useEffect(() => {
    if (
      selectedNearestStore &&
      selectedStore &&
      !hasDelivery &&
      !timeSlotsLoading.current
    ) {
      setSelectedNearestStore(false);
      timeSlotsLoading.current = true;
      dispatch(
        StoreReduxAction.selectStoreById({ storeId: String(selectedStore.id) })
      );
      dispatch(
        StoreReduxAction.setStoreOrderTimeslotsAndOffset({
          storeId: selectedStore.id,
          basketValue,
        })
      );
    }
  }, [basketValue, dispatch, selectedNearestStore, selectedStore, hasDelivery]);

  useEffect(() => {
    setGuestRecentOrders([]);
    setGuestCurrentOrders([]);
    const localRecentOrders: CheckoutModels.CheckoutResponse[] = [];
    const localCurrentOrders: CheckoutModels.CheckoutResponse[] = [];

    if (guestOrders && guestOrders.length > 0) {
      guestOrders.forEach((data) => {
        if (OrderModules.OrderUtils.isOlderThanActiveOrderThreshold(data)) {
          localRecentOrders.push(data);
        } else {
          localCurrentOrders.push(data);
        }
      });
    }

    if (localRecentOrders.length) {
      setGuestRecentOrders(
        OrderModules.OrderUtils.sortOrderByTime(
          localRecentOrders,
          ModuleEnum.LodashSortingType.DESC
        )
      );
    }

    if (localCurrentOrders.length) {
      setGuestCurrentOrders(
        OrderModules.OrderUtils.sortOrderByTime(
          localCurrentOrders,
          ModuleEnum.LodashSortingType.DESC
        )
      );
    }
  }, [guestOrders]);

  useEffect(() => {
    updateLocalPriceWithOrderResponse(getOrderResponse);
  }, [getOrderResponse]);

  const onLoyaltyRetry = useCallback(() => {
    dispatch(LoyaltyActions.getUserLoyalty());
  }, [dispatch]);

  return (
    <>
      <PromotionsModalContainer isVisible={isPromotionsModalVisible} />

      <ModalWithButton
        isVisible={showAbandonOrder}
        title={t("CheckoutPayment:abandonTitle")}
        messageBold={t("CheckoutPayment:abandonLabel")}
        primaryButton={{
          name: t("CheckoutPayment:abandonDelButton").toUpperCase(),
          action: abandonOrder,
        }}
        secondaryButton={{
          name: t("CheckoutPayment:continueButton"),
          action: keepCart,
        }}
        onModalClose={() => setAbandonOrder(false)}
        image={errorImage as ImageSourcePropType}
        small={true}
        modalId='dashboard-save-prompt'
      />

      <Dashboard
        loyaltyLoading={coffeeLoyaltyLoading || isLoading}
        favourites={favourites}
        rewards={rewards}
        coffeeLoyalty={coffeeLoyalty}
        promotions={promotions}
        availablePromos={availablePromos}
        userConfirmedOrderSetup={userConfirmedOrderSetup}
        loyalty={loyalty ?? undefined}
        locationPermission={locationPermissionGranted}
        selectedStore={selectedStore}
        isUserAuthenticated={isAuthSuccess}
        currentUser={currentUser}
        currentOrders={isAuthSuccess ? currentOrders : guestCurrentOrders}
        recentOrders={isAuthSuccess ? recentOrders : guestRecentOrders}
        versions={{ configV: config.version, packageV: config.packageVersion }}
        goToLogin={goToLogin}
        goToMyGomex={() => navigate(Screens.MyGomex)}
        goToRestaurants={() => {
          navigate(Screens.Restaurants);
        }}
        goToRecentOrders={() => navigate(Screens.RecentOrders)}
        goToCoffee={goToCoffee}
        viewCartClick={onOrderClicked}
        onLoyaltyRetry={onLoyaltyRetry}
      />
    </>
  );
};

export default DashboardContainer;
