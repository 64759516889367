import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertButton,
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";

import {
  CartReduxModels,
  FavouriteModels,
  FavouriteModules,
  FavouriteReduxAction,
  RootState,
} from "../..";
import heartImg from "../../components/assets/icons/icon_heart.png";
import heartFilledImg from "../../components/assets/icons/icon_heart_filled.png";
import SecondaryButton from "../Buttons/SecondaryButton";
import MessageToast from "../MessageToast";
import colours from "../styles/colours";
import { IconSize, Spacing } from "../styles/number";
import ModalWithInput from "./ModalWithInput";

const alertPolyfill = (
  title: string,
  description?: string,
  buttons: AlertButton[] = []
) => {
  const result = window.confirm(
    [title, description].filter(Boolean).join("\n")
  );
  if (result) {
    const confirmOption = buttons.find(({ style }) => style !== "cancel");
    if (confirmOption && confirmOption.onPress) {
      confirmOption.onPress();
    }
  } else {
    const cancelOption = buttons.find(({ style }) => style === "cancel");
    if (cancelOption && cancelOption.onPress) {
      cancelOption.onPress();
    }
  }
};

const alert = Platform.OS === "web" ? alertPolyfill : Alert.alert;

const rightIconStyle = (enabled: boolean) =>
  StyleSheet.create({
    rightIcon: {
      marginLeft: Spacing.ExtraThin,
      width: IconSize.Regular,
      height: IconSize.Regular,
      tintColor: enabled ? undefined : colours.uiGrey,
    },
  });

const styles = StyleSheet.create({
  favouriteButtonContainer: {
    paddingTop: Spacing.Light,
  },
});

export interface SaveAsFavouriteInputAndModalProps {
  categoryName: string;
  cartItems?: CartReduxModels.CartItem[] | undefined;
}

export const SaveAsFavouriteInputAndModal: React.FC<
  SaveAsFavouriteInputAndModalProps
> = ({ categoryName, cartItems = [] }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const [isFavourited, setIsFavourited] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [renameError, setRenameError] = useState<string>("");
  const [renameValue, setRenameValue] = useState<string>(categoryName);
  const {
    isCRUDFavouriteError,
    crudFavouriteErrorMessage,
    favouriteCartItems,
  } = useSelector((s: RootState) => s.favourite);
  const favouriteButtonEnabled = cartItems.length > 0;

  useEffect(() => {
    if (favouriteCartItems?.length == 0 || !favouriteCartItems) {
      setIsFavourited(false);
    } else {
      setIsFavourited(
        favouriteCartItems?.length > 0 && cartItems?.length > 0
          ? FavouriteModules.isFavouriteItem(cartItems[0], favouriteCartItems)
          : false
      );
    }
  }, [cartItems, favouriteCartItems]);

  useEffect(() => {
    if (renameValue?.length && renameError?.length) {
      setRenameError("");
    }
  }, [renameValue, renameError]);

  const onCreateORUpdateFavourite = (type: string) => {
    if (type === FavouriteModels.CRUDType.add) {
      if (cartItems?.length > 0) {
        setRenameValue(cartItems[0].name);
      }
      setShowRenameModal(true);
    } else {
      alert(
        t("Favourite:favouritedRemoveTitle"),
        t("Favourite:favouritedRemoveMessage"),
        [
          {
            text: t("CheckoutPayment:cancelDeletionButton").toUpperCase(),
            onPress: () => {},
            style: "cancel",
          },
          {
            text: t("Favourite:favouritedRemoveButton").toUpperCase(),
            onPress: () => {
              setIsFavourited(false);
              const favouriteItemId = FavouriteModules.getFavouriteIdLabel(
                cartItems[0],
                favouriteCartItems
              );

              // TODO: need to check why favouriteCartItems does not exist
              // because favouriteCartItem should exist if this item is going to be deleted.
              if (favouriteItemId) {
                dispatch(
                  FavouriteReduxAction.deleteFavourite(
                    favouriteItemId?.favouriteId
                  )
                );
              }
            },
          },
        ]
      );
    }
  };

  const onRenameSavePress = useCallback(() => {
    if (renameValue.length > 0) {
      dispatch(
        FavouriteReduxAction.createFavourite(
          FavouriteModules.addFavouritePayload(
            cartItems[0],
            renameValue
          ) as FavouriteModels.AddFavouriteRequest
        )
      );
      setIsFavourited(true);
      setShowRenameModal(false);
    } else {
      setRenameError(t("Favourite:nameError"));
    }
  }, [cartItems, dispatch, renameValue, t]);

  const onResetFavouriteStatus = () => {
    dispatch(FavouriteReduxAction.resetStatus());
  };

  const onFavourite = () => {
    onCreateORUpdateFavourite(
      isFavourited
        ? FavouriteModels.CRUDType.delete
        : FavouriteModels.CRUDType.add
    );
  };

  return isAuthSuccess ? (
    <View>
      <View style={styles.favouriteButtonContainer}>
        <SecondaryButton
          disable={!favouriteButtonEnabled}
          buttonName={
            isFavourited
              ? t("Favourite:favouritedButton")
              : t("Favourite:saveAsFavouriteButton")
          }
          onClick={onFavourite}
          rightIcon={
            <Image
              source={
                (isFavourited
                  ? heartFilledImg
                  : heartImg) as ImageSourcePropType
              }
              style={rightIconStyle(favouriteButtonEnabled).rightIcon}
            />
          }
        />
      </View>

      <ModalWithInput
        isVisible={showRenameModal}
        primaryButton={{
          name: t("Favourite:saveButton"),
          action: onRenameSavePress,
        }}
        title={t("Favourite:nameTitle")}
        message={t("Favourite:renameBody")}
        inputLabel={t("Favourite:renameLabel")}
        inputValue={renameValue}
        onTextChange={setRenameValue}
        onModalClose={() => setShowRenameModal(false)}
        isInputError={!!renameError.length}
        inputErrorMessage={renameError}
        useOnResponderGrant={Platform.OS === "web"}
      />

      {isCRUDFavouriteError && isAuthSuccess && (
        <MessageToast
          isShowClose={isCRUDFavouriteError}
          title={crudFavouriteErrorMessage}
          onClose={onResetFavouriteStatus}
        />
      )}
    </View>
  ) : null;
};
