import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AnalyticsConst,
  AnalyticsInstance,
  GuestReduxAction,
  RootState,
} from "gyg_common";
import { Screens } from "navigation/const";
import GuestDetail from "views/components/GuestDetail";

const GuestDetailContainer: React.FC = () => {
  const { firstName, lastName, phoneNumber, email } = useSelector(
    (s: RootState) => s.guest
  );
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const { getOrderResponse, delivery } = useSelector((s: RootState) => s.order);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * After sucessful login should navigate to order
   * 'cuz it needs new orderResponse for authenticated user to proceed to payment.
   */
  const onLoginClick = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.LoginOrJoin, {
      screen_origin: AnalyticsConst.LoginOrJoinSource.GuestDetails,
    });
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Order } });
  };

  useEffect(() => {
    if (!getOrderResponse) {
      navigate(Screens.Order);
    }
  }, [getOrderResponse, navigate]);

  useEffect(() => {
    if (isAuthSuccess) {
      navigate(Screens.Order);
    }
  }, [navigate, isAuthSuccess]);

  return (
    <GuestDetail
      firstName={firstName}
      lastName={lastName}
      phoneNumber={phoneNumber}
      email={email}
      deliveryFee={delivery?.fee}
      order={getOrderResponse}
      onTextChange={(props) =>
        dispatch(GuestReduxAction.updateGuestDetails(props))
      }
      onGoPayment={() => navigate(Screens.Payment)}
      onLoginClick={onLoginClick}
    />
  );
};

export default GuestDetailContainer;
