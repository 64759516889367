import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CartReduxAction,
  MenuModels,
  ProductConst,
  ProductUtils,
  RootState,
} from "gyg_common";
import { UpsellSource } from "gyg_common/modules/Upsell/model";
import { trackUpsellItem } from "gyg_common/modules/Upsell/utils";
import { Screens } from "navigation/const";
import ProductModal from "views/components/Products/ProductModal";
import { Upsell } from "views/components/Upsell/Upsell";

const UpsellContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((s: RootState) => s.login);
  const { currentOrderId } = useSelector((s: RootState) => s.order);
  const { menuStructure } = useSelector((s: RootState) => s.menu);
  const { upsellItems } = useSelector((s: RootState) => s.upsell);
  const { mobileAppConfig } = useSelector((s: RootState) => s.dashboard);

  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProductType, setSelectedProductType] =
    useState<ProductConst.ProductRoute>(
      ProductConst.ProductRoute.SimpleCustomisable
    );
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    MenuModels.Category | MenuModels.Product | MenuModels.MultiPart
  >();

  const onNoThanksPress = () => {
    navigate(Screens.Order, { replace: true });
  };

  const onItemPress = (item: MenuModels.MenuListItemProps) => {
    trackUpsellItem({
      userId: currentUser?.brazeId ?? null,
      basketId: currentOrderId,
      upsellItems: [item],
      source: UpsellSource.PreCart,
    });

    if (item.category) {
      const productRoute = ProductUtils.getProductRoute(
        item.category?.templateId as string
      );
      if (productRoute) {
        dispatch(CartReduxAction.clearMIAMItem());
        setSelectedProductType(productRoute);
        setSelectedMenuItem(item.category);
        setShowProductModal(true);
      }
    } else if (item.multiPart) {
      dispatch(CartReduxAction.clearBundleItem());
      setSelectedProductType(ProductConst.ProductRoute.Bundles);
      setSelectedMenuItem(item.multiPart);
      setShowProductModal(true);
    } else {
      setSelectedProductType(ProductConst.ProductRoute.NonCustomization);
      setSelectedMenuItem(item.product);
      setShowProductModal(true);
    }
  };

  useEffect(() => {
    if (!upsellItems || !menuStructure) {
      navigate(Screens.Dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsellItems, menuStructure]);

  return (
    <>
      <Upsell
        showNutritionInfo={mobileAppConfig?.showNutritionalInformation}
        upsellItems={upsellItems}
        onNoThanksPress={onNoThanksPress}
        onItemPress={onItemPress}
      />
      <ProductModal
        isUpsell={true}
        commonSection={menuStructure?.commonSections.find(
          (n) =>
            n.id ===
            (selectedMenuItem as MenuModels.Category)?.commonSection?.[0]
        )}
        editMode={ProductConst.EditMode.ADD_CART_ITEM}
        productType={selectedProductType}
        isVisible={showProductModal}
        onClose={() => {
          setShowProductModal(false);
        }}
        category={selectedMenuItem}
      />
    </>
  );
};

export default UpsellContainer;
