import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { StyleSheet, Text, View } from "react-native";

import colours from "styles/colours";

const style = StyleSheet.create({
  surcharges: {
    backgroundColor: colours.starburst,
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },

  surchargesText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 12,
    flexWrap: "wrap",
    textAlign: "center", // todo deskcheck options
  },
});
export interface SurchargesProps {
  surcharges?: string[];
}

export const Surcharges: React.FC<SurchargesProps> = ({
  surcharges,
}: SurchargesProps) => {
  const { isDesktopScreen } = useMediaQuery();

  const renderSurcharges = () => {
    if (!!surcharges?.length) {
      const surchargesCombined = surcharges.join("\n ");

      if (isDesktopScreen) {
        return <Text style={style.surchargesText}>{surchargesCombined}</Text>;
      } else {
        return surcharges.map((surcharge, key) => (
          <View key={key}>
            <Text style={style.surchargesText}>{surcharge}</Text>
          </View>
        ));
      }
    }
  };

  return (
    <div className='surcharges-container'>
      <View style={style.surcharges}>{renderSurcharges()}</View>
    </div>
  );
};

export default Surcharges;
