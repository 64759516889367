import "./menu.scss";
import "styles/swiper.scss";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ErrorReduxModel,
  MenuModels,
  MenuModuleUtils,
  RootState,
} from "gyg_common";
import ErrorView from "gyg_common/components/Error/ErrorView";
import MenuListItem from "gyg_common/components/Menu/MenuListItem";
import MenuListItemTile from "gyg_common/components/Menu/MenuListItemTile";
import { MenuListItemProps } from "gyg_common/redux_store/menu/models";
import { CollectionType } from "gyg_common/redux_store/order/models";
import { getComponentHeightInPx } from "styles/utils";
import { FavouriteContainer } from "views/containers/Favourites/FavouriteContainer";
import StickyBannerContainer from "views/containers/Menu/StickyBannerContainer";
import OrderSetupHeaderContainer from "views/containers/OrderSetupHeader/OrderSetupHeaderContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import FeaturedItemCarousel from "./FeaturedItem/FeaturedItemCarousel";
import TabMenu from "./TabMenu";

export interface MenuProps {
  showNutritionInfo?: boolean;
  validMenuSection: MenuModels.MenuSection | undefined;
  branchSection?: string;
  menuStructure?: MenuModels.MenuStructure;
  menuSectionEnabled: number[];
  activeTab: string;
  isLoggedUser: boolean;
  menuListData: MenuModels.MenuListData[];
  featuredItems: MenuModels.FeaturedItemType[];
  error: ErrorReduxModel.ErrorResponse;
  onFeaturedItemPress: (item: MenuModels.FeaturedItemType) => void;
  setActiveTab(newActiveTab: string): void;
  onMenuItemClicked: (menuListItem: MenuModels.MenuListItemProps) => void;
  onClearBranchState(): void;
}

const Menu: React.FC<MenuProps> = ({
  showNutritionInfo,
  validMenuSection,
  branchSection,
  menuStructure,
  menuSectionEnabled,
  menuListData,
  activeTab,
  isLoggedUser,
  featuredItems,
  error,
  onFeaturedItemPress,
  setActiveTab,
  onMenuItemClicked,
  onClearBranchState,
}) => {
  const { t } = useTranslation();
  const { orderCollectionType } = useSelector((s: RootState) => s.order);

  /**
   *  blockScrollToUpdateActiveTab is local state used to enable/disable scrolling to update the active tab functionality.
   *  Initially set to false to enable menu scrolling to update the active tab.
   *  Set to true when a tab is pressed to disable activeTab update and so circular update loop is prevented.
   */
  const blockManualScrollTabUpdate = useRef(false);
  const menuListObserver = useRef<IntersectionObserver>();

  const [tabNames, setTabNames] = useState<string[]>([]);
  const [headerHeight, setHeaderHeight] = useState<number>();
  const autoScrollAttempts = useRef(0);

  /** Initially set all menu section visibilities to false. */
  const menuSectionVisibilities: {
    name: string;
    elementId: string;
    visible: boolean;
  }[] = [];

  /**
   * Auto scroll to desired section set by branch deep linking
   */
  useEffect(() => {
    if (
      branchSection &&
      menuListData &&
      menuListData.length &&
      validMenuSection
    ) {
      const subSectionTitle = MenuModuleUtils.getSubSectionTitleById(
        Number(branchSection),
        validMenuSection.subSections
      );

      if (subSectionTitle) {
        const subSection = subSectionTitle + `-${Number(branchSection)}`;
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          autoScrollToSelectedMenuSection(subSection, true);
        }, 300);

        setActiveTab(subSection);

        //The page layout changes several times(adding Favourite section)
        //after the first autoscroll
        autoScrollAttempts.current += 1;
        if (autoScrollAttempts.current > 2) onClearBranchState();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSection, menuListData.length, validMenuSection]);

  /**
   * Calculate which is the top most AND visible section to update as the 'active' tab
   * Runs on new intersection events. Blocked if auto scroll is currently occuring.
   */
  const handleIntersectAndUpdateActiveTab = (
    entries: IntersectionObserverEntry[]
  ) => {
    if (blockManualScrollTabUpdate.current === false) {
      let firstVisibleSection:
        | {
            name: string;
            elementId: string;
            visible: boolean;
          }
        | undefined;

      entries.forEach((entry: IntersectionObserverEntry) => {
        const newMenuSectionVisibility = entry.isIntersecting;
        const menuSectionVisibilityIndexToUpdate =
          menuSectionVisibilities.findIndex(
            (menuSectionVisibility) =>
              menuSectionVisibility.elementId === entry.target.id
          );
        menuSectionVisibilities[menuSectionVisibilityIndexToUpdate].visible =
          newMenuSectionVisibility;

        firstVisibleSection = menuSectionVisibilities.find(
          (menuSectionVisibility) => menuSectionVisibility.visible === true
        );
      });
      if (firstVisibleSection && firstVisibleSection.name !== activeTab) {
        setActiveTab(firstVisibleSection.name);
      }
    }
  };

  // Handler function passed into the TabMenu to block the menu scroll from updating the active tab
  const blockActiveTabScrollUpdate = (value: boolean) => {
    blockManualScrollTabUpdate.current = value;
  };

  useEffect(() => {
    if (menuStructure) {
      const allTabNames: string[] = [];
      menuSectionEnabled.forEach((n) => {
        menuStructure.sections[n].subSections.forEach((subsection) => {
          allTabNames.push(`${subsection.title}-${subsection.id}`);
        });
      });
      setTabNames(allTabNames);
    }
  }, [menuStructure, menuSectionEnabled]);

  /** This effect is to set up an intersection observer for the menu sections.
   * This will observe when menu sections are shown and not shown and update the
   * active tab as the user scrolls.
   */
  useEffect(() => {
    const menuSectionElements: HTMLElement[] = [];

    tabNames.forEach((tabName: string) => {
      const menuSectionElement = document.getElementById(
        `MenuSection-${tabName}`
      );

      if (menuSectionElement) {
        menuSectionElements.push(menuSectionElement);
        menuSectionVisibilities.push({
          name: tabName,
          elementId: menuSectionElement.id,
          visible: true,
        });
      }
    });

    const menuListObserverOptions = {
      root: null, // defaults to the entire window as the root element
      rootMargin: `-${getComponentHeightInPx("header") + 50}px 0px 0px 0px`, // top threshold is 50px below the header
      threshold: [0, 1],
    };
    menuListObserver.current = new IntersectionObserver(
      handleIntersectAndUpdateActiveTab,
      menuListObserverOptions
    );

    menuSectionElements.forEach((menuSectionElement) => {
      menuListObserver.current?.observe(menuSectionElement);
    });

    return () => {
      menuSectionElements.forEach((menuSectionElement) => {
        menuListObserver.current?.unobserve(menuSectionElement);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabNames]);

  /**
   * Adds listener to check when auto scroll finishes.
   * Cleans up listener when finished.
   * @param menuListWrapperElement
   * @param position
   */
  const removeManualScrollBlockOnScrollFinish = (
    menuListWrapperElement: HTMLElement,
    position: number
  ) => {
    const listener = () => {
      if (menuListWrapperElement.scrollTop === position) {
        blockManualScrollTabUpdate.current = false; // Unblock or re enable manual scrolling to update the active tab
        window.removeEventListener("scroll", listener); // self clean up of event listener
      }
    };
    window.addEventListener("scroll", listener);
  };

  /**
   * Automatically scrolls the menu list to the selected tab section.
   * Called when a tab is clicked in the tab menu.
   * Blocks manual scroll observer from updating the active tab while auto scroll is completing,
   *  then re-enabels when finished.
   */
  function autoScrollToSelectedMenuSection(
    selectedTabName: string,
    fromTabClick: boolean
  ) {
    if (fromTabClick) {
      blockManualScrollTabUpdate.current = true;
    }
    const headerOffset = getComponentHeightInPx("header");
    const menuListWrapperElement = document.getElementById("menu-list-wrapper");
    const sectionHeadingElement = document.getElementById(
      `MenuSection-${selectedTabName}`
    );
    const disclaimerHeight = getComponentHeightInPx("sticky-banner");

    if (menuListWrapperElement && sectionHeadingElement) {
      const marginAdjustment = disclaimerHeight ?? 12;
      const menuListScroll =
        sectionHeadingElement.offsetTop - headerOffset - marginAdjustment;

      window.scrollTo({ top: menuListScroll, behavior: "smooth" });
      removeManualScrollBlockOnScrollFinish(
        document.documentElement,
        menuListScroll
      );
    }
  }

  // needs 0.1s delay as header img is initially stretching header when resizing happens and fn catches the wrong height number
  const updateHeaderHeight = useCallback(() => {
    setTimeout(() => {
      const headerOffset = getComponentHeightInPx("header");
      if (headerOffset) {
        setHeaderHeight(headerOffset);
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerHeight]);

  useEffect(() => {
    updateHeaderHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuListObserver.current]);

  const handlePressMap = useMemo(() => new Map(), []);

  const handlePress = useCallback(
    (item: MenuListItemProps) => {
      if (!handlePressMap.has(item)) {
        handlePressMap.set(item, () => onMenuItemClicked(item));
      }
      return handlePressMap.get(item);
    },
    [onMenuItemClicked, handlePressMap]
  );

  useEffect(() => {
    window.addEventListener("resize", updateHeaderHeight);

    return () => window.removeEventListener("resize", updateHeaderHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupMenuItems = (
    items: MenuModels.MenuListItemProps[],
    groupBy: number
  ) =>
    items.reduce(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        previousValue: any,
        currentValue: MenuModels.MenuListItemProps,
        currentIndex: number
      ) => {
        const idx = Math.floor(currentIndex / groupBy);
        previousValue[idx] = [...(previousValue[idx] || []), currentValue];
        return previousValue;
      },
      []
    );

  return (
    <div className='menu'>
      <div className='menu__header'>
        <HeaderContainer
          viewCart={true}
          bottomComponentNotScrollable={<OrderSetupHeaderContainer />}
          bottomComponentScrollable={
            <TabMenu
              tabNames={
                menuListData
                  ? menuListData.map((listData) => {
                      return { name: listData.title, id: listData.id };
                    })
                  : []
              }
              activeTab={activeTab}
              scrollToSelectedMenuSection={autoScrollToSelectedMenuSection}
              blockActiveTabScrollUpdate={blockActiveTabScrollUpdate}
            />
          }
        />
      </div>

      {!error ? (
        <>
          <StickyBannerContainer
            showSurcharges={orderCollectionType !== CollectionType.DELIVERY}
            position={headerHeight ? headerHeight : 204}
            showNutritionInfo={showNutritionInfo}
          />

          {/** Menu List */}
          <div
            id='menu-list-wrapper'
            data-testid='MenuSectionList'
            className='menu__wrapper'>
            <div
              id='menu-list-content-wrapper'
              style={
                getComponentHeightInPx("sticky-banner")
                  ? { paddingTop: getComponentHeightInPx("sticky-banner") }
                  : {}
              }
              className='menu__container main-wrapper'>
              <div>
                {featuredItems.length > 0 && (
                  <FeaturedItemCarousel
                    onItemPress={onFeaturedItemPress}
                    featuredItems={featuredItems}
                  />
                )}
              </div>
              {menuListData &&
                menuListData.map((subsection) => {
                  return (
                    <div
                      className='menu__section'
                      id={`MenuSection-${subsection.title}-${subsection.id}`}
                      data-testid={`MenuSection-${subsection.title}-${subsection.id}`}
                      key={`MenuSection-${subsection.title}-${subsection.id}`}>
                      <div className='menu__section-heading'>
                        {subsection.title}
                      </div>
                      {subsection.title === t("Favourite:title") &&
                      isLoggedUser ? (
                        <FavouriteContainer />
                      ) : (
                        <div className='menu__section-wrapper'>
                          <div>
                            {groupMenuItems(subsection.data, 3).map(
                              (
                                children: MenuModels.MenuListItemProps[],
                                groupIndex: number
                              ) => {
                                return (
                                  <div
                                    key={`menu-list-group-${groupIndex}`}
                                    className='menu__section-group'>
                                    {children.map(
                                      (
                                        item: MenuModels.MenuListItemProps,
                                        index: number
                                      ) => {
                                        return (
                                          <div
                                            id={`menu-list-ltem${
                                              item.category
                                                ? `${item.category.id}${index}`
                                                : `${item.product?.name}${index}`
                                            }`}
                                            key={`menu-list-ltem-${
                                              item.category
                                                ? `${item.category.id}${index}`
                                                : `${item.product?.name}${index}`
                                            }`}
                                            className={"menu__list-item"}
                                            style={
                                              index === 2
                                                ? { marginRight: 0 }
                                                : {}
                                            }>
                                            {item.combinedCategory ? (
                                              <MenuListItemTile
                                                item={
                                                  item.combinedCategory.data[0]
                                                }
                                                substitutionalTitle={
                                                  item.combinedCategory.title
                                                }
                                                price={MenuModuleUtils.getTheLowestPriceInCategories(
                                                  item.combinedCategory.data
                                                )}
                                                onPress={handlePress(item)}
                                                noDescription
                                              />
                                            ) : (
                                              <MenuListItem
                                                onPress={handlePress(item)}
                                                category={item.category}
                                                product={item.product}
                                                favourite={item.favourite}
                                                multiPart={item.multiPart}
                                              />
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                    {children.length === 1 && (
                                      <>
                                        <div className='menu__list-item--placeholder'></div>
                                        <div
                                          className='menu__list-item--placeholder'
                                          style={{ marginRight: 0 }}></div>
                                      </>
                                    )}
                                    {children.length === 2 && (
                                      <>
                                        <div
                                          className='menu__list-item--placeholder'
                                          style={{ marginRight: 0 }}></div>
                                      </>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        <ErrorView
          message={
            error?.message ??
            error?.heading ??
            t("OrderManagement:menuErrorMessage")
          }
        />
      )}
    </div>
  );
};

export default Menu;
