import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";

import { GomexModules } from "../../index";
import SecondaryButton from "../Buttons/SecondaryButton";
import CardSticker from "../CardSticker";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";
import EarnedPoints from "./EarnedPoints";
import GomexHeader from "./GomexHeader";

const styles = StyleSheet.create({
  currentSection: {
    padding: Spacing.Light,
    paddingBottom: Spacing.Light,
  },
  futureSection: {
    padding: Spacing.Light,
    paddingBottom: Spacing.Thin,
    borderTopWidth: 1,
    borderTopColor: colours.midGrey,
  },
  reminderText: {
    ...Typography.bodyTwo,
    color: colours.darkGrey,
    paddingBottom: Spacing.Light,
  },
  futureText: {
    ...Typography.titleThree,
    fontSize: 16,
    color: colours.darkGrey,
    paddingBottom: Spacing.Thin,
  },
  pointsText: {
    ...Typography.bodyTwo,
    color: colours.midGrey,
    paddingTop: Spacing.ExtraThin,
    paddingBottom: Spacing.Light,
  },
});

export interface GomexOrderWhiteProps {
  newPointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars;
  isOrderResponseLoading: boolean;
  reminderCopy?: string;
  captionCopy: string;
  noGomexAnimation?: boolean;
  pointsCopy?: string;
  goToLogin: () => void;
}

const GomexOrderWhite: React.FC<GomexOrderWhiteProps> = ({
  newPointsAndDollars,
  isOrderResponseLoading,
  reminderCopy,
  captionCopy,
  noGomexAnimation,
  pointsCopy,
  goToLogin,
}) => {
  const { t } = useTranslation();

  return (
    <CardSticker>
      <View style={styles.currentSection}>
        <GomexHeader
          headerAltColors
          isLoading={isOrderResponseLoading}
          noGomexAnimation={noGomexAnimation}
        />
        <Text style={styles.reminderText}>{reminderCopy}</Text>
        <Text style={styles.futureText}>{captionCopy}</Text>
        <EarnedPoints
          authUserCartGomex={true}
          earnedPoints={newPointsAndDollars.points}
          earnedDollars={newPointsAndDollars.dollars}
        />
        <Text style={styles.pointsText}>{pointsCopy}</Text>
        <SecondaryButton
          testId='OrderCardPrimaryButton'
          buttonName={t("Loyalty:guestLoginBtnLabel")}
          onClick={goToLogin}
        />
      </View>
    </CardSticker>
  );
};

export default React.memo(GomexOrderWhite);
