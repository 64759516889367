import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";
import { useDispatch } from "react-redux";

import { OrderReduxAction } from "gyg_common";

import { TimeShiftedType } from "../../hooks/useVerifyTime";
import PrimaryBlackButton from "../Buttons/PrimaryBlackButton";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { shadowTextstyle } from "../styles/shared";
import { Typography } from "../styles/typography";
import MiddleModal, { WebModalSize } from "./MiddleModal";

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: Spacing.Regular,
  },
  circle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 220,
    height: 220,
    borderRadius: 110,
    marginTop: Spacing.ExtraLight,
    backgroundColor: colours.yellow,
  },
  icon: {
    alignSelf: "flex-end",
    width: 32,
    height: 32,
  },
  headingWrapper: {
    ...Platform.select({
      web: {
        width: "100%",
        paddingHorizontal: Spacing.Light,
      },
      ios: {
        width: 294,
        marginBottom: Spacing.Thin,
      },
      android: {
        width: 300,
        marginBottom: Spacing.Thin,
      },
    }),
  },
  heading: {
    ...Typography.largeTitle,
    color: colours.darkGrey,
    textAlign: "center",
  },
  dateContainer: {
    paddingHorizontal: Spacing.Light,
    alignItems: "center",
  },
  timeContainer: {
    paddingHorizontal: Spacing.Light,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  message: {
    marginTop: Spacing.Light,
    fontFamily: "Montserrat-Regular",
    color: colours.darkGrey,
    textAlign: "center",
  },
  date: {
    fontSize: 28,
    fontFamily: "Sini-Bold",
    color: colours.darkGrey,
    textAlign: "center",
    textTransform: "uppercase",
  },
  time: {
    fontSize: 58,
    lineHeight: 58,
    height: 58,
    fontFamily: "Sini-Bold",
    color: colours.darkGrey,
    textAlign: "center",
    textTransform: "uppercase",
  },
  semicolon: {
    fontFamily: "Sini-Bold",
    fontSize: 58,
    alignSelf: "center",
    marginRight: -Spacing.Light,
    lineHeight: 58,
    height: 58,
  },
  buttonContainer: {
    marginTop: Spacing.Medium,
    paddingHorizontal: Spacing.Light,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  button: {
    flex: 1,
    marginLeft: Spacing.Thin,
  },
});
interface TimeWasChangedModalProps {
  isVisible: boolean;
  newTime: number | null;
  storeTimeZone: string | undefined;
  closeOrderTimeChangedModal: (newTime: number | null) => void;
  timeShiftedType?: TimeShiftedType;
}

export const TimeWasChangedModal = ({
  isVisible,
  newTime,
  storeTimeZone,
  closeOrderTimeChangedModal,
  timeShiftedType,
}: TimeWasChangedModalProps) => {
  const { t } = useTranslation();

  const [newFormattedOrderTime, setNewFormattedOrderTime] = useState<string>();
  const [newFormattedOrderHours, setNewFormattedOrderHours] =
    useState<string>();
  const [newFormattedOrderDate, setNewFormattedOrderDate] = useState<string>();
  const dispatch = useDispatch();
  const isClosed = useRef(false);

  const onModalClose = () => {
    if (!isClosed.current) {
      closeOrderTimeChangedModal(newTime);
    }
    isClosed.current = true;
  };

  useEffect(() => {
    if (isVisible && newTime) {
      isClosed.current = false;
      dispatch(OrderReduxAction.updateOrderTime(newTime));
      dispatch(OrderReduxAction.updateOrderInitialTime(newTime));
    }
  }, [dispatch, isVisible, newTime]);

  useEffect(() => {
    if (storeTimeZone && newTime) {
      const time = moment(newTime).tz(storeTimeZone);
      setNewFormattedOrderHours(time.format("h"));
      setNewFormattedOrderTime(time.format("mma"));
      setNewFormattedOrderDate(time.format("dddd DD MMM"));
    }
  }, [newTime, storeTimeZone]);

  const title = (() => {
    switch (timeShiftedType) {
      case TimeShiftedType.BreakfastMenu:
        return t("OrderManagement:pickupBreakfastMenuTimeWasChangedTitle");
      case TimeShiftedType.LunchMenu:
        return t("OrderManagement:pickupLunchDinnerMenuTimeWasChangedTitle");
      default:
        return t("OrderManagement:pickupTimeWasChangedTitle");
    }
  })();

  const description = (() => {
    switch (timeShiftedType) {
      case TimeShiftedType.BreakfastMenu:
        return t("OrderManagement:pickupBreakfastMenuTimeWasChangedDesc");
      case TimeShiftedType.LunchMenu:
        return t("OrderManagement:pickupLunchDinnerMenuTimeWasChangedDesc");
      default:
        return t("OrderManagement:pickupTimeWasChangedDesc");
    }
  })();

  return (
    <MiddleModal
      testId={"TimeWasShiftedModal"}
      onAndroidBackPress={onModalClose}
      isVisible={isVisible}
      webModalSize={WebModalSize.SMALL}
      onDismiss={onModalClose}>
      <View style={styles.container}>
        <View style={styles.headingWrapper}>
          <Text style={styles.heading}>{title}</Text>
        </View>
        <View style={styles.circle}>
          <View style={styles.dateContainer}>
            <Text
              numberOfLines={Platform.OS == "web" ? 2 : 1}
              adjustsFontSizeToFit
              allowFontScaling={false}
              style={[styles.date, shadowTextstyle(2).shadow]}>
              {newFormattedOrderDate}
            </Text>
          </View>

          <View style={styles.timeContainer}>
            <Text
              numberOfLines={1}
              style={[styles.time, shadowTextstyle(2).shadow]}>
              {newFormattedOrderHours}
            </Text>
            <Text style={[styles.semicolon, shadowTextstyle(2).shadow]}>:</Text>
            <Text
              numberOfLines={1}
              style={[styles.time, shadowTextstyle(2).shadow]}>
              {newFormattedOrderTime}
            </Text>
          </View>
        </View>
        <View style={styles.headingWrapper}>
          <Text numberOfLines={3} adjustsFontSizeToFit style={styles.message}>
            {description}
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <PrimaryBlackButton
              testId='ModalPrimaryButton'
              buttonName={t("OrderManagement:pickupTimeWasChangedAction")}
              onClick={onModalClose}
            />
          </View>
        </View>
      </View>
    </MiddleModal>
  );
};
