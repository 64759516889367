import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { useDispatch } from "react-redux";

import RadioButtonGroup from "../../../components/Buttons/Radio/RadioButtonGroup";
import GradientLine from "../../../components/GradientLine";
import { locales } from "../../../locales/locales.enum";
import * as PlatformUtils from "../../../modules/platformUtils";
import { OptionDataItemProps } from "../../../modules/Products/model";
import * as ProductUtils from "../../../modules/Products/utils";
import { cartActions as CartReduxAction } from "../../../redux_store/cart/cart.slice";
import * as MenuModels from "../../../redux_store/menu/models";
import Accordion from "../../Accordion";
import { Typography } from "../../styles/typography";
import MakeMealOption from "./MakeMealOption";
import { styles } from "./styles";

export interface MakeItAMealProps {
  id: string;
  itemOnlyFromPrice?: number;
  error?: boolean;
  expand?: boolean;
  handleUpdateExpandList?: (key: string, expand: boolean) => void;
  categoryImageAngleUrl?: string;
  categoryImageTopDownUrl?: string;
  commonSection: MenuModels.CommonSection;
  selectedMealId: number | undefined;
  updateSelectedMealId: (selectedMealId: number) => void;
  identifierSectionTitle?: string;
}

const renderMakeOption = (item: OptionDataItemProps) => {
  return <MakeMealOption item={item} />;
};

const MakeItAMeal: React.FC<MakeItAMealProps> = ({
  id,
  itemOnlyFromPrice,
  error,
  expand,
  handleUpdateExpandList,
  categoryImageAngleUrl,
  categoryImageTopDownUrl,
  commonSection,
  selectedMealId,
  updateSelectedMealId,
  identifierSectionTitle,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const miamIdentifierData = useMemo(() => {
    return ProductUtils.generateMakeMealIdentifierData({
      category: commonSection.categories[0],
      categoryImageAngleUrl,
      categoryImageTopDownUrl,
      locale: i18n.language as locales,
      itemOnlyFromPrice,
    });
  }, [
    categoryImageAngleUrl,
    categoryImageTopDownUrl,
    commonSection.categories,
    i18n.language,
    itemOnlyFromPrice,
  ]);

  const onItemSelection = (_id: number | string) => {
    const id = _id as number; // data.id is always number

    // Resets MIAM cart upon changing meal selection
    if (selectedMealId !== id) {
      dispatch(CartReduxAction.clearMIAMItem());
      updateSelectedMealId(id);
    }
  };

  const onExpandChanged = (key: string, expanded: boolean) => {
    if (handleUpdateExpandList) {
      handleUpdateExpandList(key, expanded);
    }
  };

  return (
    <Accordion
      expand={expand || false}
      onExpandChanged={onExpandChanged}
      key={id + "Accordion"}
      id={id}
      title={
        identifierSectionTitle
          ? identifierSectionTitle
          : t("OrderManagement:makeMealSection")
      }
      subTitle={t("OrderManagement:required")}
      subTitleStyle={error ? Typography.error : undefined}>
      <View
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `MakeItAMeal-Section-${commonSection.title}`
        )}
        key={commonSection.title}>
        <View style={styles.container}>
          <RadioButtonGroup<OptionDataItemProps<number>>
            key={commonSection.title}
            showGradientLine={true}
            onItemSelection={onItemSelection}
            selectedId={selectedMealId}
            renderItem={renderMakeOption}
            data={miamIdentifierData}
          />
          <GradientLine />
        </View>
      </View>
    </Accordion>
  );
};

export default MakeItAMeal;
