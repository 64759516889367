import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ErrorReduxModel, UserReduxModels } from "gyg_common";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import ErrorView from "gyg_common/components/Error/ErrorView";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";
import { UpdateEmailContainer } from "views/containers/UserManagement/UpdateEmailContainer";
import { UpdatePasswordContainer } from "views/containers/UserManagement/UpdatePasswordContainer";
import { EditModeType } from "views/containers/UserManagement/UserProfileContainer";

import { UpdateDateOfBirth } from "./UpdateDateOfBirth";
import { UpdateMobileNumber } from "./UpdateMobileNumber";
import { UpdateUserName } from "./UpdateUserName";

export interface UserProfileProps {
  userProfile: UserReduxModels.UserProfile | null;
  isMobileVerified: boolean;
  errorOTPMessage: string;
  onEditMode: EditModeType | undefined;
  errorMessage: ErrorReduxModel.ErrorResponse | null;
  isUserLoading: boolean;
  isMobileVerifyLoading: boolean;
  onUpdateUserAccount: (
    userAccountRequest: UserReduxModels.UserAccountRequest
  ) => void;
  onVerifyPress: (mobileNumber: string) => void;
  setEditMode: (type: EditModeType | undefined) => void;
  onVerifyOTPCode: (mobileNumber: string, verificationCode: string) => void;
  resetMobileVerificationError: () => void;
}

export const UserProfileView: React.FC<UserProfileProps> = ({
  userProfile,
  isMobileVerified,
  errorOTPMessage,
  onEditMode,
  errorMessage,
  isUserLoading,
  isMobileVerifyLoading,
  onUpdateUserAccount,
  onVerifyPress,
  setEditMode,
  onVerifyOTPCode,
  resetMobileVerificationError,
}) => {
  const { t } = useTranslation();
  const container = useRef(null);

  useEffect(() => {
    if (errorMessage) {
      const body = document.documentElement;
      body.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [errorMessage]);

  return (
    <div>
      <HeaderContainer pageTitle={t("Dashboard:sectionManage")} />
      <LoadingScreen loading={isUserLoading || isMobileVerifyLoading} />
      <div
        className='main-wrapper main-wrapper__row main-wrapper--narrow'
        ref={container}>
        {userProfile ? (
          <>
            {!!errorMessage && (
              <div className='user-profile-main__alert'>
                <ErrorAlert
                  isVisible={!!errorMessage}
                  title={
                    errorMessage?.message ||
                    t("UserManagement:userAccountUpdateError")
                  }
                />
              </div>
            )}
            <h2 className='user-profile-main__section-title'>
              {t("Dashboard:personalBtn")}
            </h2>
            <UpdateUserName
              userProfile={userProfile}
              onEditMode={onEditMode}
              setEditMode={setEditMode}
              onUpdateUserAccount={onUpdateUserAccount}
            />
            <UpdateEmailContainer
              emailFromState={userProfile.email}
              onEditMode={onEditMode}
              setEditMode={setEditMode}
              hideEdit={
                !userProfile.activatedAuthenticationTypes.includes("Password")
              }
            />
            <UpdateMobileNumber
              isUserLoading={isUserLoading} // separate loading states so one will make modal disapear
              mobileNumberFromState={userProfile.phoneNumber}
              isMobileVerified={isMobileVerified}
              onEditMode={onEditMode}
              errorMessage={errorMessage}
              setEditMode={setEditMode}
              onVerifyPress={onVerifyPress}
              errorOTPMessage={errorOTPMessage}
              onVerifyOTPCode={onVerifyOTPCode}
              resetMobileVerificationError={resetMobileVerificationError}
            />

            <UpdateDateOfBirth
              userProfile={userProfile}
              onEditMode={onEditMode}
              setEditMode={setEditMode}
              onUpdateUserAccount={onUpdateUserAccount}
            />

            <UpdatePasswordContainer
              email={userProfile.email}
              onEditMode={onEditMode}
              setEditMode={setEditMode}
            />
          </>
        ) : (
          !isUserLoading && (
            <ErrorView message={t("UserManagement:userProfileError")} />
          )
        )}
      </div>
    </div>
  );
};
