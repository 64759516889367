import { batch, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AnalyticsAction, CartReduxAction, OrderReduxAction } from "gyg_common";
import {
  logAddedCartItems,
  mapUpsellForSource,
} from "gyg_common/modules/Cart/utils";
import { HandleUpdateOrder } from "gyg_common/modules/Order/models";

import { Screens } from "../navigation/const";

interface AddToOrder {
  isUpsell?: boolean;
  onClose?: () => void;
  categoryName?: string;
  applyAll?: boolean;
}

const useAddToOrder = ({
  onClose,
  isUpsell,
  applyAll,
  categoryName,
}: AddToOrder) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToOrder = ({ cartItems }: HandleUpdateOrder) => {
    if (cartItems.length > 0) {
      const isPreCart = window.location.pathname !== Screens.Order;
      // Mapped upsellSource here so it's easy to identify what's the source is
      const newCartItems = mapUpsellForSource({
        cartItems,
        isPreCart,
        isUpsell: !!isUpsell,
      });

      batch(() => {
        dispatch(CartReduxAction.addItemToCart({ cartItems: newCartItems }));
        dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      });
      if (isUpsell) {
        dispatch(
          AnalyticsAction.addUpsellItemToCart({
            id: newCartItems[0].productId,
            didAddToCart: true,
            isUpsellPreCart: window.location.pathname !== Screens.Order,
          })
        );

        navigate(Screens.Order, { replace: true });
      }

      logAddedCartItems({
        cartItems: newCartItems,
        applyAll: !!applyAll,
        isUpsell: !!isUpsell,
        categoryName,
      });

      if (onClose) {
        onClose();
      }
    }
  };

  return handleAddToOrder;
};

export default useAddToOrder;
