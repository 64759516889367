import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { HandleUpdateOrder } from "../modules/Order/models";
import * as ProductConst from "../modules/Products/const";
import { cartActions as CartReduxAction } from "../redux_store/cart/cart.slice";

const CART_LENGTH_WITH_MIAM = 2;

const useUpdateOrder = (
  editMode: ProductConst.EditMode,
  callback: () => void | undefined
) => {
  const dispatch = useDispatch();

  const onEditPress = useCallback(
    ({ index, cartItems }: HandleUpdateOrder) => {
      switch (editMode) {
        case ProductConst.EditMode.MODIFY_CART_ITEM: {
          if (index !== undefined && cartItems) {
            dispatch(
              CartReduxAction.updateCartItem({ cartItem: cartItems[0], index })
            );
            if (cartItems?.length === CART_LENGTH_WITH_MIAM) {
              dispatch(
                CartReduxAction.addItemToCart({ cartItems: [cartItems[1]] })
              );
            }
          }

          break;
        }

        case ProductConst.EditMode.UPDATE_MIAM_ITEM: {
          if (cartItems[0]) {
            dispatch(
              CartReduxAction.updateMIAMItem({
                cartItem: cartItems[0],
                index: index,
              })
            );
          }

          break;
        }

        case ProductConst.EditMode.UPDATE_BUNDLE_ITEM: {
          if (cartItems[0]) {
            dispatch(
              CartReduxAction.updateBundleItem({
                cartItem: cartItems[0],
                index: index,
              })
            );
          }

          break;
        }

        case ProductConst.EditMode.UPDATE_TACO_ITEM: {
          if (cartItems[0]) {
            dispatch(
              CartReduxAction.updateTacoItem({
                cartItem: cartItems[0],
                index: index,
              })
            );
          }

          break;
        }
      }

      if (callback) {
        callback();
      }
    },
    [dispatch, callback, editMode]
  );

  return onEditPress;
};

export default useUpdateOrder;
