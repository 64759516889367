import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";

import { CheckoutModels, OrderModules } from "gyg_common";
import OrderStatusReceiptTable from "gyg_common/components/OrderStatus/OrderStatusReceiptTable";
import colours from "styles/colours";
import { Typography } from "styles/typography";
import { OrderDetailsHeaderInfo } from "views/components/OrderDetails/OrderDetailsHeaderInfo";
import { OrderDetailsListItem } from "views/components/OrderDetails/OrderDetailsListItem";

const style = StyleSheet.create({
  receiptInfo: {
    padding: 16,
    paddingBottom: 0,
  },
  receiptInfoDetailed: {
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
    paddingBottom: 12,
    marginBottom: 12,
  },
});

export interface OrderStatusReceiptProps {
  detailedRecieptMode?: boolean;
  storeName: string;
  order: CheckoutModels.OrderCheckoutResponse;
  locale: string;
  paymentTypes: CheckoutModels.PaymentCheckoutResponse[];
  deliveryFee?: number;
}

const { OrderConstants, OrderUtils } = OrderModules;

const OrderStatusReceipt: React.FC<OrderStatusReceiptProps> = ({
  storeName,
  order,
  locale,
  paymentTypes,
  detailedRecieptMode,
  deliveryFee,
}) => {
  const { t } = useTranslation();
  const devicePurchaseWasMadeOn = order?.channelId
    ? OrderUtils.ChannelIdMap[order?.channelId]
    : null;

  return (
    <>
      <View testID={OrderConstants.OrderStatusTestingId.orderReceipt}>
        <View
          style={[
            style.receiptInfo,
            detailedRecieptMode && style.receiptInfoDetailed,
          ]}>
          <Text style={[Typography.titleTwo, { marginBottom: 8 }]}>
            {t("OrderStatus:receiptTitle")}
          </Text>

          {/* Only renders on the order status screen and not for the detailed reciept view */}
          {detailedRecieptMode ? (
            <OrderDetailsHeaderInfo
              order={order}
              storeName={storeName}
              isDelivery={!!order.delivery}
            />
          ) : (
            <Text style={Typography.titleTwo}>
              {t("OrderStatus:receiptFrom")} {storeName}
            </Text>
          )}
        </View>
        <OrderStatusReceiptTable
          isShowSurchargePercent={false}
          locale={locale}
          order={order}
          paymentTypes={paymentTypes}
          deliveryFee={deliveryFee}
        />

        {/* Only render these components if detailedRecieptMode is enabled */}
        {detailedRecieptMode ? (
          <OrderDetailsListItem
            content={`${t("OrderStatus:GYG")} ${devicePurchaseWasMadeOn}`}
            title={t("OrderStatus:purchasedThrough")}
          />
        ) : null}
      </View>
    </>
  );
};

export default OrderStatusReceipt;
