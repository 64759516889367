import React from "react";
import { useTranslation } from "react-i18next";

import { OrderReduxModels } from "gyg_common";
import ToggleSelect, {
  ActiveTabType,
} from "gyg_common/components/ToggleSelect";

export interface PickupTypeProps {
  driveThruOptionAvailable: boolean;
  pickupType: OrderReduxModels.CollectionType;
  onChangePickupType: (setting: OrderReduxModels.CollectionType) => void;
}

const OrderPickupType: React.FC<PickupTypeProps> = ({
  driveThruOptionAvailable,
  pickupType,
  onChangePickupType,
}) => {
  const { t } = useTranslation();

  const onChangePickUpFrom = (value: OrderReduxModels.CollectionType) => {
    onChangePickupType(value);
  };

  return (
    <div>
      {driveThruOptionAvailable ? (
        <div>
          <div className='pickup-title'>
            <div className='title-three'>
              {t("CheckoutPayment:pickupLabel")}
            </div>
          </div>
          <div data-testid='PickUpFromControl'>
            <ToggleSelect
              activeTab={
                pickupType === OrderReduxModels.CollectionType.DRIVE_THRU
                  ? ActiveTabType.RIGHT
                  : ActiveTabType.LEFT
              }
              leftTitle={t("CheckoutPayment:inStoreLabel")}
              rightTitle={t("CheckoutPayment:driveThruLabel")}
              onUpdateTab={(activeTab) => {
                onChangePickUpFrom(
                  activeTab === ActiveTabType.LEFT
                    ? OrderReduxModels.CollectionType.DRIVE_THRU
                    : OrderReduxModels.CollectionType.PICK_UP
                );
              }}
            />
          </div>
          {pickupType === OrderReduxModels.CollectionType.PICK_UP && (
            <div className='body-two-bold pickup-label'>
              {t("CheckoutPayment:pickupCaption")}
            </div>
          )}
          {pickupType === OrderReduxModels.CollectionType.DRIVE_THRU && (
            <div className='body-two-bold pickup-label'>
              {t("CheckoutPayment:driveThruCaption")}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default OrderPickupType;
