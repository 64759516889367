import React from "react";
import { Image, ImageStyle } from "react-native";
import { styles } from "./GYGDollarsIcon.styles";

interface GYGDollarsIconProps {
  scale?: 1 | 2 | 3;
  style?: ImageStyle;
}

const GYGDollarsIcon: React.FC<GYGDollarsIconProps> = ({
  scale = 1,
  style,
}) => {
  return (
    <Image
      style={[styles.icon, style]}
      source={
        scale === 1
          ? require("../../assets/icons/icon_dollars.png")
          : require(`../../assets/icons/icon_dollars@${scale}x.png`)
      }
    />
  );
};

export default GYGDollarsIcon;
