import React, { useCallback } from "react";
import { Platform, StyleSheet, View } from "react-native";

import { generateTestID } from "../../../modules/platformUtils";
import * as ProductConst from "../../../modules/Products/const";
import * as ProductModuleModel from "../../../modules/Products/model";
import Accordion from "../../Accordion";
import RadioButtonGroup from "../../Buttons/Radio/RadioButtonGroup";
import { CheckboxButtonGroup } from "../../Checkbox";
import colours from "../../styles/colours";
import { Typography } from "../../styles/typography";
import Option from "./Option";
import RevertedToggleData from "./RevertedToggleData";

const styles = StyleSheet.create({
  wrapper: {
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
  },
});

export interface ProductModifierProps {
  error?: boolean;
  item: ProductModuleModel.CustomizeOptionsDataProps;
  index: number;
  expand: boolean;
  selectedId: (number | string)[];
  customisationState?: ProductModuleModel.CustomizableOptionState;
  footerTitle?: string;
  handleUpdateExpandList: (key: string, expand: boolean) => void;
  handleCustomizeOptionsUpdate: (
    item: ProductModuleModel.CustomizeOptionsDataProps,
    id: (number | string)[],
    index: number
  ) => void;
  handleToggleShowAll?: () => void;
  updateApplyRemoveModifier?: (
    value: boolean,
    removeModifierId?: number
  ) => void;
}

const renderOptionView = (
  optionItem: ProductModuleModel.OptionDataItemProps
) => <Option data={optionItem} />;

const ProductModifier: React.FC<ProductModifierProps> = ({
  error,
  item,
  index,
  expand,
  selectedId,
  customisationState,
  handleUpdateExpandList,
  handleCustomizeOptionsUpdate,
  updateApplyRemoveModifier,
  footerTitle,
  handleToggleShowAll,
}) => {
  const onItemSelection = useCallback(
    (id) => {
      const idToPass = item.isMultiple ? id : [id];
      handleCustomizeOptionsUpdate(item, idToPass, index);
    },
    [handleCustomizeOptionsUpdate, index, item]
  );

  return (
    <Accordion
      expand={expand}
      onExpandChanged={handleUpdateExpandList}
      key={item.id + "Accordion"}
      id={item.id}
      title={item.title}
      subTitle={item.subTitle}
      subTitleStyle={error ? Typography.error : undefined}>
      {item.isMultiple ? (
        <View
          style={styles.wrapper}
          {...generateTestID(Platform.OS, `${item.title}CheckboxButtonGroup`)}>
          <CheckboxButtonGroup
            maximumSelection={item.maximumSelection}
            key={item.id + index}
            showGradientLine={true}
            onItemSelection={onItemSelection}
            handleToggleShowAll={handleToggleShowAll}
            footerTitle={footerTitle}
            selectedId={selectedId}
            renderItem={renderOptionView}
            data={item?.data ?? []}
            customisationState={customisationState}
            isRemoveSection={
              item.id === ProductConst.SectionTitle.RemoveModifier
            }
          />
        </View>
      ) : (
        <View
          style={styles.wrapper}
          {...generateTestID(Platform.OS, `${item.title}RadioButtonGroup`)}>
          <RadioButtonGroup
            key={item.id + index}
            showGradientLine={true}
            onItemSelection={onItemSelection}
            selectedId={selectedId[0]}
            renderItem={renderOptionView}
            data={item?.data ?? []}
          />

          {/** Displays remove modifier as toggle */}
          {customisationState &&
            customisationState?.[item.id]?.length > 0 &&
            item?.revertedToggleData?.map(({ element }) => (
              <RevertedToggleData
                key={element.id}
                id={element.id as number}
                name={element.name}
                onChange={updateApplyRemoveModifier}
                selected={
                  !customisationState[
                    ProductConst.SectionTitle.RemoveModifier
                  ].includes(element.id)
                }
              />
            ))}
        </View>
      )}
    </Accordion>
  );
};

export default React.memo(ProductModifier);
