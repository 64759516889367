import { StyleSheet } from "react-native";

import * as GomexModules from "../../../modules/Gomex";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export const progressBarStyle = (
  isEstimatedStatus: boolean,
  loyaltyMaxPoints: number,
  progressBarWidth: number,
  loyaltyPoints?: number
) =>
  StyleSheet.create({
    back: {
      backgroundColor: colours.midGrey,
      height: 16,
      flex: 1,
      alignSelf: "center",
      overflow: "hidden",
    },
    front: {
      backgroundColor: isEstimatedStatus ? colours.starburst : colours.yellow,
      height: 16,
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: GomexModules.calculateGomexPercentageBar(
        loyaltyMaxPoints,
        progressBarWidth,
        loyaltyPoints
      ),
    },
    triangle: {
      width: 0,
      height: 16,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: 8,
      borderRightWidth: 8,
      borderBottomWidth: 8,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: isEstimatedStatus ? colours.starburst : colours.yellow,
      transform: [{ rotate: "90deg" }],
      position: "absolute",
      left: GomexModules.calculateGomexPercentageBar(
        loyaltyMaxPoints,
        progressBarWidth,
        loyaltyPoints
      ),
      right: 0,
      top: 0,
      bottom: 0,
    },
  });

export const pointsStyle = (isEstimatedStatus: boolean) =>
  StyleSheet.create({
    details: {
      ...Typography.btnPrice,
      color: isEstimatedStatus ? colours.starburst : colours.yellow,
    },
  });

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    marginTop: Spacing.Thin,
  },
  subContainer: {
    height: "100%",
    flex: 1,
  },
  progressBarContainer: {
    flexDirection: "row",
    flex: 1,
  },
  dollarSign: {
    ...Typography.titleFour,
    color: colours.yellow,
    marginLeft: Spacing.ExtraThin,
    height: 18,
  },
  pointDetailContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    flex: 1,
    marginTop: Spacing.Narrow,
  },
  pointRef: {
    ...Typography.body,
    color: colours.uiGrey,
    textAlign: "right",
  },
});
