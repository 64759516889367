import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { Dimensions, StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { PromoReduxAction, RootState } from "gyg_common";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import IconButton from "gyg_common/components/IconButton";
import MiddleModal from "gyg_common/components/modals/MiddleModal";
import { PromotionsScreen } from "gyg_common/components/Promotions/PromotionsScreen/PromotionsScreen";

export const styles = StyleSheet.create({
  container: {
    width: 580,
  },
  containerMobile: {
    minWidth: Dimensions.get("window").width * 0.9,
  },
  backButtonWrapper: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 2,
  },
});

interface PromotionsModalContainerProps {
  isVisible: boolean;
}

export const PromotionsModalContainer: React.FC<
  PromotionsModalContainerProps
> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const { isTabletScreen } = useMediaQuery();

  const { selectedAvailablePromoDetails: promoDetails } = useSelector(
    (s: RootState) => s.promo
  );

  const onBtnPressed = () => {
    if (promoDetails.btnLink) {
      window.open(promoDetails.btnLink, "_blank");
    }
  };

  const onOpenTermsURL = () => {
    if (promoDetails.terms.linkUrl) {
      window.open(promoDetails.terms.linkUrl, "_blank");
    }
  };

  const closeModal = () => {
    dispatch(
      PromoReduxAction.setShowPromotionsModal({
        isPromotionsModalVisible: false,
      })
    );
  };

  return (
    <MiddleModal isVisible={isVisible} testId='promotions-detail-modal'>
      <View style={isTabletScreen ? styles.container : styles.containerMobile}>
        <View style={styles.backButtonWrapper}>
          <IconButton
            testID='BackIconButton'
            onPress={closeModal}
            iconImage={closeIcon}
          />
        </View>
        <PromotionsScreen
          {...promoDetails}
          onMainButtonPressed={onBtnPressed}
          openURL={onOpenTermsURL}
        />
      </View>
    </MiddleModal>
  );
};

export default PromotionsModalContainer;
