import { Dimensions, Platform, StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

const container =
  Platform.OS === "ios" || Platform.OS === "web" ? { zIndex: 2 } : {};

const minWidth = 768;
const windowWidth = Dimensions.get("window").width;

export const backButtonStyle = (isLeft: boolean) =>
  StyleSheet.create({
    backButton: {
      position: "absolute",
      top: Platform.OS == "web" ? 16 : 48,
      left: isLeft ? 16 : null,
      right: !isLeft ? 16 : null,
    },
  });

export const styles = StyleSheet.create({
  mealSection: {
    paddingHorizontal: Spacing.Light,
  },
  gap: {
    marginTop: Spacing.ExtraThin,
  },
  optionCustomizeText: {
    flex: 1,
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
  },
  optionText: {
    width: 75,
  },
  container: {
    ...container,
  },
  productImage: {
    ...Platform.select({
      android: {
        height: 328,
      },
      ios: {
        height: 328,
      },
      web: {
        minHeight: windowWidth > minWidth ? 328 : 268,
      },
    }),
    resizeMode: Platform.OS == "web" ? "contain" : "cover",
    justifyContent: "center",
    alignContent: "center",
    width: Platform.OS == "web" ? null : Dimensions.get("screen").width,
  },
  textView: {
    marginTop: Spacing.Thin,
  },
  title: { width: 250 },
  column: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
  backButton: {
    position: "absolute",
    top: Platform.OS == "web" ? 16 : 48,
    left: 16,
  },
  icon: {
    width: 32,
    height: 32,
    margin: Spacing.Thin,
    resizeMode: "cover",
  },
  split: {
    flex: 1,
  },
  quantitySpace: {
    paddingTop: Spacing.Light,
  },
  productTitle: {
    ...Typography.largeProductTitle,
    marginBottom: Spacing.Thin,
  },
  quantitySelector: {
    marginTop: -Spacing.Regular,
    alignSelf: "center",
    zIndex: 99,
  },
  productInfo: {
    paddingBottom: Spacing.Light,
    paddingHorizontal: Spacing.Light,
  },
  allergenText: {
    marginBottom: Spacing.Thin,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  coffeeSection: {
    paddingVertical: Spacing.ExtraLight,
    paddingHorizontal: Spacing.Light,
  },
});
