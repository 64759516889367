import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AnalyticsAction,
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  RegistrationAction,
  RootState,
  UserApiModels,
  UserReduxAction,
  UserReduxModels,
} from "gyg_common";
import { VerifyCodePayload } from "gyg_common/services/api/registration/model";
import { Screens } from "navigation/const";
import { UserProfileView } from "views/components/UserManagement/UserProfileView";

export enum EditModeType {
  NAME = "NAME",
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
  BIRTHDAY = "BIRTHDAY",
  PASSWORD = "PASSWORD",
}

const UserProfileContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    profile,
    errorMessage,
    isLoading: isUserLoading,
    isUpdateUserSuccess,
    isBrazeInitialised,
    isVerifyEmailAddressSuccess,
    verifyEmailAddressLoading,
    verifyEmailAddressError,
  } = useSelector((s: RootState) => s.user);
  const { updateUserAccountState } = useSelector((s: RootState) => s.analytics);
  const { isAuthSuccess, isLoading: isAuthLoading } = useSelector(
    (s: RootState) => s.login
  );

  const {
    verifyCodeErrorResponse: errorOTPMessage,
    isVerified,
    loading: isMobileVerifyLoading,
  } = useSelector((s: RootState) => s.registration);

  const [onEditMode, setEditMode] = useState<EditModeType | undefined>();

  /**
   * Update user account details - Firstname, LastName, Mobile Number , email and birthday
   * @param userAccountRequest
   */
  const onUserSave = (
    userAccountRequest: UserReduxModels.UserAccountRequest
  ) => {
    dispatch(UserReduxAction.updateUserProfile(userAccountRequest));
  };

  /**
   * handler when user verifies otp code and if success, auto save mobile number
   * @param mobileNumber
   * @param verificationCode
   */
  const onVerifyOTPCode = (mobileNumber: string, verificationCode: string) => {
    if (verificationCode !== "") {
      const payload: VerifyCodePayload = {
        code: parseInt(verificationCode),
        mobileNumber: mobileNumber,
        isLoggedIn: true,
      };
      dispatch(RegistrationAction.verifyCode(payload));
    }
  };

  /**
   * handler when user request for otp code
   * @param mobileNumber
   */
  const onVerifyPress = (mobileNumber: string) => {
    const payload: UserApiModels.PatchUserMobilePayload = {
      mobile: mobileNumber,
    };

    dispatch(UserReduxAction.getMobileVerificationCode(payload));
  };

  const resetMobileVerificationError = useCallback(() => {
    dispatch(RegistrationAction.resetMobileVerification());
  }, [dispatch]);

  /**
   * Send tracking event to tealium
   * then clears the state in analytics
   */
  const trackUpdateUserToAnalytics = useCallback(() => {
    if (updateUserAccountState) {
      const analyticsType = AnalyticsPayloadGenerator.updateUserAccountPayload(
        updateUserAccountState
      );

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.UpdateDetails,
        analyticsType
      );
    }
    dispatch(AnalyticsAction.setUpdateUserAccountToState(null));
  }, [dispatch, updateUserAccountState]);

  /**
   * Refresh user data
   */
  useEffect(() => {
    if (isAuthSuccess || isVerified) {
      dispatch(UserReduxAction.resetUserAccount());
      dispatch(UserReduxAction.getUserProfile());
      resetMobileVerificationError();
    }
  }, [dispatch, isAuthSuccess, isVerified, resetMobileVerificationError]);

  useEffect(() => {
    if (isVerified) {
      if (updateUserAccountState) {
        trackUpdateUserToAnalytics();
      }

      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:mobileNoSavedSuccess"),
          showCloseButton: true,
          iconType: "check",
        })
      );
    }
  }, [
    t,
    dispatch,
    trackUpdateUserToAnalytics,
    isVerified,
    updateUserAccountState,
  ]);

  useEffect(() => {
    if (!isAuthLoading && !isAuthSuccess) navigate(Screens.Dashboard);
  }, [navigate, isAuthSuccess, isAuthLoading]);

  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "personal_details",
        page_type: "personal_details_view",
      });
    }
  }, [isBrazeInitialised]);

  /**
   * Turns off edit mode for inputs when error message is dismissed.
   * Should happened only when API respond with updated user details.
   */
  useEffect(() => {
    if (
      profile &&
      !errorMessage &&
      !isUserLoading &&
      !verifyEmailAddressLoading
    ) {
      setEditMode(undefined);
    }
  }, [profile, errorMessage, isUserLoading, verifyEmailAddressLoading]);

  /**
   * Dismisses error message when edit mode is cancelled.
   */
  useEffect(() => {
    if (errorMessage && !onEditMode) {
      dispatch(UserReduxAction.resetError());
    }
  }, [onEditMode, errorMessage, dispatch]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      if (updateUserAccountState) {
        trackUpdateUserToAnalytics();
      }

      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:userAccountUpdateSuccess"),
          showCloseButton: true,
          iconType: "check",
        })
      );
      dispatch(UserReduxAction.resetUserAccount());
    }
  }, [
    t,
    trackUpdateUserToAnalytics,
    dispatch,
    isUpdateUserSuccess,
    updateUserAccountState,
  ]);

  useEffect(() => {
    if (!profile?.verifiedEmail && isVerifyEmailAddressSuccess) {
      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:verifyEmailAddressSuccess"),
          showCloseButton: true,
          iconType: "check",
        })
      );
    } else if (verifyEmailAddressError) {
      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:verifyEmailAddressError"),
          showCloseButton: true,
          iconType: "alert",
        })
      );
    }
  }, [
    t,
    dispatch,
    profile?.verifiedEmail,
    isVerifyEmailAddressSuccess,
    verifyEmailAddressError,
  ]);

  useEffect(() => {
    const componentWillUnmount = () => {
      dispatch(UserReduxAction.setMessageToast(null));
    };

    return componentWillUnmount;
  }, [dispatch]);

  return (
    <UserProfileView
      isUserLoading={isUserLoading}
      isMobileVerifyLoading={isMobileVerifyLoading}
      userProfile={profile}
      errorMessage={errorMessage}
      onUpdateUserAccount={onUserSave}
      onVerifyPress={onVerifyPress}
      isMobileVerified={isVerified}
      onVerifyOTPCode={onVerifyOTPCode}
      errorOTPMessage={errorOTPMessage?.message}
      onEditMode={onEditMode}
      setEditMode={setEditMode}
      resetMobileVerificationError={resetMobileVerificationError}
    />
  );
};

export default UserProfileContainer;
