import "./style.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import {
  ApiOrderModel,
  AuthStateModels,
  CartReduxModels,
  ErrorReduxModel,
  FavouriteModels,
  GomexModules,
  GuestDetailModels,
  LoyaltyModels,
  OrderModules,
  OrderReduxModels,
} from "gyg_common";
import AllergenStatement from "gyg_common/components/AllergenStatement";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import { Checkbox } from "gyg_common/components/Checkbox";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import ErrorView from "gyg_common/components/Error/ErrorView";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import LoyaltyBonusCard from "gyg_common/components/LoyaltyBonusCard";
import OrderReceipt from "gyg_common/components/Order/OrderReceipt";
import { CartRewardsContainer } from "gyg_common/containers/Rewards/CartRewardsContainer";
import { OrderErrorResponse } from "gyg_common/services/api/order/model";
import { getStatusCode } from "gyg_common/utils/order";
import CartItemList from "views/components/Order/CartItemList";
import OrderPickupType from "views/components/Order/OrderPickupType";
import { UpsellSectionContainer } from "views/containers/Order/UpsellSectionContainer";
import OrderSetupHeaderContainer from "views/containers/OrderSetupHeader/OrderSetupHeaderContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import { SurchargesContainer } from "../Menu/SurchargesContainer";
import { EmptyCartGomex } from "./EmptyCartGomex";
import { EmptyCartViewMenu } from "./EmptyCartViewMenu";
import OrderWithGomex from "./OrderWithGomex";

export interface OrderProps {
  loyaltyBonusPoints: number;
  loyaltyBonusDollars: number;
  isLoggedIn: boolean;
  data: CartReduxModels.CartItem[];
  orderCollectionType: OrderReduxModels.CollectionType;
  getOrderLoading: boolean; // controls small loading elements in components
  isInLoadingState: boolean; // controls main loading view
  cutlery: boolean;
  basketValue: number;
  total: number;
  totalBeforeDiscount: number;
  subtotal: number;
  surcharges?: ApiOrderModel.Surcharge[];
  tax?: number;
  displayTax?: boolean;
  isSavePayment: boolean;
  favouriteCartItems: FavouriteModels.FavouriteCartItem[];
  isFavouriteUpdateError: boolean;
  isFavouriteUpdateErrorMessage: string;
  basketErrors?: OrderErrorResponse[];
  orderError?: ErrorReduxModel.ErrorResponse;
  isCartItemDeletionInProgress: boolean;
  hasOrderInvalidProducts: boolean;
  currentUser: AuthStateModels.CurrentUser | null;
  newPointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars;
  redeemDollars: number | null;
  redeemDollarsBalance: number | null;
  redeemDollarsErrorMessage?: string;
  driveThruOptionAvailable: boolean;
  enableCutlery: boolean;
  discountAmount?: number | null;
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  guestLoyalty: GuestDetailModels.GuestLoyalty;
  onChangePickupType: (pickupType: OrderReduxModels.CollectionType) => void;
  onCutleryChange: (cutlery: boolean) => void;
  updateCartItemQuantity: (cartItemId: number, quantity: number) => void;
  deleteItem: (cartItemId: number) => void;
  orderNow: () => void;
  onSavePayment: () => void;
  onCreateORUpdateFavourite: (
    payload: FavouriteModels.AddFavouriteRequest | number,
    type: string
  ) => void;
  onResetFavouriteStatus: () => void;
  onRenamePress: (
    index: number,
    payload: FavouriteModels.UpdateFavouriteRequest
  ) => void;
  onItemEdit: (item: CartReduxModels.CartItem, index: number) => void;
  onAddSomethingElse: () => void;
  onRedeemDollars: (value: number | null) => void;
  onOpenAllergenLink: () => void;
  onOrderSetupConfirmed?: () => void;
  deliveryFee?: number;
  goToLogin?: () => void;
}

const Order: React.FC<OrderProps> = ({
  loyaltyBonusDollars,
  loyaltyBonusPoints,
  isLoggedIn,
  data,
  orderCollectionType,
  getOrderLoading,
  isInLoadingState,
  cutlery,
  basketValue,
  total,
  subtotal,
  surcharges,
  tax,
  displayTax,
  isSavePayment,
  favouriteCartItems,
  isFavouriteUpdateError,
  orderError,
  basketErrors,
  isCartItemDeletionInProgress,
  hasOrderInvalidProducts,
  redeemDollars,
  driveThruOptionAvailable,
  enableCutlery,
  discountAmount,
  loyalty,
  coffeeLoyalty,
  guestLoyalty,
  newPointsAndDollars,
  onChangePickupType,
  onCutleryChange,
  updateCartItemQuantity,
  deleteItem,
  orderNow,
  onSavePayment,
  onCreateORUpdateFavourite,
  onRenamePress,
  onItemEdit,
  onAddSomethingElse,
  onRedeemDollars,
  onOpenAllergenLink,
  onOrderSetupConfirmed,
  goToLogin,
  deliveryFee,
}) => {
  const { t } = useTranslation();

  const showLoyaltyBonusCard =
    isLoggedIn && (loyaltyBonusDollars || loyaltyBonusPoints);

  const errorStatusCode = orderError
    ? getStatusCode(orderError.statusCode)
    : undefined;

  const renderBasketErrors = () => {
    return basketErrors?.map((error, index) => {
      return (
        <div
          key={error.error + error.errorDescription + index}
          data-testid='order-error-label'
          className='order__error-container'>
          <ErrorAlert
            isVisible={true}
            title={
              error?.errorDescription ?? t("CheckoutPayment:problemOrderLabel")
            }
          />
        </div>
      );
    });
  };

  return (
    <div className='order'>
      <HeaderContainer
        viewCart={true}
        bottomComponentNotScrollable={
          <>
            {OrderModules.OrderUtils.showOrderError(
              errorStatusCode,
              hasOrderInvalidProducts
            ) &&
              !basketErrors?.length && (
                <div
                  data-testid='order-error-label'
                  className='order__error-container'>
                  <ErrorAlert
                    isVisible={true}
                    title={
                      orderError?.message ||
                      orderError?.heading ||
                      t("CheckoutPayment:problemOrderLabel")
                    }
                  />
                </div>
              )}
            {renderBasketErrors()}
            <OrderSetupHeaderContainer
              onOrderSetupConfirmed={onOrderSetupConfirmed}
            />
          </>
        }
        pageTitle={t("CheckoutPayment:checkoutOrderTitle")}
      />
      <SurchargesContainer />

      <div className='order__container main-wrapper main-wrapper--narrow'>
        {isInLoadingState && !orderError ? (
          <LoadingScreen loading={isInLoadingState} />
        ) : (
          <>
            {/** Renders error view when API responds with server error 5xx */}
            {errorStatusCode?.toString().charAt(0) === "5" ? (
              <ErrorView message={t("CheckoutPayment:apiErrorMessage")} />
            ) : (
              <>
                <div className='main-wrapper__col order__col'>
                  <OrderPickupType
                    pickupType={orderCollectionType}
                    driveThruOptionAvailable={driveThruOptionAvailable}
                    onChangePickupType={onChangePickupType}
                  />
                  <div className='order__section'>
                    {enableCutlery ? (
                      <div
                        className='cutlery__container'
                        data-testid='CutleryToggleSection'>
                        <p className='cutlery__label'>
                          {t("CheckoutPayment:cutleryLabel")}
                        </p>
                        <button
                          className='cutlery__checkbox'
                          onClick={() => onCutleryChange(!cutlery)}>
                          <Checkbox selected={cutlery} />
                        </button>
                      </div>
                    ) : (
                      <div className='order__space' />
                    )}
                    <div className='gradient_line' />
                    <div className='cutlery__container'>
                      <AllergenStatement
                        statement={t("OrderStatus:allergenCopy")}
                        label={t("OrderStatus:allergenLabel")}
                        onOpenLink={onOpenAllergenLink}
                      />
                    </div>
                  </div>
                  <CartItemList
                    data={data}
                    favouriteCartItems={favouriteCartItems}
                    onUpdateCartItemQuantity={updateCartItemQuantity}
                    onDelete={deleteItem}
                    getOrderLoading={getOrderLoading}
                    isLoggedInUser={isLoggedIn}
                    isCartItemDeletionInProgress={isCartItemDeletionInProgress}
                    onCreateORUpdateFavourite={onCreateORUpdateFavourite}
                    isFavouriteUpdateError={isFavouriteUpdateError}
                    onRenamePress={onRenamePress}
                    onItemEdit={onItemEdit}
                  />

                  {data?.length > 0 ? (
                    <div className='order__section'>
                      <SecondaryButton
                        onClick={onAddSomethingElse}
                        buttonName={t("CheckoutPayment:addSomethingElseButton")}
                      />
                    </div>
                  ) : (
                    <EmptyCartViewMenu />
                  )}

                  <UpsellSectionContainer smallCards={true} />
                </div>
                <div className='main-wrapper__col'>
                  {/** start receipt section divided by border */}
                  {data?.length > 0 && isLoggedIn && (
                    <CartRewardsContainer
                      isLoading={getOrderLoading}
                      onRedeemDollars={onRedeemDollars}
                    />
                  )}
                  <OrderReceipt
                    surcharges={surcharges}
                    tax={tax}
                    total={total}
                    subtotal={subtotal}
                    basketValue={basketValue}
                    gygDollars={redeemDollars}
                    activeReward={discountAmount}
                    displayTax={displayTax}
                    deliveryFee={deliveryFee}
                  />
                  {!!showLoyaltyBonusCard && (
                    <LoyaltyBonusCard
                      isLoading={getOrderLoading}
                      loyaltyBonusDollars={loyaltyBonusDollars}
                      loyaltyBonusPoints={loyaltyBonusPoints}
                    />
                  )}
                  {/** end receipt section divided by border */}

                  {/** start loyalty & upsell */}
                  <div className='order__col order__col--bg'>
                    {data?.length > 0 ? (
                      <OrderWithGomex
                        isOrderResponseLoading={getOrderLoading}
                        newPointsAndDollars={newPointsAndDollars}
                        isUserAuthenticated={isLoggedIn}
                        loyalty={loyalty}
                        coffeeLoyalty={coffeeLoyalty}
                        guestLoyalty={guestLoyalty}
                        goToLogin={goToLogin ? goToLogin : () => {}}
                      />
                    ) : (
                      <EmptyCartGomex
                        loyalty={loyalty}
                        coffeeLoyalty={coffeeLoyalty}
                      />
                    )}
                  </div>
                  {/** end loyalty & upsell */}

                  {isLoggedIn ? (
                    <div className='save-payment__container'>
                      <p className='save-payment__label'>
                        {t("CheckoutPayment:savePaymentLabel")}
                      </p>
                      <button onClick={onSavePayment}>
                        <Checkbox selected={isSavePayment} />
                      </button>
                    </div>
                  ) : (
                    <span className='order__space' />
                  )}

                  <div className='order__col order__button'>
                    <PrimaryBlackButton
                      loading={getOrderLoading}
                      buttonName={t("CheckoutPayment:checkoutTitle")}
                      onClick={orderNow}
                      price={total}
                      disable={
                        data?.length === 0 ||
                        OrderModules.OrderUtils.showOrderError(
                          errorStatusCode,
                          hasOrderInvalidProducts
                        ) ||
                        !!basketErrors?.length
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Order;
