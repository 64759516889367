import React from "react";
import { View } from "react-native";
import { useDispatch } from "react-redux";

import {
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  MenuModuleUtils,
  ProductConst,
} from "gyg_common";
import NonCustomisableProduct from "views/components/Products/NonCustomisableProduct";
import CustomizableProductContainer from "views/containers/Products/CustomizableProductContainer";
import MIAMProductContainer from "views/containers/Products/MIAMProductContainer";
import ProductBundleContainer from "views/containers/Products/ProductBundleContainer";
import TacoProductContainer from "views/containers/Products/TacoProductContainer";

import SimpleCustomisableProduct from "./SimpleCustomisableProduct";

export interface ProductTemplateSelectorProps {
  productType?: ProductConst.ProductRoute;
  cartItem?: CartReduxModels.CartItem;
  category:
    | MenuModels.Category
    | undefined
    | MenuModels.Product
    | MenuModels.MultiPart;
  onClose: () => void;
  onBack: () => void;
  editMode: ProductConst.EditMode;
  index?: number;
  commonSection?: MenuModels.CommonSection;
  enableGoBackMode?: boolean;
  isUpsell?: boolean;
}

const ProductTemplateSelector = (
  props: ProductTemplateSelectorProps
): JSX.Element => {
  const {
    category,
    cartItem,
    productType,
    onClose,
    onBack,
    editMode,
    index,
    commonSection,
    enableGoBackMode,
    isUpsell,
  } = props;

  const dispatch = useDispatch();

  const handleBackNavigation = () => {
    // reset bundle item state
    dispatch(CartReduxAction.clearBundleItem());
    onBack();
  };

  const renderProductView = () => {
    switch (productType) {
      case ProductConst.ProductRoute.SimpleCustomisable:
        return (
          <SimpleCustomisableProduct
            key={category?.name || index}
            category={category as MenuModels.Category}
            onClose={onClose}
            goBack={onBack}
            editMode={editMode}
            cartItem={cartItem}
            index={index}
            enableGoBackMode={enableGoBackMode}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.NonCustomization:
        return (
          <NonCustomisableProduct
            key={category?.name || index}
            editMode={editMode}
            product={category as MenuModels.Product}
            goBack={onBack}
            cartItem={cartItem}
            {...MenuModuleUtils.getProductSizeAndTitle(
              category as MenuModels.Product
            )}
            index={index}
            enableGoBackMode={enableGoBackMode}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.ComplexCustomisable:
        return (
          <CustomizableProductContainer
            key={category?.name || index}
            onClose={onClose}
            index={index}
            editMode={editMode}
            goBack={onBack}
            commonSection={commonSection}
            category={category as MenuModels.Category}
            cartItem={cartItem}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.ComplexCustomisableWithMeal:
        return (
          <CustomizableProductContainer
            key={category?.name || index}
            onClose={onClose}
            index={index}
            editMode={editMode}
            goBack={onBack}
            category={category as MenuModels.Category}
            cartItem={cartItem}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.HotDrinks:
        return (
          <CustomizableProductContainer
            key={category?.name || index}
            onClose={onClose}
            index={index}
            editMode={editMode}
            goBack={onBack}
            commonSection={commonSection}
            category={category as MenuModels.Category}
            cartItem={cartItem}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.Tacos:
        return (
          <TacoProductContainer
            key={category?.name || index}
            editMode={editMode}
            goBack={onBack}
            onClose={onClose}
            commonSection={commonSection}
            category={category as MenuModels.Category}
            cartItem={cartItem}
            isUpsell={isUpsell}
            index={index}
            enableGoBackMode={enableGoBackMode}
          />
        );
      case ProductConst.ProductRoute.Bundles:
      case ProductConst.ProductRoute.KidsBundle:
        return (
          <ProductBundleContainer
            editMode={editMode}
            goBack={handleBackNavigation}
            multiPart={category as MenuModels.MultiPart}
            index={index}
            quantity={cartItem?.quantity}
            isUpsell={isUpsell}
          />
        );
      case ProductConst.ProductRoute.MIAM:
        return (
          <MIAMProductContainer
            key={category?.name || index}
            editMode={editMode}
            goBack={onBack}
            onClose={onClose}
            index={index as number}
            commonSection={commonSection as MenuModels.CommonSection}
            category={category as MenuModels.Category}
            cartItem={cartItem as CartReduxModels.CartItem}
            isUpsell={isUpsell}
          />
        );
      default:
        return <View />;
    }
  };
  return <>{renderProductView()}</>;
};

export default ProductTemplateSelector;
