import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputType, UserReduxModels } from "gyg_common";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import FormInput from "gyg_common/components/FormInput";
import PasswordValidation from "gyg_common/components/PasswordValidation";
import { EditModeType } from "views/containers/UserManagement/UserProfileContainer";

export interface UpdatePasswordProps {
  email: string;
  type: UserReduxModels.UpdatePasswordType;
  onEditMode: EditModeType | undefined;
  newPasswordErrorMessage: string;
  currentPassword: string;
  currentPasswordError: boolean;
  currentPasswordErrorMessage: string;
  newPassword: string;
  confirmPassword: string;
  newPasswordError: boolean;
  confirmPasswordError: boolean;
  setCurrentPassword: (text: string) => void;
  setNewPassword: (text: string) => void;
  setConfirmPassword: (text: string) => void;
  onChangeClick: () => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

export const UpdatePassword: React.FC<UpdatePasswordProps> = ({
  email,
  type,
  onEditMode,
  newPasswordErrorMessage,
  currentPassword,
  currentPasswordError,
  currentPasswordErrorMessage,
  newPassword,
  confirmPassword,
  newPasswordError,
  confirmPasswordError,
  setCurrentPassword,
  setNewPassword,
  setConfirmPassword,
  onChangeClick,
  onSaveClick,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

  const isSaveDisabled = useMemo(() => {
    if (type === UserReduxModels.UpdatePasswordType.set) {
      return newPassword === "" || confirmPassword === "";
    }

    return (
      currentPassword === "" || newPassword === "" || confirmPassword === ""
    );
  }, [confirmPassword, currentPassword, newPassword, type]);

  useEffect(() => {
    setIsInEditMode(onEditMode === EditModeType.PASSWORD);
  }, [onEditMode]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isSaveDisabled) {
      onSaveClick();
    }
  };

  return (
    <div className='change-password-wrapper space-between-rows '>
      <div className='user__wrapper-space-between'>
        <h2 className='user-profile-main__section-title'>
          {type === UserReduxModels.UpdatePasswordType.change
            ? t("Dashboard:chnagePassBtn")
            : t("UserManagement:setSigninPasswordLabel")}
        </h2>

        {isInEditMode ? (
          <button className='input-label__cancel' onClick={onCancelClick}>
            {t("UserManagement:cancelLabel")}
          </button>
        ) : (
          <button
            className='input-label__edit'
            onClick={onChangeClick}
            disabled={!!onEditMode}>
            <img
              className={!onEditMode ? "logo--pencil" : "logo--pencil-disabled"}
              src={editIcon}></img>
            <a
              className={
                !onEditMode ? "button-label" : "button-label-disabled"
              }>
              {t("UserManagement:changeLabel")}
            </a>
          </button>
        )}
      </div>

      {type === UserReduxModels.UpdatePasswordType.change && (
        <p className='user__change-password-desc'>
          {t("UserManagement:changePasswordDescription")}
        </p>
      )}

      {type === UserReduxModels.UpdatePasswordType.set && (
        <p className='user__change-password-username'>
          {t("UserManagement:changePasswordUsername", { email: email })}
        </p>
      )}

      {isInEditMode && (
        <div>
          <form
            style={{ display: "contents" }}
            autoComplete='on'
            onSubmit={handleSubmit}>
            {type === UserReduxModels.UpdatePasswordType.change && (
              <FormInput
                type={InputType.PASSWORD}
                name={t("UserManagement:currentPasswordLabel")}
                testId={t("UserManagement:currentPasswordLabel")}
                handleChangeText={setCurrentPassword}
                value={currentPassword}
                placeHolder={t("UserManagement:passwordPlaceholder")}
                error={currentPasswordError}
                errorMsg={currentPasswordErrorMessage}
                disableClipboard
              />
            )}

            <FormInput
              type={InputType.PASSWORD}
              name={t("UserManagement:newPasswordLabel")}
              testId={t("UserManagement:newPasswordLabel")}
              handleChangeText={setNewPassword}
              value={newPassword}
              placeHolder={t("UserManagement:passwordPlaceholder")}
              error={newPasswordError}
              errorMsg={newPasswordErrorMessage}
              disableClipboard
            />

            <PasswordValidation value={newPassword} />

            <FormInput
              type={InputType.PASSWORD}
              name={t("UserManagement:confirmPasswordLabel")}
              testId={t("UserManagement:confirmPasswordLabel")}
              handleChangeText={setConfirmPassword}
              value={confirmPassword}
              placeHolder={t("UserManagement:passwordPlaceholder")}
              error={confirmPasswordError}
              errorMsg={t("UserManagement:passwordMatchError")}
              disableClipboard
            />

            <PrimaryBlackButton
              testId={t("UserManagement:saveLabel")}
              disable={isSaveDisabled}
              border={false}
              onClick={onSaveClick}
              buttonName={t("UserManagement:saveLabel")}
            />
          </form>
        </div>
      )}
    </div>
  );
};
