import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageURISource,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import * as CartItemsCalculator from "../../modules/Cart/itemsCalculator";
import * as FavouriteModules from "../../modules/Favourite";
import * as PlatformUtils from "../../modules/platformUtils";
import * as CartReduxModels from "../../redux_store/cart/models";
import * as FavouriteModels from "../../redux_store/favourite/models";
import * as MenuModels from "../../redux_store/menu/models";
import heartEmptyIcon from "../assets/icons/icon_heart.png";
import heartIcon from "../assets/icons/icon_heart_filled.png";
import tagIcon from "../assets/icons/icon_rename.png";
import defaultImage from "../assets/images/no_image_available_menu.png";
import SecondaryButton from "../Buttons/SecondaryButton";
import CardSticker from "../CardSticker";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { CAROUSEL_SIZES } from "../styles/shared";
import { Typography } from "../styles/typography";
import FavouriteCustomisationList from "./FavouriteCustomisationList";

const favouriteHeadingNumberLines = 3;

const containerStyles = (dynamicHeight: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: Spacing.Thin,
      height: dynamicHeight ? null : CAROUSEL_SIZES.STANDARD,
      minHeight: Platform.OS === "web" ? CAROUSEL_SIZES.STANDARD : null,
      backgroundColor: colours.white,
      borderRadius: BorderRadius.Medium,
      shadowColor: colours.black,
      justifyContent: "space-between",
    },
  });

const styles = StyleSheet.create({
  subContainer: {
    backgroundColor: colours.white,
    borderRadius: BorderRadius.Medium,
    flexDirection: "row",
    justifyContent: "flex-start",
    flexShrink: 1,
  },
  subContainerParts: {
    paddingLeft: Spacing.Light,
  },
  productImage: {
    width: 110,
    height: 88,
    borderRadius: BorderRadius.Medium,
  },
  productInfo: {
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: Spacing.Light,
    flex: 1,
  },
  titleWrapper: {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  iconContainer: {
    flexGrow: 1,
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "flex-end",
    paddingRight: Spacing.Light,
    marginLeft: Spacing.Thin,
    flex: 1,
  },
  headingWrapper: {
    flexWrap: "wrap",
    flexDirection: "row",
    flex: 2,
    alignItems: "center",
  },
  menuItemHeading: {
    ...Typography.smallProductTitle,
    width: "100%",
    marginTop: Spacing.Thin,
  },
  subMenuItemHeading: {
    ...Typography.smallProductTitle,
    marginTop: Spacing.Thin,
    fontSize: Typography.headline.fontSize,
  },
  heartIcon: {
    width: 32,
    height: 32,
  },
  tagIcon: {
    width: 32,
    height: 32,
    marginRight: Spacing.Thin,
  },
  buttonContainer: {
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Thin,
    flexGrow: 1,
    justifyContent: "flex-end",
  },
});

export interface FavouriteCardProps {
  allMenuIDs: number[];
  favouriteCartItem: FavouriteModels.FavouriteCartItem;
  isFavouriteItem: boolean;
  menuStructure?: MenuModels.MenuStructure;
  onRenameTagPress: (
    selectedFavourite: FavouriteModels.FavouriteCartItem
  ) => void;
  onAddToCart: (cartItem: CartReduxModels.CartItem) => void;
  setYourFavourite: (type: FavouriteModels.CRUDType) => void;
  numberOfExtras?: number;
}

export const FavouriteCard = (props: FavouriteCardProps): JSX.Element => {
  const {
    allMenuIDs,
    favouriteCartItem,
    isFavouriteItem,
    menuStructure,
    onAddToCart,
    onRenameTagPress,
    setYourFavourite,
    numberOfExtras,
  } = props;
  const { t } = useTranslation();

  const oneLineHeight = 24;
  const defaultNumberofExtras =
    (numberOfExtras ?? favouriteCartItem.miamItem) ? 2 : 5;

  const price = useMemo(() => {
    if (!favouriteCartItem.cartItem) {
      return 0;
    }

    const itemToCalculate = {
      ...favouriteCartItem.cartItem,
      miamItem: favouriteCartItem.miamItem,
    };

    return (
      CartItemsCalculator.calculateItemUnitPrice([itemToCalculate], 0) ||
      favouriteCartItem.cartItem?.price
    );
  }, [favouriteCartItem.cartItem, favouriteCartItem.miamItem]);

  const isFavouriteItemAvailable = FavouriteModules.checkAvailableFavourite(
    allMenuIDs,
    [favouriteCartItem]
  );

  const [numOfExtras, setNumOfExtras] = useState<number>(defaultNumberofExtras);
  const [titleHeight, setTitleHeight] = useState<number>(0);

  /**
   * Set the number of extras based on the height of the title
   * One line height = 24
   */
  useEffect(() => {
    const height = Math.floor(titleHeight);

    switch (height) {
      case oneLineHeight * 3:
        setNumOfExtras(3);
        break;

      case oneLineHeight * 2:
        setNumOfExtras(4);
        break;

      case oneLineHeight:
        setNumOfExtras(defaultNumberofExtras);
        break;
    }
  }, [defaultNumberofExtras, titleHeight]);

  const onOrderPress = () => {
    if (favouriteCartItem?.cartItem) {
      onAddToCart({
        ...favouriteCartItem?.cartItem,
        miamItem: favouriteCartItem.miamItem || undefined,
      });
    }
  };

  const onRenamePress = () => {
    onRenameTagPress(favouriteCartItem);
  };

  const onFavouritePress = () => {
    setYourFavourite(
      isFavouriteItem
        ? FavouriteModels.CRUDType.delete
        : FavouriteModels.CRUDType.add
    );
  };

  const onTitleLayout = (titleLayoutEvent: LayoutChangeEvent) => {
    setTitleHeight(titleLayoutEvent.nativeEvent.layout.height);
  };

  return (
    <CardSticker>
      <View
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `FavouriteItem-${favouriteCartItem?.favouriteId}`
        )}
        style={containerStyles(!numberOfExtras).container}>
        <View
          style={[
            styles.subContainer,
            favouriteCartItem?.cartItem?.parts && styles.subContainerParts,
          ]}>
          {/** Side image for simple, customisable or non-customisable */}
          {!favouriteCartItem?.cartItem?.parts && (
            <View style={styles.productImage}>
              <Image
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  `FavouriteImage-${favouriteCartItem?.favouriteId}`
                )}
                source={
                  favouriteCartItem?.imageTopDownUrl?.length
                    ? { uri: favouriteCartItem?.imageTopDownUrl }
                    : (defaultImage as ImageURISource)
                }
                style={styles.productImage}
              />
            </View>
          )}

          <View style={styles.productInfo}>
            <View style={styles.titleWrapper}>
              <View style={styles.headingWrapper}>
                <Text
                  onLayout={onTitleLayout}
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    `FavouriteHeading-${favouriteCartItem?.favouriteId}`
                  )}
                  numberOfLines={
                    numberOfExtras ? 1 : favouriteHeadingNumberLines
                  }
                  style={styles.menuItemHeading}>
                  {favouriteCartItem?.label}
                </Text>
              </View>

              {/** Clickable icons */}
              <View style={styles.iconContainer}>
                {isFavouriteItem && (
                  <TouchableOpacity
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      "FavouriteRenameTag-" + favouriteCartItem?.favouriteId
                    )}
                    onPress={onRenamePress}>
                    <Image
                      source={tagIcon as ImageURISource}
                      style={styles.tagIcon}
                    />
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "FavouriteIcon-" + favouriteCartItem?.favouriteId
                  )}
                  onPress={onFavouritePress}>
                  <Image
                    source={
                      (isFavouriteItem
                        ? heartIcon
                        : heartEmptyIcon) as ImageURISource
                    }
                    style={styles.heartIcon}
                  />
                </TouchableOpacity>
              </View>
            </View>

            {favouriteCartItem.cartItem && (
              <FavouriteCustomisationList
                cartItem={favouriteCartItem.cartItem}
                menuStructure={menuStructure}
                numberOfExtras={
                  !favouriteCartItem.miamItem && numberOfExtras
                    ? 6
                    : numOfExtras
                }
                expandable={!numberOfExtras}
              />
            )}

            {favouriteCartItem.miamItem && (
              <>
                <Text
                  onLayout={onTitleLayout}
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    `FavouriteHeadingMiam-${favouriteCartItem?.favouriteId}`
                  )}
                  numberOfLines={favouriteHeadingNumberLines}
                  style={styles.subMenuItemHeading}>
                  {favouriteCartItem.miamItem.name}
                </Text>
                <FavouriteCustomisationList
                  cartItem={favouriteCartItem.miamItem}
                  menuStructure={menuStructure}
                  numberOfExtras={numOfExtras}
                  expandable={!numberOfExtras}
                />
              </>
            )}
          </View>
        </View>

        <View style={styles.buttonContainer}>
          {isFavouriteItemAvailable && price ? (
            <SecondaryButton
              testId={`FavouriteButton-${favouriteCartItem?.favouriteId}`}
              buttonName={t("Favourite:addToOrder")}
              onClick={onOrderPress}
              price={price}
            />
          ) : (
            <SecondaryButton
              disable={true}
              testId={`FavouriteButton-${favouriteCartItem?.favouriteId}`}
              buttonName={t("Favourite:itemUnavailable")}
              onClick={onOrderPress}
            />
          )}
        </View>
      </View>
    </CardSticker>
  );
};
