import "./styles.scss";

import barcodeIcon from "assets/icons/icon_barcode.svg";
import gygYellowLogo from "assets/images/gyg_abrv_yellow.png";
import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { CartReduxModels } from "gyg_common";
import gygBrandLogo from "gyg_common/components/assets/images/logo_primary_gyg.png";
import CardSticker from "gyg_common/components/CardSticker";
import IconButton from "gyg_common/components/IconButton";
import { Screens } from "navigation/const";
import CartYellowButton from "views/components/shared/Buttons/CartYellowButton";
import HeaderAccountContainer from "views/containers/shared/Header/HeaderAccountContainer";

export interface HeaderProps {
  totalPrice: number;
  items: CartReduxModels.CartItem[];
  bottomComponentNotScrollable?: JSX.Element;
  bottomComponentScrollable?: JSX.Element;
  pageTitle?: string;
  showBarcode?: boolean;
  viewCartClick: () => void;
  onMenuClick: () => void;
  onBarcodeClick: () => void;
  showIconAndPrice?: number | false | null;
  hideCartButton?: boolean;
  cartButtonLoading?: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { isTabletScreen } = useMediaQuery();
  const {
    bottomComponentNotScrollable,
    bottomComponentScrollable,
    pageTitle,
    items,
    showBarcode,
    hideCartButton,
    showIconAndPrice,
    onMenuClick,
    viewCartClick,
    onBarcodeClick,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <header id='header' className='header'>
      <div className='header--black'>
        <div className='header__top main-wrapper'>
          <div className='header__col'>
            <button onClick={() => navigate(Screens.Dashboard)}>
              <img
                src={isTabletScreen ? gygBrandLogo : gygYellowLogo}
                className='header__logo'
              />
            </button>
            <button
              onClick={onMenuClick}
              className={`header__menu ${
                location.pathname === Screens.Menu ? "header__menu--active" : ""
              }`}>
              {t("OrderManagement:menuTitle")}
            </button>
          </div>

          <div className='header__col'>
            <HeaderAccountContainer />
            {!hideCartButton && (
              <div
                className='header__cart-button-wrapper'
                data-dd-action-name='view-cart'>
                <CartYellowButton
                  buttonName={t("OrderManagement:viewOrderBtn")}
                  onClick={viewCartClick}
                  items={items}
                  showIconAndPrice={showIconAndPrice}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {(bottomComponentNotScrollable ||
        bottomComponentScrollable ||
        pageTitle) && (
        <div className='header--white'>
          <div className='header__bottom main-wrapper'>
            {(pageTitle || showBarcode) && (
              <div className='header__title-wrapper'>
                {showBarcode && (
                  <div className='header__barcode'>
                    {isTabletScreen ? (
                      <button
                        onClick={onBarcodeClick}
                        className='header__barcode-btn'>
                        <img
                          className='header__barcode-icon'
                          src={barcodeIcon}
                        />
                        <p className='header__barcode-text'>
                          {t("Dashboard:barCodeBtn")}
                        </p>
                      </button>
                    ) : (
                      <CardSticker>
                        <IconButton
                          onPress={onBarcodeClick}
                          iconImage={barcodeIcon}
                        />
                      </CardSticker>
                    )}
                  </div>
                )}
                {pageTitle && <p className='header__title-text'>{pageTitle}</p>}
              </div>
            )}

            {bottomComponentNotScrollable && (
              <div className='header__bottom--static'>
                {bottomComponentNotScrollable}
              </div>
            )}
            {bottomComponentScrollable && (
              <div
                id='scrollable-header-white'
                data-testid='scrollable-header-white'
                className='header__bottom--scrolled'>
                {bottomComponentScrollable}
              </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
