import { useActionSheet } from "@expo/react-native-action-sheet";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { useDispatch } from "react-redux";

import { ApiOrderModel, LoyaltyActions, PlatformUtils } from "gyg_common";

import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    backgroundColor: colours.lightestGrey,
  },
  taxView: {
    backgroundColor: colours.lightestGrey,
    paddingHorizontal: Spacing.Light,
    paddingBottom: Spacing.Light,
    justifyContent: "center",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
  },
  rewardView: {
    backgroundColor: colours.lightestGrey,
    margin: Spacing.Thin,
    paddingHorizontal: Spacing.Thin,
    paddingTop: Spacing.ExtraLight,
    paddingBottom: Spacing.Thin,
    justifyContent: "center",
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    borderColor: colours.yellow,
    borderWidth: 1,
    borderRadius: BorderRadius.Medium,
  },
  subtotalView: {
    paddingTop: Spacing.Light,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: colours.lightGrey,
  },
  // TODO: Border top supposed to be dashed similar to web,
  // but dashed border on one side only is not supported in this RN version
  // https://github.com/facebook/react-native/pull/29099
  totalView: {
    backgroundColor: colours.lightestGrey,
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Regular,
    justifyContent: "center",
    paddingTop: 18,
    flexDirection: "row-reverse",
    alignItems: "center",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: colours.lightGrey,
  },
  orderTitleOne: {
    marginTop: 2,
    ...Typography.titleOne,
  },
  orderTitleTwo: {
    ...Typography.titleTwo,
    textTransform: "none",
  },
  orderTitleThree: {
    ...Typography.titleThree,
    textTransform: "none",
  },
  split: {
    flex: 1,
  },
  surchargesDescription: {
    ...Typography.titleThree,
    textTransform: "none",
    width: "70%",
  },
});

export interface OrderReceiptProps {
  gygDollars: number | null;
  activeReward?: number | null;
  surcharges?: ApiOrderModel.Surcharge[];
  tax?: number;
  total: number;
  subtotal: number;
  basketValue: number;
  displayTax?: boolean;
  deliveryFee?: number;
  allowVoucherCode?: boolean;
}

const OrderReceipt: React.FC<OrderReceiptProps> = ({
  subtotal,
  total,
  surcharges,
  gygDollars,
  activeReward,
  tax,
  basketValue,
  displayTax,
  deliveryFee,
  allowVoucherCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showActionSheetWithOptions } = useActionSheet();

  const deliveryFeeDollars = deliveryFee ?? 0;

  const removeRewards = () => {
    const options = [
      t("Loyalty:removeOffer"),
      t("CheckoutPayment:cancelDeletionButton"),
    ];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (selectedIndex: number) => {
        switch (selectedIndex) {
          case destructiveButtonIndex:
            dispatch(LoyaltyActions.clearActiveReward());
            break;
        }
      }
    );
  };

  const renderReward = () => {
    if (!activeReward) {
      return null;
    }

    const label = (
      <>
        <Text style={styles.orderTitleThree}>
          {`-$${activeReward.toFixed(2)}`}
        </Text>
        <View style={styles.split} />
        <Text style={styles.orderTitleThree}>
          {t("CheckoutPayment:rewardsLabel")}
        </Text>
      </>
    );

    if (!allowVoucherCode) {
      return <View style={styles.taxView}>{label}</View>;
    }

    return (
      <Pressable style={styles.rewardView} onPress={removeRewards}>
        {label}
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.taxView}>
        <Text style={styles.orderTitleTwo}>${basketValue.toFixed(2)}</Text>
        <View style={styles.split} />
        <Text style={styles.orderTitleTwo}>
          {t("CheckoutPayment:orderValueLabel")}
        </Text>
      </View>

      {deliveryFee != undefined && (
        <View style={styles.taxView}>
          <Text style={styles.orderTitleTwo}>
            ${deliveryFeeDollars.toFixed(2)}
          </Text>
          <View style={styles.split} />
          <Text style={styles.orderTitleTwo}>
            {t("CheckoutPayment:checkoutDeliveryFeeTitle")}
          </Text>
        </View>
      )}

      {renderReward()}

      {surcharges?.map((surcharge: ApiOrderModel.Surcharge, index: number) => {
        return (
          <View style={styles.taxView} key={index}>
            <Text style={styles.orderTitleThree}>
              {`$${surcharge.amount.toFixed(2)}`}
            </Text>
            <View style={styles.split} />
            <Text
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "SurchargeDescription&Percentage"
              )}
              style={styles.surchargesDescription}>
              {surcharge.name}
            </Text>
          </View>
        );
      })}
      {!!gygDollars && (
        <View style={styles.taxView}>
          <Text style={styles.orderTitleThree}>
            {`-$${gygDollars.toFixed(2)}`}
          </Text>
          <View style={styles.split} />
          <Text style={styles.orderTitleThree}>
            {t("CheckoutPayment:GYGdollarsLabel")}
          </Text>
        </View>
      )}

      {!!displayTax && (
        <View style={styles.subtotalView}>
          <View style={styles.taxView}>
            <Text
              {...PlatformUtils.generateTestID(Platform.OS, "TotalWithoutTax")}
              style={styles.orderTitleTwo}>
              ${subtotal.toFixed(2)}
            </Text>
            <View style={styles.split} />
            <Text style={styles.orderTitleTwo}>
              {t("CheckoutPayment:subTotalLabel")}
            </Text>
          </View>
          {!!tax && (
            <View style={styles.taxView}>
              <Text
                {...PlatformUtils.generateTestID(Platform.OS, "Tax")}
                style={styles.orderTitleTwo}>
                {`$${tax.toFixed(2)}`}
              </Text>
              <View style={styles.split} />
              <Text style={styles.orderTitleThree}>
                {t("CheckoutPayment:taxLabel")}
              </Text>
            </View>
          )}
        </View>
      )}

      <View style={styles.totalView}>
        <Text style={styles.orderTitleOne}>{`$${total.toFixed(2)}`}</Text>
        <View style={styles.split} />
        <Text
          {...PlatformUtils.generateTestID(Platform.OS, "TotalPrice")}
          style={styles.orderTitleOne}>
          {t("CheckoutPayment:totalLabel")}
        </Text>
      </View>
    </View>
  );
};

export default OrderReceipt;
