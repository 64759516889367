import config from "../../../config";
import {
  CurrentSocialResponse,
  SocialProps,
} from "../../../redux_store/social/models";
import { httpClient } from "../../httpClient";

export async function getSocialSignIn(
  data: SocialProps
): Promise<CurrentSocialResponse> {
  try {
    // console.debug("API: POST auth/socialsignin");

    const response = await httpClient({
      url: `auth/socialsignin`,
      method: "POST",
      data: data,
    });

    const responseData = response.data as CurrentSocialResponse;

    config.accessToken = responseData.accessToken;
    config.refreshToken = responseData.refreshToken;
    return responseData;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/socialsignin");
    throw e;
  }
}
