import { Platform, StyleSheet } from "react-native";

import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    borderRadius: BorderRadius.Medium,
    overflow: "hidden",
  },
  container: {
    flex: 1,
    padding: Spacing.Light,
  },
  orderInfoContainer: {
    flex: Platform.OS == "web" ? 1 : undefined,
  },
  desktopContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mobileButtonContainer: {
    width: "100%",
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  desktopButtonContainer: {
    width: "50%",
    flexDirection: "row",
    alignSelf: "flex-end",
    paddingTop: Spacing.Medium,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    ...Typography.titleTwo,
    marginBottom: Spacing.Thin,
  },
  orderStatus: {
    marginBottom: Spacing.ExtraThin,
    alignSelf: "flex-start",
  },
  price: {
    ...Typography.bodyBold,
  },
  orderDetails: {
    flexDirection: "row",
    alignItems: "center",
  },
  orderDetailsWeb: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 0,
  },
  orderDetailsContainer: {
    flexDirection: "row",
    marginTop: Spacing.ExtraThin,
    justifyContent: "space-between",
  },
  orderDetailText: {
    ...Typography.bodyTwoBold,
    alignSelf: "center",
  },
  locationIcon: {
    width: 24,
    height: 24,
    marginLeft: -Spacing.ExtraThin,
  },
  icon: {
    width: 24,
    height: 18,
  },
  deliveryIcon: {
    width: 20,
    height: 15,
    marginRight: Spacing.Thin,
    marginLeft: Spacing.Narrow,
  },
  productName: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    marginVertical: Spacing.Thin,
  },
  productNameDesktop: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    marginVertical: Spacing.Thin,
    paddingTop: Spacing.Thin,
  },
  buttonContainer: {
    flexDirection: "row",
    flex: 1,
    marginTop: Spacing.Narrow,
  },
  primaryButton: {
    flex: 0.5,
    marginLeft: Spacing.Thin,
  },
  secondaryButton: {
    flex: 0.5,
    marginRight: Spacing.Thin,
  },
});

export const statusBarStyle = (
  bgColor: string,
  isTabletScreen: boolean | undefined
) =>
  StyleSheet.create({
    statusBar: {
      backgroundColor: bgColor,
      flex: isTabletScreen ? 0.015 : 0.025,
    },
  });
