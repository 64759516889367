import { StyleSheet } from "react-native";
import { Spacing } from "../../styles/number";

export const styles = StyleSheet.create({
  icon: {
    width: 35,
    height: 35,
    marginRight: Spacing.Thin,
  },
});
