import "./styles.scss";

import React from "react";
import { useTranslation } from "react-i18next";

import {
  CheckoutModels,
  GuestDetailModels,
  LoyaltyModels,
  OrderModules,
} from "gyg_common";
import CardSticker from "gyg_common/components/CardSticker";
import LoyaltyBonusCard from "gyg_common/components/LoyaltyBonusCard";
import OrderStatusInfo from "gyg_common/components/OrderStatus/OrderStatusInfo";
import OrderStatusMap from "gyg_common/components/OrderStatus/OrderStatusMap";
import { OrderStatusLabelProps } from "gyg_common/components/OrderStatusLabel";
import { GetOrderResponse } from "gyg_common/services/api/order/model";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import { FlyingBurritoLoader } from "../shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";
import OrderStatusGomex from "./OrderStatusGomex";
import OrderStatusReceipt from "./OrderStatusReceipt";

const { OrderUtils } = OrderModules;

export interface OrderStatusProps {
  loyaltyBonusPoints: number;
  loyaltyBonusDollars: number;
  data?: CheckoutModels.CheckoutResponse;
  status?: GetOrderResponse | null;
  guestLoyalty: GuestDetailModels.GuestLoyalty;
  locale: string;
  orderStatusLabel: OrderStatusLabelProps;
  canShowOrderNumber: boolean;
  isFailed: boolean;
  isLoggedIn: boolean;
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  navigateToStoreDetails: () => void;
  goToLogin: () => void;
  deliveryFee?: number;
  deliveryTrackingURL?: string;
}

const OrderStatus: React.FC<OrderStatusProps> = ({
  loyaltyBonusDollars,
  loyaltyBonusPoints,
  data,
  guestLoyalty,
  locale,
  orderStatusLabel,
  canShowOrderNumber,
  isFailed,
  isLoggedIn,
  loyalty,
  coffeeLoyalty,
  status,
  navigateToStoreDetails,
  goToLogin,
}) => {
  const { t } = useTranslation();

  const showLoyaltyBonusCard =
    isLoggedIn && (loyaltyBonusDollars || loyaltyBonusPoints);

  return (
    <div className='order-status'>
      <HeaderContainer pageTitle={t("OrderStatus:title")} />
      {!data && <FlyingBurritoLoader />}

      {data && (
        <div className='main-wrapper main-wrapper--narrow'>
          <div className='main-wrapper__col'>
            <OrderStatusInfo
              orderId={data.order.orderNumber}
              orderDate={data.order.pickupTime}
              storeName={data.store.name}
              orderType={data.orderCollectionType}
              orderStatusLabel={orderStatusLabel}
              tableNumber={data.order.tableNumber}
              timeZone={data.store.timezoneInfo.storeTimeZone}
              isOrderASAP={OrderUtils.isOrderASAP(
                data.order.pickupTime,
                data.order.timeSubmitted
              )}
              canShowOrderNumber={canShowOrderNumber}
              isFailed={isFailed}
            />
            <div className='order-status-map'>
              <OrderStatusMap
                storeAddress={data.store.address}
                storeId={parseInt(data.store.id) ?? 0}
                storeName={data.store.name}
                navigateToStoreDetails={navigateToStoreDetails}
              />
            </div>
          </div>
          <div className='main-wrapper__col'>
            <CardSticker>
              <OrderStatusReceipt
                storeName={data.store.name}
                locale={locale}
                order={data.order}
                paymentTypes={data.payment}
                deliveryFee={status?.delivery?.fee ?? data.order.delivery?.fee}
              />
            </CardSticker>
            {!!showLoyaltyBonusCard && (
              <LoyaltyBonusCard
                loyaltyBonusDollars={loyaltyBonusDollars}
                loyaltyBonusPoints={loyaltyBonusPoints}
              />
            )}
            <OrderStatusGomex
              isUserAuthenticated={isLoggedIn}
              loyalty={loyalty ?? null}
              guestLoyalty={guestLoyalty}
              coffeeLoyalty={coffeeLoyalty}
              goToLogin={goToLogin}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
