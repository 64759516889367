import React from "react";

export interface NutritionalInfoDisclaimerProps {
  text: string;
  transparent?: boolean;
  position?: number;
  displayPosition?: "absolute" | "relative" | "fixed" | "sticky";
}

const NutritionalInfoDisclaimer: React.FC<NutritionalInfoDisclaimerProps> = ({
  text,
  transparent,
  position,
  displayPosition = "sticky",
}) => {
  return (
    <div
      data-testid='NutritionalInfoDisclaimer'
      id='NutritionalInfoDisclaimer'
      style={{
        top: `${position || 0}px`,
        position: displayPosition,
      }}
      className={
        transparent
          ? "menu-nutrition-info__content-transparent"
          : "menu-nutrition-info__content"
      }>
      <p className='menu-nutrition-info__text'>{text}</p>
    </div>
  );
};

export default NutritionalInfoDisclaimer;
