import moment from "moment";

import config from "../config";
import { locales } from "../locales/locales.enum";

interface ValidatePhoneProps {
  isValid: boolean;
  region: locales | null;
}

export const validateEmail = (input: string): boolean => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !reg.test(input);
};

export const isInternationalNumber = (input: string): boolean => {
  return input.charAt(0) === "+";
};

export const validateName = (input: string): boolean => {
  const reg = /^[a-z ,.'-]+$/i;
  return !reg.test(input);
};

export const validatePhone = (
  input: string,
  locale?: string
): ValidatePhoneProps => {
  let appVersion = locale ? locale : config.version;
  if (!appVersion) {
    appVersion = locales.AU;
  }
  const isAUAppType =
    appVersion === locales.AU && !isInternationalNumber(input);
  const isUSAppType =
    appVersion === locales.US && !isInternationalNumber(input);
  const isValidLength = input.length <= 15;

  if (isAUAppType) {
    const reg = /^(\+61|61)?\s?\(?\d{1,2}\)?\s?\d{4}[-\s]?\d{4}$/;
    return {
      isValid: reg.test(input) && isValidLength && !isNaN(Number(input)),
      region: locales.AU,
    };
  } else if (isUSAppType) {
    const reg = /^(\+1|1)?\s?\(?\d{3}\)?\s?\d{3}[-\s]?\d{4}$/;
    return {
      isValid: reg.test(input) && isValidLength,
      region: locales.US,
    };
  } else {
    const reg = /^\+(?:[0-9]●?){6,14}[0-9]$/;
    return {
      isValid: reg.test(input) && isInternationalNumber(input) && isValidLength,
      region: null,
    };
  }
};

/**
 * Returns true if DOB is not valid.
 * @param input
 * @returns
 */
export const validateBirthday = (input: string): boolean => {
  const expectedDateFormat =
    config.version === locales.US ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const reg = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  const year = input.substring(6, 10);
  const age = new Date().getFullYear() - parseInt(year);
  const date = moment(input, expectedDateFormat, true);
  return !(reg.test(input) && age >= 10 && date ? date.isValid() : false);
};

export const isEightChars = (text: string): boolean => {
  return text.length >= 8;
};

export const hasLowerCase = (text: string): boolean => {
  return text.toUpperCase() !== text;
};

export const hasUpperCase = (text: string): boolean => {
  return text.toLowerCase() !== text;
};

export const hasNumber = (text: string): boolean => {
  return /\d/.test(text);
};

export const hasWhiteSpace = (text: string): boolean => {
  return /\s/g.test(text);
};

export const passPasswordRegex = (text: string): boolean => {
  // Double check the password against the same regex as the backend
  return /^(?!.* )(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(text);
};

export const isPasswordValid = (text: string): boolean => {
  return (
    isEightChars(text) &&
    hasLowerCase(text) &&
    hasUpperCase(text) &&
    hasNumber(text) &&
    !hasWhiteSpace(text) &&
    passPasswordRegex(text)
  );
};

export const hasAlphanumeric = (text: string): boolean => {
  return /^\w+$/.test(text);
};
