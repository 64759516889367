import { StyleSheet } from "react-native";

import colours from "./colours";
import { Typography } from "./typography";

const MainBgStyle = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
  },
});

const lineStyle = StyleSheet.create({
  dashed: {
    borderTopWidth: 1,
    //borderStyle: "dashed",
    borderColor: colours.lightGrey,
    borderRadius: 1,
  },
  gradientWrapper: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
  },
  gradient: {
    flex: 1,
    height: 1,
  },
});

const tableStyle = StyleSheet.create({
  header: {
    marginTop: 8,
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  headerCol: {
    ...Typography.captionOne,
  },
  bodyRow: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginTop: 4,
    marginBottom: 4,
  },
  bodyCol: {
    ...Typography.titleThree,
  },
  bodyColSmall: {
    ...Typography.titleThree,
    fontSize: 16,
  },
  colProduct: {
    width: "50%",
  },
  colQty: {
    flex: 1,
    alignItems: "flex-end",
  },
  colPrice: {
    width: "28%",
    alignItems: "flex-end",
  },
});

const errorStyle = StyleSheet.create({
  caption: {
    ...Typography.captionOne,
    color: colours.red,
  },
});

export { errorStyle, lineStyle, MainBgStyle, tableStyle };
