import useAddToOrder from "hooks/useAddToOrder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxModels,
  MenuModels,
  PlatformUtils,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
  RootState,
} from "gyg_common";
import RadioButtonGroup, {
  RadioButtonGroupData,
} from "gyg_common/components/Buttons/Radio/RadioButtonGroup";
import { Checkbox } from "gyg_common/components/Checkbox";
import AddModifyCartItem from "gyg_common/components/Products/AddModifyCartItem";
import ProductDetail from "gyg_common/components/Products/ProductDetail";
import useUpdateOrder from "gyg_common/hooks/useUpdateOrder";
import { CartItem } from "gyg_common/redux_store/cart/models";
import { Typography } from "styles/typography";

export interface SimpleCustomisableProductProps {
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  goBack: () => void;
  onClose: () => void;
  cartItem?: CartReduxModels.CartItem;
  index?: number;
  enableGoBackMode?: boolean;
  isUpsell?: boolean;
}

const style = StyleSheet.create({
  wrapper: {
    flexDirection: "column",
    paddingTop: 8,
  },
  categoryRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  radioBtnSection: {
    paddingLeft: 16,
  },
  productRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  productInfo: {
    flex: 1,
    justifyContent: "center",
  },
  productTitle: {
    ...Typography.titleThree,
  },
  productCaption: {
    ...Typography.captionOne,
    marginTop: 4,
  },
  radioBtnLabel: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  greyText: {
    ...Typography.captionOne,
  },
});

const SimpleCustomisableProduct: React.FC<SimpleCustomisableProductProps> = ({
  category,
  goBack,
  cartItem,
  editMode,
  index,
  onClose,
  enableGoBackMode,
  isUpsell,
}) => {
  const { t } = useTranslation();
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const [quantity, setQuantity] = useState<number>(cartItem?.quantity || 1);
  const [radioButtonData, setRadioButtonData] = useState<
    RadioButtonGroupData<ProductModuleModel.SimpleCustomisableProductRadioButton>[]
  >([]);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [toggleSelection, setToggleSelection] = useState<
    ProductModuleModel.ToggleState[]
  >([]);
  const [cartProduct, setCartProduct] = useState<CartItem>();

  const handleUpdateOrder = useUpdateOrder(
    editMode,
    editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM ? onClose : goBack
  );
  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: category?.name ?? "",
    onClose,
  });

  const selectedRadioButton = radioButtonData.find(
    (radio) => radio.element.id === selectedProductId
  );
  const renderRadioItem = (
    element: ProductModuleModel.SimpleCustomisableProductRadioButton
  ) => (
    <View
      style={style.radioBtnLabel}
      {...PlatformUtils.generateTestID(
        Platform.OS,
        `simple-customisable-radio-button-option-${element.id}`
      )}>
      <View style={style.productInfo}>
        <Text style={style.productTitle}>{element.label}</Text>
        {element.nutritionalInfo && (
          <Text style={style.productCaption}>
            {element.nutritionalInfo}
            {t("OrderManagement:energyUnit")}
          </Text>
        )}
      </View>
      <View style={{ justifyContent: "center" }}>
        <Text style={style.greyText}>${element.price.toFixed(2)}</Text>
      </View>
    </View>
  );

  /**
   * Returns new array with updated switch value.
   * @param label switch label equal customisation
   * @param value boolean
   */
  const onToggleChange = (id: number, value: boolean) => {
    const newToggleSelection = toggleSelection.map((toggleItem) => {
      if (toggleItem.id === id) {
        toggleItem.value = value;
      }
      return toggleItem;
    });

    setToggleSelection(newToggleSelection);
  };

  // Applies radio buttons and toggle content.
  useEffect(() => {
    let newToggle;
    let modifiers;
    let selectedModifiersId;

    if (category.products) {
      if (
        (editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM ||
          editMode === ProductConst.EditMode.MODIFY_CART_ITEM) &&
        cartItem
      ) {
        const selectedProduct = category.products.find(
          (p) => p.id === cartItem.productId
        );

        if (selectedProduct) {
          setSelectedProductId(cartItem.productId);
          modifiers = selectedProduct.removeModifier;
        }
        if (cartItem.removeModifier) {
          selectedModifiersId = ProductUtils.getIdsFromModifier(
            cartItem.removeModifier
          );
        }
      } else {
        // auto select one available product indentifier (radio btn)
        if (category.products.length === 1) {
          setSelectedProductId(category.products[0].id);
        }
        modifiers = category.products[0].removeModifier;
      }
      newToggle = ProductUtils.setToggleData(
        category,
        toggleSelection,
        modifiers,
        selectedModifiersId
      );

      if (newToggle) {
        setToggleSelection(newToggle);
      }

      const radioData =
        ProductUtils.getSimpleCustomisableProductOptions(category);
      setRadioButtonData(radioData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, cartItem]);

  /**
   * Sets product selection when size or customisation changes.
   */
  useEffect(() => {
    // adds removeModifier when toggle is UNSELECTED
    const modifiers = toggleSelection
      .filter((toggle) => toggle.value === false)
      .map((toggle) => toggle.id);

    if (selectedProductId) {
      setCartProduct(
        ProductUtils.createCartItem(
          category,
          selectedProductId,
          quantity,
          false,
          modifiers
        )
      );
    }
  }, [selectedProductId, toggleSelection, quantity, category]);

  // Updates toggle content for selected product.
  useEffect(() => {
    const selectedProduct = category.products?.find(
      (p) => p.id === selectedProductId
    );
    if (selectedProduct && editMode === ProductConst.EditMode.ADD_CART_ITEM) {
      const newToggle = ProductUtils.setToggleData(
        category,
        toggleSelection,
        selectedProduct.removeModifier
      );
      if (newToggle) {
        setToggleSelection(newToggle);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductId]);

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.SimpleProduct, {
      product_id: category.id,
      product_name: category.name,
      product_quantity: quantity,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemSelection = (newSelectedProductId: number | string) => {
    setSelectedProductId(newSelectedProductId as number);
  };

  return (
    <div className='product-wrapper--small'>
      <ProductDetail
        key={category.name}
        setQuantity={
          editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM
            ? undefined
            : setQuantity
        }
        onGoBack={() => {
          goBack();
        }}
        gobackMode={
          enableGoBackMode ||
          editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM
            ? true
            : false
        }
        name={category.name}
        quantity={quantity}
        imageAngleUrl={category.imageAngleUrl}
        closeButtonPosition={
          editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM
            ? "left"
            : "right"
        }
        productNutritionalInfo={ProductUtils.getNutritionalInfo(
          radioButtonData,
          selectedRadioButton?.element
        )}
        showNutritionalDisclaimer={true}
        hidePrice
        cartItems={cartProduct ? [cartProduct] : []}
      />
      <View style={style.radioBtnSection}>
        {radioButtonData && (
          <RadioButtonGroup
            showGradientLine
            data={radioButtonData}
            onItemSelection={onItemSelection}
            renderItem={renderRadioItem}
            selectedId={selectedProductId}
          />
        )}
      </View>
      <div className={"gradient_line"} />
      <div
        className={"product__toggle"}
        data-testid={"customizable-single-product-toggle"}>
        {toggleSelection.length > 0 &&
          toggleSelection.map(
            (customisation: ProductModuleModel.ToggleState, i: number) => (
              <div key={i}>
                <View style={style.productRow} key={i}>
                  <Text style={Typography.titleThree}>
                    {customisation.label}
                  </Text>
                  <button
                    onClick={() =>
                      onToggleChange(customisation.id, !customisation.value)
                    }>
                    <Checkbox selected={customisation.value} />
                  </button>
                </View>
                <div className={"gradient_line"} />
              </div>
            )
          )}
      </div>
      <div className={"product__cart-btn-wrapper"}>
        <AddModifyCartItem
          onEditOrder={handleUpdateOrder}
          onAddToOrder={handleAddToOrder}
          index={index}
          editMode={editMode}
          cartItems={cartProduct ? [cartProduct] : []}
          categoryName={category?.name ?? ""}
          isUpsell={isUpsell}
        />
      </div>
    </div>
  );
};

export default SimpleCustomisableProduct;
