import React from "react";
import { StyleSheet, Text, View } from "react-native";

import colours from "styles/colours";
import { lineStyle, tableStyle } from "styles/shared";
import { Typography } from "styles/typography";

export interface IOrderDetailsListItemProps {
  content: string;
  title: string;
}

const style = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
  },
  title: {
    marginBottom: 5,
  },
});

export const OrderDetailsListItem: React.FC<IOrderDetailsListItemProps> = ({
  content,
  title,
}) => {
  return (
    <View style={[lineStyle.dashed, style.container]}>
      <Text style={[tableStyle.headerCol, style.title]}>{title}</Text>
      <Text style={Typography.bodyTwo}>{content}</Text>
    </View>
  );
};
