import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";

import PaymentTypeInfo from "../../components/PaymentTypeInfo";
import ReceiptTable from "../../components/ReceiptTable";
import {
  appTableStyle,
  lineStyle,
  webTableStyle,
} from "../../components/styles/shared";
import { locales } from "../../locales/locales.enum";
import * as OrderModules from "../../modules/Order";
import * as PlatformUtils from "../../modules/platformUtils";
import * as CheckoutModels from "../../redux_store/checkout/model";
import * as ApiOrderModel from "../../services/api/order/model";
import { OrderResponse } from "../../services/api/order/model";
import { Spacing } from "../styles/number";

const style = StyleSheet.create({
  paddingTB: {
    paddingVertical: Spacing.ExtraLight,
    paddingHorizontal: Spacing.Light,
    marginVertical: 0,
  },
  paddingB: {
    paddingBottom: Spacing.ExtraLight,
    paddingHorizontal: Spacing.Light,
    marginVertical: 0,
  },
  paddingT: {
    paddingVertical: Spacing.Light,
    paddingHorizontal: Spacing.Light,
    marginVertical: 0,
  },
  roundedCorners: {
    borderBottomLeftRadius: Spacing.Thin,
    borderBottomRightRadius: Spacing.Thin,
  },
});

const tableStyle = Platform.OS === "web" ? webTableStyle : appTableStyle;

export interface OrderStatusReceiptTableProps {
  isShowSurchargePercent: boolean;
  // Pre checkout mode uses OrderResponse as opposed to OrderCheckoutResponse
  isPreCheckoutMode?: boolean;
  order: CheckoutModels.OrderCheckoutResponse | OrderResponse;
  locale: string;
  paymentTypes?: CheckoutModels.PaymentCheckoutResponse[];
  deliveryFee?: number;
  gygDollars?: number | null;
}

const { OrderConstants } = OrderModules;

const OrderStatusReceiptTable: React.FC<OrderStatusReceiptTableProps> = ({
  isShowSurchargePercent,
  order,
  locale,
  isPreCheckoutMode = false,
  paymentTypes = [],
  deliveryFee,
  gygDollars,
}) => {
  const { t } = useTranslation();
  const paidWithGYGdollars = useMemo(() => {
    const paymentType = paymentTypes.find(
      (it: CheckoutModels.PaymentCheckoutResponse) =>
        it.type === CheckoutModels.PaymentType.GYGDOLLAR
    );
    return paymentType
      ? paymentType
      : gygDollars && gygDollars > 0
        ? { amount: gygDollars }
        : null;
  }, [paymentTypes, gygDollars]);

  const brainTreeTotal = useMemo(() => {
    return paymentTypes.find(
      (it: CheckoutModels.PaymentCheckoutResponse) =>
        it.type === CheckoutModels.PaymentType.BRAINTREE
    );
  }, [paymentTypes]);

  const showExtraSection =
    !!paidWithGYGdollars ||
    !!order.basket.discountAmount ||
    !!order.basket.surcharges?.length;

  const totalPrice = isPreCheckoutMode
    ? order.basket.total
    : (brainTreeTotal?.amount ?? 0);

  return (
    <>
      <View style={[style.paddingB, { marginBottom: 0 }]}>
        <ReceiptTable data={order.basket.basketItems} />
      </View>

      {/* Order value row for AU */}
      {locale === locales.AU && showExtraSection && (
        <View
          style={[
            lineStyle.dashed,
            isPreCheckoutMode ? style.paddingTB : style.paddingT,
          ]}>
          <View style={tableStyle.bodyRow}>
            <View style={tableStyle.colProduct}>
              <Text style={tableStyle.bodyCol}>
                <Text>{t("OrderStatus:orderValue")}</Text>
              </Text>
            </View>
            <View
              style={tableStyle.colPrice}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                OrderConstants.OrderStatusTestingId.orderValue
              )}>
              <Text
                style={tableStyle.bodyCol}
                {...PlatformUtils.generateTestID(Platform.OS, "OrderValue")}>
                ${order.basket.basketValue?.toFixed(2) || "0.00"}
              </Text>
            </View>
          </View>
        </View>
      )}

      {/** Discount row for both US/AU + tax row for US + Surcharges row for both US/AU*/}
      {showExtraSection && (
        <View
          style={
            isPreCheckoutMode
              ? [style.paddingB]
              : [lineStyle.dashed, style.paddingTB]
          }>
          {paidWithGYGdollars && (
            <View style={tableStyle.bodyRow}>
              <View style={tableStyle.colProduct}>
                <Text
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "ORDER_REDEEMED_GYG_DOLLARS"
                  )}
                  style={tableStyle.bodyColSmall}>
                  {t("OrderStatus:GYGdollars")}
                </Text>
              </View>
              <View style={tableStyle.colPrice}>
                <Text style={tableStyle.bodyColSmall}>
                  -${paidWithGYGdollars.amount?.toFixed(2)}
                </Text>
              </View>
            </View>
          )}
          {/**Surcharges Row for both US & AU */}
          {order.basket.surcharges?.map(
            (surcharge: ApiOrderModel.Surcharge, index: number) => {
              return (
                <View style={tableStyle.bodyRow} key={index}>
                  <View style={tableStyle.colProduct}>
                    <Text
                      style={tableStyle.bodyCol}
                      {...PlatformUtils.generateTestID(
                        Platform.OS,
                        "SurchargesDescription"
                      )}>
                      {surcharge.name}
                    </Text>
                  </View>
                  {isShowSurchargePercent && (
                    <View style={tableStyle.colQty}>
                      <Text
                        style={tableStyle.bodyCol}
                        {...PlatformUtils.generateTestID(
                          Platform.OS,
                          "SurchargesPercentage"
                        )}>
                        {surcharge.surchargePercentage}%
                      </Text>
                    </View>
                  )}
                  <View style={tableStyle.colPrice}>
                    <Text
                      style={tableStyle.bodyCol}
                      {...PlatformUtils.generateTestID(
                        Platform.OS,
                        "SurchargesAmount"
                      )}>
                      ${surcharge.amount?.toFixed(2)}
                    </Text>
                  </View>
                </View>
              );
            }
          )}
          {/** Discount row for both US/AU */}
          {!!order.basket.discountAmount && (
            <View style={tableStyle.bodyRow}>
              <View style={tableStyle.colProduct}>
                <Text
                  style={tableStyle.bodyColSmall}
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "ORDER_APPLIED_REWARD"
                  )}>
                  {t("OrderStatus:discount")}
                </Text>
              </View>
              <View style={tableStyle.colPrice}>
                <Text style={tableStyle.bodyColSmall}>
                  -${order.basket.discountAmount?.toFixed(2)}
                </Text>
              </View>
            </View>
          )}
        </View>
      )}
      {/* Sub total row for US */}
      {locale === locales.US && (
        <>
          <View style={[lineStyle.dashed, style.paddingTB]}>
            <View style={tableStyle.bodyRow}>
              <View style={tableStyle.colProduct}>
                <Text style={tableStyle.bodyCol}>
                  <Text
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      OrderConstants.OrderStatusTestingId.subTotal
                    )}>
                    {t("OrderStatus:subTotal")}
                  </Text>
                </Text>
              </View>
              <View style={tableStyle.colPrice}>
                <Text style={tableStyle.bodyCol}>
                  ${order.basket.subtotal?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View style={[lineStyle.dashed, style.paddingTB]}>
            <View style={tableStyle.bodyRow}>
              <View style={tableStyle.colProduct}>
                <Text
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    OrderConstants.OrderStatusTestingId.orderTax
                  )}
                  style={tableStyle.bodyCol}>
                  {t("OrderStatus:tax")}
                </Text>
              </View>
              <View style={tableStyle.colQty}>
                <Text style={tableStyle.bodyCol}>{order.basket.taxRate}%</Text>
              </View>
              <View style={tableStyle.colPrice}>
                <Text style={tableStyle.bodyCol}>
                  ${order.basket.salesTax?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </>
      )}

      {/** Delivery fee (if applicable) */}
      {!!deliveryFee && (
        <View
          style={
            isPreCheckoutMode
              ? [
                  lineStyle.dashed,
                  style.paddingTB,
                  isPreCheckoutMode && style.roundedCorners,
                ]
              : [lineStyle.dashed, style.paddingTB]
          }>
          <View style={tableStyle.bodyRow}>
            <View style={tableStyle.colProduct}>
              <Text style={tableStyle.bodyCol}>
                {t("OrderStatus:deliveryFee")}
              </Text>
            </View>
            <View
              style={tableStyle.colPrice}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                OrderConstants.OrderStatusTestingId.totalValue
              )}>
              <Text style={tableStyle.bodyCol}>${deliveryFee?.toFixed(2)}</Text>
            </View>
          </View>
        </View>
      )}

      {/** Payment total row for both AU/US */}
      <View
        style={
          isPreCheckoutMode
            ? [
                lineStyle.dashed,
                style.paddingTB,
                isPreCheckoutMode && style.roundedCorners,
              ]
            : [lineStyle.dashed, style.paddingTB]
        }>
        <View style={tableStyle.bodyRow}>
          <View style={tableStyle.colProduct}>
            <Text style={tableStyle.bodyCol}>
              {locale === locales.AU && !showExtraSection
                ? t("OrderStatus:paymentTotalInclGst")
                : t("OrderStatus:paymentTotal")}
            </Text>
          </View>
          <View
            style={tableStyle.colPrice}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              OrderConstants.OrderStatusTestingId.totalValue
            )}>
            <Text style={tableStyle.bodyCol}>${totalPrice?.toFixed(2)}</Text>
          </View>
        </View>
      </View>

      {/** Payment type row for both AU/US */}
      {!isPreCheckoutMode &&
        paymentTypes.length > 0 &&
        !(paymentTypes.length < 2 && order.basket.discountAmount) && (
          <View style={[lineStyle.dashed, style.paddingTB]}>
            <View style={tableStyle.bodyRow}>
              <View>
                <Text style={tableStyle.headerCol}>
                  {t("OrderStatus:paymentType")}
                </Text>
              </View>
            </View>
            <PaymentTypeInfo paymentTypes={paymentTypes} />
          </View>
        )}
    </>
  );
};

export default OrderStatusReceiptTable;
