import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch } from "react-redux";

import { UserReduxAction } from "gyg_common";
import questionImg from "gyg_common/components/assets/images/illustration_error_question.png";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import {
  getCurrentLocationPermission,
  requestLocationPermission,
} from "modules/location";
import OrderSetupHeaderContainer from "views/containers/OrderSetupHeader/OrderSetupHeaderContainer";

import { LocationPermission } from "../Location/LocationPermission";
import { LocationPermissionModal } from "../Location/LocationPermissionModal";
import { NoStoresNearby } from "../Location/NoStoresNearby";

interface DashboardHeaderSwitchProps {
  locationPermission: boolean;
  isSelectedStore: boolean;
  userConfirmedOrderSetup: boolean;
}

const DashboardHeaderSwitch: React.FC<DashboardHeaderSwitchProps> = ({
  locationPermission,
  isSelectedStore,
  userConfirmedOrderSetup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLocationPermissionModalVisible, setLocationPermissionModalVisible] =
    useState<boolean>(false);
  const [shareLocationModalVisible, setShareLocationModalVisible] =
    useState<boolean>(false);

  const askForLocationPermission = async () => {
    const res = await requestLocationPermission();
    if (!res.userLocation) {
      setShareLocationModalVisible(false);
      setLocationPermissionModalVisible(true);
    } else {
      setShareLocationModalVisible(false);
      setLocationPermissionModalVisible(false);
    }
  };

  useEffect(() => {
    if (!userConfirmedOrderSetup && !isSelectedStore) {
      getCurrentLocationPermission()
        .then(async (val) => {
          if (val === "denied") {
            setShareLocationModalVisible(true);
          }

          if (val === "prompt") {
            await askForLocationPermission();
          }
        })
        .catch(() => {
          dispatch(UserReduxAction.setLocationPermission(false));
        });
    }
  }, [dispatch, userConfirmedOrderSetup, isSelectedStore]);

  return userConfirmedOrderSetup || isSelectedStore ? (
    <OrderSetupHeaderContainer />
  ) : locationPermission ? (
    <NoStoresNearby />
  ) : (
    <>
      <ModalWithButton
        image={questionImg as ImageSourcePropType}
        modalId='LocationPermissionModal'
        isVisible={isLocationPermissionModalVisible}
        primaryButton={{
          name: "CLOSE",
          action: () => setLocationPermissionModalVisible(false),
        }}
        small={true}
        title={t("Dashboard:turnLocationOn")}
        message={t("Dashboard:locationDeniedMessage")}
        onModalClose={() => setLocationPermissionModalVisible(false)}
      />
      <LocationPermissionModal
        isVisible={shareLocationModalVisible}
        onClose={() => setShareLocationModalVisible(false)}
        onPrimaryClick={askForLocationPermission}
      />
      <LocationPermission onClickPrimaryButton={askForLocationPermission} />
    </>
  );
};

export default DashboardHeaderSwitch;
