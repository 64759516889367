import dropin from "braintree-web-drop-in";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";

import {
  BraintreeReduxActions,
  CheckoutAction,
  OrderReduxAction,
  RootState,
} from "../index";
import { ErrorResponse, HTTPStatusCode } from "../redux_store/error/models";
import { getStatusCode } from "../utils/order";
import useCreateUpdateOrder from "./useCreateUpdateOrder";

interface OrderErrorHandlerProps {
  store: ReduxStore;
  recreateOrderCallback?: () => void;
  webBrainTreeInstance?: dropin.Dropin;
}

export const isUnexpectedError = (
  checkoutHasUnexpectedError: boolean,
  error?: ErrorResponse | null | undefined
) => {
  return (
    getStatusCode(error?.statusCode) >= HTTPStatusCode.UNEXPECTEDERROR ||
    checkoutHasUnexpectedError
  );
};

export const isBraintreeError = (error?: ErrorResponse | null | undefined) => {
  return error?.title === "BraintreeTransactionCreationFailedException";
};

const useHandleOrderError = ({
  store,
  recreateOrderCallback,
  webBrainTreeInstance,
}: OrderErrorHandlerProps) => {
  const { updateOrder } = useCreateUpdateOrder(store);
  const dispatch = useDispatch();
  const { error: checkoutError } = useSelector((s: RootState) => s.checkout);

  const recreateOrder = useCallback(() => {
    if (recreateOrderCallback) {
      recreateOrderCallback();
    }

    // clear order response and order ID so a new order can be created
    dispatch(OrderReduxAction.clearOrderResponse());
    //reset order status
    dispatch(OrderReduxAction.clearOrderStatus());
    // reset payment status
    dispatch(CheckoutAction.resetPayment());
    // reset the braintree payment payload
    dispatch(BraintreeReduxActions.clearPaymentPayload());
    dispatch(OrderReduxAction.updateCurrentOrderId(null));

    // create a new order, this param triggers a new order creation
    updateOrder(true);
  }, [updateOrder, dispatch, recreateOrderCallback]);

  const clearOrderErrorStates = useCallback(() => {
    dispatch(OrderReduxAction.clearOrderError());
    dispatch(CheckoutAction.resetPayment());
    dispatch(BraintreeReduxActions.setTimeoutError(false));
  }, [dispatch]);

  const clearWebBraintreeSelectedPaymentMethod = useCallback(() => {
    // clear selected payment to get new braintree nonce
    if (!webBrainTreeInstance) {
      return;
    }

    webBrainTreeInstance.clearSelectedPaymentMethod();
  }, [webBrainTreeInstance]);

  /*
  ⚠️ WARNING ⚠️  
  This block captures two core flows:
  1. All types of errors returned from the backend's response
    This scenario is safe - we have enough information for the frontend to determine what to do next

  2. No response from the backend (eg. XHR timeout)
    Scenario 2 is ⚠️ very dangerous ⚠️ - due to a lack of a response, the actual state of the order is indeterminate
    (until we make another request)

    There is a nonzero chance that the previous checkout was actually successful
    eg. checkout was successful, but the response never made it to the user due to user device network issues
    In this scenario, we want to be ⚠️ VERY CAREFUL ⚠️ not to let the user pay >1 time for a given order (ie. double payments)
    
    This is a classic concurrency problem.

  The actual solution would be for the frontend to long poll to check if an order had a successful checkout
  ie. If order has successful checkout, redirect user to status page
  */
  const onCheckoutErrorModalAction = useCallback(() => {
    if (isBraintreeError(checkoutError)) {
      //recreates for Braintree soft/hard errors encountered
      clearOrderErrorStates();
      recreateOrder();
      return;
    }

    //close checkout error modal and clear error states
    clearOrderErrorStates();
    clearWebBraintreeSelectedPaymentMethod();
  }, [
    checkoutError,
    clearOrderErrorStates,
    clearWebBraintreeSelectedPaymentMethod,
    recreateOrder,
  ]);

  return onCheckoutErrorModalAction;
};

export default useHandleOrderError;
