import { isEqual, orderBy } from "lodash";
import moment, { Moment } from "moment-timezone";
import { PlatformOSType } from "react-native";
import { Dispatch, Store as ReduxStore } from "redux";

import { Reward } from "modules/Loyalty/models";

import config from "../../config";
import {
  BraintreeReduxActions,
  CheckoutAction,
  ErrorReduxModel,
} from "../../index";
import { locales } from "../../locales/locales.enum";
import orderManagementAU from "../../locales/OrderManagement/au.json";
import orderManagementUS from "../../locales/OrderManagement/us.json";
import * as MenuModuleUtils from "../../modules/Menu/utils";
import { CartItem, Modifier } from "../../redux_store/cart/models";
import {
  CheckoutResponse,
  OrderFinalisationStatusType,
  OrderProgressEnum,
} from "../../redux_store/checkout/model";
import { IRewards } from "../../redux_store/loyalty/models";
import {
  Category,
  MenuSection,
  MenuStructure,
  ModifierLookup,
  MultiPart,
  MultipartSection,
  Product,
  TagLookup,
  TagType,
} from "../../redux_store/menu/models";
import {
  CollectionType,
  DeliveryOrder,
  OrderTimeSetup,
  RatedOrderProps,
} from "../../redux_store/order/models";
import { orderActions } from "../../redux_store/order/order.slice";
import { Store } from "../../redux_store/store/models";
import {
  BasketCoupon,
  BasketCouponType,
  BasketItem,
  CreateOrderPayload,
  GetOrderResponse,
  ItemPayload,
  OrderResponse,
  OrderTimes,
} from "../../services/api/order/model";
import {
  MenuTradingHour,
  OrderTime,
  TimePeriod,
  TradingHour,
} from "../../services/api/store/model";
import { HttpStatus } from "../../services/httpClient";
import UUIDGenerator from "../../utils/uuidGenerator";
import { PickerItemProps } from "../Components/Shared/dropdownPickerModels";
import { LodashSortingType } from "../enum";
import {
  jsonPathAccessor,
  timeSplitter,
  TimeSplitterProps,
} from "../Menu/utils";
import { ProductRoute } from "../Products/const";
import { productCustomizationList } from "../Products/customizationMapper";
import { findTemplateRoute, getIdsFromModifier } from "../Products/utils";
import { DEFAULT_TIMEZONE, DELAYED_ORDER_SECONDS } from "./constants";
import { CombinedCartItem, OrderReceiptInfo, OrderSetupDay } from "./models";

const coffeeCouponName = "LOYALTY COFFEE";

interface GroupAmPmTimeslotsProp {
  am: {
    // hour keys -> array of number for minutes valid in that hour
    [hour: number]: number[];
  };
  pm: {
    [hour: number]: number[];
  };
}

export const getTimeFromDate = (date: string): string => {
  const time = new Date(date).toLocaleTimeString(["en-US"], {
    hour: "2-digit",
    hour12: true,
    minute: "2-digit",
  });
  return time;
};

/**
 * Checks if the order time has shifted by more than 15 minutes due to offset.
 * @param initialTime - initial order time
 * @param currentTime - current order time include offset
 */
export const hasOrderTimeShifted = (
  dispatch: Dispatch,
  initialTime: number | null,
  currentTime: number | null
): boolean => {
  if (initialTime && currentTime && currentTime > initialTime) {
    const diff = currentTime - initialTime;
    if (diff >= DELAYED_ORDER_SECONDS) {
      dispatch(orderActions.setIgnoreTimeChange(true));
    }
    return Math.abs(diff) >= DELAYED_ORDER_SECONDS; // 15 min
  } else {
    return false;
  }
};

export const getEffectiveMenuForOrderTime = (
  initialTime: number | null,
  menuSection: MenuSection[],
  timeZone: string | undefined
) => {
  const orderTime = timeZone
    ? moment(initialTime).tz(timeZone).format("HH:mm")
    : moment(initialTime).format("HH:mm");

  return menuSection
    ? menuSection.filter((section) => {
        const dayMenuTimePeriod = MenuModuleUtils.getDayOfMenuSectionTimePeriod(
          section,
          moment(initialTime).format("dddd").toLowerCase()
        );

        if (dayMenuTimePeriod) {
          const currentPeriod = dayMenuTimePeriod.filter((period) => {
            return orderTime >= period.openTime && orderTime <= period.endTime;
          });

          return currentPeriod.length > 0;
        }
      })
    : [];
};

const isOrderTimesMeetMenuTimePeriodRanges = (
  dayMenuTimePeriod: TimePeriod[],
  orderTime: string,
  calculatedTime: string
): boolean => {
  return (
    dayMenuTimePeriod.filter((period) => {
      if (
        orderTime >= period.openTime &&
        orderTime <= period.endTime &&
        calculatedTime >= period.openTime &&
        calculatedTime <= period.endTime
      ) {
        return false;
      } else {
        return true;
      }
    }).length > 0
  );
};

/**
 * Checks if menu hours shifted and returns section or null
 */
export const hasOrderShiftedToNextPeriod = (
  menuSection: MenuSection[],
  initialTime: number | null,
  currentTime: number | null,
  timeZone: string | undefined
): boolean => {
  const orderTime = timeZone
    ? moment(initialTime).tz(timeZone).format("HH:mm")
    : moment(initialTime).format("HH:mm");

  const calculatedTime = timeZone
    ? moment(currentTime).tz(timeZone).format("HH:mm")
    : moment(currentTime).format("HH:mm");

  const menuOrderedFrom = getEffectiveMenuForOrderTime(
    initialTime,
    menuSection,
    timeZone
  );

  if (menuOrderedFrom.length) {
    const dayMenuTimePeriod = MenuModuleUtils.getDayOfMenuSectionTimePeriod(
      menuOrderedFrom[0],
      moment(initialTime).format("dddd").toLowerCase()
    );

    if (dayMenuTimePeriod) {
      return isOrderTimesMeetMenuTimePeriodRanges(
        dayMenuTimePeriod,
        orderTime,
        calculatedTime
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const shouldConfirmOrderTime = (initialTime: number | null): boolean => {
  if (initialTime) {
    const currentTime = moment().toDate().getTime();
    return currentTime - initialTime >= DELAYED_ORDER_SECONDS;
  } else {
    return true;
  }
};

export const getFormattedOrderTime = (
  orderTime: number,
  timeZone: string
): string => {
  const expectedDateFormat =
    config.version === locales.US ? "ddd MM/DD - h:mm a" : "ddd DD/MM - h:mm a";
  const isCurrentDay = moment(orderTime)
    .tz(timeZone)
    .isSame(moment(new Date()).tz(timeZone), "day");
  return isCurrentDay
    ? moment(orderTime).tz(timeZone).format("h:mm a")
    : moment(orderTime).tz(timeZone).format(expectedDateFormat);
};

export const displayOrderType = (
  orderCollectionType: CollectionType,
  locale: locales
): string => {
  switch (orderCollectionType) {
    case CollectionType.DELIVERY:
      return locale === locales.AU
        ? orderManagementAU.delivery
        : orderManagementUS.delivery;
    case CollectionType.DRIVE_THRU:
      return locale === locales.AU
        ? orderManagementAU.pickUp
        : orderManagementUS.pickUp;
    case CollectionType.PICK_UP:
      return locale === locales.AU
        ? orderManagementAU.pickUp
        : orderManagementUS.pickUp;
    case CollectionType.TABLE_SERVICE:
      return locale === locales.AU
        ? orderManagementAU.tableService
        : orderManagementUS.tableService;
    default:
      return "";
  }
};

/**
 *  Sets order type dropdown options.
 *  It filters out `table service` from list of options if:
 *  - it's US
 *  - there's only one store
 *  - store is closed (no sheduling for table service)
 *  - store does not have table service tag
 */
export const createOrderTypeDropdownItems = (
  localVersion: locales,
  isTableServiceAvailable: boolean,
  platform: PlatformOSType,
  isStoreOpen?: boolean
): PickerItemProps<CollectionType>[] => {
  return Object.values(CollectionType)
    .filter(
      (orderType: CollectionType) =>
        orderType !== CollectionType.DELIVERY &&
        !(
          ((!isStoreOpen || !isTableServiceAvailable) &&
            orderType === CollectionType.TABLE_SERVICE) ||
          orderType === CollectionType.DRIVE_THRU
        )
    )
    .map((orderType: CollectionType) => {
      return {
        label: displayOrderType(orderType, localVersion),
        value: orderType,
      } as PickerItemProps<CollectionType>;
    });
};

const prepareModifiers = (modifier?: Modifier[], filling?: Modifier[]) => {
  const modis: Modifier[] = [];
  if (modifier) {
    modis.push(...modifier);
  }
  if (filling) {
    modis.push(...filling);
  }

  return modis;
};

export const generateBasketItemForOrderPayload = (
  item: CartItem,
  miamGUID?: string
): ItemPayload => {
  const basketItem: ItemPayload = {
    id: item.productId,
    name: item.name,
    miamGUID: miamGUID,
    quantity: item.quantity,
    menuFlowId: item.menuFlowId,
    removeModifiers: getIdsFromModifier(item.removeModifier),
    addModifiers: getIdsFromModifier(
      prepareModifiers(item.addModifier, item.addFilling)
    ),
    extraModifiers: getIdsFromModifier(
      prepareModifiers(item.extraModifier, item.extraFilling)
    ),
  };

  // Appends upsellSource if existing
  return item.upsellSource
    ? { ...basketItem, upsellSource: item.upsellSource }
    : basketItem;
};

const cartItemToItemPayload = (
  item: CartItem,
  miamGUID?: string
): ItemPayload => {
  const basketItem: ItemPayload = {
    ...generateBasketItemForOrderPayload(item, miamGUID),
  };
  if (item.parts) {
    basketItem.parts = item.parts.map((part) =>
      generateBasketItemForOrderPayload(part, miamGUID)
    );
  }
  return basketItem;
};

export const generateCreateOrderItemPayload = (
  items: CartItem[]
): ItemPayload[] => {
  try {
    const result: ItemPayload[] = [];
    items.map((n) => {
      const miamGUID = n.miamItem ? UUIDGenerator.getRandomUUID() : undefined;
      result.push(cartItemToItemPayload(n, miamGUID));
      if (n.miamItem) result.push(cartItemToItemPayload(n.miamItem, miamGUID));
    });
    return result;
  } catch (e) {
    return [];
  }
};

/**
 * Gets object of modifier by id.
 * @param modifiers array of modifiers' id
 * @param lookup modifier lookup
 * @returns array of objects
 */
export const getModifierFromLookup = (
  modifiers: number[],
  lookup: ModifierLookup[]
): Modifier[] => {
  let modifierFromLookUp: Modifier[] = [];

  if (modifiers && modifiers.length > 0 && lookup) {
    modifierFromLookUp = modifiers
      .map((m: number) => {
        const modi = lookup.find(
          (modifier: ModifierLookup) => modifier.id === m
        );
        if (modi) {
          const modiObj: Modifier = {
            id: m,
            name: modi.name,
            price: modi.price,
          };
          return modiObj;
        }
      })
      .filter((modi) => modi !== undefined);
  }
  return modifierFromLookUp;
};

export const getProductTypeName = (
  tagLookup?: TagLookup[],
  tags?: string[]
): string | undefined => {
  const productTypeTitle = tagLookup?.find((lookup: TagLookup) =>
    tags?.find(
      (tag: string) =>
        lookup.tagId === tag && lookup.type === TagType.ProductType
    )
  )?.value;
  return productTypeTitle;
};

/**
 * Checks if basket item can be populated in the template.
 * @param menuStructure
 * @param item
 * @returns
 */
const getBasketItemRoute = (menuStructure: MenuStructure, item: BasketItem) => {
  const objPath = menuStructure.categoryLookup[item.id]?.split("/") ?? [];
  const category: Category = jsonPathAccessor(objPath, menuStructure);
  return category ? findTemplateRoute(category) : undefined;
};

/**
 * Maps basket item to cart item.
 * @param item basket item
 * @param validItem
 * @param hasCategory does item has a category in menu
 * if not, should display its name (Coke instead of Drinks)
 * @param tagLookup
 * @returns
 */
const mapBasketItemToCart = (
  item: BasketItem,
  validItem: boolean,
  menuStructure: MenuStructure | undefined,
  isPart: boolean,
  tagLookup?: TagLookup[]
) => {
  const itemRoute = menuStructure
    ? getBasketItemRoute(menuStructure, item)
    : undefined;
  const productTypeTitle = getProductTypeName(tagLookup, item.tags);
  const showCategoryName =
    !isPart && itemRoute !== ProductRoute.NonCustomization;
  const showProductTypeTitle = isPart && productTypeTitle;

  let title = item.name;
  if (showCategoryName) {
    // Main product
    title = item.category || item.name;
  } else if (showProductTypeTitle) {
    // Parts with product type tag
    title = productTypeTitle;
  } else if (
    // Parts with other tags
    item.tags?.length !== 0 &&
    itemRoute !== ProductRoute.NonCustomization
  ) {
    // Main drink product
    title = item.category;
  }

  const labelForFavourite = item.miamGUID ? `${title} MIAM` : title;

  const newItem: CartItem = {
    productId: item.id,
    name: item.name,
    miamGUID: item.miamGUID,
    quantity: item.quantity || 1,
    unitPrice: item.unitPrice || 0,
    price: item.price || 0,
    isValid: validItem,
    menuFlowId: item.menuFlowId,
    posPlu: item.posPlu,
    labelForFavourite,
    tags: item.tags,
    allowModify: itemRoute ? !!itemRoute : undefined,
    isMiam: itemRoute === ProductRoute.MIAM,
    displayText: item.displayText,
    originalPrice: item.originalPrice,
    associatedOffers: item.associatedOffers,
    productDiscountsApplied: item.productDiscountsApplied,
    isOfferProduct: item.isOfferProduct ?? false,
    category: item.category,
  };

  if (item.upsellSource) {
    newItem.upsellSource = item.upsellSource;
  }

  // `addModifiers` returned in basket item includes `addFillings` and `addModifiers`
  newItem.addModifier = item.addModifiers?.map((modi: Modifier) => ({
    id: modi.id,
    name: modi.name,
    price: modi.price,
    isValid: modi?.isValid,
    modifierId: modi?.modifierId,
  }));
  newItem.removeModifier = item.removeModifiers?.map((modi: Modifier) => ({
    id: modi.id,
    name: modi.name,
    price: modi.price,
    isValid: modi?.isValid,
  }));
  // `extraModifiers` returned in basket item includes `extraFillings` and `extraModifiers`
  newItem.extraModifier = item.extraModifiers?.map((modi: Modifier) => ({
    id: modi.id,
    name: modi.name,
    price: modi.price,
    isValid: modi?.isValid,
  }));
  // identifiers
  newItem.defaultModifier = item.defaultModifiers?.map((modi: Modifier) => ({
    id: modi.id,
    name: modi.name,
    price: modi.price,
    isValid: modi?.isValid,
  }));

  newItem.tagLookup = tagLookup;
  return newItem;
};

const basketItemToCartItem = (
  item: BasketItem,
  menuStructure: MenuStructure | undefined,
  validItem: boolean,
  tagLookup?: TagLookup[]
): CartItem => {
  const newItem = mapBasketItemToCart(
    item,
    validItem,
    menuStructure,
    false,
    tagLookup
  );

  if (item.parts) {
    newItem.parts = item.parts.map((part: BasketItem) => {
      return mapBasketItemToCart(
        part,
        validItem,
        menuStructure,
        true,
        tagLookup
      );
    });
  }
  return newItem;
};

/**
 * Mapps basket items returned from order API to model of cart item.
 * It combines valid basket items with invalid ones into one array and
 * adds appriopriate validation flag to each item.
 * @param items basket items
 * @returns cart items
 */
export const basketMapper = (
  items: BasketItem[],
  menuStructure: MenuStructure | undefined,
  tagLookup?: TagLookup[],
  invalidItems?: BasketItem[]
): CartItem[] => {
  const newItems: CartItem[] = items.map((item: BasketItem) => {
    return basketItemToCartItem(item, menuStructure, true, tagLookup);
  });

  invalidItems?.forEach((item: BasketItem) => {
    const newItem = basketItemToCartItem(item, menuStructure, false, tagLookup);
    newItems.push(newItem);
  });

  const group = newItems.reduce((r, a) => {
    const key = a.miamGUID ?? "";
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));
  const cartItems = [];
  for (const [key, value] of Object.entries(group)) {
    const array = value as CartItem[];
    if (key === "") {
      cartItems.push(...array);
    } else {
      const product = array.find((it) => !it.isMiam);
      const miamProduct = array.find((it) => it.isMiam);
      const cartItem: CartItem = {
        ...product,
        miamItem: miamProduct,
        isValid: miamProduct?.isValid && product?.isValid,
        isMiam: miamProduct?.miamGUID ? true : false,
      };
      cartItems.push(cartItem);
    }
  }
  return cartItems;
};

export const identifierTypes = [
  TagType.SpiceLevel,
  TagType.Size,
  TagType.ProductQuantity,
  TagType.Filling,
  TagType.TacoType,
  TagType.ChurrosFillings,
];

/**
 * Extracts identifiers for the cart item product or part from tags.
 * @param tags
 * @param tagLookup
 * @param addTitle
 * @param productName
 * @returns
 */
export const getIdentifiersFromTags = (
  tags?: string[],
  tagLookup?: TagLookup[],
  addTitle?: boolean,
  productName?: string
): string[] => {
  const identifiers: string[] = [];

  tags?.forEach((tag) => {
    const matchedTag = tagLookup?.find(
      (t: TagLookup) => t.tagId === tag && identifierTypes.includes(t.type)
    );
    if (matchedTag) {
      identifiers.push(matchedTag.value as string);
    }
  });
  if (addTitle) {
    identifiers.push(productName);
  }

  return identifiers;
};

/**
 * Returns amount of all modifiers applied to the product.
 * @param item Cart item
 * @returns number
 */
export const getModifiersAmount = (item: CartItem): number => {
  let modifiersAmount = 0;
  productCustomizationList.map((modifierTitle: string) => {
    for (const [key, value] of Object.entries(item)) {
      if (key === modifierTitle && value?.length > 0) {
        modifiersAmount += value.length;
      }
    }
  });
  return modifiersAmount;
};

export const createNewTimeSetup = (
  orderTime: number | null,
  isOrderASAP: boolean
): OrderTimeSetup => {
  const newTimeSetup: OrderTimeSetup = {
    pickupDay: orderTime ? moment(orderTime) : moment(),
    pickupHour: orderTime ? moment(orderTime).hour() : 0,
    pickupMinute: orderTime ? moment(orderTime).minute() : 0,
    pickupASAP: isOrderASAP,
    pickupAmPm: orderTime
      ? moment(orderTime).isAfter(moment().hour(12).minute(0).second(0))
      : moment().isAfter(moment().hour(12).minute(0).second(0)),
  };
  return newTimeSetup;
};

// build the order time from each of the day, hour, minute and am/pm selections
export const buildOrderTime = (
  day: Moment,
  hour: number,
  minute: number | null,
  isAM: boolean
): number => {
  //converts 12 hour format to 24 hour format
  //e.g. 1:00pm -> 13:00
  const finalHour =
    !isAM && hour !== 12 ? hour + 12 : isAM && hour === 12 ? hour - 12 : hour;

  const initMoment = moment(day);

  const orderTime = initMoment.set("hour", finalHour).set("minute", minute);

  const orderTimeBuiltUnix = orderTime.valueOf();

  return orderTimeBuiltUnix;
};

export const getMenuHoursForDay = (
  menuTradingHour: MenuTradingHour[],
  dayName: string
): TradingHour => {
  const menuHoursForDay: TradingHour = {
    enabled: false,
    timePeriods: [],
    dayOfWeek: "",
  };

  const allMenuTradingHours: TradingHour[] = [];

  //Get all menu hours in menu
  for (let index = 0; index < menuTradingHour.length; index++) {
    const tradingHours = menuTradingHour[index];
    if (!tradingHours?.tradingHours?.length) {
      continue;
    }

    allMenuTradingHours.push(
      tradingHours?.tradingHours?.find((tradingHour) => {
        return tradingHour.dayOfWeek === dayName.toLowerCase();
      })
    );
  }

  for (const tradingHour of allMenuTradingHours) {
    if (tradingHour) {
      for (let index = 0; index < tradingHour.timePeriods.length; index++) {
        const openTime = tradingHour.timePeriods[index].openTime;
        const endTime = tradingHour.timePeriods[index].endTime;

        //Set the first timePeriod if timeperiod is empty
        if (menuHoursForDay.timePeriods.length === 0) {
          menuHoursForDay.timePeriods.push({
            openTime: openTime,
            endTime: endTime,
          });
        } else {
          //Get earliest open time in all the menu hours
          if (
            menuHoursForDay.timePeriods[0].openTime >
            tradingHour.timePeriods[index].openTime
          ) {
            menuHoursForDay.timePeriods[0].openTime =
              tradingHour.timePeriods[index].openTime;
          }

          //Get latest close time in all the menu hours
          if (
            menuHoursForDay.timePeriods[0].endTime <
            tradingHour.timePeriods[index].endTime
          ) {
            menuHoursForDay.timePeriods[0].endTime =
              tradingHour.timePeriods[index].endTime;
          }
        }
      }
    }
  }

  //Set enabled and dayOfWeek if store is open
  if (allMenuTradingHours[0]) {
    menuHoursForDay.enabled = allMenuTradingHours[0].enabled;
    menuHoursForDay.dayOfWeek = allMenuTradingHours[0].dayOfWeek;
  }

  return menuHoursForDay;
};

export const getValidOrderSetupDays = (
  orderTimes: OrderTime[]
): OrderSetupDay[] => {
  const dateFormat =
    config.version === locales.US ? "dddd, MMMM DD" : "dddd DD MMMM";
  return orderTimes.map((orderTime) => {
    const firstOpenTimeSlot = orderTime.timeSlots[0];
    const openDay = moment(`${orderTime.date} ${firstOpenTimeSlot}`);

    return {
      label: openDay.format(dateFormat),
      value: openDay,
    };
  });
};

// returns an array of available restaurant timeslots
// [ "12:50","12:51","12:52","12:53"]
export const getValidOrderTimeslots = (
  orderDay: Moment,
  orderTimes: OrderTime[],
  asapTime: Date,
  storeTimeZone: string | undefined
): string[] => {
  const applicableTimes = orderTimes.filter(
    (orderTime) => orderTime.date === orderDay.format("YYYY-MM-DD")
  );
  // Minute values are being filtered,
  // so that the time selected by the user
  // is not less than the value of ASAP for current day
  if (orderTimes[0]?.date === orderDay.format("YYYY-MM-DD") && storeTimeZone) {
    const startHour = moment(asapTime).tz(storeTimeZone).format("HH:mm");
    const timeSlots = orderTimes[0]?.timeSlots.filter(
      (time) => time >= startHour
    );
    return timeSlots;
  }

  return applicableTimes && applicableTimes.length
    ? applicableTimes[0].timeSlots
    : [];
};

// based on available timeslots, return an object
// that distinguishes am/pm and has corresponding hour
// mapping to array of minute slots for that hour
export const groupAmPmTimeslots = (
  validHoursForDay: string[]
): GroupAmPmTimeslotsProp => {
  const amPmTimeslots = validHoursForDay.reduce(
    (acc: GroupAmPmTimeslotsProp, openTime: string) => {
      const hourAndMinutes = openTime.split(":");

      if (Number(hourAndMinutes[0]) < 12) {
        // handle scenario where am is 0:xx
        const amHour = Number(hourAndMinutes[0]) || 12;
        const availableMinsForHour = acc.am[amHour] || [];
        return {
          am: {
            ...acc.am,
            [amHour]: [...availableMinsForHour, Number(hourAndMinutes[1])],
          },
          pm: acc.pm,
        };
      } else {
        // handle scenario where pm is 24:xx
        const pmHour = Number(hourAndMinutes[0]) - 12 || 12;
        const availableMinsForHour = acc.pm[pmHour] || [];

        return {
          pm: {
            ...acc.pm,
            [pmHour]: [...availableMinsForHour, Number(hourAndMinutes[1])],
          },
          am: acc.am,
        };
      }
    },
    { am: {}, pm: {} }
  );
  return amPmTimeslots;
};

// orders the hours, placing 12 as the first item if present
export const orderDisplayedHours = (hours: string[]): string[] => {
  const has12thHour = hours.includes("12");
  return has12thHour ? ["12", ...hours.filter((hour) => hour !== "12")] : hours;
};

export const is24Hours = (openTime: string, endTime: string): boolean => {
  if (openTime === "00:01" && endTime === "23:59") return true;

  return false;
};

/**
 * Checks whether selected hour is closing hour of any time periods
 * @param selectedHour
 * @param timePeriodsForDay
 * @returns closingTime if selected hour is closing hour
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isSelectedHourClosingHour = (
  selectedHour: number,
  timePeriodsForDay: TimePeriod[]
): TimeSplitterProps | undefined => {
  for (const timePeriod of timePeriodsForDay) {
    const closingTime = timeSplitter(timePeriod.endTime);
    const closingHour = closingTime.hour;
    if (closingHour === selectedHour) {
      return closingTime;
    }
  }
  return undefined;
};

/**
 * Checks order error code for generic error label for cart view.
 */
export const showOrderError = (
  errorCode?: number,
  hasInvalidProducts?: boolean
): boolean => {
  let showError;
  switch (errorCode) {
    case 200:
      showError = false;
      break;
    case 400:
      showError = true;
      break;
    case 404:
      showError = true;
      break;
    default:
      showError = false;
      break;
  }

  if (hasInvalidProducts) {
    showError = true;
  }
  if (errorCode?.toString().charAt(0) === "5") {
    showError = false;
  }

  return showError;
};

/**
 * Checks if cart item includes any of modifiers.
 * @param item
 * @returns
 */
const itemWithModis = (item: CartItem) => {
  return (
    !!item.defaultModifier?.length ||
    !!item.removeModifier?.length ||
    !!item.addModifier?.length ||
    !!item.extraModifier?.length
  );
};

/**
 * It checks if element before part in cart item has modifier or identifier, as well as item
 * and applies extra spacing between them when confirmed.
 * @param newItems
 * @param item
 * @param itemIdentifiers
 * @param index
 * @param tagLookup
 * @returns
 */
export const checkIfSurroundedByModifiers = (
  newItems: CombinedCartItem[],
  item: CartItem,
  itemIdentifiers: string[],
  index: number,
  tagLookup?: TagLookup[]
): boolean => {
  const prevPartHasIdentifier =
    index > 0
      ? getIdentifiersFromTags(newItems[index - 1]?.item.tags, tagLookup)
      : [];

  const prevHasModis =
    index > 0
      ? itemWithModis(newItems[index - 1]?.item) ||
        prevPartHasIdentifier.length > 0
      : false;
  const itemHasModis = itemWithModis(item) || itemIdentifiers.length > 0;
  const isSurroundedByModis = prevHasModis || itemHasModis;
  return isSurroundedByModis;
};

/**
 * Checks for equal modifiers between the same products (parts) in bundle.
 * Returns false if at least one modifier is not overlapping.
 */
export const hasEqualModifiers = (item: CartItem, part: CartItem): boolean => {
  const comparedModifiers = productCustomizationList.map((customisation) => {
    for (const [partKey, partValue] of Object.entries(part)) {
      for (const [itemKey, itemValue] of Object.entries(item)) {
        // finds match for modifier title and part & item key
        if (customisation === partKey && customisation === itemKey) {
          const areModisEqual = isEqual(partValue, itemValue);
          return areModisEqual;
        }
      }
    }
  });
  // if comparedModifiers has false -> modifier is not equal
  return !comparedModifiers.includes(false);
};

/**
 * Combines duplicated bundle's parts and calculates its amount.
 * @param parts
 * @returns array with item and its amount
 */
export const combineDuplicatedCartItems = (
  parts: CartItem[]
): CombinedCartItem[] => {
  const amountsOfItems: CombinedCartItem[] = [];

  parts.filter((part, index) => {
    let amount = 1;
    const getIndexForAmount = amountsOfItems.indexOf(
      amountsOfItems.find(
        (o) => o.item.posPlu === part.posPlu
      ) as CombinedCartItem
    );
    const itemToCompare = parts?.find(
      (p) => p.posPlu && part.posPlu && p.posPlu === part.posPlu
    ) as CartItem;

    // checks for repeated items in bundle with overlapping modifiers
    if (
      itemToCompare &&
      parts?.indexOf(itemToCompare) !== index &&
      hasEqualModifiers(itemToCompare, part)
    ) {
      if (getIndexForAmount !== -1) {
        amount = amountsOfItems[getIndexForAmount].amount + 1;
        amountsOfItems.splice(getIndexForAmount, 1, {
          item: part,
          amount,
        });
      }
      return false;
    } else {
      amountsOfItems.push({ item: part, amount });
      return true;
    }
  });

  return amountsOfItems;
};

/**
 * Calculates amount of elements to display in cart item.
 * @param item cart item
 * @returns
 */
export const getProductIngridientsAmount = (item: CartItem): number => {
  let amount = 0;
  amount += getModifiersAmount(item);
  if (item.parts?.length > 0) {
    const newItems = combineDuplicatedCartItems(item.parts);

    newItems.forEach((part: CombinedCartItem) => {
      amount += 1;
      const displaysIdentifiers = !!getProductTypeName(
        item.tagLookup,
        part.item.tags
      );
      amount +=
        getIdentifiersFromTags(part.item.tags, part.item.tagLookup).length &&
        displaysIdentifiers
          ? 1
          : 0;
      amount += getModifiersAmount(part.item);
    });
  }

  return amount;
};

/**
 * Extracts identifiers for the cart item product or part with use of menu.
 * @param item
 * @param menuStructure
 * @param multipartParent
 * @returns
 */
export const findIdentifiersWithMenu = (
  item: CartItem,
  menuStructure: MenuStructure,
  addTitle: boolean,
  multipartParent?: CartItem
): string[] => {
  let categoryPath;

  // category extracted from jsonPathAccessor
  let category: Category | MultiPart;

  // direct category above product that has tags information
  let directCategory: Category | MultiPart | MultipartSection;

  // product object from menu
  let originalItemObj: Product | undefined = undefined;

  // name to display in label
  let productName: string;

  let identifiers: string[] = [];

  if (multipartParent) {
    categoryPath =
      menuStructure.categoryLookup[multipartParent.productId as number]?.split(
        "/"
      ) ?? [];
    category = jsonPathAccessor(categoryPath, menuStructure) as MultiPart;

    if (category?.multiPartSection) {
      // Family Fiesta example
      category.multiPartSection.forEach((s: MultipartSection) => {
        if (s.categories?.length > 0) {
          // Customisable Product (Burrito, Bowl)
          s.categories.forEach((c) => {
            const match = c.products?.find((p) => p.id === item.productId);
            if (match) {
              directCategory = c;
              originalItemObj = match;
              productName = c.name;
            }
          });
        } else {
          // Non-customisable product (Souce, Side)
          const match = s.products.find((p) => p.id === item.productId);
          if (match) {
            directCategory = category;
            originalItemObj = match;
            productName = match.name;
          }
        }
      });
    } else {
      // Little G Meal, Taco example
      originalItemObj = category?.products?.find(
        (product: Product) => product.id === item.productId
      );
      directCategory = category;
      // commented out to hide name for little g meal main products
      // productName = originalItemObj?.name;

      if (!originalItemObj) {
        category?.multiPart?.forEach((mp: MultiPart) => {
          mp.multiPartSection?.forEach((mps: MultipartSection) => {
            const match = mps.products.find((p) => p.id === item.productId);
            if (match) {
              originalItemObj = match;
              if (category?.multiPart?.length > 1) {
                // Customisable Product (Taco)
                directCategory = mps;
                productName = mps.name;
              } else {
                // Non-customisable product (Souce, Side)
                productName = match.name;
              }
            }
          });
        });
      }
    }
  } else {
    // Burrito example
    categoryPath =
      menuStructure.categoryLookup[item.productId as number]?.split("/") ?? [];
    category = jsonPathAccessor(categoryPath, menuStructure) as Category;
    directCategory = category;
    originalItemObj = category?.products?.find(
      (product: Product) => product.id === item.productId
    );
    productName = category.name;

    if (!originalItemObj) {
      // Coke example, Non-customisable product
      category.tags?.forEach((tag: string) => {
        const matchedTag = directCategory?.tagLookup
          ?.filter((t: TagLookup) => t.type === TagType.Size)
          .find((t: TagLookup) => t.tagId === tag);
        if (matchedTag) {
          identifiers.push(matchedTag.value as string);
        }
      });
    }
  }

  if (originalItemObj) {
    identifiers = [
      ...getIdentifiersFromTags(
        originalItemObj.tags,
        directCategory.tagLookup,
        addTitle,
        productName
      ),
    ];
  }

  return identifiers;
};

export const ChannelIdMap = ["App", "Web"];

interface RedeemGYGerrorState {
  errMsg: string;
  isError: boolean;
}
/**
 * Validates typed GYG dollars amount to redeem against string format, available GYG dollars' amount and total of the order.
 * @param value typed value in the input
 * @param dollars available GYG dollars in loyalty account
 * @param total order's total value
 * @returns
 */
export const validateGYGdollarsAmount = (
  value: string,
  dollars: number | null | undefined,
  total: number
): RedeemGYGerrorState => {
  const onlyNumber = /^[0-9]{1,8}([.][0-9]{1,2})?$/;
  // format original string to number to string with decimals and back to float with decimals eg. "2" => 2 => "2.00" => 2.00
  const numberValue = parseFloat(parseFloat(value).toFixed(2));

  let err: RedeemGYGerrorState = {
    errMsg: "",
    isError: false,
  };

  // validation
  if (!onlyNumber.test(value)) {
    // check if value has numbers or dot/comma
    err = {
      errMsg: "Loyalty:redeemGYGdollarsFormatError",
      isError: true,
    };
  } else if (!numberValue) {
    // check if value is not 0
    err = {
      errMsg: "Loyalty:redeemGYGdollarsAmountError",
      isError: true,
    };
  } else if (
    total !== undefined &&
    numberValue > parseFloat(total.toFixed(2))
  ) {
    err = {
      errMsg: "Loyalty:redeemGYGdollarsUpLimitError",
      isError: true,
    };
  } else if (dollars && numberValue > parseFloat(dollars.toFixed(2))) {
    err = {
      errMsg: "Loyalty:redeemGYGdollarsLowLimitError",
      isError: true,
    };
  } else {
    err = {
      errMsg: "",
      isError: false,
    };
  }

  return err;
};

export const getOrderTimeDifferenceWithCurrentTime = (
  orderTime: number
): number => {
  return moment(orderTime).diff(moment(), "minute");
};

/**
 * checks if order asap time is still current time + 4 minutes
 * @param orderTime
 * @param orderAsap
 * @returns boolean
 */
export const isOrderTimeAsapExpired = (
  orderTime: number | null,
  orderAsap: boolean
): boolean => {
  if (!orderAsap) {
    return false;
  } else {
    return orderTime && getOrderTimeDifferenceWithCurrentTime(orderTime) < 3;
  }
};

/**
 * dispatches action for create or update order
 * dispatch createOrder if no orderId
 * dispatch updateOrder if there is orderId
 * @param store
 * @param posMenuId
 * @param orderCollectionType
 * @param cartItems
 * @param orderTime
 * @param orderASAP
 * @param selectedStore
 * @param tableNumber
 * @param timeZone
 * @param activeReward
 */
export const createOrUpdateOrder = (
  store: ReduxStore,
  posMenuId: number,
  orderCollectionType: CollectionType,
  cartItems: CartItem[],
  orderTime: number | null,
  orderASAP: boolean,
  storeAsapTime: Date,
  selectedStore: Store | null,
  tableNumber: string | undefined,
  timeZone: string,
  orderId: string | undefined,
  activeReward?: IRewards | null,
  gygDollars?: number | null,
  delivery?: DeliveryOrder | null
): void => {
  if (cartItems.length == 0) return;
  const getCoupons = () => {
    if (activeReward?.userCouponCode) {
      return { coupons: [{ code: activeReward?.userCouponCode }] };
    } else {
      return {};
    }
  };

  const finalTime = orderTime || 0;

  if (orderTime && isOrderTimeAsapExpired(orderTime, orderASAP)) {
    store.dispatch(orderActions.updateOrderTime(storeAsapTime.getTime()));
  }

  if (selectedStore?.id) {
    const payload: CreateOrderPayload = {
      storeId: selectedStore.id,
      collectionType: orderCollectionType,
      delivery,
      posMenuId,
      pickUpTime: moment(finalTime).tz(timeZone).format(),
      basketItems: generateCreateOrderItemPayload(cartItems),
      ...getCoupons(),
    };
    if (tableNumber) {
      payload.tableNumber = tableNumber;
    }
    if (gygDollars) {
      payload.gygDollars = { amount: gygDollars };
    }

    //if there is order id passed, it's update order
    if (orderId) {
      store.dispatch(
        orderActions.updateOrderById({
          orderId: orderId,
          orderPayload: payload,
        })
      );
    } else {
      store.dispatch(BraintreeReduxActions.clearPaymentPayload());
      store.dispatch(CheckoutAction.clearClientToken());
      store.dispatch(orderActions.createOrder(payload));
    }
  }
};

export const isOrderASAP = (
  pickUpTime: string,
  submissionTime: string
): boolean => {
  const diffInMinutes = moment(pickUpTime).diff(
    moment(submissionTime),
    "minutes"
  );
  return diffInMinutes <= 4;
};

/**
 * maps date object into moment object with timezone
 * @param date
 * @param timeZone
 * @returns Moment object with timezone
 */
export const convertTimeToTimezone = (
  date: Date,
  timeZone: string | undefined
): Moment => {
  if (timeZone) {
    return moment(date).tz(timeZone);
  } else {
    return moment(date);
  }
};

/**
 * get the timezone offset in minutes,
 * after converting date object to moment with timezone
 * @param date
 * @param timeZone
 * @returns timezone offset
 */
export const getTimeZoneOffset = (
  date: Date,
  timeZone: string | undefined
): number => {
  return convertTimeToTimezone(date, timeZone).utcOffset();
};

/**
 * Calculate the difference between UTC and Sydney time
 * @returns +11:00 / +10:00
 */
export const getStoreUTC = (timezone?: string): string => {
  return moment()
    .tz(timezone ?? DEFAULT_TIMEZONE)
    .format("Z");
};

export const sortOrderByTime = (
  orders: CheckoutResponse[],
  sortingType: LodashSortingType
): CheckoutResponse[] => {
  return orderBy(orders, (i) => i.order.pickupTime, [sortingType]);
};

export const getHighestPriceProductFromCart = (
  cartItem: CartItem[]
): CartItem => {
  let item = cartItem[0];
  for (const basketItem of cartItem) {
    if (basketItem.price > item.price) {
      item = basketItem;
    }
  }
  return item;
};

/**
 * @param checkoutResponse
 * @returns true if order is older than activeOrderUntil
 */
export const isOlderThanActiveOrderThreshold = (
  checkoutResponse: CheckoutResponse | null
): boolean => {
  if (!checkoutResponse) {
    return;
  }

  if (checkoutResponse.order.delivery) {
    if (
      ([
        OrderFinalisationStatusType.COMPLETED,
        OrderFinalisationStatusType.CREATED,
        OrderFinalisationStatusType.RECEIVED,
        OrderFinalisationStatusType.DELIVERED,
        OrderFinalisationStatusType.AWAITING_PICKUP,
      ].includes(checkoutResponse.order.orderFinalisationStatus) &&
        moment(checkoutResponse.order.activeOrderUntil).unix() >
          moment().unix()) ||
      checkoutResponse.order.orderProgressStatus.orderProgressCode ==
        OrderProgressEnum.RECEIVEDATSITE ||
      checkoutResponse.order.orderProgressStatus.orderProgressCode ==
        OrderProgressEnum.INPROGRESS
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return checkoutResponse
      ? moment(checkoutResponse.order.activeOrderUntil).unix() <=
          moment().unix()
      : true;
  }
};

export const isTrackingUrlExpired = (orderStatus: GetOrderResponse) => {
  if (orderStatus) {
    if (orderStatus.delivery?.trackingUrlExpiresAt) {
      return moment() > moment(orderStatus.delivery?.trackingUrlExpiresAt);
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const addOrdersToAccount = (
  store: ReduxStore,
  orders: CheckoutResponse[]
): void => {
  const currentGuestOrders = orders
    .filter((order) => !isOlderThanActiveOrderThreshold(order))
    .map((checkout: CheckoutResponse) => checkout.order.orderId);
  const recentGuestOrders = orders
    .filter((order) => isOlderThanActiveOrderThreshold(order))
    .map((checkout: CheckoutResponse) => checkout.order.orderId);

  store.dispatch(
    orderActions.addToAccount({
      ordersWithLoyalty: currentGuestOrders,
      ordersToAssign: recentGuestOrders,
    })
  );
};

/**
 *
 * @returns a new ratingOrder object that contains all the needed information
 */
export const createRatingOrder = (
  ratingOrder: CheckoutResponse,
  menuStructure?: MenuStructure
): RatedOrderProps => {
  const highestPriceProduct =
    ratingOrder &&
    getHighestPriceProductFromCart(
      basketMapper(ratingOrder.order.basket.basketItems, undefined)
    );

  const ratingOrderObject: RatedOrderProps = {
    cartSize: ratingOrder?.order.basket.basketItems.length,
    cartAmount: ratingOrder?.order.basket.total,
    orderId: ratingOrder?.order.orderId,
    orderNumber: ratingOrder?.order.orderNumber,
    storeName: ratingOrder?.store.name,
    storeId: ratingOrder?.store.id,
    orderType: ratingOrder?.orderCollectionType,
    numberOfOtherProducts: ratingOrder?.order.basket.basketItems.length
      ? ratingOrder?.order.basket.basketItems.length - 1
      : 0,
    highestPriceProduct: highestPriceProduct,
  };

  // get rating order product image
  if (
    menuStructure &&
    ratingOrderObject.highestPriceProduct &&
    menuStructure.categoryLookup
  ) {
    const objPath =
      menuStructure.categoryLookup[
        ratingOrderObject.highestPriceProduct.productId
      ]?.split("/");
    const category = jsonPathAccessor(objPath, menuStructure);

    if (category) {
      ratingOrderObject.productImage = category.imageTopDownUrl;
    }
  }

  return ratingOrderObject;
};

export const getRatingOrder = (
  isAuthSuccess: boolean,
  currentOrders: CheckoutResponse[],
  recentOrders: CheckoutResponse[],
  guestOrders: CheckoutResponse[],
  menuStructure?: MenuStructure
): RatedOrderProps => {
  let ratingOrder: CheckoutResponse | undefined = undefined;

  if (isAuthSuccess) {
    if (currentOrders.length) {
      ratingOrder = currentOrders[0];
    } else if (recentOrders.length) {
      ratingOrder = recentOrders[0];
    }
  } else if (guestOrders.length) {
    ratingOrder = sortOrderByTime(guestOrders, LodashSortingType.DESC)[0];
  }

  return createRatingOrder(ratingOrder, menuStructure);
};

/**
 * Gets the next available time slot for the order from current day and next day times slots (only)
 * @param orderTime
 * @param timeSlots
 * @param timezone
 * @returns
 */
export const getNextAvailableTime = (
  orderTime: number | null,
  timeSlots: OrderTimes[],
  timezone?: string
): number => {
  const defaultTime = moment.tz(timezone).add(4, "minutes").valueOf();

  if (!orderTime) {
    return defaultTime;
  } else if (timeSlots.length === 0) {
    return orderTime;
  } else if (!timezone) {
    return orderTime;
  }

  const currentOrderDate = moment.tz(orderTime, timezone);
  const currentOrderTime = moment
    .tz(Math.max(orderTime, defaultTime), timezone)
    .format("HH:mm");

  const thisDayTimes = timeSlots.find((times) =>
    moment.tz(times.date, timezone).isSame(currentOrderDate, "date")
  );
  if (thisDayTimes) {
    const timeslot = thisDayTimes.timeSlots.find((timeslot) => {
      return moment
        .tz(timeslot, "HH:mm", timezone)
        .isSameOrAfter(
          moment.tz(currentOrderTime, "HH:mm", timezone),
          "minute"
        );
    });
    if (timeslot) {
      return moment
        .tz(`${thisDayTimes.date}T${timeslot}`, timezone)
        .set({ second: 0, millisecond: 0 })
        .valueOf();
    }
  }
  const nextDayTimes = timeSlots.find((times) =>
    moment.tz(times.date, timezone).isAfter(currentOrderDate, "date")
  );
  if (nextDayTimes) {
    const timeslot = nextDayTimes.timeSlots[0];
    if (timeslot) {
      return moment
        .tz(`${nextDayTimes.date}T${timeslot}`, timezone)
        .set({ second: 0, millisecond: 0 })
        .valueOf();
    }
  }

  return orderTime;
};

export const checkIfOrderNotFoundError = (
  orderError: ErrorReduxModel.ErrorResponse
) => orderError?.statusCode === HttpStatus.NotFound;

export const isCheckoutInProgressError = (
  orderError?: ErrorReduxModel.ErrorResponse
) => orderError?.statusCode === HttpStatus.CheckoutInProgress;

export const isOrderTotalInvalid = (total: number | undefined) => {
  return total && total < 0;
};

export const displayTax = (locale: string, salesTax: number | undefined) => {
  return locale === locales.US && !!salesTax;
};

export const formatOrderResponse = (getOrderResponse: OrderResponse | null) => {
  const showTax = displayTax(
    config.version,
    getOrderResponse?.basket?.salesTax
  );
  const model: OrderReceiptInfo = {
    redeemDollars: getOrderResponse?.gygDollars?.applied ?? null,
    redeemDollarsBalance:
      getOrderResponse?.loyalty?.actualDollarsBalance ?? null,
    redeemDollarsErrorMessage:
      getOrderResponse?.gygDollars?.errors[0]?.errorDescription,
    basketValue: getOrderResponse?.basket?.basketValue ?? 0,
    total: getOrderResponse?.basket?.total ?? 0,
    totalBeforeDiscount:
      // US totalExcl: totalBeforeDiscount - discountAmount + surcharge
      // AU totalIncl: totalBeforeDiscount - discountAmount + surcharge
      config.version === locales.US
        ? getOrderResponse?.basket?.totalExcl
        : (getOrderResponse?.basket?.totalIncl ?? 0),
    subtotal: getOrderResponse?.basket?.subtotal ?? 0,
    surcharges: getOrderResponse?.basket?.surcharges ?? [],
    tax: getOrderResponse?.basket?.salesTax ?? 0,
    displayTax: showTax,
    discountAmount: getOrderResponse?.basket.discountAmount,
  };

  return model;
};

export const activeRewardsList = (
  rewards: IRewards[],
  basketCoupons?: BasketCoupon[]
): Reward[] => {
  return rewards
    .filter((iReward) => {
      return iReward.name !== coffeeCouponName;
    })
    .map((iReward) => {
      const basketCoupon = basketCoupons?.find(
        (coupon) => coupon.code === iReward.userCouponCode
      );
      if (!!basketCoupon && basketCoupon.errors.length) {
        return {
          ...iReward,
          errorMessage: basketCoupon.errors[0].errorDescription,
        } as Reward;
      }
      return { ...iReward } as Reward;
    });
};

export const needToSyncCoupons = (
  getOrderResponse: OrderResponse | null,
  activeReward: IRewards | null
) => {
  const validCoupons = getOrderResponse?.basket.coupons?.filter((c) => {
    return c.couponType !== BasketCouponType.LoyaltyCoffee;
  }); // Ignore a coffee coupon applied on BE
  const removedReward = validCoupons?.length && !activeReward;
  const addedReward = !validCoupons?.length && !!activeReward;

  return removedReward || addedReward;
};

export const hasNoAvailablePickupSlotsToday = (
  time: number | Date | null | undefined,
  asapAlwaysEnabled?: boolean,
  storeTimeZone?: string
) => {
  if (process.env.hasOwnProperty("JEST_WORKER_ID")) {
    return false;
  }
  const timeM = moment.tz(time, storeTimeZone);
  const now = moment.tz(storeTimeZone);
  const timeIsToday = timeM.isSame(now, "day");
  return asapAlwaysEnabled ? false : !timeIsToday;
};

export const formatDeliveryDuration = (deliveryDuration?: {
  min: string | number;
  max: string | number;
}): string => {
  if (!deliveryDuration) {
    return;
  }

  return `${[
    ...(deliveryDuration.min ? [`${deliveryDuration.min}`] : []),
    ...(deliveryDuration.max ? [`${deliveryDuration.max}`] : []),
  ].join(" - ")} mins`;
};
