import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Image,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import {
  CartReduxModels,
  OrderModules,
  PlatformUtils,
  ProductCustomizationUtils,
} from "../..";
import colours from "../styles/colours";
import { IconSize, Spacing } from "../styles/number";
import { RealtiveStyle, Typography } from "../styles/typography";

interface CustomisationListProps {
  cartItem: CartReduxModels.CartItem;
  expand: boolean;
  expandable?: boolean;
  containsInvalidModifier?: boolean;
}

const styles = StyleSheet.create({
  subProductTitle: {
    fontSize: 16,
    fontFamily: "Sini-BoldItalic",
    textTransform: "uppercase",
    lineHeight: 20,
    letterSpacing: 0.5,
    ...RealtiveStyle(16).sini,
  },
  productTitle: {
    ...Typography.titleTwo,
    fontSize: 16,
  },
  customisationRow: {
    marginVertical: Spacing.Narrow,
  },
  multipartMarginTop: {
    marginTop: Spacing.ExtraThin,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    width: IconSize.Small,
    height: IconSize.Small,
    marginLeft: Spacing.ExtraThin,
    resizeMode: "cover",
  },
});

export const customisationTextStyles = (
  isValid?: boolean,
  expandable?: boolean
) =>
  StyleSheet.create({
    customizationText: {
      fontSize: 16,
      fontFamily: expandable ? "Sini-BoldItalic" : "Sini-Bold",
      lineHeight: 20,
      letterSpacing: 0.5,
      color: isValid === false ? colours.red : colours.black,
      ...RealtiveStyle(16).sini,
    },
  });

const CustomisationList: React.FC<CustomisationListProps> = ({
  cartItem,
  expand,
  expandable,
  containsInvalidModifier,
}) => {
  const renderElementsKey: string[] = useMemo(() => [], []);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (containsInvalidModifier) {
      setExpanded(true);
    }
  }, [containsInvalidModifier]);

  const modifierElement = useCallback(
    (
      modifier: CartReduxModels.Modifier,
      index: number,
      parentId: number,
      prefix?: string
    ) => {
      renderElementsKey.push(`${modifier.name}-${index}-${parentId}`);
      return (
        <View
          key={`${modifier.name}-${index}-${parentId}`}
          style={styles.customisationRow}>
          <Text
            style={
              customisationTextStyles(modifier?.isValid, expandable)
                .customizationText
            }>
            {prefix && `${prefix} `}
            {modifier.name}
          </Text>
        </View>
      );
    },
    [expandable, renderElementsKey]
  );

  const identifierElement = useCallback(
    (identifiers: string[]) => {
      renderElementsKey.push(
        `${identifiers.join()}-${identifiers.length}-${cartItem.productId}`
      );

      return (
        <View
          {...PlatformUtils.generateTestID(Platform.OS, "CartItemIdentifier")}
          key={`${identifiers.join()}`}
          style={styles.customisationRow}>
          <Text
            style={customisationTextStyles(true, expandable).customizationText}>
            {identifiers.map((i) => (
              <Text key={i}>{i} </Text>
            ))}
          </Text>
        </View>
      );
    },
    [cartItem.productId, expandable, renderElementsKey]
  );

  const multipartTitleElement = useCallback(
    (
      amount: number,
      title: string,
      addExtraMargin: boolean,
      id: number,
      isValid?: boolean
    ) => {
      renderElementsKey.push(`${amount}-${title}-${id}`);

      return (
        <View
          key={`${amount}-${title}-${id}`}
          style={addExtraMargin && styles.multipartMarginTop}
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "CartItemMultipartTitle"
          )}>
          <Text
            style={
              expandable
                ? customisationTextStyles(isValid, expandable).customizationText
                : styles.subProductTitle
            }>
            {amount > 1 ? `${amount} ${title}` : `${title}`}
          </Text>
        </View>
      );
    },
    [expandable, renderElementsKey]
  );

  const addCustomisation = useCallback(
    (item: CartReduxModels.CartItem) => {
      const modifiers: JSX.Element[] = [];
      ProductCustomizationUtils.productCustomizationList.map(
        (modifierTitle: string) => {
          for (const [key, value] of Object.entries(item)) {
            if (key === modifierTitle && value) {
              value.forEach(
                (modifier: CartReduxModels.Modifier, index: number) => {
                  const isSub = modifier.modifierId === 8;
                  const prefix =
                  ProductCustomizationUtils.addCustomisationPrefix(key, isSub);
                  modifiers.push(
                    modifierElement(modifier, index, item.productId, prefix)
                  );
                }
              );
            }
          }
        }
      );
      return modifiers;
    },
    [modifierElement]
  );

  /**
   * Renders list of modifiers/products in the cart element.
   * @returns
   */
  const renderList = useMemo(() => {
    let renderElements: JSX.Element[] = [];

    // adds identifier modifiers (like spice level, main filling)
    const identifiers = OrderModules.OrderUtils.getIdentifiersFromTags(
      cartItem.tags,
      cartItem.tagLookup
    );
    if (identifiers.length) {
      renderElements.push(identifierElement(identifiers));
    }

    // adds customisation for main products (usually singles)
    renderElements = renderElements.concat(addCustomisation(cartItem));

    if (cartItem.parts) {
      const newItems = OrderModules.OrderUtils.combineDuplicatedCartItems(
        cartItem.parts
      );

      // adds customisations for multiparts and its' name
      newItems.forEach(
        (
          { item, amount }: OrderModules.OrderModels.CombinedCartItem,
          index: number
        ) => {
          const itemIdentifier = OrderModules.OrderUtils.getIdentifiersFromTags(
            item.tags,
            cartItem.tagLookup
          );

          const addTopMargin =
            renderElements.length > 0 &&
            OrderModules.OrderUtils.checkIfSurroundedByModifiers(
              newItems,
              item,
              itemIdentifier,
              index,
              cartItem.tagLookup
            );

          renderElements.push(
            multipartTitleElement(
              amount,
              item.name,
              addTopMargin,
              item.productId,
              item?.isValid
            )
          );

          renderElements = renderElements.concat(addCustomisation(item));
        }
      );
    }

    if (expandable) {
      return expanded ? renderElements : [];
    }

    if (expandable) {
      return expanded ? renderElements : [];
    }

    return expand
      ? renderElements
      : renderElements.slice(
          0,
          OrderModules.OrderConstants.VisibleCustomisations
        );
  }, [
    addCustomisation,
    cartItem,
    expand,
    expandable,
    expanded,
    identifierElement,
    multipartTitleElement,
  ]);

  const onExpandPress = () => {
    LayoutAnimation.configureNext({
      duration: 100,
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
    setExpanded(!expanded);
  };

  return (
    <>
      <View>
        {expandable && (
          <TouchableOpacity onPress={onExpandPress}>
            <View style={styles.row}>
              <Text style={styles.productTitle}>{cartItem.name}</Text>
              <Image
                source={
                  expanded
                    ? require("gyg_common/components/assets/icons/icon_chevron_up_small.png")
                    : require("gyg_common/components/assets/icons/icon_chevron_down_small.png")
                }
                style={styles.icon}
              />
            </View>
          </TouchableOpacity>
        )}
        {renderElementsKey.length > 0 &&
          renderList.map((el, index) => {
            return (
              <View key={`${renderElementsKey[index]}-${index}` || index}>
                {el}
              </View>
            );
          })}
      </View>
    </>
  );
};

export default CustomisationList;
