import useAddToOrder from "hooks/useAddToOrder";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import {
  CartReduxModels,
  MenuModels,
  ProductConst,
  ProductUtils,
  RootState,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import ProductMultipartSectionSelection from "gyg_common/components/ProductMultipartSectionSelection";
import AddModifyCartItem from "gyg_common/components/Products/AddModifyCartItem";
import ProductDetail from "gyg_common/components/Products/ProductDetail";
import ProductDetailSticky from "gyg_common/components/Products/ProductDetailSticky";
import ProductMultiPartSectionItem from "gyg_common/components/Products/ProductMultiPartSectionItem";
import useUpdateOrder from "gyg_common/hooks/useUpdateOrder";
import { Modifier } from "gyg_common/redux_store/cart/models";
import colours from "styles/colours";
import { Typography } from "styles/typography";
import ProductTemplateSelector from "views/components/Products/ProductTemplateSelector";

const styles = {
  container: {
    flex: 1,
  },
  multipartList: {
    marginHorizontal: 16,
    marginBottom: 8,
  },
  listContainer: {
    marginTop: 16,
  },
  customization: {
    backgroundColor: colours.lightestGrey,
    paddingHorizontal: 16,
    paddingVertical: 22,
    borderBottomColor: colours.lightGrey,
    borderBottomWidth: 1,
  },
  required: {
    ...Typography.bodyTwo,
    marginTop: 8,
  },
};

interface ProductBundleProps {
  multiPart: MenuModels.MultiPart;
  bundleItem: CartReduxModels.BundleState;
  handleResetBundleItem: (index: number) => void;
  handleUpdateBundleItem: (
    cartItem: CartReduxModels.CartItem,
    index: number
  ) => void;
  goBack: () => void;
  editMode: ProductConst.EditMode;
  cartIndex?: number;
  quantity?: number;
  isUpsell?: boolean;
}

const ProductBundle: React.FC<ProductBundleProps> = ({
  multiPart,
  handleResetBundleItem,
  bundleItem,
  handleUpdateBundleItem,
  goBack,
  editMode,
  cartIndex,
  quantity,
  isUpsell,
}): JSX.Element => {
  const { isDesktopScreen } = useMediaQuery();
  const [step, updateStep] = useState<number>(1);
  const [lastStep, updateLastStep] = useState<number>(1);
  const [selectionData, setSelectionData] = useState<
    (MenuModels.Category | MenuModels.Product)[]
  >([]);
  const [selectedCategory, updateSelectedCategory] = useState<
    MenuModels.Category | undefined
  >(undefined);
  const [selectedMultipartItem, updateSelectedMultipartItem] = useState<
    CartReduxModels.CartItem | undefined
  >(undefined);
  const [selectedMultiPartSectionIndex, updateSelectedMultiPartSectionIndex] =
    useState<number>(0);
  const [stickerVisible, setStickerVisible] = useState<boolean>(false);
  const [isApplyAllUsed, setIsApplyAllUsed] = useState<boolean>(false);
  const nodeRef = React.useRef(null);
  const { t } = useTranslation();
  const { isLoading } = useSelector((s: RootState) => s.favourite);
  const handleUpdateOrder = useUpdateOrder(editMode, goBack);
  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    applyAll: isApplyAllUsed,
    categoryName: multiPart?.name ?? "",
    onClose: goBack,
  });

  const cartItems = ProductUtils.getBundleCartItem(
    bundleItem,
    multiPart.multiPartSection?.length || 0,
    multiPart,
    quantity || 1
  );

  /**
   * Listens for scroll action to show/hide sticky product details.
   */
  useEffect(() => {
    const target = document.getElementById("product-img");

    if (target != null) {
      const handleScroll = (entries: IntersectionObserverEntry[]) => {
        setStickerVisible(!entries[0].isIntersecting);
      };
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: [1, 0],
      };

      const observer = new window.IntersectionObserver(handleScroll, options);
      observer.observe(target);

      return function cleanup() {
        if (target != null) {
          observer.unobserve(target);
        }
      };
    }
  });
  const goToStep = (index: number) => {
    if (step != lastStep) {
      updateLastStep(step);
    }
    updateStep(index);
  };

  /**
   * Select a template to customise (e.g. Burritos)
   * @param category
   * @param multiPartSectionItem
   */
  const handleCategorySectionSelected = (
    category: MenuModels.Category,
    multiPartSectionItem?: CartReduxModels.CartItem
  ) => {
    updateSelectedMultipartItem(multiPartSectionItem);
    updateSelectedCategory(category);
    goToStep(3);
  };
  const handleResetSelection = () => {
    updateStep(1);
    updateLastStep(1);
    setSelectionData([]);
    updateSelectedMultiPartSectionIndex(0);
    updateSelectedCategory(undefined);
  };
  const handleOnAddToBundleItem = (
    item: MenuModels.Product,
    index?: number
  ) => {
    handleUpdateBundleItem(
      {
        productId: item.id,
        posPlu: item.posPlu,
        price: item.price,
        quantity: 1,
        name: item.name,
        unitPrice: item.price,
      },
      index || selectedMultiPartSectionIndex
    );
    handleResetSelection();
  };
  const isResetValid = (bundleItems: CartReduxModels.BundleState) => {
    for (const i in bundleItems) {
      if (bundleItems[i]) {
        return true;
      }
    }
    return false;
  };
  const onResetBundle = () => {
    for (const i in bundleItem) {
      handleResetBundleItem(parseInt(i));
    }
  };
  const handleOnApplyAll = (
    multiPartModel: MenuModels.MultiPart,
    bundleItemModel: CartReduxModels.CartItem,
    removeModifiers: Modifier[],
    excludedIndexs: string[]
  ) => {
    const validIndexs = ProductUtils.applyAllValidObjects(
      multiPartModel,
      bundleItemModel,
      excludedIndexs
    );
    validIndexs.forEach((i) => {
      handleUpdateBundleItem(
        {
          ...bundleItemModel,
          productId: i.product.id,
          removeModifier: removeModifiers,
        },
        i.index
      );
    });
    if (validIndexs.length) {
      setIsApplyAllUsed(true);
    }
  };

  const updateItem = (
    bundleItemModel: CartReduxModels.CartItem,
    removeModifiers: Modifier[],
    indexOfItem: number
  ) => {
    handleUpdateBundleItem(
      {
        ...bundleItemModel,
        removeModifier: removeModifiers,
      },
      indexOfItem
    );
  };

  /**
   * pop up a modal for Burrito / Bowls OR directly redirect to template customization if it's already in customized state
   * @param data
   * @param index
   * @param multiPartSectionItem
   */
  const handleSelectMultpartItem = (
    data: (MenuModels.Category | MenuModels.Product)[] | undefined,
    index: number,
    multiPartSectionItem?: CartReduxModels.CartItem
  ) => {
    if (data) {
      updateSelectedMultiPartSectionIndex(index);
      if (multiPartSectionItem) {
        const category = ProductUtils.getMultipartSectionCategoryFromCartItem(
          multiPartSectionItem,
          data as MenuModels.Category[]
        );
        if (category) {
          handleCategorySectionSelected(category, multiPartSectionItem);
        } else {
          goToStep(2);
          setSelectionData(data);
        }
      } else {
        if (data.length == 1) {
          // jump to MultipartSectionSelected if there is only one selection for MultipartSection
          if ("products" in data[0]) {
            handleCategorySectionSelected(data[0]);
          } else {
            handleOnAddToBundleItem(data[0] as MenuModels.Product, index);
          }
        } else {
          goToStep(2);
          setSelectionData(data);
        }
      }
    }
  };

  const renderBundleView = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        return (
          <>
            <div className='product-wrapper'>
              <div className='product-wrapper-col'>
                <ProductDetail
                  key={multiPart.name}
                  onGoBack={() => {
                    goBack();
                  }}
                  onReset={onResetBundle}
                  resetDisabled={!isResetValid(bundleItem)}
                  name={multiPart.name}
                  imageAngleUrl={multiPart.imageAngleUrl as string}
                  customizationText={multiPart.description as string}
                  price={multiPart.price ? multiPart.price : 0} // remove condition when Price attribute fixed in API
                  showNutritionalDisclaimer={false}
                  cartItems={cartItems}
                />
                {!isDesktopScreen && (
                  <CSSTransition
                    nodeRef={nodeRef}
                    in={stickerVisible}
                    unmountOnExit
                    timeout={200}
                    classNames='product-sticky-header'>
                    <div
                      ref={nodeRef}
                      className={"product-sticky-header-wrapper"}>
                      <ProductDetailSticky
                        onGoBack={() => {
                          goBack();
                        }}
                        name={multiPart.name.toUpperCase()}
                        customizationText={multiPart.description as string}
                        price={multiPart.price ? multiPart.price : 0} // remove condition when Price attribute fixed in API
                      />
                    </div>
                  </CSSTransition>
                )}
              </div>
              <div className='product-wrapper-col'>
                <div className='product__customization'>
                  <View style={styles.customization}>
                    <Text style={Typography.titleTwo}>
                      {t(
                        "OrderManagement:bundleMultipartCustomizeSectionTitle"
                      )}
                    </Text>
                    <Text style={styles.required}>
                      {t("OrderManagement:required")}
                    </Text>
                  </View>
                  <div className='product__customization__multipart'>
                    {multiPart.multiPartSection &&
                      multiPart.multiPartSection.map((item, index) => (
                        <View
                          key={item.name}
                          style={{
                            ...styles.multipartList,
                            marginTop: index === 0 ? 16 : 0,
                          }}>
                          <ProductMultiPartSectionItem
                            handleOnApplyAll={(
                              cartItem: CartReduxModels.CartItem,
                              removeModifiers: CartReduxModels.Modifier[]
                            ) => {
                              handleOnApplyAll(
                                multiPart,
                                cartItem,
                                removeModifiers,
                                ProductUtils.getExcludedIndexs(
                                  bundleItem,
                                  index
                                )
                              );
                            }}
                            updateItem={(
                              cartItem: CartReduxModels.CartItem,
                              removeModifiers: CartReduxModels.Modifier[]
                            ) => updateItem(cartItem, removeModifiers, index)}
                            applyAllValid={
                              ProductUtils.applyAllValidObjects(
                                multiPart,
                                bundleItem[index],
                                ProductUtils.getExcludedIndexs(
                                  bundleItem,
                                  index
                                )
                              ).length > 0
                            }
                            onReset={() => handleResetBundleItem(index)}
                            multiPartSectionItem={bundleItem[index]}
                            multiPartSection={item}
                            onPress={(data) => {
                              handleSelectMultpartItem(
                                data,
                                index,
                                bundleItem[index]
                              );
                            }}
                          />
                        </View>
                      ))}
                  </div>
                </div>
              </div>
              <div className={"product__cart-btn-wrapper"}>
                <AddModifyCartItem
                  categoryName={multiPart?.name ?? ""}
                  editMode={editMode}
                  cartItems={cartItems}
                  index={cartIndex}
                  isUpsell={isUpsell}
                  onAddToOrder={handleAddToOrder}
                  onEditOrder={handleUpdateOrder}
                />
              </div>
              <LoadingScreen loading={isLoading} />
            </div>
          </>
        );
      case 2:
        return (
          <ProductMultipartSectionSelection
            onBack={() => {
              goToStep(1);
            }}
            onAddtoBundleItem={handleOnAddToBundleItem}
            selectionData={selectionData}
            onPress={handleCategorySectionSelected}
          />
        );
      case 3:
        return (
          <ProductTemplateSelector
            cartItem={selectedMultipartItem}
            editMode={ProductConst.EditMode.UPDATE_BUNDLE_ITEM}
            onClose={handleResetSelection}
            onBack={() => {
              goToStep(1);
            }}
            category={selectedCategory}
            index={selectedMultiPartSectionIndex}
            productType={ProductUtils.getProductRoute(
              selectedCategory?.templateId || ""
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{renderBundleView(step)}</>;
};

export default ProductBundle;
