import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import {
  OrderModules,
  OrderReduxAction,
  RootState,
  StoreModules,
  StoreReduxAction,
} from "gyg_common";
import OrderErrorModal from "gyg_common/components/modals/OrderErrorModal";
import { TimeWasChangedModal } from "gyg_common/components/modals/TimeWasChangedModal";
import useVerifyTime, { TimeShiftedType } from "gyg_common/hooks/useVerifyTime";
import { storeAsapTimeSelector } from "gyg_common/redux_store/store/selectors";
import { buildFullAddress } from "gyg_common/redux_store/utils/OrderUtils";
import { Screens } from "navigation/const";
import { NoStoresNearby } from "views/components/Location/NoStoresNearby";
import OrderSetupHeader from "views/components/OrderSetupHeader/OrderSetupHeader";

import { OrderSetupContainer } from "../OrderSetup/OrderSetupContainer";

interface OrderSetupHeaderContainerProps {
  disable?: boolean;
  isShowOrderSetup?: boolean;
  onOrderSetupConfirmed?: () => void;
  orderSetupDidShow?: () => void;
}

const OrderSetupHeaderContainer: React.FC<OrderSetupHeaderContainerProps> = ({
  disable,
  onOrderSetupConfirmed,
  isShowOrderSetup,
  orderSetupDidShow,
}) => {
  const { totalPrice: cartTotalPrice } = useSelector((s: RootState) => s.cart);
  const {
    orderASAP,
    orderCollectionType,
    orderTime,
    delivery,
    deliveryAddress,
  } = useSelector((s: RootState) => s.order);
  const { getOrderResponse } = useSelector((s: RootState) => s.order);
  const {
    selectedStore,
    storeOpenTime,
    storeOrderTimeSlots,
    storeOrderTimeSlots: { storeOrderTimes },
  } = useSelector((s: RootState) => s.store);

  const storeAsapTime = useSelector(storeAsapTimeSelector);
  const addressForDelivery = buildFullAddress(deliveryAddress, {
    businessName: delivery?.dropoffBusinessName,
    suiteAndFloor: delivery?.dropoffSuiteAndFloor,
  });

  const nextAvailableOrderTime = OrderModules.OrderUtils.getNextAvailableTime(
    orderTime,
    storeOrderTimes,
    selectedStore?.timeZoneInfo.storeTimeZone
  );

  const [isOrderTimeChangedModalVisible, setOrderTimeChangedModalVisible] =
    useState<boolean>(false);
  const [timeShiftedType, setTimeShiftedType] = useState(
    TimeShiftedType.Default
  );
  const [isOrderTimeUpdated, setIsOrderTimeUpdated] = useState<boolean>(false);
  const [calculatedOrderTime, setCalculatedOrderTime] = useState<number | null>(
    nextAvailableOrderTime
  );

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onTimeShiftedCallback = useCallback((type: TimeShiftedType) => {
    setTimeShiftedType(type);
    setOrderTimeChangedModalVisible(true);
  }, []);

  const onTimeUpdatedCallback = useCallback(
    (time: number | null, shouldUpdateOrder: boolean) => {
      if (shouldUpdateOrder) {
        dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      } else if (time) {
        setCalculatedOrderTime(time);
      }
    },
    [dispatch]
  );

  useVerifyTime({
    onTimeShiftedCallback,
    onTimeUpdatedCallback,
    calculatedOrderTime,
    reduxStore: store,
  });

  const [orderSetupNavigation, setOrderSetupNavigation] = useState<string>(
    Screens.Menu
  );

  const [showOrderSetup, setOrderSetup] = useState<boolean>(false);

  const closeOrderTimeChangedModal = () => {
    dispatch(OrderReduxAction.setShouldUpdateOrder(true));
    setOrderTimeChangedModalVisible(false);
  };

  const onConfirm = () => {
    navigate(orderSetupNavigation);
    if (onOrderSetupConfirmed) {
      onOrderSetupConfirmed();
    }
    setOrderSetup(false);
  };

  useEffect(() => {
    if (isShowOrderSetup) {
      setOrderSetup(true);
    }
  }, [isShowOrderSetup]);

  useEffect(() => {
    if (showOrderSetup && orderSetupDidShow) {
      orderSetupDidShow();
    }
  }, [orderSetupDidShow, showOrderSetup]);

  useEffect(() => {
    if (
      location.pathname === Screens.Order ||
      location.pathname === Screens.Payment
    ) {
      setOrderSetupNavigation(location.pathname);
    } else {
      setOrderSetupNavigation(Screens.Menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Calculate the storeOpenTime when the Selected Store changes */
  useEffect(() => {
    if (selectedStore) {
      dispatch(
        StoreReduxAction.setStoreOpenTime(
          StoreModules.StoreUtils.checkStoreOpenHours(
            selectedStore?.tradingHours ?? [],
            selectedStore.timeZoneInfo.storeTimeZone
          )
        )
      );
    }
  }, [dispatch, selectedStore, showOrderSetup]);

  // when menu item selection is done and transition to order page
  useEffect(() => {
    const { storeOrderOffset, storeOrderTimes } = storeOrderTimeSlots;
    const hasTimeslotsAndOffset =
      storeOrderOffset.length > 0 && storeOrderTimes.length > 0;

    if (
      !isOrderTimeUpdated &&
      orderTime &&
      OrderModules.OrderUtils.isOrderTimeAsapExpired(orderTime, orderASAP) &&
      hasTimeslotsAndOffset
    ) {
      setIsOrderTimeUpdated(true);
      const newAsapTime = storeAsapTime.getTime();
      dispatch(OrderReduxAction.updateOrderTime(newAsapTime));
    }
  }, [
    location.pathname,
    orderTime,
    orderASAP,
    isOrderTimeUpdated,
    cartTotalPrice,
    dispatch,
    selectedStore?.timeZoneInfo.storeTimeZone,
    getOrderResponse,
    storeOrderTimeSlots,
    storeAsapTime,
  ]);

  const onCloseModal = useCallback(() => {
    setOrderSetup(false);
  }, []);

  const navigateToOrderSetup = useCallback(() => {
    setOrderSetup(true);
  }, []);

  if (selectedStore) {
    return (
      <div>
        <OrderErrorModal />
        <OrderSetupContainer
          onConfirmClick={onConfirm}
          onCloseModal={onCloseModal}
          isVisible={showOrderSetup}
        />

        <TimeWasChangedModal
          timeShiftedType={timeShiftedType}
          isVisible={isOrderTimeChangedModalVisible}
          newTime={calculatedOrderTime}
          storeTimeZone={selectedStore.timeZoneInfo.storeTimeZone}
          closeOrderTimeChangedModal={closeOrderTimeChangedModal}
        />
        <OrderSetupHeader
          timeZone={selectedStore.timeZoneInfo.storeTimeZone}
          isDisabled={disable}
          storeName={selectedStore.name}
          storeOpenTime={storeOpenTime}
          distance={selectedStore.distanceTextShort ?? ""}
          orderCollectionType={orderCollectionType}
          orderTime={calculatedOrderTime}
          orderASAP={orderASAP}
          navigateToOrderSetup={navigateToOrderSetup}
          deliveryDuration={delivery?.deliveryDuration}
          deliveryFee={delivery?.quoteId ? delivery?.fee : undefined}
          deliveryAddress={addressForDelivery}
        />
      </div>
    );
  } else {
    return <NoStoresNearby />;
  }
};

export default OrderSetupHeaderContainer;
