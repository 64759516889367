import "./styles.scss";

import { Card } from "@braze/web-sdk";
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch } from "react-redux";

import { BANNER_BACKGROUND_IMAGE } from "config";
import {
  AuthStateModels,
  CheckoutModels,
  FavouriteModels,
  LoyaltyModels,
  PromoReduxModels,
  StoreReduxModels,
} from "gyg_common";
import locationIcon from "gyg_common/components/assets/icons/icon_location.png";
import NavigationButton, {
  NavigationButtonIconSize,
} from "gyg_common/components/Buttons/NavigationButton";
import CardSticker from "gyg_common/components/CardSticker";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import {
  getCurrentLocationPermission,
  requestLocationPermission,
} from "modules/location";
import DashboardBannerSection from "views/components/Dashboard/DashboardBannerSection";
import { DashboardSortingContainer } from "views/containers/Dashboard/DashboardSortingContainer";
import { FavouriteContainer } from "views/containers/Favourites/FavouriteContainer";
import { RewardsCarouselContainer } from "views/containers/Rewards/RewardsCarouselContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import { PromotionsSection } from "../../components/Dashboard/PromotionsSection";
import { CurrentRecentOrdersSection } from "./CurrentRecentOrdersSection";
import DashboardGomexSection from "./DashboardGomexSection";
import DashboardHeaderSwitch from "./DashboardHeaderSwitch";

export interface DashboardProps {
  favourites: FavouriteModels.GetFavouriteResponse[];
  rewards: LoyaltyModels.IRewards[];
  promotions: Card[];
  availablePromos: PromoReduxModels.Promo[];
  selectedStore: StoreReduxModels.Store | null;
  locationPermission: boolean;
  isUserAuthenticated: boolean;
  currentUser?: AuthStateModels.CurrentUser;
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  loyaltyLoading?: boolean;
  recentOrders: CheckoutModels.CheckoutResponse[];
  currentOrders: CheckoutModels.CheckoutResponse[];
  userConfirmedOrderSetup: boolean;
  versions: { configV: string; packageV: string };
  goToLogin: () => void;
  goToRestaurants: () => void;
  goToMyGomex: () => void;
  goToCoffee: () => void;
  goToRecentOrders: () => void;
  viewCartClick: () => void;
  onLoyaltyRetry?: () => void;
}

const Dashboard: React.FC<DashboardProps> = ({
  selectedStore,
  locationPermission,
  isUserAuthenticated,
  currentUser,
  loyalty,
  coffeeLoyalty,
  currentOrders,
  recentOrders,
  userConfirmedOrderSetup,
  favourites,
  rewards,
  promotions,
  availablePromos,
  versions,
  loyaltyLoading,
  goToLogin,
  goToRestaurants,
  goToMyGomex,
  goToCoffee,
  goToRecentOrders,
  viewCartClick,
  onLoyaltyRetry,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const gb = useGrowthBook();

  /** Check location permission */
  useEffect(() => {
    getCurrentLocationPermission().then((permission) => {
      if (permission === "denied") {
        requestLocationPermission().catch((err) => console.error(err));
      }
    });
  }, [dispatch]);

  const currentOrdersComponent =
    currentOrders.length > 0 ? (
      <CurrentRecentOrdersSection isCurrentOrders orders={currentOrders} />
    ) : null;

  const recentOrdersComponent =
    recentOrders.length > 0 ? (
      <CurrentRecentOrdersSection
        onViewAllButtonClick={goToRecentOrders}
        orders={recentOrders}
      />
    ) : null;

  const favouriteComponent =
    userConfirmedOrderSetup && isUserAuthenticated && favourites.length > 0 ? (
      <FavouriteContainer />
    ) : null;

  const rewardsComponent =
    rewards.length > 0 && isUserAuthenticated ? (
      <RewardsCarouselContainer />
    ) : null;

  const promotionsComponent =
    availablePromos.length > 0 ? (
      <PromotionsSection brazeCards={promotions} />
    ) : null;

  return (
    <div className='dashboard'>
      <HeaderContainer
        showBarcode={isUserAuthenticated}
        bottomComponentNotScrollable={
          !gb?.ready || hasDelivery ? undefined : (
            <DashboardHeaderSwitch
              locationPermission={locationPermission}
              isSelectedStore={!!selectedStore}
              userConfirmedOrderSetup={userConfirmedOrderSetup}
            />
          )
        }
      />
      <DashboardBannerSection
        title={t("Dashboard:heroBannerTitle")}
        subTitle={t("Dashboard:heroBannerSubtitle")}
        bgImage={BANNER_BACKGROUND_IMAGE}
        viewCartClick={viewCartClick}
      />
      <div className='dashboard__container main-wrapper'>
        <div className='dashboard__section'>
          <DashboardGomexSection
            loyalty={loyalty ?? null}
            coffeeLoyalty={coffeeLoyalty}
            loading={loyaltyLoading}
            currentUser={currentUser}
            isUserAuthenticated={isUserAuthenticated}
            goToLogin={goToLogin}
            goToMyGomex={goToMyGomex}
            goToCoffee={goToCoffee}
            onRetry={onLoyaltyRetry}
          />
        </div>

        <DashboardSortingContainer
          currentOrdersComponent={currentOrdersComponent}
          recentOrdersComponent={recentOrdersComponent}
          favouritesComponent={favouriteComponent}
          rewardsComponent={rewardsComponent}
          promotionsComponent={promotionsComponent}
        />

        <div className='dashboard__section'>
          <CardSticker>
            <NavigationButton
              title={t("Dashboard:locationsBtnTitle")}
              subtitle={t("Dashboard:locationsBtnCopy")}
              onClick={goToRestaurants}
              rightIcon={locationIcon as ImageSourcePropType}
              iconSize={NavigationButtonIconSize.BIG}
            />
          </CardSticker>
        </div>

        <div className='dashboard__section'>
          <p className='badge-text' style={{ textAlign: "center" }}>
            GYG {versions.configV} - V {versions.packageV}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
