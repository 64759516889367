import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Animated,
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { OpenTemplateParams } from "gyg_common/navigation";

import {
  CartReduxModels,
  LoyaltyModels,
  MenuModels,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
} from "../../../";
import useIsFocused from "../../../hooks/useIsFocused";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import * as PlatformUtils from "../../../modules/platformUtils";
import { ProductDetailProps } from "../../../modules/Products/model";
import { handleProductScroll } from "../../../utils/product/utils";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";
import MakeItAMeal from "../MakeItAMeal";
import MakeItAMealSection from "../MakeItAMealSection";
import ProductCombinedModifier from "../ProductCombinedModifier";
import ProductModifier from "../ProductModifier";
import CustomizableProductListHeader from "./CustomizableProductListHeader";

export interface CustomizableProductProps {
  preSelectedMiamParts?: CartReduxModels.CartItem[];
  fadeAnimationSticky: Animated.Value;
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  commonSection?: MenuModels.CommonSection;
  MIAMItem?: CartReduxModels.BundleState;
  MIAMCartItem?: CartReduxModels.CartItem;
  customizeOptions: React.MutableRefObject<ProductModuleModel.CustomizableOptionState>;
  customisableSectionData: ProductModuleModel.CustomizeOptionsDataProps[];
  mealCustomizeOptions: ProductModuleModel.MealCustomizableOptionState;
  selectedMealId?: number;
  quantity: number;
  mealRemoveModifierOptions: ProductModuleModel.MealCustomizableOptionState;
  customizableProductInfoProps: ProductDetailProps;
  goBack: () => void;
  handleMIAMsimpleOptionSelected?: (
    optionCartItem: CartReduxModels.CartItem | undefined,
    index: number
  ) => void;
  handleMIAMcustomisableOptionSelected?: (
    category: MenuModels.Category,
    cartItems: CartReduxModels.CartItem[] | undefined,
    index: number
  ) => void;
  handleRemoveMeal: () => void;
  updateSelectedMealId: (id: number) => void;
  getMealMultipart: () => MenuModels.MultiPart | undefined;
  setQuantity: (qty: number) => void;
  quantityDisabled?: boolean;
  errorIndex: number | null;
  clearErrorIndex: () => void;
  onForceUpdate: () => void;
  setStickyHeaderVisible: (visible: boolean) => void;
  openTemplate?: (
    params: OpenTemplateParams,
    cartItems?: CartReduxModels.CartItem[]
  ) => void;
  requiredFieldsError: ErrorsState;
  cartItems?: CartReduxModels.CartItem[];
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null | undefined;
}

export interface ExpandListState {
  [key: string]: boolean;
}

export interface ErrorsState {
  [key: string]: boolean;
}

export interface RenderOptionsItemProps {
  item: ProductModuleModel.CustomizeOptionsDataProps;
  index: number;
}

const CustomizableProductListHeaderWidth = 477;
const CustomizableProductListWebContainerMaxHeight = 768;
const CustomizableProductListWebContainerWidth = 480;
const CustomizableProductListMobileWebContainerMaxWidth = 500;

const keyExtractor = (
  item: ProductModuleModel.CustomizeOptionsDataProps,
  flatListIndex: number
) => {
  return item.id.toString() + flatListIndex.toString();
};

const onScrollToIndexFailed = () => {};

const styles = StyleSheet.create({
  titleSection: {
    flexDirection: "row",
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Regular,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    alignItems: "center",
  },
  header: {
    width: CustomizableProductListHeaderWidth, //web desktop image container width
  },
});

export const containerStyles = (isDesktopScreen: boolean) =>
  StyleSheet.create({
    container: {
      flexDirection: Platform.OS == "web" && isDesktopScreen ? "row" : "column",
      maxHeight:
        Platform.OS == "web" && isDesktopScreen
          ? CustomizableProductListWebContainerMaxHeight
          : null,
      flex: 1,
    },
    listContainer:
      Platform.OS == "web" && isDesktopScreen //web desktop
        ? {
            width: CustomizableProductListWebContainerWidth, //web desktop list container width
            borderLeftWidth: 1,
            borderLeftColor: colours.lightGrey,
            flex: 1,
          }
        : Platform.OS == "web"
          ? {
              flex: 1,
              maxWidth: CustomizableProductListMobileWebContainerMaxWidth,
            } //mobile web or responsive layout width
          : {},
  });

const CustomizableProductList = (
  props: CustomizableProductProps
): JSX.Element => {
  const {
    preSelectedMiamParts,
    category,
    commonSection,
    fadeAnimationSticky,
    MIAMItem,
    MIAMCartItem,
    handleMIAMsimpleOptionSelected,
    handleMIAMcustomisableOptionSelected,
    goBack,
    customisableSectionData,
    customizableProductInfoProps,
    customizeOptions,
    editMode,
    quantity,
    setQuantity,
    quantityDisabled,
    mealCustomizeOptions,
    selectedMealId,
    handleRemoveMeal,
    updateSelectedMealId,
    getMealMultipart,
    mealRemoveModifierOptions,
    errorIndex,
    clearErrorIndex,
    onForceUpdate,
    setStickyHeaderVisible,
    openTemplate,
    requiredFieldsError,
    cartItems,
    coffeeLoyalty,
  } = props;

  const isFocused = useIsFocused();
  const { isDesktopScreen } = useMediaQuery();
  const isWebDesktop = Platform.OS === "web" && isDesktopScreen;
  const { t } = useTranslation();
  const initialState = ProductUtils.generateCustomizableOptionState(category);

  const FlatListRef: RefObject<FlatList> = useRef(null);
  const fadeAnimationProductDetail = useRef(new Animated.Value(1)).current;
  // track expansion of modifiers, ie: Show All Fillings as it can cause FlatList height increment
  const isShowMoreOrLessModifiers = useRef<boolean>(false);
  // track scroll index after selecting MIAM option
  const miamScrollIndex = useRef<number>(0);
  // track last scroll index when meal combo is removed
  const removeMiamScrollIndex = useRef<number>(0);
  // track last known number of sections
  const prevCustomisableSectionLength = useRef<number>(
    customisableSectionData.length
  );
  // on focus of component, track if customisable sections has changed
  const hasSectionsListUpdated = useRef<boolean>(false);

  const initialExpandList = Object.keys(initialState).reduce(
    (previousValue, currentValue) => {
      return { ...previousValue, [currentValue]: true };
    },
    {}
  );
  // local state
  const [expandList, updateExpandList] =
    useState<ExpandListState>(initialExpandList);
  const [stickerVisible, setStickerVisible] = useState<boolean>(false);
  const [allergenText, setAllergenText] = useState<string>();

  // Reusable callback fn to scroll to next section
  const scrollToNext = useCallback(
    (scrollToIndex: number) => {
      // customisableSectionData
      if (scrollToIndex + 1 < customisableSectionData.length) {
        FlatListRef.current?.scrollToIndex({
          animated: true,
          index: scrollToIndex + 1,
          viewOffset: ProductConst.STICKY_HEADER_DISPLAY_VALUE,
        });
      }
    },
    [customisableSectionData.length]
  );

  const onFocus = useCallback(() => {
    // detects if sections list have been expanded/unexpanded
    hasSectionsListUpdated.current =
      prevCustomisableSectionLength.current !== customisableSectionData.length;
  }, [customisableSectionData.length]);

  useEffect(() => {
    onFocus();
  }, [isFocused, onFocus]);

  useEffect(() => {
    if (
      typeof errorIndex === "number" &&
      errorIndex < customisableSectionData.length
    ) {
      FlatListRef.current?.scrollToIndex({
        animated: true,
        index: errorIndex,
        viewOffset: ProductConst.STICKY_HEADER_DISPLAY_VALUE,
      });

      clearErrorIndex();
    }
  }, [errorIndex, clearErrorIndex, customisableSectionData.length]);

  /**
   * Updates allergen information for hot drinks
   */
  useEffect(() => {
    if (category?.templateId === ProductConst.TemplateId.HotDrinks) {
      setAllergenText(t("OrderStatus:hotDrinkAllergenLabel"));
    }
  }, [category?.templateId, t]);

  /**
   * Brings back meal selection.
   * Only if selected meal id matches temporary main cart item or there is no meal selection.
   */
  useEffect(() => {
    if (
      MIAMCartItem &&
      (selectedMealId === MIAMCartItem.productId || !selectedMealId)
    ) {
      updateSelectedMealId(MIAMCartItem.productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MIAMCartItem, customisableSectionData, commonSection?.title]);

  const handleReset = useCallback(() => {
    customizeOptions.current = initialState;
    isShowMoreOrLessModifiers.current = true;
    handleRemoveMeal();
  }, [customizeOptions, handleRemoveMeal, initialState]);

  /**
   * Updates MIAM product's cart item in redux with remove modifier.
   * Assums that remove modi applies for sides and sides are first section in MIAM.
   * @param removeModifierId
   * @param value toggle value
   * @param miamRemoveIndex section index
   */
  const updateMIAMItemWithRemoveModifier = useCallback(
    (
      removeModifierId: number | undefined,
      value: boolean,
      miamRemoveIndex: number
    ) => {
      const multipart = getMealMultipart();
      const section =
        multipart?.multiPartSection && multipart?.multiPartSection[0]?.name;

      if (section && handleMIAMsimpleOptionSelected) {
        let selectedProductId;
        Object.entries(mealCustomizeOptions).forEach(
          ([mealCustomizeKey, mealCustomizeItem]) => {
            if (mealCustomizeKey === section) {
              selectedProductId = mealCustomizeItem;
            }
          }
        );

        // condition uses reverted value because remove modi is reverted toggle
        const removeModiArray =
          !value && removeModifierId ? [removeModifierId] : undefined;

        if (selectedProductId && multipart?.multiPartSection) {
          const newSelectedProductCartItem:
            | CartReduxModels.CartItem
            | undefined = ProductUtils.createCartItem(
            multipart?.multiPartSection[0] as unknown as MenuModels.Category,
            selectedProductId,
            1,
            true,
            removeModiArray
          );

          if (newSelectedProductCartItem) {
            handleMIAMsimpleOptionSelected(
              newSelectedProductCartItem,
              miamRemoveIndex
            );
          }
        }
      }
    },
    [getMealMultipart, handleMIAMsimpleOptionSelected, mealCustomizeOptions]
  );

  /**
   * Updates RemoveModifier toggle state
   * `!values` because of reverse logic, should apply RemoveModifier when toggle OFF
   * @param id
   * @param value
   * @param removeModifierId
   */
  const handleUpdateApplyRemoveModifier = useCallback(
    (
      id: string,
      value: boolean,
      removeModifierIndex: number,
      removeModifierId?: number
    ) => {
      const mealFirstOptionSectionName = Object.keys(mealCustomizeOptions)[0];

      // applies for MIAM's Sides
      if (id === mealFirstOptionSectionName) {
        updateMIAMItemWithRemoveModifier(
          removeModifierId,
          value,
          removeModifierIndex
        );
        // applies for reverted toggle modifier in customisable product (cheese fries)
      } else {
        if (!value) {
          customizeOptions.current = {
            ...customizeOptions.current,
            [id]: [...customizeOptions.current[id], removeModifierId as number],
          };
        } else {
          const modiIndex = Object.values(
            customizeOptions.current[id]
          ).findIndex((modi) => modi === removeModifierId);
          const newRemoveModifiersIdList = [...customizeOptions.current[id]];
          newRemoveModifiersIdList.splice(modiIndex, 1);
          customizeOptions.current = {
            ...customizeOptions.current,
            [id]: newRemoveModifiersIdList,
          };
        }
      }
    },
    [customizeOptions, mealCustomizeOptions, updateMIAMItemWithRemoveModifier]
  );

  /**
   * Navigates to template for customisable MIAM option
   * @param miamCategory
   * @param miamIndex
   */
  const onMIAMCustomisableOptionSelected = useCallback(
    (
      miamCategory: MenuModels.Category,
      cartItems: CartReduxModels.CartItem[] | undefined,
      miamIndex: number
    ) => {
      if (Platform.OS == "web") {
        if (handleMIAMcustomisableOptionSelected) {
          handleMIAMcustomisableOptionSelected(
            miamCategory,
            cartItems,
            miamIndex
          );
        }
      } else {
        const MIAMcartItem = MIAMItem ? MIAMItem[miamIndex] : undefined;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        openTemplate &&
          openTemplate(
            {
              category: miamCategory,
              editMode: ProductConst.EditMode.UPDATE_MIAM_ITEM,
              pushScreen: true,
              cartItem: MIAMcartItem,
              index: miamIndex,
              fromMIAM: true,
            },
            cartItems
          );
      }
    },
    [MIAMItem, openTemplate, handleMIAMcustomisableOptionSelected]
  );

  /**
   * Updates meal object with selected options on user's click.
   * Navigates to customisable meal option template when applicable.
   * @param id parent section id
   * @param selectedId selected option id
   * @param mealCustomiseIndex parent section index
   */
  const handleSetMealCustomizeOptions = useCallback(
    (
      id: string,
      selectedId: number,
      mealCustomiseIndex: number,
      selectedCategory?: MenuModels.Category
    ) => {
      if (selectedCategory) {
        const mealCustomizeOptionsLength =
          Object.keys(mealCustomizeOptions).length;
        const newTemporaryCartItems = ProductUtils.getCartItem(
          customizeOptions.current,
          category,
          quantity,
          getMealMultipart(),
          mealCustomizeOptions,
          mealRemoveModifierOptions,
          mealCustomizeOptionsLength,
          MIAMItem
        );
        // saves main product cart item and meal cart item in container's local state,
        // to bring it back when returning from MIAM option customisation template
        onMIAMCustomisableOptionSelected(
          selectedCategory,
          newTemporaryCartItems,
          mealCustomiseIndex
        );
      } else {
        const optionCartItem = ProductUtils.getSelectedMiamOption(
          id,
          selectedId,
          getMealMultipart(),
          preSelectedMiamParts
        );
        // saves MIAM selection to the redux state
        if (optionCartItem && handleMIAMsimpleOptionSelected) {
          handleMIAMsimpleOptionSelected(optionCartItem, mealCustomiseIndex);
        }
      }
    },
    [
      MIAMItem,
      category,
      customizeOptions,
      getMealMultipart,
      handleMIAMsimpleOptionSelected,
      mealCustomizeOptions,
      mealRemoveModifierOptions,
      onMIAMCustomisableOptionSelected,
      quantity,
      preSelectedMiamParts,
    ]
  );

  const handleCustomizeOptionsUpdate = useCallback(
    (
      item: ProductModuleModel.CustomizeOptionsDataProps,
      ids: (number | string)[],
      customiseOptIndex: number
    ) => {
      customizeOptions.current = {
        ...customizeOptions.current,
        [item.id]: ids,
      };

      // note we force update UI only on customize options list being updated
      // reason being too many useEffects affect the state customizeOptions
      // and has now changed to a ref instead
      onForceUpdate();

      const listLength = FlatListRef.current?.props?.data?.length;

      // Scroll to next only when user click on Single Choice
      if (
        listLength &&
        listLength > customiseOptIndex + 1 &&
        !item.isMultiple
      ) {
        scrollToNext(customiseOptIndex);
      }
    },
    [FlatListRef, customizeOptions, onForceUpdate, scrollToNext]
  );

  const handleUpdateExpandList = useCallback(
    (key: string, expand: boolean) => {
      const nextState = { [key]: !expand };
      updateExpandList({ ...expandList, ...nextState });
    },
    [expandList]
  );

  // NOTE: We leverage this Flatlist subscription function primarily because
  // scrolling cannot happen until after FlatList re-renders with the updated list
  // and this is the only trigger we know for when new sections will be rendered.
  // ie: putting this logic in useFocusEffect/useCallback will not work
  const handleOnContentSizeChange = useCallback(() => {
    //Only scrollable when the previous number of sections and current don't match
    if (hasSectionsListUpdated.current) {
      // miam option has been selected
      if (
        miamScrollIndex.current > 0 &&
        miamScrollIndex.current < customisableSectionData.length
      ) {
        // note timeout needed as the MIAM section takes a while to render?
        setTimeout(() => {
          scrollToNext(miamScrollIndex.current);
          removeMiamScrollIndex.current = miamScrollIndex.current - 1;
          miamScrollIndex.current = 0;
        }, 100);
        // new section is available and size change not due to show more/less modifiers
      } else if (!isShowMoreOrLessModifiers.current) {
        isShowMoreOrLessModifiers.current = false;
        const indexValue = prevCustomisableSectionLength.current - 1;
        //disable autoscroll to first section when screen is shown
        if (indexValue !== -1) {
          setTimeout(() => {
            scrollToNext(indexValue);
          }, 100);
        }
      }

      // reset flag that indicates show more or less modifiers was called
      prevCustomisableSectionLength.current = customisableSectionData.length;
      // reset flag that indicates section lists has updated
      hasSectionsListUpdated.current = false;
    }
  }, [customisableSectionData.length, scrollToNext]);

  const handleToggleShowAll = () => {
    isShowMoreOrLessModifiers.current = true;
  };

  // render sticky header
  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const visible = handleProductScroll(
        event,
        stickerVisible,
        setStickerVisible,
        fadeAnimationProductDetail,
        fadeAnimationSticky
      );
      if (visible !== undefined) {
        setStickyHeaderVisible(visible);
      }
    },
    [
      fadeAnimationProductDetail,
      fadeAnimationSticky,
      setStickyHeaderVisible,
      stickerVisible,
    ]
  );

  // conditionally render section view
  const renderItem = useCallback(
    ({ item, index: renderItemIndex }: RenderOptionsItemProps) => {
      if (item.commonSection) {
        return (
          <MakeItAMeal
            itemOnlyFromPrice={customizableProductInfoProps.price}
            id={item.id}
            error={requiredFieldsError[item.id] === true}
            expand={expandList[item.id] !== false}
            handleUpdateExpandList={handleUpdateExpandList}
            selectedMealId={selectedMealId}
            updateSelectedMealId={updateSelectedMealId}
            commonSection={item.commonSection}
            categoryImageTopDownUrl={category.imageTopDownUrl}
            identifierSectionTitle={item.title}
            categoryImageAngleUrl={category.imageAngleUrl}
          />
        );
      } else if (item.multipartSection) {
        return (
          <MakeItAMealSection
            id={item.id}
            preSelectedMiamParts={preSelectedMiamParts}
            error={requiredFieldsError[item.id] === true}
            expand={expandList[item.id] !== false}
            handleUpdateExpandList={handleUpdateExpandList}
            updateApplyRemoveModifier={(value, removeModifierId) => {
              handleUpdateApplyRemoveModifier(
                item.id,
                value,
                renderItemIndex,
                removeModifierId
              );
            }}
            applyRemoveModifier={!mealRemoveModifierOptions[item.id]}
            selectedId={mealCustomizeOptions[item.id]}
            updateSelectedId={(selectedId) => {
              handleSetMealCustomizeOptions(
                item.id,
                selectedId,
                renderItemIndex
              );
            }}
            multipartSection={item.multipartSection}
            onCustomisableOptionSelection={(selectedCategory) => {
              handleSetMealCustomizeOptions(
                item.id,
                selectedCategory.id,
                renderItemIndex,
                selectedCategory
              );
            }}
          />
        );
      } else if (item.combinedData) {
        return (
          <ProductCombinedModifier
            key={item.id}
            handleCustomizeOptionsUpdate={handleCustomizeOptionsUpdate}
            index={renderItemIndex}
            item={item}
            selectedId={customizeOptions.current[item.id] || []}
          />
        );
      } else if (item.data) {
        return (
          <ProductModifier
            error={requiredFieldsError[item.id] === true}
            key={item.id}
            footerTitle={item.footerTitle}
            expand={expandList[item.id] !== false}
            handleUpdateExpandList={handleUpdateExpandList}
            handleCustomizeOptionsUpdate={handleCustomizeOptionsUpdate}
            handleToggleShowAll={handleToggleShowAll}
            updateApplyRemoveModifier={(value, removeModifierId) => {
              handleUpdateApplyRemoveModifier(
                ProductConst.SectionTitle.RemoveModifier,
                value,
                renderItemIndex,
                removeModifierId
              );
            }}
            index={renderItemIndex}
            item={item}
            customisationState={customizeOptions.current}
            selectedId={customizeOptions.current[item.id] || []}
          />
        );
      } else {
        return (
          <View style={styles.titleSection} key={item.id}>
            <Text style={Typography.titleTwo}>{item.title}</Text>
          </View>
        );
      }
    },
    [
      category,
      customizeOptions,
      customizableProductInfoProps.price,
      expandList,
      handleCustomizeOptionsUpdate,
      handleSetMealCustomizeOptions,
      handleUpdateApplyRemoveModifier,
      handleUpdateExpandList,
      mealCustomizeOptions,
      mealRemoveModifierOptions,
      updateSelectedMealId,
      selectedMealId,
      requiredFieldsError,
      preSelectedMiamParts,
    ]
  );

  const renderListHeaderComponent = useMemo(() => {
    return (
      <CustomizableProductListHeader
        customizableProductInfoProps={customizableProductInfoProps}
        fadeAnimationProductDetail={
          !isWebDesktop ? fadeAnimationProductDetail : undefined
        }
        onReset={handleReset}
        customizeOptions={customizeOptions.current}
        category={category}
        editMode={editMode}
        setQuantity={setQuantity}
        quantityDisabled={quantityDisabled}
        goBack={goBack}
        allergenText={allergenText}
        cartItems={cartItems}
        coffeeLoyalty={coffeeLoyalty}
      />
    );
  }, [
    allergenText,
    cartItems,
    category,
    customizableProductInfoProps,
    customizeOptions,
    editMode,
    fadeAnimationProductDetail,
    goBack,
    handleReset,
    isWebDesktop,
    setQuantity,
    quantityDisabled,
    coffeeLoyalty,
  ]);

  return (
    <View style={[containerStyles(isDesktopScreen).container]}>
      {Platform.OS === "web" && isDesktopScreen && (
        <View style={styles.header}>{renderListHeaderComponent}</View>
      )}
      <View style={[containerStyles(isDesktopScreen).listContainer]}>
        <FlatList<ProductModuleModel.CustomizeOptionsDataProps>
          onContentSizeChange={handleOnContentSizeChange}
          key={category.name}
          onScrollToIndexFailed={onScrollToIndexFailed}
          ref={FlatListRef}
          nestedScrollEnabled={false}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          // render header only once, alternative is to useMemo
          // but it seems that any wrapping of useCallback/useMemo
          // for renderItem is discouraged, unsure for ListHeaderComponent
          ListHeaderComponent={
            !isWebDesktop ? renderListHeaderComponent : undefined
          }
          data={customisableSectionData}
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "CustomizeProductScrollView"
          )}
          scrollEventThrottle={50}
          onScroll={!isWebDesktop ? handleScroll : undefined}
        />
      </View>
    </View>
  );
};

export default CustomizableProductList;
