import React from "react";
import { ImageSourcePropType, Platform, Text, View } from "react-native";

import defaultImage from "../../../components/assets/images/no_image_available_menu.png";
import MenuImage from "../../../components/Menu/MenuImage";
import { Typography } from "../../../components/styles/typography";
import * as PlatformUtils from "../../../modules/platformUtils";
import * as ProductModuleModel from "../../../modules/Products/model";
import { isSmallerDevice } from "../../../utils/device";
import { styles } from "./MakeMealOption.styles";

export interface MakeMealOptionProps {
  item: ProductModuleModel.OptionDataItemProps;
}

const MakeMealOption: React.FC<MakeMealOptionProps> = ({ item }) => {
  //Check if device is smaller width and move price to the next line so main title displays in 1 line
  const priceText = () =>
    `From${isSmallerDevice() && !item.isItemOnly ? "\n" : " "}${
      item.isItemOnly ? " " : "+"
    }$${item.price.toFixed(2)}`;

  return (
    <View
      {...PlatformUtils.generateTestID(
        Platform.OS,
        `MakeItAMeal-Section-List-${item.id}`
      )}
      key={item.id}
      style={styles.optionView}>
      {!item.withoutImage && (
        <MenuImage
          style={styles.optionIcon}
          source={
            item.imageTopDownUrl
              ? (item.imageTopDownUrl as ImageSourcePropType)
              : defaultImage
          }
        />
      )}
      <View style={styles.textGroup}>
        <Text style={Typography.titleThree}>{item.name}</Text>
        {!!item.description && (
          <Text style={Typography.captionOne}>{item.description}</Text>
        )}
        {!!item.nutritionalInfo && (
          <Text style={Typography.captionOne}>{item.nutritionalInfo}</Text>
        )}
      </View>
      {!!item.price && (
        <Text numberOfLines={2} style={styles.price}>
          {priceText()}
        </Text>
      )}
    </View>
  );
};

export default MakeMealOption;
