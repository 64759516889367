import { Platform, StyleSheet } from "react-native";

import colours from "../styles/colours";
import { BorderRadius, IconSize, Spacing } from "../styles/number";

export const styles = StyleSheet.create({
  bottom: {
    flex: 1,
  },
  split: {
    flex: 1,
  },
  title: {
    flex: 0.95,
  },
  logo: {
    alignSelf: "center",
    width: 51,
    height: IconSize.Small,
    resizeMode: "cover",
    marginTop: 10,
  },
  container: {
    minHeight: 168,
    padding: Spacing.Light,
    paddingBottom: Spacing.Thin,
    borderRadius: Spacing.Thin,
    backgroundColor: colours.yellow,
  },
  priceView: {
    width: 95,
    marginRight: Spacing.Thin,
  },
  quantityView: {
    paddingVertical: Spacing.Thin,
    paddingRight: Spacing.Thin,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  itemText: {
    fontSize: 16,
    fontFamily: "Sini-BoldItalic",
    lineHeight: 20,
    letterSpacing: 0.5,
    marginTop: Spacing.ExtraLight,
  },
  expandButton: {
    marginTop: Spacing.ExtraLight,
    marginBottom: Spacing.ExtraLight,
    width: 60,
  },
  icon: {
    width: IconSize.Small,
    height: IconSize.Small,
    marginLeft: Spacing.ExtraThin,
    resizeMode: "cover",
  },
  cartIcons: {
    width: IconSize.Medium,
    height: IconSize.Medium,
    marginLeft: Spacing.Light,
  },
  miam: {
    marginTop: Spacing.ExtraThin,
    marginBottom: Spacing.Thin,
  },
  subContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: Spacing.Thin,
  },
  iconsContainer: {
    flexDirection: "row",
    alignSelf: "flex-start",
    marginRight: -Spacing.Light,
  },
  favouriteButton: {
    justifyContent: "center",
    alignItems: "center",
  },
  favouriteButtonContainer: {
    flexDirection: "row",
  },
});

const cartItemMinimumHeight = 168;
const cartItemMinItemWidth = 300;

export const containerStyles = (roundedBottomBorder: boolean) =>
  StyleSheet.create({
    container: {
      padding: Spacing.Light,
      paddingBottom: Spacing.Thin,
      backgroundColor: colours.yellow,
      minHeight: cartItemMinimumHeight,
      minWidth: cartItemMinItemWidth,
      ...Platform.select({
        web: {
          borderTopLeftRadius: BorderRadius.Medium,
          borderTopRightRadius: BorderRadius.Medium,
          borderBottomLeftRadius: roundedBottomBorder ? BorderRadius.Medium : 0,
          borderBottomRightRadius: roundedBottomBorder
            ? BorderRadius.Medium
            : 0,
        },
      }),
    },
  });

export const iconStyles = (disabled?: boolean) =>
  StyleSheet.create({
    cartIcon: {
      ...styles.cartIcons,
      tintColor: disabled === true ? colours.uiGrey : undefined,
    },
  });

export const editIconStyles = (invalid?: boolean) =>
  StyleSheet.create({
    editIcon: {
      ...styles.cartIcons,
      tintColor: invalid === true ? colours.red : colours.black,
    },
  });
