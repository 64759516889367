import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsAction,
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  RootState,
  StringValidators,
  UserReduxAction,
} from "gyg_common";
import { UpdateEmail } from "views/components/UserManagement/UpdateEmail";

import { EditModeType } from "./UserProfileContainer";

export interface UpdateEmailContainerProps {
  emailFromState: string;
  onEditMode: EditModeType | undefined;
  setEditMode: (type: EditModeType | undefined) => void;
  hideEdit: boolean;
}

export const UpdateEmailContainer: React.FC<UpdateEmailContainerProps> = ({
  emailFromState,
  onEditMode,
  setEditMode,
  hideEdit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { updateUserAccountState } = useSelector((s: RootState) => s.analytics);
  const { isEmailUpdated, profile } = useSelector(
    (state: RootState) => state.user
  );

  const [emailFormatError, setEmailError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(emailFromState);

  const [emailVerificationSent, setEmailVerificationSent] =
    useState<boolean>(false);

  const isOnEditMode = onEditMode === EditModeType.EMAIL;

  const checkValidField = useCallback((): boolean => {
    setEmailError(StringValidators.validateEmail(email));
    if (email === "" || StringValidators.validateEmail(email)) {
      return false;
    } else {
      return true;
    }
  }, [email]);

  //Cancel button click event
  const onCancel = () => {
    setEmail(emailFromState);
    setEmailError(false);
    setEditMode(undefined);
  };

  //Save button click event
  const onSaveEmail = () => {
    if (checkValidField()) {
      dispatch(UserReduxAction.updateUserEmail({ email: email }));
    }
  };

  //Edit button click event
  const onEditEmail = () => {
    setEditMode(EditModeType.EMAIL);
  };

  const emailHandler = (text: string) => {
    setEmail(text);
    setEmailError(false);
  };

  //Verify email address click event
  const onVerifyEmailAddressPress = useCallback(() => {
    setEmailVerificationSent(true);
    dispatch(UserReduxAction.verifyEmailAddress());
  }, [dispatch]);

  /**
   * Send tracking event to tealium
   * then clears the state in analytics
   */
  const trackUpdateUserToAnalytics = useCallback(() => {
    if (updateUserAccountState) {
      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.UpdateDetails,
        AnalyticsPayloadGenerator.updateUserAccountPayload(
          updateUserAccountState
        )
      );
    }

    dispatch(AnalyticsAction.setUpdateUserAccountToState(null));
  }, [dispatch, updateUserAccountState]);

  useEffect(() => {
    if (isEmailUpdated) {
      if (updateUserAccountState) {
        trackUpdateUserToAnalytics();
      }

      setEditMode(undefined);
      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:updateEmailToast"),
          showCloseButton: true,
          iconType: "alert",
        })
      );

      //Resets isEmailUpdated flag
      dispatch(UserReduxAction.updateUserEmailSuccess(null));
      dispatch(UserReduxAction.getUserProfile());
    }
  }, [
    t,
    dispatch,
    setEditMode,
    trackUpdateUserToAnalytics,
    isEmailUpdated,
    updateUserAccountState,
  ]);

  useEffect(() => {
    const componentWillUnmount = () => {
      dispatch(UserReduxAction.setMessageToast(null));
    };

    return componentWillUnmount;
  }, [dispatch]);

  return (
    <UpdateEmail
      hideEdit={hideEdit}
      email={email}
      emailFromState={emailFromState}
      onEditMode={isOnEditMode}
      emailFormatError={emailFormatError}
      disableEditButton={!!onEditMode}
      emailHandler={emailHandler}
      onCancel={onCancel}
      onSaveEmail={onSaveEmail}
      onEditEmail={onEditEmail}
      emailVerificationSent={emailVerificationSent}
      isEmailVerified={profile?.verifiedEmail ?? false}
      onVerifyEmailAddressClick={onVerifyEmailAddressPress}
    />
  );
};
