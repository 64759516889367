import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";

import errorImage from "../../components/assets/images/illustration_error.png";
import ModalWithButton from "../../components/modals/ModalWithButton";

export interface RecentOrderAbandonModalProps {
  isVisible: boolean;
  setAbandonModalVisible: (isVisible: boolean) => void;
  abandonOrder: () => void;
  keepCart: () => void;
}

export const RecentOrderAbandonModal: React.FC<
  RecentOrderAbandonModalProps
> = ({ isVisible, setAbandonModalVisible, abandonOrder, keepCart }) => {
  const { t } = useTranslation();

  return (
    <ModalWithButton
      isVisible={isVisible}
      title={t("CheckoutPayment:abandonTitle")}
      message={t("CheckoutPayment:abandonLabel")}
      primaryButton={{
        name: t("CheckoutPayment:abandonDelButton").toUpperCase(),
        action: abandonOrder,
      }}
      secondaryButton={{
        name: t("CheckoutPayment:continueButton"),
        action: keepCart,
      }}
      onModalClose={() => setAbandonModalVisible(false)}
      image={errorImage as ImageSourcePropType}
    />
  );
};
