import "./style.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  ErrorReduxModel,
  RegistrationUtils,
  StringValidators,
} from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { WebModalSize } from "gyg_common/components/modals/MiddleModal";
import ModalWithInput from "gyg_common/components/modals/ModalWithInput";
import { EditModeType } from "views/containers/UserManagement/UserProfileContainer";

import FormInputWithButton from "../shared/FormInput/FormInputWithButton";

export interface UpdateMobileNumberProps {
  mobileNumberFromState: string;
  isUserLoading: boolean;
  isMobileVerified: boolean;
  errorOTPMessage: string;
  errorMessage: ErrorReduxModel.ErrorResponse | null;
  onEditMode: EditModeType | undefined;
  onVerifyPress: (mobileNumber: string) => void;
  setEditMode: (type: EditModeType | undefined) => void;
  onVerifyOTPCode: (mobileNumber: string, verificationCode: string) => void;
  resetMobileVerificationError: () => void;
}

export const UpdateMobileNumber: React.FC<UpdateMobileNumberProps> = ({
  mobileNumberFromState,
  isUserLoading,
  isMobileVerified,
  errorOTPMessage,
  onEditMode,
  errorMessage,
  onVerifyPress,
  setEditMode,
  onVerifyOTPCode,
  resetMobileVerificationError,
}) => {
  const { t } = useTranslation();
  const [mobileFormatError, isMobileFormatError] = useState<boolean>(false);
  const [verificationCodeError, setVerificationCodeError] = useState<string>(
    errorOTPMessage ? errorOTPMessage : ""
  );
  const existingMobileNumber = mobileNumberFromState;

  const [mobileNumber, setMobileNumber] =
    useState<string>(existingMobileNumber);
  const [verificationCode, setVerificationCode] = useState<string>("");

  const [isOTPRequested, setIsOTPRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isOnEditMode = onEditMode === EditModeType.MOBILE;

  const mobileNumberHandler = (text: string) => {
    setMobileNumber(text);
    isMobileFormatError(false);
  };

  const verificationCodeHandler = (text: string) => {
    setVerificationCode(text);
    setVerificationCodeError("");
  };

  const isMobileNumberValid = (): boolean => {
    if (
      mobileNumber === "" ||
      (mobileNumber === mobileNumberFromState && isMobileVerified) ||
      !StringValidators.validatePhone(mobileNumber)?.isValid
    ) {
      return false;
    } else {
      return true;
    }
  };

  //Mobile number validation and error
  const checkValidField = (): boolean => {
    isMobileFormatError(!StringValidators.validatePhone(mobileNumber)?.isValid);
    return isMobileNumberValid();
  };

  //Mobile number request OTP. Verify Button
  const onVerify = () => {
    if (checkValidField()) {
      onVerifyPress(
        RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        )
      );
      setIsOTPRequested(true);
      setVerificationCodeError("");
    }
  };

  const onConfirmClick = () => {
    if (verificationCode !== "") {
      onVerifyOTPCode(
        RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        ),
        verificationCode
      );
      setIsLoading(true);
    } else {
      setVerificationCodeError(t("CheckoutPayment:mandatoryField"));
    }
  };

  /**
   * Cancels edit mode and clean up
   */
  const onCancel = () => {
    setEditMode(undefined);
    setIsOTPRequested(false);
    setMobileNumber(existingMobileNumber);
    isMobileFormatError(false);
    setVerificationCode("");
    resetMobileVerificationError();
    setEditMode(undefined);
    setVerificationCodeError("");
  };

  /**
   * Enters edit mode
   */
  const onMobileNoEdit = () => {
    setEditMode(EditModeType.MOBILE);
    setIsOTPRequested(false);
  };

  useEffect(() => {
    setVerificationCodeError(errorOTPMessage);
    setIsLoading(false);
  }, [errorOTPMessage]);

  /**
   * Reset modes when mobile is verified
   */
  useEffect(() => {
    if (isMobileVerified) {
      setEditMode(undefined);
      setIsOTPRequested(false);
    }
  }, [isMobileVerified, setEditMode]);

  useEffect(() => {
    if (isOTPRequested) {
      AnalyticsInstance.trackView({
        page_name: "validate_mobile",
        page_type: AnalyticsConst.Events.ValidateMobile,
      });
    }
  }, [isOTPRequested]);

  const mobileLabelWithCancelButton = () => {
    return (
      <div className='user-profile-main__titlewrapper'>
        <div className='user-profile-main__label'>
          <p className='input-label__bold'>{t("UserManagement:mobileLabel")}</p>
        </div>

        <div className='user-profile-main__cancel'>
          <div>
            <a className='input-label__cancel' onClick={onCancel}>
              {t("UserManagement:cancelLabel")}
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='space-between-rows '>
      <form style={{ display: "contents" }} autoComplete='on'>
        {!isOnEditMode && (
          <FormInputWithButton
            inputLabel={t("UserManagement:mobileLabel")}
            onChangeText={mobileNumberHandler}
            error={mobileFormatError}
            value={mobileNumber}
            isBorderBottomOnly={true}
            disabled={!!onEditMode}
            onEdit={onMobileNoEdit}
          />
        )}

        {isOnEditMode && (
          <div>
            {mobileLabelWithCancelButton()}

            <p className='input-label'>
              {t("UserManagement:mobileDescription")}
            </p>

            <div className='user-profile-main__wrapper'>
              <div className='user-profile-main__threequartercol'>
                <FormInputWithButton
                  inputLabel={t("UserManagement:mobileLabel")}
                  onChangeText={mobileNumberHandler}
                  error={mobileFormatError}
                  value={mobileNumber}
                  isBorderBottomOnly={false}
                  isShowLabel={false}
                  isLabelBold={true}
                  placeHolder={t("LoginRegistration:enterCodeLabel")}
                  errorMsg={t("LoginRegistration:phoneError")}
                />
              </div>

              <div className={"user-profile-main__onequartercol"}>
                <PrimaryBlackButton
                  testId={t("UserManagement:verify")}
                  disable={!isMobileNumberValid()}
                  border={false}
                  onClick={onVerify}
                  buttonName={t("UserManagement:verify")}
                />
              </div>
            </div>
          </div>
        )}

        <ModalWithInput
          verifyMobile
          webModalSize={WebModalSize.SMALL}
          isVisible={isOTPRequested && !errorMessage && !isUserLoading}
          testId={"EditPhoneNumberModal"}
          isInputError={verificationCodeError !== ""}
          inputErrorMessage={verificationCodeError}
          primaryButtonDisable={verificationCode.length === 0 || isLoading}
          primaryButton={{
            name: t("UserManagement:confirm"),
            action: onConfirmClick,
          }}
          title={t("LoginRegistration:verifyMobileTitle")}
          inputLabel={t("LoginRegistration:verifyCodeLabel")}
          inputPlaceholder={t("LoginRegistration:enterCodeLabel")}
          messageBold={`${t(
            "LoginRegistration:verifyMobileNote"
          )}\n${RegistrationUtils.getMobileNumberWithDialCode(
            mobileNumber,
            config.version
          )}`}
          message={t("LoginRegistration:verifyMobileLabel")}
          inputValue={verificationCode}
          onTextChange={verificationCodeHandler}
          onRequestCode={onVerify}
          onModalClose={onCancel}
        />
      </form>
    </div>
  );
};
