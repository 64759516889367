import "./style.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ErrorView from "gyg_common/components/Error/ErrorView";
import { Screens } from "navigation/const";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

const NotFoundComponent: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='not-found'>
      <HeaderContainer />
      <div className='main-wrapper'>
        <ErrorView
          heading={t("Dashboard:notFoundErrorTitle")}
          message={t("Dashboard:notFoundErrorCopy")}
          btnTitle={t("Dashboard:notFoundErrorCTA")}
          onTryAgain={() => navigate(Screens.Dashboard)}
        />
      </div>
    </div>
  );
};

export default NotFoundComponent;
