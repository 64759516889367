import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import SwiperCore, { Pagination } from "swiper";

import { configAnalytics } from "gyg_common/analytics";

import { Analytics } from "./analytics";
import App from "./App";

configAnalytics(new Analytics());

// install Swiper modules
SwiperCore.use([Pagination]);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
