import React from "react";
import { useTranslation } from "react-i18next";

import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";

import FormInputWithButton from "../shared/FormInput/FormInputWithButton";

export interface UpdateEmailProps {
  email: string;
  emailFromState: string;
  onEditMode: boolean;
  disableEditButton: boolean;
  emailVerificationSent: boolean;
  isEmailVerified: boolean;
  emailFormatError: boolean;
  emailHandler: (text: string) => void;
  onCancel: () => void;
  onEditEmail: () => void;
  onSaveEmail: () => void;
  hideEdit: boolean;
  onVerifyEmailAddressClick: () => void;
}

export const UpdateEmail: React.FC<UpdateEmailProps> = ({
  email,
  emailFromState,
  onEditMode,
  emailFormatError,
  disableEditButton,
  emailVerificationSent,
  isEmailVerified,
  emailHandler,
  onCancel,
  onEditEmail,
  onSaveEmail,
  hideEdit,
  onVerifyEmailAddressClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className='space-between-rows '>
      <form style={{ display: "contents" }} autoComplete='on'>
        {!onEditMode && (
          <FormInputWithButton
            inputLabel={t("UserManagement:emailLabel")}
            onChangeText={emailHandler}
            error={emailFormatError}
            value={email}
            isBorderBottomOnly={true}
            onEdit={onEditEmail}
            hideEdit={hideEdit}
            disabled={disableEditButton}
          />
        )}

        {onEditMode && (
          <div>
            <div className='user-profile-main__titlewrapper'>
              <div className='user-profile-main__label'>
                <p className='input-label'> {t("UserManagement:emailLabel")}</p>
              </div>

              <div className='user-profile-main__cancel'>
                <div>
                  <a className='input-label__cancel' onClick={onCancel}>
                    {t("UserManagement:cancelLabel")}
                  </a>
                </div>
              </div>
            </div>

            <div className='user-profile-main__wrapper'>
              <div className='user-profile-main__threequartercol'>
                <FormInputWithButton
                  inputLabel={t("UserManagement:emailLabel")}
                  onChangeText={emailHandler}
                  error={emailFormatError}
                  value={email}
                  isBorderBottomOnly={false}
                  onEdit={onEditEmail}
                  isShowLabel={false}
                  errorMsg={t("UserManagement:emailError")}
                />
              </div>

              <div className={"user-profile-main__onequartercol"}>
                <PrimaryBlackButton
                  testId={t("UserManagement:saveLabel")}
                  disable={email === "" || email === emailFromState}
                  border={false}
                  onClick={onSaveEmail}
                  buttonName={t("UserManagement:saveLabel")}
                />
              </div>
            </div>
          </div>
        )}

        {!onEditMode && !isEmailVerified && !emailVerificationSent && (
          <div className='user-profile-main__wrapper'>
            <div className='user-profile-main__onequartercol'>
              <PrimaryBlackButton
                disable={isEmailVerified}
                border={false}
                onClick={onVerifyEmailAddressClick}
                buttonName={t("UserManagement:verifyEmailAddressHeader")}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
