import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { store } from "redux_store/configureReduxStore";

import {
  AnalyticsConst,
  AnalyticsInstance,
  FavouriteModels,
  FavouriteModules,
  FavouriteReduxAction,
  RootState,
} from "gyg_common";
import { WebModalSize } from "gyg_common/components/modals/MiddleModal";
import ModalWithInput from "gyg_common/components/modals/ModalWithInput";
import { FavouritesSection } from "views/components/Dashboard/FavouritesSection";

export interface FavouriteContainerProps {
  withTitle?: boolean;
}

export const FavouriteContainer = (
  props: FavouriteContainerProps
): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { menuStructure } = useSelector((s: RootState) => s.menu);
  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );

  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const { favourites, favouriteCartItems } = useSelector(
    (s: RootState) => s.favourite
  );

  const [selectedFavourite, setSelectedFavourite] =
    useState<FavouriteModels.FavouriteCartItem>({
      label: "",
      favouriteId: 0,
      cartItem: { productId: 0, name: "", quantity: 0, unitPrice: 0, price: 0 },
    });

  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [renameError, setRenameError] = useState<string>("");

  useEffect(() => {
    if (isAuthSuccess) dispatch(FavouriteReduxAction.getFavourite());
  }, [isAuthSuccess, dispatch]);

  useEffect(() => {
    if (
      menuStructure &&
      favourites.length > 0 &&
      isAuthSuccess &&
      userConfirmedOrderSetup
    ) {
      FavouriteModules.updateFavouriteToState(
        store,
        menuStructure,
        isAuthSuccess,
        userConfirmedOrderSetup,
        favourites
      );
    }
  }, [
    menuStructure,
    favourites,
    isAuthSuccess,
    userConfirmedOrderSetup,
    favouriteCartItems.length,
  ]);

  const onRenameSavePress = () => {
    if (selectedFavourite && selectedFavourite.label.trim() !== "") {
      const cartItem = selectedFavourite.cartItem;

      dispatch(
        FavouriteReduxAction.renameFavourite(
          FavouriteModules.updateFavouritePayload(
            selectedFavourite.favouriteId,
            selectedFavourite.label,
            cartItem
          )
        )
      );
      setShowRenameModal(false);
    } else {
      setRenameError(t("Favourite:nameError"));
    }

    AnalyticsInstance.trackEvent(AnalyticsConst.Events.FavouriteRenamed, {
      custom_name: selectedFavourite.label,
    });
  };

  const onRenamePress = (favourite: FavouriteModels.FavouriteCartItem) => {
    setRenameError("");
    setSelectedFavourite(favourite);
    setShowRenameModal(true);
  };

  const onFavouriteLabelChange = (text: string) => {
    setSelectedFavourite({ ...selectedFavourite, label: text });
  };

  if (
    favourites.length > 0 &&
    favouriteCartItems.length > 0 &&
    userConfirmedOrderSetup
  ) {
    return (
      <>
        <ModalWithInput
          testId={t("Favourite:renameTitle")}
          webModalSize={WebModalSize.SMALL}
          isVisible={showRenameModal}
          primaryButton={{
            name: t("Favourite:saveButton"),
            action: onRenameSavePress,
          }}
          title={t("Favourite:renameTitle")}
          message={t("Favourite:renameBody")}
          inputLabel={t("Favourite:renameLabel")}
          inputValue={selectedFavourite.label}
          onTextChange={onFavouriteLabelChange}
          onModalClose={() => setShowRenameModal(false)}
          isInputError={!!renameError.length}
          inputErrorMessage={renameError}
        />

        <FavouritesSection
          onRenamePress={onRenamePress}
          withTitle={props.withTitle}
          favouriteCartItems={favouriteCartItems}
        />
      </>
    );
  } else {
    return <></>;
  }
};
