import "../shared/Modals/style.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { ListRenderItem, Modal } from "react-native";

import { MenuModels, ProductConst } from "gyg_common";
import ModalListOptions from "gyg_common/components/ModalListOptions";
import ProductTemplateSelector from "views/components/Products/ProductTemplateSelector";

export interface CombinedItemSelectionModalProps {
  isVisible: boolean;
  optionData: MenuModels.Category[];
  renderItem: ListRenderItem<MenuModels.Category | MenuModels.Product>;
  productType: ProductConst.ProductRoute;
  selectedCategory?: MenuModels.Category;
  commonSection?: MenuModels.CommonSection;
  onClose: () => void;
  onGoBack: () => void;
}

export default function CombinedItemSelectionModal(
  props: CombinedItemSelectionModalProps
): JSX.Element {
  const {
    onClose,
    commonSection,
    selectedCategory,
    productType,
    optionData,
    isVisible,
    renderItem,
    onGoBack,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal transparent={true} visible={isVisible}>
      <div className={"overlay"} id='product-modal-overlay'>
        <div id={"product-modal-wrapper"} className={"product-modal-wrapper"}>
          {selectedCategory ? (
            <ProductTemplateSelector
              commonSection={commonSection}
              onBack={onGoBack}
              editMode={ProductConst.EditMode.ADD_CART_ITEM}
              category={selectedCategory}
              onClose={onClose}
              productType={productType}
              enableGoBackMode
            />
          ) : (
            <div className='menu__combined-item'>
              <ModalListOptions
                title={t("OrderManagement:bundleMultipartSectionSelection")}
                optionData={optionData}
                renderItem={renderItem}
                onBack={onClose}
                enableCloseMode
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
