import referAFriendImage from "assets/images/refer_a_friend.jpeg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { LoyaltyModels, PlatformUtils } from "gyg_common";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import FormInput from "gyg_common/components/FormInput";
import MiddleModal, {
  WebModalSize,
} from "gyg_common/components/modals/MiddleModal";
import { RewardsModal } from "views/components/Rewards/RewardsModal/RewardsModal";

import { styles } from "./styles";

interface IReferToFriendModal {
  reward: LoyaltyModels.IRewards;
  referToFriendUrl: string;
  visible: boolean;
  mainButtonName?: string;
  onClose: () => void;
  openTermsAndConditions: () => void;
}

export const ReferToFriendModal: React.FC<IReferToFriendModal> = ({
  reward,
  visible,
  referToFriendUrl,
  mainButtonName,
  onClose,
  openTermsAndConditions,
}) => {
  const { t } = useTranslation();
  const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
    setShowCopyLinkModal(false);
    setLinkCopied(false);
  };

  const handleShareLink = () => {
    navigator.clipboard.writeText(referToFriendUrl);
    setLinkCopied(true);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleShareLink();
  };

  return (
    <>
      {showCopyLinkModal ? (
        <MiddleModal
          isVisible={showCopyLinkModal}
          webModalSize={WebModalSize.SMALL}
          testId='rewards-detail-modal'>
          <View style={styles.container}>
            <View style={styles.topContainer}>
              <Text style={styles.heading}>{t("Loyalty:referLinkTitle")}</Text>

              <TouchableWithoutFeedback onPress={() => handleClose()}>
                <Image
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "close-icon-modal"
                  )}
                  style={styles.icon}
                  source={closeIcon as ImageSourcePropType}
                />
              </TouchableWithoutFeedback>
            </View>
            <form
              style={{ display: "contents" }}
              autoComplete='on'
              onSubmit={handleSubmit}>
              <View style={styles.messageContainer}>
                <View style={styles.textInput}>
                  <FormInput
                    name=''
                    isDisabled={true}
                    value={referToFriendUrl}
                  />
                </View>

                {navigator.clipboard && (
                  <View style={styles.buttonContainer}>
                    <View style={styles.primaryButton}>
                      <PrimaryBlackButton
                        testId='PrimaryModalWithInputButton'
                        buttonName={
                          linkCopied
                            ? t("Loyalty:linkCopiedBtn")
                            : t("Loyalty:copyLinkBtn")
                        }
                        onClick={() => handleShareLink()}
                      />
                    </View>
                  </View>
                )}
              </View>
            </form>
          </View>
        </MiddleModal>
      ) : (
        <RewardsModal
          {...reward}
          mainButtonName={mainButtonName ? mainButtonName : ""}
          assetImage={referAFriendImage as ImageSourcePropType}
          closeModal={onClose}
          rewardsDetailModalVisible={visible}
          onMainButtonPressed={() => setShowCopyLinkModal(true)}
          openTermsAndConditions={openTermsAndConditions}
        />
      )}
    </>
  );
};
