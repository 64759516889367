import React, { useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, Text, View } from "react-native";

import RadioButtonGroup from "../../Buttons/Radio/RadioButtonGroup";
import { RadioButtonModels } from "../../../modules/Components";
import { ModifierLookup } from "../../../redux_store/menu/models";
import { generateTestID } from "../../../modules/platformUtils";
import { getModifierFromCombinedIds } from "../../../modules/Products/utils";
import {
  OptionDataItemProps,
  OptionDataProps,
  CustomizeOptionsDataProps,
} from "../../../modules/Products/model";

import { Typography } from "../../styles/typography";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";

const styles = StyleSheet.create({
  titleSection: {
    flexDirection: "row",
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Regular,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    alignItems: "center",
  },
  removeSection: {
    backgroundColor: colours.white,
    paddingHorizontal: Spacing.Regular,
    paddingVertical: Spacing.Thin,
    borderRadius: Spacing.Thin,
  },
  container: {
    flex: 1,
    backgroundColor: colours.white,
  },
  split: {
    flex: 1,
  },
  optionView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: Spacing.ExtraThin,
  },
  optionIcon: {
    width: 76,
    height: 76,
    marginLeft: Spacing.Light,
    resizeMode: "cover",
  },
  textGroup: {
    padding: Spacing.Light,
  },
  wrapper: {
    borderBottomWidth: 1,
    borderColor: colours.lightGrey,
  },
  subSection: {
    padding: Spacing.Light,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: colours.lightGrey,
  },
});

const renderQuantityOptionView = (optionItem: OptionDataItemProps) => {
  return (
    <View
      key={optionItem.id + "CombinedQuantityItem"}
      style={styles.optionView}>
      <View style={styles.textGroup}>
        <Text style={Typography.titleThree}>{optionItem.name}</Text>
      </View>
      <View style={styles.split} />
    </View>
  );
};

const renderOptionView = (optionItem: OptionDataItemProps) => {
  return (
    <View key={optionItem.id + "CombinedTypeItem"} style={styles.optionView}>
      <View style={styles.textGroup}>
        <Text style={Typography.titleThree}>{optionItem.name}</Text>
      </View>
      <View style={styles.split} />
    </View>
  );
};

export interface ProductCombinedModifierProps {
  item: CustomizeOptionsDataProps;
  index: number;
  selectedId: (number | string)[];
  handleCustomizeOptionsUpdate: (
    item: CustomizeOptionsDataProps,
    id: (number | string)[],
    index: number
  ) => void;
}

const ProductCombinedModifier: React.FC<ProductCombinedModifierProps> = ({
  item,
  index,
  selectedId,
  handleCustomizeOptionsUpdate,
}) => {
  const [needUpdate, setNeedUpdate] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState<
    number | string | undefined
  >(undefined);
  const [selectedType, setSelectedType] = useState<number | string | undefined>(
    undefined
  );

  useEffect(() => {
    const modifier: ModifierLookup | undefined =
      item.combinedData?.modifierLookups?.find(
        (n: ModifierLookup) => n.id === selectedId[0]
      );
    modifier?.tags?.forEach((tagId) => {
      if (
        item.combinedData?.combinedModifierTypes?.find(
          (n: OptionDataProps) => n.element.id === tagId
        )
      ) {
        setSelectedType(tagId);
      }
      if (
        item.combinedData?.combinedModifierQuantity?.find(
          (n: OptionDataProps) => n.element.id === tagId
        )
      ) {
        setSelectedQuantity(tagId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId[0], item.combinedData]);

  useEffect(() => {
    if (needUpdate === false) return;

    if (item.combinedData?.modifierLookups) {
      const modifier = getModifierFromCombinedIds(
        item.combinedData.modifierLookups,
        selectedType,
        selectedQuantity
      );
      if (modifier) {
        handleCustomizeOptionsUpdate(item, [modifier.id], index);
      } else {
        handleCustomizeOptionsUpdate(item, [], index);
      }
    }
    setNeedUpdate(false);
  }, [
    selectedQuantity,
    selectedType,
    item,
    index,
    needUpdate,
    handleCustomizeOptionsUpdate,
  ]);

  const handleSelectQuantity = (id: number | string) => {
    setSelectedQuantity(id);
    setNeedUpdate(true);
    if (selectedType == undefined) {
      setSelectedType(
        item.combinedData?.combinedModifierTypes?.[0]?.element.id ?? undefined
      );
    }
  };

  const handleSelectType = (id: number | string) => {
    setSelectedType(id);
    setNeedUpdate(true);
    if (selectedQuantity == undefined) {
      setSelectedQuantity(
        item.combinedData?.combinedModifierQuantity?.[0]?.element.id ??
          undefined
      );
    }
  };

  const handleOnRemove = () => {
    setSelectedQuantity(undefined);
    setSelectedType(undefined);
    setNeedUpdate(true);
  };

  return (
    <View style={styles.container}>
      <View style={styles.titleSection}>
        <Text style={Typography.titleTwo}>{item.title}</Text>
        <View style={styles.split} />
        {selectedId[0] && (
          <Pressable
            {...generateTestID(
              Platform.OS,
              `ProductCombinedModifierRemoveButton`
            )}
            onPress={handleOnRemove}
            style={styles.removeSection}>
            <Text style={Typography.titleThree}>{`REMOVE`}</Text>
          </Pressable>
        )}
      </View>
      <View
        style={styles.wrapper}
        {...generateTestID(
          Platform.OS,
          `${item.title}CombiledRadioButtonGroup`
        )}>
        <View
          style={styles.container}
          key={item.title + "CombinedType"}
          {...generateTestID(
            Platform.OS,
            `${item.title}CombiledRadioButtonGroup-CombinedType`
          )}>
          <RadioButtonGroup
            key={item.id + index + "CombinedType"}
            showGradientLine={true}
            onItemSelection={handleSelectType}
            selectedId={selectedType}
            renderItem={renderOptionView}
            data={
              item.combinedData
                ?.combinedModifierTypes as RadioButtonModels.RadioButtonGroupData<OptionDataItemProps>[]
            }
          />
        </View>
        <View style={styles.subSection}>
          <Text style={Typography.titleTwo}>{`${item.title} QUANTITY`}</Text>
        </View>
        <View
          style={styles.container}
          key={item.title + "CombinedQuantity"}
          {...generateTestID(
            Platform.OS,
            `${item.title}CombiledRadioButtonGroup-CombinedQuantity`
          )}>
          <RadioButtonGroup
            key={item.id + index + "CombinedQuantity"}
            showGradientLine={true}
            onItemSelection={handleSelectQuantity}
            selectedId={selectedQuantity}
            renderItem={renderQuantityOptionView}
            data={
              item.combinedData
                ?.combinedModifierQuantity as RadioButtonModels.RadioButtonGroupData<OptionDataItemProps>[]
            }
          />
        </View>
      </View>
    </View>
  );
};

export default ProductCombinedModifier;
