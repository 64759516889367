import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllActionsOf } from "../utils/Actions";
import {
  AddCartItemPayload,
  CartItem,
  CartState,
  CartUpdateState,
  UpdateBundleItemPayload,
  UpdateCartItemPayload,
} from "./models";

const initialState: CartState = {
  items: [],
  bundleItem: {},
  tacoItem: {},
  MIAMItem: {},
  totalPrice: 0,
  cartItemSource: undefined,
};

// on updateCart -> totalPrice = action.payload.totalIncl, covers setting price in the btn based on total returned from order api response

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    updateCart(state, action: PayloadAction<CartUpdateState>) {
      state.items =
        action.payload.items.length > 0
          ? (state.items = action.payload.items.map(function (item) {
              return {
                ...item,
              };
            }))
          : action.payload.items;
      state.totalPrice = action.payload.totalPrice || 0;
    },
    addItemToCart(state, action: PayloadAction<AddCartItemPayload>) {
      const nextItemsState: CartItem[] = state.items;
      state.items = [...nextItemsState, ...action.payload.cartItems];
      state.cartItemSource = action.payload?.source;
    },
    updateCartItem(state, action: PayloadAction<UpdateCartItemPayload>) {
      const cartItemToUpdateIndex =
        action.payload.index === undefined
          ? state.items.findIndex(
              (cartItem) =>
                cartItem.productId === action.payload.cartItem.productId
            )
          : action.payload.index;
      const updatedCartItem = {
        ...action.payload.cartItem,
      };
      state.items[cartItemToUpdateIndex] = updatedCartItem;
    },
    deleteCartItem(state, action: PayloadAction<number>) {
      state.items.splice(action.payload, 1);
    },
    clearCart(state) {
      state.items = [];
      state.bundleItem = {};
      state.totalPrice = 0;
    },
    updateTotal(state, action: PayloadAction<number | undefined>) {
      if (action.payload) {
        state.totalPrice = action.payload;
      }
    },
    updateBundleItem(state, action: PayloadAction<UpdateBundleItemPayload>) {
      state.bundleItem = {
        ...state.bundleItem,
        [action.payload.index]: action.payload.cartItem ?? undefined,
      };
    },
    clearBundleItem(state) {
      state.bundleItem = {};
    },
    updateTacoItem(state, action: PayloadAction<UpdateBundleItemPayload>) {
      if (action.payload.cartItem) {
        state.tacoItem[action.payload.index] = action.payload.cartItem;
      } else {
        delete state.tacoItem[action.payload.index];
      }
    },
    clearTacoItem(state) {
      state.tacoItem = {};
    },
    updateMIAMItem(state, action: PayloadAction<UpdateBundleItemPayload>) {
      if (action.payload.cartItem) {
        state.MIAMItem[action.payload.index] = action.payload.cartItem;
      } else {
        delete state.MIAMItem[action.payload.index];
      }
    },
    clearMIAMItem(state) {
      state.MIAMItem = {};
    },
    clearCartItemSource(state) {
      state.cartItemSource = undefined;
    },
  },
});

const { actions, reducer } = cartSlice;
export type CartActionsType = AllActionsOf<typeof actions>;
export { actions as cartActions, reducer as cartReducer, initialState };
