import React from "react";
import { useTranslation } from "react-i18next";

import config from "config";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import CardSticker from "gyg_common/components/CardSticker";
import { CheckoutResponse } from "gyg_common/redux_store/checkout/model";
import OrderStatusReceipt from "views/components/OrderStatus/OrderStatusReceipt";

export interface IOrderDetails {
  orderDetails: CheckoutResponse;
  onReorderPress: () => void;
}

export const OrderDetails: React.FC<IOrderDetails> = ({
  orderDetails,
  onReorderPress,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='u-flex u-margin-bottom'>
        <CardSticker>
          <OrderStatusReceipt
            detailedRecieptMode
            storeName={orderDetails.store.name}
            locale={config.version}
            order={orderDetails.order}
            paymentTypes={orderDetails.payment}
            deliveryFee={orderDetails.order?.delivery?.fee ?? undefined}
          />
          <div className='u-padding'>
            <SecondaryButton
              buttonName={t("RecentOrders:reorderButton")}
              onClick={onReorderPress}
            />
          </div>
        </CardSticker>
      </div>
    </>
  );
};
