import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { CheckoutAction, OrderModules, RootState } from "../..";
import errorImage from "../../components/assets/images/illustration_error.png";
import ModalWithButton from "../../components/modals/ModalWithButton";
import { isUnexpectedError } from "../../hooks/useHandleOrderError";

export interface CheckoutErrorModalProps {
  onModalActionButtonPress: () => void;
}

export const CHECKOUT_MODAL_ERROR_DISPLAYED = "CheckoutErrorModal was shown:";

export const CheckoutErrorModal: React.FC<CheckoutErrorModalProps> = (
  props
) => {
  const { onModalActionButtonPress } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { error: checkoutError, hasUnexpectedError } = useSelector(
    (s: RootState) => s.checkout
  );
  const { getOrderError } = useSelector((s: RootState) => s.order);

  const isCheckoutInProgressError =
    OrderModules.OrderUtils.isCheckoutInProgressError(getOrderError);

  const isCheckoutModalVisible = useMemo(() => {
    return !!checkoutError || isCheckoutInProgressError;
  }, [checkoutError, isCheckoutInProgressError]);

  useEffect(() => {
    if (checkoutError) {
      dispatch(CheckoutAction.resetPayment());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = useCallback(() => {
    if (isUnexpectedError(hasUnexpectedError, checkoutError)) {
      return t("CheckoutPayment:unExpectedStatusTitle");
    } else if (isCheckoutInProgressError) {
      return getOrderError?.heading
        ? getOrderError.heading
        : t("CheckoutPayment:errorTitle");
    } else {
      return checkoutError?.heading
        ? checkoutError.heading
        : t("CheckoutPayment:errorTitle");
    }
  }, [
    checkoutError,
    getOrderError?.heading,
    hasUnexpectedError,
    isCheckoutInProgressError,
    t,
  ]);

  const message = useCallback(() => {
    if (isUnexpectedError(hasUnexpectedError, checkoutError)) {
      return t("CheckoutPayment:unExpectedStatusMessage");
    } else if (isCheckoutInProgressError) {
      return getOrderError?.message;
    } else {
      return checkoutError?.message;
    }
  }, [
    checkoutError,
    getOrderError?.message,
    hasUnexpectedError,
    isCheckoutInProgressError,
    t,
  ]);

  const onCloseButtonTap = () => {
    onModalActionButtonPress();
  };

  // /**
  //  * Sent checkout error to Sentry
  //  */
  // useEffect(() => {
  //   if (isCheckoutModalVisible) {
  //     SentryLoggerInstance.sentryCaptureCustomError(
  //       CHECKOUT_MODAL_ERROR_DISPLAYED,
  //       `ORDER_ID: ${getOrderResponse?.orderId} ` +
  //         title() +
  //         ` ${message()}` +
  //         ` statusCode: ${checkoutError?.statusCode}`
  //     );
  //   }
  // }, [checkoutError, isCheckoutModalVisible, getOrderResponse, title, message]);

  return (
    <ModalWithButton
      isVisible={isCheckoutModalVisible}
      image={errorImage as ImageSourcePropType}
      small
      title={title()}
      messageBold={message()}
      primaryButton={{
        name: isCheckoutInProgressError
          ? t("CheckoutPayment:tryAgainButton")
          : t("CheckoutPayment:okGotItButton"),
        action: onCloseButtonTap,
      }}
      onModalClose={onCloseButtonTap}
    />
  );
};
