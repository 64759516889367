import "./style.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { InputType, StringValidators } from "gyg_common";
import { LinkButton } from "gyg_common/components/Buttons/LinkButton";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import FormInput from "gyg_common/components/FormInput";

export interface LoginWithEmailProps {
  onLoginPress: (username: string, password: string) => void;
  onForgotPassword: () => void;
}

const LoginWithEmail: React.FC<LoginWithEmailProps> = ({
  onLoginPress,
  onForgotPassword,
}) => {
  const { t } = useTranslation();

  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const emailHandler = (text: string) => {
    setUsername(text);
    setUsernameError(false);
  };

  const passwordHandler = (text: string) => {
    setPassword(text);
    setPasswordError(false);
  };

  const forgetPassword = () => {
    return (
      <div className='forget-password'>
        <LinkButton
          testId={t("LoginRegistration:forgotPassword")}
          buttonName={t("LoginRegistration:forgotPassword")}
          onPress={onForgotPassword}
          boldText
        />
      </div>
    );
  };

  const checkField = () => {
    if (username === "" || password === "") {
      return false;
    } else {
      return true;
    }
  };

  const onLoginCheck = () => {
    setPasswordError(password === "");
    setUsernameError(StringValidators.validateEmail(username.toString()));
  };

  const onUserLogin = () => {
    onLoginPress(username, password);
  };

  const onSubmit =
    username === "" ||
    password === "" ||
    StringValidators.validateEmail(username)
      ? onLoginCheck
      : onUserLogin;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (checkField()) {
      onSubmit();
    }
  };

  return (
    <div className='main-login__container'>
      <form
        style={{ display: "contents" }}
        autoComplete='on'
        onSubmit={handleSubmit}>
        <FormInput
          name={t("LoginRegistration:loginEmailField")}
          testId={t("LoginRegistration:loginEmailField")}
          handleChangeText={emailHandler}
          error={usernameError}
          placeHolder={t("LoginRegistration:loginEmailPlaceholder")}
          errorMsg={t("LoginRegistration:emailError")}
          type={InputType.EMAIL}
        />

        <FormInput
          name={t("LoginRegistration:loginPasswordField")}
          testId={t("LoginRegistration:loginPasswordField")}
          handleChangeText={passwordHandler}
          error={passwordError}
          placeHolder={t("LoginRegistration:loginPasswordPlaceholder")}
          type={InputType.PASSWORD}
          onSubmit={onSubmit}
        />

        <PrimaryBlackButton
          buttonName={t("LoginRegistration:capsLogin")}
          onClick={onSubmit}
          disable={!checkField()}
        />
      </form>
      {forgetPassword()}
    </div>
  );
};

export default LoginWithEmail;
