import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  CartReduxAction,
  LoyaltyActions,
  NavigationReduxAction,
  OrderReduxAction,
  RootState,
  StoreReduxModels,
  UpsellReduxActions,
  UpsellUtils,
} from "gyg_common";
import errorImage from "gyg_common/components/assets/images/illustration_error.png";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { Screens } from "navigation/const";
import { BarcodeModal } from "views/components/Barcode";
import Header from "views/components/shared/Header";

import { OrderUtils } from "@/../../packages/common/modules/Order";

export interface HeaderContainerProps {
  bottomComponentNotScrollable?: JSX.Element;
  bottomComponentScrollable?: JSX.Element;
  pageTitle?: string;
  viewCart?: boolean;
  showBarcode?: boolean;
}

const HeaderContainer: React.FC<HeaderContainerProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { items, totalPrice } = useSelector((state: RootState) => state.cart);
  const { validMenuSection, menuSectionEnabled } = useSelector(
    (state: RootState) => state.menu
  );
  const {
    userConfirmedOrderSetup,
    orderTime,
    orderInitialTime,
    getOrderLoading,
    updateOrderLoading,
  } = useSelector((state: RootState) => state.order);
  const { profile } = useSelector((state: RootState) => state.user);
  const { loyalty } = useSelector((state: RootState) => state.loyalty);
  const { locationPermissionGranted } = useSelector(
    (state: RootState) => state.user
  );
  const stores = useSelector((state: RootState) => state.store.stores);
  const selectedStore = useSelector(
    (state: RootState) => state.store.selectedStore
  );

  const analyticsState = useSelector((state: RootState) => state.analytics);
  const { isUserShownUpsell, upsellItemsFromConfigPreCart } = useSelector(
    (state: RootState) => state.upsell
  );

  const [isBarcodeVisible, setIsBarcodeVisible] = useState<boolean>(false);
  const [showAbandonOrder, setAbandonOrder] = useState<boolean>(false);

  const confirmedOrderSetup =
    selectedStore && userConfirmedOrderSetup && orderTime;

  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const hasCoffee = useFeatureIsOn(FeatureFlags.COFFEE_LOYALTY as string);

  /**
   * If cart item is more than one - Displays abandon order modal
   * If user has not confirmed order setup - Displays order setup modal
   * Otherwise, navigates to menu screen
   */
  const onMenuClicked = useCallback(() => {
    if (pathname === Screens.Dashboard && !userConfirmedOrderSetup) {
      dispatch(NavigationReduxAction.setOrderSetupNavigation(Screens.Menu));
      if (items.length > 0) {
        setAbandonOrder(true);
      } else {
        if (
          (selectedStore && !userConfirmedOrderSetup && !orderTime) ||
          (selectedStore && OrderUtils.shouldConfirmOrderTime(orderInitialTime))
        ) {
          dispatch(NavigationReduxAction.setOpenOrderSetup(true));
        } else if (selectedStore && userConfirmedOrderSetup && orderTime) {
          navigate(Screens.Menu);
        } else {
          if (hasDelivery) {
            dispatch(NavigationReduxAction.setOpenOrderSetup(true));
          } else {
            navigate(Screens.Restaurants, {
              state: {
                previousScreen: pathname,
              },
            });
          }
        }
      }
    } else {
      navigate(Screens.Menu);
    }
  }, [
    dispatch,
    navigate,
    items.length,
    orderInitialTime,
    orderTime,
    pathname,
    selectedStore,
    userConfirmedOrderSetup,
    hasDelivery,
  ]);

  const isUpsellItemExist = useCallback(() => {
    if (upsellItemsFromConfigPreCart?.length && validMenuSection) {
      const upsellItems = UpsellUtils.convertToMenuSection(
        upsellItemsFromConfigPreCart,
        validMenuSection,
        menuSectionEnabled
      );
      if (upsellItems.length > 0) {
        dispatch(UpsellReduxActions.setUpsellItems(upsellItems));
        dispatch(UpsellReduxActions.setUserShownUpsell(true));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [
    dispatch,
    menuSectionEnabled,
    upsellItemsFromConfigPreCart,
    validMenuSection,
  ]);

  const onOrderClicked = useCallback(() => {
    dispatch(
      NavigationReduxAction.setOrderSetupNavigation(
        confirmedOrderSetup ? Screens.Order : Screens.Menu
      )
    );
    if (confirmedOrderSetup) {
      if (
        pathname === Screens.Menu &&
        !isUserShownUpsell &&
        config.showUpsell &&
        isUpsellItemExist()
      ) {
        navigate(Screens.Upsell);
      } else {
        navigate(Screens.Order);
      }
    } else if (
      (selectedStore && !userConfirmedOrderSetup && !orderTime) ||
      (selectedStore && OrderUtils.shouldConfirmOrderTime(orderInitialTime))
    ) {
      dispatch(NavigationReduxAction.setOpenOrderSetup(true));
    } else {
      if (hasDelivery) {
        dispatch(NavigationReduxAction.setOpenOrderSetup(true));
      } else {
        navigate(Screens.Restaurants, {
          state: {
            previousScreen: pathname,
          },
        });
      }
    }
  }, [
    dispatch,
    confirmedOrderSetup,
    selectedStore,
    userConfirmedOrderSetup,
    orderTime,
    orderInitialTime,
    pathname,
    isUserShownUpsell,
    isUpsellItemExist,
    navigate,
    hasDelivery,
  ]);

  const keepCart = useCallback(() => {
    setAbandonOrder(false);
    if (userConfirmedOrderSetup) {
      if (OrderUtils.shouldConfirmOrderTime(orderInitialTime)) {
        dispatch(NavigationReduxAction.setOpenOrderSetup(true));
      } else {
        navigate(Screens.Menu);
      }
    } else {
      if (locationPermissionGranted) {
        dispatch(
          NavigationReduxAction.setNavigationState({
            showOrderSetup: true,
            orderSetupNavigation: Screens.Menu,
          })
        );
      } else {
        if (hasDelivery) {
          dispatch(NavigationReduxAction.setOpenOrderSetup(true));
        } else {
          navigate(Screens.Restaurants);
        }
      }
    }
  }, [
    dispatch,
    navigate,
    locationPermissionGranted,
    orderInitialTime,
    userConfirmedOrderSetup,
    hasDelivery,
  ]);

  const abandonOrder = useCallback(() => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.AbandonedCart,
      AnalyticsPayloadGenerator.abandonCartPayload(analyticsState)
    );

    dispatch(CartReduxAction.clearCart());
    dispatch(OrderReduxAction.setOrderResponse(null));
    if (hasCoffee) dispatch(LoyaltyActions.getUserCoffeeLoyalty());
    keepCart();
  }, [analyticsState, dispatch, hasCoffee, keepCart]);

  const onCloseBarcode = useCallback(() => {
    setIsBarcodeVisible(false);
  }, []);

  const onBarcodeClick = useCallback(() => {
    let nearestStore: StoreReduxModels.Store | null = null;

    if (locationPermissionGranted && stores && stores.length > 0) {
      nearestStore = stores[0];
    }

    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.BarcodeScan,
      AnalyticsPayloadGenerator.barcodePayload(
        AnalyticsConst.BarcodeSource.Header,
        nearestStore
      )
    );
    setIsBarcodeVisible(true);
  }, [locationPermissionGranted, stores]);

  return (
    <>
      <BarcodeModal
        isVisible={isBarcodeVisible}
        cardNumber={profile?.cardNumber?.toString() ?? " "}
        firstName={profile?.firstName ?? ""}
        lastName={profile?.lastName ?? ""}
        gygPoints={loyalty?.actualPointsBalance ?? 0}
        gygDollars={loyalty?.actualDollarsBalance ?? 0}
        onCloseClick={onCloseBarcode}
      />

      <ModalWithButton
        isVisible={showAbandonOrder}
        title={t("CheckoutPayment:abandonTitle")}
        messageBold={t("CheckoutPayment:abandonLabel")}
        primaryButton={{
          name: t("CheckoutPayment:abandonDelButton").toUpperCase(),
          action: abandonOrder,
        }}
        secondaryButton={{
          name: t("CheckoutPayment:continueButton"),
          action: keepCart,
        }}
        onModalClose={() => setAbandonOrder(false)}
        image={errorImage as ImageSourcePropType}
        small={true}
        modalId='header-save-prompt'
      />

      <Header
        {...props}
        items={items}
        totalPrice={totalPrice}
        viewCartClick={onOrderClicked}
        onMenuClick={onMenuClicked}
        showIconAndPrice={confirmedOrderSetup}
        hideCartButton={pathname === Screens.Order}
        onBarcodeClick={onBarcodeClick}
        cartButtonLoading={getOrderLoading || updateOrderLoading}
      />
    </>
  );
};

export default HeaderContainer;
