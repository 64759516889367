import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import iconAdd from "../../../components/assets/icons/icon_add.png";
import iconDelete from "../../../components/assets/icons/icon_delete.png";
import iconMinus from "../../../components/assets/icons/icon_minus.png";
import * as PlatformUtils from "../../../modules/platformUtils";
import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export interface ProductQuantitySelectorProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
  theme?: ProductQuantitySelectorTheme;
  isError?: boolean;
  isCartItemDeletionInProgress?: boolean;
  deleteItem?: () => void;
  getOrderLoading?: boolean;
  hidePlusButton?: boolean;
  forceDisable?: boolean;
}

interface ProductQuantitySelectorStyles {
  icon: ImageStyle;
  disabledIcon: ImageStyle;
  buttonGroup: ViewStyle;
  card: ViewStyle;
  text: TextStyle;
}

export enum ProductQuantitySelectorTheme {
  product = "product",
  cart = "cart",
}

// TODO: Split styles out
const resizeModeCover: ImageResizeMode = "cover";

const productIconStyle = {
  width: 32,
  height: 32,
  margin: Spacing.Thin,
  resizeMode: resizeModeCover,
};

const commonStyles = StyleSheet.create({
  split: {
    flex: 1,
  },
  placeholderContainer: {
    width: 20,
  },
});

const productStyles = StyleSheet.create<ProductQuantitySelectorStyles>({
  icon: {
    ...productIconStyle,
    tintColor: colours.black,
  },
  disabledIcon: {
    ...productIconStyle,
    tintColor: colours.uiGrey,
  },
  card: {
    height: 48,
    width: 133,
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    ...Typography.titleOne,
    marginTop: Platform.OS != "web" ? Spacing.ExtraThin : 0,
  },
});

const cartIconStyle = {
  width: 24,
  height: 24,
  margin: Spacing.Thin,
  resizeMode: resizeModeCover,
};

const cartStyles = StyleSheet.create<ProductQuantitySelectorStyles>({
  icon: {
    ...cartIconStyle,
  },
  disabledIcon: {
    ...cartIconStyle,
    tintColor: colours.uiGrey,
  },
  card: {
    minHeight: 40,
    width: 108,
  },
  buttonGroup: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: BorderRadius.Light,
    borderWidth: 2,
    borderColor: colours.black,
  },
  text: {
    ...Typography.titleTwo,
    marginTop: Platform.OS != "web" ? 5 : 0,
  },
});

const ProductQuantitySelector: React.FC<ProductQuantitySelectorProps> = ({
  deleteItem,
  isError,
  isCartItemDeletionInProgress = false,
  quantity,
  setQuantity,
  theme,
  getOrderLoading,
  hidePlusButton,
  forceDisable,
}) => {
  const [currentQuantity, setCurrentQuantity] = useState<number>(quantity);
  const isButtonClicked = useRef<boolean>(false);

  // callback to parent to set the correct quantity
  // in state management
  useEffect(() => {
    if (currentQuantity !== quantity) {
      if (isCartItemDeletionInProgress || !isButtonClicked.current) {
        // if the cart item is being deleted or
        // if an updated response (based on validation)
        // comes back from BE
        // update the UI based on cart slice state
        setCurrentQuantity(quantity);
      } else {
        // otherwise update cart slice state based on + - clicks
        // allow 0 quantity to be sent back up to order container
        // which will be ignored, thereby preventing
        // an additional update cart API call which can cause
        // issues when made in quick sucession with delete cart
        setQuantity(currentQuantity);
        isButtonClicked.current = false;
      }
    }
  }, [isCartItemDeletionInProgress, currentQuantity, quantity, setQuantity]);

  const getStyles = (): ProductQuantitySelectorStyles => {
    switch (theme) {
      case ProductQuantitySelectorTheme.cart:
        return cartStyles;
      case ProductQuantitySelectorTheme.product:
        return productStyles;
      default:
        return productStyles;
    }
  };

  const isQuantityDecreaseDisabled = (): boolean | undefined => {
    return (
      forceDisable || (currentQuantity === 1 && !deleteItem) || getOrderLoading
    );
  };

  const decreaseQuantity = () => {
    isButtonClicked.current = true;
    if ((deleteItem && currentQuantity == 1) || isError) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      deleteItem && deleteItem();
    } else {
      const newQuantity = currentQuantity - 1;
      if (newQuantity >= 0) {
        setCurrentQuantity(newQuantity);
      }
    }
  };

  const increaseQuantity = () => {
    isButtonClicked.current = true;
    setCurrentQuantity(currentQuantity + 1);
  };

  const componentStyles = getStyles();

  const counterView = (
    <View
      style={[
        componentStyles.buttonGroup,
        isError && { borderColor: colours.red },
      ]}>
      <TouchableOpacity
        {...PlatformUtils.generateTestID(Platform.OS, "DecreaseQuantityBy1")}
        disabled={isQuantityDecreaseDisabled()}
        onPress={isError ? deleteItem : decreaseQuantity}>
        <Image
          style={
            isQuantityDecreaseDisabled()
              ? componentStyles.disabledIcon
              : componentStyles.icon
          }
          source={
            (deleteItem && currentQuantity === 1) || isError
              ? (iconDelete as ImageSourcePropType)
              : (iconMinus as ImageSourcePropType)
          }
        />
      </TouchableOpacity>
      <View style={commonStyles.split} />
      <Text
        {...PlatformUtils.generateTestID(Platform.OS, "ProductQuantity")}
        style={componentStyles.text}>
        {currentQuantity || 1}
      </Text>
      <View style={commonStyles.split} />
      {hidePlusButton ? (
        <View style={commonStyles.placeholderContainer} />
      ) : (
        <TouchableOpacity
          disabled={forceDisable || getOrderLoading || isError}
          {...PlatformUtils.generateTestID(Platform.OS, "IncreaseQuantityBy1")}
          onPress={increaseQuantity}>
          <Image
            style={
              forceDisable || getOrderLoading || isError
                ? componentStyles.disabledIcon
                : componentStyles.icon
            }
            source={iconAdd as ImageSourcePropType}
          />
        </TouchableOpacity>
      )}
    </View>
  );
  return <View style={componentStyles.card}>{counterView}</View>;
};

export default ProductQuantitySelector;
