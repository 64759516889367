import "./styles.scss";

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity } from "react-native";

import { ErrorReduxModel, InputType } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { Checkbox } from "gyg_common/components/Checkbox";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import FormInput from "gyg_common/components/FormInput";
import PasswordValidation from "gyg_common/components/PasswordValidation";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export interface Input {
  text: string;
  error: boolean;
}

export interface RegistrationProps {
  isSocialUser: boolean;
  isMobileVerified: boolean;
  email: Input;
  password: Input;
  confirmPassword: Input;
  firstName: Input;
  lastName: Input;
  phone: Input;
  birthday: Input;
  acceptTerms: boolean;
  isSubsribed: boolean;
  isTermsError: boolean;
  isEightChars: boolean;
  hasLowerCase: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
  disableButton: boolean;
  registerErrorResponse: ErrorReduxModel.ErrorResponse | undefined;
  linkPrivacyPolicy: string;
  linkTermsAndConditions: string;
  existingUserMobileVerify: boolean;
  onTextChange: (text: string, type: string) => void;
  onButtonPress: () => void;
  onTermsPress: () => void;
  onSubsPress: () => void;
}

export const Registration: React.FC<RegistrationProps> = (props) => {
  const { t } = useTranslation();
  const container = useRef(null);

  useEffect(() => {
    if (!!props.registerErrorResponse) {
      const body = document.documentElement;
      body.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [props.registerErrorResponse]);

  const handleChangeText = (inputType: string) => (text: string) => {
    props.onTextChange(text, inputType);
  };

  const onOpenTermsURL = () => {
    if (props.linkTermsAndConditions) {
      window.open(props.linkTermsAndConditions, "_blank");
    }
  };

  const onOpenPrivacyPolicyURL = () => {
    if (props.linkPrivacyPolicy) {
      window.open(props.linkPrivacyPolicy, "_blank");
    }
  };

  return (
    <>
      <HeaderContainer pageTitle={t("LoginRegistration:registerTitle")} />
      <div
        className='registration main-wrapper main-wrapper--narrow'
        ref={container}>
        <div className='registration__social main-wrapper__col main-wrapper__col--single'>
          {props.registerErrorResponse && (
            <div
              data-testid='registration-error'
              className='registration__errorContainer'>
              <ErrorAlert
                isVisible={!!props.registerErrorResponse}
                title={
                  props.registerErrorResponse?.message ??
                  props.registerErrorResponse?.heading ??
                  t("Error:defaultErrorHeading")
                }
              />
            </div>
          )}
          <form style={{ display: "contents" }} autoComplete='on'>
            <FormInput
              isDisabled={props.existingUserMobileVerify || props.isSocialUser}
              name={t("LoginRegistration:emailLabel")}
              handleChangeText={handleChangeText(InputType.EMAIL)}
              error={props.email.error}
              value={props.email.text}
              placeHolder={t("LoginRegistration:emailPlaceholder")}
              errorMsg={t("LoginRegistration:emailError")}
              type={InputType.EMAIL}
            />
            <FormInput
              isDisabled={props.isSocialUser}
              isHidden={props.isSocialUser}
              type={InputType.PASSWORD}
              name={t("LoginRegistration:passwordLabel")}
              handleChangeText={handleChangeText(InputType.PASSWORD)}
              error={props.password.error}
              value={props.password.text}
              placeHolder={t("LoginRegistration:passwordPlaceholder")}
              errorMsg={t("LoginRegistration:passwordFormatError")}
            />

            {!props.isSocialUser && (
              <PasswordValidation value={props.password.text} />
            )}

            <FormInput
              isDisabled={props.isSocialUser}
              isHidden={props.isSocialUser}
              type={InputType.PASSWORD}
              name={t("LoginRegistration:confirmPasswordLabel")}
              handleChangeText={handleChangeText(InputType.CONFIRM_PASSWORD)}
              error={props.confirmPassword.error}
              value={props.confirmPassword.text}
              placeHolder={t("LoginRegistration:passwordPlaceholder")}
              errorMsg={t("LoginRegistration:passwordMatchError")}
            />
            <FormInput
              isDisabled={props.existingUserMobileVerify && !props.isSocialUser}
              name={t("LoginRegistration:firstNameLabel")}
              handleChangeText={handleChangeText(InputType.FIRST_NAME)}
              error={props.firstName.error}
              value={props.firstName.text}
              errorMsg={t("LoginRegistration:nameError")}
              placeHolder={t("LoginRegistration:firstNamePlaceholder")}
            />
            <FormInput
              isDisabled={props.existingUserMobileVerify && !props.isSocialUser}
              name={t("LoginRegistration:lastNameLabel")}
              handleChangeText={handleChangeText(InputType.LAST_NAME)}
              error={props.lastName.error}
              value={props.lastName.text}
              errorMsg={t("LoginRegistration:nameError")}
              placeHolder={t("LoginRegistration:lastNamePlaceholder")}
            />
            <FormInput
              name={t("LoginRegistration:phoneLabel")}
              handleChangeText={handleChangeText(InputType.PHONE)}
              error={
                !props.isMobileVerified &&
                (props.existingUserMobileVerify || props.phone.error)
              }
              value={props.phone.text}
              placeHolder={t("LoginRegistration:phonePlaceholder")}
              errorMsg={
                props.phone.error
                  ? t("LoginRegistration:phoneError")
                  : t("UserManagement:existingVerifyRegisterErrorLabel")
              }
              type={InputType.PHONE}
            />
          </form>
          {props.isMobileVerified && (
            <>
              <FormInput
                isDisabled={props.isSocialUser}
                isHidden={props.isSocialUser}
                type={InputType.BIRTHDAY}
                name={t("LoginRegistration:birthdayLabel")}
                handleChangeText={handleChangeText(InputType.BIRTHDAY)}
                error={props.birthday.error}
                value={props.birthday.text}
                placeHolder={t("LoginRegistration:birthdayPlaceholder")}
                errorMsg={t("LoginRegistration:dateError")}
              />

              <div className='registration__tcView'>
                <TouchableOpacity
                  data-testid='TermsConditionLabel'
                  activeOpacity={1}
                  onPress={props.onTermsPress}>
                  <Checkbox
                    error={props.isTermsError}
                    selected={props.acceptTerms}
                  />
                </TouchableOpacity>
                <div className='registration__tcLabel'>
                  {t("LoginRegistration:tcLabel1")}
                  <div
                    onClick={onOpenTermsURL}
                    className='registration__tcUnderLine'>
                    {t("LoginRegistration:tcLabel2")}
                  </div>
                  <div>{" & "}</div>
                  <div
                    onClick={onOpenPrivacyPolicyURL}
                    className='registration__tcUnderLine'>
                    {t("LoginRegistration:tcLabel3")}
                  </div>
                </div>
              </div>

              {props.isTermsError && (
                <div className='registration__tcError'>
                  {t("LoginRegistration:tcLabelError")}
                </div>
              )}

              <div className='registration__tcView2'>
                <TouchableOpacity
                  data-testid={t("LoginRegistration:subsLabel")}
                  activeOpacity={1}
                  onPress={props.onSubsPress}>
                  <Checkbox selected={props.isSubsribed} />
                </TouchableOpacity>
                <div className='registration__tcLabel'>
                  {t("LoginRegistration:subsLabel")}
                </div>
              </div>
            </>
          )}

          <PrimaryBlackButton
            testId={
              props.isMobileVerified
                ? t("LoginRegistration:registerTitle")
                : t("LoginRegistration:nextButton")
            }
            disable={props.disableButton}
            onClick={props.onButtonPress}
            buttonName={
              props.isMobileVerified
                ? t("LoginRegistration:registerTitle")
                : t("LoginRegistration:nextButton")
            }
          />
        </div>
      </div>
    </>
  );
};
