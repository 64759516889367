import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { LoyaltyActions, LoyaltyModels, RootState } from "gyg_common";

import { CartRewards } from "../../components/Rewards/CartRewards/CartRewards";
import { LoyaltyUtils } from "../../modules/Loyalty";
import { OrderUtils } from "../../modules/Order";

export interface CartRewardsContainerProps {
  isLoading: boolean;
  onRedeemDollars: (value: number | null) => void;
}

export const CartRewardsContainer: React.FC<CartRewardsContainerProps> = ({
  isLoading,
  onRedeemDollars,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { rewards, activeReward, loyalty } = useSelector(
    (state: RootState) => state.loyalty
  );
  const { getOrderResponse } = useSelector((s: RootState) => s.order);
  const rewardsList = OrderUtils.activeRewardsList(
    rewards,
    getOrderResponse?.basket.coupons
  );

  const orderReceiptInfo = OrderUtils.formatOrderResponse(getOrderResponse);

  const onRewardPressed = (newActiveReward: LoyaltyModels.IRewards) => {
    if (activeReward?.userCouponCode === newActiveReward.userCouponCode) {
      dispatch(LoyaltyActions.clearActiveReward());
    } else {
      dispatch(LoyaltyActions.updateActiveReward(newActiveReward));
    }
  };

  useEffect(() => {
    if (activeReward) {
      LoyaltyUtils.adjustRewardsIfNeeded(rewardsList, activeReward, dispatch);
    }
  }, [activeReward, dispatch, rewardsList]);

  if ((!rewards?.length && !loyalty?.actualDollarsBalance) || !getOrderResponse)
    return null;

  return (
    <CartRewards
      rewards={rewardsList}
      activeReward={activeReward}
      onRewardPressed={onRewardPressed}
      isLoading={isLoading}
      title={t("Loyalty:rewardsTitle")}
      dollars={loyalty?.actualDollarsBalance}
      total={orderReceiptInfo.total}
      subtotal={orderReceiptInfo.subtotal}
      totalBeforeDiscount={orderReceiptInfo.totalBeforeDiscount}
      onRedeemDollars={onRedeemDollars}
      redeemDollars={orderReceiptInfo.redeemDollars}
      redeemDollarsBalance={orderReceiptInfo.redeemDollarsBalance}
      errorMessage={orderReceiptInfo.redeemDollarsErrorMessage}
    />
  );
};

export default CartRewardsContainer;
