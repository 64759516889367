import "./styles.scss";

import { Loader } from "@googlemaps/js-api-loader";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import config, { VERSION } from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  ApiOrderModel,
  ApiStoreModel,
  locales,
  MenuModels,
  StoreModules,
  StoreReduxModels,
  UserReduxAction,
} from "gyg_common";
import ErrorView from "gyg_common/components/Error/ErrorView";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import StoresList from "gyg_common/components/Stores/StoresList";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { getUserLocation } from "modules/location";
import { addRecentOrderItemsToCart } from "modules/OrderCard";
import { Screens } from "navigation/const";
import StoreFilter from "views/components/Stores/StoreFilter";
import StoreSearch, {
  ResultListItem,
} from "views/components/Stores/StoreSearch";
import StoresMap from "views/components/Stores/StoresMap";
import ToggleStoresView from "views/components/Stores/ToggleStoresView";
import { OrderSetupContainer } from "views/containers/OrderSetup/OrderSetupContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import { StoreLinkModal } from "../StoreLinkModal";

import StoreDetails from "@/views/components/Stores/NotWithDelivery/StoreDetails";

const loader = new Loader({
  apiKey: config.googleMapsKey || "",
  libraries: ["places"],
  id: config.googleMapsScriptId,
});

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    position: "relative",
  },
  mapFilter: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    zIndex: 500,
  },
});

export interface RestaurantsProps {
  previousScreen: string;
  recentOrdersToAddToCart?: ApiOrderModel.BasketItem[];
  menuStructure?: MenuModels.MenuStructure;
  locationPermissionGranted: boolean;
  userConfirmedOrderSetup: boolean;
  store: StoreReduxModels.StoreState;
  handleSetMessageToast: (toastMessage: string | null) => void;
  handleStoreSearch: (payload: StoreReduxModels.StoreSearchPayload) => void;
  handleGooglePlaceDetailSearchSuccess: (
    payload: StoreReduxModels.SortStoreByDistanceProps
  ) => void;
  handleSortStoresByDistance: (
    payload: StoreReduxModels.SortStoreByDistanceProps
  ) => void;
  handleSetStoreInfo: (payload: StoreReduxModels.Store | null) => void;
  handleSetStore: (payload: StoreReduxModels.Store) => void;
  handleSetStoreSearchLocation: (
    payload: StoreModules.StoreMapper.StoreCoordinates | null
  ) => void;
  handleSetNearestStoreAsSelected: (
    payload: StoreReduxModels.NearestStoreProps
  ) => void;
}

const Restaurants: React.FC<RestaurantsProps> = ({
  locationPermissionGranted,
  recentOrdersToAddToCart,
  menuStructure,
  store,
  handleSetMessageToast,
  handleStoreSearch,
  handleGooglePlaceDetailSearchSuccess,
  handleSetStore,
  handleSetStoreInfo,
  handleSetStoreSearchLocation,
  handleSortStoresByDistance,
  handleSetNearestStoreAsSelected,
  previousScreen,
}) => {
  // hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTabletScreen } = useMediaQuery();

  // variables
  const { DISTANCETHRESHOLD_AU, DISTANCETHRESHOLD_US } =
    StoreModules.StoreUtils;
  const {
    getStoresLoading,
    storeTags,
    searchResult,
    showStoreInfo,
    storeSearchLocation,
  } = store;

  // local state
  const [debounce, setDebounce] = useState<NodeJS.Timeout>();
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>();
  const [placeService, setPlaceService] =
    useState<google.maps.places.PlacesService>();

  const [placeResults, setplaceResults] = useState<
    StoreReduxModels.GooglePlaceSearchResult[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [stores, setStores] = useState<StoreReduxModels.Store[]>([]);
  const [selectedTags, updateSelectedTags] = useState<
    StoreModules.StoreModels.StoreTagLabel[]
  >([]);
  const [storeSearchFocused, setStoreSearchFocused] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [userLocation, setUserLocation] =
    useState<StoreModules.StoreMapper.StoreCoordinates | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [viewType, setViewType] =
    useState<StoreModules.StoreModels.RestaurantsViewType>(
      StoreModules.StoreModels.RestaurantsViewType.LIST
    );
  const [showOrderSetup, setShowOrderSetup] = useState<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [storeShareLink, setStoreShareLink] = useState<string>("");
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  const sortStoresByDistance = useCallback(
    (payload: StoreReduxModels.SortStoreByDistanceProps) =>
      handleSortStoresByDistance(payload),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSortStoresByDistance]
  );

  const setNearestStoreAsSelected = useCallback(
    (payload: StoreReduxModels.NearestStoreProps) =>
      handleSetNearestStoreAsSelected(payload),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSetNearestStoreAsSelected]
  );

  useEffect(() => {
    const handleScriptLoad = async () => {
      const { AutocompleteService, PlacesService } =
        await loader.importLibrary("places");
      const googleAutoCompleteService = new AutocompleteService();
      const googlePlaceService = new PlacesService(
        document.createElement("div")
      );
      setAutocompleteService(googleAutoCompleteService);
      setPlaceService(googlePlaceService);
    };

    handleScriptLoad();
  }, []);

  const getStoreSearchViewType = (): "List" | "Map" => {
    return `${viewType.substr(0, 1)}${viewType
      .substr(1, viewType.length)
      .toLowerCase()}` as "List" | "Map";
  };

  const sendViewLocationsAnalytics = async () => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ViewLocations,
      AnalyticsPayloadGenerator.viewLocationsPayload(
        previousScreen === "/" ? "Home" : previousScreen,
        locationPermissionGranted ? "True" : "False",
        getStoreSearchViewType()
      )
    );
  };

  const sendSearchLocationsAnalytics = async (autoSuggestTitle: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.SearchLocations,
      AnalyticsPayloadGenerator.searchLocationsPayload(
        searchText,
        autoSuggestTitle,
        getStoreSearchViewType()
      )
    );
  };

  const sendFilterLocationsAnalytics = async (filterName: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.FilterLocations,
      AnalyticsPayloadGenerator.filterLocationsPayload(
        filterName,
        getStoreSearchViewType()
      )
    );
  };

  const handleUpdateSelectedTag = (
    selected: boolean,
    tag: StoreModules.StoreModels.StoreTagLabel
  ) => {
    sendFilterLocationsAnalytics(tag);
    let result: StoreModules.StoreModels.StoreTagLabel[] = [...selectedTags];
    if (selected) {
      //unSelect the button
      result = result.filter((n) => n != tag);
    } else {
      //Select the button
      result.push(tag);
    }
    updateSelectedTags(result);
  };

  const onOrderSetupConfirmClick = () => {
    if (recentOrdersToAddToCart && menuStructure) {
      addRecentOrderItemsToCart(recentOrdersToAddToCart, menuStructure);
    }
    navigate(Screens.Menu);
  };

  const toggleViewType = () => {
    setViewType(
      viewType === StoreModules.StoreModels.RestaurantsViewType.LIST
        ? StoreModules.StoreModels.RestaurantsViewType.MAP
        : StoreModules.StoreModels.RestaurantsViewType.LIST
    );
  };

  const handleOrder = (newStore: StoreReduxModels.Store) => {
    if (!hasDelivery) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.OrderNow, {
        screen_origin: Screens.Restaurants,
        store_id: store.selectedStore?.id,
        store_name: store.selectedStore?.name,
        new_store_id: newStore.id,
        new_store_name: newStore.id,
      });

      handleSetStore(newStore);
    }
    setShowOrderSetup(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
    handleSetStoreInfo(null);
  };

  const handleSelectedStore = (storeModel: StoreReduxModels.Store) => {
    sendSearchLocationsAnalytics(storeModel.name);
    setShowModal(false);
    handleSetStoreInfo(storeModel);
  };

  const getSearchResult = (
    storeSearchResult: StoreReduxModels.StoreSearchResult
  ): ResultListItem[] => {
    return [
      ...storeSearchResult.fuzzySearchResult.map((n) => {
        return {
          data: n,
          type: StoreModules.StoreModels.ResultListItemType.FUZZY_SEARCH,
        };
      }),
      ...placeResults.map((n) => {
        return {
          data: n,
          type: StoreModules.StoreModels.ResultListItemType.GOOGLE_PLACE,
        };
      }),
    ];
  };

  const handleGetPlacePredictions = (
    predictions?: ApiStoreModel.Prediction[]
  ) => {
    if (!predictions) {
      setplaceResults([]);
      return;
    }
    let googleSearchResult: StoreReduxModels.GooglePlaceSearchResult[] =
      predictions.slice(0, 5).map((n) => {
        return {
          distance: n.distance_meters,
          name: n.structured_formatting.main_text,
          address: n.description,
          placeId: n.place_id,
        };
      });
    if (userLocation) {
      googleSearchResult = googleSearchResult.sort((a, b) => {
        return (a.distance || 0) - (b.distance || 0);
      });
    }
    setplaceResults(googleSearchResult);
  };

  const handleStoreSearchAction = (text: string) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        handleStoreSearch({
          searchText: text,
          location: userLocation,
          version: VERSION,
          stores: stores,
          apiKey: config.googleMapsKey,
          disableGoogleSearch: true,
        });
        if (!text) {
          handleGetPlacePredictions([]);
          return;
        }
        const request: { [key: string]: unknown } = {
          input: text,
          types: ["(regions)"],
          componentRestrictions: { country: VERSION },
        };
        if (userLocation) {
          request.origin = {
            lat: userLocation.latitude,
            lng: userLocation.longitude,
          };
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (autocompleteService as any)?.getPlacePredictions(
          request,
          handleGetPlacePredictions
        );
      }, 1000)
    );
  };

  const handleGetDetail = (data: ApiStoreModel.Result) => {
    handleGooglePlaceDetailSearchSuccess({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      latitude: (data.geometry.location as any).lat(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      longitude: (data.geometry.location as any).lng(),
      version: config.version,
    });
  };

  const handleSelectedRegion = (
    data: StoreReduxModels.GooglePlaceSearchResult
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (placeService as any)?.getDetails(
      { placeId: data.placeId, fields: ["geometry"] },
      handleGetDetail
    );
  };

  const handleClearSearch = () => {
    handleSetStoreSearchLocation(null);
    if (userLocation) {
      sortStoresByDistance({
        ...userLocation,
        version: VERSION,
      });
    }
    setplaceResults([]);
  };

  const onShareLinkClick = (link: string) => {
    setShowLinkModal(true);
    setStoreShareLink(link);
    setShowModal(false);
  };

  useEffect(() => {
    const filterResult = StoreModules.StoreFilter.filterStoreByTags(
      selectedTags,
      store.stores
    );
    setStores(filterResult);

    if (filterResult.length <= 0) {
      handleSetMessageToast(t("StoreSearch:alertNoFilteredResults"));
    } else {
      const isAU = config.version === locales.AU;
      const distance = store.stores[0].distance ?? 0;
      const localeDistance = isAU
        ? distance
        : StoreModules.DistanceCalculator.getMiles(distance);

      if (localeDistance > DISTANCETHRESHOLD_AU) {
        handleSetMessageToast(
          t(
            storeSearchLocation !== null
              ? "StoreSearch:alertNoResultsNearby"
              : "StoreSearch:alertNoRestaurantsNearby",
            {
              distanceThreshold: isAU
                ? DISTANCETHRESHOLD_AU / 1000
                : DISTANCETHRESHOLD_US,
            }
          )
        );
      } else {
        handleSetMessageToast(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, store.stores]);

  /** Sort the stores by distance to the user and set the nearest store as selected by default */
  useEffect(() => {
    if (
      !getStoresLoading &&
      !showOrderSetup &&
      userLocation &&
      userLocation.latitude &&
      userLocation.longitude
    ) {
      sortStoresByDistance({
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
        version: config.version,
      });
      setNearestStoreAsSelected({
        nearestStoreThresholdKm: config.locationConfig.nearestStoreThresholdKm,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation, getStoresLoading, showOrderSetup]);

  /**
   * Gets user's location if it's enabled.
   */
  useEffect(() => {
    const getUserLocationLatLong = async () => {
      if (locationPermissionGranted) {
        getUserLocation()
          .then((data) => {
            if (data) {
              const coordinates = {
                longitude: data.coords.longitude,
                latitude: data.coords.latitude,
              };
              setUserLocation(coordinates);
              sortStoresByDistance({
                ...coordinates,
                version: VERSION,
              });
            }
          })
          .catch(() => {
            dispatch(UserReduxAction.setLocationPermission(false));
          });
      }
    };
    getUserLocationLatLong().catch(() => {
      dispatch(UserReduxAction.setLocationPermission(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationPermissionGranted]);

  useEffect(() => {
    if (showStoreInfo) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [showStoreInfo]);

  useEffect(() => {
    sendViewLocationsAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType]);

  const onCloseAction = () => {
    setShowOrderSetup(false);
  };

  return (
    <div className='restaurants'>
      <OrderSetupContainer
        onConfirmClick={onOrderSetupConfirmClick}
        onCloseModal={onCloseAction}
        isVisible={showOrderSetup}
      />

      {/* Only if clipboard is not available */}
      <StoreLinkModal
        isVisible={showLinkModal}
        link={storeShareLink}
        onClose={() => setShowLinkModal(false)}
      />

      <StoreDetails
        showModal={showModal}
        store={showStoreInfo}
        handleOrderFromRestaurant={handleOrder}
        onCloseModal={onCloseModal}
        onShareLinkClick={onShareLinkClick}
      />
      <View
        style={styles.header}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setHeaderHeight(height);
        }}>
        <HeaderContainer
          pageTitle={t("StoreSearch:pageName")}
          bottomComponentNotScrollable={
            <div className={"restaurants__store-search"}>
              <StoreSearch
                searchText={searchText}
                setSearchText={setSearchText}
                headerHeight={headerHeight + 60}
                onClearSearch={handleClearSearch}
                onSelectedRegion={handleSelectedRegion}
                onSelectedStore={(data) => {
                  handleSelectedStore(data);
                }}
                searchResult={getSearchResult(searchResult)}
                isFocused={storeSearchFocused}
                setFocused={(focus) => {
                  setStoreSearchFocused(focus);
                }}
                handleStoreSearch={handleStoreSearchAction}
              />
              {(!storeSearchFocused || isTabletScreen) && (
                <div className={"restaurants__store-search-toggle"}>
                  <ToggleStoresView
                    activeView={viewType}
                    onViewChange={() => toggleViewType()}
                  />
                </div>
              )}
            </div>
          }
        />
      </View>
      {getStoresLoading && !store.error ? (
        <LoadingScreen loading={getStoresLoading} />
      ) : (
        <>
          {store.error ? (
            <ErrorView
              heading={store.error.heading}
              message={
                store.error.message ?? t("StoreSearch:noStoresErrorMessage")
              }
            />
          ) : (
            <View style={styles.wrapper}>
              <View
                style={
                  viewType === StoreModules.StoreModels.RestaurantsViewType.MAP
                    ? styles.mapFilter
                    : {}
                }>
                <div className='main-wrapper restaurants__store-filter-container'>
                  <StoreFilter
                    onSelectedUpdate={handleUpdateSelectedTag}
                    selectedTags={selectedTags}
                    storeTags={storeTags}
                  />
                </div>
              </View>
              {viewType === StoreModules.StoreModels.RestaurantsViewType.MAP ? (
                <StoresMap
                  stores={
                    searchResult.fuzzySearchResult.length > 0
                      ? getSearchResult(searchResult)
                          .filter(
                            (n) =>
                              n.type ===
                              StoreModules.StoreModels.ResultListItemType
                                .FUZZY_SEARCH
                          )
                          .map((n) => n.data as StoreReduxModels.Store)
                      : stores
                  }
                  withDelivery={false}
                  handleOrder={handleOrder}
                  showToast={handleSetMessageToast}
                  latLong={
                    userLocation
                      ? [userLocation.latitude, userLocation.longitude]
                      : undefined
                  }
                  isUserLocationKnown={locationPermissionGranted}
                />
              ) : (
                <div className='restaurants__list-wrapper'>
                  <div className={"restaurants__list-container"}>
                    <div className={"restaurants__list-view"}>
                      <StoresList
                        searchResultApply={storeSearchLocation != null}
                        location={userLocation}
                        stores={stores}
                        handleOrder={handleOrder}
                      />
                    </div>
                  </div>
                </div>
              )}
            </View>
          )}
        </>
      )}
    </div>
  );
};

export default Restaurants;
