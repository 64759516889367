import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import CardSticker from "gyg_common/components/CardSticker";

import colours from "./styles/colours";
import { BorderRadius, Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

export interface RoundedTitleImageContainerProps {
  title: string;
  image: ImageSourcePropType | undefined;
  rightImage?: ImageSourcePropType | undefined;
  children?: React.ReactNode;
  topPadding?: number;
  onPress?: () => void;
}

const wrapperStyles = (topPadding?: number) =>
  StyleSheet.create({
    wrapper: {
      margin: Spacing.Light,
      marginTop: topPadding ?? Spacing.ExtraLight,
      marginBottom: Platform.OS == "web" ? Spacing.Medium : Spacing.ExtraLight,
    },
  });

const styles = StyleSheet.create({
  wrapper: {
    margin: Spacing.Light,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  titleImageContainer: { flexDirection: "row", alignItems: "center" },
  iconContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    backgroundColor: colours.yellow,
    borderRadius: BorderRadius.High,
    marginRight: Spacing.Thin,
  },
  icon: { width: 20, height: 20 },
  rightIconContainer: {
    alignItems: "flex-end",
  },
  rightIcon: { height: 23, width: 85 },
  rightIconArrow: { height: 25, width: 24 },
  text: {
    ...Typography.titleFour,
    fontSize: Platform.OS == "web" ? 23 : 16,
    paddingTop: Spacing.Narrow,
    textTransform: "capitalize",
  },
});

export const RoundedTitleImageContainer: React.FC<
  RoundedTitleImageContainerProps
> = ({ children, title, image, rightImage, topPadding, onPress }) => {
  return (
    <View style={wrapperStyles(topPadding).wrapper}>
      <CardSticker>
        <View style={styles.headerContainer}>
          <View style={styles.titleImageContainer}>
            <View style={styles.iconContainer}>
              <Image source={image} resizeMode='contain' style={styles.icon} />
            </View>
            <Text style={styles.text}>{title.toLowerCase()}</Text>
          </View>
          {rightImage && (
            <Pressable
              onPress={onPress}
              style={styles.rightIconContainer}
              disabled={!onPress}>
              <Image
                source={rightImage}
                resizeMode='contain'
                style={onPress ? styles.rightIconArrow : styles.rightIcon}
              />
            </Pressable>
          )}
        </View>
        {children}
      </CardSticker>
    </View>
  );
};
