import useAddToOrder from "hooks/useAddToOrder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  ProductConst,
  RootState,
} from "gyg_common";
import CustomizableProduct from "gyg_common/components/Products/CustomizableProduct/CustomizableProduct";
import { getPreSelectedMealId } from "gyg_common/modules/Products/utils";

import MIAMProduct from "../../components/Products/MIAMProduct";

export interface MIAMProductContainerProps {
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  index: number;
  cartItem: CartReduxModels.CartItem;
  commonSection: MenuModels.CommonSection;
  isUpsell?: boolean;
  goBack: () => void;
  onClose: () => void;
}

const MIAMProductContainer = (
  props: MIAMProductContainerProps
): JSX.Element => {
  const {
    category,
    goBack,
    editMode,
    index,
    onClose,
    cartItem,
    commonSection,
    isUpsell,
  } = props;
  const { MIAMItem } = useSelector((state: RootState) => state.cart);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const dispatch = useDispatch();

  const [showCustomisableMIAMoption, setShowCustomisableMIAMoption] =
    useState<boolean>(false);
  const [selectedMIAMcategory, setSelectedMIAMcategory] = useState<{
    category: MenuModels.Category;
    index: number;
  }>();
  const [temporaryCartItem, setTemporaryCartItem] = useState<
    CartReduxModels.CartItem | undefined
  >(undefined);

  const preSelectedMealId = getPreSelectedMealId({
    editMode,
    cartItem,
  });
  const [selectedMealId, updateSelectedMealId] = useState<number | undefined>(
    preSelectedMealId
  );

  const handleResetMIAMItems = () => {
    dispatch(CartReduxAction.clearMIAMItem());
  };

  const onCloseMiamOption = () => {
    setShowCustomisableMIAMoption(false);
  };

  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: showCustomisableMIAMoption
      ? selectedMIAMcategory?.category?.name
      : (category?.name ?? ""),
    onClose: showCustomisableMIAMoption ? onCloseMiamOption : onClose,
  });

  const handleSelectMIAMoption = (
    optionCartItem: CartReduxModels.CartItem | undefined,
    optionIndex: number
  ) => {
    dispatch(
      CartReduxAction.updateMIAMItem({
        cartItem: optionCartItem,
        index: optionIndex,
      })
    );
  };

  const handleMIAMcustomisableOptionSelected = (
    categoryMiam: MenuModels.Category,
    temporaryMiamCartItem: CartReduxModels.CartItem | undefined,
    indexMiam: number
  ) => {
    if (temporaryMiamCartItem) {
      setSelectedMIAMcategory({ category: categoryMiam, index: indexMiam });
      setShowCustomisableMIAMoption(true);
      setTemporaryCartItem(temporaryMiamCartItem);
    } else {
      setShowCustomisableMIAMoption(false);
      setTemporaryCartItem(undefined);
    }
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductCategory, {
      product_category_id: category.id,
      product_category_name: category.name,
      product_quantity: cartItem?.quantity ?? 1,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showCustomisableMIAMoption ? (
        <CustomizableProduct
          selectedMealId={selectedMealId}
          updateSelectedMealId={updateSelectedMealId}
          key='MIAM-customisable-option'
          MIAMItem={MIAMItem}
          cartItem={
            MIAMItem && selectedMIAMcategory
              ? MIAMItem[selectedMIAMcategory.index]
              : undefined
          }
          handleAddToOrder={handleAddToOrder}
          handleResetMIAMItems={handleResetMIAMItems}
          index={selectedMIAMcategory?.index}
          editMode={ProductConst.EditMode.UPDATE_MIAM_ITEM}
          goBack={() => setShowCustomisableMIAMoption(false)}
          category={selectedMIAMcategory?.category as MenuModels.Category}
          fromMIAM={temporaryCartItem ? true : false}
        />
      ) : (
        <MIAMProduct
          category={category}
          commonSection={commonSection}
          MIAMItem={MIAMItem}
          editMode={editMode}
          cartItem={temporaryCartItem || cartItem}
          index={index}
          goBack={goBack}
          onClose={onClose}
          handleMIAMcustomisableOptionSelected={
            handleMIAMcustomisableOptionSelected
          }
          handleMIAMsimpleOptionSelected={handleSelectMIAMoption}
          handleResetMIAMItems={handleResetMIAMItems}
          isUpsell={isUpsell}
        />
      )}
    </>
  );
};

export default MIAMProductContainer;
