import dollarIcon from "assets/icons/icon_dollars.png";
import pointsIcon from "assets/icons/icon_points.png";
import React from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageURISource, StyleSheet, Text, View } from "react-native";

import * as GomexModules from "../../modules/Gomex";
import colours from "../styles/colours";
import { Typography } from "../styles/typography";

const authUserCartGomexStyles = (authUserCartGomex: boolean) =>
  StyleSheet.create({
    rewardsText: {
      ...Typography.titleThree,
      fontSize: 16,
      letterSpacing: 0.5,
      color: authUserCartGomex ? colours.darkGrey : colours.yellow,
    },
  });

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    flex: 0.5,
  },
  rewardsText: {
    ...Typography.titleThree,
    fontSize: 16,
    letterSpacing: 0.5,
    color: colours.yellow,
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 6,
  },
});

export interface EarnedPointsProps {
  authUserCartGomex: boolean;
  earnedPoints: number;
  earnedDollars: number;
}

const EarnedPoints: React.FC<EarnedPointsProps> = ({
  authUserCartGomex,
  earnedPoints,
  earnedDollars,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.row}>
        <Image source={pointsIcon as ImageURISource} style={styles.icon} />
        <Text style={authUserCartGomexStyles(authUserCartGomex).rewardsText}>
          {t("Loyalty:earnedPointsLabel", { earnedPoints })}
        </Text>
      </View>
      <View style={styles.row}>
        <Image source={dollarIcon as ImageURISource} style={styles.icon} />
        <Text style={authUserCartGomexStyles(authUserCartGomex).rewardsText}>
          {t("Loyalty:earnedDollarsLabel", {
            earnedDollars:
              GomexModules.GomexUtils.formatLoyaltyDollars(earnedDollars),
          })}
        </Text>
      </View>
    </View>
  );
};

export default EarnedPoints;
