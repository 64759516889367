import "./style.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import {
  FlatList,
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { CheckoutModels, OrderCardUtils } from "gyg_common";
import downImg from "gyg_common/components/assets/icons/icon_chevron_down_small.png";
import { OrderCardViewProps } from "gyg_common/modules/OrderCard/model";
import colours from "styles/colours";
import { FlyingBurritoLoader } from "views/components/shared/Loading/FlyingBurritoLoader/FlyingBurritoLoader";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";
import { OrderCardContainer } from "views/containers/shared/OrderCard/OrderCardContainer";

export interface RecentOrdersProps {
  recentOrders: CheckoutModels.CheckoutResponse[];
  showMoreAvailable: boolean;
  isLoading: boolean;
  onShowMore: () => void;
  reorderButtonPress: (model: OrderCardViewProps) => void;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  footer: {
    padding: 16,
    justifyContent: "center",
    flexDirection: "row",
  },
  showMoreBtn: {
    padding: 12,
    flexDirection: "row",
    justifyContent: "center",
  },
  btnText: {
    fontFamily: "Sini-Bold",
    color: colours.darkGrey,
    fontSize: 18,
    lineHeight: 24,
  },
  upDownImage: {
    width: 24,
    height: 24,
  },
});

export const RecentOrders: React.FC<RecentOrdersProps> = ({
  recentOrders,
  showMoreAvailable,
  isLoading,
  reorderButtonPress,
  onShowMore,
}) => {
  const { t } = useTranslation();
  const recentOrdersCardProps =
    OrderCardUtils.mapCheckoutResponseToOrderCardProps(recentOrders);

  const renderFooter = () => {
    return (
      <View style={styles.footer}>
        {isLoading ? (
          <FlyingBurritoLoader />
        ) : (
          <>
            {recentOrdersCardProps.length && showMoreAvailable ? (
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => onShowMore()}
                style={styles.showMoreBtn}>
                <>
                  <Text style={styles.btnText}>
                    {t("Dashboard:showMoreButton")}
                  </Text>
                  <Image
                    style={styles.upDownImage}
                    source={downImg as ImageSourcePropType}
                  />
                </>
              </TouchableOpacity>
            ) : null}
          </>
        )}
      </View>
    );
  };

  return (
    <div className='main-recent-orders'>
      <HeaderContainer pageTitle={t("RecentOrders:recentOrdersTitle")} />
      <div className='main-wrapper main-wrapper--narrow'>
        <div className='main-recent-orders__container'>
          {recentOrdersCardProps ? (
            <FlatList
              style={styles.container}
              keyExtractor={(item) => item.orderNo}
              data={recentOrdersCardProps}
              renderItem={({ item }) => (
                <div
                  id={`RecentOrders-${item.orderNo}`}
                  className='main-recent-orders__order'
                  key={`RecentOrders - ${item.orderNo}`}>
                  <OrderCardContainer
                    isFullWidth={true}
                    orderProps={item}
                    reorderButtonPress={() => reorderButtonPress(item)}
                  />
                </div>
              )}
              ListFooterComponent={renderFooter}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RecentOrders;
