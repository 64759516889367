import "./style.scss";

import locationIcon from "assets/icons/icon_location_small.png";
import React from "react";
import { useTranslation } from "react-i18next";

import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";

export interface LocationPermissionProps {
  onClickPrimaryButton: () => void;
}

export const LocationPermission: React.FC<LocationPermissionProps> = ({
  onClickPrimaryButton,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid='ShareLocation' className='location'>
      <div className='location__title'>
        <img src={locationIcon} className='location__icon' />
        <p className='location__title-text'>
          {t("OrderManagement:fasterOrderingTitle")}
        </p>
      </div>

      <div className='location__content'>
        <div className='location__button location__button--location-perm'>
          <div
            data-testid='ShareLocationButton'
            className='location__button--location-perm__element location__button--location-perm--no-margin'>
            <PrimaryBlackButton
              buttonName={t("OrderManagement:shareLocation")}
              onClick={onClickPrimaryButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
