import "./styles.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorReduxModel, InputType } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import FormInput from "gyg_common/components/FormInput";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export interface Input {
  text: string;
  error: boolean;
}

export interface ForgotPasswordProps {
  email: Input;
  isDisableButton: boolean;
  forgotPasswordError?: ErrorReduxModel.ErrorResponse | undefined;
  onTextChange: (text: string, type: string) => void;
  onButtonPress: () => void;
  onCloseErrorAlert: () => void;
}

export default function ForgotPassword(
  props: ForgotPasswordProps
): JSX.Element {
  const { t } = useTranslation();

  const [error, setError] = useState<
    ErrorReduxModel.ErrorResponse | undefined
  >();

  useEffect(() => {
    if (props.forgotPasswordError) {
      setError(props.forgotPasswordError);
    } else {
      setError(undefined);
    }
  }, [props.forgotPasswordError]);

  const handleChangeText = (text: string) =>
    props.onTextChange(text, InputType.EMAIL);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!props.isDisableButton) {
      props.onButtonPress();
    }
  };

  return (
    <div className='main-forgot'>
      <HeaderContainer pageTitle={t("LoginRegistration:forgotPasswordTitle")} />
      <div className='main-wrapper main-wrapper--narrow'>
        <div className='main-wrapper__col main-wrapper__col--single'>
          <p className='main-forgot__subtitle'>
            {t("LoginRegistration:forgotPasswordLabel")}
          </p>
          <ErrorAlert
            isVisible={!!error}
            title={error?.message || t("LoginRegistration:errorLabel")}
            onDismiss={props.onCloseErrorAlert}
          />
          <p className='main-forgot__reminder'>
            {t("LoginRegistration:forgotPasswordReminder")}
          </p>
          <form
            style={{ display: "contents" }}
            autoComplete='on'
            onSubmit={handleSubmit}>
            <FormInput
              name={t("LoginRegistration:emailTitle")}
              testId={t("LoginRegistration:emailTitle")}
              handleChangeText={handleChangeText}
              error={props.email.error}
              value={props.email.text}
              errorMsg={t("LoginRegistration:emailError")}
              placeHolder={t("LoginRegistration:emailPlaceholder")}
              type={InputType.EMAIL}
            />

            <PrimaryBlackButton
              disable={props.isDisableButton}
              testId={t("LoginRegistration:resetPasswordTitle")}
              onClick={props.onButtonPress}
              buttonName={t("LoginRegistration:resetPasswordTitle")}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
