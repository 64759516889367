import { batch } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  ApiOrderModel,
  CartReduxAction,
  MenuModels,
  OrderModules,
  OrderReduxAction,
} from "gyg_common";

import { store } from "../../redux_store/configureReduxStore";

/**
 * Add items in recent orders to cart
 */
export const addRecentOrderItemsToCart = (
  items: ApiOrderModel.BasketItem[],
  menuStructure: MenuModels.MenuStructure
): void => {
  const cartItems = OrderModules.OrderUtils.basketMapper(items, menuStructure);

  AnalyticsInstance.trackEvent(AnalyticsConst.Events.AddToOrder, {
    source: AnalyticsConst.AddToOrderSource.RECENTORDER,
  });
  batch(() => {
    store.dispatch(CartReduxAction.addItemToCart({ cartItems }));
    store.dispatch(OrderReduxAction.setShouldUpdateOrder(true));
  });
};
