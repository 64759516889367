import { Dimensions, Platform, StyleSheet } from "react-native";

import colours from "../../../styles/colours";
import { Spacing } from "../../../styles/number";
import { Typography } from "../../../styles/typography";

const middleSize = 412;
const badgeSize = 20;

export const styleContainer = (showPointer: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      ...Platform.select({
        web: {
          pointerEvents: showPointer ? "auto" : "none",
        },
      }),
    },
  });

export const styles = StyleSheet.create({
  column: {
    flexDirection: "column",
    minWidth: 110,
    paddingRight: Spacing.ExtraThin,
  },
  smallText: {
    fontSize: 13,
  },
  labelYellowText: {
    ...Typography.titleThree,
    color: colours.black,
    backgroundColor: colours.yellow,
    paddingHorizontal: Spacing.ExtraThin,
  },
  labelWrapper: {
    borderTopColor: colours.yellow,
    borderTopWidth: 3,
  },
  coffeeIconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  coffeeIcon: {
    marginRight: Spacing.Thin,
  },
  rightChevronContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight:
      Dimensions.get("screen").width > middleSize
        ? Spacing.ExtraThin
        : -Spacing.ExtraLight,
  },
  rightChevronIcon: {
    width: 24,
    height: 24,
  },
  badgeText: {
    fontFamily: "Montserrat-Regular",
  },
});

export const labelStyle = (blackColor: boolean) =>
  StyleSheet.create({
    label: {
      flex: 0,
      ...Typography.titleThree,
      color: blackColor ? colours.black : colours.white,
      minWidth: 110,
    },
    labelSubPart: {
      flex: 0,
      ...Typography.titleThree,
      color: blackColor ? colours.black : colours.white,
      borderTopColor: blackColor ? colours.white : colours.black,
      borderTopWidth: 3,
    },
    viewWrapper: {
      borderTopColor: !blackColor ? colours.black : colours.white,
      borderTopWidth: Platform.OS === "android" ? 3 : 0,
    },
    labelWrapper: {
      flex: 0,
      minWidth: 110,
      flexDirection: "row",
    },
  });

export const countStyle = (available: boolean) =>
  StyleSheet.create({
    coffeeCount: {
      position: "absolute",
      top: -Spacing.ExtraLight,
      right: -Spacing.Thin,
      width: badgeSize,
      height: badgeSize,
      backgroundColor: available ? colours.yellow : colours.starburst,
      borderRadius: badgeSize / 2,
      alignItems: "center",
      justifyContent: "center",
    },
  });
