import React from "react";
import { useTranslation } from "react-i18next";

import { GomexModules, GuestDetailModels, LoyaltyModels } from "gyg_common";
import GomexOrderBlack from "gyg_common/components/Gomex/GomexOrderBlack";
import GomexOrderWhite from "gyg_common/components/Gomex/GomexOrderWhite";

export interface OrderWithGomexProps {
  isUserAuthenticated: boolean;
  newPointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars;
  guestLoyalty: GuestDetailModels.GuestLoyalty;
  isOrderResponseLoading: boolean;
  loyalty?: LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  goToLogin: () => void;
}

/**
 * Displays Gomex section in cart view.
 * @param props
 * @returns
 */
const OrderWithGomex: React.FC<OrderWithGomexProps> = ({
  isUserAuthenticated,
  newPointsAndDollars,
  isOrderResponseLoading,
  loyalty,
  coffeeLoyalty,
  guestLoyalty,
  goToLogin,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isUserAuthenticated ? (
        <GomexOrderBlack
          isOrderResponseLoading={isOrderResponseLoading}
          newPointsAndDollars={newPointsAndDollars}
          loyaltyRewardAmount={loyalty?.loyaltyRewardAmount || 0}
          loyaltyRewardConversionLimit={
            loyalty?.loyaltyRewardConversionLimit || 0
          }
          actualDollarsBalance={loyalty?.actualDollarsBalance || 0}
          actualPointsBalance={loyalty?.actualPointsBalance || 0}
          captionCopy={t("Loyalty:futurePointsLabel")}
          isEstimatedStatus
          noGomexAnimation
          coffeeLoyalty={coffeeLoyalty}
        />
      ) : (
        <GomexOrderWhite
          isOrderResponseLoading={isOrderResponseLoading}
          newPointsAndDollars={newPointsAndDollars}
          captionCopy={t("Loyalty:guestFuturePointsLabel")}
          noGomexAnimation
          reminderCopy={t("Loyalty:reminderLabel")}
          pointsCopy={t("Loyalty:pointsConversion", {
            points: guestLoyalty.rewardConversionLimit || 0,
            loyaltyConversionRate: guestLoyalty.rewardAmount || 0,
          })}
          goToLogin={goToLogin}
        />
      )}
    </>
  );
};

export default OrderWithGomex;
