import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

import { FavouriteModels } from "gyg_common";
import { FavouriteCardContainer } from "views/containers/Favourites/FavouriteCardContainer";

interface FavouritesSectionProps {
  withTitle?: boolean;
  favouriteCartItems: FavouriteModels.FavouriteCartItem[];
  onRenamePress: (selectedFavourite: FavouriteModels.FavouriteCartItem) => void;
}

export const FavouritesSection = (
  props: FavouritesSectionProps
): JSX.Element => {
  const { favouriteCartItems, withTitle, onRenamePress } = props;
  const { t } = useTranslation();

  return (
    <div className='dashboard__favourite dashboard__favourite-wrapper__section'>
      {withTitle && (
        <p className='dashboard__favourite-title'>{t("Favourite:title")}</p>
      )}
      <Swiper
        observeParents
        observer
        resizeObserver
        slidesPerView={1}
        spaceBetween={4}
        breakpoints={{
          568: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
        }}
        updateOnWindowResize
        freeMode={false}
        watchOverflow={true}
        pagination={{
          clickable: true,
        }}>
        <div>
          {favouriteCartItems.map(
            (item: FavouriteModels.FavouriteCartItem, index: number) => {
              if (item) {
                return (
                  <div
                    id={`MenuListItem-${item.favouriteId}`}
                    key={`MenuListItem - ${item.favouriteId}`}>
                    <SwiperSlide key={`key- ${index} - ${item.favouriteId}`}>
                      <FavouriteCardContainer
                        favouriteCartItem={item}
                        onRenamePress={onRenamePress}
                      />
                    </SwiperSlide>
                  </div>
                );
              }
            }
          )}
        </div>
      </Swiper>
    </div>
  );
};
