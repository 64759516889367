import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, SafeAreaView } from "react-native";

import * as CartItemsCalculator from "../../../modules/Cart/itemsCalculator";
import {
  HandleUpdateOrder,
  ModifyOrderType,
} from "../../../modules/Order/models";
import * as ProductConst from "../../../modules/Products/const";
import * as CartReduxModels from "../../../redux_store/cart/models";
import PrimaryBlackButton from "../../Buttons/PrimaryBlackButton";
import PrimaryYellowButton from "../../Buttons/PrimaryYellowButton";

export interface AddModifyCartItemProps {
  addButtonTitle?: string;
  editMode: ProductConst.EditMode;
  cartItems?: CartReduxModels.CartItem[];
  index?: number;
  categoryName?: string;
  isUpsell?: boolean;
  onAddToOrder: (params: HandleUpdateOrder) => void;
  onEditOrder: (params: HandleUpdateOrder) => void;
  alwaysEnabled?: boolean;
}

const AddModifyCartItem: React.FC<AddModifyCartItemProps> = ({
  addButtonTitle,
  alwaysEnabled,
  editMode,
  cartItems,
  index,
  categoryName,
  onAddToOrder,
  onEditOrder,
}) => {
  const { t } = useTranslation();

  const buttonName =
    editMode === ProductConst.EditMode.MODIFY_CART_ITEM
      ? t("OrderManagement:updateOrderCTA")
      : t("OrderManagement:saveBundleItemButton", {
          categoryName: categoryName?.toUpperCase() ?? "",
        });

  const price = useMemo((): number => {
    if (!cartItems) {
      return 0;
    }
    return CartItemsCalculator.calculateItemUnitPrice(cartItems, 0);
  }, [cartItems]);

  const onEditPress = () => {
    onEditOrder({
      index,
      cartItems: cartItems || [],
      type: ModifyOrderType.UPDATE,
    });
  };

  const onAddPress = () => {
    onAddToOrder({
      type: ModifyOrderType.ADD,
      cartItems: cartItems || [],
    });
  };

  const ButtonContainer = Platform.OS === "web" ? React.Fragment : SafeAreaView;

  return editMode === ProductConst.EditMode.ADD_CART_ITEM ? (
    <PrimaryBlackButton
      border={true}
      price={price === 0 ? undefined : price}
      onClick={onAddPress}
      applySafeArea={Platform.OS !== "web"}
      disable={cartItems === undefined || cartItems?.length === 0}
      disguisedDisable={alwaysEnabled}
      buttonName={addButtonTitle || t("OrderManagement:addProductToCart")}
      testId='PrimaryAddItemToCartButton'
    />
  ) : (
    <ButtonContainer>
      <PrimaryYellowButton
        border
        onClick={onEditPress}
        disable={cartItems === undefined || cartItems?.length === 0}
        buttonName={buttonName}
        testId='PrimaryUpdateItemToCartButton'
      />
    </ButtonContainer>
  );
};

export default AddModifyCartItem;
