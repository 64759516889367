import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthReduxAction, AuthStateModels, RootState } from "gyg_common";
import { Screens } from "navigation/const";
import ResetPassword, {
  STATUS,
} from "views/components/ResetPassword/ResetPassword";

const ResetPasswordContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { code, isLinkValid } = queryString.parse(search, {
    parseBooleans: true,
  });
  const [resetStatus, setResetStatus] = useState<STATUS>();

  const { isResetPasswordSuccess, resetPasswordError, resetPasswordLoading } =
    useSelector((s: RootState) => s.login);

  const onResetPassword = (
    resetPasswordRequest: AuthStateModels.ResetPasswordPayload
  ) => {
    dispatch(AuthReduxAction.resetPassword(resetPasswordRequest));
  };

  const onRetry = () => {
    if (resetStatus === STATUS.ERROR) {
      navigate(Screens.Dashboard);
    } else if (resetStatus === STATUS.EXPIRED) {
      navigate(Screens.ForgotPassword);
    } else {
      navigate(Screens.Login, {
        state: { loginNavigatesTo: Screens.Dashboard },
      });
    }
  };

  useEffect(() => {
    if (isLinkValid === false) {
      setResetStatus(STATUS.EXPIRED);
    }
  }, [isLinkValid]);

  useEffect(() => {
    if (isResetPasswordSuccess) {
      setResetStatus(STATUS.SUCCESS);
    }
  }, [isResetPasswordSuccess]);

  useEffect(() => {
    if (resetPasswordError) {
      setResetStatus(STATUS.ERROR);
    }
  }, [resetPasswordError]);

  return (
    <ResetPassword
      loading={resetPasswordLoading}
      error={resetPasswordError}
      status={resetStatus}
      code={code as string}
      onRetry={onRetry}
      onChangePassword={onResetPassword}
    />
  );
};

export default ResetPasswordContainer;
