import axios from "axios";

import config from "../../../config";
import {
  AuthProps,
  CurrentUser,
  LoginOTPChallenge,
  LoginOTPChallengeResponse,
  LoginOTPChannel,
  LoginOTPVerify,
  RefreshTokenPayload,
  ResetPasswordPayload,
} from "../../../redux_store/auth/models";
import { httpClient } from "../../httpClient";
import {
  ForgotPasswordPayload,
  ForgotPasswordResponse,
  LogoutAllResponse,
  RefreshTokenResposne,
  ResetPasswordResponse,
} from "./models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOTPChallenge(obj: any): obj is LoginOTPChallenge {
  return (obj as LoginOTPChallenge).mfaToken !== undefined;
}

export async function login(
  userData: AuthProps
): Promise<CurrentUser | LoginOTPChallenge> {
  try {
    const response = await httpClient({
      url: "auth/signin",
      method: "POST",
      data: userData,
      useFQDN: true,
    });

    if (isOTPChallenge(response.data)) {
      return response.data as LoginOTPChallenge;
    } else {
      const responseData = response.data as CurrentUser;
      config.accessToken = responseData.accessToken;
      config.refreshToken = responseData.refreshToken;

      return responseData;
    }
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/signin");
    throw e;
  }
}

export async function getLoginOTPCode(userData: {
  mfaToken: string;
  channel?: LoginOTPChannel; // Use the server default
  scope: string;
  deviceId?: string;
}): Promise<LoginOTPChallengeResponse> {
  try {
    const response = await httpClient({
      url: `auth/mfa/send`,
      method: "POST",
      data: userData,
    });

    return response.data as LoginOTPChallengeResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/mfa/send");
    throw e;
  }
}

export async function verifyLoginOTPCode(
  userData: LoginOTPVerify
): Promise<CurrentUser> {
  try {
    const response = await httpClient({
      url: `auth/mfa/verify`,
      method: "POST",
      data: userData,
    });

    const responseData = response.data as CurrentUser;
    config.accessToken = responseData.accessToken;
    config.refreshToken = responseData.refreshToken;
    return responseData;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/mfa/verify");
    throw e;
  }
}

export async function getNewToken(
  data: RefreshTokenPayload
): Promise<RefreshTokenResposne> {
  try {
    // console.debug("API: POST auth/refreshToken");
    // We have to use axios.post due to the required skipAuthRefresh for the refresh handling.
    // See https://github.com/Flyrell/axios-auth-refresh#pause-the-instance-while-refresh-logic-is-running
    const response = await axios.post(
      `${config.api.host}/auth/refreshToken`,
      { ...data },
      {
        timeout: config.api.timeout,
        headers: {
          "Content-Type": "application/json",
          "x-gyg-session-id": config.sessionId,
        },
        // eslint-disable-next-line
        // @ts-ignore
        skipAuthRefresh: true,
      }
    );

    return response.data as RefreshTokenResposne;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/refreshToken");
    throw e;
  }
}

export async function logoutAll(): Promise<LogoutAllResponse> {
  try {
    // console.debug("API: GET auth/logoutAll");

    const userToken = config.accessToken;
    const response = await httpClient({
      url: `auth/logoutAll`,
      method: "GET",
      headers: {
        Authorization: `${userToken}`,
      },
    });
    return response.data as LogoutAllResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "GET auth/logoutAll");
    throw e;
  }
}

export async function forgotPassword(
  data: ForgotPasswordPayload
): Promise<ForgotPasswordResponse> {
  try {
    // console.debug("API: POST auth/forgotpassword");

    const response = await httpClient({
      url: `auth/forgotpassword`,
      method: "POST",
      data: data,
    });
    return response.data as ForgotPasswordResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/forgotpassword");
    throw e;
  }
}

export async function resetPassword(
  data: ResetPasswordPayload
): Promise<ResetPasswordResponse> {
  try {
    // console.debug("API: POST auth/confirm");

    const response = await httpClient({
      url: `auth/confirm`,
      method: "POST",
      data: {
        code: data.code,
        newPassword: data.newPassword,
      },
    });
    return response.data as ResetPasswordResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/confirm");
    throw e;
  }
}
