import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  LoyaltyActions,
  LoyaltyModels,
  MenuActions,
  NavigationReduxAction,
  RootState,
} from "gyg_common";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { Screens } from "navigation/const";
import { RewardsModal } from "views/components/Rewards/RewardsModal/RewardsModal";

interface IRewardsModalContainerProps extends LoyaltyModels.IRewards {
  rewardsDetailModalVisible: boolean;
  closeModal: () => void;
}

export const RewardsModalContainer: React.FC<IRewardsModalContainerProps> = ({
  closeModal,
  rewardsDetailModalVisible,
  ...reward
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );

  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const coffeeCouponName = "LOYALTY COFFEE";

  const openTermsAndConditions = () => {
    if (reward.terms.url) {
      window.open(reward.terms.url, "_blank");
    }
  };

  const onOrderNowPressed = () => {
    if (reward.name !== coffeeCouponName) {
      dispatch(LoyaltyActions.updateActiveReward(reward));

      if (reward.link) {
        window.open(reward.link, "_blank");
      }
    } else {
      if (userConfirmedOrderSetup) {
        navigate(Screens.Menu);
      } else {
        if (hasDelivery) {
          dispatch(NavigationReduxAction.setOpenOrderSetup(true));
        } else {
          navigate(Screens.Restaurants);
        }
      }
      dispatch(MenuActions.needScrollToHotDrinks(true));
    }
  };

  return (
    <RewardsModal
      {...reward}
      closeModal={closeModal}
      mainButtonName={t("CheckoutPayment:orderBtnLabel")}
      rewardsDetailModalVisible={rewardsDetailModalVisible}
      onMainButtonPressed={onOrderNowPressed}
      openTermsAndConditions={openTermsAndConditions}
    />
  );
};

export default RewardsModalContainer;
