import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { MenuActions } from "gyg_common";

export interface TabMenuProps {
  tabNames: { name: string; id: number }[];
  activeTab: string;
  scrollToSelectedMenuSection(
    selectedTabName: string,
    fromTabClick: boolean
  ): void;
  blockActiveTabScrollUpdate(value: boolean): void;
}

const scrollToTab = (selectedTabName: string) => {
  const tab = document.getElementById(`Tab-${selectedTabName}`);
  const headerWhite = document.getElementById("scrollable-header-white");
  if (tab && headerWhite) {
    // 16px is the `.header__bottom` padding spacing size
    // Removing the 16px to avoid the padding from blocking the active tab
    headerWhite.scrollTo(tab.offsetLeft - 16, 0);
  }
};

const TabMenu = (props: TabMenuProps): JSX.Element => {
  const {
    tabNames,
    activeTab,
    scrollToSelectedMenuSection,
    blockActiveTabScrollUpdate,
  } = props;
  const dispatch = useDispatch();

  const onTabClicked = (selectedTabName: string) => {
    blockActiveTabScrollUpdate(true);
    if (selectedTabName !== activeTab) {
      dispatch(MenuActions.setActiveTab(selectedTabName));
    }
    scrollToSelectedMenuSection(selectedTabName, true);
    scrollToTab(selectedTabName);
  };

  useEffect(() => {
    scrollToTab(activeTab);
  }, [activeTab]);

  return (
    <div data-testid='TabMenu' className='menuTab'>
      <div className='menuTab__container'>
        {tabNames.map((tabName: { name: string; id: number }) => {
          return (
            <button
              id={`Tab-${tabName.name}-${tabName.id}`}
              data-testid={
                `${tabName.name}-${tabName.id}` === activeTab
                  ? `ActiveTab-${tabName.name}`
                  : `Tab-${tabName.name}`
              }
              key={`${tabName.name}-${tabName.id}`}
              onClick={() => onTabClicked(`${tabName.name}-${tabName.id}`)}
              className={
                `${tabName.name}-${tabName.id}` === activeTab
                  ? "menuTab__btn--active"
                  : "menuTab__btn"
              }>
              {tabName.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TabMenu;
