import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import {
  ImageBackground,
  ImageResizeMode,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { PlatformUtils } from "gyg_common";
import CardSticker from "gyg_common/components/CardSticker";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = (isTabletScreen?: boolean | undefined) =>
  StyleSheet.create({
    statusBar: {
      backgroundColor: colours.darkGrey,
      flex: isTabletScreen ? 0.005 : 0.025,
    },
    cardContainer: {
      flexDirection: "row",
      height: isTabletScreen ? 280 : 180,
    },
    textStyle: {
      ...Typography.captionOne,
      marginTop: 8,
      textAlign: "right",
    },
    imageStyle: {
      borderRadius: 8,
    },
  });

export interface SliderCardProps {
  imageWideUrl?: string;
  imageUrl?: string;
  showStatus?: boolean;
  caption?: string;
  testId?: string;
  onClick: () => void;
  resizeMode?: ImageResizeMode | undefined;
}

// TODO: This component not shared - change to non react-native impl
const SliderCard: React.FC<SliderCardProps> = ({
  imageWideUrl,
  imageUrl,
  caption,
  showStatus = true,
  testId = "SliderCard",
  onClick,
  resizeMode,
}) => {
  const [activeImage, setActiveImage] = useState<string | null>();
  const { isTabletScreen } = useMediaQuery();

  useEffect(() => {
    if (isTabletScreen) {
      setActiveImage(imageWideUrl ? imageWideUrl : imageUrl ? imageUrl : null);
    } else {
      setActiveImage(imageUrl ? imageUrl : null);
    }
  }, [imageUrl, imageWideUrl, isTabletScreen]);

  return (
    <View>
      <CardSticker>
        <Pressable
          {...PlatformUtils.generateTestID(Platform.OS, testId)}
          onPress={onClick}>
          {activeImage && (
            <ImageBackground
              style={styles(resizeMode ? true : isTabletScreen).cardContainer}
              source={activeImage as ImageSourcePropType}
              imageStyle={styles().imageStyle}
              resizeMode={resizeMode ?? "cover"}>
              {showStatus && (
                <View
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "SliderCardStatus"
                  )}
                  style={styles(isTabletScreen).statusBar}
                />
              )}
            </ImageBackground>
          )}
        </Pressable>
      </CardSticker>
      {caption && (
        <Text
          {...PlatformUtils.generateTestID(Platform.OS, "SliderCardCaption")}
          style={styles().textStyle}>
          {caption}
        </Text>
      )}
    </View>
  );
};

export default SliderCard;
