import React from "react";
import { useTranslation } from "react-i18next";

import { MenuModels } from "gyg_common";
import MenuListItem from "gyg_common/components/Menu/MenuListItem";

export interface UpsellSectionProps {
  upsellItems: MenuModels.MenuListItemProps[];
  onItemPress: (menuItemProp: MenuModels.MenuListItemProps) => void;
  smallCards?: boolean;
}

export const UpsellSection: React.FC<UpsellSectionProps> = ({
  upsellItems,
  onItemPress,
  smallCards,
}) => {
  const { t } = useTranslation();

  const groupMenuItems = (
    items: MenuModels.MenuListItemProps[],
    groupBy: number
  ) =>
    items.reduce(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        previousValue: any,
        currentValue: MenuModels.MenuListItemProps,
        currentIndex: number
      ) => {
        const idx = Math.floor(currentIndex / groupBy);
        previousValue[idx] = [...(previousValue[idx] || []), currentValue];
        return previousValue;
      },
      []
    );

  const renderItems = () => {
    return groupMenuItems(upsellItems, 2).map(
      (children: MenuModels.MenuListItemProps[], groupIndex: number) => {
        return (
          <div key={"Upsell-List-Group-" + groupIndex} className='list-group'>
            {children.map((item, index) => {
              return (
                <div key={index} className={"row"}>
                  <MenuListItem
                    key={index}
                    onPress={() => onItemPress(item)}
                    category={item.category}
                    product={item.product}
                    multiPart={item.multiPart}
                    smallCard={smallCards}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    );
  };

  const renderTitle = () => {
    const title = t("OrderManagement:upsellTitle");
    const modifiedTitle =
      title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
    return <p className='upsell-cart__title'>{modifiedTitle}</p>;
  };

  return (
    <div className='upsell-cart'>
      {renderTitle()}
      {renderItems()}
    </div>
  );
};
