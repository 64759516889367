import React from "react";
import { Image, ImageStyle } from "react-native";
import { styles } from "./GYGPointsIcon.styles";

interface GYGPointsIconProps {
  scale?: 1 | 2 | 3;
  style?: ImageStyle;
}

const GYGPointsIcon: React.FC<GYGPointsIconProps> = ({ scale = 1, style }) => {
  return (
    <Image
      style={[styles.icon, style]}
      source={
        scale === 1
          ? require("../../assets/icons/icon_points.png")
          : require(`../../assets/icons/icon_points@${scale}x.png`)
      }
    />
  );
};

export default GYGPointsIcon;
