import "./styles.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { Typography } from "styles/typography";
import { CheckboxWithTitle } from "views/components/Notifications/CheckboxWithTitle/CheckboxWithTitle";

import { styles } from "./styles";

export interface INotificationsProps {
  onSaveButtonPressed: () => void;
  isSaveButtonEnabled: boolean;
  receiveEmails: boolean;
  receivePushNotifications: boolean;
  receiveSMS: boolean;
  setReceiveSMS: React.Dispatch<React.SetStateAction<boolean>>;
  setReceivePushNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  setReceiveEmails: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Notifications: React.FC<INotificationsProps> = ({
  onSaveButtonPressed,
  isSaveButtonEnabled,
  receiveEmails,
  receivePushNotifications,
  receiveSMS,
  setReceiveEmails,
  setReceivePushNotifications,
  setReceiveSMS,
}) => {
  const { t } = useTranslation();

  return (
    <div className='main-wrapper main-wrapper--skinny notifications__container'>
      <Text style={styles.title}>{t("Notifications:promotionalContent")}</Text>
      <Text style={Typography.body}>
        {t("Notifications:promotionsDescription")}
      </Text>
      <CheckboxWithTitle
        onCheckboxPressed={setReceiveEmails}
        checkboxState={receiveEmails}
        title={t("Notifications:email")}
      />
      <CheckboxWithTitle
        onCheckboxPressed={setReceivePushNotifications}
        checkboxState={receivePushNotifications}
        title={t("Notifications:pushNotifications")}
        description={t("Notifications:pushNotificationsDescription")}
      />
      <CheckboxWithTitle
        onCheckboxPressed={setReceiveSMS}
        checkboxState={receiveSMS}
        title={t("Notifications:smsNotifications")}
      />
      <View style={styles.buttonWrapper}>
        <PrimaryBlackButton
          buttonName={t("Notifications:saveLabel")}
          onClick={onSaveButtonPressed}
          disable={!isSaveButtonEnabled}
        />
      </View>
    </div>
  );
};
