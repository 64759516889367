import React, { useEffect, useRef } from "react";
import { Platform, StyleSheet, View } from "react-native";

import { useLottieWeb } from "gyg_common/hooks";

import { generateTestID } from "../../modules/platformUtils";
import flyingBurrito from "../assets/gif/refresh.json";
import { Spacing } from "../styles/number";

export const styles = StyleSheet.create({
  lottieWrapper: {
    paddingTop: Spacing.Light,
    paddingBottom: Spacing.ExtraLight,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  loader: {
    height: 50,
  },
});

export const FlyingBurrito: React.FC = () => {
  const lottieRef = useRef<HTMLDivElement>(null);
  const { lottie } = useLottieWeb();

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.loadAnimation({
      name: "flyingBurrito",
      container: lottieRef.current,
      renderer: "canvas",
      loop: true,
      autoplay: true,
      animationData: flyingBurrito,
    });
  }, [lottie]);

  return (
    <View style={styles.lottieWrapper}>
      <div style={styles.loader} ref={lottieRef} />
    </View>
  );
};

export default FlyingBurrito;
