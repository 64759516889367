import sign from "jwt-encode";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import config, { BASE_URL } from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AuthReduxAction,
  OrderModules,
  RootState,
} from "gyg_common";
import { ActiveTabType } from "gyg_common/components/ToggleSelect";
import { LoginOTPChannel } from "gyg_common/redux_store/auth/models";
import { ErrorResponse } from "gyg_common/redux_store/error/models";
import { getLoginOTPCode } from "gyg_common/services/api/auth";
import { ScreenParams, Screens } from "navigation/const";
import Login from "views/components/Auth/Login";

import { getDeviceId } from "@/RouterObserver/WebAuthObsever";

export enum AuthMode {
  LOGIN = "login",
  REGISTER = "signin",
}
export interface LoginParams {
  [ScreenParams.AUTH]: AuthMode;
}

export enum SocialButtonType {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
}

// interface LoginRouterState {
//   loginNavigatesTo?: Screens;
// }

const deviceId = getDeviceId();

const LoginContainer: React.FC = () => {
  const { authMode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { orders } = useSelector((s: RootState) => s.guest);
  const { guestOrderId, addToAccountLoading } = useSelector(
    (s: RootState) => s.order
  );
  const {
    isLoading,
    isAuthSuccess,
    currentUser,
    isLogoutAllError,
    loginErrorResponse,
    requiresOTP,
    mfaToken,
    mfaError,
    otpSubmittedLoading,
  } = useSelector((s: RootState) => s.login);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const [isSendingOTP, setIsSendingOTP] = useState(false);

  const [selectedMode, setSelectedMode] = useState<ActiveTabType>(
    ActiveTabType.LEFT
  );

  const updateMode = (activeTab: ActiveTabType) => {
    if (activeTab === ActiveTabType.LEFT) {
      setSelectedMode(ActiveTabType.RIGHT);
    } else {
      setSelectedMode(ActiveTabType.LEFT);
    }
  };

  const goToRegister = () => {
    setSelectedMode(ActiveTabType.RIGHT);
  };

  const onProceedAsGuest = () => {
    navigate(Screens.Dashboard);
  };

  const onForgotPassword = () => {
    navigate(Screens.ForgotPassword);
  };

  const [sentLoginOTPChannel, setSentChosenLoginOTPChannel] =
    useState<LoginOTPChannel>();

  const [sentLoginOTPSource, setSentLoginOTPSource] = useState<string>();

  const sendOTP = useCallback(
    async (requestedLoginOTPChannel?: LoginOTPChannel) => {
      dispatch(AuthReduxAction.setOTPError(null));
      setIsSendingOTP(true);
      if (mfaToken) {
        try {
          const { source, channel } = await getLoginOTPCode({
            scope: "login",
            channel: requestedLoginOTPChannel,
            deviceId,
            mfaToken,
          });

          setSentChosenLoginOTPChannel(channel);
          setSentLoginOTPSource(source);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          const errorResponse: ErrorResponse = {
            ...e.response?.data,
            statusCode: e.response?.status,
          };
          dispatch(AuthReduxAction.setOTPError(errorResponse));
        }
      }
      setIsSendingOTP(false);
    },
    [dispatch, mfaToken]
  );

  useEffect(() => {
    if (requiresOTP) {
      sendOTP();
    }
  }, [requiresOTP, sendOTP]);

  const onLoginPress = (username: string, password: string) => {
    const authRequestBody = {
      username: username,
      password: password,
      deviceId,
    };
    if (!!loginErrorResponse) dispatch(AuthReduxAction.clearError());
    dispatch(AuthReduxAction.login(authRequestBody));
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.LoginWithEmail, {});
  };

  const onSocialLogin = (
    type: SocialButtonType,
    loginType: AnalyticsConst.LoginType
  ) => {
    AnalyticsInstance.trackEvent(
      type === SocialButtonType.LOGIN
        ? AnalyticsConst.Events.LoginThirdParty
        : AnalyticsConst.Events.Registration,
      {
        login_type: loginType,
      }
    );

    window.location.replace(
      `${BASE_URL}/auth/socialStart?redirect_uri=https://${window.location.hostname}/social&identity_provider=${loginType}`
    );

    localStorage.setItem("GuestOrderId", guestOrderId ?? "");
    localStorage.setItem("NavigatesTo", state?.loginNavigatesTo ?? "");
  };

  const onCloseErrorAlert = () => {
    dispatch(AuthReduxAction.clearError());
  };

  const addOrdersToAccount = useCallback(() => {
    if (orders.length && !addToAccountLoading) {
      OrderModules.OrderUtils.addOrdersToAccount(store, orders);
    }
  }, [orders, addToAccountLoading]);

  const navigateAwayFromLogin = useCallback(() => {
    if (state?.loginNavigatesTo) {
      navigate(state.loginNavigatesTo);
    } else {
      navigate(Screens.Dashboard);
    }
  }, [navigate, state?.loginNavigatesTo]);

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "Login",
        page_type: "login_view",
      });
    }
  }, [isBrazeInitialised]);

  // When login success close this login screen
  useEffect(() => {
    if (isAuthSuccess) {
      if (currentUser) {
        const secret = config.jwtSecret;
        const jwt = sign(currentUser, secret);

        localStorage.setItem("CurrentUser", jwt);

        /** TODO: To be implemented later to handle starting braze session for logged in user
         * appboy.changeUser("morris.mendoza@tigerspike.com");
         appboy.openSession();
         if (appboy.isPushSupported()) {
            console.log("Push is supported!");
          }
         else {
            console.log("Push is not supported!");
          }
         appboy.registerAppboyPushMessages();
         */
        if (guestOrderId && orders.length > 0) {
          addOrdersToAccount();
        }
      }

      navigateAwayFromLogin();
    }
  }, [
    isAuthSuccess,
    currentUser,
    orders,
    guestOrderId,
    navigateAwayFromLogin,
    addOrdersToAccount,
  ]);

  /**
   * Sets active toggle tab based on passed parameter from navigation.
   */
  useEffect(() => {
    if (authMode) {
      if (authMode === AuthMode.LOGIN) {
        setSelectedMode(ActiveTabType.LEFT);
      }
      if (authMode === AuthMode.REGISTER) {
        setSelectedMode(ActiveTabType.RIGHT);
      }
    }
  }, [authMode]);

  const handleOnSubmitOTP = (OTP: string) => {
    if (mfaToken) {
      dispatch(
        AuthReduxAction.verifyLoginOTP({
          code: OTP,
          deviceId,
          mfaToken,
          scope: "login",
        })
      );
    }
  };

  // Reset MFA challenge if arriving on this screen
  useEffect(() => {
    dispatch(AuthReduxAction.setDoesNotRequireOTP());
  }, [dispatch]);

  return (
    <Login
      onLoginPress={onLoginPress}
      onSocialLogin={onSocialLogin}
      onGoRegister={goToRegister}
      onGoAsGuest={onProceedAsGuest}
      loading={isLoading}
      isLogoutAllError={isLogoutAllError}
      loginErrorResponse={loginErrorResponse}
      onForgotPassword={onForgotPassword}
      updateMode={(activeToggle) => updateMode(activeToggle)}
      selectedMode={selectedMode}
      onCloseErrorAlert={onCloseErrorAlert}
      onSubmitOTP={handleOnSubmitOTP}
      requiresOTP={requiresOTP}
      onOTPResend={sendOTP}
      onChangeVerificationMethod={sendOTP}
      sentLoginOTPChannel={sentLoginOTPChannel}
      sentLoginOTPSource={sentLoginOTPSource}
      isSendingOTP={isSendingOTP}
      mfaError={mfaError}
      otpSubmittedLoading={otpSubmittedLoading}
    />
  );
};

export default LoginContainer;
