import "./styles.scss";

import branch from "branch-sdk";
import sign from "jwt-encode";
import queryString from "query-string";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";
import { v4 as uuidv4 } from "uuid";

import config, { BASE_URL } from "config";
import {
  AnalyticsConst,
  AuthReduxAction,
  BranchConst,
  OrderModules,
  OrderReduxAction,
  RootState,
  SocialActions,
  UserReduxAction,
} from "gyg_common";
import ErrorView from "gyg_common/components/Error/ErrorView";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { Screens } from "navigation/const";
import { AuthMode } from "views/containers/Auth/LoginContainer";

import HeaderContainer from "../shared/Header/HeaderContainer";

import { getDeviceId } from "@/RouterObserver/WebAuthObsever";

export default function SocialContainer(): JSX.Element {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const dispatch = useDispatch();

  const { currentSocialUser, isSocialRegister, isError } = useSelector(
    (s: RootState) => s.social
  );
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const { addToAccountLoading } = useSelector((s: RootState) => s.order);
  const { orders } = useSelector((s: RootState) => s.guest);

  const { code } = queryString.parse(search);
  const secret = config.jwtSecret;
  const currentOrderId = localStorage.getItem("GuestOrderId");
  const navigatesTo = localStorage.getItem("NavigatesTo");

  const { profile } = useSelector((state: RootState) => state.user);

  const socialSignIn = (token: string, redirectUri: string) => {
    // this device ID is only used when the social auth flow doesn't result
    // in user completing onboarding (ie add phone number details in registration step)
    // - which happens when the user mobile verification is already handled by social media platform
    const deviceId = getDeviceId() || uuidv4();
    const socialRequestBody = {
      token: token,
      redirectUri: redirectUri,
      deviceId,
    };

    dispatch(SocialActions.getSocialSignIn(socialRequestBody));
  };

  const addOrdersToAccount = useCallback(() => {
    if (!addToAccountLoading) {
      OrderModules.OrderUtils.addOrdersToAccount(store, orders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders.length, addToAccountLoading]);

  useEffect(() => {
    if (code) {
      dispatch(SocialActions.addSocialCode(code as string));
      socialSignIn(
        `${code}`,
        `${window.location.protocol}//${window.location.hostname}/social`
      );
    } else {
      navigate(Screens.Dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const logoutGoogleUser = () => {
    if (profile?.authenticationSourceType === AnalyticsConst.LoginType.Google) {
      window.location.replace(
        `${BASE_URL}/auth/socialLogout?redirect_uri=https://${window.location.hostname}/authenticate`
      );
    }
  };

  useEffect(() => {
    if (isSocialRegister || (profile && !profile.registrationComplete)) {
      //clear current user if registration is not completed as we are preventing to sign up with web
      UserReduxAction.setShowMobileVerifyModal(false);
      localStorage.removeItem("CurrentUser");
      dispatch(AuthReduxAction.logoutCurrentDevice());
      logoutGoogleUser();

      //move user to QR code scanning screen
      setTimeout(() => {
        navigate(`${Screens.Login}/${AuthMode.REGISTER}`, {
          state: {
            registrationNavigatesTo: Screens.Dashboard,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocialRegister, profile]);

  const navigateAwayFromSocial = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    navigatesTo
      ? navigate(navigatesTo, { state: { previousScreen: pathname } })
      : navigate(Screens.Dashboard);
  };

  useEffect(() => {
    if (isAuthSuccess) {
      if (currentSocialUser) {
        localStorage.removeItem("CurrentUser");
        const jwt = sign(currentSocialUser, secret);
        localStorage.setItem("CurrentUser", jwt);

        branch.logEvent(BranchConst.BranchEvents.CompleteRegistration, {
          branchId: currentSocialUser?.branchId ?? "",
        });

        // uses currentOrderId from local storage
        if (currentOrderId && orders.length > 0) {
          dispatch(OrderReduxAction.updateCurrentOrderId(currentOrderId));
          addOrdersToAccount();
        }
      }
      navigateAwayFromSocial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthSuccess, currentSocialUser, currentOrderId, orders.length]);

  /**
   * Saves currentOrderId back in redux state from local storage
   * in case of claiming Gomex points in social register.
   */
  useEffect(() => {
    if (currentOrderId) {
      dispatch(OrderReduxAction.updateCurrentOrderId(currentOrderId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrderId]);

  return (
    <>
      {!isError ? (
        <LoadingScreen loading />
      ) : (
        <div className='social'>
          <HeaderContainer />
          <div className='main-wrapper'>
            <ErrorView message={isError.message} heading={isError.heading} />
          </div>
        </div>
      )}
    </>
  );
}
