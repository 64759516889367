import cartIcon from "assets/icons/icon_cart.png";
import orderIcon from "assets/images/order.png";
import orderNowIcon from "assets/images/order_now.png";
import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { CartReduxModels, getFormattedPrice, PlatformUtils } from "gyg_common";
import Spinner from "gyg_common/components/Buttons/Spinner";
import CardSticker from "gyg_common/components/CardSticker";
import colours from "styles/colours";
import { Typography } from "styles/typography";

interface StyleProps extends CartYellowButtonProps {
  hovered?: boolean;
  pressed?: boolean;
}

const styles = (props: StyleProps) =>
  StyleSheet.create({
    orderNowIcon: {
      width: 145,
      height: 20,
      resizeMode: "cover",
    },
    orderIcon: {
      width: 85,
      height: 20,
      resizeMode: "cover",
    },
    cartIcon: {
      width: 32,
      height: 32,
      resizeMode: "cover",
    },
    container: {
      flexDirection: "row",
      borderRadius: 8,
      backgroundColor: props.pressed
        ? colours.starburstOpacity
        : props.hovered
          ? colours.starburst
          : colours.yellow,
      paddingVertical: 6,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingHorizontal: 8,
      justifyContent: "center",
      alignItems: "center",
    },
    textStyle: {
      ...Typography.titleThree,
      fontSize: 18,
      flex: 1,
      color: colours.darkGrey,
      textAlign: "left",
      marginLeft: 12,
    },
    leftView: {
      flex: 1,
      justifyContent: "flex-start",
      flexDirection: "row",
      alignItems: "center",
    },
    rightText: {
      ...Typography.btnPrice,
      flex: 0.25,
      justifyContent: "flex-end",
      color: colours.darkGrey,
      textAlign: "right",
      marginLeft: 24,
    },
    spinnerContainer: {
      marginLeft: 32,
      width: 32,
      height: 32,
    },
    badge: {
      position: "absolute",
      paddingHorizontal: 5,
      backgroundColor: colours.white,
      borderRadius: 4,
      left: 20,
      top: -3,
    },
    orderNowView: {
      paddingHorizontal: 8,
      paddingVertical: 5,
    },
  });

export interface CartYellowButtonProps {
  buttonName: string;
  items: CartReduxModels.CartItem[];
  price?: number;
  shadow?: boolean;
  onClick?: () => void;
  showIconAndPrice?: number | false | null;
  loading?: boolean;
}

// TODO: This component not shared - change to non react-native impl
export default function CartYellowButton(
  props: CartYellowButtonProps
): JSX.Element {
  const getQuantity = (): string => {
    let quantity = 0;
    props.items.forEach((i) => {
      quantity += i.quantity;
    });
    return quantity.toString();
  };

  const { isTabletScreen } = useMediaQuery();

  return (
    <CardSticker>
      <Pressable
        {...PlatformUtils.generateTestID(Platform.OS, "cart-yellow-button")}
        onPress={props.onClick}
        style={({ hovered, pressed }) =>
          styles({ ...props, hovered, pressed }).container
        }>
        <View style={styles(props).buttonContainer}>
          {getQuantity() === "0" && !props.showIconAndPrice ? (
            <View style={styles(props).orderNowView}>
              {isTabletScreen ? (
                <Image
                  style={styles(props).orderNowIcon}
                  source={orderNowIcon as ImageSourcePropType}
                />
              ) : (
                <Image
                  style={styles(props).orderIcon}
                  source={orderIcon as ImageSourcePropType}
                />
              )}
            </View>
          ) : (
            <>
              <View style={styles(props).leftView}>
                <Image
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "cart-yellow-image"
                  )}
                  style={styles(props).cartIcon}
                  source={cartIcon as ImageSourcePropType}
                />
                <View style={styles(props).badge}>
                  <Text style={Typography.badgeText}>{getQuantity()}</Text>
                </View>

                <Text numberOfLines={1} style={styles(props).textStyle}>
                  {props.buttonName}
                </Text>
              </View>
              {props.loading ? (
                <View style={styles(props).spinnerContainer}>
                  <Spinner color={colours.black} />
                </View>
              ) : (
                props.price && (
                  <Text style={styles(props).rightText}>
                    {getFormattedPrice(props.price)}
                  </Text>
                )
              )}
            </>
          )}
        </View>
      </Pressable>
    </CardSticker>
  );
}
