import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Keyboard,
  Platform,
  StyleSheet,
  Switch,
  Text,
  View,
} from "react-native";

import { InputType } from "../../modules/Components/Shared/InputType.enum";
import { validateGYGdollarsAmount } from "../../modules/Order/utils";
import * as PlatformUtils from "../../modules/platformUtils";
import SecondaryButton from "../Buttons/SecondaryButton";
import CoinAnimation from "../CoinAnimation";
import FormInput from "../FormInput";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  itemWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputTitle: {
    ...Typography.bodyTwo,
    color: colours.darkGrey,
    fontSize: 14,
  },
  inputTitleError: {
    ...Typography.bodyTwo,
    color: colours.red,
    fontSize: 14,
  },
  itemText: {
    ...Typography.body,
    paddingRight: Spacing.Thin,
    flexGrow: 1,
  },
  availableDollars: {
    ...Typography.captionOne,
    marginRight: Spacing.Light,
  },
  form: {
    borderTopColor: colours.lightGrey,
    borderTopWidth: 1,
    paddingTop: Spacing.Thin,
    marginTop: Spacing.ExtraLight,
  },
  row: {
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginTop: Spacing.Thin,
  },
  switchWrapper: {
    marginTop: -Spacing.Regular,
  },
  inputWrapper: {
    flex: 1,
  },
  button: {
    alignContent: "center",
    justifyContent: "center",
    marginLeft: Spacing.Light,
    minWidth: 100, //TODO: only app
    height: 44,
  },
  coinContainer: {
    height: 30,
    width: 30,
  },
});

export interface PayWithGomexProps {
  dollars: number;
  total: number;
  totalBeforeDiscount: number;
  isLoading: boolean;
  redeemDollars?: number | null;
  redeemDollarsBalance?: number | null;
  onRedeemDollars: (value: number | null) => void;
  errorMessage?: string;
}

const PayWithGomex: React.FC<PayWithGomexProps> = ({
  dollars,
  total,
  totalBeforeDiscount,
  isLoading,
  redeemDollars,
  redeemDollarsBalance,
  onRedeemDollars,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const [selectRedeem, setSelectRedeem] = useState<boolean>(
    redeemDollars ? redeemDollars > 0 : false
  );
  const [value, setValue] = useState<string>(
    redeemDollars ? redeemDollars.toFixed(2) : dollars.toFixed(2)
  );
  const [error, setError] = useState<boolean>(false);
  const [pressedButton, setPressedButton] = useState(false);
  const [changedText, setChangedText] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState<string>(
    errorMessage || ""
  );

  const onChange = (changedGygDollarsAmount: string) => {
    const numberValue = parseFloat(
      parseFloat(changedGygDollarsAmount).toFixed(2)
    );
    const diff = dollars - numberValue;
    const remainingDollars = isNaN(diff) ? dollars : diff;

    //not allow to enter value more than exist for user
    if (remainingDollars < 0) {
      setValue(dollars.toString());
    } else {
      setValue(changedGygDollarsAmount);
    }
    setChangedText(true);
  };

  const onApply = useCallback(() => {
    const numberValue = parseFloat(parseFloat(value).toFixed(2));
    const isValidAmount = validateGYGdollarsAmount(
      value,
      redeemDollarsBalance,
      totalBeforeDiscount
    );
    if (!isValidAmount.isError) {
      onRedeemDollars(numberValue);
      setError(false);
      Keyboard.dismiss();
    } else {
      setInputErrorMessage(t(isValidAmount.errMsg));
      setError(true);
    }
  }, [onRedeemDollars, redeemDollarsBalance, t, totalBeforeDiscount, value]);

  const onPressButton = useCallback(() => {
    setPressedButton(true);
    onApply();
  }, [onApply]);

  const onActivation = useCallback(() => {
    setPressedButton(false);
    setChangedText(false);
  }, []);

  const onSelectRedeem = useCallback(() => {
    if (!isLoading) {
      setSelectRedeem(!selectRedeem);
      if (!selectRedeem && Math.min(dollars, total) > 0) onApply();
    }
  }, [dollars, isLoading, onApply, selectRedeem, total]);

  /**
   * Sets placeholder value in input.
   */
  useEffect(() => {
    if (!redeemDollars) {
      // displays proposed $ value to redeem
      setValue(Math.min(dollars, total).toFixed(2));
    } else {
      // displays applied $ value
      setValue(redeemDollars.toFixed(2));
    }
    setError(false);
    Keyboard.dismiss();
  }, [dollars, total, redeemDollars]);

  /**
   * Set error
   */
  useEffect(() => {
    setError(!!errorMessage);
  }, [errorMessage]);

  /**
   * Clears redux state of amount of applied $
   */
  useEffect(() => {
    if (!selectRedeem) {
      onRedeemDollars(null);
    }
  }, [selectRedeem, onRedeemDollars]);

  /**
   * Manages value of applied $ on total change
   */
  useEffect(() => {
    if (!redeemDollars) {
      // clear redeem GYG $ selection when set value to redeem is cleared
      setSelectRedeem(false);
      onActivation();
    }
  }, [redeemDollars, onRedeemDollars, onActivation]);

  return (
    <View>
      <View>
        <View style={styles.itemWrapper}>
          <CoinAnimation />
          <Text style={styles.itemText}>
            {t("Loyalty:availableDollarsLabel", {
              dollars: dollars.toFixed(2),
            })}
          </Text>
          <View style={styles.switchWrapper}>
            <Switch
              trackColor={{
                false: colours.switchGrey,
                true: colours.lightGreen,
              }}
              value={selectRedeem}
              disabled={isLoading}
              onValueChange={onSelectRedeem}
            />
          </View>
        </View>
      </View>
      {selectRedeem && (
        <View
          style={styles.form}
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "RedeemDollarsInputSection"
          )}>
          <Text style={error ? styles.inputTitleError : styles.inputTitle}>
            {t("Loyalty:redeemGYGdollarsCaption")}
          </Text>
          <View style={styles.row}>
            <View style={styles.inputWrapper}>
              <FormInput
                error={error}
                handleChangeText={onChange}
                onActivation={onActivation}
                isDisabled={isLoading}
                value={value}
                errorMsg={errorMessage || inputErrorMessage}
                type={InputType.DECIMAL}
                inputPrefix='$'
                confirm={pressedButton && changedText && !isLoading}
                isDisablePadding
              />
            </View>

            <View style={styles.button}>
              <SecondaryButton
                testId='ApplyRedeemBtn'
                buttonName={t("Loyalty:redeemGYGdollarsCTA")}
                onClick={onPressButton}
                disable={value.length === 0 || isLoading}
                loading={isLoading}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default React.memo(PayWithGomex);
