import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Switch,
  Pressable,
} from "react-native";

import { Checkbox } from "../../Checkbox";
import GradientLine from "../../GradientLine";
import { Typography } from "../../styles/typography";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";

const styles = StyleSheet.create({
  toggleRow: {
    padding: Spacing.Light,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const trackColor = {
  false: colours.switchGrey,
  true: colours.lightGreen,
};

interface RevertedToggleDataProps {
  id: number;
  name: string;
  selected: boolean;
  onChange?: (value: boolean, id: number) => void;
}

const RevertedToggleData: React.FC<RevertedToggleDataProps> = ({
  id,
  name,
  selected,
  onChange,
}) => {
  const onPress = () => {
    if (onChange) {
      onChange(!selected, id);
    }
  };

  const onSwitch = (value: boolean) => {
    if (onChange) {
      onChange(value, id);
    }
  };

  return (
    <View>
      <GradientLine />
      <View style={styles.toggleRow}>
        <Text style={Typography.titleThree}>{name}</Text>
        {Platform.OS === "web" ? (
          <Pressable onPress={onPress}>
            <Checkbox selected={selected} />
          </Pressable>
        ) : (
          <Switch
            trackColor={trackColor}
            value={selected}
            onValueChange={onSwitch}
          />
        )}
      </View>
    </View>
  );
};

export default RevertedToggleData;
