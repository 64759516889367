import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, StyleSheet, View } from "react-native";

import { AnalyticsConst } from "gyg_common";
import appleImg from "gyg_common/components/assets/images/socialMedia/icon-social-apple.png";
import fbImg from "gyg_common/components/assets/images/socialMedia/icon-social-facebook.png";
import googleImg from "gyg_common/components/assets/images/socialMedia/icon-social-google.png";
import { SocialMediaButton } from "gyg_common/components/Buttons/SocialMediaButton";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  itemContainer: {
    marginBottom: 8,
  },

  absoluteView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 16,
    backgroundColor: colours.white,
    borderRadius: 8,
    height: 44,
  },

  appleImageIcon: {
    width: 31,
    height: 44,
    marginLeft: 20,
  },

  googleImageIcon: {
    width: 18,
    height: 18,
    marginLeft: 24,
  },

  imageIcon: {
    width: 24,
    height: 24,
    marginLeft: 24,
  },

  leftContainer: {
    flex: 0.45,
    flexDirection: "row",
    alignItems: "center",
  },
  rightContainer: {
    flex: 0.55,
    flexDirection: "row",
    alignItems: "center",
  },
  socialMediaText: {
    ...Typography.viewCartBtnPrice,
    textAlign: "center",
    textTransform: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    paddingHorizontal: 12,
    paddingBottom: 12,
    marginTop: 8,
  },
  emptyOr: {
    marginBottom: -8,
  },
});

export interface LoginWithSocialMediaProps {
  children?: ReactNode;
  isJoinGYG?: boolean;
  onSocialLogin: (loginType: AnalyticsConst.LoginType) => void;
}

const LoginWithSocialMedia: React.FC<LoginWithSocialMediaProps> = ({
  children,
  isJoinGYG,
  onSocialLogin,
}) => {
  const { t } = useTranslation();

  return (
    <div className='main-login__social-container'>
      {isJoinGYG ? (
        <p className='main-login__subtitle'>
          {t("LoginRegistration:joinwithLabel")}
        </p>
      ) : null}

      {children && <View style={styles.button}>{children}</View>}

      {isJoinGYG ? (
        <p className='main-login__or'> {t("LoginRegistration:OR")} </p>
      ) : (
        <View style={styles.emptyOr} />
      )}

      <SocialMediaButton
        title={t("LoginRegistration:apple")}
        onPress={() => onSocialLogin(AnalyticsConst.LoginType.Apple)}
        icon={appleImg as ImageSourcePropType}
      />

      <SocialMediaButton
        title={t("LoginRegistration:google")}
        onPress={() => onSocialLogin(AnalyticsConst.LoginType.Google)}
        icon={googleImg as ImageSourcePropType}
      />

      <SocialMediaButton
        title={t("LoginRegistration:facebook")}
        onPress={() => onSocialLogin(AnalyticsConst.LoginType.Facebook)}
        icon={fbImg as ImageSourcePropType}
      />
    </div>
  );
};

export default LoginWithSocialMedia;
