import * as braze from "@braze/web-sdk";
import { mapContentCardToPromotionCardData } from "hooks/useBrazeContentCards";
import React, { useEffect, useMemo, useRef } from "react";

import { PromotionCardModels } from "gyg_common";
import SliderCard from "views/components/shared/SliderCard";

interface PromotionCardProps {
  active: boolean;
  card: braze.Card;
  onClick: (url: string) => void;
}

const PromotionCard: React.FC<PromotionCardProps> = ({
  active,
  card,
  onClick,
}) => {
  const isLogged = useRef<boolean>(false);

  const cardData: PromotionCardModels.PromotionCardDataProps = useMemo(() => {
    return mapContentCardToPromotionCardData(card);
  }, [card]);

  useEffect(() => {
    if (active && !isLogged.current) {
      const logCardImpresions = braze.logContentCardImpressions([card]);

      isLogged.current = logCardImpresions;
    }
  }, [active, card]);

  const onPromoCardClick = () => {
    braze.logContentCardClick(card);

    if (cardData.url) {
      onClick(cardData.url);
    }
  };

  return (
    <SliderCard
      imageWideUrl={cardData.imageWideUrl}
      imageUrl={cardData.imageUrl as string}
      onClick={onPromoCardClick}
      showStatus={false}
    />
  );
};

export default PromotionCard;
