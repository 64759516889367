import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  ImageURISource,
  Platform,
  Pressable,
  Text,
  View,
} from "react-native";

import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import CardSticker from "../../../components/CardSticker";
import MenuImage from "../../../components/Menu/MenuImage";
import * as PlatformUtils from "../../../modules/platformUtils";
import * as ProductUtils from "../../../modules/Products/utils";
import * as CartReduxModels from "../../../redux_store/cart/models";
import * as MenuModels from "../../../redux_store/menu/models";
import iconImage from "../../assets/icons/icon_refresh_small.png";
import defaultImage from "../../assets/images/no_image_available_menu.png";
import { Typography } from "../../styles/typography";
import { containerStyle, styles } from "./TacoMultiPartSectionItem.styles";

export interface TacoMultiPartSectionItemProps {
  multiPartSection: MenuModels.MultipartSection;
  onPress: (data: MenuModels.Category) => void;
  multiPartSectionItem?: CartReduxModels.CartItem;
  onReset: () => void;
  applyAllValid: boolean;
  handleOnApplyAll: (
    multiPartSectionItem: CartReduxModels.CartItem,
    multipartIndex: number
  ) => void;
  multiPartIndex: number;
}

/**
 * List item of Taco Customize Option  ( First Taco, Second Taco)
 * @param props
 * @returns
 */
const TacoMultiPartSectionItem: React.FC<TacoMultiPartSectionItemProps> = ({
  onPress,
  multiPartSection,
  multiPartSectionItem,
  onReset,
  applyAllValid,
  handleOnApplyAll,
  multiPartIndex,
}) => {
  const { t } = useTranslation();
  const [multipartSelectionInfo, setMultipartSelectionInfo] = useState<
    ProductUtils.MultipartSelectionInfo | undefined
  >(undefined);
  const [sectionImage, setSectionImage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (multiPartSectionItem && multiPartSection.categories) {
      setMultipartSelectionInfo(
        ProductUtils.getMultipartSelectionInfoFromCartItem(
          multiPartSection.categories[0],
          multiPartSectionItem
        )
      );
    } else {
      setMultipartSelectionInfo(undefined);
    }
  }, [multiPartSectionItem, multiPartSection]);

  useEffect(() => {
    setSectionImage(
      ProductUtils.getMultipartSectionImage(
        multiPartSection,
        multipartSelectionInfo?.imageUrl
      )
    );
  }, [multipartSelectionInfo, multiPartSection]);

  return (
    <CardSticker>
      <Pressable
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `TacoMultiPartItemButton-${multiPartSection.name}`
        )}
        style={({ hovered }) =>
          containerStyle(Boolean(multiPartSectionItem), hovered).container
        }
        onPress={() =>
          multiPartSection.categories[0] &&
          onPress(multiPartSection.categories[0])
        }>
        <View>
          <MenuImage
            style={styles.icon}
            source={
              sectionImage
                ? (sectionImage as ImageSourcePropType)
                : defaultImage
            }
          />
        </View>
        <View style={Platform.OS === "web" ? styles.rightWeb : styles.right}>
          <Text style={Typography.smallProductTitle}>
            {(multipartSelectionInfo?.name
              ? multipartSelectionInfo.name
              : multiPartSection.name
            ).toUpperCase()}
          </Text>
          <View
            style={
              Platform.OS === "web" ? styles.descriptionWeb : styles.description
            }>
            {!multiPartSectionItem && (
              <Text style={Typography.captionOne}>
                {t("OrderManagement:tacoMultipartSectionSelection")}
              </Text>
            )}
            {multipartSelectionInfo &&
            multipartSelectionInfo.customizationText ? (
              <Text style={Typography.captionOne}>
                {multipartSelectionInfo.customizationText}
              </Text>
            ) : null}
            <View style={styles.gap} />
            {multipartSelectionInfo && multipartSelectionInfo.removeText ? (
              <Text style={Typography.captionOne}>
                {multipartSelectionInfo.removeText}
              </Text>
            ) : null}
          </View>
        </View>
        <View style={styles.split} />
        {multiPartSectionItem && (
          <View style={styles.iconContainer}>
            <Pressable
              {...PlatformUtils.generateTestID(
                Platform.OS,
                `TacoMultiPartItemReset-${multiPartSection.name}`
              )}
              onPress={onReset}>
              <Image style={styles.tick} source={iconImage as ImageURISource} />
            </Pressable>
          </View>
        )}
      </Pressable>
      {applyAllValid && (
        <View
          {...PlatformUtils.generateTestID(
            Platform.OS,
            `BundleMultiPartItemApplyToAll-${multiPartSection.name}`
          )}
          style={styles.applyAll}>
          <SecondaryButton
            testId='TacoApplyAllButton'
            buttonName={t("OrderManagement:applyAllButton")}
            onClick={() => {
              handleOnApplyAll(
                multiPartSectionItem as CartReduxModels.CartItem,
                multiPartIndex
              );
            }}
          />
        </View>
      )}
    </CardSticker>
  );
};

export default TacoMultiPartSectionItem;
