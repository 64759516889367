import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { CartReduxModels } from "gyg_common";

import * as PlatformUtils from "../../modules/platformUtils";
import starIcon from "../assets/icons/icon-star-hover.png";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export const styles = StyleSheet.create({
  iconContainer: {
    alignSelf: "center",
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: BorderRadius.High,
    backgroundColor: colours.white,
    marginHorizontal: Spacing.Thin,
  },
  icon: {
    alignSelf: "center",
    width: 18,
    height: 18,
    resizeMode: "contain",
    tintColor: colours.black,
  },
  container: {
    minHeight: 30,
    padding: Spacing.Thin,
    borderRadius: 0,
    borderBottomLeftRadius: BorderRadius.Medium,
    borderBottomRightRadius: BorderRadius.Medium,
    backgroundColor: colours.black,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    ...Typography.titleThree,
    fontSize: 16,
    flex: 1,
    color: colours.white,
  },
});

export interface CartItemBottomBannerProps {
  cartItem: CartReduxModels.CartItem;
}

export const CartItemBottomBanner: React.FC<CartItemBottomBannerProps> = ({
  cartItem,
}) => {
  const offer = cartItem?.associatedOffers?.length
    ? cartItem?.associatedOffers[0]
    : undefined;

  //display associatedOffer text or discounted product reward
  const displayText = offer ? offer.displayText : cartItem.displayText;

  return (
    <>
      {displayText?.length && (
        <View
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "CartItemBottomBannerContainer"
          )}
          key={displayText}
          style={styles.container}>
          <View style={styles.iconContainer}>
            <Image
              style={styles.icon}
              source={starIcon as ImageSourcePropType}
            />
          </View>
          <Text style={styles.text}>{displayText}</Text>
        </View>
      )}
    </>
  );
};
