import React from "react";
import { StyleSheet, Text, View } from "react-native";

import { GomexModules, LoyaltyModels } from "../../index";
import CardSticker from "../CardSticker";
import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";
import { CoffeeLoyaltyFeatureFlag } from "./CoffeeLoyalty/CoffeeLoyaltyFeatureFlag";
import { CoffeeLoyaltyInfo } from "./CoffeeLoyalty/CoffeeLoyaltyInfo";
import EarnedPoints from "./EarnedPoints";
import GomexHeader from "./GomexHeader";
import GomexPointsView from "./GomexPointsView";
import GomexProgressBar from "./GomexProgressBar";

const styles = StyleSheet.create({
  currentSection: {
    padding: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  futureSection: {
    padding: Spacing.Light,
    paddingBottom: Spacing.Thin,
    borderTopWidth: 1,
    borderTopColor: colours.midGrey,
  },
  coffeeSection: {
    padding: Spacing.Light,
    paddingBottom: Spacing.Light,
    borderTopWidth: 1,
    borderTopColor: colours.midGrey,
  },
  futureText: {
    ...Typography.titleThree,
    fontSize: 16,
    color: colours.white,
    paddingBottom: Spacing.Thin,
  },
});

export interface GomexOrderBlackProps {
  newPointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars;
  isOrderResponseLoading: boolean;
  loyaltyRewardConversionLimit: number;
  loyaltyRewardAmount: number;
  actualPointsBalance: number;
  actualDollarsBalance: number;
  captionCopy: string;
  isEstimatedStatus?: boolean;
  noGomexAnimation?: boolean;
  title?: string;
  coffeeLoyalty?: LoyaltyModels.CoffeeLoyalty | null;
}

const GomexOrderBlack: React.FC<GomexOrderBlackProps> = ({
  newPointsAndDollars,
  isOrderResponseLoading,
  loyaltyRewardConversionLimit,
  loyaltyRewardAmount,
  actualPointsBalance,
  actualDollarsBalance,
  captionCopy,
  isEstimatedStatus,
  noGomexAnimation,
  coffeeLoyalty,
}) => {
  return (
    <CardSticker bgColor={colours.darkGrey}>
      <View style={styles.currentSection}>
        <GomexHeader
          isLoading={isOrderResponseLoading}
          noGomexAnimation={noGomexAnimation}
        />
        <GomexPointsView
          userLoyaltyPoints={actualPointsBalance}
          userLoyaltyDollar={actualDollarsBalance}
        />
      </View>
      {coffeeLoyalty?.counter && (
        <CoffeeLoyaltyFeatureFlag>
          <View style={styles.coffeeSection}>
            <CoffeeLoyaltyInfo loyalty={coffeeLoyalty} />
          </View>
        </CoffeeLoyaltyFeatureFlag>
      )}
      <View style={styles.futureSection}>
        <Text style={styles.futureText}>{captionCopy}</Text>
        <EarnedPoints
          authUserCartGomex={false}
          earnedPoints={newPointsAndDollars?.points}
          earnedDollars={newPointsAndDollars?.dollars || 0}
        />
        <GomexProgressBar
          loyaltyMaxPoints={loyaltyRewardConversionLimit}
          points={
            isEstimatedStatus
              ? newPointsAndDollars?.points
              : actualPointsBalance
          }
          loyaltyConversionRate={loyaltyRewardAmount}
          isEstimatedStatus={isEstimatedStatus}
        />
      </View>
    </CardSticker>
  );
};

export default React.memo(GomexOrderBlack);
