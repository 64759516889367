import "./styles.scss";

import gomexAnimation from "assets/gif/gomex.json";
import gygYellowLogo from "assets/images/gyg_abrv_yellow.png";
import JsBarcode from "jsbarcode";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { GomexModules } from "gyg_common";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import MiddleModal, {
  WebModalSize,
} from "gyg_common/components/modals/MiddleModal";
import { useLottieWeb } from "gyg_common/hooks";

import colours from "@/../../packages/common/components/styles/colours";

export interface BarcodeProps {
  cardNumber: string;
  firstName: string;
  lastName: string;
  gygPoints: number;
  gygDollars: number;
  isVisible: boolean;
  onCloseClick: () => void;
}

export const BarcodeModal = (props: BarcodeProps): JSX.Element => {
  const {
    cardNumber,
    firstName,
    lastName,
    gygPoints,
    gygDollars,
    isVisible,
    onCloseClick,
  } = props;
  const { t } = useTranslation();
  const { lottie } = useLottieWeb();

  const ref = useRef<HTMLDivElement>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    setCanvas(document.createElement("canvas"));
    return () => {
      setCanvas(undefined);
    };
  }, []);

  useEffect(() => {
    if (canvas && ref) {
      JsBarcode(canvas, cardNumber, {
        displayValue: false,
        height: 240,
        format: "",
        width: 3,
      });
      ref.current?.appendChild(canvas);
    }
    // TODO: check use effect eslint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, canvas, isVisible]);

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.loadAnimation({
      container: document.getElementById(
        "gomexBarcodeAnimation"
      ) as HTMLDivElement,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: gomexAnimation,
    });
  }, [isVisible, lottie]);

  return (
    <MiddleModal
      backgroundColor={colours.transparent}
      webModalSize={WebModalSize.SMALL}
      isVisible={isVisible}
      testId='BarcodeModal'>
      <div className='barcode'>
        <div data-testid='BarcodeSection' className='barcode__container'>
          <div className='barcode__canvas' ref={ref} />
          <p className='barcode__number'>
            {GomexModules.GomexUtils.formatCardNumber(cardNumber)}
          </p>
        </div>

        <div data-testid='BarcodeLoyalty' className='barcode__card'>
          {isVisible && (
            <div id='gomexBarcodeAnimation' className='barcode__card-header' />
          )}
          <p className='barcode__card-text__title'>{t("Loyalty:nameLabel")}</p>
          <p className='barcode__card-text__value-name'>
            {firstName.toUpperCase()} {lastName.toUpperCase()}
          </p>

          <div className='barcode__card__points'>
            <div className='barcode__card__points__section'>
              <p className='barcode__card-text__title'>
                {t("Loyalty:gygPointsLabel")}
              </p>
              <p className='barcode__card-text__value'>{gygPoints}</p>
            </div>

            <div className='barcode__card__points__section'>
              <p className='barcode__card-text__title'>
                {t("Loyalty:gygDollarsLabel")}
              </p>
              <p className='barcode__card-text__value'>
                ${GomexModules.GomexUtils.formatLoyaltyDollars(gygDollars)}
              </p>
            </div>

            <img className='barcode__card__logo' src={gygYellowLogo} />
          </div>
        </div>

        <button
          data-testid='BarcodeCloseButton'
          onClick={onCloseClick}
          className='barcode__close-button'>
          <img className='barcode__close-icon' src={closeIcon} />
        </button>
      </div>
    </MiddleModal>
  );
};
