import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, Text, View } from "react-native";

import {
  CartReduxModels,
  FavouriteModels,
  FavouriteModules,
  OrderModules,
  PlatformUtils,
} from "gyg_common";
import { CartItem } from "gyg_common/components/Order/CartItem";
import colours from "styles/colours";
import { errorStyle } from "styles/shared";

const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.white,
  },
  itemContainer: {
    marginBottom: 16,
  },
  errorCaption: {
    marginTop: 8,
  },
});

export interface CartItemListProps {
  data: CartReduxModels.CartItem[];
  isCartItemDeletionInProgress: boolean;
  isLoggedInUser: boolean;
  isFavouriteUpdateError: boolean;
  favouriteCartItems: FavouriteModels.FavouriteCartItem[];
  onDelete: (cartItemId: number) => void;
  onUpdateCartItemQuantity: (cartItemId: number, quantity: number) => void;
  onCreateORUpdateFavourite: (
    payload: FavouriteModels.AddFavouriteRequest | number,
    type: string
  ) => void;
  onRenamePress: (
    index: number,
    payload: FavouriteModels.UpdateFavouriteRequest
  ) => void;
  onItemEdit: (item: CartReduxModels.CartItem, index: number) => void;
  getOrderLoading: boolean;
}

export default function CartItemList({
  data,
  isCartItemDeletionInProgress,
  isLoggedInUser,
  isFavouriteUpdateError,
  favouriteCartItems,
  onDelete,
  onUpdateCartItemQuantity,
  onCreateORUpdateFavourite,
  onRenamePress,
  onItemEdit,
  getOrderLoading,
}: CartItemListProps): JSX.Element {
  const { t } = useTranslation();

  const renderItem = (itemData: CartReduxModels.CartItem, i: number) => {
    const checkIfFavourite =
      favouriteCartItems && favouriteCartItems.length > 0
        ? FavouriteModules.isFavouriteItem(itemData, favouriteCartItems)
        : false;

    // combines amount of ingridients that are listed with identifier line
    const lengthOfIdentifier = OrderModules.OrderUtils.getIdentifiersFromTags(
      itemData.tags,
      itemData.tagLookup
    ).length
      ? 1
      : 0;
    const lengthOfCustomisationList =
      OrderModules.OrderUtils.getProductIngridientsAmount(itemData) +
      lengthOfIdentifier;
    const isExpandable =
      lengthOfCustomisationList >
      OrderModules.OrderConstants.VisibleCustomisations;
    return (
      <View key={`${itemData.productId}-${i}`} style={styles.itemContainer}>
        <CartItem
          index={i}
          key={`${itemData.productId}-${i}`}
          isCartItemDeletionInProgress={isCartItemDeletionInProgress}
          isFavourite={checkIfFavourite}
          favouriteData={FavouriteModules.getFavouriteIdLabel(
            itemData,
            favouriteCartItems
          )}
          cartItem={itemData}
          expandable={isExpandable}
          getOrderLoading={getOrderLoading}
          updateCartItemQuantity={onUpdateCartItemQuantity}
          deleteItem={onDelete}
          isLoggedInUser={isLoggedInUser}
          onCreateORUpdateFavourite={onCreateORUpdateFavourite}
          isFavouriteUpdateError={isFavouriteUpdateError}
          onRenamePress={onRenamePress}
          onItemEdit={onItemEdit}
        />
        {itemData?.isValid === false && (
          <View
            style={styles.errorCaption}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              "cart-item-error-caption"
            )}>
            <Text style={errorStyle.caption}>
              {t("CheckoutPayment:itemErrorLabel")}
            </Text>
          </View>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {data.map((val, i) => {
        return renderItem(val, i);
      })}
    </View>
  );
}
