import useAddToOrder from "hooks/useAddToOrder";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import {
  CartReduxModels,
  locales,
  MenuModels,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
  RootState,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import AddModifyCartItem from "gyg_common/components/Products/AddModifyCartItem";
import CustomizableProduct from "gyg_common/components/Products/CustomizableProduct/CustomizableProduct";
import MakeItAMeal from "gyg_common/components/Products/MakeItAMeal";
import MakeItAMealSection from "gyg_common/components/Products/MakeItAMealSection";
import ProductDetail from "gyg_common/components/Products/ProductDetail";
import ProductDetailSticky from "gyg_common/components/Products/ProductDetailSticky";
import ProductModifier from "gyg_common/components/Products/ProductModifier";
import TacoMultiPartSectionSelection from "gyg_common/components/Products/TacoMultiPartSectionItem/TacoMultipartSectionSelection";
import useUpdateOrder from "gyg_common/hooks/useUpdateOrder";
import { useValidateTacoProduct } from "gyg_common/hooks/useValidateProduct";
import { mapButtonTitle } from "gyg_common/modules/Cart/utils";
import {
  HandleUpdateOrder,
  ModifyOrderType,
} from "gyg_common/modules/Order/models";
import { findMultipart } from "gyg_common/modules/Products/utils";
import { CartItem } from "gyg_common/redux_store/cart/models";
import { MultiPart } from "gyg_common/redux_store/menu/models";

export interface RenderOptionsItemProps {
  item: ProductModuleModel.CustomizeOptionsDataProps;
  index: number;
}
export interface ExpandListState {
  [key: string]: boolean;
}

export interface ErrorsState {
  [key: string]: boolean;
}

export interface TacoProductProps {
  category: MenuModels.Category;
  tacoItem: CartReduxModels.BundleState;
  cartItem?: CartReduxModels.CartItem;
  MIAMCartItem?: CartReduxModels.CartItem;
  MIAMItem?: CartReduxModels.BundleState;
  editMode: ProductConst.EditMode;
  commonSection?: MenuModels.CommonSection;
  isUpsell?: boolean;
  index?: number;
  enableGoBackMode?: boolean;
  handleResetTacoItem: (index: number) => void;
  goBack: () => void;
  onClose: () => void;
  handleUpdateTacoItem: (
    cartItem: CartReduxModels.CartItem,
    index: number
  ) => void;
  handleMIAMsimpleOptionSelected?: (
    optionCartItem: CartReduxModels.CartItem | undefined,
    index: number
  ) => void;
  handleMIAMcustomisableOptionSelected?: (
    category: MenuModels.Category,
    cartItems: CartReduxModels.CartItem[] | undefined,
    index: number
  ) => void;
  handleResetMIAMItems?: () => void;
  selectedMealId: number | undefined;
  updateSelectedMealId: (id: number | undefined) => void;
}

/**
 * TACOS main page
 * @param props
 * @returns
 */
export default function TacoProduct(props: TacoProductProps): JSX.Element {
  const {
    category,
    handleResetTacoItem,
    tacoItem,
    editMode,
    cartItem,
    commonSection,
    MIAMItem,
    isUpsell,
    index,
    enableGoBackMode = true,
    goBack,
    onClose,
    handleUpdateTacoItem,
    handleMIAMcustomisableOptionSelected,
    handleMIAMsimpleOptionSelected,
    handleResetMIAMItems,
    selectedMealId,
    updateSelectedMealId,
  } = props;
  const { i18n } = useTranslation();
  const { isDesktopScreen } = useMediaQuery();
  const [step, updateStep] = useState<number>(1);
  const nodeRef = React.useRef(null);
  const { isLoading } = useSelector((s: RootState) => s.favourite);

  const [tacoCustomizeIndex, updateTacoCustomizeIndex] = useState<number>(0);
  const [tacoCustomizeCartItem, updateTacoCustomizeCartItem] = useState<
    CartReduxModels.CartItem | undefined
  >(undefined);
  const [tacoCustomizeCategory, updateTacoCustomizeCategory] = useState<
    MenuModels.Category | undefined
  >(undefined);
  const identifierLength = 1;
  const [selectedMultiPart, updateSelectedMultiPart] = useState<
    (string | number)[]
  >([]);
  const [expandList, updateExpandList] = useState<ExpandListState>({
    [category.name]: true,
  });
  const [listLengthBeforeUpdate, updateListLengthBeforeUpdate] = useState<
    number | undefined
  >(undefined);

  const [CustomizeOptionsData, setCustomizeOptionsData] = useState<
    ProductModuleModel.CustomizeOptionsDataProps[]
  >([]);
  const [mealCustomizeOptions, setMealCustomizeOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [mealRemoveModifierOptions, setMealRemoveModifierOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [MIAMrequiredLength, setMIAMrequiredLength] = useState<number>(0);
  const [stickerVisible, setStickerVisible] = useState<boolean>(false);
  const [isApplyAllUsed, setIsApplyAllUsed] = useState<boolean>(false);
  const [preSelectedMiamParts, setPreSelectedMiamParts] =
    useState<CartItem[]>();

  const handleUpdateOrder = useUpdateOrder(editMode, onClose);
  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    applyAll: isApplyAllUsed,
    categoryName:
      step == 2 ? tacoCustomizeCategory?.name : (category?.name ?? ""),
    onClose,
  });

  const { requiredFieldsError, errorIndex, clearErrorIndex, validateOrder } =
    useValidateTacoProduct();

  const selectedMultipartData = useMemo(() => {
    if (selectedMultiPart.length === 0) {
      return null;
    }

    return findMultipart(
      category?.multiPart || [],
      parseInt(selectedMultiPart[0] as string, 10)
    );
  }, [category, selectedMultiPart]);

  const addButtonTitle = useMemo(() => {
    const isSelectionValid = validateOrder({
      category,
      customizeOptionsData: CustomizeOptionsData,
      selectedMultiPart,
      selectedMealId,
      requiredTacoItemLength:
        selectedMultipartData?.multiPartSection?.length ?? 0,
      tacoItem,
      mealCustomizeOptions,
      skipErrorConfig: true,
    });

    return mapButtonTitle({
      locale: i18n.language as locales,
      selectedMealId,
      hasCommonSection: !!commonSection,
      isSelectionValid,
    });
  }, [
    CustomizeOptionsData,
    category,
    commonSection,
    i18n.language,
    mealCustomizeOptions,
    selectedMealId,
    selectedMultiPart,
    selectedMultipartData?.multiPartSection?.length,
    tacoItem,
    validateOrder,
  ]);

  const onUpdateOrder = (updateOrderParams: HandleUpdateOrder) => {
    const isValid = validateOrder({
      category,
      customizeOptionsData: CustomizeOptionsData,
      selectedMultiPart,
      selectedMealId,
      requiredTacoItemLength:
        selectedMultipartData?.multiPartSection?.length ?? 0,
      tacoItem,
      mealCustomizeOptions,
    });

    if (!isValid) {
      return;
    }

    if (updateOrderParams.type === ModifyOrderType.ADD) {
      handleAddToOrder(updateOrderParams);
    } else {
      handleUpdateOrder(updateOrderParams);
    }
  };

  const onResetTaco = (ignoreMeal = false) => {
    if (!ignoreMeal) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleRemoveMeal();
    }

    updateSelectedMultiPart([]);
    updateListLengthBeforeUpdate(undefined);
    for (const i in tacoItem) {
      handleResetTacoItem(parseInt(i));
    }
  };

  /**
   * Updates redux state of simple MIAM option and
   * saves temporary cart item of taco and MIAM in container.
   * @param id section id
   * @param selectedId selected product id
   * @param index sectin index
   * @param categoryOption
   */
  const handleSetMealCustomizeOptions = (
    id: string,
    selectedId: number,
    optionIndex: number,
    categoryOption?: MenuModels.Category
  ) => {
    const mealMultipart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );

    if (categoryOption) {
      const MIAMLength = MIAMItem ? Object.keys(MIAMItem).length : 0;
      const tacoLength = tacoItem ? Object.keys(tacoItem).length : 0;
      const newTemporaryCartItems = ProductUtils.getTacoCartItem(
        tacoItem,
        tacoLength,
        category?.multiPart?.find((n) => n.id === selectedMultiPart[0]),
        1,
        category.templateId,
        mealMultipart,
        mealCustomizeOptions,
        mealRemoveModifierOptions,
        MIAMItem,
        MIAMLength
      );
      // saves main product cart item and meal cart item in container's local state,
      // to bring it back when returning from MIAM option customisation template
      if (handleMIAMcustomisableOptionSelected) {
        handleMIAMcustomisableOptionSelected(
          categoryOption,
          newTemporaryCartItems,
          optionIndex
        );
      }
    } else {
      const optionCartItem = ProductUtils.getSelectedMiamOption(
        id,
        selectedId,
        mealMultipart,
        preSelectedMiamParts
      );
      // saves MIAM selection to the redux state
      if (optionCartItem && handleMIAMsimpleOptionSelected) {
        handleMIAMsimpleOptionSelected(optionCartItem, optionIndex);
      }
    }
  };

  /**
   * Updates MIAM product's cart item in redux with remove modifier.
   * Assums that remove modi applies for sides and sides are first section in MIAM.
   * @param removeModifierId
   * @param value toggle value
   * @param index section index
   */
  const updateMIAMItemWithRemoveModifier = (
    removeModifierId: number | undefined,
    value: boolean,
    itemIndex: number
  ) => {
    const multipart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const section =
      multipart?.multiPartSection && multipart?.multiPartSection[0]?.name;

    if (section && handleMIAMsimpleOptionSelected) {
      let selectedProductId;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Object.entries(mealCustomizeOptions).forEach(([key, value]) => {
        if (key == section) {
          selectedProductId = value;
        }
      });

      // condition uses reverted value because remove modi is reverted toggle
      const removeModiArray =
        !value && removeModifierId ? [removeModifierId] : undefined;

      if (selectedProductId && multipart?.multiPartSection) {
        const newSelectedProductCartItem: CartReduxModels.CartItem | undefined =
          ProductUtils.createCartItem(
            multipart?.multiPartSection[0] as unknown as MenuModels.Category,
            selectedProductId,
            1,
            true,
            removeModiArray
          );

        if (newSelectedProductCartItem) {
          handleMIAMsimpleOptionSelected(newSelectedProductCartItem, itemIndex);
        }
      }
    }
  };

  const handleUpdateExpandList = (key: string, expand: boolean) => {
    const nextState = { [key]: !expand };
    updateExpandList({ ...expandList, ...nextState });
  };

  const handleRemoveMeal = () => {
    setMealRemoveModifierOptions({});
    setMealCustomizeOptions({});
    if (handleMIAMcustomisableOptionSelected) {
      handleMIAMcustomisableOptionSelected(category, undefined, 0);
    }
    if (handleResetMIAMItems) {
      handleResetMIAMItems();
    }
    updateSelectedMealId(undefined);
  };

  const goToStep = (stepIndex: number) => {
    updateStep(stepIndex);
  };

  const handleCustomizeTaco = (
    mealCategory: MenuModels.Category,
    mealIndex: number,
    mealCartItem?: CartReduxModels.CartItem
  ) => {
    updateTacoCustomizeCartItem(mealCartItem);
    updateTacoCustomizeCategory(mealCategory);
    updateTacoCustomizeIndex(mealIndex);
    goToStep(2);
  };

  const handleOnApplyAll = (
    multiPart: MenuModels.MultiPart,
    bundleItem: CartReduxModels.CartItem,
    excludedIndexs: string[]
  ) => {
    const validIndexs = ProductUtils.applyAllValidObjects(
      multiPart,
      bundleItem,
      excludedIndexs
    );
    validIndexs.forEach((i) => {
      handleUpdateTacoItem({ ...bundleItem, productId: i.product.id }, i.index);
    });
    if (validIndexs.length) {
      setIsApplyAllUsed(true);
    }
  };

  const handleSelectMultipartSection = (
    menuCategory: MenuModels.Category,
    sectionIndex: number
  ) => {
    handleCustomizeTaco(menuCategory, sectionIndex, tacoItem[sectionIndex]);
  };

  const handleCustomizeOptionsUpdate = (
    item: ProductModuleModel.CustomizeOptionsDataProps,
    ids: (number | string)[],
    optionIndex: number
  ) => {
    onResetTaco(true);
    updateSelectedMultiPart(ids);

    // Scroll to next only when user click identifier customization
    if (identifierLength && optionIndex < identifierLength) {
      const nextSection = document.getElementById(
        `product-modifier-${optionIndex + 1}`
      );
      nextSection?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    if (errorIndex !== null) {
      let nextSection = document.getElementById(
        `product-modifier-${errorIndex}`
      );

      if (!nextSection) {
        nextSection = document.getElementById(
          `product-mealSection-${errorIndex}`
        );
      }
      nextSection?.scrollIntoView({ behavior: "smooth", block: "start" });

      clearErrorIndex();
    }
  }, [errorIndex, clearErrorIndex]);

  useEffect(() => {
    // scroll to next section when content size change (select product)
    if (identifierLength && CustomizeOptionsData.length > identifierLength) {
      let nextSection = document.getElementById(
        `product-modifier-${listLengthBeforeUpdate}`
      );

      if (!nextSection) {
        nextSection = document.getElementById(
          `product-mealSection-${listLengthBeforeUpdate}`
        );
      }
      nextSection?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomizeOptionsData.length]);

  const onChangeSelectedMeal = (selectedMeal: number) => {
    setMealCustomizeOptions({});
    updateSelectedMealId(selectedMeal);
  };

  // conditionally render section view
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderItem = ({ item, index }: RenderOptionsItemProps) => {
    if (item.commonSection) {
      return (
        <div key={item.id} id={`product-mealSelection`}>
          <MakeItAMeal
            id={item.id}
            itemOnlyFromPrice={category.price ? category.price : 0}
            error={requiredFieldsError[item.id] === true}
            expand={expandList[item.id] !== false}
            handleUpdateExpandList={handleUpdateExpandList}
            selectedMealId={selectedMealId}
            updateSelectedMealId={onChangeSelectedMeal}
            commonSection={item.commonSection}
            categoryImageTopDownUrl={category.imageTopDownUrl}
            identifierSectionTitle={item.title}
            categoryImageAngleUrl={category.imageAngleUrl}
          />
        </div>
      );
    } else if (item.multipartSection) {
      return (
        <div key={item.id} id={`product-mealSection-${index}`}>
          <MakeItAMealSection
            id={item.id}
            preSelectedMiamParts={preSelectedMiamParts}
            error={requiredFieldsError[item.id] === true}
            expand={expandList[item.id] !== false}
            handleUpdateExpandList={handleUpdateExpandList}
            updateApplyRemoveModifier={(value, removeModifierId) => {
              updateMIAMItemWithRemoveModifier(removeModifierId, value, index);
            }}
            applyRemoveModifier={!Boolean(mealRemoveModifierOptions[item.id])}
            selectedId={mealCustomizeOptions[item.id]}
            updateSelectedId={(selectedId) => {
              handleSetMealCustomizeOptions(item.id, selectedId, index);
            }}
            multipartSection={item.multipartSection}
            onCustomisableOptionSelection={(selectedCategory) => {
              handleSetMealCustomizeOptions(
                item.id,
                selectedCategory.id,
                index,
                selectedCategory
              );
            }}
          />
        </div>
      );
    } else if (item.multiPart) {
      return (
        <div key={item.id} id={`product-modifier-${index}`}>
          <TacoMultiPartSectionSelection
            category={category}
            error={requiredFieldsError[item.id] === true}
            handleOnApplyAll={(
              cartItemModel: CartReduxModels.CartItem,
              multipartIndex: number
            ) => {
              handleOnApplyAll(
                item.multiPart as MultiPart,
                cartItemModel,
                ProductUtils.getExcludedIndexs(tacoItem, multipartIndex)
              );
            }}
            key={item.multiPart.id + index}
            handleSelectMultipartSection={handleSelectMultipartSection}
            multiPart={item.multiPart}
            tacoItem={tacoItem}
            handleResetTacoItem={handleResetTacoItem}
          />
        </div>
      );
    } else {
      return (
        <div key={item.id} id={`product-modifier-${index}`}>
          <ProductModifier
            error={requiredFieldsError[item.id] === true}
            key={item.id}
            footerTitle={item.footerTitle}
            expand={expandList[item.id]}
            handleUpdateExpandList={handleUpdateExpandList}
            handleCustomizeOptionsUpdate={handleCustomizeOptionsUpdate}
            index={index}
            item={item}
            selectedId={selectedMultiPart}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    const selectedMultiPartData = category?.multiPart?.find(
      (n) => n.id === selectedMultiPart[0]
    );
    const selectedMealMultiPart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const customizeOptionsData = ProductUtils.generateTacoOptionsData(
      category,
      i18n.language as locales,
      selectedMultiPartData,
      commonSection,
      selectedMealMultiPart,
      selectedMealId
    );
    if (customizeOptionsData.length > CustomizeOptionsData.length) {
      updateListLengthBeforeUpdate(CustomizeOptionsData.length);
    }
    setCustomizeOptionsData(customizeOptionsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, selectedMultiPart, commonSection, selectedMealId]);

  /**
   * Sets required cart item array length for MIAM.
   */
  useEffect(() => {
    const selectedMultiPartCategory =
      commonSection?.categories[0]?.multiPart?.find(
        (n) => n.id === selectedMealId
      );
    setMIAMrequiredLength(
      selectedMultiPartCategory?.multiPartSection?.length || 0
    );
  }, [selectedMealId, commonSection]);

  useEffect(() => {
    const target = document.getElementById("product-img");

    if (target != null) {
      const handleScroll = (entries: IntersectionObserverEntry[]) => {
        setStickerVisible(!entries[0].isIntersecting);
      };
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: [1, 0],
      };

      const observer = new window.IntersectionObserver(handleScroll, options);
      observer.observe(target);

      return function cleanup() {
        if (target != null) {
          observer.unobserve(target);
        }
      };
    }
  });

  /**
   * Populates selection of taco product based on cart item.
   */
  useEffect(() => {
    if (cartItem) {
      updateSelectedMultiPart([cartItem.productId]);
    }
  }, [cartItem]);

  /**
   * Updates local state of meal options with products from MIAMItem.
   */
  useEffect(() => {
    ProductUtils.preselectMiamParts(
      CustomizeOptionsData,
      setMealCustomizeOptions,
      setMealRemoveModifierOptions,
      setPreSelectedMiamParts,
      MIAMItem,
      cartItem?.miamItem?.parts
    );
  }, [MIAMItem, cartItem?.miamItem?.parts, CustomizeOptionsData]);

  const cartItems = useMemo(() => {
    return ProductUtils.getTacoCartItem(
      tacoItem,
      selectedMultipartData?.multiPartSection?.length ?? 0,
      selectedMultipartData,
      1,
      category.templateId,
      commonSection?.categories[0]?.multiPart?.find(
        (n) => n.id === selectedMealId
      ),
      mealCustomizeOptions,
      mealRemoveModifierOptions,
      MIAMItem,
      MIAMrequiredLength
    );
  }, [
    MIAMItem,
    MIAMrequiredLength,
    category.templateId,
    commonSection?.categories,
    mealCustomizeOptions,
    mealRemoveModifierOptions,
    selectedMealId,
    selectedMultipartData,
    tacoItem,
  ]);

  const renderBundleView = (stepNumber: number) => {
    switch (stepNumber) {
      case 1:
        return (
          <>
            <div className='product-wrapper'>
              <div className='product-wrapper-col'>
                <ProductDetail
                  showNutritionalDisclaimer={false}
                  onReset={onResetTaco}
                  resetDisabled={Boolean(!selectedMultiPart[0])}
                  key={category.name + "ProductDetail"}
                  setQuantity={undefined}
                  onGoBack={goBack}
                  gobackMode={enableGoBackMode}
                  name={category.name}
                  price={category.price ? category.price : 0}
                  customizationText={category.description as string}
                  {...ProductUtils.getMealText(
                    selectedMealId,
                    mealCustomizeOptions,
                    commonSection
                  )}
                  imageAngleUrl={category.imageAngleUrl as string}
                  cartItems={cartItems}
                />
                {!isDesktopScreen && (
                  <CSSTransition
                    nodeRef={nodeRef}
                    in={stickerVisible}
                    unmountOnExit
                    timeout={200}
                    classNames='product-sticky-header'>
                    <div
                      ref={nodeRef}
                      className={"product-sticky-header-wrapper"}>
                      <ProductDetailSticky
                        key={category.name + "ProductDetailSticky"}
                        onGoBack={goBack}
                        name={category.name}
                        price={category.price ? category.price : 0}
                        customizationText={category.description as string}
                        {...ProductUtils.getMealText(
                          selectedMealId,
                          mealCustomizeOptions,
                          commonSection
                        )}
                        gobackMode={enableGoBackMode}
                      />
                    </div>
                  </CSSTransition>
                )}
              </div>
              <div className='product-wrapper-col'>
                <div className='product__customization'>
                  <div className='product__customization__multipart'>
                    {CustomizeOptionsData.map(
                      (
                        item: ProductModuleModel.CustomizeOptionsDataProps,
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        index
                      ) => {
                        return renderItem({ item, index });
                      }
                    )}
                  </div>
                </div>
              </div>
              <div className={"product__cart-btn-wrapper"}>
                <AddModifyCartItem
                  addButtonTitle={addButtonTitle}
                  index={index}
                  editMode={editMode}
                  cartItems={cartItems}
                  categoryName={category?.name ?? ""}
                  isUpsell={isUpsell}
                  alwaysEnabled={true}
                  onAddToOrder={onUpdateOrder}
                  onEditOrder={onUpdateOrder}
                />
              </div>
              <LoadingScreen loading={isLoading} />
            </div>
          </>
        );
      case 2:
        return (
          <CustomizableProduct
            selectedMealId={selectedMealId}
            updateSelectedMealId={updateSelectedMealId}
            key={tacoCustomizeCategory?.name || "TACO-CustomizableProduct"}
            index={tacoCustomizeIndex}
            handleAddToOrder={handleAddToOrder}
            editMode={ProductConst.EditMode.UPDATE_TACO_ITEM}
            goBack={() => goToStep(1)}
            category={tacoCustomizeCategory as MenuModels.Category}
            cartItem={tacoCustomizeCartItem}
          />
        );
      default:
        return <></>;
    }
  };
  return <>{renderBundleView(step)}</>;
}
