import { StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

export const sectionContainerStyles = (index: number) =>
  StyleSheet.create({
    sectionContainer: {
      marginTop: index > 0 ? Spacing.Regular : 0,
    },
  });

export const pressableStyles = (pressed: boolean) =>
  StyleSheet.create({
    pressableOpacity: { opacity: pressed ? 0.5 : 1.0 },
  });

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.white,
    position: "relative",
  },
  stepWrapper: {
    flexDirection: "row",
    marginBottom: Spacing.Thin,
    paddingRight: Spacing.Light,
  },
  stepNumber: {
    ...Typography.bodyBold,
    marginRight: Spacing.Thin,
  },
  titleText: {
    ...Typography.titleOne,
    marginBottom: Spacing.Thin,
  },
  contentWrapper: {
    padding: Spacing.Light,
  },
  topContentWrapper: {
    borderBottomWidth: 1,
    borderColor: colours.borderGrey,
    paddingHorizontal: Spacing.Light,
    paddingTop: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  bottomContentWrapper: {
    padding: Spacing.Light,
  },
  descriptionText: {
    ...Typography.body,
    marginBottom: Spacing.Light,
  },
  listItemWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    paddingBottom: Spacing.Thin,
    paddingRight: Spacing.Thin,
  },
  backButtonWrapper: {
    position: "absolute",
    left: 16,
    zIndex: 1,
  },
  bannerImage: {
    width: "100%",
    height: 224,
  },
  chevron: {
    marginRight: Spacing.Thin,
    marginLeft: -Spacing.Thin,
  },
  termsLink: {
    ...Typography.linkText,
    marginTop: Spacing.Thin,
  },
  orderButtonWrapper: {
    marginTop: "auto",
  },
  scrollWrapper: {
    flex: 1,
  },
});
