import { StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { Typography } from "../../styles/typography";
import { Spacing, BorderRadius } from "../../styles/number";

const styles = () =>
  StyleSheet.create({
    wrapper: {
      borderRadius: BorderRadius.Medium,
      flexDirection: "column",
      backgroundColor: colours.red,
    },
    header: {
      paddingHorizontal: Spacing.Thin,
      paddingVertical: Spacing.Thin,
      flexDirection: "row",
      justifyContent: "center",
    },
    headerText: {
      paddingHorizontal: Spacing.Thin,
      justifyContent: "center",
      flex: 1,
    },
    title: {
      ...Typography.titleThree,
      color: colours.white,
    },
    closeIcon: {
      tintColor: colours.white,
      width: 24,
      height: 24,
      marginTop: Spacing.Narrow,
    },
    image: {
      tintColor: colours.white,
      width: 26,
      height: 26,
    },
    errorMessage: {
      ...Typography.bodyTwoBold,
      color: colours.white,
      paddingHorizontal: Spacing.Light,
      paddingBottom: Spacing.Thin,
    },
  });

export default styles;
