import { ReactComponent as AccountIcon } from "assets/icons/icon_account.svg";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Badge, { BadgeSizes } from "gyg_common/components/Badge";
import { DeleteAccountModal } from "gyg_common/components/Gomex/DeleteAccountModal";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import useLockedBody from "gyg_common/hooks/useLockedBody";
import { Screens } from "navigation/const";
import HeaderDropdown from "views/components/shared/Header/HeaderDropdown";

interface HeaderAccountButtonProps {
  isUserAuthenticated: boolean;
  userName: string;
  onLogOut: () => void;
  onLoginClick: () => void;
  onRegisterClick: () => void;
  onLogOutAll: () => void;
  countOfNotifications?: number | undefined;
}

/**
 * Account button in the black header.
 * Controls show/hide dropdown.
 * @param props
 * @returns
 */
const HeaderAccountButton: React.FC<HeaderAccountButtonProps> = ({
  isUserAuthenticated,
  userName,
  onLogOut,
  onLoginClick,
  onRegisterClick,
  onLogOutAll,
  countOfNotifications,
}: HeaderAccountButtonProps): JSX.Element => {
  const { isTabletScreen } = useMediaQuery();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const [showDeleteAccount, setShowDeleteAccountModal] =
    useState<boolean>(false);

  const [, setLocked] = useLockedBody(false, "root");

  useEffect(() => {
    if (!isTabletScreen) setLocked(showDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletScreen, showDropdown]);

  const onContainerClick = () => {
    if (!showDropdown) setShowDropdown(true);
  };

  /**
   * Logs out the user.
   */
  const loginClick = () => {
    setShowDropdown(false);
    onLoginClick();
  };
  const registerClick = () => {
    setShowDropdown(false);
    onRegisterClick();
  };
  const logoutCurrentDevice = () => {
    onLogOut();
    setShowLogout(false);
    navigate(Screens.Login, { state: { loginNavigatesTo: pathname } });
  };
  const logoutAllDevices = () => {
    onLogOutAll();
    setShowLogout(false);
    navigate(Screens.Login, { state: { loginNavigatesTo: pathname } });
  };
  const onLogoutModalClose = () => {
    setShowLogout(false);
  };

  const showDeleteAccountModal = () => {
    setShowDeleteAccountModal(true);
  };

  const onDeleteAccount = () => {
    navigate(Screens.Dashboard, { replace: true });
    window.localStorage.clear();
  };

  return (
    <>
      <div
        onClick={onContainerClick}
        {...(isTabletScreen && {
          onMouseOver: () => setShowDropdown(true),
          onMouseLeave: () => setShowDropdown(false),
          onBlur: () => setShowDropdown(false),
        })}
        className={`header__account ${
          showDropdown ? "header__account--open" : ""
        }`}>
        <div className='header__account-icon'>
          <AccountIcon />
        </div>
        {isTabletScreen && (
          <>
            {isUserAuthenticated && userName ? (
              <p className='header__greeting'>
                {t("Dashboard:accountLoggedTitle", {
                  userName: userName,
                })}
              </p>
            ) : (
              <p className='header__greeting'>
                {t("Dashboard:accountGuestTitle")}
              </p>
            )}
          </>
        )}
        {showDropdown && (
          <HeaderDropdown
            isUserAuthenticated={isUserAuthenticated}
            onClose={(e) => {
              if (!isTabletScreen) e?.stopPropagation();
              setShowDropdown(false);
            }}
            showLogoutModal={() => setShowLogout(true)}
            onLoginClick={loginClick}
            onRegisterClick={registerClick}
            showDeleteAccountModal={showDeleteAccountModal}
            countOfNotifications={countOfNotifications}
          />
        )}
        {!!countOfNotifications && (
          <div className='header__account-badge'>
            <Badge size={BadgeSizes.extraSmall} />
          </div>
        )}
      </div>

      <ModalWithButton
        isVisible={showLogout}
        title={t("UserManagement:logoutModalTitle")}
        message={t("UserManagement:logoutModalLabel")}
        primaryButton={{
          name: t("UserManagement:allDeviceBtn"),
          action: logoutAllDevices,
        }}
        secondaryButton={{
          name: t("UserManagement:thisDeviceBtn"),
          action: logoutCurrentDevice,
        }}
        onModalClose={onLogoutModalClose}
        small={true}
        modalId='logout-prompt'
      />

      <DeleteAccountModal
        onDeleteAccount={onDeleteAccount}
        isDeleteConfirmVisible={showDeleteAccount}
        setIsDeleteConfirmVisible={setShowDeleteAccountModal}
      />
    </>
  );
};

export default HeaderAccountButton;
