import "./styles.scss";

import { useMediaQuery } from "hooks/useMediaQuery";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { MenuModels, MenuModuleUtils } from "gyg_common";
import SliderCard from "views/components/shared/SliderCard";

export interface FeaturedItemCarouselProps {
  featuredItems: MenuModels.FeaturedItemType[];
  onItemPress: (item: MenuModels.FeaturedItemType) => void;
}

const FeaturedItemCarousel: React.FC<FeaturedItemCarouselProps> = ({
  featuredItems,
  onItemPress,
}) => {
  const { isTabletScreen } = useMediaQuery();

  const renderFeaturedCard = (item: MenuModels.FeaturedItemType) => {
    const images = MenuModuleUtils.getFeaturedItemImages(item);
    return (
      <div
        className='featured-item'
        data-testid='FeaturedItemSlide'
        key={`featured-item-${item.id}`}>
        {images && (
          <SliderCard
            imageUrl={images.imageUrl as string}
            imageWideUrl={images.imageWideUrl as string}
            onClick={() => onItemPress(item)}
            showStatus={false}
          />
        )}
      </div>
    );
  };

  const renderCards = () => {
    return featuredItems.map((item, index: number) => {
      return (
        <div id={`FeaturedItem-${item.id}`} key={`FeaturedItem - ${item.id}`}>
          <SwiperSlide key={`key- ${index}`}>
            {renderFeaturedCard(item)}
          </SwiperSlide>
        </div>
      );
    });
  };

  return featuredItems.length > 0 ? (
    <Swiper
      slidesPerView={1}
      observeParents
      observer
      resizeObserver
      freeMode={false}
      spaceBetween={isTabletScreen ? 16 : 8}
      watchOverflow={true}
      pagination={{
        clickable: true,
      }}>
      <div data-testid='FeaturedItemCarousel'>{renderCards()}</div>
    </Swiper>
  ) : null;
};

export default FeaturedItemCarousel;
