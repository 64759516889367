import "./style.scss";
import "styles/braintree.scss";

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import config from "config";
import { RootState } from "gyg_common";
import CardSticker from "gyg_common/components/CardSticker";
import OrderStatusReceiptTable from "gyg_common/components/OrderStatus/OrderStatusReceiptTable";
import { PaymentType } from "gyg_common/redux_store/checkout/model";
import { OrderResponse } from "gyg_common/services/api/order/model";

export interface PaymentReceiptProps {
  order: OrderResponse | null;
  deliveryFee?: number;
}

const PaymentReceipt: React.FC<PaymentReceiptProps> = ({
  order,
  deliveryFee,
}) => {
  const { t } = useTranslation();
  const { redeemDollars } = useSelector((s: RootState) => s.loyalty);
  const basket = useSelector(
    (s: RootState) => s.order.getOrderResponse?.basket
  );
  const total = basket?.total ?? 0;
  // only display extra breakdown if GyG dollar is applied
  const paymentTypes = redeemDollars
    ? [
        { type: PaymentType.BRAINTREE, amount: total },
        { type: PaymentType.GYGDOLLAR, amount: redeemDollars || 0 },
      ]
    : [];

  return (
    <CardSticker>
      <div className='receipt-container'>
        <p className='receipt-container__title'>
          {t("CheckoutPayment:checkoutOrderTitle")}
        </p>
        {order && (
          <OrderStatusReceiptTable
            isPreCheckoutMode
            isShowSurchargePercent={false}
            locale={config.version}
            order={order}
            paymentTypes={paymentTypes}
            deliveryFee={deliveryFee}
          />
        )}
      </div>
    </CardSticker>
  );
};

export default PaymentReceipt;
