import useAddToOrder from "hooks/useAddToOrder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  ProductConst,
  RootState,
} from "gyg_common";
import CustomizableProduct from "gyg_common/components/Products/CustomizableProduct/CustomizableProduct";
import { getPreSelectedMealId } from "gyg_common/modules/Products/utils";

export interface CustomizableProductContainerProps {
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  index?: number;
  cartItem?: CartReduxModels.CartItem;
  commonSection?: MenuModels.CommonSection;
  isUpsell?: boolean;
  goBack: () => void;
  onClose: () => void;
}

export default function CustomizableProductContainer(
  props: CustomizableProductContainerProps
): JSX.Element {
  const {
    category,
    goBack,
    editMode,
    index,
    onClose,
    cartItem,
    commonSection,
    isUpsell,
  } = props;

  const preSelectedMealId = getPreSelectedMealId({
    editMode,
    cartItem,
  });
  const [selectedMealId, updateSelectedMealId] = useState<number | undefined>(
    preSelectedMealId
  );
  const [selectedMIAMOptionMealId, updateSelectedMIAMOptionMealId] = useState<
    number | undefined
  >(preSelectedMealId);

  const { MIAMItem } = useSelector((state: RootState) => state.cart);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const { coffeeLoyalty } = useSelector((s: RootState) => s.loyalty);
  const dispatch = useDispatch();

  const [showCustomisableMIAMoption, setShowCustomisableMIAMoption] =
    useState<boolean>(false);
  const [selectedMIAMcategory, setSelectedMIAMcategory] = useState<{
    category: MenuModels.Category;
    index: number;
  }>();
  const [temporaryCartItem, setTemporaryCartItem] = useState<{
    mainCartItem: CartReduxModels.CartItem | undefined;
    MIAMcartItem: CartReduxModels.CartItem | undefined;
  }>({ mainCartItem: undefined, MIAMcartItem: undefined });

  const updateSelectedMealIdAndMIAMOptionId = (id: number | undefined) => {
    updateSelectedMealId(id);
    updateSelectedMIAMOptionMealId(id);
  };

  const handleResetMIAMItems = () => {
    dispatch(CartReduxAction.clearMIAMItem());
  };

  const onCloseMiamOption = () => {
    setShowCustomisableMIAMoption(false);
  };

  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: showCustomisableMIAMoption
      ? selectedMIAMcategory?.category?.name
      : (category?.name ?? ""),
    onClose: showCustomisableMIAMoption ? onCloseMiamOption : onClose,
  });

  const handleSelectMIAMoption = (
    optionCartItem: CartReduxModels.CartItem | undefined,
    optionIndex: number
  ) => {
    dispatch(
      CartReduxAction.updateMIAMItem({
        cartItem: optionCartItem,
        index: optionIndex,
      })
    );
  };

  const handleMIAMcustomisableOptionSelected = (
    categoryMiam: MenuModels.Category,
    temporaryCartItems: CartReduxModels.CartItem[] | undefined,
    indexMiam: number
  ) => {
    if (temporaryCartItems?.length === 1) {
      setSelectedMIAMcategory({ category: categoryMiam, index: indexMiam });
      setShowCustomisableMIAMoption(true);
      setTemporaryCartItem({
        mainCartItem: temporaryCartItems[0],
        MIAMcartItem: undefined,
      });
    } else if (temporaryCartItems?.length === 2) {
      setSelectedMIAMcategory({ category: categoryMiam, index: indexMiam });
      setShowCustomisableMIAMoption(true);
      setTemporaryCartItem({
        mainCartItem: temporaryCartItems[0],
        MIAMcartItem: temporaryCartItems[1],
      });
    } else {
      setShowCustomisableMIAMoption(false);
      setTemporaryCartItem({
        mainCartItem: undefined,
        MIAMcartItem: undefined,
      });
    }
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductCategory, {
      product_category_id: category.id,
      product_category_name: category.name,
      product_quantity: cartItem?.quantity ?? 1,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
  }, [cartItem, category, editMode, selectedStore]);

  useEffect(() => {
    if (showCustomisableMIAMoption && selectedMIAMcategory) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductCategory, {
        product_category_id: selectedMIAMcategory.category.id,
        product_category_name: selectedMIAMcategory.category.name,
        product_quantity: 1,
        view_mode: ProductConst.EditMode.UPDATE_MIAM_ITEM,
        store_id: selectedStore?.id,
        store_name: selectedStore?.name,
      });
    }
  }, [
    showCustomisableMIAMoption,
    selectedMIAMcategory,
    selectedStore?.id,
    selectedStore?.name,
  ]);

  return (
    <>
      {showCustomisableMIAMoption ? (
        <CustomizableProduct
          selectedMealId={selectedMIAMOptionMealId}
          updateSelectedMealId={updateSelectedMIAMOptionMealId}
          key='MIAM-customisable-option'
          MIAMItem={MIAMItem}
          cartItem={
            MIAMItem && selectedMIAMcategory
              ? MIAMItem[selectedMIAMcategory.index]
              : undefined
          }
          handleResetMIAMItems={handleResetMIAMItems}
          handleAddToOrder={handleAddToOrder}
          index={selectedMIAMcategory?.index}
          editMode={ProductConst.EditMode.UPDATE_MIAM_ITEM}
          goBack={() => setShowCustomisableMIAMoption(false)}
          category={selectedMIAMcategory?.category as MenuModels.Category}
          fromMIAM={temporaryCartItem.mainCartItem ? true : false}
          coffeeLoyalty={coffeeLoyalty}
        />
      ) : (
        <CustomizableProduct
          selectedMealId={selectedMealId}
          updateSelectedMealId={updateSelectedMealIdAndMIAMOptionId}
          key='main-complex-customisable-product'
          MIAMItem={MIAMItem}
          handleResetMIAMItems={handleResetMIAMItems}
          index={index}
          editMode={editMode}
          handleAddToOrder={handleAddToOrder}
          goBack={goBack}
          category={category as MenuModels.Category}
          cartItem={temporaryCartItem.mainCartItem || cartItem}
          MIAMCartItem={temporaryCartItem.MIAMcartItem}
          commonSection={commonSection}
          handleMIAMsimpleOptionSelected={handleSelectMIAMoption}
          handleMIAMcustomisableOptionSelected={
            handleMIAMcustomisableOptionSelected
          }
          coffeeLoyalty={coffeeLoyalty}
        />
      )}
    </>
  );
}
