import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ApiOrderModel,
  CheckoutAction,
  OrderCardModels,
  OrderModules,
  OrderReduxAction,
} from "gyg_common";
import { OrderCard } from "gyg_common/components/OrderCard";
import { Screens } from "navigation/const";
import { clearAndNavigateToOrderStatusScreen } from "navigation/utils";

interface OrderCardContainerProps {
  isFullWidth?: boolean;
  orderProps: OrderCardModels.OrderCardViewProps;
  reorderButtonPress: (item: ApiOrderModel.BasketItem[]) => void;
}

export const OrderCardContainer: React.FC<OrderCardContainerProps> = ({
  isFullWidth,
  orderProps,
  reorderButtonPress,
}) => {
  const { checkoutResponse } = orderProps;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOlderThanActiveOrderThreshold =
    OrderModules.OrderUtils.isOlderThanActiveOrderThreshold(checkoutResponse);

  const goToOrderStatus = () => {
    dispatch(CheckoutAction.initPaymentSuccess(checkoutResponse));
    if (checkoutResponse?.order?.orderId) {
      dispatch(
        OrderReduxAction.getOrderStatus({
          orderId: checkoutResponse.order.orderId,
        })
      );

      if (isOlderThanActiveOrderThreshold && !checkoutResponse.order.delivery) {
        navigate(Screens.OrderDetails);
      } else {
        clearAndNavigateToOrderStatusScreen(navigate, dispatch);
      }
    } else {
      //Fetch recent orders to get correct data
      dispatch(OrderReduxAction.getAuthenticatedOrders());
    }
  };

  return (
    <OrderCard
      isFullWidth={isFullWidth}
      isOlderThanActiveOrderThreshold={isOlderThanActiveOrderThreshold}
      orderCardViewProps={orderProps}
      primaryButtonText={t("RecentOrders:reorderButton")}
      secondaryButtonText={t("RecentOrders:viewDetailsButton")}
      onPrimaryButtonPress={() =>
        reorderButtonPress(checkoutResponse.order.basket.basketItems)
      }
      onSecondaryButtonPress={goToOrderStatus}
      goToOrderStatus={goToOrderStatus}
    />
  );
};
