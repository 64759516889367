import loadingText from "assets/gif/loadingtext.json";
import dropin from "braintree-web-drop-in";
import branch from "branch-sdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  BranchConst,
  GomexModules,
  GuestDetailModels,
  GuestReduxAction,
  LoyaltyActions,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  RootState,
} from "gyg_common";
import questionImage from "gyg_common/components/assets/images/illustration_error_question.png";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { CheckoutErrorModal } from "gyg_common/components/modals/CheckoutErrorModal";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { OrderSetupGlobalState } from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import useHandleOrderError from "gyg_common/hooks/useHandleOrderError";
import { useInitPayment } from "gyg_common/hooks/usePayment";
import { DeliveryOrder } from "gyg_common/redux_store/order/models";
import { Screens } from "navigation/const";
import { clearAndNavigateToOrderStatusScreen } from "navigation/utils";
import { Payment } from "views/components/Payment";

const GOOGLE_MERCHANT_ID = config.googleMerchantId;
const PATCH_ORDER_ANIMATION_HIDE_DELAY = 1000;

const PaymentContainer: React.FC = () => {
  // hooks
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isFocused = pathname === Screens.Payment;

  // redux state
  const {
    clientTokenResponse,
    paymentSuccess,
    getClientTokenLoading,
    paymentResponse,
    loading: checkoutLoading,
  } = useSelector((s: RootState) => s.checkout);
  const { firstName, lastName, phoneNumber, email } = useSelector(
    (s: RootState) => s.guest
  );
  const {
    getOrderLoading,
    getOrderResponse,
    getOrderError,
    orderASAP,
    orderTime,
    updateOrderLoading,
    delivery,
    deliveryAddress,
    orderCollectionType,
  } = useSelector((s: RootState) => s.order);
  const { loyalty, coffeeLoyalty } = useSelector(
    (state: RootState) => state.loyalty
  );
  const { guestLoyalty } = useSelector((s: RootState) => s.guest);
  const { redeemDollars } = useSelector((s: RootState) => s.loyalty);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const { profile } = useSelector((s: RootState) => s.user);
  const basket = useSelector(
    (s: RootState) => s.order.getOrderResponse?.basket
  );
  const { isTimeout } = useSelector((s: RootState) => s.braintree);
  const analyticsState = useSelector((s: RootState) => s.analytics);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);
  const { items: cartItems } = useSelector((s: RootState) => s.cart);
  // variables
  const items = basket?.basketItems || [];
  const total = basket?.total ?? 0;

  const fullLoyaltyPayment = isAuthSuccess && total === 0;
  const clientToken = clientTokenResponse.clientToken;
  const supportedNetworks = [
    "amex",
    "discover",
    "maestro",
    "masterCard",
    "visa",
  ];
  const isDelivery =
    orderCollectionType === OrderReduxModels.CollectionType.DELIVERY;
  const orderReceiptInfo =
    OrderModules.OrderUtils.formatOrderResponse(getOrderResponse);

  const dropInObject: dropin.Options = {
    authorization: clientToken,
    container: "#dropin-container",
    dataCollector: true,
    vaultManager: true,
    paypal: {
      flow: "checkout",
      amount: total.toFixed(2),
      currency: t("CheckoutPayment:currencyCode"),
      commit: true,
    },
    googlePay: {
      googlePayVersion: 2,
      merchantId: GOOGLE_MERCHANT_ID,
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPrice: total.toFixed(2),
        currencyCode: t("CheckoutPayment:currencyCode"),
      },
    },
    applePay: {
      displayName: t("CheckoutPayment:storeLabel"),
      paymentRequest: {
        countryCode: config.version,
        supportedNetworks: supportedNetworks,
        currencyCode: t("CheckoutPayment:currencyCode"),
        merchantCapabilities: [
          "supports3DS",
          "supportsCredit",
          "supportsDebit",
        ],
        total: {
          label: t("CheckoutPayment:storeLabel"),
          amount: total.toFixed(2),
        },
        requiredBillingContactFields: ["postalAddress"],
        requiredShippingContactFields: ["postalAddress"],
        shippingContact: ["postalAddress"],
      },
    },
  };

  // local state
  const [newPointsAndDollars, setNewPointsAndDollars] =
    useState<GomexModules.GomexUtils.GomexPointsAndDollars>({
      points: 0,
      dollars: 0,
    });
  const [recaptchaCode, setRecaptchaCode] = useState<string | null>();
  const [dropInInstance, setDropInInstance] = useState<dropin.Dropin>();
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentOrderId, setCurrentOrderId] = useState(
    getOrderResponse?.orderId
  );
  const [isPatchOrdering, setIsPatchOrdering] = useState(false);

  const orderTotal = basket?.total ?? 0;
  const initPayment = useInitPayment(store, orderTotal, {
    webBrainTreeInstance: dropInInstance,
    webSetBrainTreeInstance: setDropInInstance,
  });

  const loadingAnimation = useMemo(() => {
    if (checkoutLoading) {
      return loadingText;
    } else {
      return undefined;
    }
  }, [checkoutLoading]);

  /**
   * Triggers braintree and prepares payload for payment.
   */
  const proceedWithPayment = useCallback(() => {
    setIsLoading(false);
    initPayment(false);
  }, [initPayment]);

  const confirmWarningModal = () => {
    setShowWarning(false);
    if (
      OrderModules.OrderUtils.isOrderTimeAsapExpired(orderTime, orderASAP) &&
      getOrderResponse
    ) {
      dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      if (currentOrderId) {
        setIsLoading(true);
      }
    }
  };

  const goBack = () => {
    if (showWarning) {
      setShowWarning(false);
    }
    navigate(Screens.Order);
  };

  const onCheckoutErrorModalAction = useHandleOrderError({
    store,
    webBrainTreeInstance: dropInInstance,
  });

  /**
   * Checks if applied rewards value is above the order value.
   */
  const onPayNow = () => {
    if (paymentSuccess) {
      //Move to Order status screen if checkout response exist
      OrderSetupGlobalState.getInstance().resetOrderSetupStates();
      clearAndNavigateToOrderStatusScreen(navigate, dispatch, {
        previousScreen: pathname,
        ...state,
      });
      return;
    }
    proceedWithPayment();
  };

  /**
   * Returns new points and dollars for future loyalty balance.
   */
  const getNewPointsAndDollars = useCallback(() => {
    const pointsAndDollars: GomexModules.GomexUtils.GomexPointsAndDollars =
      isAuthSuccess
        ? {
            points: getOrderResponse?.loyalty?.afterCheckoutPointsBalance || 0,
            dollars:
              getOrderResponse?.loyalty?.afterCheckoutDollarsBalance || 0,
          }
        : {
            points: guestLoyalty?.accruedPoints || 0,
            dollars: guestLoyalty?.convertedDollars || 0,
          };

    return pointsAndDollars;
  }, [isAuthSuccess, getOrderResponse?.loyalty, guestLoyalty]);

  /**
   * Updates future loyalty balance.
   */
  useEffect(() => {
    if (guestLoyalty || getOrderResponse?.loyalty) {
      setNewPointsAndDollars(getNewPointsAndDollars());
    }
  }, [guestLoyalty, getOrderResponse?.loyalty, getNewPointsAndDollars]);

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "checkout_details",
        page_type: "checkout_details_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    if (!getClientTokenLoading && clientToken) {
      dropin.create(dropInObject, (_, dropInInstance) => {
        // only set the dropin if it exists
        // on payment error, this instance is undefined
        if (dropInInstance) {
          setDropInInstance(dropInInstance);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken, getClientTokenLoading]);

  useEffect(() => {
    if (paymentSuccess && paymentResponse) {
      setIsLoading(false);
      branch.logEvent(
        BranchConst.BranchEvents.Purchase,
        {
          branchId: profile?.branchId ?? "",
        },
        items
      );

      //track user custom attributes only for logged in users
      if (selectedStore?.name && isAuthSuccess) {
        AnalyticsInstance.setCustomAttribute(
          AnalyticsConst.CustomAttribute.LastStoreOrdered,
          selectedStore.name
        );
      }

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.PayOrder,
        AnalyticsPayloadGenerator.payOrderPayload(
          analyticsState,
          AnalyticsConst.DeviceSource.WEB
        )
      );
      OrderSetupGlobalState.getInstance().resetOrderSetupStates();
      clearAndNavigateToOrderStatusScreen(navigate, dispatch, {
        previousScreen: pathname,
        ...state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSuccess, history, paymentResponse]);

  useEffect(() => {
    if (
      (!getOrderLoading &&
        !isPatchOrdering &&
        getOrderResponse &&
        getOrderResponse.basket.errors) ||
      getOrderError
    ) {
      // navigate back to order view
      navigate(Screens.Order);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderResponse, getOrderError, getOrderLoading, isPatchOrdering]);

  //update redeemGyg value
  useEffect(() => {
    if (
      getOrderResponse?.gygDollars?.applied !== undefined &&
      getOrderResponse?.gygDollars?.applied !== redeemDollars
    ) {
      dispatch(
        LoyaltyActions.updateRedeemDollars(
          getOrderResponse?.gygDollars?.applied
        )
      );
    }
    if (getOrderResponse) {
      setCurrentOrderId(getOrderResponse?.orderId);
    }
  }, [dispatch, getOrderResponse, redeemDollars]);

  /** Adds delay after patch order response came to avoid user tap Pay Now button in delay of animations changes */
  useEffect(() => {
    if (!updateOrderLoading) {
      setTimeout(() => {
        setIsPatchOrdering(false);
      }, PATCH_ORDER_ANIMATION_HIDE_DELAY);
    } else {
      setIsPatchOrdering(true);
    }
  }, [updateOrderLoading]);

  const listenReloadActions = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "";
  };

  /** Prevent user to move from screen if checkout loading */
  const listenToPopActions = () => {
    if ((checkoutLoading || getOrderLoading || isPatchOrdering) && isFocused) {
      history.go(1);
    }
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ViewCheckout,
      AnalyticsPayloadGenerator.viewCheckoutPayload(
        analyticsState,
        isAuthSuccess,
        // FIXME compile error occurred due to upstream redux type constraint
        // ie. should this event emit if 'delivery' is null? (this is existing behavior)
        delivery as DeliveryOrder
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthSuccess]);

  /** Listen to navigation changes and prevent user to move from screen or refresh the screen without popup confirmation */
  useEffect(() => {
    window.addEventListener("beforeunload", listenReloadActions);
    window.addEventListener("popstate", listenToPopActions);
    return () => {
      window.removeEventListener("beforeunload", listenReloadActions);
      window.removeEventListener("popstate", listenToPopActions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGuestTextChange = (props: GuestDetailModels.GuestDetailsState) => {
    dispatch(GuestReduxAction.updateGuestDetails(props));
  };

  const goToLogin = () => {
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Order } });
  };

  return (
    <>
      <LoadingScreen
        loading={
          (checkoutLoading ||
            isLoading ||
            getClientTokenLoading ||
            getOrderLoading ||
            isPatchOrdering) &&
          !isTimeout
        }
        json={loadingAnimation}
        description={
          checkoutLoading
            ? t("OrderManagement:orderProcessingDescriptionWeb")
            : undefined
        }
      />
      <CheckoutErrorModal
        onModalActionButtonPress={onCheckoutErrorModalAction}
      />
      <Payment
        isLoggedIn={isAuthSuccess}
        order={getOrderResponse}
        fullLoyaltyPayment={fullLoyaltyPayment}
        isDisabled={!recaptchaCode}
        onGoogleReCAPTCHA={setRecaptchaCode}
        onPayClick={onPayNow}
        goToLogin={goToLogin}
        isLoading={isLoading}
        deliveryFee={delivery?.fee}
        deliveryAddress={deliveryAddress}
        delivery={delivery}
        isDeliveryCheckout={isDelivery}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        email={email}
        onGuestTextChange={onGuestTextChange}
        coffeeLoyalty={coffeeLoyalty}
        loyalty={loyalty ?? undefined}
        guestLoyalty={guestLoyalty}
        data={cartItems}
        getOrderLoading={getOrderLoading}
        newPointsAndDollars={newPointsAndDollars}
        redeemDollars={orderReceiptInfo.redeemDollars}
        goBack={goBack}
      />
      <ModalWithButton
        isVisible={showWarning}
        small={true}
        title={t("Loyalty:loyaltyWarningTitle")}
        messageBold={t("Loyalty:loyaltyWarningCopy")}
        primaryButton={{
          name: t("Loyalty:loyaltyWarningConfirm"),
          action: confirmWarningModal,
        }}
        secondaryButton={{
          name: t("Loyalty:loyaltyWarningBack"),
          action: goBack,
        }}
        image={questionImage as ImageSourcePropType}
        onModalClose={() => setShowWarning(false)}
        modalId='reward-warning-moodal'
      />
    </>
  );
};

export default PaymentContainer;
