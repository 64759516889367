import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";

import LoadingScreen from "gyg_common/components/LoadingScreen";
import { useUntilHandled } from "gyg_common/hooks/useUntilHandled";
import { useValidateCustomisableProduct } from "gyg_common/hooks/useValidateProduct";
import { OpenTemplateParams } from "gyg_common/navigation";

import {
  CartReduxModels,
  locales,
  LoyaltyModels,
  MenuModels,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
  RootState,
} from "../../../";
import useIsFocused from "../../../hooks/useIsFocused";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import useUpdateOrder from "../../../hooks/useUpdateOrder";
import { mapButtonTitle } from "../../../modules/Cart/utils";
import {
  HandleUpdateOrder,
  ModifyOrderType,
} from "../../../modules/Order/models";
import { CartItem } from "../../../redux_store/cart/models";
import colours from "../../styles/colours";
import AddModifyCartItem from "../AddModifyCartItem";
import ProductDetailSticky from "../ProductDetailSticky";
import CustomizableProductList from "./CustomizableProductList";

export interface CustomizableProductProps {
  cartItem?: CartReduxModels.CartItem;
  category: MenuModels.Category;
  goBack: () => void;
  editMode: ProductConst.EditMode;
  index?: number;
  commonSection?: MenuModels.CommonSection;
  MIAMItem?: CartReduxModels.BundleState;
  MIAMCartItem?: CartReduxModels.CartItem;
  handleResetMIAMItems?: () => void;
  handleMIAMsimpleOptionSelected?: (
    optionCartItem: CartReduxModels.CartItem | undefined,
    index: number
  ) => void;
  handleMIAMcustomisableOptionSelected?: (
    category: MenuModels.Category,
    cartItems: CartReduxModels.CartItem[] | undefined,
    index: number
  ) => void;
  handleAddToOrder: (params: HandleUpdateOrder) => void;
  openTemplate?: (
    params: OpenTemplateParams,
    cartItems?: CartReduxModels.CartItem[]
  ) => void;
  selectedMealId: number | undefined;
  updateSelectedMealId: (id: number | undefined) => void;
  fromMIAM?: boolean;
  coffeeLoyalty?: LoyaltyModels.CoffeeLoyalty | null;
}

export interface ExpandListState {
  [key: string]: boolean;
}

export interface RenderOptionsItemProps {
  item: ProductModuleModel.CustomizeOptionsDataProps;
  index: number;
}

const styles = StyleSheet.create({
  titleSection: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 24,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    alignItems: "center",
  },
  container: {
    flex: 1,
    backgroundColor: colours.white,
  },
});

const CustomizableProduct = (props: CustomizableProductProps): JSX.Element => {
  const {
    category,
    editMode,
    index,
    cartItem,
    commonSection,
    MIAMItem,
    MIAMCartItem,
    handleResetMIAMItems,
    handleMIAMsimpleOptionSelected,
    goBack,
    handleAddToOrder,
    openTemplate,
    handleMIAMcustomisableOptionSelected,
    selectedMealId,
    updateSelectedMealId,
    fromMIAM,
    coffeeLoyalty,
  } = props;

  const isFocused = useIsFocused();
  const { isDesktopScreen } = useMediaQuery();
  const isWebDesktop = Platform.OS === "web" && isDesktopScreen;
  const { isLoading } = useSelector((s: RootState) => s.favourite);
  // constants
  const initialState = useRef(
    ProductUtils.generateCustomizableOptionState(category)
  );
  const ignoreCartItemInitiation = useRef(false);
  const fadeAnimationSticky = useRef(new Animated.Value(0)).current;
  const [stickyHeaderVisible, setStickyHeaderVisible] = useState(false);
  const customizeOptions = useRef<ProductModuleModel.CustomizableOptionState>(
    initialState.current
  );

  const [customisableSectionData, setCustomisableSectionData] = useState<
    ProductModuleModel.CustomizeOptionsDataProps[]
  >([]);
  const [quantity, setQuantity] = useState<number>(cartItem?.quantity || 1);

  const [mealCustomizeOptions, setMealCustomizeOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [mealRemoveModifierOptions, setMealRemoveModifierOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [requiredLength, setRequiredLength] = useState<number>(0);
  const [preSelectedMiamParts, setPreSelectedMiamParts] = useState<
    CartItem[] | undefined
  >();
  // use to force update
  const [, updateState] = useState<unknown>();

  // hooks
  const { i18n } = useTranslation();

  const handleUpdateOrder = useUpdateOrder(editMode, goBack);
  const { requiredFieldsError, errorIndex, clearErrorIndex, validateOrder } =
    useValidateCustomisableProduct();

  const customizableProductInfoProps =
    ProductUtils.getCustomizableProductInfoProps(
      category,
      customizeOptions.current,
      quantity,
      mealCustomizeOptions,
      selectedMealId,
      commonSection
    );

  const addButtonTitle = useMemo(() => {
    const isSelectionValid = validateOrder({
      customisableSectionData,
      mealCustomizeOptions,
      selectedMealId,
      customizeOptions: customizeOptions.current,
      skipErrorConfig: true,
    });

    return mapButtonTitle({
      locale: i18n.language as locales,
      selectedMealId,
      hasCommonSection: !!commonSection,
      isSelectionValid,
    });
  }, [
    commonSection,
    customisableSectionData,
    i18n.language,
    mealCustomizeOptions,
    selectedMealId,
    validateOrder,
  ]);

  // update selected product according to selection
  const handleUpdateProduct = useCallback(
    (customizeOptionsData: ProductModuleModel.CustomizeOptionsDataProps[]) => {
      let nextRemoveModiSelected: (number | string)[] = [];
      customizeOptionsData.forEach((n) => {
        // finds selected reverted toggle remove modifier
        if (
          category.categoryTags?.findIndex((tag) => tag.type === n.id) &&
          n.revertedToggleData
        ) {
          n.revertedToggleData?.forEach((modi) => {
            if (
              (
                customizeOptions.current[
                  ProductConst.SectionTitle.RemoveModifier
                ] as (number | string)[]
              )?.find((id) => modi.element.id === id)
            ) {
              nextRemoveModiSelected.push(modi.element.id);
            }
          });
        }
        // find customazation state (exclude identifier) and commonSection and multipartSection
        if (
          category.categoryTags?.findIndex((tag) => tag.type === n.id) === -1 &&
          !n.commonSection &&
          !n.multipartSection
        ) {
          const nextSelected =
            (customizeOptions.current[n.id] as (number | string)[])?.filter(
              (id) => {
                if (n.data?.find((d) => d.element.id === id)) {
                  return true;
                }
                if (n.combinedData?.modifierLookups?.find((m) => m.id === id)) {
                  return true;
                }
                return false;
              }
            ) ?? [];

          if (n.id === ProductConst.SectionTitle.RemoveModifier) {
            nextRemoveModiSelected =
              nextRemoveModiSelected.concat(nextSelected);
          } else {
            customizeOptions.current = {
              ...customizeOptions.current,
              [n.id]: nextSelected,
            };
          }
        }
      });

      // sets new state for remove ingridients
      customizeOptions.current = {
        ...customizeOptions.current,
        [ProductConst.SectionTitle.RemoveModifier]: nextRemoveModiSelected,
      };
    },
    [customizeOptions, category.categoryTags]
  );

  const updateProductSelection = useCallback(() => {
    const selectedMultiPart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const customizeOptionsData = ProductUtils.generateOptionsData(
      category,
      i18n.language as locales,
      ProductUtils.getSelectedProduct(customizeOptions.current, category),
      commonSection,
      selectedMultiPart,
      customizeOptions.current,
      selectedMealId
    );

    handleUpdateProduct(customizeOptionsData);
    setCustomisableSectionData(customizeOptionsData);
  }, [
    category,
    commonSection,
    handleUpdateProduct,
    i18n.language,
    selectedMealId,
  ]);

  // on force update, since we are tracking customisations user made
  // with ref to reduce re-renders
  const onForceUpdate = useCallback(() => {
    updateProductSelection();
    updateState({});
  }, [updateProductSelection]);

  /**
   * Updates local state of meal options with products from MIAMItem.
   */
  useEffect(() => {
    ProductUtils.preselectMiamParts(
      customisableSectionData,
      setMealCustomizeOptions,
      setMealRemoveModifierOptions,
      setPreSelectedMiamParts,
      MIAMItem,
      cartItem?.miamItem?.parts
    );
  }, [MIAMItem, cartItem?.miamItem?.parts, customisableSectionData]);

  /**
   * Updates required length of cartItems when MIAM is selected.
   */
  useEffect(() => {
    const productRoute = ProductUtils.getProductRoute(
      category?.templateId as string
    );
    if (
      productRoute === ProductConst.ProductRoute.ComplexCustomisableWithMeal &&
      category?.multiPart?.[0]
    ) {
      setRequiredLength(category?.multiPart?.[0].multiPartSection?.length || 0);
    } else {
      const selectedMultiPart = commonSection?.categories[0]?.multiPart?.find(
        (n) => n.id === selectedMealId
      );
      setRequiredLength(selectedMultiPart?.multiPartSection?.length || 0);
    }
  }, [commonSection, selectedMealId, category]);

  /**
   * Auto selects identifiers if only one product exists in category or there is only one option for identifier.
   */
  useUntilHandled(() => {
    if (category.products?.length === 1) {
      const onlyProduct: MenuModels.Product = category.products[0];
      const onlyOneOptionItem: CartReduxModels.CartItem = {
        productId: onlyProduct.id,
        name: onlyProduct.name,
        quantity: 1,
        unitPrice: onlyProduct.price,
        price: onlyProduct.price,
      };
      const option = ProductUtils.reloadCustomizableOptionStateFromCartItem(
        onlyOneOptionItem,
        category
      );
      customizeOptions.current = {
        ...customizeOptions.current,
        ...option.customizableOptionState,
      };
      return true;
    } else if (customisableSectionData.length) {
      customisableSectionData.forEach((section) => {
        if (section.isRequired && section.data && section.data.length === 1) {
          customizeOptions.current = {
            ...customizeOptions.current,
            [section.id]: section.data ? [section.data[0].element.id] : [],
          };
        }
      });
      onForceUpdate();
      return true;
    }
    return false;
  }, [category, customisableSectionData, onForceUpdate]);

  const onFocus = useCallback(() => {
    if (cartItem && !ignoreCartItemInitiation.current) {
      const option = ProductUtils.reloadCustomizableOptionStateFromCartItem(
        cartItem,
        category
      );
      customizeOptions.current = {
        ...customizeOptions.current,
        ...option.customizableOptionState,
      };
      if (!MIAMItem) {
        setMealCustomizeOptions((state) => {
          return {
            ...state,
            ...option.mealCustomizeOptions,
          };
        });
        setMealRemoveModifierOptions((state) => {
          return {
            ...state,
            ...option.mealRemoveModifierOptions,
          };
        });
      }
    }
    updateProductSelection();
  }, [MIAMItem, cartItem, category, updateProductSelection]);

  useEffect(() => {
    onFocus();
  }, [onFocus, isFocused]);

  const handleRemoveMeal = useCallback(() => {
    customizeOptions.current = initialState.current;
    ignoreCartItemInitiation.current = true;

    setMealRemoveModifierOptions({});
    setMealCustomizeOptions({});

    if (editMode !== ProductConst.EditMode.UPDATE_TACO_ITEM) {
      updateSelectedMealId(undefined);
    }

    // TODO: CustomizableProductContainer has this callback prop passed
    // but not in NavigateToTemplate logic, easy fix is to always dispatch from
    // this component
    if (
      handleResetMIAMItems &&
      editMode !== ProductConst.EditMode.UPDATE_MIAM_ITEM &&
      editMode !== ProductConst.EditMode.UPDATE_TACO_ITEM
    ) {
      handleResetMIAMItems();
    }
    onForceUpdate();

    setTimeout(() => {
      updateProductSelection();
    }, 100); //need a small delay to refresh default selection, fixes issue when drink size is selected after reset all button tapped but options are not visible
  }, [
    updateSelectedMealId,
    handleResetMIAMItems,
    editMode,
    onForceUpdate,
    updateProductSelection,
  ]);

  const getMealMultipart = useCallback(() => {
    const productRoute = ProductUtils.getProductRoute(
      category?.templateId as string
    );
    if (
      productRoute === ProductConst.ProductRoute.ComplexCustomisableWithMeal
    ) {
      return category?.multiPart?.[0];
    } else {
      return commonSection?.categories[0]?.multiPart?.find(
        (n) => n.id === selectedMealId
      );
    }
  }, [
    category?.multiPart,
    category?.templateId,
    commonSection?.categories,
    selectedMealId,
  ]);

  const onUpdateOrder = (updateOrderParams: HandleUpdateOrder) => {
    const isValid = validateOrder({
      customisableSectionData,
      mealCustomizeOptions,
      selectedMealId,
      customizeOptions: customizeOptions.current,
    });

    if (!isValid) {
      return;
    }

    if (updateOrderParams.type === ModifyOrderType.ADD) {
      handleAddToOrder(updateOrderParams);
    } else {
      handleUpdateOrder(updateOrderParams);
    }
  };

  const onSelectMeal = (id: number) => {
    setMealCustomizeOptions({});
    updateSelectedMealId(id);
  };

  const cartItems = ProductUtils.getCartItem(
    customizeOptions.current,
    category,
    quantity,
    getMealMultipart(),
    mealCustomizeOptions,
    mealRemoveModifierOptions,
    requiredLength,
    MIAMItem
  );

  return (
    <View key={category.name} style={styles.container}>
      <CustomizableProductList
        preSelectedMiamParts={preSelectedMiamParts}
        requiredFieldsError={requiredFieldsError}
        fadeAnimationSticky={fadeAnimationSticky}
        category={category}
        commonSection={commonSection}
        MIAMItem={MIAMItem}
        MIAMCartItem={MIAMCartItem}
        handleMIAMsimpleOptionSelected={handleMIAMsimpleOptionSelected}
        handleMIAMcustomisableOptionSelected={
          handleMIAMcustomisableOptionSelected
        }
        goBack={goBack}
        customisableSectionData={customisableSectionData}
        customizeOptions={customizeOptions}
        editMode={editMode}
        quantity={quantity}
        setQuantity={setQuantity}
        quantityDisabled={fromMIAM}
        mealCustomizeOptions={mealCustomizeOptions}
        selectedMealId={selectedMealId}
        updateSelectedMealId={onSelectMeal}
        handleRemoveMeal={handleRemoveMeal}
        getMealMultipart={getMealMultipart}
        mealRemoveModifierOptions={mealRemoveModifierOptions}
        errorIndex={errorIndex}
        clearErrorIndex={clearErrorIndex}
        onForceUpdate={onForceUpdate}
        setStickyHeaderVisible={setStickyHeaderVisible}
        openTemplate={openTemplate}
        customizableProductInfoProps={customizableProductInfoProps}
        cartItems={cartItems}
        coffeeLoyalty={coffeeLoyalty}
      />
      {!isWebDesktop && (
        <ProductDetailSticky
          fadeAnimationSticky={!isWebDesktop && fadeAnimationSticky}
          stickyHeaderVisible={!isWebDesktop && stickyHeaderVisible}
          onGoBack={props.goBack}
          hidePrice={editMode === ProductConst.EditMode.UPDATE_MIAM_ITEM}
          gobackMode={true}
          cartItems={cartItems}
          category={category}
          editMode={editMode}
          {...customizableProductInfoProps}
        />
      )}
      <AddModifyCartItem
        index={index}
        editMode={editMode}
        categoryName={category?.name ?? ""}
        onEditOrder={onUpdateOrder}
        onAddToOrder={onUpdateOrder}
        alwaysEnabled={Boolean(commonSection)}
        addButtonTitle={addButtonTitle}
        cartItems={cartItems}
      />
      <LoadingScreen loading={isLoading} disableDestroy />
    </View>
  );
};

export default CustomizableProduct;
