import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  ApiOrderModel,
  CheckoutModels,
  DashboardReduxAction,
  OrderCardModels,
  OrderCardUtils,
  RootState,
} from "gyg_common";
import chevronRight from "gyg_common/components/assets/icons/icon_chevron_right_small.png";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { addRecentOrderItemsToCart } from "modules/OrderCard";
import { Screens } from "navigation/const";
import { OrderSetupContainer } from "views/containers/OrderSetup/OrderSetupContainer";
import { RecentOrderAbandonModalContainer } from "views/containers/shared/RecentOrderAbandonModal/RecentOrderAbandonModalContainer";

import { OrderCardContainer } from "../../containers/shared/OrderCard/OrderCardContainer";

export interface CurrentRecentOrdersSectionProps {
  isCurrentOrders?: boolean;
  orders: CheckoutModels.CheckoutResponse[];
  onViewAllButtonClick?: () => void;
}

export const CurrentRecentOrdersSection: React.FC<
  CurrentRecentOrdersSectionProps
> = ({ isCurrentOrders, orders, onViewAllButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const { selectedStore } = useSelector((state: RootState) => state.store);
  const { menuStructure } = useSelector((state: RootState) => state.menu);

  const [isAbanadonModalVisible, setAbandonModalVisible] =
    useState<boolean>(false);
  const [showOrderSetup, setOrderSetup] = useState<boolean>(false);
  const [reorderItems, setReorderItems] = useState<ApiOrderModel.BasketItem[]>(
    []
  );
  const dispatch = useDispatch();
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  const orderCardProps = isCurrentOrders
    ? OrderCardUtils.mapCheckoutResponseToOrderCardProps(orders).reverse()
    : OrderCardUtils.mapCheckoutResponseToOrderCardProps(orders);

  /**
   * Trigger function when Reorder is pressed
   */
  const reorderButtonPress = (items: ApiOrderModel.BasketItem[]) => {
    setReorderItems(items);

    if (cartItems.length > 0) {
      setAbandonModalVisible(true);
    } else if (userConfirmedOrderSetup && menuStructure) {
      addRecentOrderItemsToCart(items, menuStructure);
    } else {
      if (selectedStore !== null || hasDelivery) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(items));
        setOrderSetup(true);
      } else {
        navigate(Screens.Restaurants, {
          state: {
            recentOrdersToAddToCart: items,
          },
        });
      }
    }
  };

  const onOrderSetupConfirmClick = () => {
    if (cartItems.length === 0 && menuStructure && !hasDelivery) {
      addRecentOrderItemsToCart(reorderItems, menuStructure);
    }
    setOrderSetup(false);
    navigate(Screens.Order);
  };

  return orders.length > 0 ? (
    <>
      <OrderSetupContainer
        onConfirmClick={onOrderSetupConfirmClick}
        onCloseModal={() => setOrderSetup(false)}
        isVisible={showOrderSetup}
      />

      <RecentOrderAbandonModalContainer
        isAbandonModalVisible={isAbanadonModalVisible}
        reorderItems={reorderItems}
        setAbandonModalVisible={setAbandonModalVisible}
        setOrderSetup={setOrderSetup}
      />

      <div className='dashboard__orders'>
        <div className='dashboard__orders-title--wrapper'>
          {onViewAllButtonClick && (
            <div className='dashboard__orders-button'>
              <button
                className='dashboard__orders-button-text'
                onClick={onViewAllButtonClick}>
                {t("RecentOrders:viewAllButton")}
              </button>
              <img
                className='dashboard__orders-button-icon'
                src={chevronRight}
              />
            </div>
          )}
        </div>
        <Swiper
          observeParents
          observer
          resizeObserver
          slidesPerView={1}
          spaceBetween={4}
          breakpoints={{
            568: {
              spaceBetween: 16,
              slidesPerView: 2,
            },
            1024: {
              spaceBetween: 16,
              slidesPerView: 3,
            },
          }}
          updateOnWindowResize
          freeMode={false}
          watchOverflow={true}
          pagination={{
            clickable: true,
          }}>
          <div>
            {orderCardProps.map(
              (item: OrderCardModels.OrderCardViewProps, index: number) => {
                return (
                  <div
                    id={`CurrentOrders-${item.orderNo}`}
                    key={`CurrentOrders - ${item.orderNo}`}>
                    <SwiperSlide key={`key- ${index}`}>
                      <div className='dashboard__orders-card'>
                        <OrderCardContainer
                          orderProps={item}
                          reorderButtonPress={reorderButtonPress}
                        />
                      </div>
                    </SwiperSlide>
                  </div>
                );
              }
            )}
          </div>
        </Swiper>
      </div>
    </>
  ) : null;
};
