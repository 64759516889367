import React from "react";
import Svg, { Path, Text } from "react-native-svg";

import { CoffeeCupState } from "../../../redux_store/loyalty/models";
import colours from "../../styles/colours";

interface CoffeeNumberIconProps {
  text: string;
  state: CoffeeCupState;
}

export const CoffeeNumberIcon: React.FC<CoffeeNumberIconProps> = ({
  text,
  state,
}) => {
  const textColor =
    state === CoffeeCupState.EMPTY
      ? colours.lightGrey
      : state === CoffeeCupState.PURCHASED
        ? colours.black
        : colours.uiGrey;
  const borderColor =
    state === CoffeeCupState.EMPTY ? colours.lightGrey : colours.uiGrey;
  const topColor = state === CoffeeCupState.EMPTY ? "none" : colours.white;
  const cupColor =
    state === CoffeeCupState.EMPTY
      ? "none"
      : state === CoffeeCupState.PURCHASED
        ? colours.yellow
        : colours.starburst;

  return (
    <Svg width='23' height='26' viewBox='0 0 23 26' fill='none'>
      {/* cup */}
      <Path
        d='M1 7.29658L1 4.65019H2.21149L4.1409 1H19.0826L20.8774 4.65019H21.9543V7.29658H20.8774L19.0826 25H4.1409L2.73721 12.1203L2.21149 7.29658H1Z'
        fill={cupColor}
      />
      <Path
        d='M2.21149 7.29658H1L1 4.65019H20.8774M2.21149 7.29658H20.8774M2.21149 7.29658L2.73721 12.1203L4.1409 25H19.0826L20.8774 7.29658M20.8774 7.29658H21.9543V4.65019H20.8774M2.21149 4.65019L4.1409 1H19.0826L20.8774 4.65019'
        stroke={borderColor}
      />
      {/* cap */}
      <Path
        d='M1.5 7V5H21.5V7H1.5Z'
        fill={topColor}
        stroke={borderColor}
        strokeWidth='0.2'
      />
      <Path
        d='M18.7667 1.5H4.43333L3 4.1H20.2L18.7667 1.5Z'
        fill={topColor}
        stroke={borderColor}
        strokeWidth='0.2'
      />
      {/* number */}
      {state == CoffeeCupState.PURCHASED ? (
        <Path
          d='M10.2607 19.9033C9.94824 19.9033 9.71387 19.791 9.50391 19.542L6.98438 16.4902C6.80859 16.29 6.74512 16.1094 6.74512 15.9043C6.74512 15.4453 7.08691 15.1084 7.56055 15.1084C7.83398 15.1084 8.0293 15.2061 8.20996 15.4209L10.2363 17.9355L14.2305 11.6367C14.4258 11.3291 14.626 11.2119 14.9482 11.2119C15.417 11.2119 15.7588 11.5439 15.7588 12.0029C15.7588 12.1738 15.7051 12.3545 15.5781 12.5498L11.0273 19.5078C10.8516 19.7764 10.5928 19.9033 10.2607 19.9033Z'
          fill='#171717'
        />
      ) : (
        <Text
          x='11.5'
          y='19'
          fontFamily='Montserrat-Regular'
          fontSize='10'
          textAnchor='middle'
          stroke={textColor}
          fill={textColor}>
          {text}
        </Text>
      )}
    </Svg>
  );
};
