import React, { useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { useSelector } from "react-redux";

import { RootState, StoreUtils } from "gyg_common";
import { CollectionType } from "gyg_common/redux_store/order/models";

import Surcharges from "./Surcharges";

const style = StyleSheet.create({
  container: {
    width: "100%",
  },
});

export function SurchargesContainer() {
  const { orderTime, orderCollectionType } = useSelector(
    (s: RootState) => s.order
  );
  const { selectedStore } = useSelector((s: RootState) => s.store);

  const [surcharges, setSurcharges] = useState<string[]>([]);

  useEffect(() => {
    if (selectedStore) {
      setSurcharges(
        StoreUtils.getSurchargesTextArray(orderTime, selectedStore.surcharges)
      );
    }
  }, [selectedStore, orderTime]);

  return orderCollectionType !== CollectionType.DELIVERY ? (
    <div id='surcharges-banner' style={style.container}>
      {surcharges?.length ? <Surcharges surcharges={surcharges} /> : null}
    </div>
  ) : null;
}
