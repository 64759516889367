import { StyleSheet } from "react-native";

import { Typography } from "styles/typography";

export const styles = StyleSheet.create({
  title: {
    ...Typography.titleTwo,
    marginBottom: 16,
  },
  buttonWrapper: {
    marginTop: 24,
  },
});
