import "./styles.scss";

import { useMediaQuery } from "hooks/useMediaQuery";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImageResizeMode, StyleSheet, View } from "react-native";
import { Swiper, SwiperSlide } from "swiper/react";

import { AuthStateModels, LoyaltyModels } from "gyg_common";
import { Spacing } from "gyg_common/components/styles/number";
import { isSmallerDevice } from "gyg_common/utils/device";

import SliderCard from "../shared/SliderCard";

const styles = StyleSheet.create({
  rewardsContainer: {
    paddingBottom: 0,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rewardContainer: {
    padding: Spacing.Thin,
    minWidth: isSmallerDevice() ? 200 : 390,
    minHeight: 150,
    flex: 1,
  },
});

export interface IRewardsCarouselProps {
  rewards: LoyaltyModels.IRewards[];
  currentUser: AuthStateModels.CurrentUser | undefined;
  onRewardItemPressed: (
    navigationParams: LoyaltyModels.IRewards,
    currentUser: AuthStateModels.CurrentUser | undefined
  ) => void;
  horizontalMode?: boolean | undefined;
}

export const RewardsCarousel: React.FC<IRewardsCarouselProps> = ({
  rewards,
  currentUser,
  onRewardItemPressed,
  horizontalMode,
}) => {
  const { isTabletScreen } = useMediaQuery();
  const { t } = useTranslation();

  const RewardSliderCard = (
    item: LoyaltyModels.IRewards,
    resizeMode?: ImageResizeMode
  ) => {
    return (
      <SliderCard
        testId='RewardsCarouselPressable'
        onClick={() => onRewardItemPressed(item, currentUser)}
        imageUrl={isTabletScreen ? item.webImage : item.image}
        showStatus={false}
        resizeMode={resizeMode}
        caption={`${t("Loyalty:expires")} ${moment(item.expiryDate).format(
          "D MMM YYYY"
        )}`}
      />
    );
  };

  const renderListItem = () =>
    rewards.map((item, index) => (
      <div id={`key- ${index}`} key={`key- ${index}`}>
        <SwiperSlide key={`key- ${index}`}>
          <div className='rewards__card'>{RewardSliderCard(item)}</div>
        </SwiperSlide>
      </div>
    ));

  return horizontalMode ? (
    <View style={styles.rewardsContainer}>
      {rewards.map((item) => {
        return (
          <View style={styles.rewardContainer} key={item.id}>
            {RewardSliderCard(item, "cover")}
          </View>
        );
      })}
    </View>
  ) : (
    <Swiper
      observeParents
      observer
      resizeObserver
      slidesPerView={1}
      freeMode={false}
      spaceBetween={isTabletScreen ? 16 : 8}
      watchOverflow
      pagination={{
        clickable: true,
      }}>
      <div>{renderListItem()}</div>
    </Swiper>
  );
};
