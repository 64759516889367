import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  ApiOrderModel,
  CartReduxAction,
  DashboardReduxAction,
  LoyaltyActions,
  OrderReduxAction,
  RootState,
} from "gyg_common";
import { RecentOrderAbandonModal } from "gyg_common/components/modals/RecentOrderAbandonModal";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { addRecentOrderItemsToCart } from "modules/OrderCard";
import { Screens } from "navigation/const";

interface RecentOrderAbandonModalContainerProps {
  reorderItems: ApiOrderModel.BasketItem[];
  isAbandonModalVisible: boolean;
  setAbandonModalVisible: (isVisible: boolean) => void;
  setOrderSetup: (isVisible: boolean) => void;
}

export const RecentOrderAbandonModalContainer: React.FC<
  RecentOrderAbandonModalContainerProps
> = ({
  reorderItems,
  isAbandonModalVisible,
  setAbandonModalVisible,
  setOrderSetup,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const analyticsState = useSelector((state: RootState) => state.analytics);
  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );
  const { menuStructure } = useSelector((state: RootState) => state.menu);
  const { selectedStore } = useSelector((state: RootState) => state.store);
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const hasCoffee = useFeatureIsOn(FeatureFlags.COFFEE_LOYALTY as string);
  /**
   * Trigger function when Continue With Order button is pressed
   */
  const keepCart = () => {
    setAbandonModalVisible(false);

    if (selectedStore && menuStructure) {
      if (!userConfirmedOrderSetup) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(reorderItems));
        setOrderSetup(true);
      } else {
        addRecentOrderItemsToCart(reorderItems, menuStructure);
        navigate(Screens.Order);
      }
    } else {
      if (hasDelivery) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(reorderItems));
        setOrderSetup(true);
      } else {
        navigate(Screens.Restaurants, {
          state: {
            recentOrdersToAddToCart: reorderItems,
          },
        });
      }
    }
  };

  /**
   * Trigger function when Abandon button is pressed
   */
  const abandonOrder = () => {
    setAbandonModalVisible(false);
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.AbandonedCart,
      AnalyticsPayloadGenerator.abandonCartPayload(analyticsState)
    );
    dispatch(CartReduxAction.clearCart());
    dispatch(OrderReduxAction.setOrderResponse(null));
    if (hasCoffee) dispatch(LoyaltyActions.getUserCoffeeLoyalty());

    if (selectedStore || hasDelivery) {
      if (!userConfirmedOrderSetup) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(reorderItems));
        setOrderSetup(true);
      } else {
        if (menuStructure) {
          addRecentOrderItemsToCart(reorderItems, menuStructure);
        }
        navigate(Screens.Order);
      }
    } else {
      navigate(Screens.Restaurants, {
        state: {
          recentOrdersToAddToCart: reorderItems,
        },
      });
    }
  };

  return (
    <RecentOrderAbandonModal
      isVisible={isAbandonModalVisible}
      abandonOrder={abandonOrder}
      keepCart={keepCart}
      setAbandonModalVisible={setAbandonModalVisible}
    />
  );
};
