import sign from "jwt-encode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import config from "config";
import {
  AnalyticsInstance,
  AuthReduxAction,
  RootState,
  StringValidators,
  UserReduxAction,
  UserReduxModels,
} from "gyg_common";
import { UpdatePassword } from "views/components/UserManagement/UpdatePassword";

import { EditModeType } from "./UserProfileContainer";

import { getDeviceId } from "@/RouterObserver/WebAuthObsever";

interface ChangePasswordContainerProps {
  email: string;
  onEditMode: EditModeType | undefined;
  setEditMode: (type: EditModeType | undefined) => void;
}

export const UpdatePasswordContainer: React.FC<
  ChangePasswordContainerProps
> = ({ email, onEditMode, setEditMode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isPasswordUpdated, profile } = useSelector((s: RootState) => s.user);
  const { currentUser } = useSelector((s: RootState) => s.login);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [currentPasswordError, setCurrentPasswordError] =
    useState<boolean>(false);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] =
    useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] =
    useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false);

  /**
   * empty all fields and reset edit mode
   */
  const resetUpdatePassword = () => {
    setEditMode(undefined);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (isPasswordUpdated) {
      if (isBrazeInitialised) {
        AnalyticsInstance.trackView({
          page_name: "change_password",
          page_type: "change_password_view",
        });
      }

      dispatch(
        UserReduxAction.setMessageToast({
          title: t("UserManagement:passwordUpdateSuccess"),
          showCloseButton: true,
          iconType: "check",
        })
      );
      dispatch(UserReduxAction.resetUserAccount());

      dispatch(
        AuthReduxAction.login({
          username: profile?.userName ?? "",
          password: newPassword,
          deviceId: getDeviceId(),
        })
      );

      resetUpdatePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordUpdated, isBrazeInitialised]);

  useEffect(() => {
    if (currentUser) {
      const secret = config.jwtSecret;
      const jwt = sign(currentUser, secret);
      localStorage.setItem("CurrentUser", jwt);
    }
  }, [currentUser]);

  const onChangePassword = () => {
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setCurrentPasswordError(false);

    const isFormInvalid =
      newPassword === "" ||
      confirmPassword === "" ||
      currentPassword === "" ||
      currentPassword === newPassword ||
      confirmPassword !== newPassword ||
      StringValidators.hasWhiteSpace(currentPassword) ||
      !StringValidators.isPasswordValid(newPassword);

    if (!isFormInvalid) {
      const changePasswordRequest: UserReduxModels.ChangePasswordRequest = {
        oldPassword: currentPassword,
        newPassword: newPassword,
      };
      dispatch(UserReduxAction.changeUserPassword(changePasswordRequest));
    } else {
      if (StringValidators.hasWhiteSpace(currentPassword)) {
        setCurrentPasswordError(true);
        setCurrentPasswordErrorMessage(t("UserManagement:passwordFormatError"));
      } else if (!StringValidators.isPasswordValid(newPassword)) {
        setNewPasswordError(true);
        setNewPasswordErrorMessage(t("UserManagement:passwordFormatError"));
      } else if (currentPassword === newPassword) {
        setNewPasswordError(true);
        setNewPasswordErrorMessage(t("UserManagement:passwordSameError"));
      } else if (confirmPassword !== newPassword) {
        setConfirmPasswordError(true);
      }
    }
  };

  const onSetPassword = () => {
    setNewPasswordError(false);
    setConfirmPasswordError(false);

    const isFormInvalid =
      newPassword === "" ||
      confirmPassword === "" ||
      confirmPassword !== newPassword ||
      !StringValidators.isPasswordValid(newPassword);

    if (!isFormInvalid) {
      const setPasswordRequest: UserReduxModels.SetPasswordRequest = {
        password: newPassword,
      };
      dispatch(UserReduxAction.setUserPassword(setPasswordRequest));
    } else {
      if (!StringValidators.isPasswordValid(newPassword)) {
        setNewPasswordError(true);
        setNewPasswordErrorMessage(t("UserManagement:passwordFormatError"));
      } else if (confirmPassword !== newPassword) {
        setConfirmPasswordError(true);
      }
    }
  };

  const onChangeClick = () => {
    setEditMode(EditModeType.PASSWORD);
  };

  const currentPasswordHandler = (text: string) => {
    setCurrentPassword(text);
  };

  const newPasswordHandler = (text: string) => {
    setNewPassword(text);

    if (newPasswordError) {
      setNewPasswordError(false);
    }
  };

  const confirmPasswordHandler = (text: string) => {
    setConfirmPassword(text);

    if (confirmPasswordError) {
      setConfirmPasswordError(false);
    }
  };

  return (
    <UpdatePassword
      email={email}
      type={
        profile?.activatedAuthenticationTypes.includes("Password")
          ? UserReduxModels.UpdatePasswordType.change
          : UserReduxModels.UpdatePasswordType.set
      }
      onEditMode={onEditMode}
      newPasswordErrorMessage={newPasswordErrorMessage}
      currentPassword={currentPassword}
      currentPasswordError={currentPasswordError}
      currentPasswordErrorMessage={currentPasswordErrorMessage}
      newPassword={newPassword}
      confirmPassword={confirmPassword}
      newPasswordError={newPasswordError}
      confirmPasswordError={confirmPasswordError}
      setCurrentPassword={currentPasswordHandler}
      setNewPassword={newPasswordHandler}
      setConfirmPassword={confirmPasswordHandler}
      onChangeClick={onChangeClick}
      onSaveClick={
        profile?.activatedAuthenticationTypes.includes("Password")
          ? onChangePassword
          : onSetPassword
      }
      onCancelClick={resetUpdatePassword}
    />
  );
};
