import React from "react";
import { Trans } from "react-i18next";
import { Platform, Pressable, Text, View } from "react-native";

import {
  CoffeeLoyalty,
  CoffeeLoyaltyCup,
} from "../../../../redux_store/loyalty/models";
import chevronRightWhite from "../../../assets/icons/icon_chevron_right_white.svg";
import { SvgIcon } from "../../../Icon/SvgIcon/SvgIcon";
import { CoffeeFreeIcon } from "../CoffeeFreeIcon";
import { CoffeeNumberIcon } from "../CoffeeNumberIcon";
import {
  countStyle,
  labelStyle,
  styleContainer,
  styles,
} from "./CoffeeLoyaltyInfo.styles";

export interface CoffeeLoyaltyInfoProps {
  isShowChevron?: boolean;
  useBlackFont?: boolean;
  loyalty: CoffeeLoyalty;
  onClick?: () => void;
}

export const CoffeeLoyaltyInfo: React.FC<CoffeeLoyaltyInfoProps> = ({
  isShowChevron,
  useBlackFont,
  loyalty,
  onClick,
}) => {
  // I apologize to future developers!!!
  // real life is very dangerous and
  // the application does not always get what it wants
  const newLineDetector = loyalty.message.includes("\\n");
  const messages = loyalty.message.split(newLineDetector ? "\\n" : "\n");

  return (
    <Pressable style={styleContainer(!!onClick).container} onPress={onClick}>
      {Platform.OS === "web" ? (
        <Text style={labelStyle(!!useBlackFont).label}>
          <Trans
            components={{
              highlightText: <Text style={styles.labelYellowText} />,
              small: <Text style={styles.smallText} />,
            }}>
            {loyalty.message.replace("FREE", " FREE ").replace("\\n", "<br>")}
          </Trans>
        </Text>
      ) : (
        <View style={styles.column}>
          {messages.map((item: string, index) => {
            const regex = /(<([^>]+)>)/gi;
            if (item.includes("small")) {
              const textValue = item.replace(regex, "");
              return (
                <Text
                  key={`loyatyMessage-${index}`}
                  style={labelStyle(!!useBlackFont).label}>
                  <Text style={styles.smallText}>{textValue}</Text>
                </Text>
              );
            } else if (item.includes("highlightText")) {
              const parts = item.split("FREE");

              const children = parts.map((part: string, i) => {
                const textValue = part.replace(regex, "");
                if (textValue.length == 0) {
                  return (
                    <View
                      key={`loyatyMessage-${index}-${i}`}
                      style={styles.labelWrapper}>
                      <Text style={styles.labelYellowText}>{"FREE"}</Text>
                    </View>
                  );
                }
                return (
                  <View
                    key={`loyatyMessage-${index}-${i}`}
                    style={labelStyle(!!useBlackFont).viewWrapper}>
                    <Text style={labelStyle(!!useBlackFont).labelSubPart}>
                      {textValue}
                    </Text>
                  </View>
                );
              });
              return (
                <View
                  key={`loyatyMessage-${index}`}
                  style={labelStyle(!!useBlackFont).labelWrapper}>
                  {children}
                </View>
              );
            } else {
              return (
                <Text
                  key={`loyatyMessage-${index}`}
                  style={labelStyle(!!useBlackFont).label}>
                  {item}
                </Text>
              );
            }
          })}
        </View>
      )}

      <View style={styles.coffeeIconContainer}>
        {loyalty.counter.map((item: CoffeeLoyaltyCup, index) => {
          return (
            <View key={`${item.text}+${index}`} style={styles.coffeeIcon}>
              <CoffeeNumberIcon text={item.text} state={item.status} />
            </View>
          );
        })}
        <View style={loyalty.freeCup.text != 0 ? styles.coffeeIcon : null}>
          <CoffeeFreeIcon
            text={loyalty.freeCup.text}
            enable={loyalty.freeCup.enable}
          />
          {loyalty.freeCup.text != 0 && (
            <View style={countStyle(loyalty.freeCup.enable).coffeeCount}>
              <Text style={styles.badgeText}>{loyalty.freeCup.text}</Text>
            </View>
          )}
        </View>
      </View>
      {isShowChevron && (
        <View style={styles.rightChevronContainer}>
          <SvgIcon
            src={chevronRightWhite}
            width={styles.rightChevronIcon.width}
            height={styles.rightChevronIcon.height}
          />
        </View>
      )}
    </Pressable>
  );
};
