import React from "react";
import { useTranslation } from "react-i18next";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import Spinner from "gyg_common/components/Buttons/Spinner";

import colours from "../styles/colours";
import { Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export interface LoyaltySpinnerProps {
  isLoading: boolean | undefined;
  noData?: boolean;
  onRetry?: () => void;
}

const loadingContainerTopSpacing = 50;

export const styles = StyleSheet.create({
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colours.darkGrey,
    top: loadingContainerTopSpacing,
    flexDirection: "row",
  },
  contentContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  spinnerContainer: {
    alignItems: "center",
    width: 50,
    height: 50,
  },
  gygYellowText: {
    ...Typography.titleThree,
    fontSize: 16,
    color: colours.yellow,
    paddingBottom: Platform.OS === "web" ? Spacing.Light : Spacing.ExtraLight,
  },
});
export const LoyaltySpinner: React.FC<LoyaltySpinnerProps> = ({
  isLoading = false,
  noData = false,
  onRetry,
}) => {
  const { t } = useTranslation();
  return (
    (isLoading || noData) && (
      <>
        <View style={styles.loadingContainer}>
          <TouchableOpacity
            disabled={!noData}
            onPress={onRetry}
            style={styles.contentContainer}>
            <View style={styles.spinnerContainer}>
              <Spinner color={colours.yellow} autoPlay={!noData} />
            </View>
            {noData && (
              <View>
                <Text style={styles.gygYellowText}>{t("Dashboard:retry")}</Text>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </>
    )
  );
};
