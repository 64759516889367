import config from "../../../config";
import { CurrentUser } from "../../../redux_store/auth/models";
import { httpClient } from "../../httpClient";
import {
  RegistrationPayload,
  SocialRegistrationPayload,
  SocialRegistrationResponse,
  VerificationCodePayload,
  VerifyCodePayload,
  VerifyCodeResponse,
} from "./model";

export async function getVerificationCode(
  data: VerificationCodePayload
): Promise<VerifyCodeResponse> {
  try {
    // console.debug("API: POST auth/verify/trigger/mobile");

    const response = await httpClient({
      url: "auth/verify/trigger/mobile",
      method: "POST",
      data: { ...data },
    });
    return response.data as VerifyCodeResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(
    //   e,
    //   "POST auth/verify/trigger/mobile"
    // );
    throw e;
  }
}

export async function verifyCode(
  data: VerifyCodePayload
): Promise<VerifyCodeResponse> {
  const isLoggedIn = data.isLoggedIn;
  delete data.isLoggedIn;
  try {
    // console.debug("API: POST auth/verify");

    const response = await httpClient({
      url: "auth/verify",
      method: "POST",
      data: { ...data },
      headers: isLoggedIn
        ? { Authorization: `${config.accessToken}` }
        : undefined,
    });
    return response.data as VerifyCodeResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/verify");
    throw e;
  }
}

export async function registerUser(
  data: RegistrationPayload
): Promise<CurrentUser> {
  try {
    // console.debug("API: POST auth/signup");

    const response = await httpClient({
      url: "auth/signup",
      method: "POST",
      data: data,
    });
    return response.data as CurrentUser;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/signup");
    throw e;
  }
}

export async function registerSocialUser(
  data: SocialRegistrationPayload
): Promise<SocialRegistrationResponse> {
  try {
    // console.debug("API: POST auth/socialsignup");

    const response = await httpClient({
      url: "auth/socialsignup",
      method: "POST",
      data: data,
    });
    return response.data as SocialRegistrationResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST auth/socialsignup");
    throw e;
  }
}
