import "./styles.scss";
import "styles/swiper.scss";

import barcodeIcon from "assets/icons/icon_barcode.svg";
import friendsIcon from "assets/icons/icon_friends.svg";
import openBrowserIcon from "assets/icons/icon_open_browser.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType, StyleSheet, Text, View } from "react-native";

import { LoyaltyModels, UserReduxModels } from "gyg_common";
import NavigationButton, {
  NavigationButtonIconSize,
} from "gyg_common/components/Buttons/NavigationButton";
import CardSticker from "gyg_common/components/CardSticker";
import { CoffeeLoyaltyFeatureFlag } from "gyg_common/components/Gomex/CoffeeLoyalty/CoffeeLoyaltyFeatureFlag";
import { CoffeeLoyaltyInfo } from "gyg_common/components/Gomex/CoffeeLoyalty/CoffeeLoyaltyInfo";
import GomexHeader from "gyg_common/components/Gomex/GomexHeader";
import GomexPointsView from "gyg_common/components/Gomex/GomexPointsView";
import GomexProgressBar from "gyg_common/components/Gomex/GomexProgressBar";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import UserProfileName from "gyg_common/components/UserProfileNameView";
import colours from "styles/colours";
import { Typography } from "styles/typography";
import { RewardsCarouselContainer } from "views/containers/Rewards/RewardsCarouselContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

const styles = StyleSheet.create({
  title: {
    ...Typography.sectionTitle,
    marginBottom: 16,
    display: "flex",
  },
});

export interface MyGomexProps {
  loyalty: LoyaltyModels.Loyalty | null;
  rewards: LoyaltyModels.IRewards[];
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  userProfile: UserReduxModels.UserProfile | null;
  goToAboutGomex: () => void;
  goToBarcode: () => void;
  goToReferFriend: () => void;
  loading: boolean;
}

export const MyGomex: React.FC<MyGomexProps> = ({
  userProfile,
  loyalty,
  rewards,
  coffeeLoyalty,
  goToAboutGomex,
  goToBarcode,
  goToReferFriend,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <View>
      <div className='mygomex'>
        <HeaderContainer pageTitle={t("Loyalty:myGomexTitle")} />

        {loading ? (
          <LoadingScreen loading={loading} />
        ) : (
          <div className='mygomex__container main-wrapper'>
            <div className='mygomex__container-top'>
              <UserProfileName
                firstName={userProfile?.firstName ?? ""}
                lastName={userProfile?.lastName ?? ""}
              />

              <div className='mygomex__barcode-btn'>
                <CardSticker>
                  <NavigationButton
                    testId={"BarcodeButton"}
                    title={t("Dashboard:barCodeBtn")}
                    onClick={goToBarcode}
                    rightIcon={barcodeIcon as ImageSourcePropType}
                    iconSize={NavigationButtonIconSize.BIG}
                  />
                </CardSticker>
              </div>
            </div>

            <div className='mygomex__section'>
              {rewards.length > 0 ? (
                <Text style={styles.title}>{t("Dashboard:rewardsTitle")}</Text>
              ) : null}
              <RewardsCarouselContainer horizontalMode={true} />
            </div>

            <div data-testid='GomexBlackCard' className='mygomex__section'>
              <CardSticker bgColor={colours.darkGrey}>
                <div className='mygomex__section--black-card'>
                  <GomexHeader />
                  <div className='mygomex__section--black-card__row'>
                    <div className='mygomex__section--black-card__points'>
                      <GomexPointsView
                        userLoyaltyPoints={loyalty?.actualPointsBalance ?? 0}
                        userLoyaltyDollar={loyalty?.actualDollarsBalance ?? 0}
                      />
                    </div>

                    <div className='mygomex__section--black-card__progress-bar'>
                      <GomexProgressBar
                        loyaltyMaxPoints={
                          loyalty?.loyaltyRewardConversionLimit ?? 0
                        }
                        points={loyalty?.actualPointsBalance ?? 0}
                        loyaltyConversionRate={loyalty?.loyaltyRewardAmount}
                      />
                    </div>
                  </div>
                  {coffeeLoyalty?.counter && (
                    <CoffeeLoyaltyFeatureFlag>
                      <div className='mygomex__section--black-card__coffee'>
                        <CoffeeLoyaltyInfo loyalty={coffeeLoyalty} />
                      </div>
                    </CoffeeLoyaltyFeatureFlag>
                  )}
                </div>
              </CardSticker>
            </div>

            <div className='mygomex__section--two-col'>
              <div className='mygomex__section mygomex__section--left'>
                <CardSticker>
                  <NavigationButton
                    testId='ReferFriendButton'
                    title={t("Loyalty:referTitle")}
                    onClick={goToReferFriend}
                    rightIcon={friendsIcon as ImageSourcePropType}
                    subtitle={t("Loyalty:earnMoreLabel")}
                    iconSize={NavigationButtonIconSize.BIG}
                  />
                </CardSticker>
              </div>

              <div className='mygomex__section mygomex__section--right'>
                <CardSticker>
                  <NavigationButton
                    testId='AboutGomexBtn'
                    title={t("Loyalty:aboutGomexButton")}
                    onClick={goToAboutGomex}
                    rightIcon={openBrowserIcon as ImageSourcePropType}
                    subtitle={t("Loyalty:aboutGomexLabel")}
                    iconSize={NavigationButtonIconSize.SMALL}
                  />
                </CardSticker>
              </div>
            </div>
          </div>
        )}
      </div>
    </View>
  );
};
