import debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppState, AppStateStatus } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";
import { Store } from "redux_store/store/models";

import { OrderReduxAction, StoreModules, StoreReduxAction } from "../index";
import { OrderUtils } from "../modules/Order";
import { orderActions } from "../redux_store/order/order.slice";
import { basketValueSelector } from "../redux_store/order/selectors";
import { RootState } from "../redux_store/rootReducer";
import { storeAsapTimeSelector } from "../redux_store/store/selectors";

const CHECK_TIME_DEBOUNCE_DELAY = 200;

export enum TimeShiftedType {
  Default,
  Menu,
  BreakfastMenu,
  LunchMenu,
}

const breakfastTitle = "Breakfast";

const usePoller = (selectedStore: Store, basketValue: number) => {
  const dispatch = useDispatch();
  const counterRef = useRef(null);
  const [currentAppState, setAppState] = useState(AppState.currentState);

  const clearIntervalCallback = useCallback(() => {
    if (counterRef.current) clearInterval(counterRef.current);
  }, []);

  const poller = useCallback(() => {
    if (selectedStore) {
      // Clear the setInterval callback (if any) before setting a new one
      clearIntervalCallback();
      const cb = () => {
        dispatch(
          StoreReduxAction.setStoreOrderTimeslotsAndOffset({
            storeId: selectedStore.id,
            basketValue,
          })
        );
      };
      cb();
      counterRef.current = setInterval(
        cb,
        StoreModules.StoreUtils.ORDER_TIME_TIMER_DELAY
      );
    }
  }, [selectedStore, basketValue, clearIntervalCallback, dispatch]);

  const handleAppStateChange = useCallback(
    (nextAppState: AppStateStatus) => {
      if (
        currentAppState.match(/inactive|background/) &&
        nextAppState === "active"
      ) {
        poller();
      }
      if (
        currentAppState === "active" &&
        nextAppState.match(/inactive|background/)
      ) {
        // This will clear the setInterval callback when the app goes to background
        clearIntervalCallback();
      }
      setAppState(nextAppState);
    },
    [currentAppState, poller, clearIntervalCallback]
  );

  // This will clear the setInterval callback
  // - when the poller function changes
  // - when the component is unmounted
  useEffect(() => {
    poller();
    return () => {
      clearIntervalCallback();
    };
  }, [poller, clearIntervalCallback]);

  useEffect(() => {
    const sub = AppState.addEventListener("change", handleAppStateChange);

    return () => {
      sub.remove();
    };
  }, [handleAppStateChange]);
};
interface UseVerifyTimeParameters {
  onTimeShiftedCallback: (type: TimeShiftedType) => void;
  onTimeUpdatedCallback: (
    time: number | null,
    shouldUpdateOrder: boolean
  ) => void;
  calculatedOrderTime: number | null;
  reduxStore?: ReduxStore | null;
}

const useVerifyTime = ({
  onTimeShiftedCallback,
  onTimeUpdatedCallback,
  calculatedOrderTime,
}: UseVerifyTimeParameters) => {
  const dispatch = useDispatch();
  const interval15minutes = 900000;
  const { orderASAP, orderInitialTime, orderTime, getOrderResponse } =
    useSelector((s: RootState) => s.order);
  const { selectedStore, storeOrderTimeSlots } = useSelector(
    (s: RootState) => s.store
  );
  const storeAsapTime = useSelector(storeAsapTimeSelector);

  const basketValue = useSelector(basketValueSelector);

  const { menuStructure } = useSelector((s: RootState) => s.menu);

  const getTimeShiftedType = useCallback(() => {
    const menuOrderedFrom = OrderUtils.getEffectiveMenuForOrderTime(
      orderInitialTime,
      menuStructure?.sections || [],
      menuStructure?.store?.timeZoneInfo?.storeTimeZone
    );
    if (menuOrderedFrom.length > 0) {
      const menu = menuOrderedFrom[0];
      if (menu.title == breakfastTitle) {
        return TimeShiftedType.BreakfastMenu;
      } else {
        return TimeShiftedType.LunchMenu;
      }
    }
    return TimeShiftedType.Default;
  }, [
    menuStructure?.sections,
    menuStructure?.store?.timeZoneInfo?.storeTimeZone,
    orderInitialTime,
  ]);

  const verifyTime = useMemo(
    () =>
      debounce(
        (
          _orderInitialTime: number | null,
          _calculatedOrderTime: number | null
        ) => {
          if (_orderInitialTime === _calculatedOrderTime) {
            return;
          }
          // has an order shifted to another trading hour period range? To display a popup for when
          // order shifts between breakfast to lunch and vice versa, making the current order likely to be invalid
          const isOrderShiftedToNextPeriod = menuStructure
            ? OrderUtils.hasOrderShiftedToNextPeriod(
                menuStructure.sections,
                _orderInitialTime,
                _calculatedOrderTime,
                menuStructure.store.timeZoneInfo.storeTimeZone
              )
            : false;
          if (
            OrderUtils.hasOrderTimeShifted(
              dispatch,
              _orderInitialTime,
              _calculatedOrderTime
            ) ||
            isOrderShiftedToNextPeriod
          ) {
            if (getOrderResponse) {
              onTimeShiftedCallback(
                isOrderShiftedToNextPeriod
                  ? getTimeShiftedType()
                  : TimeShiftedType.Default
              );
              dispatch(
                orderActions.updateOrderInitialTime(_calculatedOrderTime)
              );
            }
          }
        },
        CHECK_TIME_DEBOUNCE_DELAY
      ),
    [
      dispatch,
      getOrderResponse,
      getTimeShiftedType,
      menuStructure,
      onTimeShiftedCallback,
    ]
  );

  useEffect(() => {
    verifyTime(orderInitialTime, calculatedOrderTime);
  }, [orderInitialTime, calculatedOrderTime, verifyTime]);

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        StoreReduxAction.setStoreOrderTimeslotsAndOffset({
          storeId: selectedStore.id,
          basketValue,
        })
      );
    }
  }, [basketValue, selectedStore, dispatch]);

  useEffect(() => {
    const { storeOrderTimeBasketValue, storeOrderTimesLoading } =
      storeOrderTimeSlots;
    const timezone = selectedStore?.timeZoneInfo.storeTimeZone;

    if (storeOrderTimesLoading) {
      return;
    }
    // We have not received the store order times for this basket total yet, ignore update
    if (basketValue !== storeOrderTimeBasketValue) {
      return;
    }

    const nextAvailASAPTime: number = storeAsapTime.getTime();

    //check if order time is ASAP or scheduled order time is earlier than fastest possible time
    if (orderASAP || orderTime < nextAvailASAPTime) {
      // There's no need to update the order pickup time till the order is checked out
      //checkIfOrderPickupTimeMeetRangesAndUpdateOrderIfNeeded(nextAvailASAPTime);
      onTimeUpdatedCallback(nextAvailASAPTime, false);
      dispatch(OrderReduxAction.updateOrderTime(nextAvailASAPTime));
    } else {
      onTimeUpdatedCallback(orderTime, false);
    }

    //show a modal window when the pickup time has changed the next day and the difference
    //between the new and old pickup time is greater than 15 minutes
    const isTheSameDay = moment
      .tz(nextAvailASAPTime, timezone)
      .isSame(moment.tz(timezone), "date");
    if (!isTheSameDay && nextAvailASAPTime - orderTime > interval15minutes) {
      onTimeShiftedCallback(getTimeShiftedType());
      //set order as scheduled if time moved to next day
      dispatch(OrderReduxAction.setOrderASAP(false));
    }
  }, [
    basketValue,
    dispatch,
    getOrderResponse,
    getTimeShiftedType,
    onTimeShiftedCallback,
    onTimeUpdatedCallback,
    orderASAP,
    orderTime,
    selectedStore?.timeZoneInfo?.storeTimeZone,
    storeOrderTimeSlots,
    storeAsapTime,
  ]);

  usePoller(selectedStore, basketValue);
};

export default useVerifyTime;
