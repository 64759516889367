import { StyleSheet } from "react-native";

import colours from "styles/colours";
import { Typography } from "styles/typography";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.white,
    position: "relative",
    height: "100%",
    borderRadius: 8,
  },
  stepWrapper: {
    flexDirection: "row",
    marginBottom: 8,
    paddingHorizontal: 18,
  },
  stepNumber: {
    ...Typography.bodyBold,
    marginRight: 8,
  },
  titleText: {
    ...Typography.titleOne,
    marginBottom: 16,
    paddingHorizontal: 18,
  },
  contentWrapper: {
    padding: 16,
  },
  topContentWrapper: {
    borderBottomWidth: 1,
    borderColor: colours.borderGrey,
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
  },
  bottomContentWrapper: {
    padding: 16,
  },
  descriptionText: {
    ...Typography.body,
    marginBottom: 16,
    paddingHorizontal: 18,
  },
  listItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    marginBottom: 8,
  },
  backButtonWrapper: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 2,
  },
  bannerImage: {
    width: "100%",
    height: 224,
  },
  chevron: {
    height: 24,
    width: 24,
    marginRight: 8,
    marginLeft: -6,
  },
  termsLink: {
    ...Typography.btnPrice,
    marginTop: 8,
    paddingHorizontal: 18,
  },
  yellowBackground: {
    marginTop: "auto",
    backgroundColor: colours.yellow,
  },
  scrollWrapper: { flex: 1 },
  topContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "space-between",
    width: "100%",
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
    marginLeft: 8,
  },
  heading: {
    ...Typography.largeTitle,
    color: colours.darkGrey,
    textAlign: "center",
    marginLeft: 40,
    flexWrap: "wrap",
    flex: 1,
  },
  messageContainer: {
    marginLeft: 16,
    marginRight: 16,
  },
  message: {
    marginTop: 16,
    ...Typography.body,
    color: colours.darkGrey,
    textAlign: "center",
  },
  messageBold: {
    marginTop: 16,
    ...Typography.bodyBold,
    color: colours.darkGrey,
    textAlign: "center",
  },
  primaryButton: {
    flex: 1,
  },
  textInput: {
    marginTop: 16,
  },
  buttonContainer: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -4,
  },
  askCode: {
    ...Typography.titleThree,
    marginBottom: 8,
    textAlign: "center",
  },
});
