import locationIcon from "assets/icons/icon_location_small.png";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { CheckoutModels } from "gyg_common";
import dateIcon from "gyg_common/components/assets/icons/services/icon_date_small.png";
import deliveryIcon from "gyg_common/components/assets/icons/services/icon_delivery_no_bg.png";
import pickupIcon from "gyg_common/components/assets/icons/services/icon_pickup.png";
import { Typography } from "styles/typography";

export const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    flexDirection: "column",
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 3,
  },
  litItemImage: {
    width: 24,
    height: 24,
    resizeMode: "contain",
    marginRight: 5,
  },
});

interface IOrderDetailsHeaderInfo {
  order: CheckoutModels.OrderCheckoutResponse;
  storeName: string;
  isDelivery?: boolean;
}

export const OrderDetailsHeaderInfo: React.FC<IOrderDetailsHeaderInfo> = ({
  order: { pickupTime },
  storeName,
  isDelivery,
}) => {
  const { t } = useTranslation();
  const renderListItem = (image: ImageSourcePropType, text: string) => {
    return (
      <View style={styles.listItem}>
        <Image
          source={image}
          style={styles.litItemImage}
          resizeMode='contain'
        />
        <Text style={Typography.bodyTwoBold}>{text}</Text>
      </View>
    );
  };

  const formattedPickUpTime = moment(pickupTime).format("h:mma");
  const formattedPickUpDate = moment(pickupTime).format("D MMMM YYYY");

  return (
    <View style={styles.container}>
      {renderListItem(
        isDelivery
          ? (deliveryIcon as ImageSourcePropType)
          : (pickupIcon as ImageSourcePropType),
        isDelivery
          ? t("RecentOrders:deliveryLabel")
          : `${t("OrderStatus:pickupAt")}${formattedPickUpTime}`
      )}
      {renderListItem(dateIcon as ImageSourcePropType, formattedPickUpDate)}
      {renderListItem(locationIcon as ImageSourcePropType, storeName)}
    </View>
  );
};
