import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "gyg_common";
import { checkIfChannelIdSupportsTheCurrentPlatform } from "gyg_common/modules/Dashboard/utils";

export interface IDashboardSortingContainer {
  currentOrdersComponent: JSX.Element | null;
  recentOrdersComponent: JSX.Element | null;
  rewardsComponent: JSX.Element | null;
  favouritesComponent: JSX.Element | null;
  promotionsComponent: JSX.Element | null;
}

export const DashboardSortingContainer: React.FC<
  IDashboardSortingContainer
> = ({
  currentOrdersComponent,
  recentOrdersComponent,
  rewardsComponent,
  favouritesComponent,
  promotionsComponent,
}) => {
  const { t } = useTranslation();
  const { mobileAppConfig } = useSelector(
    ({ dashboard }: RootState) => dashboard
  );

  const componentToRender = {
    "Current Orders": {
      component: currentOrdersComponent,
      title: t("RecentOrders:currentOrdersTitle"),
    },
    "Recent Orders": {
      component: recentOrdersComponent,
      title: t("RecentOrders:recentOrdersTitle"),
    },
    Rewards: {
      component: rewardsComponent,
      title: t("Dashboard:rewardsTitle"),
    },
    Favourites: {
      component: favouritesComponent,
      title: t("Favourite:title"),
    },
    Promotions: {
      component: promotionsComponent,
      title: t("Dashboard:promoTitle"),
    },
  };

  const renderDashboardComponent = () => {
    return mobileAppConfig?.dashboard?.map(
      ({ isVisible, channeltype, componentName, id }) => {
        const { component, title } = componentToRender[componentName];
        const isComponentSupportedOnCurrentPlatform =
          checkIfChannelIdSupportsTheCurrentPlatform(channeltype);

        if (isVisible && isComponentSupportedOnCurrentPlatform && component) {
          return (
            <div
              key={`${componentName}${id}`}
              id={`${componentName}${id}`}
              className='dashboard__section'>
              {!!title && (
                <div className='title-two dashboard__subsection'>{title}</div>
              )}
              {component}
            </div>
          );
        }
      }
    );
  };

  return <>{renderDashboardComponent()}</>;
};
