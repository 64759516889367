import config from "../../../config";
import { CheckoutResponse } from "../../../redux_store/checkout/model";
import {
  DeliveryAddress,
  getMenuChannelId,
  OrderType,
} from "../../../redux_store/order/models";
import { canceledCode, httpClient } from "../../httpClient";
import {
  AddToAccountPayload,
  AddToAccountResponse,
  CreateOrderPayload,
  GetOrderByOrderIdPayload,
  GetOrderOffsetPayload,
  GetOrderResponse,
  OrderResponse,
  RateOrderPayload,
  UpdateOrderPayload,
} from "./model";

let abortController = new AbortController();
let operationIsRunning = false;

export async function createOrder(
  data: CreateOrderPayload
): Promise<OrderResponse> {
  if (operationIsRunning) {
    abortController.abort();
    abortController = new AbortController();
  }
  operationIsRunning = true;
  try {
    console.debug(`POST: POST orders`);
    const response = await httpClient({
      url: "orders",
      method: "POST",
      headers: config.accessToken
        ? {
            Authorization: config.accessToken,
          }
        : undefined,
      data: {
        ...data,
        channelId: getMenuChannelId(data.collectionType),
        type: OrderType.STANDARD,
      },
      abortController: abortController,
    });
    if (response) {
      operationIsRunning = false;
    }
    return response.data as OrderResponse;
  } catch (e) {
    operationIsRunning = false;
    //ignore canceled error from logging to Sentry
    if (e?.message !== canceledCode) {
      // SentryLoggerInstance.sentryEndpointError(e, "POST orders");
    }
    throw e;
  }
}

export async function getOrderStatus(
  data: GetOrderByOrderIdPayload
): Promise<GetOrderResponse> {
  try {
    const response = await httpClient({
      url: `orders/${data.orderId}/status`,
      method: "GET",
    });
    return response.data as GetOrderResponse;
  } catch (e) {
    // SentryLoggerInstance.sentrySetTag("order.id", data.orderId);
    // SentryLoggerInstance.sentryEndpointError(e, `GET orders/<orderId>/status`);
    throw e;
  }
}

export async function getAuthenticatedOrders(
  data: GetOrderOffsetPayload
): Promise<CheckoutResponse[]> {
  try {
    const response = await httpClient({
      url: `orders?offset=${data.offset || 0}&limit=${config.orderListLimit}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });
    return response.data as CheckoutResponse[];
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "GET orders");
    throw e;
  }
}

export async function getOrderById(orderId: string): Promise<CheckoutResponse> {
  try {
    const response = await httpClient({
      url: `orders/${orderId}`,
      method: "GET",
      headers: config.accessToken
        ? {
            Authorization: `${config.accessToken}`,
          }
        : undefined,
    });
    return response.data as CheckoutResponse;
  } catch (e) {
    // SentryLoggerInstance.sentrySetTag("order.id", orderId);
    // SentryLoggerInstance.sentryEndpointError(e, `GET orders/<orderId>`);
    throw e;
  }
}

export async function updateOrderById(
  payload: UpdateOrderPayload
): Promise<OrderResponse> {
  const orderId = payload.orderId;
  if (operationIsRunning) {
    abortController.abort();
    abortController = new AbortController();
  }
  operationIsRunning = true;
  try {
    console.debug(`API: PATCH orders/${orderId}`);
    const response = await httpClient({
      url: `orders/${orderId}`,
      method: "PATCH",
      headers: config.accessToken
        ? {
            Authorization: `${config.accessToken}`,
          }
        : undefined,
      data: {
        ...payload.orderPayload,
        channelId: getMenuChannelId(payload.orderPayload.collectionType),
        type: OrderType.STANDARD,
      },
      abortController: abortController,
    });
    if (response) {
      operationIsRunning = false;
    }
    if (response.status === 207) {
      // SentryLoggerInstance.sentrySetTag("order.id", orderId);
      // // use custom error as it's not an api error
      // SentryLoggerInstance.sentryCaptureCustomError(
      //   "API multi: PATCH order/<orderId> status 207",
      //   orderId
      // );
    }
    return response.data as OrderResponse;
  } catch (e) {
    operationIsRunning = false;
    //ignore canceled error from logging to Sentry
    if (e?.message !== canceledCode) {
      // SentryLoggerInstance.sentrySetTag("order.id", orderId);
      // SentryLoggerInstance.sentryEndpointError(e, `PATCH order/<orderId>`);
    }
    throw e;
  }
}

export async function addToAccount(
  data: AddToAccountPayload
): Promise<AddToAccountResponse> {
  try {
    const response = await httpClient({
      url: "orders/addtoaccount",
      method: "POST",
      headers: {
        Authorization: config.accessToken,
      },
      data: {
        ...data,
      },
    });
    return response.data as AddToAccountResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST orders/addtoaccount");
    throw e;
  }
}

export async function rateOrder(
  data: RateOrderPayload
): Promise<{ message: string }> {
  const orderId = data.orderId;
  delete data.orderId;
  try {
    const response = await httpClient({
      url: `orders/${orderId}/rating`,
      method: "POST",
      headers: {
        Authorization: config.accessToken,
      },
      data: { ...data },
    });
    return response.data as { message: string };
  } catch (e) {
    // SentryLoggerInstance.sentrySetTag("order.id", data.orderId);
    // SentryLoggerInstance.sentryEndpointError(e, `POST orders/<orderId>/rating`);
    throw e;
  }
}

export async function getRecentAddresses(): Promise<DeliveryAddress[]> {
  try {
    const response = await httpClient({
      url: `/deliveries/addresses`,
      method: "GET",
      headers: config.accessToken
        ? {
            Authorization: `${config.accessToken}`,
          }
        : undefined,
    });
    return response.data as DeliveryAddress[];
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, `GET /deliveries/addresses`);
    throw e;
  }
}
