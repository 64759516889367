import { useFeatureIsOn } from "@growthbook/growthbook-react";
import gomexAnimation from "assets/gif/gomex.json";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AuthStateModels, LoyaltyModels } from "gyg_common";
import chevronRight from "gyg_common/components/assets/icons/icon_chevron_right_small.png";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import CardSticker from "gyg_common/components/CardSticker";
import { CoffeeLoyaltyInfo } from "gyg_common/components/Gomex/CoffeeLoyalty/CoffeeLoyaltyInfo";
import GomexPointsView from "gyg_common/components/Gomex/GomexPointsView";
import { LoyaltySpinner } from "gyg_common/components/LoyaltyBonusCard/LoyaltySpinner";
import { useLottieWeb } from "gyg_common/hooks";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import colours from "styles/colours";

interface DashboardGomexSectionProps {
  isUserAuthenticated: boolean;
  currentUser?: AuthStateModels.CurrentUser;
  loyalty: LoyaltyModels.Loyalty | null;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  loading?: boolean;
  goToLogin: () => void;
  goToMyGomex: () => void;
  goToCoffee: () => void;
  onRetry?: () => void;
}

export const DashboardGomexSection: React.FC<DashboardGomexSectionProps> = (
  props
) => {
  const {
    isUserAuthenticated,
    currentUser,
    loyalty,
    coffeeLoyalty,
    loading,
    goToLogin,
    goToMyGomex,
    goToCoffee,
    onRetry,
  } = props;
  const { t } = useTranslation();
  const { lottie } = useLottieWeb();

  const hasCoffee =
    useFeatureIsOn(FeatureFlags.COFFEE_LOYALTY as string) || __DEV__;

  const isLoading = loading && !coffeeLoyalty?.counter && !loyalty;
  const noData = !loading && !loyalty;

  useEffect(() => {
    if (!lottie) {
      return;
    }

    if (!isLoading && !noData) {
      lottie.destroy();
      lottie.loadAnimation({
        container: document.getElementById("gomexAnimation") as HTMLDivElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: gomexAnimation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("gomexAnimation"), isLoading, lottie]);

  if (isUserAuthenticated) {
    return !loading && !loyalty ? (
      <></>
    ) : (
      <CardSticker bgColor={colours.transparent}>
        <div>
          <button
            data-testid='dashboard-gomex-black-card'
            onClick={goToMyGomex}
            className='dashboard__gomex-black-card'>
            <p className='dashboard__gomex-black-card__title'>
              {t("Dashboard:accountLoggedTitle", {
                userName: currentUser?.firstName,
              }).toUpperCase()}
            </p>

            <div
              data-testid='gomexAnimation'
              className='dashboard__gomex-black-card__animation'
              id='gomexAnimation'
            />

            <div className='dashboard__gomex-black-card__points'>
              <GomexPointsView
                userLoyaltyPoints={loyalty?.actualPointsBalance ?? 0}
                userLoyaltyDollar={loyalty?.actualDollarsBalance ?? 0}
              />
            </div>

            <img
              className='dashboard__gomex-black-card__icon'
              src={chevronRight}
            />
          </button>

          {((coffeeLoyalty?.counter && hasCoffee) || isLoading) && (
            <button className='dashboard__coffee-loyalty-card'>
              {coffeeLoyalty?.counter && (
                <CoffeeLoyaltyInfo
                  loyalty={coffeeLoyalty}
                  onClick={goToCoffee}
                />
              )}
            </button>
          )}
          <LoyaltySpinner
            isLoading={isLoading}
            noData={noData}
            onRetry={onRetry}
          />
        </div>
      </CardSticker>
    );
  }

  return (
    <CardSticker>
      <div className='dashboard__cta-wrapper'>
        <div className='dashboard__cta-copy'>
          <p className='title-three'>{t("Dashboard:ctaWebTitle")}</p>
          <p className='dashboard__cta-text'>{t("Dashboard:ctaWebCopy")}</p>
        </div>
        <div className='dashboard__cta-btn'>
          <PrimaryBlackButton
            onClick={goToLogin}
            buttonName={t("Dashboard:ctaWebBtn")}
          />
        </div>
      </div>
    </CardSticker>
  );
};

export default DashboardGomexSection;
