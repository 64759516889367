import useAddToOrder from "hooks/useAddToOrder";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  ProductConst,
  RootState,
} from "gyg_common";
import CustomizableProduct from "gyg_common/components/Products/CustomizableProduct/CustomizableProduct";
import { getPreSelectedMealId } from "gyg_common/modules/Products/utils";
import TacoProduct from "views/components/Products/TacoProduct";

export interface TacoProductContainerProps {
  cartItem?: CartReduxModels.CartItem;
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  commonSection?: MenuModels.CommonSection;
  index?: number;
  isUpsell?: boolean;
  enableGoBackMode?: boolean;
  goBack: () => void;
  onClose: () => void;
}

export default function TacoProductContainer(
  props: TacoProductContainerProps
): JSX.Element {
  const {
    cartItem,
    category,
    editMode,
    commonSection,
    isUpsell,
    index,
    enableGoBackMode,
    goBack,
    onClose,
  } = props;
  const { tacoItem, MIAMItem } = useSelector((state: RootState) => state.cart);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const dispatch = useDispatch();

  const [showCustomisableMIAMoption, setShowCustomisableMIAMoption] =
    useState<boolean>(false);
  const [selectedMIAMcategory, setSelectedMIAMcategory] = useState<{
    category: MenuModels.Category;
    index: number;
  }>();
  const [temporaryCartItem, setTemporaryCartItem] = useState<{
    mainCartItem: CartReduxModels.CartItem | undefined;
    MIAMcartItem: CartReduxModels.CartItem | undefined;
  }>({ mainCartItem: undefined, MIAMcartItem: undefined });

  const preSelectedMealId = getPreSelectedMealId({
    editMode,
    cartItem,
  });
  const [selectedMealId, updateSelectedMealId] = useState<number | undefined>(
    preSelectedMealId
  );
  const [selectedMIAMOptionMealId, updateSelectedMIAMOptionMealId] = useState<
    number | undefined
  >(preSelectedMealId);

  const updateSelectedMealIdAndMIAMOptionId = (id: number | undefined) => {
    updateSelectedMealId(id);
    updateSelectedMIAMOptionMealId(id);
  };

  const onCloseMiamOption = () => {
    setShowCustomisableMIAMoption(false);
  };

  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: showCustomisableMIAMoption
      ? selectedMIAMcategory?.category?.name
      : (category?.name ?? ""),
    onClose: showCustomisableMIAMoption ? onCloseMiamOption : onClose,
  });

  const handleResetTacoItem = (indexTaco: number) => {
    dispatch(
      CartReduxAction.updateTacoItem({ cartItem: undefined, index: indexTaco })
    );
  };
  const handleUpdateTacoItem = (
    optionCartItem: CartReduxModels.CartItem | undefined,
    optionIndex: number
  ) => {
    dispatch(
      CartReduxAction.updateTacoItem({
        cartItem: optionCartItem,
        index: optionIndex,
      })
    );
  };

  const handleResetMIAMItems = () => {
    dispatch(CartReduxAction.clearMIAMItem());
  };

  const handleSelectMIAMoption = (
    optionCartItem: CartReduxModels.CartItem | undefined,
    optionIndex: number
  ) => {
    dispatch(
      CartReduxAction.updateMIAMItem({
        cartItem: optionCartItem,
        index: optionIndex,
      })
    );
  };

  const handleMIAMcustomisableOptionSelected = (
    categoryMiam: MenuModels.Category,
    temporaryCartItems: CartReduxModels.CartItem[] | undefined,
    indexMiam: number
  ) => {
    if (temporaryCartItems?.length === 1) {
      setSelectedMIAMcategory({ category: categoryMiam, index: indexMiam });
      setShowCustomisableMIAMoption(true);
      setTemporaryCartItem({
        mainCartItem: temporaryCartItems[0],
        MIAMcartItem: undefined,
      });
    } else if (temporaryCartItems?.length === 2) {
      setSelectedMIAMcategory({ category: categoryMiam, index: indexMiam });
      setShowCustomisableMIAMoption(true);
      setTemporaryCartItem({
        mainCartItem: temporaryCartItems[0],
        MIAMcartItem: temporaryCartItems[1],
      });
    } else {
      setShowCustomisableMIAMoption(false);
      setTemporaryCartItem({
        mainCartItem: undefined,
        MIAMcartItem: undefined,
      });
    }
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductBundle, {
      bundle_id: category.id,
      bundle_name: category.name,
      product_quantity: 1,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
  }, [selectedStore, category, editMode]);

  useEffect(() => {
    if (showCustomisableMIAMoption && selectedMIAMcategory) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductCategory, {
        product_category_id: selectedMIAMcategory.category.id,
        product_category_name: selectedMIAMcategory.category.name,
        product_quantity: 1,
        view_mode: ProductConst.EditMode.UPDATE_MIAM_ITEM,
        store_id: selectedStore?.id,
        store_name: selectedStore?.name,
      });
    }
  }, [
    showCustomisableMIAMoption,
    selectedMIAMcategory,
    selectedStore?.id,
    selectedStore?.name,
  ]);

  return (
    <>
      {showCustomisableMIAMoption ? (
        <CustomizableProduct
          selectedMealId={selectedMIAMOptionMealId}
          updateSelectedMealId={updateSelectedMIAMOptionMealId}
          key='MIAM-customisable-option'
          MIAMItem={MIAMItem}
          cartItem={
            MIAMItem && selectedMIAMcategory
              ? MIAMItem[selectedMIAMcategory.index]
              : undefined
          }
          handleAddToOrder={handleAddToOrder}
          handleResetMIAMItems={handleResetMIAMItems}
          index={selectedMIAMcategory?.index}
          editMode={ProductConst.EditMode.UPDATE_MIAM_ITEM}
          goBack={() => setShowCustomisableMIAMoption(false)}
          category={selectedMIAMcategory?.category as MenuModels.Category}
          fromMIAM={temporaryCartItem.mainCartItem ? true : false}
        />
      ) : (
        <TacoProduct
          selectedMealId={selectedMealId}
          updateSelectedMealId={updateSelectedMealIdAndMIAMOptionId}
          handleUpdateTacoItem={handleUpdateTacoItem}
          handleResetTacoItem={handleResetTacoItem}
          tacoItem={tacoItem}
          MIAMItem={MIAMItem}
          handleResetMIAMItems={handleResetMIAMItems}
          handleMIAMsimpleOptionSelected={handleSelectMIAMoption}
          handleMIAMcustomisableOptionSelected={
            handleMIAMcustomisableOptionSelected
          }
          cartItem={cartItem || temporaryCartItem.mainCartItem}
          MIAMCartItem={temporaryCartItem.MIAMcartItem}
          category={category}
          editMode={editMode}
          commonSection={commonSection}
          isUpsell={isUpsell}
          index={index}
          goBack={goBack}
          onClose={onClose}
          enableGoBackMode={enableGoBackMode}
        />
      )}
    </>
  );
}
