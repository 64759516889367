import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { LoyaltyBonusType } from "../../services/api/order/model";
import Spinner from "../Buttons/Spinner";
import GomexHeader from "../Gomex/GomexHeader";
import BonusSection from "./BonusSection";
import { styles } from "./LoyaltyBonusCard.style";

interface LoyaltyBonusCardProps {
  loyaltyBonusPoints: number;
  loyaltyBonusDollars: number;
  isLoading?: boolean;
}

const LoyaltyBonusCard: React.FC<LoyaltyBonusCardProps> = ({
  loyaltyBonusDollars,
  loyaltyBonusPoints,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.bonusesWrapper}>
        <View style={styles.header}>
          <View style={styles.gomexHeader}>
            <GomexHeader
              title={t("Loyalty:bonusRewards")}
              noGomexAnimation
              headerAltColors
            />
          </View>
          {isLoading && <Spinner containerStyle={styles.spinnerContainer} />}
        </View>

        <View style={styles.body}>
          {!!loyaltyBonusPoints && (
            <BonusSection
              value={loyaltyBonusPoints}
              type={LoyaltyBonusType.GYGPoints}
            />
          )}

          {!!loyaltyBonusDollars && (
            <BonusSection
              value={loyaltyBonusDollars}
              type={LoyaltyBonusType.GYGDollar}
            />
          )}
        </View>
      </View>
    </View>
  );
};

export default React.memo(LoyaltyBonusCard);
