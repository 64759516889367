// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeDuplicateByKey = (arr: any[], key: string): any[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return arr.reduce((acc: any[], val: any) => {
    //Found duplicate value
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (acc.find((el: any) => el[key] === val[key])) {
      return [...acc];
    }
    return [...acc, val];
  }, []);
};
