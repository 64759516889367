import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import { GuestDetailModels, LoyaltyModels, PlatformUtils } from "gyg_common";
import GomexOrderWhite from "gyg_common/components/Gomex/GomexOrderWhite";
import GomexWithoutFutureSection from "gyg_common/components/Gomex/GomexWithoutFutureSection";
import { Spacing } from "gyg_common/components/styles/number";

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    paddingTop: Spacing.Light,
  },
});

export interface OrderStatusGomexProps {
  isUserAuthenticated: boolean;
  loyalty: LoyaltyModels.Loyalty | null;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
  guestLoyalty: GuestDetailModels.GuestLoyalty;
  goToLogin: () => void;
}

/**
 * Displays Gomex section in order status.
 * @param props
 * @returns
 */
const OrderStatusGomex: React.FC<OrderStatusGomexProps> = ({
  isUserAuthenticated,
  loyalty,
  coffeeLoyalty,
  guestLoyalty,
  goToLogin,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isUserAuthenticated && loyalty && (
        <div
          className='order-status-gomex'
          data-testid='order-status-gomex-authenticated'>
          <GomexWithoutFutureSection
            loyalty={loyalty}
            coffeeLoyalty={coffeeLoyalty}
          />
        </div>
      )}
      {!isUserAuthenticated && guestLoyalty && (
        <View
          style={styles.wrapper}
          {...PlatformUtils.generateTestID(
            Platform.OS,
            "order-status-gomex-guest"
          )}>
          <GomexOrderWhite
            isOrderResponseLoading={false}
            newPointsAndDollars={{
              points: guestLoyalty.accruedPoints,
              dollars: guestLoyalty.convertedDollars,
            }}
            captionCopy={t("Loyalty:guestFuturePointsLabel")}
            noGomexAnimation
            reminderCopy={t("Loyalty:reminderLabel")}
            pointsCopy={t("Loyalty:pointsConversion", {
              points: guestLoyalty.rewardConversionLimit,
              loyaltyConversionRate: guestLoyalty.rewardAmount,
            })}
            goToLogin={goToLogin}
          />
        </View>
      )}
    </>
  );
};

export default OrderStatusGomex;
