import "./style.scss";

import locationIcon from "assets/icons/icon_location_small.png";
import React from "react";
import { useTranslation } from "react-i18next";

import config from "config";
import { OrderModules, OrderReduxModels, StoreReduxModels } from "gyg_common";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import deliveryIcon from "gyg_common/components/assets/icons/services/icon_delivery_no_bg.png";
import pickupIcon from "gyg_common/components/assets/icons/services/icon_pickup.png";
import tblServiceIcon from "gyg_common/components/assets/icons/services/icon_table.png";
import { StoreStatus } from "gyg_common/components/StoreDetails";

import { OrderUtils } from "@/../../packages/common/modules/Order";

export interface OrderSetupHeaderProps {
  timeZone: string;
  storeName?: string;
  storeOpenTime: StoreReduxModels.StoreOpenTime;
  distance: string;
  orderCollectionType: OrderReduxModels.CollectionType;
  orderASAP: boolean;
  orderTime: number | null;
  isDisabled?: boolean;
  navigateToOrderSetup: () => void;
  deliveryDuration?: {
    min: string | number;
    max: string | number;
  };
  deliveryFee?: number;
  deliveryAddress?: string;
}

export const OrderSetupHeader: React.FC<OrderSetupHeaderProps> = ({
  timeZone,
  storeName,
  storeOpenTime,
  distance,
  orderCollectionType,
  orderASAP,
  orderTime,
  isDisabled,
  navigateToOrderSetup,
  deliveryDuration,
  deliveryFee,
  deliveryAddress,
}) => {
  const { t } = useTranslation();
  const isDelivery =
    orderCollectionType === OrderReduxModels.CollectionType.DELIVERY;

  return (
    <div
      className={`${"header-setup-order__button"} ${isDisabled ? "header-setup-order__button--disabled" : ""}`}
      onClick={() => (!isDisabled ? navigateToOrderSetup() : null)}
      data-testid='OrderSetupHeader'
      data-dd-action-name='setup-order'>
      <div className='header-setup-order'>
        <div className='header-setup-order__container'>
          <div className=' header-setup-order__text'>
            <img className='header-setup-order__icon' src={locationIcon} />
            {!isDelivery ? (
              <>
                <p className='header-setup-order__text--name'>{storeName}</p>
                <p className='header-setup-order__text--distance'>{distance}</p>
              </>
            ) : (
              <p className='header-setup-order__text--name'>
                {deliveryAddress}
              </p>
            )}
          </div>

          {storeOpenTime.isOpen || orderTime ? (
            <div className=' header-setup-order__text'>
              {isDelivery && (
                <img
                  data-testid='DeliveryIcon'
                  className='header-setup-order__icon_order_type'
                  src={deliveryIcon}
                />
              )}
              {(orderCollectionType ===
                OrderReduxModels.CollectionType.PICK_UP ||
                orderCollectionType ===
                  OrderReduxModels.CollectionType.DRIVE_THRU) && (
                <img
                  data-testid='PickupIcon'
                  className='header-setup-order__icon_order_type'
                  src={pickupIcon}
                />
              )}

              {orderCollectionType ===
                OrderReduxModels.CollectionType.TABLE_SERVICE && (
                <img
                  data-testid='TableServiceIcon'
                  className='header-setup-order__icon'
                  src={tblServiceIcon}
                />
              )}
              <p className='header-setup-order__text--wrap'>
                {OrderModules.OrderUtils.displayOrderType(
                  orderCollectionType,
                  config.version
                )}
              </p>

              {orderASAP && !isDelivery && (
                <p
                  data-testid='ASAP'
                  className='header-setup-order__text--name'>
                  {t("OrderManagement:asapEstLabelTime")}
                </p>
              )}

              {orderTime !== null && (
                <p
                  data-testid='orderTime'
                  className='header-setup-order__text--distance'>
                  {isDelivery
                    ? OrderUtils.formatDeliveryDuration(deliveryDuration)
                    : OrderUtils.getFormattedOrderTime(orderTime, timeZone)}

                  {isDelivery &&
                    deliveryFee !== undefined &&
                    deliveryFee !== null &&
                    ` •  $${deliveryFee} Delivery fee`}
                </p>
              )}
            </div>
          ) : (
            <div data-testid='closed' className='header-setup-order--closed'>
              <StoreStatus {...storeOpenTime} />
            </div>
          )}
          <div />
        </div>

        {!isDisabled && (
          <div className='header-setup-order__edit-container'>
            <div className='header-setup-order__text'>
              <img src={editIcon} className='header-setup-order__icon' />
              <p className='header-setup-order__edit'>EDIT</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSetupHeader;
