import React from "react";
import {
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  ImageURISource,
  StyleProp,
  View,
} from "react-native";

import { styles } from "./MenuImage.styles";

export interface MenuImageProps {
  resizeMode?: ImageResizeMode;
  testId?: string;
  defaultSource?: ImageURISource;
  source: ImageSourcePropType;
  style?: StyleProp<ImageStyle>;
}

/**
 * Re renders image if image fails to load by changing the key on fail
 * @param props ImageProps
 * @returns
 */
const MenuImage: React.FC<Readonly<MenuImageProps>> = (props) => {
  return (
    <View style={[styles.container, props.style || {}]}>
      <Image
        {...props}
        source={!!props.source ? props.source : props.defaultSource}
      />
    </View>
  );
};

export default MenuImage;
