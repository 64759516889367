import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";

import { RootState } from "../redux_store/rootReducer";
import { Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

const styles = StyleSheet.create({
  container: {
    paddingTop: Spacing.Thin,
    paddingBottom: Spacing.Light,
    flexDirection: "row",
    justifyContent: "center",
  },
  infoText: {
    ...Typography.captionOne,
  },
});

export const ProductNutritionalInfoDisclaimer: React.FC = () => {
  const { t } = useTranslation();
  const { mobileAppConfig } = useSelector((s: RootState) => s.dashboard);
  return (
    <View style={styles.container}>
      {mobileAppConfig?.showNutritionalInformation && (
        <Text style={styles.infoText}>
          {t("OrderManagement:nutrionalInfo")}
        </Text>
      )}
    </View>
  );
};
