import { useEffect, useState } from "react";
import { Platform } from "react-native";

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

let originalPaddingRight: string;
let scrollBarWidth: number;

function useLockedBody(
  initialLocked = false,
  rootId = "___gatsby" // Default to `___gatsby` to not introduce breaking change
): UseLockedBodyOutput {
  const [locked, setLocked] = useState(initialLocked);

  const cleanup = () => {
    document.body.style.overflow = "initial";

    if (scrollBarWidth) {
      document.body.style.paddingRight = originalPaddingRight;
    }
  };

  // Do the side effect before render
  useEffect(() => {
    if (Platform.OS !== "web" || !locked) {
      return;
    }

    if (locked) {
      // Save initial body style
      originalPaddingRight = document.body.style.paddingRight;

      // Lock body scroll
      document.body.style.overflow = "hidden";

      // Get the scrollBar width
      const root = document.getElementById(rootId); // or root
      scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0;

      // Avoid width reflow
      if (scrollBarWidth) {
        document.body.style.paddingRight = `${scrollBarWidth}px`;
      }
    } else {
      cleanup();
    }

    return cleanup;
  }, [locked, rootId]);

  // Update state if initialValue changes
  useEffect(() => {
    if (locked !== initialLocked && Platform.OS === "web") {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
}

export default useLockedBody;
