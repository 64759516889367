import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import closeIcon from "../../../components/assets/icons/icon_close.png";
import infoIcon from "../../../components/assets/icons/icon_info.png";
import CardSticker from "../../../components/CardSticker";
import * as PlatformUtils from "../../../modules/platformUtils";
import styles from "./ErrorAlert.styles";

export interface ErrorAlertProps {
  isVisible: boolean;
  title: string;
  message?: string;
  testId?: string;
  onDismiss?: () => void;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  isVisible,
  message,
  title,
  testId,
  onDismiss,
}) => {
  return (
    <>
      {isVisible ? (
        <>
          <CardSticker>
            <View
              style={styles().wrapper}
              {...PlatformUtils.generateTestID(
                Platform.OS,
                testId ? testId : "ErrorAlert"
              )}>
              <View style={styles().header}>
                <Image
                  style={styles().image}
                  source={infoIcon as ImageSourcePropType}
                />
                <View style={styles().headerText}>
                  <Text style={styles().title}>{title}</Text>
                </View>
                {onDismiss && (
                  <TouchableWithoutFeedback
                    onPress={onDismiss}
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      "ErrorAlertCloseButton"
                    )}>
                    <Image
                      style={styles().closeIcon}
                      source={closeIcon as ImageSourcePropType}
                    />
                  </TouchableWithoutFeedback>
                )}
              </View>

              {message && (
                <Text
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "ErrorAlertMessage"
                  )}
                  style={styles().errorMessage}>
                  {message}
                </Text>
              )}
            </View>
          </CardSticker>
          {Platform.OS == "web" ? <div className='main-login__error' /> : null}
        </>
      ) : null}
    </>
  );
};

export default ErrorAlert;
