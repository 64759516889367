import "./styles.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuModels } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import MenuListItem from "gyg_common/components/Menu/MenuListItem";
import NutritionalInfoDisclaimer from "views/components/Menu/NutritionalInfoDisclaimer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export interface UpsellProps {
  showNutritionInfo?: boolean;
  upsellItems: MenuModels.MenuListItemProps[];
  onNoThanksPress: () => void;
  onItemPress: (menuItemProp: MenuModels.MenuListItemProps) => void;
}

export const Upsell: React.FC<UpsellProps> = ({
  showNutritionInfo,
  upsellItems,
  onNoThanksPress,
  onItemPress,
}) => {
  const { t } = useTranslation();
  const [headerHeight, setHeaderHeight] = useState<number>(120);

  const groupMenuItems = (
    items: MenuModels.MenuListItemProps[],
    groupBy: number
  ) =>
    items.reduce(
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        previousValue: any,
        currentValue: MenuModels.MenuListItemProps,
        currentIndex: number
      ) => {
        const idx = Math.floor(currentIndex / groupBy);
        previousValue[idx] = [...(previousValue[idx] || []), currentValue];
        return previousValue;
      },
      []
    );

  const renderItems = () => {
    return groupMenuItems(upsellItems, 3).map(
      (children: MenuModels.MenuListItemProps[], groupIndex: number) => {
        return (
          <div key={"Upsell-List-Group-" + groupIndex} className='list-group'>
            {children.length === 1 && (
              <div className='item-placeholder' style={{ marginLeft: 0 }}></div>
            )}
            {children.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    upsellItems.length === 1 ? "one-item" : "list-item"
                  }
                  style={index === 2 ? { marginRight: 0 } : {}}>
                  <MenuListItem
                    key={index}
                    onPress={() => onItemPress(item)}
                    category={item.category}
                    product={item.product}
                    multiPart={item.multiPart}
                  />
                </div>
              );
            })}

            {children.length < 3 && (
              <div
                className='item-placeholder'
                style={{ marginRight: 0 }}></div>
            )}
          </div>
        );
      }
    );
  };

  useEffect(() => {
    const element = document.getElementById("header");
    if (element) {
      setHeaderHeight(element.offsetHeight);
    }
  }, []);

  return (
    <div className='upsell upsell-background'>
      <HeaderContainer pageTitle={t("OrderManagement:upsellTitle")} viewCart />
      <div className='nutrition-info'>
        {showNutritionInfo && (
          <NutritionalInfoDisclaimer
            transparent
            text={t("OrderManagement:nutrionalInfo")}
            position={headerHeight}
          />
        )}
      </div>

      <div className='upsell-container'>{renderItems()}</div>

      <div className='primary-black-button'>
        <PrimaryBlackButton
          testId='UpsellNoThanksButton'
          buttonName={t("OrderManagement:upSellNoThanks")}
          onClick={onNoThanksPress}
        />
      </div>
    </div>
  );
};
