import React from "react";
import {
  Image,
  ImageSourcePropType,
  Pressable,
  ScrollView,
  StyleProp,
  Text,
  TextStyle,
  View,
} from "react-native";

import PrimaryBlackButton from "../../../components/Buttons/PrimaryBlackButton";
import { PromoDetails } from "../../../redux_store/promo/models";
import { Typography } from "../../styles/typography";
import { pressableStyles, sectionContainerStyles, styles } from "./styles";

export interface PromotionsScreenProps extends PromoDetails {
  assetImage?: ImageSourcePropType;
  loading?: boolean;
  onMainButtonPressed: () => void;
  openURL: () => void;
}

export const PromotionsScreen = ({
  btnText,
  assetImage,
  onMainButtonPressed,
  terms,
  image,
  title,
  subTitle,
  sections,
  loading,
  openURL,
}: PromotionsScreenProps) => {
  const renderSectionDescription = (
    descriptions: string[],
    hasSectionTitle: boolean
  ) =>
    descriptions.map((text, index) => {
      return (
        <View style={styles.stepWrapper} key={`${text} + ${index}`}>
          {hasSectionTitle && descriptions.length > 1 && (
            <Text style={styles.stepNumber}>{index + 1}.</Text>
          )}
          <Text style={Typography.body}>{text}</Text>
        </View>
      );
    });

  const renderSections = () =>
    sections?.map((section, index) => (
      <View
        style={sectionContainerStyles(index).sectionContainer}
        key={section.title + index}>
        {section.title && <Text style={styles.titleText}>{section.title}</Text>}
        {renderSectionDescription(section.description, !!section.title)}
      </View>
    ));

  const renderSubTitles = () =>
    subTitle?.map((text, index) => (
      <View style={styles.listItemWrapper} key={text + index}>
        <Text style={Typography.bodyBold}>{text}</Text>
      </View>
    ));

  const imageSource = assetImage ?? { uri: image || undefined };

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.scrollWrapper}>
          <Image style={styles.bannerImage} source={imageSource} />

          <View style={styles.topContentWrapper}>
            <Text style={styles.titleText}>{title}</Text>
            {renderSubTitles()}
          </View>
          <View style={styles.bottomContentWrapper}>
            {renderSections()}
            {terms && terms.linkUrl && terms.linkText && (
              <Pressable
                style={({ pressed }) => [
                  pressableStyles(pressed).pressableOpacity,
                ]}
                onPress={openURL}>
                <Text style={styles.termsLink as StyleProp<TextStyle>}>
                  {terms.linkText}
                </Text>
              </Pressable>
            )}
          </View>
        </View>
      </ScrollView>

      <View style={styles.orderButtonWrapper}>
        <PrimaryBlackButton
          shadow
          applySafeArea
          testId='MainButton'
          buttonName={btnText}
          border
          onClick={onMainButtonPressed}
          loading={loading}
        />
      </View>
    </View>
  );
};
