import React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { IconButton } from "gyg_common/components";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";
import { Spacing } from "gyg_common/components/styles/number";

import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  container: {
    marginBottom: Spacing.Light,
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: Spacing.Light,
    paddingRight: Spacing.Narrow,
  },
  textContentContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: 6,
    flex: 1,
  },
  gygRestaurantText: {
    ...Typography.headline,
    fontSize: 14,
    textTransform: "capitalize",
    paddingTop: Spacing.Thin,
  },
  restaurantNameText: {
    fontSize: 14,
    fontFamily: "Montserrat-Regular",
    marginRight: Spacing.Regular,
    paddingTop: Spacing.Narrow,
  },
});

interface DeliveryInfoProps {
  address: string;
  instructions?: string;
  onPress: () => void;
}

const DeliveryInfo = ({
  address,
  onPress,
  instructions,
}: DeliveryInfoProps) => {
  const { t } = useTranslation();

  return (
    <Pressable onPress={onPress} style={styles.container}>
      <View style={styles.textContainer}>
        <View style={styles.textContentContainer}>
          <Text style={styles.gygRestaurantText}>
            {t("OrderManagement:deliveryAddressTitle")}
          </Text>
          <Text style={styles.restaurantNameText}>{address}</Text>

          {instructions && (
            <View>
              <Text style={styles.gygRestaurantText}>
                {t("OrderManagement:deliveryInstructionsTitle")}
              </Text>

              <Text style={styles.restaurantNameText}>{instructions}</Text>
            </View>
          )}
        </View>
        <IconButton
          withShadow={false}
          onPress={onPress}
          iconImage={editIcon}
          size={25}
        />
      </View>
    </Pressable>
  );
};

export default DeliveryInfo;
