import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";

import { LoyaltyModels, PlatformUtils } from "gyg_common";
import chevronRight from "gyg_common/components/assets/icons/icon_chevron_right_small.png";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import IconButton from "gyg_common/components/IconButton";
import MiddleModal from "gyg_common/components/modals/MiddleModal";
import { Typography } from "styles/typography";

import { styles } from "./styles";

import { WebModalSize } from "@/../../packages/common/components/modals/MiddleModal";

interface IRewardsModal extends LoyaltyModels.IRewards {
  rewardsDetailModalVisible: boolean;
  closeModal: () => void;
  mainButtonName: string;
  assetImage?: ImageSourcePropType;
  onMainButtonPressed: () => void;
  openTermsAndConditions: () => void;
}

export const RewardsModal: React.FC<IRewardsModal> = ({
  terms,
  stepText,
  image,
  title,
  priceDescription,
  expiryDate,
  offerDescription,
  intraTitle,
  rewardsDetailModalVisible,
  mainButtonName,
  assetImage,
  closeModal,
  openTermsAndConditions,
  onMainButtonPressed,
}) => {
  const { t } = useTranslation();
  const renderSteps = () =>
    stepText?.map((stepDescription, index) => (
      <View style={styles.stepWrapper} key={stepDescription + index}>
        <Text style={styles.stepNumber}>{index + 1}.</Text>
        <Text style={Typography.body}>{stepDescription}</Text>
      </View>
    ));

  const renderBulletListItem = (itemText: string) => (
    <View style={styles.listItemWrapper}>
      <Image
        style={styles.chevron}
        source={chevronRight as ImageSourcePropType}
      />
      <Text style={Typography.bodyBold}>{itemText}</Text>
    </View>
  );

  const imageSource = assetImage ?? { uri: image || undefined };

  return (
    <MiddleModal
      isVisible={rewardsDetailModalVisible}
      webModalSize={WebModalSize.LARGE}
      testId='rewards-detail-modal'>
      <View style={styles.container}>
        <View style={styles.backButtonWrapper}>
          <IconButton
            testID='BackIconButton'
            onPress={closeModal}
            iconImage={closeIcon}
          />
        </View>

        <ScrollView contentContainerStyle={styles.scrollWrapper}>
          <Image style={styles.bannerImage} source={imageSource} />

          <View style={styles.topContentWrapper}>
            <Text style={styles.titleText}>{title}</Text>
            {renderBulletListItem(priceDescription ? priceDescription : "")}
            {expiryDate
              ? renderBulletListItem(
                  `${t("Loyalty:expires")} ${moment(expiryDate).format(
                    "D MMM YYYY"
                  )}`
                )
              : null}
          </View>
          <View style={styles.bottomContentWrapper}>
            <Text style={styles.descriptionText}>{offerDescription}</Text>
            <Text style={styles.titleText}>{intraTitle}</Text>
            {renderSteps()}
            <Pressable
              {...PlatformUtils.generateTestID(
                Platform.OS,
                "TermsAndConditionsLink"
              )}
              style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1.0 }]}
              onPress={openTermsAndConditions}>
              <Text style={styles.termsLink}>{terms.description}</Text>
            </Pressable>
          </View>

          <View style={styles.yellowBackground}>
            <PrimaryBlackButton
              applySafeArea
              testId='OrderNowButton'
              buttonName={mainButtonName ? mainButtonName : ""}
              border
              onClick={onMainButtonPressed}
            />
          </View>
        </ScrollView>
      </View>
    </MiddleModal>
  );
};
