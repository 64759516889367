import successImg from "assets/images/illustration_success.png";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";

import {
  AuthStateModels,
  ErrorReduxModel,
  InputType,
  StringValidators,
} from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import ErrorView from "gyg_common/components/Error/ErrorView";
import FormInput from "gyg_common/components/FormInput";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import PasswordValidation from "gyg_common/components/PasswordValidation";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

export enum STATUS {
  SUCCESS = "Success",
  ERROR = "Error",
  EXPIRED = "Expired",
}

export interface ResetPasswordProps {
  loading: boolean;
  code: string;
  status?: STATUS;
  error?: ErrorReduxModel.ErrorResponse;
  onRetry: () => void;
  onChangePassword: (
    changePasswordRequest: AuthStateModels.ResetPasswordPayload
  ) => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  loading,
  status,
  error,
  code,
  onRetry,
  onChangePassword,
}) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false);

  const newPasswordHandler = (text: string) => {
    setNewPassword(text);
    setNewPasswordError(false);
  };

  const confirmPasswordHandler = (text: string) => {
    setConfirmPassword(text);
    setConfirmPasswordError(false);
  };

  const checkValidField = (): boolean => {
    const isPasswordValid = StringValidators.isPasswordValid(newPassword);
    setNewPasswordError(!isPasswordValid);
    setConfirmPasswordError(newPassword !== confirmPassword);

    if (
      newPassword === "" ||
      confirmPassword === "" ||
      confirmPassword !== newPassword ||
      !isPasswordValid
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onPasswordChange = () => {
    if (checkValidField() && code) {
      const resetPasswordRequest: AuthStateModels.ResetPasswordPayload = {
        code: code,
        newPassword: newPassword,
      };
      onChangePassword(resetPasswordRequest);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const msgHeadingCopy = (status: string) => {
    switch (status) {
      case STATUS.ERROR:
        return error?.heading
          ? error?.heading
          : t("LoginRegistration:errorTitle");
      case STATUS.EXPIRED:
        return t("LoginRegistration:expiredTitle");
      default:
        return t("LoginRegistration:successTitle");
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const labelCopy = (status: string) => {
    switch (status) {
      case STATUS.ERROR:
        return "";
      case STATUS.EXPIRED:
        return t("LoginRegistration:expiredLabel");
      default:
        return t("LoginRegistration:successLabel");
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const messageCopy = (status: string) => {
    switch (status) {
      case STATUS.ERROR:
        return error?.message
          ? error?.message
          : t("LoginRegistration:errorMessage");
      case STATUS.EXPIRED:
        return t("LoginRegistration:expiredMessage");
      default:
        return t("LoginRegistration:successMessage");
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const buttonNameCopy = (status: string) => {
    switch (status) {
      case STATUS.ERROR:
        return t("LoginRegistration:tryAgainButton");
      case STATUS.EXPIRED:
        return t("LoginRegistration:expiredBtn");
      default:
        return t("LoginRegistration:successBtn");
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onPasswordChange();
  };

  return (
    <>
      <LoadingScreen loading={loading} />
      <HeaderContainer pageTitle={t("LoginRegistration:resetPasswordTitle")} />
      {status ? (
        <div className='main-wrapper main-wrapper--narrow'>
          <ErrorView
            heading={msgHeadingCopy(status)}
            img={successImg as ImageSourcePropType}
            label={labelCopy(status)}
            message={messageCopy(status)}
            btnTitle={buttonNameCopy(status)}
            onTryAgain={status !== STATUS.ERROR ? onRetry : undefined}
          />
        </div>
      ) : (
        <div className='main-wrapper main-wrapper--narrow'>
          <div className='main-wrapper__col main-wrapper__col--single'>
            <form
              style={{ display: "contents" }}
              autoComplete='on'
              onSubmit={handleSubmit}>
              <FormInput
                type={InputType.PASSWORD}
                name={t("LoginRegistration:passwordLabel")}
                testId={t("LoginRegistration:passwordLabel")}
                handleChangeText={newPasswordHandler}
                error={newPasswordError}
                value={newPassword}
                placeHolder={t("LoginRegistration:passwordPlaceholder")}
                errorMsg={t("LoginRegistration:passwordFormatError")}
                disableClipboard
              />

              <PasswordValidation value={newPassword} />

              <FormInput
                type={InputType.PASSWORD}
                testId={t("LoginRegistration:confirmPasswordLabel")}
                name={t("LoginRegistration:confirmPasswordLabel")}
                handleChangeText={confirmPasswordHandler}
                error={confirmPasswordError}
                value={confirmPassword}
                placeHolder={t("LoginRegistration:passwordPlaceholder")}
                errorMsg={t("LoginRegistration:passwordMatchError")}
                disableClipboard
              />

              <PrimaryBlackButton
                testId={t("LoginRegistration:savePasswordBtn")}
                onClick={onPasswordChange}
                buttonName={t("LoginRegistration:savePasswordBtn")}
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
