import React from "react";
import {
  DimensionValue,
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { shadowTextstyle } from "../styles/shared";
import { Typography } from "../styles/typography";

interface StyleProps extends SocialMediaButtonProps {
  hovered?: boolean;
  pressed?: boolean;
}

const textStyles =
  Platform.OS === "web" ? Typography.viewCartBtnPrice : Typography.btnPrice;

const styles = (props: StyleProps) =>
  StyleSheet.create({
    container: {
      marginBottom: Spacing.Thin,
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: props.hovered
        ? colours.starburstOpacity
        : props.pressed
          ? colours.starburst
          : colours.white,
      borderRadius: BorderRadius.Medium,
      paddingVertical: Spacing.Thin,
    },
    icon: {
      width: 24,
      height: 24,
    },
    iconContainer: {
      position: Platform.OS === "web" ? "absolute" : "relative",
      marginLeft: Spacing.Light,
      marginRight: Spacing.Light,
      alignItems: "center",
      justifyContent: "center",
      ...Platform.select({
        web: {
          minWidth: "28px" as DimensionValue, // type assertion to satisfy type
        },
      }),
    },
    textContainer: {
      display: "flex",
      ...Platform.select({
        web: {
          width: "100%",
        },
        default: {
          flex: 1,
        },
      }),
    },
    text: {
      ...textStyles,
      textAlign: "center",
      textTransform: "none",
      alignSelf: "center",
      flex: 1,
      margin: "auto",
    },
    //emptyView on the right simulates icon style, to center text correctly inside the container
    emptyView: {
      width: 24,
      marginLeft: Spacing.Light,
      marginRight: Spacing.Light,
    },
  });

export interface SocialMediaButtonProps {
  title: string;
  icon: ImageSourcePropType;
  onPress: () => void;
}

export const SocialMediaButton = (
  props: SocialMediaButtonProps
): JSX.Element => {
  const { title, onPress, icon } = props;

  return (
    <Pressable
      {...PlatformUtils.generateTestID(Platform.OS, title + "SocialMediaLogin")}
      style={({ hovered, pressed }) =>
        styles({ ...props, hovered, pressed }).container
      }
      onPress={onPress}>
      <View style={styles(props).iconContainer}>
        <Image
          {...PlatformUtils.generateTestID(
            Platform.OS,
            title + "SocialMediaIcon"
          )}
          resizeMode='contain'
          style={styles(props).icon}
          source={icon}
        />
      </View>
      <View style={styles(props).textContainer}>
        <Text style={[styles(props).text, shadowTextstyle(1).shadow]}>
          {title}
        </Text>
      </View>
      <View style={styles(props).emptyView} />
    </Pressable>
  );
};
