import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import config from "config";
import { CurrentUser } from "gyg_common/redux_store/auth/models";

export const initDatadog = () => {
  datadogRum.init({
    applicationId: config.datadog.rumApplicationId,
    clientToken: config.datadog.rumClientToken,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: config.datadog.service,
    env: config.datadog.env,
    version: config.packageVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [config.api.host, window.location.href],
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: (event) => {
      if (
        event.type !== "error" &&
        event.resource &&
        event.resource.hasOwnProperty("type") &&
        (event.resource as { type: "image" }).type === "image"
      ) {
        return false;
      }
      return true;
    },
  });

  datadogLogs.init({
    clientToken: config.datadog.loggingClientToken,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: config.datadog.env,
    service: config.datadog.service,
    version: config.packageVersion,
  });
};

export const identifyDatadogUser = async (userProfile: CurrentUser) => {
  datadogRum.setUser({
    name: `${userProfile.firstName} ${userProfile.lastName}`,
    email: userProfile.email,
    brazeId: userProfile.brazeId,
    branchId: userProfile.branchId,
    userName: userProfile.userName,
  });
};
