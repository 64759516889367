import { StyleSheet } from "react-native";

import colours from "styles/colours";
import { Typography } from "styles/typography";

export const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 8,
  },
  itemWrapper: {
    flexDirection: "column",
    borderBottomColor: colours.borderGrey,
    borderBottomWidth: 1,
    padding: 16,
  },
  titleText: {
    ...Typography.titleThree,
    paddingRight: 8,
  },
  descriptionText: {
    ...Typography.bodyTwo,
    color: colours.midGrey,
    marginTop: 8,
  },
  topContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
});
