import "./styles.scss";

import useAddToOrder from "hooks/useAddToOrder";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import { CSSTransition } from "react-transition-group";

import {
  CartReduxModels,
  MenuModels,
  MenuModuleUtils,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
} from "gyg_common";
import AddModifyCartItem from "gyg_common/components/Products/AddModifyCartItem";
import MakeItAMeal from "gyg_common/components/Products/MakeItAMeal";
import MakeItAMealSection from "gyg_common/components/Products/MakeItAMealSection";
import ProductDetail from "gyg_common/components/Products/ProductDetail";
import ProductDetailSticky from "gyg_common/components/Products/ProductDetailSticky";
import useUpdateOrder from "gyg_common/hooks/useUpdateOrder";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  titleSection: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 24,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    alignItems: "center",
  },
});

export interface MIAMProductProps {
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  index: number;
  commonSection: MenuModels.CommonSection;
  MIAMItem: CartReduxModels.BundleState;
  cartItem: CartReduxModels.CartItem;
  isUpsell?: boolean;
  goBack: () => void;
  onClose: () => void;
  handleMIAMsimpleOptionSelected: (
    optionCartItem: CartReduxModels.CartItem | undefined,
    index: number
  ) => void;
  handleMIAMcustomisableOptionSelected: (
    category: MenuModels.Category,
    cartItems: CartReduxModels.CartItem | undefined,
    index: number
  ) => void;
  handleResetMIAMItems: () => void;
}
export interface RenderOptionsItemProps {
  item: ProductModuleModel.CustomizeOptionsDataProps;
  index: number;
}

const MIAMProduct: React.FC<MIAMProductProps> = ({
  category,
  index,
  editMode,
  cartItem,
  commonSection,
  MIAMItem,
  isUpsell,
  goBack,
  onClose,
  handleMIAMsimpleOptionSelected,
  handleMIAMcustomisableOptionSelected,
  handleResetMIAMItems,
}) => {
  // hooks
  const { t } = useTranslation();
  const { isDesktopScreen } = useMediaQuery();
  const nodeRef = React.useRef(null);

  // local state
  const [customisableSectionData, setCustomisableSectionData] = useState<
    ProductModuleModel.CustomizeOptionsDataProps[]
  >([]);
  const [selectedMealId, updateSelectedMealId] = useState<number | undefined>(
    undefined
  );
  const [mealCustomizeOptions, setMealCustomizeOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [mealRemoveModifierOptions, setMealRemoveModifierOptions] =
    useState<ProductModuleModel.MealCustomizableOptionState>({});
  const [mealCartItem, setMealCartItem] = useState<CartReduxModels.CartItem>();
  const [listLengthBeforeUpdate, updateListLengthBeforeUpdate] = useState<
    number | undefined
  >(undefined);
  const [stickerVisible, setStickerVisible] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(cartItem?.quantity || 1);
  const [requiredLength, setRequiredLength] = useState<number>(0);

  const handleUpdateOrder = useUpdateOrder(editMode, onClose);
  const handleAddToOrder = useAddToOrder({
    isUpsell: !!isUpsell,
    categoryName: category?.name ?? "",
    onClose: onClose,
  });

  const handleScrolltoNextMealSection = (sectionIndex: number) => {
    const nextSection = document.getElementById(
      `product-mealSection-${sectionIndex + 1}`
    );
    const scrollableContainer = isDesktopScreen
      ? document.getElementById("product-customisation-column")
      : document.getElementById("product-modal-wrapper");
    const stickyHeaderHeight =
      document.getElementById("product-detail-sticky")?.offsetHeight || 0;

    if (nextSection && scrollableContainer) {
      const scrollPos = nextSection.offsetTop - stickyHeaderHeight;

      scrollableContainer.scrollTo({
        top: scrollPos,
        behavior: "smooth",
      });
    }
  };
  const handleReset = () => {
    setMealRemoveModifierOptions({});
    setMealCustomizeOptions({});
    handleMIAMcustomisableOptionSelected(category, undefined, 0);
    handleResetMIAMItems();
    updateSelectedMealId(undefined);
  };

  const handleupdateSelectedMealId = useCallback(
    (id: number, mealIndex: number) => {
      updateSelectedMealId(id);
      setMealCustomizeOptions({});
      handleResetMIAMItems();
      handleScrolltoNextMealSection(mealIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Updates MIAM product's cart item in redux with remove modifier.
   * Assums that remove modi applies for sides and sides are first section in MIAM.
   * @param removeModifierId
   * @param value toggle value
   * @param index section index
   */
  const updateMIAMItemWithRemoveModifier = (
    removeModifierId: number | undefined,
    value: boolean,
    itemIndex: number
  ) => {
    const multipart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const section =
      multipart?.multiPartSection && multipart?.multiPartSection[0]?.name;

    if (section && handleMIAMsimpleOptionSelected) {
      let selectedProductId;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Object.entries(mealCustomizeOptions).forEach(([key, value]) => {
        if (key == section) {
          selectedProductId = value;
        }
      });

      // condition uses reverted value 'cuz remove modi is reverted toggle
      const removeModiArray =
        !value && removeModifierId ? [removeModifierId] : undefined;

      if (selectedProductId && multipart?.multiPartSection) {
        const newSelectedProductCartItem: CartReduxModels.CartItem | undefined =
          ProductUtils.createCartItem(
            multipart?.multiPartSection[0] as unknown as MenuModels.Category,
            selectedProductId,
            1,
            true,
            removeModiArray
          );

        if (newSelectedProductCartItem) {
          handleMIAMsimpleOptionSelected(
            newSelectedProductCartItem,
            itemIndex - 1
          );
        }
      }
    }
  };

  /**
   * Updates meal object with selected options on user's click.
   * Navigates to customisable meal option template when applicable.
   * @param id parent section id
   * @param selectedId selected option id
   * @param index parent section index
   */
  const handleSetMealCustomizeOptions = (
    id: string,
    selectedId: number,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    index: number,
    categoryOption?: MenuModels.Category
  ) => {
    const getMultipart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const getMultipartSection = getMultipart?.multiPartSection?.find(
      (s) => s.name === id
    );
    const item = getMultipartSection?.products.find((p) => p.id === selectedId);
    // index - 1 considers first section which is MIAM size and it's not saved in redux
    if (categoryOption && getMultipart) {
      const length = Object.entries(MIAMItem).length;
      const newTemporaryCartItems = ProductUtils.generateMIAMcartItem(
        getMultipart,
        mealCustomizeOptions,
        mealRemoveModifierOptions,
        quantity,
        true,
        length,
        MIAMItem
      );

      // saves main product cart item and meal cart item in container's local state,
      // to bring it back when returning from MIAM option customisation template
      handleMIAMcustomisableOptionSelected(
        categoryOption,
        newTemporaryCartItems,
        index - 1
      );
    } else {
      // saves MIAM selection to the redux state
      if (item && handleMIAMsimpleOptionSelected) {
        const optionCartItem: CartReduxModels.CartItem | undefined =
          ProductUtils.createCartItem(
            getMultipartSection as unknown as MenuModels.Category,
            item.id,
            1,
            true
          );
        if (optionCartItem) {
          handleMIAMsimpleOptionSelected(optionCartItem, index - 1);
        }
      }
    }
  };

  // scroll to next section when content size change (select product)
  useEffect(() => {
    if (customisableSectionData.length > 1) {
      let nextSection = document.getElementById(
        `product-modifier-${listLengthBeforeUpdate}`
      );
      if (!nextSection) {
        nextSection = document.getElementById(
          `product-mealSection-${listLengthBeforeUpdate}`
        );
      }
      nextSection?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [customisableSectionData.length, listLengthBeforeUpdate]);

  /**
   * Shows fixed product description (mobile version) when main description is out of the view due to scroll.
   */
  useEffect(() => {
    const target = document.getElementById("product-img");

    if (target != null) {
      const handleScroll = (entries: IntersectionObserverEntry[]) => {
        setStickerVisible(!entries[0].isIntersecting);
      };
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: [1, 0],
      };

      const observer = new window.IntersectionObserver(handleScroll, options);
      observer.observe(target);

      return function cleanup() {
        if (target != null) {
          observer.unobserve(target);
        }
      };
    }
  });

  /**
   * Generates MIAM sections options' data.
   */
  useEffect(() => {
    const selectedMultiPart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    const newCustomizeOptionsData: ProductModuleModel.CustomizeOptionsDataProps[] =
      [
        {
          title: commonSection.title,
          id: commonSection.title,
          data: [],
          commonSection: commonSection,
          isRequired: false,
        },
      ];

    if (selectedMultiPart) {
      selectedMultiPart.multiPartSection?.forEach((m) => {
        newCustomizeOptionsData.push({
          title: m.name,
          id: m.name,
          data: [],
          multipartSection: m,
          isRequired: false,
        });
      });
    }

    if (newCustomizeOptionsData.length > customisableSectionData.length) {
      updateListLengthBeforeUpdate(customisableSectionData.length);
    }
    setCustomisableSectionData(newCustomizeOptionsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, commonSection, selectedMealId]);

  /** Brings back meal selection. */
  useEffect(() => {
    if (cartItem) {
      const MIAMname = commonSection?.title;
      const MIAMsectionIndex = customisableSectionData?.findIndex(
        (data) => data.title == MIAMname
      );

      updateSelectedMealId(cartItem.productId);
      handleScrolltoNextMealSection(MIAMsectionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem, commonSection?.title, handleupdateSelectedMealId]);

  /**
   * Updates required length of cartItems when MIAM is selected.
   */
  useEffect(() => {
    const selectedMultiPart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    setRequiredLength(selectedMultiPart?.multiPartSection?.length || 0);
  }, [commonSection, selectedMealId]);

  /**
   * Updates local state of meal options with products from MIAMItem.
   */
  useEffect(() => {
    if (MIAMItem) {
      Object.entries(MIAMItem).forEach(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ([key, value]: [string, CartReduxModels.CartItem], index: number) => {
          const sectionIndex = Number(key);
          // index + 1 considers first MIAM section that's not saved in the redux
          const optionSection = customisableSectionData[sectionIndex + 1];

          if (optionSection) {
            setMealCustomizeOptions(
              (state: ProductModuleModel.MealCustomizableOptionState) => {
                return {
                  ...state,
                  [optionSection.id]: value.productId,
                };
              }
            );

            // handles modifiers only for Sides
            if (index === 0) {
              const getRemoveModiId = value.removeModifier?.length
                ? value.removeModifier[0].id
                : undefined;
              if (getRemoveModiId) {
                setMealRemoveModifierOptions({
                  [optionSection.id]: getRemoveModiId,
                });
              } else {
                setMealRemoveModifierOptions({});
              }
            }
            handleScrolltoNextMealSection(sectionIndex + 1);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MIAMItem, customisableSectionData]);

  useEffect(() => {
    const selectedMultiPart = commonSection?.categories[0]?.multiPart?.find(
      (n) => n.id === selectedMealId
    );
    if (selectedMultiPart) {
      const newCartItem = ProductUtils.generateMIAMcartItem(
        selectedMultiPart,
        mealCustomizeOptions,
        mealRemoveModifierOptions,
        quantity,
        true,
        requiredLength,
        MIAMItem
      );
      setMealCartItem(newCartItem);
    }
  }, [
    selectedMealId,
    mealCustomizeOptions,
    requiredLength,
    MIAMItem,
    commonSection,
    mealRemoveModifierOptions,
    quantity,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderItem = ({ item, index }: RenderOptionsItemProps) => {
    if (item.commonSection) {
      return (
        <div key={item.id} id={`product-mealSelection`}>
          <MakeItAMeal
            id={item.id}
            key={commonSection?.title}
            selectedMealId={selectedMealId}
            updateSelectedMealId={(id) => handleupdateSelectedMealId(id, index)}
            commonSection={item.commonSection}
            identifierSectionTitle={t(
              "OrderManagement:MIAMIdentifierSectionTitle"
            )}
          />
        </div>
      );
    } else if (item.multipartSection) {
      return (
        <div key={item.id} id={`product-mealSection-${index}`}>
          <MakeItAMealSection
            id={item.id}
            key={item.multipartSection?.name}
            updateApplyRemoveModifier={(value, removeModifierId) => {
              updateMIAMItemWithRemoveModifier(removeModifierId, value, index);
            }}
            applyRemoveModifier={!Boolean(mealRemoveModifierOptions[item.id])}
            selectedId={mealCustomizeOptions[item.id]}
            updateSelectedId={(selectedId) => {
              handleSetMealCustomizeOptions(item.id, selectedId, index);
            }}
            multipartSection={item.multipartSection}
            onCustomisableOptionSelection={(selectedCategory) => {
              handleSetMealCustomizeOptions(
                item.id,
                selectedCategory.id,
                index,
                selectedCategory
              );
            }}
          />
        </div>
      );
    } else {
      return (
        <View style={styles.titleSection} key={item.id}>
          <Text style={Typography.titleTwo}>{item.title}</Text>
        </View>
      );
    }
  };

  return (
    <>
      <div className='product-wrapper'>
        <div className='product-wrapper-col'>
          <div>
            <ProductDetail
              onReset={handleReset}
              resetDisabled={Boolean(!selectedMealId)}
              key={category.name}
              setQuantity={setQuantity}
              quantity={quantity}
              onGoBack={() => goBack()}
              showNutritionalDisclaimer={false}
              name={commonSection.title}
              price={MenuModuleUtils.getLowestMIAMPrice(commonSection)}
              mealOptions={
                ProductUtils.getMealText(
                  selectedMealId,
                  mealCustomizeOptions,
                  commonSection
                ).mealOptions
              }
              imageAngleUrl={commonSection.categories[0].imageAngleUrl}
            />
          </div>
          {!isDesktopScreen && (
            <CSSTransition
              nodeRef={nodeRef}
              in={stickerVisible}
              unmountOnExit
              timeout={200}
              classNames='product-sticky-header'>
              <div ref={nodeRef} className={"product-sticky-header-wrapper"}>
                <ProductDetailSticky
                  name={commonSection.title}
                  mealOptions={
                    ProductUtils.getMealText(
                      selectedMealId,
                      mealCustomizeOptions,
                      commonSection
                    ).mealOptions
                  }
                  customizationText=''
                  price={MenuModuleUtils.getLowestMIAMPrice(commonSection)}
                  onGoBack={() => goBack()}
                />
              </div>
            </CSSTransition>
          )}
        </div>
        <div className='product-wrapper-col'>
          <div
            className='product__customization'
            id='product-customisation-column'>
            {customisableSectionData.map((n, i) => {
              return renderItem({ item: n, index: i });
            })}
          </div>
        </div>
        <div className={"product__cart-btn-wrapper"}>
          <AddModifyCartItem
            index={index}
            editMode={editMode}
            cartItems={mealCartItem ? [mealCartItem] : undefined}
            categoryName={category?.name ?? ""}
            isUpsell={isUpsell}
            onAddToOrder={handleAddToOrder}
            onEditOrder={handleUpdateOrder}
          />
        </div>
      </div>
    </>
  );
};

export default MIAMProduct;
