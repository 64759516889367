import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, Text, View } from "react-native";

import { useLottieWeb } from "gyg_common/hooks";

import { AnalyticsInstance } from "../../../analytics";
import * as AnalyticsConst from "../../../modules/Analytics/const";
import resetButton from "../../assets/gif/resetButton.json";
import { styles, stylesWithProps } from "./ResetButton.styles";

export interface ResetButtonProps {
  disabled: boolean;
  onPress: () => void;
}

const ResetButton: React.FC<ResetButtonProps> = (props: ResetButtonProps) => {
  const { t } = useTranslation();
  const { lottie } = useLottieWeb();

  const lottieRef = useRef<HTMLDivElement>(null);
  const animationName = "reset";

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.loadAnimation({
      name: animationName,
      container: lottieRef.current,
      renderer: "canvas",
      loop: false,
      autoplay: false,
      animationData: resetButton,
    });
  }, [lottie]);

  useEffect(() => {
    if (!lottie) {
      return;
    }

    if (props.disabled) {
      lottie.stop(animationName);
    }
  }, [lottie, props.disabled]);

  const handleClick = () => {
    if (!lottie) {
      return;
    }

    lottie.play(animationName);
    setTimeout(() => {
      props.onPress();
    }, 350);

    AnalyticsInstance.trackEvent(AnalyticsConst.Events.Reset, {});
  };

  return (
    <Pressable
      style={({ hovered, pressed }) =>
        stylesWithProps({ ...props, hovered, pressed }).wrapper
      }
      disabled={props.disabled}
      onPress={handleClick}>
      <View style={styles.container}>
        <View style={styles.animationContainer}>
          <div
            ref={lottieRef}
            id={"reset-lottie"}
            className={`reset-lottie reset-lottie-${
              props.disabled ? "disabled" : "default"
            }`}
          />
        </View>
        <Text style={[stylesWithProps({ disabled: props.disabled }).text]}>
          {t("OrderManagement:resetButton")}
        </Text>
      </View>
    </Pressable>
  );
};

export default ResetButton;
