import "./style.scss";

import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PinField from "react-pin-field";

import gygBrandLogo from "gyg_common/components/assets/images/logo_primary_gyg.png";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { useMediaQuery } from "gyg_common/hooks/useMediaQuery.web";
import { LoginOTPChannel } from "gyg_common/redux_store/auth/models";
import { ErrorResponse } from "gyg_common/redux_store/error/models";

export interface LoginOTPProps {
  onOTPSubmit: (otp: string) => void;
  onOTPResend: (OTPChannel: LoginOTPChannel) => void;
  sentLoginOTPChannel?: LoginOTPChannel;
  sentLoginOTPSource?: string;
  onChangeVerificationMethod: (newLoginOTPChannel: LoginOTPChannel) => void;
  isSendingOTP: boolean;
  mfaError?: ErrorResponse;
  otpSubmittedLoading: boolean;
}

const LoginOTP: React.FC<LoginOTPProps> = ({
  onOTPSubmit,
  onOTPResend,
  sentLoginOTPChannel,
  sentLoginOTPSource,
  onChangeVerificationMethod,
  isSendingOTP,
  mfaError,
  otpSubmittedLoading,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement[] | null>(null);

  const [OTP, setOTP] = useState<string>("");

  const checkField = () => OTP !== "" && OTP.length === 6;

  const resetFields = () => {
    if (ref.current) {
      ref.current.forEach((input: HTMLInputElement) => (input.value = ""));
      ref.current[0].focus();
      setOTP("");
    }
  };

  const onSubmitOTP = () => {
    onOTPSubmit(OTP);
    mfaError = undefined;
  };

  const handleResend = (e: MouseEvent) => {
    e.preventDefault();
    if (sentLoginOTPChannel) {
      onOTPResend(sentLoginOTPChannel);
    }
  };

  const handleVerifyEmail = (e: MouseEvent) => {
    e.preventDefault();
    onChangeVerificationMethod(LoginOTPChannel.Email);
  };

  const handleVerifySMS = (e: MouseEvent) => {
    e.preventDefault();
    onChangeVerificationMethod(LoginOTPChannel.SMS);
  };

  useEffect(() => {
    if (mfaError) {
      resetFields();
    }
  }, [mfaError]);

  useEffect(() => {
    setTimeout(() => {
      resetFields();
    }, 1000);
  }, []);

  const handleOnComplete = (newOTP: string) => {
    onOTPSubmit(newOTP);
    mfaError = undefined;
  };

  const { isTabletScreen, isDesktopScreen } = useMediaQuery();

  return (
    <div className='main-login__container'>
      {sentLoginOTPChannel && (
        <>
          {!isTabletScreen && !isDesktopScreen && (
            <div className='otp-mobile-logo'>
              <img src={gygBrandLogo} />
            </div>
          )}
          <p className='otp-message'>
            {t("LoginRegistration:otpVerificationCodeMessage", {
              sentLoginOTPChannel:
                sentLoginOTPChannel === LoginOTPChannel.SMS
                  ? "mobile"
                  : sentLoginOTPChannel,
            })}{" "}
            <strong>{sentLoginOTPSource}</strong>
          </p>
          <p className='otp-message'>
            {t("LoginRegistration:otpVerificationCodeInstruction")}
          </p>
          <div className='pin-fields'>
            <PinField
              className='pin-field'
              length={6}
              autoFocus={true}
              onChange={setOTP}
              onComplete={handleOnComplete}
              ref={ref}
            />
          </div>
          <PrimaryBlackButton
            buttonName={t("LoginRegistration:otpSubmit")}
            onClick={onSubmitOTP}
            loading={otpSubmittedLoading}
            disable={isSendingOTP || !checkField()}
          />
          <p className='otp-message'>
            {t("LoginRegistration:otpDidntReceiveCode")}
            <br />
          </p>
          <p className='otp-message'>
            <a href='#' onClick={handleResend}>
              {t("LoginRegistration:otpResendCode")}
            </a>{" "}
            or{" "}
            {sentLoginOTPChannel == LoginOTPChannel.SMS && (
              <a href='#' onClick={handleVerifyEmail}>
                {t("LoginRegistration:otpVerifyWithEmail")}
              </a>
            )}
            {sentLoginOTPChannel == LoginOTPChannel.Email && (
              <a href='#' onClick={handleVerifySMS}>
                {t("LoginRegistration:otpVerifyWithSMS")}
              </a>
            )}
          </p>
        </>
      )}
      {mfaError && <ErrorAlert title={mfaError.message} isVisible={true} />}
      {(!sentLoginOTPChannel || isSendingOTP) && !mfaError && (
        <LoadingScreen
          loading={true}
          description={t("LoginRegistration:otpSendingTokenMessage")}
        />
      )}
    </div>
  );
};

export default LoginOTP;
