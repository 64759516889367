import { ImageSourcePropType } from "react-native";
import { store } from "redux_store/configureReduxStore";

import {
  ApiOrderModel,
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  ProductConst,
  StoreModules,
} from "gyg_common";

export interface ServiceIcon {
  id: StoreModules.StoreModels.StoreTagLabel;
  url: ImageSourcePropType;
}

export interface SelectedCartItemToEdit {
  cartItem: CartReduxModels.CartItem;
  menuItem: MenuModels.Category | MenuModels.Product | MenuModels.MultiPart;
  productType: ProductConst.ProductRoute;
  index: number;
  commonSection: MenuModels.CommonSection | undefined;
}

/**
 * Recalculate local total cart value based on order response
 */
export const updateLocalPriceWithOrderResponse = (
  orderResponse: ApiOrderModel.OrderResponse | null
): void => {
  if (orderResponse) {
    //update local total cart if there is order response
    store.dispatch(CartReduxAction.updateTotal(orderResponse.basket.total));
  }
};

export const getFullName = (firstName: string, lastName?: string | null) => {
  if (!lastName) return firstName;

  return `${firstName} ${lastName}`;
};
