import React, { useState } from "react";
import {
  Image,
  LayoutAnimation,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import * as OrderModules from "../../modules/Order";
import * as ProductCustomizationUtils from "../../modules/Products/customizationMapper";
import * as CartReduxModels from "../../redux_store/cart/models";
import * as MenuModels from "../../redux_store/menu/models";
import colours from "../styles/colours";
import { IconSize, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

interface FavouriteCustomisationListProps {
  cartItem: CartReduxModels.CartItem;
  menuStructure?: MenuModels.MenuStructure;
  numberOfExtras: number;
  expandable?: boolean;
}

const styles = StyleSheet.create({
  productExtra: {
    marginTop: Spacing.ExtraThin,
    paddingRight: Spacing.Light,
  },
  extraText: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    lineHeight: Spacing.Light,
  },
  icon: {
    width: IconSize.Small,
    height: IconSize.Small,
    marginLeft: Spacing.ExtraThin,
    resizeMode: "cover",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: Spacing.ExtraThin,
  },
});

const FavouriteCustomisationList: React.FC<FavouriteCustomisationListProps> = ({
  cartItem,
  menuStructure,
  numberOfExtras,
  expandable,
}) => {
  const [expand, setExpand] = useState(false);

  /**
   * Controls amount of elemnts to render.
   * @param element
   * @param index
   * @returns
   */
  const rowWrapper = (element: JSX.Element, index: number, key: string) => {
    const numOfExtras = expand ? 20 : numberOfExtras;
    if (index === numOfExtras - 1 && !expandable) {
      return (
        <View key={`row-wrapper-${key}`} style={{ flexDirection: "row" }}>
          {element}
          <Text style={styles.extraText}> ...</Text>
        </View>
      );
    }

    return index <= numOfExtras - 1 ? element : <></>;
  };

  const modifierElement = (
    title: string,
    index: number,
    parentId: number,
    prefix?: string
  ) => {
    const modifierElementKey = `${title}-${index}-${parentId}`;
    return rowWrapper(
      <Text
        numberOfLines={expandable ? undefined : 1}
        key={modifierElementKey}
        style={styles.extraText}>
        {prefix && `${prefix} `}
        {title}
      </Text>,
      index,
      modifierElementKey
    );
  };

  const multipartTitleElement = (
    amount: number,
    title: string,
    index: number
  ) => {
    const multipartTitleElementKey = amount + title;
    return rowWrapper(
      <View key={multipartTitleElementKey}>
        <Text numberOfLines={1} style={styles.extraText}>
          {amount} x {title}
        </Text>
      </View>,
      index,
      multipartTitleElementKey
    );
  };

  /**
   * Adds modifier row to the list of elements.
   * @param item
   * @param startIndex
   * @returns
   */
  const addCustomisation = (
    item: CartReduxModels.CartItem,
    startIndex: number
  ) => {
    const modifiers: JSX.Element[] = [];
    ProductCustomizationUtils.productCustomizationList.map(
      (modifierTitle: string) => {
        for (const [key, value] of Object.entries(item)) {
          if (key === modifierTitle) {
            const prefix = key === "removeModifier" ? "No" : "Add";
            value.forEach((modifier: CartReduxModels.Modifier) => {
              modifiers.push(
                modifierElement(
                  modifier.name,
                  modifiers.length + startIndex,
                  item.productId,
                  prefix
                )
              );
            });
          }
        }
      }
    );
    return modifiers;
  };

  const renderList = () => {
    // early exit if menu structure is undefined
    if (!menuStructure) return [];

    let renderElements: JSX.Element[] = [];

    // adds identifier modifiers (like spice level, main filling)
    const identifiers = OrderModules.OrderUtils.findIdentifiersWithMenu(
      cartItem,
      menuStructure,
      false
    );

    if (identifiers.length > 0) {
      renderElements.push(
        modifierElement(identifiers.join(" "), 0, cartItem.productId)
      );
    }

    // adds modifiers
    renderElements = renderElements.concat(
      addCustomisation(cartItem, renderElements.length)
    );

    if (cartItem.parts) {
      const newItems = OrderModules.OrderUtils.combineDuplicatedCartItems(
        cartItem.parts
      );

      newItems.forEach(
        (
          { item, amount }: OrderModules.OrderModels.CombinedCartItem,
          index: number
        ) => {
          const partIdentifier =
            OrderModules.OrderUtils.findIdentifiersWithMenu(
              item,
              menuStructure,
              true,
              cartItem
            );
          const title = partIdentifier ? partIdentifier.join(" ") : item.name;
          const itemIndex = index;
          renderElements.push(multipartTitleElement(amount, title, itemIndex));
        }
      );
    }
    return renderElements;
  };

  const onExpandPress = () => {
    LayoutAnimation.configureNext({
      duration: 100,
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
    setExpand(!expand);
  };

  return (
    <View style={styles.productExtra}>
      {renderList()}
      {expandable && renderList().length > numberOfExtras && (
        <TouchableOpacity onPress={onExpandPress}>
          <View style={styles.row}>
            <Text style={Typography.titleThree}>
              {expand ? "Less" : "More"}
            </Text>
            <Image
              source={
                expand
                  ? require("gyg_common/components/assets/icons/icon_chevron_up_small.png")
                  : require("gyg_common/components/assets/icons/icon_chevron_down_small.png")
              }
              style={styles.icon}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default FavouriteCustomisationList;
