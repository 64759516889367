import { Dimensions, Platform } from "react-native";

import DeviceInfo from "gyg_common/utils/deviceInfo";

const oldIOSDevices = [
  "iPhone5,3", //"iPhone 5c", // (model A1456, A1532 | GSM)
  "iPhone5,4", //"iPhone 5c", // (model A1507, A1516, A1526 (China), A1529 | Global)
  "iPhone6,1", //"iPhone 5s", // (model A1433, A1533 | GSM)
  "iPhone6,2", //"iPhone 5s", // (model A1457, A1518, A1528 (China), A1530 | Global)
  "iPhone7,1", //"iPhone 6 Plus", //
  "iPhone7,2", //"iPhone 6", //
  "iPhone8,1", //"iPhone 6s", //
  "iPhone8,2", //"iPhone 6s Plus", //
  "iPhone8,4", //"iPhone SE", //
  "iPhone9,1", //"iPhone 7", // (model A1660 | CDMA)
  "iPhone9,3", //"iPhone 7", // (model A1778 | Global)
  "iPhone9,2", //"iPhone 7 Plus", // (model A1661 | CDMA)
  "iPhone9,4", //"iPhone 7 Plus", // (model A1784 | Global)
  "iPhone10,3", //"iPhone X", // (model A1865, A1902)
  "iPhone10,6", //"iPhone X", // (model A1901)
  "iPhone10,1", //"iPhone 8", // (model A1863, A1906, A1907)
  "iPhone10,4", //"iPhone 8", // (model A1905)
  "iPhone10,2", //"iPhone 8 Plus", // (model A1864, A1898, A1899)
  "iPhone10,5", //"iPhone 8 Plus", // (model A1897)
  "iPhone11,2", //"iPhone XS", // (model A2097, A2098)
  "iPhone11,8", //"iPhone XR", // (model A1882, A1719, A2105)
  "iPhone12,1", //"iPhone 11",
];

export const SMALLER_DEVICES_WIDTH = 400;
export const MEDIUM_WEB_WIDTH = 768; // or 48em;

export const isSmallerDevice = () =>
  Dimensions.get("window").width < SMALLER_DEVICES_WIDTH;

export const isOldIOSDevice = () =>
  oldIOSDevices.includes(DeviceInfo.getDeviceId()) && Platform.OS == "ios";
