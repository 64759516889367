import "./style.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { GuestDetailModels, StringValidators } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import FormInput from "gyg_common/components/FormInput";
import { OrderResponse } from "gyg_common/services/api/order/model";
import OrderSetupHeaderContainer from "views/containers/OrderSetupHeader/OrderSetupHeaderContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import PaymentReceipt from "../Payment/PaymentReceipt";

export interface GuestDetailProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  order: OrderResponse | null;
  deliveryFee?: number;
  onTextChange: (props: GuestDetailModels.GuestDetailsState) => void;
  onGoPayment: () => void;
  onLoginClick: () => void;
}

const GuestDetail: React.FC<GuestDetailProps> = (props) => {
  const { t } = useTranslation();

  const [firstError, setFirstError] = useState<boolean>(false);
  const [lastError, setLastError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const updateProps: GuestDetailModels.GuestDetailsState = {
    firstName: props.firstName,
    lastName: props.lastName,
    phoneNumber: props.phoneNumber,
    email: props.email,
  };

  const emailHandler = (text: string) => {
    updateProps.email = text;
    props.onTextChange({ ...updateProps });
    setEmailError(false);
  };

  const firstHandler = (text: string) => {
    updateProps.firstName = text;
    props.onTextChange({ ...updateProps });
    setFirstError(false);
  };

  const lastHandler = (text: string) => {
    updateProps.lastName = text;
    props.onTextChange({ ...updateProps });
    setLastError(false);
  };

  const phoneHandler = (text: string) => {
    updateProps.phoneNumber = text;
    props.onTextChange({ ...updateProps });
    setPhoneError(false);
  };

  const checkField = () => {
    if (
      props.firstName === "" ||
      props.lastName === "" ||
      props.email === "" ||
      props.phoneNumber === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onCheckInputError = () => {
    setFirstError(StringValidators.validateName(props.firstName));
    setLastError(StringValidators.validateName(props.lastName));
    setEmailError(StringValidators.validateEmail(props.email));
    setPhoneError(!StringValidators.validatePhone(props.phoneNumber)?.isValid);
  };

  return (
    <div className='guest-detail'>
      <HeaderContainer
        viewCart={true}
        pageTitle={t("CheckoutPayment:checkoutGuestTitle")}
        bottomComponentNotScrollable={<OrderSetupHeaderContainer disable />}
      />
      <div className='main-wrapper'>
        <div className='main-wrapper__col'>
          <div className='login-container'>
            <p className='heading'>
              {t("CheckoutPayment:checkoutNearlyThere")}
            </p>
            <p className='login-container__paragraph'>
              {t("CheckoutPayment:checkoutGuestInfo")}
            </p>
            <SecondaryButton
              buttonName={t("CheckoutPayment:checkoutLoginBtn")}
              onClick={props.onLoginClick}
            />

            <div className='grey-line divider' />
          </div>
          <div className='title-container'>
            <p className='heading'>{t("CheckoutPayment:checkoutGuestHead")}</p>
            <p className='subheading'>
              {t("CheckoutPayment:checkoutGuestSubHead")}
            </p>
          </div>

          <form style={{ display: "contents" }} autoComplete='on'>
            <FormInput
              name={t("CheckoutPayment:checkoutFirstName")}
              handleChangeText={firstHandler}
              error={firstError}
              value={props.firstName}
              errorMsg={t("LoginRegistration:nameError")}
              testId='first-name-textInput'
            />
            <FormInput
              name={t("CheckoutPayment:checkoutLastName")}
              handleChangeText={lastHandler}
              error={lastError}
              value={props.lastName}
              errorMsg={t("LoginRegistration:nameError")}
              testId='last-name-textInput'
            />
            <FormInput
              name={t("CheckoutPayment:checkoutMobileNum")}
              handleChangeText={phoneHandler}
              error={phoneError}
              value={props.phoneNumber}
              errorMsg={t("CheckoutPayment:checkoutMobileErr")}
              testId='mobile-number-textInput'
            />
            <FormInput
              name={t("CheckoutPayment:checkoutEmail")}
              handleChangeText={emailHandler}
              error={emailError}
              value={props.email}
              errorMsg={t("CheckoutPayment:checkoutEmailErr")}
              testId='checkout-email-textInput'
            />
          </form>
        </div>

        <div className='grey-line__mobile divider' />

        <div className='guest-detail__col main-wrapper__col'>
          <PaymentReceipt
            order={props.order}
            deliveryFee={props?.deliveryFee}
          />
          <div className='select-pay-button'>
            <PrimaryBlackButton
              buttonName={t("CheckoutPayment:selectPaymentButton")}
              onClick={
                StringValidators.validateName(props.firstName) ||
                StringValidators.validateName(props.lastName) ||
                StringValidators.validateEmail(props.email) ||
                !StringValidators.validatePhone(props.phoneNumber)?.isValid
                  ? onCheckInputError
                  : props.onGoPayment
              }
              disable={!checkField()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestDetail;
