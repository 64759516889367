import "./styles.scss";

import { flatten } from "lottie-colorify";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useLottieWeb } from "gyg_common/hooks";

import config from "../../../config";
import gomexAnimation from "../../assets/gif/gomex.json";
import resetButtonAnimation from "../../assets/gif/resetButton.json";
import colours from "../../styles/colours";

interface GomexHeaderProps {
  title?: string;
  isLoading?: boolean;
  noGomexAnimation?: boolean;
  headerAltColors?: boolean;
}

const GomexHeader: React.FC<GomexHeaderProps> = ({
  isLoading,
  noGomexAnimation,
  headerAltColors,
  title,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const loadingAnimationWrapper = useRef<HTMLDivElement>(null);
  const { lottie } = useLottieWeb();

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.loadAnimation({
      container: wrapperRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: gomexAnimation,
      initialSegment:
        config.visualTestingMode === true || noGomexAnimation
          ? [100, 100]
          : [0, 100],
    });
  }, [lottie, noGomexAnimation]);

  useEffect(() => {
    if (!lottie) {
      return;
    }

    if (loadingAnimationWrapper.current && isLoading) {
      lottie.loadAnimation({
        container: loadingAnimationWrapper.current,
        renderer: "canvas",
        loop: true,
        autoplay: !config.visualTestingMode,
        animationData: flatten(
          headerAltColors ? colours.darkGrey : colours.white,
          resetButtonAnimation
        ),
      });
    }
  }, [headerAltColors, isLoading, lottie]);

  return (
    <div className='gomex-black-card gomex-header'>
      <div
        data-testid='GomexHeaderAnimation'
        className='gomex-header__animation'
        ref={wrapperRef}
      />
      <p
        data-testid='GomexHeaderTitle'
        className={`gomex-header__text ${
          headerAltColors ? "gomex-header--text-black" : ""
        }`}>
        {title || t("Loyalty:summary")}
      </p>
      {isLoading && (
        <div
          data-testid='loadingAnimation'
          className='gomex-header__loading-animation'
          id='loadingAnimation'
          ref={loadingAnimationWrapper}
        />
      )}
    </div>
  );
};

export default GomexHeader;
