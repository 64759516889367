import msgImg from "assets/images/illustration_inbox.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  AnalyticsInstance,
  AuthReduxAction,
  RootState,
  StringValidators,
} from "gyg_common";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import { Screens } from "navigation/const";
import ForgotPassword from "views/components/ForgotPassword/ForgotPassword";

import { getDeviceId } from "@/RouterObserver/WebAuthObsever";

const ForgotPasswordContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [deviceId] = useState(getDeviceId() || uuidv4());

  const { forgotPasswordError } = useSelector((s: RootState) => s.login);

  const closeModal = () => setOpenModal(false);

  const onNextPress = () => {
    setEmailError(StringValidators.validateEmail(emailAddress));
    if (!isEmailError) {
      dispatch(
        AuthReduxAction.forgotPassword({
          emailAddress: emailAddress,
          deviceId: deviceId,
        })
      );
      setOpenModal(true);
    }
  };

  const validateEmail =
    emailAddress === "" || StringValidators.validateEmail(emailAddress);

  const onLoginPress = () => {
    setOpenModal(false);
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Dashboard } });
    if (!!forgotPasswordError) dispatch(AuthReduxAction.clearError());
  };

  const onCloseErrorAlert = () => {
    dispatch(AuthReduxAction.clearError());
  };

  const onLinkPress = () => {
    dispatch(
      AuthReduxAction.forgotPassword({
        emailAddress: emailAddress,
        deviceId: deviceId,
      })
    );
  };

  useEffect(() => {
    if (forgotPasswordError) {
      setOpenModal(false);
    }
  }, [forgotPasswordError]);

  /**
   * Analytics view
   */
  useEffect(() => {
    AnalyticsInstance.trackView({
      page_name: "forget_password",
      page_type: "forget_password_view",
    });
  }, []);

  return (
    <>
      <ForgotPassword
        email={{ text: emailAddress, error: isEmailError }}
        isDisableButton={validateEmail}
        onTextChange={setEmailAddress}
        onButtonPress={onNextPress}
        forgotPasswordError={forgotPasswordError}
        onCloseErrorAlert={onCloseErrorAlert}
      />
      <ModalWithButton
        isVisible={openModal}
        title={t("LoginRegistration:resetPasswordTitle")}
        message={t("LoginRegistration:checkInboxLabel")}
        primaryButton={{
          name: t("LoginRegistration:loginButtonLabel"),
          action: () => onLoginPress(),
        }}
        linkText={t("LoginRegistration:requestResetLabel")}
        linkPress={onLinkPress}
        onModalClose={closeModal}
        image={msgImg as ImageSourcePropType}
        small={true}
        modalId='forgot-password-prompt'
      />
    </>
  );
};

export default ForgotPasswordContainer;
