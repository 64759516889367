import React from "react";
import { StyleSheet, View } from "react-native";

import { CheckoutModels, LoyaltyModels } from "../../index";
import CardSticker from "../CardSticker";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { CoffeeLoyaltyFeatureFlag } from "./CoffeeLoyalty/CoffeeLoyaltyFeatureFlag";
import { CoffeeLoyaltyInfo } from "./CoffeeLoyalty/CoffeeLoyaltyInfo";
import GomexHeader from "./GomexHeader";
import GomexPointsView from "./GomexPointsView";
import GomexProgressBar from "./GomexProgressBar";

const styles = StyleSheet.create({
  gomexBlackContainer: {
    backgroundColor: colours.black,
    padding: Spacing.Light,
    borderRadius: BorderRadius.Medium,
  },
  coffeeSection: {
    paddingHorizontal: Spacing.Light,
    marginHorizontal: -Spacing.Light,
    marginTop: Spacing.Thin,
    paddingTop: Spacing.Light,
    borderTopWidth: 1,
    borderTopColor: colours.midGrey,
  },
});

export interface GomexWithoutFutureSectionProps {
  loyalty: CheckoutModels.OrderLoyalty | LoyaltyModels.Loyalty;
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null;
}

const GomexWithoutFutureSection: React.FC<GomexWithoutFutureSectionProps> = ({
  loyalty,
  coffeeLoyalty,
}) => {
  return (
    <CardSticker>
      <View style={styles.gomexBlackContainer}>
        <GomexHeader />
        <GomexPointsView
          userLoyaltyPoints={loyalty?.actualPointsBalance || 0}
          userLoyaltyDollar={loyalty?.actualDollarsBalance || 0}
        />
        <GomexProgressBar
          loyaltyMaxPoints={loyalty?.loyaltyRewardConversionLimit ?? 0}
          points={loyalty?.actualPointsBalance ?? 0}
          loyaltyConversionRate={loyalty?.loyaltyRewardAmount}
        />
        {coffeeLoyalty?.counter && (
          <CoffeeLoyaltyFeatureFlag>
            <View style={styles.coffeeSection}>
              <CoffeeLoyaltyInfo loyalty={coffeeLoyalty} />
            </View>
          </CoffeeLoyaltyFeatureFlag>
        )}
      </View>
    </CardSticker>
  );
};

export default React.memo(GomexWithoutFutureSection);
