import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import {
  AnalyticsInstance,
  ApiUpsellModel,
  BranchReduxActions,
  CalculateMenuSectionEnabledByOrderTime,
  CartReduxAction,
  FavouriteModules,
  FavouriteReduxAction,
  GetMenuListData,
  MenuActions,
  MenuModels,
  MenuModuleUtils,
  OrderReduxAction,
  ProductConst,
  ProductUtils,
  RootState,
  UpsellReduxActions,
  UserReduxAction,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import MenuListItemTile from "gyg_common/components/Menu/MenuListItemTile";
import OrderUpdatedView from "gyg_common/components/OrderSetup/OrderUpdatedView";
import {
  getCurrentLocationPermission,
  requestLocationPermission,
} from "modules/location";
import { updateLocalPriceWithOrderResponse } from "modules/utils";
import { Screens } from "navigation/const";
import CombinedItemSelectionModal from "views/components/Menu/CombinedItemSelectionModal.tsx";
import ProductModal from "views/components/Products/ProductModal";

import Menu from "../../components/Menu/Menu";

const MenuContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    menuStructure,
    menuSectionEnabled,
    activeTab,
    error,
    validMenuSection,
    needScrollToHotDrinks,
    loading: menuLoading,
  } = useSelector((s: RootState) => s.menu);
  const {
    orderTime,
    userConfirmedOrderSetup,
    getOrderResponse,
    showOrderUpdatedModal,
  } = useSelector((s: RootState) => s.order);
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const { favourites, favouriteCartItems, isUpdateLoading } = useSelector(
    (s: RootState) => s.favourite
  );
  const {
    section: branchSection,
    storeId: branchStoreId,
    categoryId: branchCategoryId,
  } = useSelector((s: RootState) => s.branch);
  const { mobileAppConfig } = useSelector((s: RootState) => s.dashboard);
  const { items: cartItems } = useSelector((s: RootState) => s.cart);
  const selectedStore = useSelector((s: RootState) => s.store.selectedStore);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const [showProductModal, setShowProductModal] = useState(false);
  const [showCombinedSelectionModal, setShowCombinedSelectionModal] =
    useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    MenuModels.Category | MenuModels.Product | MenuModels.MultiPart
  >();
  const [selectedProductType, setSelectedProductType] =
    useState<ProductConst.ProductRoute>(
      ProductConst.ProductRoute.SimpleCustomisable
    );
  const [combinedOptionData, setCombinedOptionData] = useState<
    MenuModels.Category[]
  >([]);
  const [selectedCategory, setSelectedCategory] =
    useState<MenuModels.Category>();
  const menuListData = useRef<MenuModels.MenuListData[]>([]);
  const [favouriteListItem, setFavouriteListItems] = useState<
    MenuModels.MenuListItemProps[]
  >([]);
  const [featuredItems, setFeaturedItems] = useState<
    MenuModels.FeaturedItemType[]
  >([]);
  const [enableGoBackMode, setEnableGoBackMode] = useState<boolean>();

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "menu",
        page_type: "menu_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    //Refresh PRECART Upsell items
    dispatch(
      UpsellReduxActions.getUpsellFromConfig(ApiUpsellModel.UpsellType.PRECART)
    );
    return () => {
      dispatch(BranchReduxActions.clearBranchState());
    };
  }, [dispatch]);

  useEffect(() => {
    getCurrentLocationPermission()
      .then((permission) => {
        if (permission === "denied") {
          requestLocationPermission().catch((err) => console.debug(err));
        }
      })
      .catch(() => {
        dispatch(UserReduxAction.setLocationPermission(false));
      });
  }, [dispatch]);

  useEffect(() => {
    if (!userConfirmedOrderSetup) navigate(Screens.Dashboard);
  }, [userConfirmedOrderSetup, navigate]);

  useEffect(() => {
    if (isAuthSuccess) {
      dispatch(FavouriteReduxAction.getFavourite());
    }
  }, [isAuthSuccess, dispatch]);

  useEffect(() => {
    if (menuStructure && orderTime) {
      const newMenuSectionEnabled = CalculateMenuSectionEnabledByOrderTime(
        menuStructure.sections,
        new Date(orderTime),
        menuStructure.store.timeZoneInfo.storeTimeZone
      );
      dispatch(MenuActions.setMenuSectionEnabled(newMenuSectionEnabled));
    }
  }, [menuStructure, orderTime, dispatch]);

  useEffect(() => {
    if (menuStructure) {
      FavouriteModules.updateFavouriteToState(
        store,
        menuStructure,
        isAuthSuccess,
        userConfirmedOrderSetup,
        favourites
      );
    }
  }, [menuStructure, favourites, isAuthSuccess, userConfirmedOrderSetup]);

  /**
   * Compile the menu item into menuListData
   */
  useEffect(() => {
    if (menuStructure) {
      const validMenuSectionModel = MenuModuleUtils.mergeMenuSection(
        menuSectionEnabled,
        menuStructure.sections
      );

      if (!!validMenuSectionModel) {
        dispatch(MenuActions.setValidMenuSection(validMenuSectionModel));
        menuListData.current = GetMenuListData(validMenuSectionModel);
      }
      /**
       * Sets the number of initial favourite item in menu
       */
      if (menuListData.current && favouriteListItem.length > 0) {
        const favouritesToShow = favouriteListItem.slice(0, 2);

        //Logged in user + if any favorites items available, then show the option
        if (
          isAuthSuccess &&
          menuListData.current[0].title !== t("Favourite:title")
        ) {
          menuListData.current = [
            {
              title: t("Favourite:title"),
              data: favouritesToShow,
              id: -1,
            },
            ...menuListData.current,
          ];
        }
      }
    }
  }, [
    menuStructure,
    menuSectionEnabled,
    selectedStore?.id,
    cartItems,
    selectedStore,
    dispatch,
    favouriteListItem,
    isAuthSuccess,
    t,
  ]);

  /**
   * Gets all the favourite item
   */
  useEffect(() => {
    if (
      menuListData.current &&
      favouriteCartItems &&
      favouriteCartItems.length > 0 &&
      isAuthSuccess
    ) {
      setFavouriteListItems(
        FavouriteModules.mapFavouriteToMenu(favouriteCartItems)
      );
    }
  }, [favouriteCartItems, menuListData, isAuthSuccess, menuSectionEnabled]);

  /**
   * Sets array with featured items.
   */
  useEffect(() => {
    if (validMenuSection) {
      const featuredElements =
        MenuModuleUtils.getFeaturedItems(validMenuSection);
      setFeaturedItems(
        featuredElements.filter((el) =>
          MenuModuleUtils.getFeaturedItemImages(el)
        )
      );
    }
  }, [validMenuSection]);

  const handleMenuItemClicked = useCallback(
    (menuItemProps: MenuModels.MenuListItemProps) => {
      if (menuItemProps.category) {
        const productRoute = ProductUtils.getProductRoute(
          menuItemProps.category?.templateId as string
        );
        if (productRoute) {
          dispatch(CartReduxAction.clearMIAMItem());
          setSelectedProductType(productRoute);
          setSelectedMenuItem(menuItemProps.category);
          setShowProductModal(true);
        }
      } else if (menuItemProps.multiPart) {
        dispatch(CartReduxAction.clearBundleItem());
        setSelectedProductType(ProductConst.ProductRoute.Bundles);
        setSelectedMenuItem(menuItemProps.multiPart);
        setShowProductModal(true);
      } else if (menuItemProps.combinedCategory) {
        dispatch(CartReduxAction.clearMIAMItem());
        setCombinedOptionData(menuItemProps.combinedCategory.data);
        setShowCombinedSelectionModal(true);
      } else {
        setSelectedProductType(ProductConst.ProductRoute.NonCustomization);
        setSelectedMenuItem(menuItemProps.product);
        setShowProductModal(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (branchCategoryId && menuListData.current && validMenuSection) {
      if (!branchSection) {
        const sectionId = ProductUtils.getCategorySubsection(
          branchCategoryId,
          validMenuSection
        );

        if (sectionId) {
          dispatch(
            BranchReduxActions.setBranchState({
              section: sectionId.toString(),
              storeId: branchStoreId,
              categoryId: branchCategoryId,
              tableServiceError: false,
            })
          );
        }
      }

      for (const menuData of menuListData.current) {
        for (const data of menuData.data) {
          if (
            (data.category && data.category.id === branchCategoryId) ||
            (data.multiPart && data.multiPart.id === branchCategoryId) ||
            (data.product && data.product.id === branchCategoryId)
          ) {
            handleMenuItemClicked(data);
          } else if (data.combinedCategory) {
            for (const combinedCategory of data.combinedCategory.data) {
              if (combinedCategory.id === branchCategoryId) {
                const menuItemProps: MenuModels.MenuListItemProps = {
                  category: combinedCategory,
                };
                handleMenuItemClicked(menuItemProps);
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSection, branchCategoryId, validMenuSection, branchStoreId]);

  /**
   * Find Hot Drinks section
   */
  useEffect(() => {
    if (needScrollToHotDrinks && menuListData.current.length > 0) {
      dispatch(MenuActions.needScrollToHotDrinks(false));
      const hotDrinks = "Hot Drinks";
      const subSectionIndex = menuListData.current
        .map((listData) => listData.title)
        .indexOf(hotDrinks);

      if (subSectionIndex > -1) {
        const sectionId = menuListData.current[subSectionIndex].id;
        dispatch(
          BranchReduxActions.setBranchState({
            section: sectionId.toString(),
            storeId: branchStoreId,
            categoryId: branchCategoryId,
            tableServiceError: false,
          })
        );
      }
    }
  }, [
    branchCategoryId,
    branchStoreId,
    dispatch,
    menuListData.current.length,
    needScrollToHotDrinks,
  ]);

  useEffect(() => {
    updateLocalPriceWithOrderResponse(getOrderResponse);
  }, [getOrderResponse]);

  const setActiveTab = useCallback(
    (newActiveTab: string) => {
      dispatch(MenuActions.setActiveTab(newActiveTab));
    },
    [dispatch]
  );

  /**
   * Opens product template based on selected option of combined categories.
   * @param option selected product
   */
  const onOptionSelection = (option: MenuModels.Category) => {
    const productRoute = ProductUtils.getProductRoute(
      option.templateId as string
    );
    if (productRoute) {
      setSelectedProductType(productRoute);
      setSelectedCategory(option);
    }
  };

  const onFeaturedItemPress = useCallback(
    (item: MenuModels.FeaturedItemType) => {
      const productRoute = ProductUtils.getProductRoute(
        item.templateId as string
      );
      if (productRoute) {
        setEnableGoBackMode(false);
        setSelectedProductType(productRoute);
        setSelectedMenuItem(item);
        setShowProductModal(true);
      }
    },
    []
  );

  /**
   * Closes modal with combined categories list of options.
   */
  const onCloseCombinedOptions = () => {
    setShowCombinedSelectionModal(false);
    setTimeout(() => {
      setSelectedCategory(undefined);
    }, 500);
  };

  /**
   * Closes product template and brings back modal
   * with combined categories list of options.
   */
  const onGoBackFromSelectedOption = () => {
    setSelectedCategory(undefined);
  };

  const onClearBranchState = useCallback(() => {
    dispatch(BranchReduxActions.clearBranchState());
  }, [dispatch]);

  const onOrderUpdatedConfirmation = () => {
    dispatch(OrderReduxAction.setShowOrderUpdatedModal(false));
  };

  if (menuLoading && !menuStructure) {
    return <LoadingScreen loading={true} />;
  }

  if (error) {
    return (
      <Menu
        showNutritionInfo={mobileAppConfig?.showNutritionalInformation}
        validMenuSection={validMenuSection}
        menuListData={menuListData.current}
        branchSection={branchSection}
        menuStructure={menuStructure}
        menuSectionEnabled={menuSectionEnabled}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onMenuItemClicked={handleMenuItemClicked}
        isLoggedUser={isAuthSuccess}
        featuredItems={featuredItems}
        onFeaturedItemPress={onFeaturedItemPress}
        onClearBranchState={onClearBranchState}
        error={error}
      />
    );
  }

  if (menuStructure && !error) {
    return (
      <>
        <Menu
          showNutritionInfo={mobileAppConfig?.showNutritionalInformation}
          validMenuSection={validMenuSection}
          menuListData={menuListData.current}
          branchSection={branchSection}
          menuStructure={menuStructure}
          menuSectionEnabled={menuSectionEnabled}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onMenuItemClicked={handleMenuItemClicked}
          isLoggedUser={isAuthSuccess}
          featuredItems={featuredItems}
          onFeaturedItemPress={onFeaturedItemPress}
          onClearBranchState={onClearBranchState}
          error={error}
        />

        <ProductModal
          commonSection={menuStructure.commonSections.find(
            (n) =>
              n.id ===
              (selectedMenuItem as MenuModels.Category)?.commonSection?.[0]
          )}
          editMode={ProductConst.EditMode.ADD_CART_ITEM}
          productType={selectedProductType}
          isVisible={showProductModal}
          onClose={() => {
            setShowProductModal(false);
            setEnableGoBackMode(undefined);
          }}
          category={selectedMenuItem}
          enableGoBackMode={enableGoBackMode}
        />

        <CombinedItemSelectionModal
          commonSection={menuStructure.commonSections.find(
            (n) =>
              n.id ===
              (selectedCategory as MenuModels.Category)?.commonSection?.[0]
          )}
          selectedCategory={selectedCategory}
          isVisible={showCombinedSelectionModal && !!combinedOptionData}
          optionData={combinedOptionData}
          productType={selectedProductType}
          onClose={onCloseCombinedOptions}
          onGoBack={onGoBackFromSelectedOption}
          renderItem={({ item, index }) => (
            <div
              className='menu__combined-item-option'
              style={{
                paddingTop: index != 0 ? 0 : 16,
                paddingBottom: index === combinedOptionData.length - 1 ? 16 : 8,
              }}>
              <MenuListItemTile
                price={MenuModuleUtils.getTheLowestPriceInCategories([
                  item,
                ] as MenuModels.Category[])}
                item={item}
                onPress={() => onOptionSelection(item as MenuModels.Category)}
                smallCard
              />
            </div>
          )}
        />
        <LoadingScreen
          loading={
            !showProductModal && !showCombinedSelectionModal && isUpdateLoading
          }
        />

        <Modal visible={showOrderUpdatedModal} transparent={true}>
          <OrderUpdatedView onConfirm={onOrderUpdatedConfirmation} />
        </Modal>
      </>
    );
  }

  return <></>;
};

export default MenuContainer;
