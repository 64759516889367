import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import React from "react";

import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";

interface CoffeeLoyaltyFeatureFlagProps {
  children: React.ReactElement;
}

export const CoffeeLoyaltyFeatureFlag: React.FC<
  CoffeeLoyaltyFeatureFlagProps
> = (args) => {
  const { children } = args;

  return (
    <IfFeatureEnabled feature={FeatureFlags.COFFEE_LOYALTY}>
      {children}
    </IfFeatureEnabled>
  );
};
