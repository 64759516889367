import { StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";

interface StyleProps {
  completed: boolean;
  hovered?: boolean;
  pressed?: boolean;
}

export const containerStyle = (props: StyleProps) =>
  StyleSheet.create({
    container: {
      backgroundColor: props.hovered
        ? colours.starburstOpacity
        : props.pressed
        ? colours.lightestGrey
        : props.completed
        ? colours.white
        : colours.starburst,
      flex: 1,
      flexDirection: "row",
      borderRadius: BorderRadius.Medium,
    },
  });

export const styles = StyleSheet.create({
  applyAll: {
    marginHorizontal: Spacing.Thin,
    marginBottom: Spacing.Thin,
    borderTopColor: colours.lightGrey,
    borderTopWidth: 1,
    flex: 1,
    paddingTop: Spacing.Thin,
  },
  icon: {
    width: 110,
    height: 88,
    resizeMode: "cover",
    borderRadius: BorderRadius.Medium,
  },
  right: {
    paddingVertical: Spacing.Regular,
    paddingRight: Spacing.ExtraThin,
    flex: 2,
  },
  description: {
    marginTop: 4,
    flex: 1,
  },
  tick: {
    width: Spacing.Regular,
    height: Spacing.Regular,
    resizeMode: "cover",
  },
  gap: {
    paddingBottom: Spacing.Thin,
  },
  iconContainer: {
    marginRight: Spacing.Light,
    marginTop: Spacing.Regular,
  },
  controlTypeModifierRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: Spacing.Light,
    paddingBottom: Spacing.Light,
    paddingLeft: Spacing.Light,
    paddingRight: Spacing.Light,
  },
});
