import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  Switch,
  Text,
  View,
} from "react-native";

import * as MenuModuleUtils from "../../../modules/Menu/utils";
import * as PlatformUtils from "../../../modules/platformUtils";
import * as ProductConst from "../../../modules/Products/const";
import * as ProductUtils from "../../../modules/Products/utils";
import * as CartReduxModels from "../../../redux_store/cart/models";
import * as MenuModels from "../../../redux_store/menu/models";
import iconImage from "../../assets/icons/icon_refresh_small.png";
import defaultImage from "../../assets/images/no_image_available_menu.png";
import SecondaryButton from "../../Buttons/SecondaryButton";
import CardSticker from "../../CardSticker";
import { Checkbox } from "../../Checkbox";
import MenuImage from "../../Menu/MenuImage";
import colours from "../../styles/colours";
import { Typography } from "../../styles/typography";
import { containerStyle, styles } from "./ProductMultiPartSectionItem.styles";

export interface ProductMultipartSectionItemProps {
  multiPartSection: MenuModels.MultipartSection;
  onPress: (
    data: (MenuModels.Category | MenuModels.Product)[] | undefined
  ) => void;
  multiPartSectionItem?: CartReduxModels.CartItem;
  onReset: () => void;
  applyAllValid: boolean;
  handleOnApplyAll: (
    multiPartSectionItem: CartReduxModels.CartItem,
    removeModifiers: CartReduxModels.Modifier[]
  ) => void;
  updateItem: (
    multiPartSectionItem: CartReduxModels.CartItem,
    removeModifiers: CartReduxModels.Modifier[]
  ) => void;
}

/**
 * List item of Meal Customize Option in Bundles (Burrito / Bowls)
 * @param props
 * @returns
 */
const ProductMultiPartSectionItem: React.FC<
  ProductMultipartSectionItemProps
> = ({
  onPress,
  multiPartSection,
  multiPartSectionItem,
  onReset,
  applyAllValid,
  handleOnApplyAll,
  updateItem,
}) => {
  const { t } = useTranslation();
  const [removedControlTypeModifiers, setRemovedControlTypeModifiers] =
    useState<CartReduxModels.Modifier[]>(
      multiPartSectionItem?.removeModifier || []
    );
  const [multipartSelectionInfo, setMultipartSelectionInfo] =
    useState<ProductUtils.MultipartSelectionInfo>();
  const [sectionImage, setSectionImage] = useState<string>();

  const getDefaultText = (category?: MenuModels.Category) => {
    const productRoute = ProductUtils.getProductRoute(
      category?.templateId as string
    );
    if (productRoute === ProductConst.ProductRoute.NonCustomization) {
      return t("OrderManagement:bundleProductSectionSelection");
    } else {
      return t("OrderManagement:defaultBundleSelectionText");
    }
  };

  useEffect(() => {
    if (multiPartSectionItem && multiPartSection.categories) {
      const category = ProductUtils.getMultipartSectionCategoryFromCartItem(
        multiPartSectionItem,
        multiPartSection.categories
      );
      if (category) {
        setMultipartSelectionInfo(
          ProductUtils.getMultipartSelectionInfoFromCartItem(
            category,
            multiPartSectionItem
          )
        );
      } else if (multiPartSection.products) {
        const multipartProduct = multiPartSection.products.find(
          (product) => product.id === multiPartSectionItem.productId
        );
        if (multipartProduct) {
          let removeText = "";
          removeText = ProductUtils.fomartCustomizationText(
            ProductUtils.addModifierLookUpNameToCustomizationTxt(
              removeText,
              removedControlTypeModifiers,
              "No"
            )
          );

          const data = MenuModuleUtils.getProductSizeAndTitle(multipartProduct);
          setMultipartSelectionInfo({
            name: data.title as string,
            customizationText: data.size as string,
            removeText: removeText,
            imageUrl: multipartProduct.imageTopDownUrl,
          });
        }
      }
    } else {
      setMultipartSelectionInfo(undefined);
    }
  }, [multiPartSectionItem, multiPartSection, removedControlTypeModifiers]);

  useEffect(() => {
    setSectionImage(
      ProductUtils.getMultipartSectionImage(
        multiPartSection,
        multipartSelectionInfo?.imageUrl
      )
    );
  }, [multipartSelectionInfo, multiPartSection]);

  useEffect(() => {
    if (multiPartSectionItem?.removeModifier !== undefined) {
      setRemovedControlTypeModifiers(multiPartSectionItem.removeModifier);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [multipartSelectionInfo]);

  const modifiers = ProductUtils.getControlTypeModifiers(
    multiPartSection,
    multiPartSectionItem
  );

  // Check if control type modifier (Gyg Seasonong) was removed
  const isControlTypeModifierRemoved = (modifier: CartReduxModels.Modifier) => {
    return ProductUtils.containsModifier(removedControlTypeModifiers, modifier);
  };

  // Remove or add control type modifier depending on selection state.
  const updateControlTypeModifier = (modifier: CartReduxModels.Modifier) => {
    const cartModifiers = isControlTypeModifierRemoved(modifier)
      ? removedControlTypeModifiers.filter((m) => m.id !== modifier.id)
      : [...removedControlTypeModifiers, modifier];
    setRemovedControlTypeModifiers(cartModifiers);
    updateItem(multiPartSectionItem as CartReduxModels.CartItem, cartModifiers);
  };

  const onResetPressed = () => {
    setRemovedControlTypeModifiers([]);
    onReset();
  };

  const onProductMultiPartSectionItemPressed = () => {
    onPress([...multiPartSection.categories, ...multiPartSection.products]);
  };

  const onToggleClick = (item: MenuModels.ModifierLookup) => () => {
    updateControlTypeModifier(item);
  };

  const onApplyAll = () => {
    handleOnApplyAll(
      multiPartSectionItem as CartReduxModels.CartItem,
      removedControlTypeModifiers
    );
  };

  return (
    <CardSticker>
      <Pressable
        {...PlatformUtils.generateTestID(
          Platform.OS,
          `BundleMultiPartItemButton-${multiPartSection.name}`
        )}
        style={({ hovered, pressed }) =>
          containerStyle({
            completed: Boolean(multiPartSectionItem),
            hovered,
            pressed,
          }).container
        }
        onPress={onProductMultiPartSectionItemPressed}>
        <View>
          <MenuImage
            style={styles.icon}
            source={
              sectionImage
                ? (sectionImage as ImageSourcePropType)
                : defaultImage
            }
          />
        </View>
        <View style={styles.right}>
          <Text style={Typography.smallProductTitle}>
            {(multipartSelectionInfo?.name
              ? multipartSelectionInfo.name
              : multiPartSection.name
            ).toUpperCase()}
          </Text>
          <View style={styles.description}>
            {!multiPartSectionItem && (
              <Text style={Typography.captionOne}>
                {getDefaultText(multiPartSection?.categories?.[0])}
              </Text>
            )}
            {multipartSelectionInfo &&
            multipartSelectionInfo.customizationText ? (
              <Text style={Typography.captionOne}>
                {multipartSelectionInfo.customizationText}
              </Text>
            ) : null}
            {multipartSelectionInfo && multipartSelectionInfo.removeText ? (
              <>
                <View style={styles.gap} />
                <Text style={Typography.captionOne}>
                  {multipartSelectionInfo.removeText}
                </Text>
              </>
            ) : null}
          </View>
        </View>
        {multiPartSectionItem && (
          <View style={styles.iconContainer}>
            <Pressable
              {...PlatformUtils.generateTestID(
                Platform.OS,
                `BundleMultiPartItemReset-${multiPartSection.name}`
              )}
              onPress={onResetPressed}>
              <Image
                style={styles.tick}
                source={iconImage as ImageSourcePropType}
              />
            </Pressable>
          </View>
        )}
      </Pressable>

      {modifiers &&
        modifiers.map((item) => {
          return (
            <View key={item.id}>
              <Pressable
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  `BundleMultiPartItemCheckbox-${item.name}`
                )}
                onPress={onToggleClick(item)}>
                <View style={styles.controlTypeModifierRow}>
                  <Text
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      `BundleMultiPartItemCheckboxText--${item.name}`
                    )}
                    style={Typography.titleThree}>
                    {item.name}
                  </Text>
                  {Platform.OS == "web" ? (
                    <Checkbox selected={!isControlTypeModifierRemoved(item)} />
                  ) : (
                    <Switch
                      trackColor={{
                        false: colours.switchGrey,
                        true: colours.lightGreen,
                      }}
                      value={!isControlTypeModifierRemoved(item)}
                      onValueChange={onToggleClick(item)}
                    />
                  )}
                </View>
              </Pressable>
            </View>
          );
        })}

      {applyAllValid && (
        <View
          {...PlatformUtils.generateTestID(
            Platform.OS,
            `BundleMultiPartItemApplyToAll-${multiPartSection.name}`
          )}
          style={styles.applyAll}>
          <SecondaryButton
            buttonName={t("OrderManagement:applyAllButton")}
            onClick={onApplyAll}
          />
        </View>
      )}
    </CardSticker>
  );
};

export default ProductMultiPartSectionItem;
