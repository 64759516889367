import React from "react";
import { Pressable, Text, View } from "react-native";

import { Checkbox } from "gyg_common/components/Checkbox";

import { styles } from "./styles";

export interface ICheckboxWithTitle {
  title: string;
  description?: string;
  checkboxState: boolean;
  onCheckboxPressed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckboxWithTitle: React.FC<ICheckboxWithTitle> = ({
  title,
  description,
  checkboxState,
  onCheckboxPressed,
}) => {
  return (
    <Pressable
      style={({ pressed }) => [{ opacity: pressed ? 0.6 : 1 }]}
      onPress={() => onCheckboxPressed(!checkboxState)}>
      <View style={styles.itemWrapper}>
        <View style={styles.topContent}>
          <Text style={styles.titleText}>{title}</Text>
          <Checkbox selected={checkboxState} />
        </View>
        {description ? (
          <Text style={styles.descriptionText}>{description}</Text>
        ) : null}
      </View>
    </Pressable>
  );
};
