import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";

import {
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  RootState,
} from "../index";
import { storeAsapTimeSelector } from "../redux_store/store/selectors";
import { HttpStatus } from "../services/httpClient";
import { getStatusCode } from "../utils/order";

export const UPDATE_ORDER_HOOK_ORDER_404 =
  "useCreateUpdateOrder: Order Response 404 - recreating order. Route:";
export const UPDATE_ORDER_HOOK_ORDER_GENERIC =
  "useCreateUpdateOrder: Order Response 4xx 5xx error - displaying modal. Route:";

const useCreateUpdateOrder = (store: ReduxStore) => {
  const dispatch = useDispatch();

  const orderASAP = useSelector((state: RootState) => state.order.orderASAP);
  const shouldUpdateOrder = useSelector(
    (state: RootState) => state.order.shouldUpdateOrder
  );
  const orderCollectionType = useSelector(
    (state: RootState) => state.order.orderCollectionType
  );
  const delivery = useSelector((state: RootState) => state.order.delivery);
  const orderTime = useSelector((state: RootState) => state.order.orderTime);
  const tableNumber = useSelector(
    (state: RootState) => state.order.tableNumber
  );
  const getOrderResponse = useSelector(
    (state: RootState) => state.order.getOrderResponse
  );
  const getOrderError = useSelector(
    (state: RootState) => state.order.getOrderError
  );
  const displayGenericOrderError = useSelector(
    (state: RootState) => state.order.displayGenericOrderError
  );
  const selectedStore = useSelector(
    (state: RootState) => state.store.selectedStore
  );
  const storeAsapTime = useSelector(storeAsapTimeSelector);
  const posMenuId = useSelector((state: RootState) => state.menu.posMenuId);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const activeReward = useSelector(
    (state: RootState) => state.loyalty.activeReward
  );
  const redeemDollars = useSelector(
    (state: RootState) => state.loyalty.redeemDollars
  );
  const checkoutLoading = useSelector(
    (state: RootState) => state.checkout.loading
  );
  const orderNotFoundError = getOrderError?.statusCode === HttpStatus.NotFound;

  const resetErrorModal = useCallback(() => {
    dispatch(
      OrderReduxAction.setDisplayErrorModal(
        OrderReduxModels.ErrorModalState.NONE
      )
    );
  }, [dispatch]);

  const handleOrderUpdate = useCallback(
    (orderId?: string) => {
      if (
        selectedStore &&
        !checkoutLoading &&
        (orderId || cartItems?.length > 0)
      ) {
        resetErrorModal();
        OrderModules.OrderUtils.createOrUpdateOrder(
          store,
          posMenuId,
          orderCollectionType,
          cartItems,
          orderTime,
          orderASAP,
          storeAsapTime,
          selectedStore,
          tableNumber,
          selectedStore.timeZoneInfo.storeTimeZone,
          orderId,
          activeReward,
          redeemDollars,
          delivery
        );
      }
    },
    [
      activeReward,
      cartItems,
      checkoutLoading,
      delivery,
      orderASAP,
      orderCollectionType,
      orderTime,
      posMenuId,
      redeemDollars,
      resetErrorModal,
      selectedStore,
      store,
      storeAsapTime,
      tableNumber,
    ]
  );

  /**
   * Update order if order object is not empty.
   * If order object is empty, create new order.
   * Need to be called after redux store is updated, for example after item added to the cart and state.cart is updated.
   *
   * @param forceCreate boolean flag to force create new order.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateOrder = useCallback(
    debounce((forceCreate: boolean = false) => {
      dispatch(OrderReduxAction.setShouldUpdateOrder(false));
      if (
        (!getOrderResponse?.orderId && cartItems?.length > 0) ||
        forceCreate
      ) {
        handleOrderUpdate();
      } else if (getOrderResponse?.orderId) {
        handleOrderUpdate(orderNotFoundError ? null : getOrderResponse.orderId);
      }
    }),
    [
      cartItems?.length,
      getOrderResponse?.orderId,
      handleOrderUpdate,
      orderNotFoundError,
    ]
  );

  useEffect(() => {
    if (shouldUpdateOrder && cartItems.length && posMenuId) {
      debouncedUpdateOrder();
    }
    return () => {
      debouncedUpdateOrder.cancel(); // cancel debounce on cleanup
    };
  }, [
    shouldUpdateOrder,
    debouncedUpdateOrder,
    cartItems.length,
    dispatch,
    posMenuId,
  ]);

  // Handle any issues with order POST/PATCH scenarios
  // resulting in errors we don't foresee. Generic error handling
  useEffect(() => {
    const orderErrorStatusCode = getStatusCode(getOrderError?.statusCode);

    if (
      displayGenericOrderError === OrderReduxModels.ErrorModalState.NONE &&
      orderErrorStatusCode !== HttpStatus.NotFound &&
      orderErrorStatusCode !== HttpStatus.CheckoutInProgress &&
      (orderErrorStatusCode >= HttpStatus.BadRequest ||
        orderErrorStatusCode >= HttpStatus.InternalServerError)
    ) {
      dispatch(
        OrderReduxAction.setDisplayErrorModal(
          OrderReduxModels.ErrorModalState.ERROR
        )
      );
    }
  }, [dispatch, displayGenericOrderError, getOrderError]);

  return { updateOrder: debouncedUpdateOrder };
};

export default useCreateUpdateOrder;
