import { useFeatureIsOn } from "@growthbook/growthbook-react";
import branch, { BranchError, DeepLinkData } from "branch-sdk";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  BranchConst,
  CartReduxAction,
  DashboardReduxAction,
  MenuActions,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  RootState,
  StoreModules,
  StoreReduxAction,
} from "gyg_common";
import OrderErrorModal from "gyg_common/components/modals/OrderErrorModal";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import {
  CollectionType,
  DeliveryModalState,
  getMenuChannelId,
  PickupModalState,
} from "gyg_common/redux_store/order/models";
import { basketValueSelector } from "gyg_common/redux_store/order/selectors";
import { storeAsapTimeSelector } from "gyg_common/redux_store/store/selectors";
import { Screens } from "navigation/const";

import { OrderSetup } from "@/views/components/OrderSetup/NotWithDelivery/OrderSetup";
import { OrderSetup as OrderSetupWithDelivery } from "@/views/components/OrderSetup/OrderSetup";

export interface OrderSetupContainerProps {
  isVisible: boolean;
  onCloseModal: () => void;
  onConfirmClick: () => void;
}

export const OrderSetupContainer: React.FC<OrderSetupContainerProps> = ({
  isVisible,
  onCloseModal,
  onConfirmClick,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isDesktopScreen } = useMediaQuery();

  const selectedStore = useSelector(
    (state: RootState) => state.store.selectedStore
  );
  const storeOpenTime = useSelector(
    (state: RootState) => state.store.storeOpenTime
  );
  const { storeOrderOffset, storeOrderTimes, storeTimeZoneInfo } = useSelector(
    (state: RootState) => state.store.storeOrderTimeSlots
  );

  const recentOrderToAddToCart = useSelector(
    (state: RootState) => state.dashboard.recentOrderToAddToCart
  );

  const menuStructure = useSelector(
    (state: RootState) => state.menu.menuStructure
  );
  const menuError = useSelector((state: RootState) => state.menu.error);
  const menuLoading = useSelector((state: RootState) => state.menu.loading);
  const menuOpenTime = useSelector(
    (state: RootState) => state.menu.menuOpenTime
  );
  const orderASAP = useSelector((state: RootState) => state.order.orderASAP);
  const orderCollectionType = useSelector(
    (state: RootState) => state.order.orderCollectionType
  );
  const orderTime = useSelector((state: RootState) => state.order.orderTime);
  const getOrderResponse = useSelector(
    (state: RootState) => state.order.getOrderResponse
  );
  const cartTotalPrice = useSelector(
    (state: RootState) => state.cart.totalPrice
  );
  const basketValue = useSelector(basketValueSelector);
  const storeAsapTime = useSelector(storeAsapTimeSelector);

  const isBrazeInitialised = useSelector(
    (s: RootState) => s.user.isBrazeInitialised
  );
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  /** Reset the user's order setup confirmation when they click change restaruants.
   * Then navigate them to the restaurant's list.
   */
  const handleChangeRestaurant = () => {
    onCloseModal();
    navigate(Screens.Restaurants, {
      state: {
        previousScreen: pathname,
      },
    });
  };

  /**
   * Set the user's order setup
   * Set the user's confirmation and load the menu for the selected store
   * Then navigate to the appropriate screen
   */
  const handleOrderConfirmation = (
    orderSetup: OrderReduxModels.SetupOrderProps
  ) => {
    let selectedTime = orderSetup.orderTime;
    if (
      selectedTime &&
      OrderModules.OrderUtils.isOrderTimeAsapExpired(selectedTime, true)
    ) {
      selectedTime = storeAsapTime.getTime();
    }

    batch(() => {
      dispatch(
        OrderReduxAction.setupOrder({
          ...orderSetup,
          orderTime: selectedTime,
        })
      );
      dispatch(OrderReduxAction.confirmOrderSetup(true));

      if (recentOrderToAddToCart) {
        const cartItems = OrderModules.OrderUtils.basketMapper(
          recentOrderToAddToCart,
          menuStructure
        );

        dispatch(
          CartReduxAction.addItemToCart({ cartItems, source: pathname })
        );
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(null));

        AnalyticsInstance.trackEvent(AnalyticsConst.Events.AddToOrder, {
          source: AnalyticsConst.AddToOrderSource.RECENTORDER,
        });
      }

      dispatch(OrderReduxAction.setShouldUpdateOrder(true));
    });
    onConfirmClick();
  };

  const handleNavigateToStoreDetails = () => {
    onCloseModal();
    dispatch(StoreReduxAction.setStoreInfo(selectedStore));
    navigate(Screens.Restaurants, {
      state: {
        previousScreen: pathname,
      },
    });
  };

  const handleOrderChange = (
    orderSetup: OrderReduxModels.OrderCollectionTypeProps
  ) => {
    dispatch(
      OrderReduxAction.setupOrderCollectionType({
        orderCollectionType: orderSetup.orderCollectionType,
      })
    );
  };

  const handleDownloadLink = (platform: string) => {
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.TableServiceOrderSetup,
      {}
    );

    if (isDesktopScreen) {
      window.open(
        platform === "ios"
          ? `${config.appStoreLink}`
          : `${config.googlePlayLink}`,
        "_blank"
      );
    } else {
      const linkData: DeepLinkData = {
        data: {
          path: BranchConst.BranchPaths.OrderSetup,
          pickUpType: "Table service",
        },
      };
      try {
        branch.link(linkData, function (err: BranchError, link: string | null) {
          if (link) {
            window.open(link, "_blank");
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onCloseIconClick = () => {
    onCloseModal();
  };

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isVisible && isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "order_setup",
        page_type: "order_setup_view",
      });
    }
  }, [isVisible, isBrazeInitialised]);

  useEffect(() => {
    if (selectedStore) {
      dispatch(
        StoreReduxAction.setStoreOpenTime(
          StoreModules.StoreUtils.checkStoreOpenHours(
            selectedStore?.tradingHours ?? [],
            selectedStore.timeZoneInfo.storeTimeZone
          )
        )
      );
    }
  }, [dispatch, selectedStore]);

  const selectStoreAndFetchDataForChosenStore = (state: PickupModalState) => {
    //Reset menu structure if order type changes
    if (
      orderCollectionType !== state.OrderCollectionType &&
      orderCollectionType
    ) {
      if (cartTotalPrice > 0) {
        dispatch(OrderReduxAction.setShowOrderUpdatedModal(true));
      }
      dispatch(MenuActions.resetMenuStructure());
    }

    //Clear order response if store changes or order type changes
    if (
      (getOrderResponse && selectedStore?.id !== state.ChosenStore.id) ||
      (orderCollectionType && orderCollectionType !== state.OrderCollectionType)
    ) {
      dispatch(OrderReduxAction.clearOrderResponse());
      dispatch(OrderReduxAction.setOrderResponse(null));
    }
    batch(() => {
      if (state.ChosenStore) {
        dispatch(StoreReduxAction.setStore(state.ChosenStore));
        dispatch(
          StoreReduxAction.setStoreOrderTimeslotsAndOffset({
            storeId: state.ChosenStore.id,
            basketValue,
          })
        );
      }
    });

    dispatch(
      MenuActions.getMenuStructure({
        channelId: getMenuChannelId(state.OrderCollectionType),
        storeId: state.ChosenStore.id,
      })
    );
  };

  const handleOnSubmitDeliveryFromModal = (state: DeliveryModalState) => {
    selectStoreAndFetchDataForChosenStore(state);
    handleOrderConfirmation({
      orderCollectionType: CollectionType.DELIVERY,
      orderASAP: true,
      orderTime: null,
      delivery: {
        pickupAddress: state.PickupAddress,
        dropoffAddress: state.DropoffAddress,
        dropoffSuiteAndFloor: state.Apartment,
        dropoffBusinessName: state.Business,
        dropoffOption: state.DropOffOption!,
        dropoffPinCodeRequired: state.DropoffPinCodeRequired,
        dropoffNotes: state.Instructions,
        deliveryDuration: state.DeliveryDuration,
      },
      deliveryAddress: state.DropoffAddress,
      collectionStore: state.ChosenStore,
    });
  };

  const handleOnSubmitPickupFromModal = (state: PickupModalState) => {
    selectStoreAndFetchDataForChosenStore(state);
    if (state.PickupDetails) {
      handleOrderConfirmation({
        ...state.PickupDetails,
        collectionStore: selectedStore,
        orderCollectionType: CollectionType.PICK_UP,
      });
    }
  };

  return (
    <>
      <OrderErrorModal />
      {!hasDelivery && (
        <OrderSetup
          cartTotalPrice={cartTotalPrice}
          storeOrderOffset={storeOrderOffset}
          menuError={menuError}
          menuLoading={menuLoading}
          menuStructure={menuStructure}
          storeOpenTime={storeOpenTime}
          storeOrderTimes={storeOrderTimes}
          menuOpenTime={menuOpenTime}
          isVisible={isVisible}
          selectedStore={selectedStore}
          orderCollectionType={orderCollectionType}
          orderASAP={orderASAP}
          orderTime={orderTime}
          onOrderConfirmClick={handleOrderConfirmation}
          onChangeRestaurantClick={handleChangeRestaurant}
          onChangeOrderCollectionType={handleOrderChange}
          navigateBack={() => navigate(-1)}
          navigateToStoreDetails={handleNavigateToStoreDetails}
          onCloseModal={onCloseIconClick}
          navigateToDownload={handleDownloadLink}
          storeAsapTime={storeAsapTime}
          storeTimeZoneInfo={storeTimeZoneInfo}
        />
      )}
      {hasDelivery && isVisible && (
        <OrderSetupWithDelivery
          isVisible={isVisible}
          store={selectedStore}
          onCloseModal={onCloseIconClick}
          onSubmitDelivery={handleOnSubmitDeliveryFromModal}
          onSubmitPickup={handleOnSubmitPickupFromModal}
        />
      )}
    </>
  );
};
