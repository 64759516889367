import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AnalyticsConst,
  AnalyticsInstance,
  ApiOrderModel,
  CheckoutModels,
  DashboardReduxAction,
  OrderModules,
  OrderReduxAction,
  RootState,
} from "gyg_common";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { OrderCardViewProps } from "gyg_common/modules/OrderCard/model";
import { addRecentOrderItemsToCart } from "modules/OrderCard";
import { Screens } from "navigation/const";
import RecentOrders from "views/components/RecentOrders/index";

import { RecentOrderAbandonModalContainer } from "../shared/RecentOrderAbandonModal/RecentOrderAbandonModalContainer";

import { OrderSetupContainer } from "@/views/containers/OrderSetup/OrderSetupContainer";

const RecentOrdersContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isAbanadonModalVisible, setAbandonModalVisible] =
    useState<boolean>(false);
  const {
    userConfirmedOrderSetup,
    recentOrders,
    currentOrders,
    showMoreAvailable,
    getAuthenticatedOrdersLoading,
  } = useSelector((state: RootState) => state.order);
  const guestOrders = useSelector((state: RootState) => state.guest.orders);
  const { isAuthSuccess } = useSelector((state: RootState) => state.login);
  const { selectedStore } = useSelector((state: RootState) => state.store);
  const { menuStructure } = useSelector((state: RootState) => state.menu);
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const [guestRecentOrders, setGuestRecentOrders] = useState<
    CheckoutModels.CheckoutResponse[]
  >([]);
  const [showOrderSetup, setOrderSetup] = useState<boolean>(false);
  const [reorderItems, setReorderItems] = useState<ApiOrderModel.BasketItem[]>(
    []
  );

  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "recent_orders",
        page_type: "recent_orders_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    if (guestOrders && guestOrders.length > 0 && !isAuthSuccess) {
      const recentOrdersArray: CheckoutModels.CheckoutResponse[] = [];
      guestOrders.forEach((data) => {
        if (OrderModules.OrderUtils.isOlderThanActiveOrderThreshold(data)) {
          recentOrdersArray.push(data);
        }
      });
      setGuestRecentOrders(recentOrdersArray);
    }
    if (isAuthSuccess) {
      dispatch(OrderReduxAction.getAuthenticatedOrders());
    }
  }, [dispatch, guestOrders, isAuthSuccess]);

  /**
   * Trigger function when Reorder is pressed
   */
  const reorderButtonPress = (model: OrderCardViewProps) => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.Reorder, {
      order_id: model.checkoutResponse.order.orderId,
      order_no: model.orderNo,
      total: model.price ?? "0.00",
      date_string: new Date().toLocaleString(),
      date: Date.now(),
    });

    const items = model.checkoutResponse.order.basket.basketItems;

    setReorderItems(items);

    if (cartItems.length > 0) {
      setAbandonModalVisible(true);
    } else if (userConfirmedOrderSetup && menuStructure) {
      addRecentOrderItemsToCart(items, menuStructure);
    } else {
      if (selectedStore !== null || hasDelivery) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(items));
        setOrderSetup(true);
      } else {
        navigate(Screens.Restaurants, {
          state: {
            recentOrdersToAddToCart: items,
          },
        });
      }
    }
  };

  const onShowMore = () => {
    OrderReduxAction.getAuthenticatedOrders({
      offset: recentOrders.length + currentOrders.length,
    });
  };

  const onConfirmOrderSetup = () => {
    if (cartItems.length === 0 && menuStructure && !hasDelivery) {
      addRecentOrderItemsToCart(reorderItems, menuStructure);
    }
    setOrderSetup(false);
    navigate(Screens.Order);
  };

  const onOrderSetupClose = () => {
    setOrderSetup(false);
  };

  return (
    <>
      <OrderSetupContainer
        onConfirmClick={onConfirmOrderSetup}
        onCloseModal={onOrderSetupClose}
        isVisible={showOrderSetup}
      />

      <RecentOrderAbandonModalContainer
        isAbandonModalVisible={isAbanadonModalVisible}
        reorderItems={reorderItems}
        setAbandonModalVisible={setAbandonModalVisible}
        setOrderSetup={setOrderSetup}
      />

      <RecentOrders
        showMoreAvailable={isAuthSuccess ? showMoreAvailable : false}
        recentOrders={
          isAuthSuccess
            ? [...currentOrders, ...recentOrders]
            : guestRecentOrders
        }
        isLoading={getAuthenticatedOrdersLoading}
        onShowMore={onShowMore}
        reorderButtonPress={reorderButtonPress}
      />
    </>
  );
};

export default RecentOrdersContainer;
