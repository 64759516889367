import { AxiosResponse } from "axios";

import config from "../../../config";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  IPostTermsAndConditionsRequest,
  SetPasswordRequest,
  UserAccountRequest,
  UserProfile,
} from "../../../redux_store/user/models";
import { getIP, httpClient } from "../../httpClient";
import {
  DeleteAccountVerifyPayload,
  PatchUserEmailPayload,
  PatchUserEmailResponse,
  PatchUserMobilePayload,
  VerifyEmailAddressResponse,
} from "./model";

export async function getUserData(): Promise<UserProfile> {
  try {
    // console.debug("API: GET user");

    const response = await httpClient({
      url: "user",
      method: "GET",
      headers: {
        Authorization: `${config.accessToken}`,
      },
    });

    return response.data as UserProfile;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "GET user");
    throw e;
  }
}

export async function postTermsAndConditions(
  data: IPostTermsAndConditionsRequest
): Promise<boolean> {
  try {
    // console.debug("API: POST usertermsandconditions");

    const userToken = config.accessToken;
    const ip = await getIP();
    const response = await httpClient({
      url: "usertermsandconditions",
      method: "POST",
      data: { ...data, ipAddress: ip },
      headers: {
        Authorization: `${userToken}`,
      },
    });
    return response.data as boolean;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST usertermsandconditions");
    throw e;
  }
}

// TODO: Check return type - is this right??
export async function updateUserData(
  userProfileData: UserAccountRequest
): Promise<number> {
  try {
    // console.debug("API: PATCH user");

    const userToken = config.accessToken;

    const response = await httpClient({
      url: "user",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken}`,
      },
      data: userProfileData,
    });

    return response.status;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "PATCH user");
    throw e;
  }
}

export async function setUserPassword(
  data: SetPasswordRequest
): Promise<ChangePasswordResponse> {
  try {
    // console.debug("API: PUT auth/setsigninpassword");

    const response = await httpClient({
      url: `auth/setsigninpassword`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: config.accessToken,
      },
      data: data,
    });

    return response.data as ChangePasswordResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "PUT auth/setsigninpassword");
    throw e;
  }
}

export async function updateUserPassword(
  data: ChangePasswordRequest
): Promise<ChangePasswordResponse> {
  try {
    // console.debug("API: PUT auth/password");

    const response = await httpClient({
      url: `auth/password`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: config.accessToken,
      },
      data: data,
    });

    return response.data as ChangePasswordResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "PUT auth/password");
    throw e;
  }
}

/**
 * @param payload
 * @returns triggers mobile verification code
 */
export async function getMobileVerificationCode(
  payload: PatchUserMobilePayload
): Promise<{ message: string }> {
  try {
    // console.debug("API: PATCH user/mobile");

    const response = await httpClient({
      url: `user/mobile`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
      data: payload,
    });

    return response.data as { message: string };
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "PATCH user/mobile");
    throw e;
  }
}

export async function updateUserEmail(
  payload: PatchUserEmailPayload
): Promise<PatchUserEmailResponse> {
  try {
    // console.debug("API: PATCH user/email");

    const response = await httpClient({
      url: `user/email`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
      data: payload,
    });

    return response.data as PatchUserEmailResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "PATCH user/email");
    throw e;
  }
}

export async function requestDeleteAccountCode(): Promise<
  AxiosResponse<boolean>
> {
  try {
    // console.debug("API: DELETE user/delete/trigger");

    const response = await httpClient({
      url: `user/delete/trigger`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });

    return response.data as AxiosResponse<boolean>;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "DELETE user/delete/trigger");
    throw e;
  }
}

export async function verifyDeleteAccountCode(
  data: DeleteAccountVerifyPayload
): Promise<AxiosResponse<boolean>> {
  try {
    // console.debug("API: POST user/delete/verify");

    const response = await httpClient({
      url: `user/delete/verify`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
      data: data,
    });

    return response.data as AxiosResponse<boolean>;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST user/delete/verify");
    throw e;
  }
}

export async function verifyEmailAddress(): Promise<VerifyEmailAddressResponse> {
  try {
    // console.debug("API: POST auth/verify/trigger/email/resend");
    const userToken = config.accessToken;
    const response = await httpClient({
      url: `auth/verify/trigger/email/resend`,
      method: "GET",
      headers: {
        Authorization: `${userToken}`,
      },
    });
    return response.data as VerifyEmailAddressResponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(
    //   e,
    //   "POST auth/verify/trigger/email/resend"
    // );
    throw e;
  }
}
