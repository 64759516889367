import { Platform, StyleSheet } from "react-native";

import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

export const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.Light,
    borderColor: colours.lightGrey,
  },
  header: {
    flexDirection: "row",
  },
  gomexHeader: {
    flex: 1,
  },
  spinnerContainer: {
    position: "relative",
  },
  bonusesWrapper: {
    paddingHorizontal: Spacing.Thin,
    paddingVertical: Spacing.Thin,
    borderRadius: BorderRadius.Medium,
    borderWidth: 2,
    borderColor: colours.yellow,
    backgroundColor: colours.white,
  },
  body: {
    flexDirection: "row",
    columnGap: Spacing.Thin,
  },
  bonusItemWrapper: {
    flex: 1,
    marginTop: Spacing.ExtraThin,
    flexDirection: "row",
    alignItems: "center",
  },
  bonusIcon: {
    marginRight: Spacing.ExtraThin,
  },
  bonusValue: {
    ...Typography.largeTitle,
    fontSize: 36,
    lineHeight: undefined,
    marginTop: Platform.OS !== "ios" ? 0 : 10,
  },
  bonusValueContainer: {
    maxWidth: "35%",
    marginRight: Spacing.ExtraThin,
  },
  bonusTitleContainer: {
    minWidth: "35%",
    maxWidth: "50%",
    marginTop: Platform.OS !== "ios" ? -2 : Spacing.ExtraThin,
  },
  bonusTitle: {
    ...Typography.titleThree,
    fontSize: 14,
  },
  bonusTitleValue: {
    ...Typography.titleThree,
    fontSize: 14,
  },
});
