import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, StyleSheet, Text, View } from "react-native";

import * as ProductUtils from "../../../modules/Products/utils";
import * as CartReduxModels from "../../../redux_store/cart/models";
import * as MenuModels from "../../../redux_store/menu/models";
import colours from "../../styles/colours";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";
import TacoMultiPartSectionItem from "../TacoMultiPartSectionItem";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.white,
  },
  multipartList: {
    marginHorizontal: Spacing.Light,
    marginBottom: Spacing.Thin,
  },
  listContainer: {
    marginTop: Spacing.Light,
  },
  customization: {
    backgroundColor: colours.lightestGrey,
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Regular,
    borderBottomColor: colours.lightGrey,
    borderBottomWidth: 1,
  },
  requiredLabel: {
    ...Typography.bodyTwo,
    marginTop: Spacing.Thin,
  },
});

export interface TacoMultipartSectionSelectionProps {
  category: MenuModels.Category;
  error?: boolean;
  multiPart: MenuModels.MultiPart;
  tacoItem: CartReduxModels.BundleState;
  handleResetTacoItem: (index: number) => void;
  handleSelectMultipartSection: (
    category: MenuModels.Category,
    index: number
  ) => void;
  handleOnApplyAll: (
    multiPartSectionItem: CartReduxModels.CartItem,
    multipartIndex: number
  ) => void;
}

/**
 * Render Taco seletion list (TacoMultipartSectionItem[]) after choose multipart (e.g. 1 Taco) of TACOS main page
 * @param props
 * @returns
 */
export interface ProductMultipartSectionListItemProps {
  item: MenuModels.MultipartSection;
  index: number;
}

const TacoMultipartSectionSelection: React.FC<
  TacoMultipartSectionSelectionProps
> = ({
  category,
  error,
  multiPart,
  tacoItem,
  handleResetTacoItem,
  handleSelectMultipartSection,
  handleOnApplyAll,
}) => {
  const { t } = useTranslation();
  const renderMultipartSectionItem = ({
    item,
    index,
  }: ProductMultipartSectionListItemProps) => {
    return (
      <View
        key={item.name}
        style={{ ...styles.multipartList, marginTop: index === 0 ? 16 : 0 }}>
        <TacoMultiPartSectionItem
          handleOnApplyAll={handleOnApplyAll}
          applyAllValid={
            ProductUtils.applyAllValidObjects(
              multiPart,
              tacoItem[index],
              ProductUtils.getExcludedIndexs(tacoItem, index)
            ).length > 0
          }
          multiPartIndex={index}
          onReset={() => handleResetTacoItem(index)}
          multiPartSectionItem={tacoItem[index]}
          multiPartSection={item}
          onPress={(category) => handleSelectMultipartSection(category, index)}
        />
      </View>
    );
  };
  const renderListHeaderComponent = useCallback(() => {
    const titleForCustomizeProduct = `${t(
      "OrderManagement:customizeTacoSection"
    )} ${category.name.toUpperCase()}`;
    return (
      <View>
        <View style={styles.customization}>
          <Text style={Typography.titleTwo}>{titleForCustomizeProduct}</Text>
          <Text style={[styles.requiredLabel, error ? Typography.error : null]}>
            {t("OrderManagement:required")}
          </Text>
        </View>
      </View>
    );
  }, [category.name, error, t]);

  return (
    <View>
      <FlatList
        key={multiPart.name}
        nestedScrollEnabled={false}
        keyExtractor={(item, index) => item.name + index}
        renderItem={renderMultipartSectionItem}
        ListHeaderComponent={renderListHeaderComponent}
        data={multiPart.multiPartSection}
        scrollEventThrottle={50}
      />
    </View>
  );
};

export default TacoMultipartSectionSelection;
