import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
  ViewStyle,
} from "react-native";

import {
  MenuModels,
  MenuModuleUtils,
  PlatformUtils,
  ProductConst,
  ProductUtils,
} from "../..";
import defaultImage from "../../components/assets/images/no_image_available_menu.png";
import CardSticker from "../../components/CardSticker";
import FeaturedTag from "../../components/FeaturedTag";
import MenuImage from "../../components/Menu/MenuImage";
import { BorderRadius, Spacing } from "../../components/styles/number";
import { MEDIUM_WEB_WIDTH } from "../../utils/device";
import colours from "../styles/colours";
import { Typography } from "../styles/typography";

interface MenuListItemTileProps {
  item: MenuModels.Category | MenuModels.Product | MenuModels.MultiPart;
  price?: string;
  size?: string;
  nutritionalInfo?: string;
  style?: StyleProp<ViewStyle>;
  substitutionalTitle?: string;
  bigCard?: boolean;
  smallCard?: boolean;
  infoMinHeight?: number;
  noDescription?: boolean;
  onPress?: () => void;
  priority?: string;
}

interface StyleProps {
  hovered?: boolean;
  pressed?: boolean;
}

const bigTileStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  detailsText: {
    ...Typography.captionOne,
    flex: 2,
    paddingTop: Spacing.ExtraLight,
  },
  price: {
    ...Typography.captionOne,
    flex: 2,
    paddingTop: Spacing.Thin,
  },
  bottomText: {
    ...Typography.titleOne,
    fontSize: 16,
    paddingTop: Platform.OS == "web" ? Spacing.Narrow : Spacing.Thin,
    letterSpacing: 0.5,
  },
});

const styles = (
  infoMinHeight?: number,
  isFeatured?: boolean,
  titleTopSpace?: boolean
) =>
  StyleSheet.create({
    wrapper: {
      marginHorizontal: Platform.OS == "web" ? 0 : Spacing.Light,
      marginBottom: Spacing.Thin,
      minHeight: 88,
      flex: Platform.OS == "web" ? 1 : undefined,
    },
    container: {
      shadowRadius: Platform.OS == "web" ? 0 : BorderRadius.Medium,
      flexDirection: "row",
      justifyContent: "flex-start",
      elevation: 8,
      minHeight: 88,
      flex: Platform.OS == "web" ? 1 : undefined,
    },
    productImage: {
      width: 110,
      height: 88,
      borderRadius: BorderRadius.Medium,
    },
    productImageBig: {
      width: "100%",
      height: Platform.OS == "web" ? 188 : 176,
    },
    productInfo: {
      flexDirection: "column",
      justifyContent: "center",
      marginTop: isFeatured ? Spacing.Thin : Spacing.Light,
      paddingRight: Spacing.Regular,
      flex: 1,
    },
    details: {
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingTop: Spacing.ExtraThin,
      paddingRight: Spacing.Regular,
      paddingBottom: Spacing.Light,
    },
    detailsText: {
      ...Typography.captionOne,
      flex: 2,
    },
    titleWrapper: {
      flexDirection: "row",
      alignItems: "center",
    },
    menuItemHeading: {
      ...Typography.smallProductTitle,
      paddingRight: 10,
      flexShrink: 1,
      width: "100%",
    },
    cardContent: {
      minHeight: infoMinHeight ? infoMinHeight : 124,
      padding: Platform.OS == "web" ? Spacing.Regular : Spacing.Light,
      paddingTop:
        Platform.OS == "web"
          ? titleTopSpace
            ? Spacing.Regular
            : 0
          : Spacing.Light,
      paddingBottom: Spacing.Light,
      flex: Platform.OS == "web" ? 1 : undefined,
    },
    featuredValueContainer: {
      marginBottom: 10,
    },
  });

const buttonStyle = (props: StyleProps) =>
  StyleSheet.create({
    menuItem: {
      backgroundColor:
        props.hovered || props.pressed ? colours.lightestGrey : colours.white,
      ...styles().container,
      borderRadius: BorderRadius.Medium,
      overflow: "hidden",
    },
  });

const MenuListItemTile = (props: MenuListItemTileProps): JSX.Element => {
  const {
    item,
    price,
    size,
    nutritionalInfo,
    style,
    substitutionalTitle,
    bigCard,
    noDescription,
    infoMinHeight,
    priority,
    smallCard,
    onPress,
  } = props;
  const { t } = useTranslation();
  const title = substitutionalTitle || item.name;
  const description = !noDescription
    ? ProductUtils.getCustomizationText(item as MenuModels.Category, {}) ||
      item.description
    : undefined;

  const featuredValue = MenuModuleUtils.checkForFeaturedTag(
    item.tags,
    item.tagLookup
  )?.value;

  const windowWidth = Dimensions.get("window").width;
  const isWebDesktop = Platform.OS === "web" && windowWidth >= MEDIUM_WEB_WIDTH;
  const defaultWeb = isWebDesktop && !bigCard && !smallCard;

  return (
    <View
      style={[styles().wrapper, style]}
      {...PlatformUtils.generateTestID(Platform.OS, `MenuListItem-${title}`)}>
      {bigCard || defaultWeb ? (
        <CardSticker>
          <TouchableHighlight
            underlayColor={colours.lightestGrey}
            onPress={onPress}
            style={buttonStyle({}).menuItem}>
            <View style={bigTileStyles.container}>
              <MenuImage
                resizeMode={
                  item.imageAngleUrl?.length && !defaultWeb
                    ? "cover"
                    : "contain"
                }
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  `MenuItemImageBig-${title}`
                )}
                priority={priority}
                source={
                  defaultWeb
                    ? item.imageTopDownUrl?.length
                      ? (item.imageTopDownUrl as ImageSourcePropType)
                      : defaultImage
                    : item.imageAngleUrl?.length
                      ? (item.imageAngleUrl as ImageSourcePropType)
                      : defaultImage
                }
                style={styles().productImageBig}
              />

              <View
                style={
                  styles(
                    infoMinHeight,
                    undefined,
                    ProductUtils.getProductRoute(
                      (item as MenuModels.MultiPart)?.templateId as string
                    ) === ProductConst.ProductRoute.Bundles
                  ).cardContent
                }>
                <View style={styles().titleWrapper}>
                  {featuredValue && <FeaturedTag label={featuredValue} />}
                  <Text
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      `MenuHeading-${title}`
                    )}
                    adjustsFontSizeToFit
                    style={styles().menuItemHeading}>
                    {title.toUpperCase()}
                  </Text>
                </View>

                {!noDescription && (
                  <Text style={bigTileStyles.detailsText}>{description}</Text>
                )}
                {price && (
                  <Text style={bigTileStyles.price}>{`From $${price}`}</Text>
                )}

                {Platform.OS == "web" && (
                  <Text style={bigTileStyles.bottomText}>
                    {t("CheckoutPayment:order")}
                  </Text>
                )}
              </View>
            </View>
          </TouchableHighlight>
        </CardSticker>
      ) : (
        <CardSticker>
          <Pressable
            onPress={onPress}
            style={({ hovered, pressed }) =>
              buttonStyle({ hovered, pressed }).menuItem
            }>
            <View style={styles().productImage}>
              <MenuImage
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  `MenuItemImage-${title}`
                )}
                source={
                  item.imageTopDownUrl?.length
                    ? (item.imageTopDownUrl as ImageSourcePropType)
                    : defaultImage
                }
                style={styles().productImage}
              />
            </View>
            <View style={styles(undefined, !!featuredValue).productInfo}>
              <View>
                {featuredValue && (
                  <View style={styles().featuredValueContainer}>
                    <FeaturedTag label={featuredValue} />
                  </View>
                )}
                <Text
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    `MenuHeading-${title}`
                  )}
                  adjustsFontSizeToFit
                  style={styles().menuItemHeading}>
                  {title.toUpperCase()}
                </Text>
              </View>
              <View style={styles().details}>
                <Text style={styles().detailsText}>
                  {price && `From $${price}`}
                </Text>
                <Text style={styles().detailsText}>
                  {size && nutritionalInfo
                    ? `${size} | ${nutritionalInfo}${t(
                        "OrderManagement:energyUnit"
                      )}`
                    : size
                      ? `${size}`
                      : nutritionalInfo &&
                        `${nutritionalInfo}${t("OrderManagement:energyUnit")}`}
                </Text>
              </View>
            </View>
          </Pressable>
        </CardSticker>
      )}
    </View>
  );
};

export default memo(MenuListItemTile);
