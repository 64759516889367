import * as PlatformUtils from "../../modules/platformUtils";
import { formatLoyaltyDollars } from "../../modules/Gomex/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, StyleSheet, Platform } from "react-native";
import colours from "../styles/colours";
import { Typography } from "../styles/typography";

const styles = StyleSheet.create({
  gygYellowText: {
    ...Typography.titleThree,
    fontSize: 16,
    color: colours.yellow,
  },
  gygYellowNumber: {
    ...Typography.titleThree,
    fontSize: 44,
    color: colours.yellow,
    alignSelf: "flex-end",
    maxWidth: 160,
    lineHeight: 46,
  },
  gygPointContainer: {
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-start",
  },
  leftGygTextSection: {
    alignItems: "center",
    flex: 0.5,
    flexDirection: "row",
    gap: 8,
    justifyContent: "flex-start",
  },
  rightGygTextSection: {
    alignItems: "center",
    flex: 0.65,
    flexDirection: "row",
    gap: 8,
    justifyContent: "flex-end",
  },
  textWrapper: {
    flex: 1,
  },
});

export interface GomexPointsViewProps {
  userLoyaltyPoints: number;
  userLoyaltyDollar: number;
}

const GomexPointsView: React.FC<GomexPointsViewProps> = ({
  userLoyaltyDollar,
  userLoyaltyPoints,
}) => {
  const { t } = useTranslation();

  const gygPointsText = t("Loyalty:gygPointsLabel").split(" ");
  const gygDollarsText = t("Loyalty:gygDollarsLabel").split(" ");
  const gygDollars = formatLoyaltyDollars(userLoyaltyDollar);

  return (
    <View style={styles.gygPointContainer}>
      <View style={styles.leftGygTextSection}>
        <Text
          style={styles.gygYellowNumber}
          adjustsFontSizeToFit
          numberOfLines={1}
          {...PlatformUtils.generateTestID(Platform.OS, "LoyaltyPoints")}>
          {userLoyaltyPoints}
        </Text>
        <View>
          <Text style={styles.gygYellowText}>{gygPointsText[0]}</Text>
          <Text style={styles.gygYellowText}>{gygPointsText[1]}</Text>
        </View>
      </View>

      <View style={styles.rightGygTextSection}>
        <View style={styles.textWrapper}>
          <Text
            style={styles.gygYellowNumber}
            adjustsFontSizeToFit
            numberOfLines={1}
            {...PlatformUtils.generateTestID(Platform.OS, "GYGDollars")}>
            ${gygDollars}
          </Text>
        </View>
        <View>
          <Text style={styles.gygYellowText}>{gygDollarsText[0]}</Text>
          <Text style={styles.gygYellowText}>{gygDollarsText[1]}</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(GomexPointsView);
