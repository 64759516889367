import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnalyticsInstance } from "../analytics";
import { Events } from "../modules/Analytics/const";
import { AnalyticsAction } from "../redux_store/analytics/analytics.slice";
import { RootState } from "../redux_store/rootReducer";

export const useUpsellTracking = () => {
  const analyticsState = useSelector((s: RootState) => s.analytics);
  const dispatch = useDispatch();

  useEffect(() => {
    const event = analyticsState.isUpsellPreCart
      ? Events.PreCartUpsell
      : Events.InCartUpsell;

    if (analyticsState.upsellProductId.length > 0) {
      const productId = analyticsState.upsellProductId[0];
      const productIndex = analyticsState.productId.findIndex(
        (id: number) => id === productId
      );
      const eventPayload = {
        product_id_upsell: productId.toString(),
        product_name_upsell: analyticsState.productName[productIndex],
        product_category_upsell: analyticsState.productCategory[productIndex],
        product_quantity_upsell: analyticsState.productQuantity[productIndex],
      };

      AnalyticsInstance.trackEvent(event, eventPayload);
      dispatch(
        AnalyticsAction.addUpsellItemToCart({
          id: 0,
          didAddToCart: false,
          isUpsellPreCart: analyticsState.isUpsellPreCart,
        })
      );
    }
  }, [analyticsState, dispatch]);
};
