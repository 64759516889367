import React, { useState } from "react";
import { Platform, Pressable, StyleSheet, Text, TextStyle } from "react-native";

import * as PlatformUtils from "../../modules/platformUtils";
import colours from "../styles/colours";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

const styles = (linkColor: string, isBoldText: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: isBoldText ? -Spacing.Narrow : 0,
      backgroundColor: linkColor,
      borderRadius: BorderRadius.Light,
    },
    text: {
      ...(isBoldText ? Typography.bodyBold : Typography.body),
      textDecorationLine:
        linkColor === colours.starburstOpacity
          ? "none"
          : ("underline" as TextStyle["textDecorationLine"]),
      paddingLeft: Spacing.ExtraThin,
      paddingRight: Spacing.ExtraThin,
    },
  });
export interface LinkButtonProps {
  testId: string;
  buttonName: string;
  boldText?: boolean;
  onPress?: () => void;
}

export const LinkButton = (props: LinkButtonProps): JSX.Element => {
  const { testId, buttonName, boldText, onPress } = props;
  const [linkColor, setLinkColor] = useState<string>(colours.white);
  const isBoldText = boldText ? true : false;

  return (
    <Pressable
      style={styles(linkColor, isBoldText).container}
      {...PlatformUtils.generateTestID(Platform.OS, testId)}
      onPressIn={() => setLinkColor(colours.starburstOpacity)}
      onPressOut={() => setLinkColor(colours.white)}
      onPress={onPress}>
      <Text style={styles(linkColor, isBoldText).text}>{buttonName}</Text>
    </Pressable>
  );
};
