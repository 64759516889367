import { Spacing } from "../../../components/styles/number";
import colours from "../../../components/styles/colours";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: colours.white,
  },
  titleSection: {
    flexDirection: "row",
    paddingHorizontal: Spacing.Light,
    paddingVertical: Spacing.Regular,
    backgroundColor: colours.lightestGrey,
    borderBottomWidth: 1,
    borderBottomColor: colours.lightGrey,
    alignItems: "center",
  },
  split: {
    flex: 1,
  },
  removeMeal: {
    backgroundColor: colours.white,
    paddingHorizontal: Spacing.Regular,
    paddingVertical: Spacing.Thin,
    borderRadius: Spacing.Thin,
  },
});
