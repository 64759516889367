import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Animated,
  ImageSourcePropType,
  Platform,
  Text,
  View,
} from "react-native";

import {
  CartReduxModels,
  LoyaltyModels,
  MenuModels,
  ProductConst,
} from "../../..";
import backIcon from "../../../components/assets/icons/icon_chevron_left.png";
import closeIcon from "../../../components/assets/icons/icon_close.png";
import defaultImage from "../../../components/assets/images/no_product_hero_image.png";
import ResetButton from "../../../components/Buttons/ResetButton";
import CardSticker from "../../../components/CardSticker";
import { CoffeeLoyaltyFeatureFlag } from "../../../components/Gomex/CoffeeLoyalty/CoffeeLoyaltyFeatureFlag";
import { CoffeeLoyaltyInfo } from "../../../components/Gomex/CoffeeLoyalty/CoffeeLoyaltyInfo";
import IconButton from "../../../components/IconButton";
import MenuImage from "../../../components/Menu/MenuImage";
import { ProductNutritionalInfoDisclaimer } from "../../../components/ProductNutritionalInfoDisclaimer";
import ProductQuantitySelector from "../../../components/Products/ProductQuantitySelector";
import * as PlatformUtils from "../../../modules/platformUtils";
import { priority } from "../../config/fastimage";
import { SaveAsFavouriteInputAndModal } from "../../modals/SaveAsFavouriteInputAndModal";
import { Typography } from "../../styles/typography";
import { backButtonStyle, styles } from "./ProductDetail.styles";

export interface ProductDetailProps {
  showNutritionalDisclaimer?: boolean;
  name: string;
  price?: number;
  customizationText?: string;
  allergenText?: string;
  imageAngleUrl?: string;
  onGoBack: () => void;
  onReset?: () => void;
  quantity?: number;
  quantityDisabled?: boolean;
  extraFillingOptions?: string;
  extraOptions?: string;
  removeOptions?: string;
  mealOptions?: string;
  setQuantity?: (quantity: number) => void;
  resetDisabled?: boolean;
  hidePrice?: boolean;
  fadeAnimationProductDetail?: Animated.Value;
  gobackMode?: boolean;
  productSize?: string;
  productNutritionalInfo?: string;
  closeButtonPosition?: "left" | "right";
  cartItems?: CartReduxModels.CartItem[];
  category?: MenuModels.Category;
  editMode?: ProductConst.EditMode;
  coffeeLoyalty?: LoyaltyModels.CoffeeLoyalty | null;
}

export interface ProductImageProps {
  imageAngleUrl?: string;
}

const ProductImage = (props: ProductImageProps) =>
  useMemo(() => {
    return (
      <MenuImage
        {...PlatformUtils.generateTestID(Platform.OS, "ProductDetailImage")}
        style={styles.productImage}
        source={
          props.imageAngleUrl?.length
            ? (props.imageAngleUrl.trim() as ImageSourcePropType)
            : defaultImage
        }
        priority={priority.high}
      />
    );
  }, [props.imageAngleUrl]);

function ProductDetail(props: ProductDetailProps) {
  const { t } = useTranslation();

  return (
    <View>
      <View style={styles.container}>
        {Platform.OS === "web" ? (
          // Required for mobile web to be inside "div with id=product-img so CustomizableProduct can detect it while scrolling"
          <div id={"product-img"}>
            <ProductImage imageAngleUrl={props.imageAngleUrl} />
          </div>
        ) : (
          <ProductImage imageAngleUrl={props.imageAngleUrl} />
        )}

        <View
          style={
            backButtonStyle(
              props.closeButtonPosition == "left" ||
                props.closeButtonPosition == null
            ).backButton
          }>
          <IconButton
            testID={"CustomizableProductGoBackButton"}
            onPress={props.onGoBack}
            iconImage={props.gobackMode ? backIcon : closeIcon}
          />
        </View>
        {props.quantity && props.setQuantity && (
          <View style={styles.quantitySelector}>
            <CardSticker>
              <ProductQuantitySelector
                quantity={props.quantity}
                setQuantity={props.setQuantity}
                forceDisable={props.quantityDisabled}
              />
            </CardSticker>
          </View>
        )}
      </View>
      <View style={styles.productInfo}>
        <View style={styles.quantitySpace} />

        <Text
          style={styles.productTitle}
          {...PlatformUtils.generateTestID(Platform.OS, "ProductDetailTitle")}>
          {Platform.OS == "web" ? props.name.toUpperCase() : props.name}
        </Text>

        <View>
          {props.allergenText && (
            <Text style={[Typography.body, styles.allergenText]}>
              {props.allergenText}
            </Text>
          )}
        </View>

        <View style={styles.row}>
          {props.productSize && (
            <View style={styles.row}>
              <Text style={Typography.captionOne}>{props.productSize}</Text>
              {!props.hidePrice || props.productNutritionalInfo ? (
                <Text style={Typography.captionOne}>{` | `}</Text>
              ) : null}
            </View>
          )}
          {props.productNutritionalInfo && (
            <View style={styles.row}>
              <Text style={Typography.captionOne}>
                {props.productNutritionalInfo}
                {t("OrderManagement:energyUnit")}
              </Text>
              {!props.hidePrice && (
                <Text style={Typography.captionOne}>{` | `}</Text>
              )}
            </View>
          )}

          {!props.hidePrice && (
            <Text style={Typography.captionOne}>{`From $${(
              props.price ?? 0
            ).toFixed(2)}`}</Text>
          )}
        </View>

        <View style={styles.textView}>
          {props.customizationText && props.customizationText.length > 0 && (
            <Text style={Typography.body}>{props.customizationText}</Text>
          )}
        </View>

        {props.extraOptions && (
          <View style={styles.row}>
            <View style={styles.optionText}>
              <Text style={Typography.bodyBold}>
                {t("OrderManagement:customizationDescAdd")}
              </Text>
            </View>
            <View style={styles.optionCustomizeText}>
              <Text style={Typography.body}>{props.extraOptions}</Text>
            </View>
          </View>
        )}

        {props.extraFillingOptions && (
          <View style={styles.row}>
            <View style={styles.optionText}>
              <Text style={Typography.bodyBold}>
                {t("OrderManagement:customizationDescExtras")}
              </Text>
            </View>
            <View style={styles.optionCustomizeText}>
              <Text style={Typography.body}>{props.extraFillingOptions}</Text>
            </View>
          </View>
        )}

        {props.removeOptions && (
          <View style={styles.row}>
            <View style={styles.optionText}>
              <Text style={Typography.bodyBold}>
                {t("OrderManagement:customizationDescRemove")}
              </Text>
            </View>
            <View style={styles.optionCustomizeText}>
              <Text style={Typography.body}>{props.removeOptions}</Text>
            </View>
          </View>
        )}

        {props.mealOptions && (
          <View style={styles.row}>
            <View style={styles.optionText}>
              <Text style={Typography.bodyBold}>
                {t("OrderManagement:customizationDescMeal")}
              </Text>
            </View>
            <View style={styles.optionCustomizeText}>
              <Text style={Typography.body}>{props.mealOptions}</Text>
            </View>
          </View>
        )}
        {props.showNutritionalDisclaimer && (
          <View style={styles.row}>
            <ProductNutritionalInfoDisclaimer />
          </View>
        )}

        {props.category?.templateId === ProductConst.TemplateId.HotDrinks &&
          props.coffeeLoyalty?.counter && (
            <CoffeeLoyaltyFeatureFlag>
              <View style={styles.textView}>
                <CardSticker>
                  <View style={styles.coffeeSection}>
                    <CoffeeLoyaltyInfo
                      useBlackFont
                      loyalty={props.coffeeLoyalty}
                    />
                  </View>
                </CardSticker>
              </View>
            </CoffeeLoyaltyFeatureFlag>
          )}

        {(props.category || props.name) &&
          (props.editMode !== ProductConst.EditMode.UPDATE_TACO_ITEM ||
            !props.editMode) && (
            <SaveAsFavouriteInputAndModal
              cartItems={props.cartItems}
              categoryName={props.category ? props.category.name : props.name}
            />
          )}
      </View>

      {props.onReset && (
        <ResetButton
          disabled={Boolean(props.resetDisabled)}
          onPress={props.onReset}
        />
      )}

      <View style={styles.divider} />
    </View>
  );
}

export default ProductDetail;
