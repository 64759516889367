import "./style.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AnalyticsConst, ErrorReduxModel } from "gyg_common";
import { LinkButton } from "gyg_common/components/Buttons/LinkButton";
import ErrorAlert from "gyg_common/components/Error/ErrorAlert";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import ToggleSelect, {
  ActiveTabType,
} from "gyg_common/components/ToggleSelect";
import { LoginOTPChannel } from "gyg_common/redux_store/auth/models";
import { ErrorResponse } from "gyg_common/redux_store/error/models";
import { SocialButtonType } from "views/containers/Auth/LoginContainer";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import JoinGYG from "./JoinGYG";
import LoginOTP from "./LoginOTP";
import LoginWithEmail from "./LoginWithEmail";
import LoginWithSocialMedia from "./LoginWithSocialMedia";

export interface LoginProps {
  onLoginPress: (username: string, password: string) => void;
  onGoAsGuest: () => void;
  onGoRegister: () => void;
  updateMode: (mode: ActiveTabType) => void;
  selectedMode: ActiveTabType;
  loading: boolean;
  loginErrorResponse: ErrorReduxModel.ErrorResponse | undefined;
  isLogoutAllError: ErrorReduxModel.ErrorResponse;
  onForgotPassword: () => void;
  onSocialLogin: (
    type: SocialButtonType,
    loginType: AnalyticsConst.LoginType
  ) => void;
  onCloseErrorAlert: () => void;
  onSubmitOTP: (OTP: string) => void;
  requiresOTP: boolean;
  onOTPResend: (OTPChannel: LoginOTPChannel) => void;
  onChangeVerificationMethod: (newLoginOTPChannel: LoginOTPChannel) => void;
  sentLoginOTPChannel?: LoginOTPChannel;
  sentLoginOTPSource?: string;
  isSendingOTP: boolean;
  mfaError?: ErrorResponse;
  otpSubmittedLoading: boolean;
}

const Login: React.FC<LoginProps> = ({
  onLoginPress,
  onGoAsGuest,
  onGoRegister,
  updateMode,
  selectedMode,
  loading,
  loginErrorResponse,
  isLogoutAllError,
  onForgotPassword,
  onSocialLogin,
  onCloseErrorAlert,
  onSubmitOTP,
  requiresOTP,
  onOTPResend,
  onChangeVerificationMethod,
  sentLoginOTPChannel,
  sentLoginOTPSource,
  isSendingOTP,
  mfaError,
  otpSubmittedLoading,
}): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState<
    ErrorReduxModel.ErrorResponse | undefined
  >();

  useEffect(() => {
    if (!!loginErrorResponse) {
      const body = document.documentElement;
      body.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [loginErrorResponse]);

  useEffect(() => {
    if (loginErrorResponse) {
      setError(loginErrorResponse);
    } else if (isLogoutAllError) {
      setError(isLogoutAllError);
    } else {
      setError(undefined);
    }
  }, [loginErrorResponse, isLogoutAllError]);

  const NoAccountYet = () => {
    return (
      <>
        <div className='title-four-regular'>
          {t("LoginRegistration:noAccountYet")}
        </div>

        <div className='flex-container'>
          <div className='easy-to-join'>
            {t("LoginRegistration:itIsEasyTo")}
          </div>
          <LinkButton
            testId={t("LoginRegistration:register")}
            buttonName={t("LoginRegistration:register")}
            onPress={onGoRegister}
            boldText
          />
        </div>
      </>
    );
  };

  const ContinueAsGuest = () => {
    return (
      <LinkButton
        testId={t("LoginRegistration:continueAsGuest")}
        buttonName={t("LoginRegistration:continueAsGuest")}
        onPress={onGoAsGuest}
        boldText
      />
    );
  };

  const LoginView = () => {
    return (
      <div className='main-login__section'>
        <div className='title-four-regular email__section'>
          {t("LoginRegistration:emailLoginTitle")}
        </div>
        <LoginWithEmail
          onLoginPress={onLoginPress}
          onForgotPassword={onForgotPassword}
        />

        <div className='main-login__section flex-container divider__bottom'>
          <div className='line' />
          <div className='title-four flex-text divider__section'>
            {t("LoginRegistration:orLoginWith")}
          </div>
          <div className='line' />
        </div>

        <LoginWithSocialMedia
          onSocialLogin={(loginType) =>
            onSocialLogin(SocialButtonType.LOGIN, loginType)
          }
        />

        <div className='grey-line divider' />

        {NoAccountYet()}

        <div className='grey-line divider' />
      </div>
    );
  };

  return (
    <div className='main-login'>
      {!requiresOTP && (
        <>
          <HeaderContainer pageTitle={t("LoginRegistration:loginPageTitle")} />
          <LoadingScreen loading={loading} />
          <div className='main-wrapper main-wrapper--narrow'>
            <div className='main-wrapper__col main-wrapper__col--single'>
              <div className='main-login__header'>
                <ErrorAlert
                  isVisible={!!error}
                  title={
                    error?.message ??
                    error?.heading ??
                    t("LoginRegistration:errorLabel")
                  }
                  onDismiss={onCloseErrorAlert}
                />
                <p className='main-login__subtitle'>
                  {t("LoginRegistration:loginPageSubTitle")}
                </p>
                <ToggleSelect
                  onUpdateTab={updateMode}
                  activeTab={selectedMode}
                  leftTitle={t("LoginRegistration:login")}
                  rightTitle={t("LoginRegistration:joinGYG")}
                />
              </div>

              {selectedMode === ActiveTabType.RIGHT ? (
                <JoinGYG
                  onGoRegister={onGoRegister}
                  onSocialLogin={(loginType) =>
                    onSocialLogin(SocialButtonType.REGISTER, loginType)
                  }
                />
              ) : (
                LoginView()
              )}

              <div>{ContinueAsGuest()}</div>
            </div>
          </div>
        </>
      )}
      {requiresOTP && (
        <>
          <HeaderContainer pageTitle={t("LoginRegistration:otpVerify")} />
          <LoadingScreen loading={loading} />
          <div className='main-wrapper main-wrapper--narrow'>
            <div className='main-wrapper__col main-wrapper__col--single'>
              <div className='main-login__header'>
                <LoginOTP
                  onOTPSubmit={onSubmitOTP}
                  onOTPResend={onOTPResend}
                  onChangeVerificationMethod={onChangeVerificationMethod}
                  sentLoginOTPChannel={sentLoginOTPChannel}
                  sentLoginOTPSource={sentLoginOTPSource}
                  isSendingOTP={isSendingOTP}
                  mfaError={mfaError}
                  otpSubmittedLoading={otpSubmittedLoading}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
