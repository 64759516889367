import "./styles.scss";

import loadingText from "assets/gif/loadingtext.json";
import React from "react";
import { useTranslation } from "react-i18next";

import { ErrorModal } from "gyg_common/components/Error/ErrorView/ErrorModal";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import { ErrorResponse } from "gyg_common/redux_store/error/models";
import { GetOrderResponse } from "gyg_common/services/api/order/model";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import OrderDetailsContainer from "@/views/containers/OrderDetails/OrderDetailsContainer";

export interface OrderDeliveryStatusProps {
  failure?: ErrorResponse;
  trackingUrlExpiresAt?: string;
  orderStatus?: GetOrderResponse | null;
}

const OrderDeliveryStatus: React.FC<OrderDeliveryStatusProps> = ({
  failure,
  trackingUrlExpiresAt,
  orderStatus,
}) => {
  const { t } = useTranslation();

  // failure = {
  //   title: "test",
  //   heading: "test2",
  //   message: "test3",
  //   statusCode: 401,
  // };

  return (
    <div className='order-status'>
      <HeaderContainer pageTitle={t("OrderManagement:DeliveryWith")} />
      {failure ? (
        <ErrorModal
          heading={failure.heading}
          message={failure.message}
          isVisible={true}
        />
      ) : !trackingUrlExpiresAt || !orderStatus ? (
        <LoadingScreen
          loading={true}
          json={loadingText}
          description={t("OrderManagement:DeliveryLoadingText")}
        />
      ) : (
        <OrderDetailsContainer hideHeader={true} orderStatus={orderStatus} />
      )}
    </div>
  );
};

export default OrderDeliveryStatus;
