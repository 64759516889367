import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  AnalyticsConst,
  AnalyticsInstance,
  ApiOrderModel,
  CheckoutModels,
  DashboardReduxAction,
  OrderModules,
  RootState,
} from "gyg_common";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { CollectionType } from "gyg_common/redux_store/order/models";
import { GetOrderResponse } from "gyg_common/services/api/order/model";
import { addRecentOrderItemsToCart } from "modules/OrderCard";
import { Screens } from "navigation/const";
import { OrderDetails } from "views/components/OrderDetails/OrderDetails";
import HeaderContainer from "views/containers/shared/Header/HeaderContainer";

import { OrderSetupContainer } from "../OrderSetup/OrderSetupContainer";
import { RecentOrderAbandonModalContainer } from "../shared/RecentOrderAbandonModal/RecentOrderAbandonModalContainer";

interface IOrderDetailContainer {
  hideHeader?: boolean;
  orderStatus?: GetOrderResponse;
}

export const OrderDetailsContainer: React.FC<IOrderDetailContainer> = ({
  hideHeader,
  orderStatus,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);
  const { paymentResponse: orderDetails } = useSelector(
    (s: RootState) => s.checkout
  );
  const { userConfirmedOrderSetup } = useSelector(
    (state: RootState) => state.order
  );
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const { selectedStore } = useSelector((state: RootState) => state.store);
  const { menuStructure } = useSelector((state: RootState) => state.menu);
  const [isAbanadonModalVisible, setAbandonModalVisible] =
    useState<boolean>(false);
  const [showOrderSetup, setOrderSetup] = useState<boolean>(false);
  const [reorderItems, setReorderItems] = useState<ApiOrderModel.BasketItem[]>(
    []
  );

  /**
   * Trigger function when Reorder is pressed
   */
  const reorderButtonPress = () => {
    const brainTreeTotal = orderDetails?.payment.find(
      (response: CheckoutModels.PaymentCheckoutResponse) =>
        response.type === CheckoutModels.PaymentType.BRAINTREE
    );

    const totalPaid = brainTreeTotal?.amount
      ? brainTreeTotal.amount.toFixed(2)
      : "0.00";
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.Reorder, {
      order_id: orderDetails?.order.orderId,
      order_no: orderDetails?.order.orderNumber,
      total: totalPaid,
      date_string: new Date().toLocaleString(),
      date: Date.now(),
    });

    const items = orderDetails?.order.basket.basketItems ?? [];
    setReorderItems(items);

    if (cartItems.length > 0) {
      setAbandonModalVisible(true);
    } else if (userConfirmedOrderSetup && menuStructure) {
      addRecentOrderItemsToCart(items, menuStructure);
    } else if (selectedStore) {
      dispatch(DashboardReduxAction.setRecentOrderToAddToCart(items));
      setOrderSetup(true);
    } else {
      if (hasDelivery) {
        dispatch(DashboardReduxAction.setRecentOrderToAddToCart(items));
        setOrderSetup(true);
      } else {
        navigate(Screens.Restaurants, {
          state: {
            recentOrdersToAddToCart: items,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!orderDetails) {
      navigate(Screens.Dashboard);
    }
  }, [navigate, orderDetails]);

  return (
    orderDetails && (
      <>
        {hideHeader ? null : (
          <HeaderContainer
            pageTitle={`${t("CheckoutPayment:order")} #${
              orderDetails?.order.orderNumber
            }`}
          />
        )}

        {orderStatus &&
        orderStatus.orderCollectionType === CollectionType.DELIVERY &&
        orderStatus.delivery?.trackingUrl &&
        orderStatus.delivery?.trackingUrlExpiresAt &&
        !OrderModules.OrderUtils.isTrackingUrlExpired(orderStatus) ? (
          <iframe
            id='uber-iframe'
            allow='clipboard-read; clipboard-write'
            name={`${Date.now()}`}
            src={orderStatus.delivery.trackingUrl}></iframe>
        ) : (
          <>
            <OrderSetupContainer
              onConfirmClick={() => {
                if (cartItems.length === 0 && menuStructure) {
                  addRecentOrderItemsToCart(reorderItems, menuStructure);
                }
                setOrderSetup(false);
                navigate(Screens.Order);
              }}
              onCloseModal={() => setOrderSetup(false)}
              isVisible={showOrderSetup}
            />

            <RecentOrderAbandonModalContainer
              reorderItems={reorderItems}
              isAbandonModalVisible={isAbanadonModalVisible}
              setAbandonModalVisible={setAbandonModalVisible}
              setOrderSetup={setOrderSetup}
            />

            <div className='u-main-background u-flex'>
              <div className='main-wrapper main-wrapper__row main-wrapper--skinny'>
                <OrderDetails
                  orderDetails={orderDetails}
                  onReorderPress={reorderButtonPress}
                />
              </div>
            </div>
          </>
        )}
      </>
    )
  );
};

export default OrderDetailsContainer;
