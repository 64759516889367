import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  ImageURISource,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import illustration_error from "gyg_common/components/assets/images/illustration_error.png";
import gygBrandLogo from "gyg_common/components/assets/images/logo_primary_gyg.png";
import { BorderRadius, Spacing } from "gyg_common/components/styles/number";

import * as OrderModules from "../../modules/Order";
import * as PlatformUtils from "../../modules/platformUtils";
import * as OrderReduxModels from "../../redux_store/order/models";
import OrderStatusLabel, { OrderStatusLabelProps } from "../OrderStatusLabel";
import OrderStatusTime from "../OrderStatusTime";
import colours from "../styles/colours";
import { shadowTextstyle } from "../styles/shared";
import { Typography } from "../styles/typography";

const { OrderConstants } = OrderModules;

const maxSizeForOrderNumberContainer = 180;
const minSizeForOrderNumberContainer = 166;

const orderNumberContainerStyle = (width: number) =>
  StyleSheet.create({
    orderStickerContainer: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colours.yellow,
      maxWidth: Platform.OS == "web" ? null : maxSizeForOrderNumberContainer,
      maxHeight: Platform.OS == "web" ? null : maxSizeForOrderNumberContainer,
      minWidth: width,
      minHeight: width,
      borderRadius: maxSizeForOrderNumberContainer,
      marginBottom: Spacing.Light,
      marginTop: -Spacing.ExtraThin,
      ...Platform.select({
        web: {
          bottom: 0,
          height: width,
        },
      }),
    },
  });

const style = StyleSheet.create({
  summaryContainer: {
    alignItems: "center",
    marginBottom: Spacing.Regular,
  },
  orderStickerTitle: {
    ...Typography.titleTwo,
    fontSize: Platform.OS == "web" ? 24 : 20,
  },
  orderStickerNo: {
    fontFamily: "Sini-Bold",
    textTransform: "uppercase",
    color: colours.darkGrey,
    fontSize: Platform.OS == "web" ? 60 : 66,
    lineHeight: Platform.OS == "web" ? 60 : undefined,
    paddingHorizontal: Platform.OS == "web" ? Spacing.Regular : Spacing.Thin,
    textAlign: "center",
    marginTop: Platform.OS == "android" ? -Spacing.Thin : null,
    ...Platform.select({
      web: {
        bottom: 0,
      },
    }),
  },
  info: {
    ...Typography.headline,
    textAlign: "center",
    marginBottom: Spacing.Narrow,
  },
  infoBig: {
    ...Typography.titleOne,
    textAlign: "center",
    marginBottom: Spacing.Light,
  },
  semicolon: {
    alignItems: "center",
    position: "relative",
    marginRight: -Spacing.Thin,
  },
  caption: {
    ...Typography.bodyTwo,
    textAlign: "center",
    fontWeight: "bold",
    paddingLeft: Spacing.Regular,
    paddingRight: Spacing.Regular,
  },
  section: {
    marginTop: Spacing.Thin,
  },
  loadingImage: {
    borderRadius: BorderRadius.Medium,
    width: 144,
    height: 144,
  },
  errorImage: {
    borderRadius: BorderRadius.Medium,
    width: 180,
    height: 144,
    marginBottom: Spacing.Light,
    marginTop: -Spacing.ExtraThin,
  },
});

interface OrderStatusInfoProps {
  orderId: string;
  orderDate: string;
  storeName: string;
  orderType: OrderReduxModels.CollectionType;
  orderStatusLabel: OrderStatusLabelProps;
  tableNumber: string | null;
  timeZone: string;
  isOrderASAP: boolean;
  canShowOrderNumber: boolean;
  isFailed: boolean;
}

const OrderStatusInfo: React.FC<OrderStatusInfoProps> = ({
  orderId,
  orderDate,
  storeName,
  orderType,
  tableNumber,
  orderStatusLabel,
  timeZone,
  isOrderASAP,
  canShowOrderNumber,
  isFailed,
}) => {
  const { t } = useTranslation();

  const [orderNumberContainerWidth, setOrderNumberContainerWidth] = useState(
    Platform.OS == "web"
      ? maxSizeForOrderNumberContainer
      : minSizeForOrderNumberContainer
  );
  const getOrderNumberContainerWidth = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    if (
      width >= minSizeForOrderNumberContainer &&
      width != orderNumberContainerWidth &&
      width <= maxSizeForOrderNumberContainer
    ) {
      setOrderNumberContainerWidth(width);
    }
  };

  return (
    <View
      style={style.summaryContainer}
      {...PlatformUtils.generateTestID(
        Platform.OS,
        OrderConstants.OrderStatusTestingId.orderSummary
      )}>
      {!isFailed && (
        <View
          style={
            orderNumberContainerStyle(orderNumberContainerWidth)
              .orderStickerContainer
          }
          onLayout={getOrderNumberContainerWidth}>
          {canShowOrderNumber && (
            <>
              <Text
                style={[style.orderStickerTitle, shadowTextstyle(2).shadow]}>
                {t("OrderStatus:orderNo")}
              </Text>
              <Text
                style={[style.orderStickerNo, shadowTextstyle(2).shadow]}
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                allowFontScaling={false}>
                {orderId}
              </Text>
            </>
          )}
          {!canShowOrderNumber && (
            <Image
              style={style.loadingImage}
              source={gygBrandLogo as ImageSourcePropType}
              defaultSource={gygBrandLogo as ImageURISource}
            />
          )}
        </View>
      )}
      {isFailed && (
        <Image
          source={illustration_error as ImageSourcePropType}
          style={style.errorImage}
        />
      )}

      <OrderStatusLabel {...orderStatusLabel} />
      {!isFailed && canShowOrderNumber && (
        <View
          style={style.section}
          {...PlatformUtils.generateTestID(
            Platform.OS,
            orderType === OrderReduxModels.CollectionType.TABLE_SERVICE
              ? "tableServiceOrderInfo"
              : "pickupOrderInfo"
          )}>
          <OrderStatusTime
            isOrderASAP={
              orderType === OrderReduxModels.CollectionType.TABLE_SERVICE ||
              isOrderASAP
            }
            tableNumber={tableNumber ?? ""}
            orderDate={orderDate}
            timeZone={timeZone}
            orderCollectionType={orderType}
            tableServiceCopy={t("OrderStatus:readyAtTableService")}
            pickupASAPcopy={t("OrderStatus:readyAt")}
            pickupScheduledCopy={t("OrderStatus:readyAtDays")}
            spacious
          />
          {orderType === OrderReduxModels.CollectionType.TABLE_SERVICE ? (
            <>
              <Text style={style.info}>
                {t("OrderStatus:fromStoreTableServiceCopy")}
              </Text>
              <Text style={style.infoBig}>{storeName}</Text>
            </>
          ) : (
            <>
              <Text style={style.info}>{t("OrderStatus:pickUp")}</Text>
              <Text style={style.infoBig}>{storeName}</Text>
              {orderType === OrderReduxModels.CollectionType.PICK_UP && (
                <Text style={style.caption}>
                  {t("OrderStatus:captionPickUp")}
                </Text>
              )}
              {orderType === OrderReduxModels.CollectionType.DRIVE_THRU && (
                <Text style={style.caption}>
                  {t("OrderStatus:captionDriveThru")}
                </Text>
              )}
            </>
          )}
        </View>
      )}
      {isFailed && (
        <View style={style.section}>
          <Text style={style.infoBig}>{t("OrderStatus:failedTitle")}</Text>
          <Text style={style.caption}>{t("OrderStatus:failedBody")}</Text>
        </View>
      )}
    </View>
  );
};

export default OrderStatusInfo;
