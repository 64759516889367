import { StyleSheet } from "react-native";

import { Spacing } from "gyg_common/components/styles/number";
import colours from "styles/colours";
import { Typography } from "styles/typography";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.white,
    position: "relative",
    height: "100%",
  },
  stepWrapper: {
    flexDirection: "row",
    marginBottom: Spacing.Thin,
  },
  stepNumber: {
    ...Typography.bodyBold,
    marginRight: Spacing.Thin,
  },
  titleText: {
    ...Typography.titleOne,
    marginBottom: Spacing.Light,
  },
  contentWrapper: {
    padding: Spacing.Light,
  },
  topContentWrapper: {
    borderBottomWidth: 1,
    borderColor: colours.borderGrey,
    paddingHorizontal: Spacing.Light,
    paddingTop: Spacing.Light,
    paddingBottom: Spacing.Thin,
  },
  bottomContentWrapper: {
    padding: Spacing.Light,
  },
  descriptionText: {
    ...Typography.body,
    marginBottom: Spacing.Light,
  },
  listItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: Spacing.Thin,
    paddingRight: Spacing.Thin,
  },
  backButtonWrapper: {
    position: "absolute",
    top: Spacing.Light,
    right: Spacing.Light,
    zIndex: 2,
  },
  bannerImage: {
    width: "100%",
    height: 224,
  },
  chevron: {
    marginRight: Spacing.Thin,
    marginLeft: -6,
  },
  termsLink: {
    ...Typography.linkText,
    marginTop: Spacing.Thin,
  },
  yellowBackground: {
    marginTop: "auto",
    backgroundColor: colours.yellow,
  },
  scrollWrapper: { flex: 1 },
});
