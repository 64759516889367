import branch from "branch-sdk";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import config from "config";
import {
  BranchReduxActions,
  NavigationReduxAction,
  OrderModules,
  RootState,
} from "gyg_common";
import { Screens } from "navigation/const";
import { clearAndNavigateToOrderStatusScreen } from "navigation/utils";
import { OrderSetupContainer } from "views/containers/OrderSetup/OrderSetupContainer";

import { handleBranchDeeplink } from "../../../modules/branch";

const BranchAndModalsContainer: React.FC = () => {
  const { getOrderByIdSuccess } = useSelector(
    (state: RootState) => state.order
  );
  const { paymentResponse } = useSelector((state: RootState) => state.checkout);
  const branchState = useSelector((s: RootState) => s.branch);
  const { showOrderSetup, orderSetupNavigation: setupNavigation } = useSelector(
    (state: RootState) => state.navigation
  );

  const [showSetupOrder, setSetupOrder] = useState(false);
  const [orderSetupNavigation, setOrderSetupNavigation] = useState<string>(
    Screens.Menu
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCloseModal = useCallback(() => {
    setSetupOrder(false);
  }, []);

  const onConfirmClick = useCallback(() => {
    navigate(orderSetupNavigation);
    setSetupOrder(false);
  }, [navigate, orderSetupNavigation]);

  useEffect(() => {
    branch.init(
      config.branchKey,
      undefined,
      handleBranchDeeplink(navigate, dispatch)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  //Branch actions
  //
  useEffect(() => {
    if (
      getOrderByIdSuccess &&
      paymentResponse &&
      !!branchState.requestedOrderData
    ) {
      if (
        OrderModules.OrderUtils.isOlderThanActiveOrderThreshold(paymentResponse)
      ) {
        navigate(Screens.OrderDetails);
      } else {
        clearAndNavigateToOrderStatusScreen(navigate, dispatch);
      }
      dispatch(BranchReduxActions.clearRequestedOrderData());
    }
  }, [
    dispatch,
    getOrderByIdSuccess,
    navigate,
    paymentResponse,
    branchState.requestedOrderData,
  ]);

  //
  // Change OrderSetup
  //
  useEffect(() => {
    if (showOrderSetup) {
      setSetupOrder(true);
    }

    return () => {
      dispatch(NavigationReduxAction.setOpenOrderSetup(false));
    };
  }, [showOrderSetup, dispatch]);

  useEffect(() => {
    setOrderSetupNavigation(setupNavigation);
  }, [setupNavigation]);

  return (
    <>
      <OrderSetupContainer
        onConfirmClick={onConfirmClick}
        onCloseModal={onCloseModal}
        isVisible={showSetupOrder}
      />
    </>
  );
};
export default BranchAndModalsContainer;
