import React, { memo } from "react";
import { StyleProp, ViewStyle } from "react-native";

import { MenuModels, MenuModuleUtils, ProductConst, ProductUtils } from "../..";
import MenuListItemTile from "./MenuListItemTile";

export interface MenuListItemExtraProps extends MenuModels.MenuListItemProps {
  style?: StyleProp<ViewStyle>;
  smallCard?: boolean;
  bigCard?: boolean;
  onPress?: () => void;
}

const MenuListItem = (props: MenuListItemExtraProps): JSX.Element => {
  const { category, product, multiPart, bigCard, smallCard, onPress } = props;

  if (category) {
    const categoryPrice = MenuModuleUtils.getTheLowestPriceInCategories([
      category,
    ]);
    return (
      <MenuListItemTile
        item={category}
        price={categoryPrice}
        onPress={onPress}
        bigCard={bigCard}
        smallCard={smallCard}
      />
    );
  } else if (multiPart) {
    const multiPartPrice = multiPart.price?.toFixed(2);
    return (
      <MenuListItemTile
        item={multiPart}
        price={multiPartPrice}
        onPress={onPress}
        bigCard={
          ProductUtils.getProductRoute(multiPart.templateId as string) ===
            ProductConst.ProductRoute.Bundles || bigCard
        }
        smallCard={smallCard}
      />
    );
  } else if (product) {
    const productPrice = product.price?.toFixed(2);
    const productSizeAndTitle = MenuModuleUtils.getProductSizeAndTitle(product);
    return (
      <MenuListItemTile
        item={product}
        price={productPrice}
        size={productSizeAndTitle.size}
        substitutionalTitle={productSizeAndTitle.title}
        nutritionalInfo={product.nutritionalInfo}
        onPress={onPress}
        bigCard={bigCard}
        smallCard={smallCard}
      />
    );
  } else {
    return <></>;
  }
};

export default memo(MenuListItem);
