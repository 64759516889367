export const calculateGomexPercentageBar = (
  loyaltyMaxPoints: number,
  progressBarWidth: number,
  loyaltyPoints?: number
): number => {
  if (
    loyaltyPoints &&
    loyaltyPoints > 0 &&
    progressBarWidth &&
    loyaltyMaxPoints
  ) {
    const points = loyaltyPoints;
    return (points / loyaltyMaxPoints) * progressBarWidth;
  } else {
    return 0;
  }
};
