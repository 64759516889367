import { useCallback, useState } from "react";

import {
  CustomizableOptionState,
  CustomizeOptionsDataProps,
  MealCustomizableOptionState,
} from "../modules/Products/model";
import {
  validateCustomisedTacoSelection,
  validateCustomSelection,
  validateMiamSelection,
  validateTacoMultipartSelection,
} from "../modules/Products/utils";
import { BundleState } from "../redux_store/cart/models";
import { Category, InternalTagType } from "../redux_store/menu/models";

interface ErrorsState {
  [key: string]: boolean;
}

interface ConfigureErrorParams {
  firstErrorIndex: number | null;
  newErrors: ErrorsState;
  skipErrorConfig: boolean;
}

interface ValidateTacoOrderParams {
  category: Category;
  customizeOptionsData: CustomizeOptionsDataProps[];
  selectedMultiPart: (string | number)[];
  selectedMealId: number | undefined;
  tacoItem: BundleState;
  requiredTacoItemLength: number;
  mealCustomizeOptions: MealCustomizableOptionState;
  skipErrorConfig?: boolean;
}

interface ValidateCustomisableOrderParams {
  customisableSectionData: CustomizeOptionsDataProps[];
  selectedMealId: number | undefined;
  customizeOptions: CustomizableOptionState;
  mealCustomizeOptions: MealCustomizableOptionState;
  skipErrorConfig?: boolean;
}

const useProductValidationError = () => {
  const [errorIndex, setErrorIndex] = useState<number | null>(null);
  const [requiredFieldsError, setRequiredFieldsError] = useState<ErrorsState>(
    {}
  );

  const clearErrorIndex = useCallback(() => {
    setErrorIndex(null);
  }, []);

  const configureError = useCallback(
    ({
      firstErrorIndex,
      newErrors,
      skipErrorConfig,
    }: ConfigureErrorParams): boolean => {
      const hasError = Object.values(newErrors).some((error) => error);

      if (!skipErrorConfig) {
        setRequiredFieldsError(newErrors);

        if (firstErrorIndex !== null) {
          setErrorIndex(firstErrorIndex);
        }
      }

      // returns true if validation doesn't have error, else false.
      return !hasError;
    },
    []
  );

  return {
    errorIndex,
    clearErrorIndex,
    requiredFieldsError,
    configureError,
  };
};

export const useValidateCustomisableProduct = () => {
  const { errorIndex, clearErrorIndex, requiredFieldsError, configureError } =
    useProductValidationError();

  const validateOrder = useCallback(
    ({
      customisableSectionData,
      selectedMealId,
      customizeOptions,
      mealCustomizeOptions,
      skipErrorConfig,
    }: ValidateCustomisableOrderParams) => {
      const newErrors: ErrorsState = {};
      const errorIndices: number[] = [];

      customisableSectionData.forEach((customisableSection, index) => {
        let isValid: boolean;
        const isRequired = customisableSection.isRequired;

        // Validates MIAM/Item only selection
        switch (customisableSection.id) {
          case InternalTagType.MIAM: {
            isValid = validateMiamSelection({
              selectedMealId,
              isRequired,
            });

            break;
          }

          // Validates Drinks & Sides if MIAM is selected.
          default: {
            isValid = validateCustomSelection({
              id: customisableSection.id,
              mealCustomizeOptions,
              customizeOptions,
              isRequired: customisableSection.isRequired,
            });

            break;
          }
        }

        if (!isValid) {
          newErrors[customisableSection.id] = !isValid;
          errorIndices.push(index);
        }
      });

      return configureError({
        firstErrorIndex: errorIndices[0],
        newErrors,
        skipErrorConfig: skipErrorConfig || false,
      });
    },
    [configureError]
  );

  return { errorIndex, clearErrorIndex, requiredFieldsError, validateOrder };
};

export const useValidateTacoProduct = () => {
  const { errorIndex, clearErrorIndex, requiredFieldsError, configureError } =
    useProductValidationError();

  const validateOrder = useCallback(
    ({
      category,
      customizeOptionsData,
      selectedMultiPart,
      selectedMealId,
      tacoItem,
      requiredTacoItemLength,
      mealCustomizeOptions,
      skipErrorConfig,
    }: ValidateTacoOrderParams) => {
      const newErrors: ErrorsState = {};
      const errorIndices: number[] = [];

      const multipart = category?.multiPart?.find(
        (n) => n.id === selectedMultiPart[0]
      );

      customizeOptionsData.forEach((customisableSection, index) => {
        let isValid: boolean;
        const isRequired = customisableSection.isRequired;

        switch (customisableSection.id) {
          // Validates MIAM/Item only selection
          case InternalTagType.MIAM: {
            isValid = validateMiamSelection({
              selectedMealId,
              isRequired,
            });
            break;
          }

          // Validates # of Taco selection.
          case category.name: {
            isValid = validateTacoMultipartSelection({
              selectedMultiPart,
              isRequired,
            });

            break;
          }

          // Validates # of customized Tacos.
          case multipart?.name: {
            isValid = validateCustomisedTacoSelection({
              tacoItem,
              requiredTacoItemLength,
              isRequired,
            });

            break;
          }

          // Validates Drinks & Sides if MIAM is selected.
          default: {
            isValid = validateCustomSelection({
              id: customisableSection.id,
              mealCustomizeOptions,
              customizeOptions: {},
              isRequired: customisableSection.isRequired,
            });

            break;
          }
        }

        if (!isValid) {
          newErrors[customisableSection.id] = !isValid;
          errorIndices.push(index);
        }
      });

      return configureError({
        firstErrorIndex: errorIndices.length > 0 ? errorIndices[0] : null,
        newErrors,
        skipErrorConfig: skipErrorConfig || false,
      });
    },
    [configureError]
  );

  return { errorIndex, clearErrorIndex, requiredFieldsError, validateOrder };
};
