import React, { useEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";

import { useLottieWeb } from "gyg_common/hooks";

import coinAnimation from "../assets/gif/coinAnimation.json";
import { Spacing } from "../styles/number";

export const styles = StyleSheet.create({
  lottieWrapper: {
    paddingRight: Spacing.ExtraThin,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  loader: {
    height: 24,
    width: 24,
  },
});

export const CoinAnimation: React.FC = () => {
  const lottieRef = useRef<HTMLDivElement>(null);
  const { lottie } = useLottieWeb();
  const animationName = "coinAnimation";

  useEffect(() => {
    if (!lottie) {
      return;
    }

    lottie.destroy(animationName);
    lottie.loadAnimation({
      name: animationName,
      container: lottieRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: coinAnimation,
    });
  }, [lottie]);

  return (
    <View style={styles.lottieWrapper}>
      <div data-testid={animationName} style={styles.loader} ref={lottieRef} />
    </View>
  );
};

export default CoinAnimation;
