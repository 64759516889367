import React from "react";
import { Modal } from "react-native";

import { CartReduxModels, MenuModels, ProductConst } from "gyg_common";
import ProductTemplateSelector from "views/components/Products/ProductTemplateSelector";

export interface ProductModalProps {
  isVisible: boolean;
  productType: ProductConst.ProductRoute;
  category:
    | MenuModels.Category
    | undefined
    | MenuModels.Product
    | MenuModels.MultiPart;
  editMode: ProductConst.EditMode;
  commonSection?: MenuModels.CommonSection;
  cartItem?: CartReduxModels.CartItem;
  index?: number;
  isUpsell?: boolean;
  enableGoBackMode?: boolean;
  onClose: () => void;
}

export default function ProductModal(props: ProductModalProps): JSX.Element {
  const {
    category,
    onClose,
    cartItem,
    productType,
    isVisible,
    editMode,
    commonSection,
    index,
    isUpsell,
    enableGoBackMode,
  } = props;

  return (
    <>
      {category && (
        <Modal transparent={true} visible={isVisible}>
          <div className={"overlay"} id='product-modal-overlay'>
            <div
              id={"product-modal-wrapper"}
              className={"product-modal-wrapper"}>
              <ProductTemplateSelector
                commonSection={commonSection}
                onBack={onClose}
                editMode={editMode}
                category={category}
                cartItem={cartItem}
                onClose={onClose}
                productType={productType}
                index={index}
                isUpsell={isUpsell}
                enableGoBackMode={enableGoBackMode}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
