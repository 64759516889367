import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  LayoutAnimation,
  Platform,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  UIManager,
  View,
} from "react-native";
import { useDispatch } from "react-redux";

import {
  CartReduxModels,
  FavouriteModels,
  FavouriteModules,
  FavouriteReduxAction,
  ProductUtils,
} from "../..";
import * as PlatformUtils from "../../modules/platformUtils";
import editIcon from "../assets/icons/icon_edit.png";
import heartImg from "../assets/icons/icon_heart.png";
import heartFilledImg from "../assets/icons/icon_heart_filled.png";
import renameIcon from "../assets/icons/icon_rename.png";
import ModalWithInput from "../modals/ModalWithInput";
import { CartItemBottomBanner } from "../Order/CartItemBottomBanner";
import ProductQuantitySelector, {
  ProductQuantitySelectorTheme,
} from "../Products/ProductQuantitySelector";
import { Typography } from "../styles/typography";
import {
  containerStyles,
  editIconStyles,
  iconStyles,
  styles,
} from "./CartItem.styles";
import CustomisationList from "./CustomisationList";

if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export interface CartItemProps {
  isFavourite: boolean;
  favouriteData?: FavouriteModels.UpdateFavouriteResponse;
  expandable: boolean;
  cartItem: CartReduxModels.CartItem;
  isCartItemDeletionInProgress: boolean;
  isLoggedInUser: boolean;
  isFavouriteUpdateError: boolean;
  index: number;
  updateCartItemQuantity: (cartItemIndex: number, quantity: number) => void;
  deleteItem: (cartItemIndex: number) => void;
  onCreateORUpdateFavourite: (
    payload: FavouriteModels.AddFavouriteRequest | number,
    type: string
  ) => void;
  onRenamePress: (
    index: number,
    payload: FavouriteModels.UpdateFavouriteRequest
  ) => void;
  onItemEdit: (item: CartReduxModels.CartItem, index: number) => void;
  getOrderLoading: boolean;
}

export const CartItem: React.FC<CartItemProps> = ({
  expandable,
  cartItem,
  isCartItemDeletionInProgress,
  isLoggedInUser,
  isFavouriteUpdateError,
  isFavourite,
  favouriteData,
  index,
  updateCartItemQuantity,
  deleteItem,
  onCreateORUpdateFavourite,
  onItemEdit,
  getOrderLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isFavouriteItem, setFavouriteItem] = useState<boolean>(isFavourite);

  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [renameError, setRenameError] = useState<string>("");
  const [selectedFavouritelabel, setSelectedFavouriteLabel] =
    useState<string>("");
  const [expand, setExpand] = useState(false);
  const isMiam = cartItem.miamItem != null;
  const isMerchandise =
    cartItem.isOfferProduct && cartItem.category === "Merchandise";

  useEffect(() => {
    if (isFavouriteUpdateError) {
      setFavouriteItem(false);
    }
  }, [isFavouriteUpdateError]);

  const setYourFavourite = useCallback(
    (type: string) => {
      setFavouriteItem(!isFavouriteItem);
      if (favouriteData && isFavouriteItem) {
        onCreateORUpdateFavourite(favouriteData.favouriteId, type);
      } else {
        onCreateORUpdateFavourite(
          FavouriteModules.addFavouritePayload(cartItem),
          type
        );
      }
    },
    [cartItem, favouriteData, isFavouriteItem, onCreateORUpdateFavourite]
  );

  const setQuantity = useCallback(
    (quantity: number) => {
      updateCartItemQuantity(index, quantity);
    },
    [index, updateCartItemQuantity]
  );

  const deleteCartItem = useCallback(() => {
    deleteItem(index);
  }, [deleteItem, index]);

  const showRenameFavModal = useCallback(() => {
    setSelectedFavouriteLabel(favouriteData.label);
    setRenameError("");
    setShowRenameModal(true);
  }, [favouriteData]);

  const onRenameSavePress = useCallback(() => {
    if (favouriteData && selectedFavouritelabel.trim() !== "") {
      favouriteData.label = selectedFavouritelabel;

      dispatch(
        FavouriteReduxAction.renameFavourite(
          FavouriteModules.updateFavouritePayload(
            favouriteData.favouriteId,
            favouriteData.label,
            cartItem
          )
        )
      );
      setShowRenameModal(false);
    } else {
      setRenameError(t("Favourite:nameError"));
    }
  }, [cartItem, dispatch, favouriteData, selectedFavouritelabel, t]);

  useEffect(() => {
    setFavouriteItem(isFavourite);
  }, [isFavourite]);

  const price = useMemo(() => {
    const total = cartItem.price + (cartItem.miamItem?.price ?? 0);
    return total.toFixed(2);
  }, [cartItem.miamItem?.price, cartItem.price]);

  const originalPrice = useMemo(() => {
    const total = cartItem.originalPrice + (cartItem.miamItem?.price ?? 0);
    return cartItem.originalPrice && cartItem.displayText?.length
      ? total.toFixed(2)
      : null;
  }, [
    cartItem.displayText?.length,
    cartItem.miamItem?.price,
    cartItem.originalPrice,
  ]);

  const onExpandPress = () => {
    LayoutAnimation.configureNext({
      duration: 100,
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
    setExpand(!expand);
  };

  const onFavouriteLabelChange = (text: string) => {
    setSelectedFavouriteLabel(text);
  };

  const onCloseModal = () => setShowRenameModal(false);

  const onEditItem = () => onItemEdit(cartItem, index);

  const onDeleteFavourite = () =>
    setYourFavourite(FavouriteModels.CRUDType.delete);

  const onAddFavourite = () => setYourFavourite(FavouriteModels.CRUDType.add);

  const isBottomBordersRounded = () => {
    if (
      ProductUtils.associatedOffersExist(cartItem) &&
      ProductUtils.discountsExist(cartItem)
    ) {
      return false;
    }
    if (cartItem.displayText?.length) {
      return false;
    }

    return true;
  };

  return (
    <>
      <ModalWithInput
        isVisible={showRenameModal}
        primaryButton={{
          name: t("Favourite:saveButton"),
          action: onRenameSavePress,
        }}
        title={t("Favourite:renameTitle")}
        message={t("Favourite:renameBody")}
        inputLabel={t("Favourite:renameLabel")}
        inputValue={selectedFavouritelabel}
        onTextChange={onFavouriteLabelChange}
        onModalClose={onCloseModal}
        isInputError={!!renameError.length}
        inputErrorMessage={renameError}
      />

      <View
        {...PlatformUtils.generateTestID(Platform.OS, "CartItemContainer")}
        key={cartItem.productId}>
        <View style={containerStyles(isBottomBordersRounded()).container}>
          <View style={styles.subContainer}>
            <View style={styles.title}>
              <Text style={Typography.titleTwo}>
                {isFavourite
                  ? favouriteData.label
                  : ProductUtils.mainProductName(isMiam, cartItem)}
              </Text>
            </View>

            <View style={styles.iconsContainer}>
              {cartItem.allowModify && (
                <TouchableOpacity
                  disabled={getOrderLoading}
                  onPress={onEditItem}
                  style={styles.favouriteButton}>
                  <Image
                    source={editIcon as ImageSourcePropType}
                    style={
                      getOrderLoading
                        ? iconStyles(getOrderLoading).cartIcon
                        : editIconStyles(
                            ProductUtils.containsInvalidModifier(cartItem)
                          ).editIcon
                    }
                  />
                </TouchableOpacity>
              )}
              {isLoggedInUser && !isMerchandise && (
                <>
                  {isFavouriteItem ? (
                    <View style={styles.favouriteButtonContainer}>
                      <TouchableOpacity
                        disabled={getOrderLoading}
                        onPress={showRenameFavModal}
                        style={styles.favouriteButton}>
                        <Image
                          source={renameIcon as ImageSourcePropType}
                          style={iconStyles(getOrderLoading).cartIcon}
                        />
                      </TouchableOpacity>
                      <TouchableOpacity
                        disabled={getOrderLoading}
                        onPress={onDeleteFavourite}
                        style={styles.favouriteButton}>
                        <Image
                          source={heartFilledImg as ImageSourcePropType}
                          style={iconStyles(getOrderLoading).cartIcon}
                        />
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <TouchableOpacity
                      disabled={getOrderLoading}
                      onPress={onAddFavourite}
                      style={styles.favouriteButton}>
                      <Image
                        source={heartImg as ImageSourcePropType}
                        style={iconStyles(getOrderLoading).cartIcon}
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
            </View>
          </View>

          <CustomisationList
            cartItem={cartItem}
            expand={expand || isMiam}
            expandable={isMiam} //displays all customizations collapsed with arrow icon near title used only when there is MIAM
            containsInvalidModifier={ProductUtils.containsInvalidModifier(
              cartItem
            )}
          />

          {expandable && !isMiam ? (
            <View style={styles.expandButton}>
              <TouchableWithoutFeedback onPress={onExpandPress}>
                <View style={styles.row}>
                  <Text style={Typography.titleThree}>
                    {expand ? "Less" : "More"}
                  </Text>
                  <Image
                    source={
                      (expand
                        ? require("..//assets/icons/icon_chevron_up_small.png")
                        : require("..//assets/icons/icon_chevron_down_small.png")) as ImageSourcePropType
                    }
                    style={styles.icon}
                  />
                </View>
              </TouchableWithoutFeedback>
            </View>
          ) : (
            !isMiam && <View style={styles.expandButton} />
          )}

          {cartItem.miamItem && (
            <View style={styles.miam}>
              <CustomisationList
                cartItem={cartItem.miamItem}
                expand
                expandable //displays all customizations collapsed with arrow icon near title
              />
            </View>
          )}

          <View style={styles.bottom}>
            <View style={styles.split} />
            <View style={styles.row}>
              {cartItem?.isValid !== false && (
                <View style={styles.priceView}>
                  {originalPrice && (
                    <Text
                      style={Typography.strikeOut}>{`$${originalPrice}`}</Text>
                  )}
                  <Text style={Typography.titleTwo}>{`$${price}`}</Text>
                </View>
              )}
              <View style={styles.quantityView}>
                <ProductQuantitySelector
                  getOrderLoading={getOrderLoading}
                  quantity={cartItem.quantity}
                  setQuantity={setQuantity}
                  deleteItem={deleteCartItem}
                  theme={ProductQuantitySelectorTheme.cart}
                  isCartItemDeletionInProgress={isCartItemDeletionInProgress}
                  isError={cartItem?.isValid === false}
                />
              </View>
              <View style={styles.split} />
              <Image
                style={styles.logo}
                source={
                  // eslint-disable-next-line @typescript-eslint/no-var-requires
                  require("..//assets/images/gyg.png") as ImageSourcePropType
                }
              />
            </View>
          </View>
        </View>
        <CartItemBottomBanner cartItem={cartItem} />
      </View>
    </>
  );
};
