import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  LoyaltyActions,
  LoyaltyModels,
  RootState,
  StoreReduxModels,
} from "gyg_common";
import { Screens } from "navigation/const";
import { BarcodeModal } from "views/components/Barcode";
import { MyGomex } from "views/components/Gomex";
import { ReferToFriendModal } from "views/components/ReferToFriend/ReferToFriendModal";

const MyGomexContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isAuthSuccess } = useSelector(({ login }: RootState) => login);
  const {
    loyalty,
    rewards,
    rewardsLoading,
    coffeeLoyalty,
    coffeeLoyaltyLoading,
  } = useSelector((state: RootState) => state.loyalty);
  const { locationPermissionGranted, profile, isBrazeInitialised } =
    useSelector((state: RootState) => state.user);
  const { stores } = useSelector((s: RootState) => s.store);
  const { mobileAppConfig } = useSelector(
    (state: RootState) => state.dashboard
  );

  const [isBarcodeVisible, setIsBarcodeVisible] = useState<boolean>(false);

  const [showReferToFriendModal, setShowReferToFriendModal] =
    useState<boolean>(false);

  const openTermsAndConditions = () => {
    if (mobileAppConfig?.referAFriend.termsConditionsUrl) {
      window.open(mobileAppConfig?.referAFriend.termsConditionsUrl, "_blank");
    }
  };

  const reward: LoyaltyModels.IRewards = {
    name: t("Loyalty:referTitle"),
    title: t("Loyalty:referTitle"),
    image: mobileAppConfig?.referAFriend.imageUrl,
    link: "",
    description: "",
    priceDescription: mobileAppConfig?.referAFriend.rewardDescription,
    expiryDate: mobileAppConfig?.referAFriend.expiryDate,
    offerDescription: mobileAppConfig?.referAFriend.description,
    intraTitle: mobileAppConfig?.referAFriend.stepsTitle,
    stepText: mobileAppConfig?.referAFriend.steps,
    masterCouponCode: "999999",
    userCouponCode: "666666",
    terms: {
      description: mobileAppConfig?.referAFriend.termsConditionsTitle,
      url: mobileAppConfig?.referAFriend.termsConditionsUrl,
    },
    id: "",
  };

  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "my_gomex",
        page_type: "my_gomex_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    if (isAuthSuccess) {
      dispatch(LoyaltyActions.getUserRewards());
    } else {
      navigate(Screens.Dashboard);
    }
  }, [isAuthSuccess, dispatch, navigate]);

  return (
    <>
      <BarcodeModal
        isVisible={isBarcodeVisible}
        cardNumber={profile?.cardNumber?.toString() ?? "0"}
        firstName={profile?.firstName ?? ""}
        lastName={profile?.lastName ?? ""}
        gygPoints={loyalty?.actualPointsBalance ?? 0}
        gygDollars={loyalty?.actualDollarsBalance ?? 0}
        onCloseClick={() => setIsBarcodeVisible(false)}
      />

      <ReferToFriendModal
        reward={reward}
        mainButtonName={mobileAppConfig?.referAFriend.shareButtonTitle}
        referToFriendUrl={profile?.referralLink ?? ""}
        onClose={() => setShowReferToFriendModal(false)}
        visible={showReferToFriendModal}
        openTermsAndConditions={() => openTermsAndConditions()}
      />

      <MyGomex
        loading={rewardsLoading || coffeeLoyaltyLoading}
        rewards={rewards}
        userProfile={profile}
        loyalty={loyalty}
        coffeeLoyalty={coffeeLoyalty}
        goToAboutGomex={() =>
          window.open(config.brandSiteUrl, "_blank", "noopener,noreferrer")
        }
        goToBarcode={() => {
          let nearestStore: StoreReduxModels.Store | null = null;

          if (locationPermissionGranted && stores && stores.length > 0) {
            nearestStore = stores[0];
          }
          AnalyticsInstance.trackEvent(
            AnalyticsConst.Events.BarcodeScan,
            AnalyticsPayloadGenerator.barcodePayload(
              AnalyticsConst.BarcodeSource.MyGomex,
              nearestStore
            )
          );

          setIsBarcodeVisible(true);
        }}
        goToReferFriend={() => setShowReferToFriendModal(true)}
      />
    </>
  );
};

export default MyGomexContainer;
