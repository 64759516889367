import { useFeatureIsOn } from "@growthbook/growthbook-react";
import debounce from "lodash.debounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "redux_store/configureReduxStore";

import config from "config";
import {
  AnalyticsAction,
  AnalyticsConst,
  AnalyticsInstance,
  AnalyticsPayloadGenerator,
  CartItemsCalculator,
  CartReduxAction,
  CartReduxModels,
  CheckoutAction,
  FavouriteModels,
  FavouriteModules,
  FavouriteReduxAction,
  LoyaltyActions,
  MenuModels,
  MenuModuleUtils,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  ProductConst,
  ProductUtils,
  RootState,
  StoreModules,
} from "gyg_common";
import questionImage from "gyg_common/components/assets/images/illustration_error_question.png";
import { WebModalSize } from "gyg_common/components/modals/MiddleModal";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";
import ModalWithInput from "gyg_common/components/modals/ModalWithInput";
import { useUpsellTracking } from "gyg_common/hooks/useUpsellTracking";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { removeOrderIsInProgressValueFromLocalStorage } from "gyg_common/services/api/checkout";
import { SelectedCartItemToEdit } from "modules/utils";
import { Screens } from "navigation/const";
import Order from "views/components/Order";
import ProductModal from "views/components/Products/ProductModal";
import AlertMessage from "views/components/shared/AlertMessage/AlertMessage";

const UPDATE_CART_DEBOUNCE_DELAY = 750;

const OrderContainer: React.FC = () => {
  // hooks
  useUpsellTracking();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // redux state
  const analyticsState = useSelector((s: RootState) => s.analytics);
  const { items } = useSelector((s: RootState) => s.cart);
  const { menuStructure, menuSectionEnabled } = useSelector(
    (s: RootState) => s.menu
  );
  const {
    orderCollectionType,
    userConfirmedOrderSetup,
    includeCutlery,
    getOrderLoading,
    getOrderResponse,
    getOrderError,
    updateOrderLoading,
    currentOrderId,
    delivery,
  } = useSelector((s: RootState) => s.order);
  const enableCutlery: boolean =
    useSelector(
      (s: RootState) => s.dashboard.mobileAppConfig?.enableRequestCutlery
    ) || false;
  const { selectedStore, stores } = useSelector((s: RootState) => s.store);
  const {
    favouriteCartItems,
    isCRUDFavouriteError,
    crudFavouriteErrorMessage,
    favourites,
    isUpdateLoading: isFavouriteUpdateLoading,
  } = useSelector((s: RootState) => s.favourite);
  const { isAuthSuccess, currentUser } = useSelector((s: RootState) => s.login);
  const driveThruOptionAvailable: boolean =
    StoreModules.StoreUtils.checkIfStoreHasTag(
      StoreModules.StoreModels.StoreTagLabel.DRIVE_THRU,
      selectedStore?.tags
    );

  const { isSavePayment, error: checkoutError } = useSelector(
    (s: RootState) => s.checkout
  );
  const { activeReward, loyalty, coffeeLoyalty } = useSelector(
    (state: RootState) => state.loyalty
  );
  const { guestLoyalty } = useSelector((s: RootState) => s.guest);

  const hasCoffee = useFeatureIsOn(FeatureFlags.COFFEE_LOYALTY as string);

  // variables
  const cartItems = items;
  // const basket = getOrderResponse?.basket;
  const newPointsAndDollars = useCallback(
    () =>
      isAuthSuccess
        ? {
            points: getOrderResponse?.loyalty?.afterCheckoutPointsBalance || 0,
            dollars:
              getOrderResponse?.loyalty?.afterCheckoutDollarsBalance || 0,
          }
        : {
            points: guestLoyalty?.accruedPoints || 0,
            dollars: guestLoyalty?.convertedDollars || 0,
          },
    [getOrderResponse, guestLoyalty, isAuthSuccess]
  );

  const orderReceiptInfo =
    OrderModules.OrderUtils.formatOrderResponse(getOrderResponse);

  // local state
  const [saveOrderModal, setSaveOrderModal] = useState<boolean>(false);
  const [cartItemIdToDelete, setCartItemIdToDelete] = useState<null | number>(
    null
  );
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [updateFavouritePayload, setUpdateFavouritePayload] =
    useState<FavouriteModels.UpdateFavouriteRequest>({
      label: "",
      basketItems: [{ id: 0, name: "" }],
      favouriteId: 0,
    });
  const [indexToUpdateFavourite, setIndexToUpdateFavourite] = useState<{
    index: number;
    label: string;
  }>();

  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedCartItem, setSelectedCartItem] =
    useState<SelectedCartItemToEdit>();
  const [hasInvalidProducts, setHasInvalidProducts] = useState<boolean>(false);

  const firstUpdate = useRef<boolean>(true);
  const isCartItemDeletionInProgress = useRef<boolean>(false);
  const prevCartItemsLength = useRef<number>(cartItems.length);

  const orderError = useMemo(() => {
    if (checkoutError) {
      return checkoutError;
    } else if (
      !OrderModules.OrderUtils.checkIfOrderNotFoundError(getOrderError) &&
      getOrderError
    ) {
      return getOrderError;
    } else {
      return undefined;
    }
  }, [checkoutError, getOrderError]);

  const futureLoyalty = useCallback(
    () =>
      getOrderResponse?.loyalty
        ? {
            ...getOrderResponse.loyalty,
            actualDollarsBalance: newPointsAndDollars().dollars,
            actualPointsBalance: newPointsAndDollars().points,
          }
        : null,
    [getOrderResponse, newPointsAndDollars]
  );

  const futureCoffeeLoyalty = useCallback(
    () =>
      coffeeLoyalty
        ? {
            ...coffeeLoyalty,
          }
        : null,
    [coffeeLoyalty]
  );

  const deleteCartItem = (cartItemIndex: number) => {
    setCartItemIdToDelete(null);
    batch(() => {
      dispatch(CartReduxAction.deleteCartItem(cartItemIndex));
      //dispatch action is async, so has to check cart item length before deletion
      if (cartItems.length === 1) {
        batch(() => {
          dispatch(OrderReduxAction.setOrderResponse(null));
          dispatch(OrderReduxAction.clearOrderError());
          dispatch(CartReduxAction.clearCart());
          dispatch(CheckoutAction.resetPayment());
        });
      }
      dispatch(OrderReduxAction.setShouldUpdateOrder(true));
    });
  };

  const onRedeemDollars = useCallback(
    (value: number | null) => {
      if (!firstUpdate.current) {
        dispatch(LoyaltyActions.updateRedeemDollars(value));
        dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      }
    },
    [dispatch]
  );

  const onChangePickupType = (pickupType: OrderReduxModels.CollectionType) => {
    dispatch(
      OrderReduxAction.setupOrderCollectionType({
        orderCollectionType: pickupType,
      })
    );
    dispatch(OrderReduxAction.setShouldUpdateOrder(true));
  };

  const onCutleryChange = (cutlery: boolean) => {
    dispatch(OrderReduxAction.setIncludeCutlery(cutlery));
  };

  const updateCartItemState = (
    cardItems: CartReduxModels.CartItem[],
    cartItemIndex: number,
    quantity: number
  ) => {
    const cartItemToUpdate = cardItems[cartItemIndex];
    if (cartItemToUpdate) {
      const updatedCartItem =
        CartItemsCalculator.getPriceForNewCartItemQuantity(
          cartItemToUpdate,
          quantity
        );
      batch(() => {
        dispatch(
          CartReduxAction.updateCartItem({
            cartItem: updatedCartItem,
            index: cartItemIndex,
          })
        );
        dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      });
    }
  };

  // debounce cart item state updates
  // only if quantity is above 0
  // quantity 0 is handled by a confirmation popup
  // to delete cart item
  const debouncedUpdateCartItemQuantity = useMemo(
    () =>
      debounce(
        (
          cardItems: CartReduxModels.CartItem[],
          cartItemIndex: number,
          quantity: number
        ) => {
          // the quantity is valid and the cart deletion is not in progress
          if (quantity > 0 && !isCartItemDeletionInProgress.current) {
            updateCartItemState(cardItems, cartItemIndex, quantity);
          }
        },
        UPDATE_CART_DEBOUNCE_DELAY
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCartItemQuantity = (cartItemIndex: number, quantity: number) => {
    debouncedUpdateCartItemQuantity(cartItems, cartItemIndex, quantity);
  };

  const onSavePayment = () => {
    const newValue = !isSavePayment;
    if (getOrderResponse && getOrderResponse.orderId) {
      dispatch(
        CheckoutAction.getClientToken({
          orderId: getOrderResponse.orderId,
          storePaymentMethod: newValue,
        })
      );
    }
    dispatch(CheckoutAction.toggleSavePayment());
  };

  const deleteItem = (cartItemIndex: number) => {
    setCartItemIdToDelete(cartItemIndex);
  };

  const saveOrder = () => {
    setSaveOrderModal(false);
    navigate(Screens.Menu, { replace: true });
  };

  const abandonOrder = () => {
    setSaveOrderModal(false);

    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.AbandonedCart,
      AnalyticsPayloadGenerator.abandonCartPayload(analyticsState)
    );

    dispatch(CartReduxAction.clearCart());
    dispatch(OrderReduxAction.setOrderResponse(null));
    dispatch(LoyaltyActions.updateRedeemDollars(null));
    if (hasCoffee) dispatch(LoyaltyActions.getUserCoffeeLoyalty());
    navigate(Screens.Menu, { replace: true });
  };

  const onCloseSaveOrderModal = () => {
    setSaveOrderModal(false);
  };

  const orderNow = () => {
    dispatch(CheckoutAction.resetPayment());
    navigate(Screens.Payment, {
      state: {
        futureLoyalty: futureLoyalty(),
        futureCoffeeLoyalty: futureCoffeeLoyalty(),
      },
    });
  };

  //LoggedIn users can add or remove Favourite items
  const onCreateORUpdateFavourite = (
    payload: FavouriteModels.AddFavouriteRequest | number,
    type: string
  ) => {
    if (type === FavouriteModels.CRUDType.add) {
      dispatch(
        FavouriteReduxAction.createFavourite(
          payload as FavouriteModels.AddFavouriteRequest
        )
      );

      const menuSection = menuStructure
        ? MenuModuleUtils.mergeMenuSection(
            menuSectionEnabled,
            menuStructure.sections
          )
        : undefined;

      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.FavouriteOrder,
        AnalyticsPayloadGenerator.favouriteOrderPayload(
          payload as FavouriteModels.AddFavouriteRequest,
          AnalyticsConst.FavouriteSource.CART,
          selectedStore?.id ?? stores[0].id,
          selectedStore?.name ?? stores[0].name,
          menuSection
        )
      );
    } else {
      dispatch(FavouriteReduxAction.deleteFavourite(payload as number));
    }
  };

  //Close error toast message view
  const onResetFavouriteStatus = () => {
    dispatch(FavouriteReduxAction.resetStatus());
  };

  /**
   * Called when ModalWithInput Primary button pressed
   */
  const onRenameSavePress = () => {
    setIndexToUpdateFavourite({
      index: indexToUpdateFavourite?.index ?? 0,
      label: updateFavouritePayload.label,
    });
    dispatch(FavouriteReduxAction.renameFavourite(updateFavouritePayload));
    setShowRenameModal(false);

    AnalyticsInstance.trackEvent(AnalyticsConst.Events.FavouriteRenamed, {
      custom_name: updateFavouritePayload.label,
    });
  };

  /**
   * @param payload (favouriteId: number, productId: number, label: string)
   * Called when rename icon is pressed in cart item
   */
  const onRenameIconPress = (
    index: number,
    payload: FavouriteModels.UpdateFavouriteRequest
  ) => {
    setIndexToUpdateFavourite({ index, label: payload.label });
    setShowRenameModal(true);
    setUpdateFavouritePayload(payload);
  };

  const onCloseModifyProduct = () => {
    setShowProductModal(false);
    dispatch(OrderReduxAction.setShouldUpdateOrder(true));
  };

  const findProductTemplate = (
    category: MenuModels.Category,
    cartItem: CartReduxModels.CartItem,
    index: number,
    commonSection: MenuModels.CommonSection | undefined
  ) => {
    const productRoute = ProductUtils.findTemplateRoute(category);

    if (productRoute) {
      // if selected cart item is bundle, updates bundleItems state
      if (
        productRoute === ProductConst.ProductRoute.Bundles ||
        productRoute === ProductConst.ProductRoute.KidsBundle
      ) {
        cartItem?.parts?.forEach(
          (part: CartReduxModels.CartItem, i: number) => {
            dispatch(
              CartReduxAction.updateBundleItem({ cartItem: part, index: i })
            );
          }
        );
      } else if (productRoute === ProductConst.ProductRoute.Tacos) {
        cartItem?.parts?.forEach(
          (part: CartReduxModels.CartItem, i: number) => {
            dispatch(
              CartReduxAction.updateTacoItem({ cartItem: part, index: i })
            );
          }
        );
        dispatch(CartReduxAction.clearMIAMItem());
      } else if (
        productRoute === ProductConst.ProductRoute.ComplexCustomisableWithMeal
      ) {
        dispatch(CartReduxAction.clearMIAMItem());
        let kidsMealOptionsAmount: number | undefined = undefined;
        const parts = cartItem.parts;
        if (parts && parts.length) {
          const partsLength = parts.length;
          const mainProduct = category.products?.find(
            (p) => p.id === parts[partsLength - 1].productId
          );
          // checks how many sections show up before MIAM section
          kidsMealOptionsAmount =
            mainProduct &&
            ProductUtils.generateCustomisationOptionsData(
              mainProduct,
              config.version,
              category
            ).length;

          // ignores main product in cart item's parts and creates MIAM items' temporary state
          parts.forEach((part: CartReduxModels.CartItem, i: number) => {
            if (i !== partsLength - 1 && kidsMealOptionsAmount) {
              dispatch(
                CartReduxAction.updateMIAMItem({
                  cartItem: part,
                  index: kidsMealOptionsAmount + i,
                })
              );
            }
          });
        }
      } else if (productRoute === ProductConst.ProductRoute.MIAM) {
        dispatch(CartReduxAction.clearMIAMItem());
        cartItem?.parts?.forEach(
          (part: CartReduxModels.CartItem, i: number) => {
            dispatch(
              CartReduxAction.updateMIAMItem({ cartItem: part, index: i })
            );
          }
        );
      } else if (
        productRoute === ProductConst.ProductRoute.ComplexCustomisable
      ) {
        dispatch(CartReduxAction.clearMIAMItem());
      }

      setSelectedCartItem({
        cartItem,
        menuItem: category,
        productType: productRoute,
        index,
        commonSection,
      });
      setShowProductModal(true);
    }
  };

  const openProductTemplateToEdit = (
    cartItem: CartReduxModels.CartItem,
    index: number
  ) => {
    // early exit if no menu selected
    if (!menuStructure) return;

    const objPath = menuStructure.categoryLookup[cartItem.productId].split("/");
    const isMIAM = objPath[0] === "commonSections";
    const commonSectionIndex = Number(objPath[1]);
    const category = MenuModuleUtils.jsonPathAccessor(objPath, menuStructure);

    if (category) {
      const commonSection = !isMIAM
        ? menuStructure.commonSections.find(
            (n) => n.id === category?.commonSection?.[0]
          )
        : menuStructure.commonSections?.[commonSectionIndex];
      findProductTemplate(category, cartItem, index, commonSection);
    }
  };

  const onAddSomethingElse = () => {
    dispatch(AnalyticsAction.isAddSomethingElse(true));
    navigate(Screens.Menu);
  };

  /**
   * Clears currentOrderId and orderStatus on entry.
   * Keep it as first useEffect.
   */
  useEffect(() => {
    if (currentOrderId) {
      dispatch(OrderReduxAction.updateCurrentOrderId(null));
    }
    //reset order status
    //to avoid redirection to Dashboard when checkout failed
    dispatch(OrderReduxAction.clearOrderStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (analyticsState.basketId !== null) {
      AnalyticsInstance.trackEvent(
        AnalyticsConst.Events.ViewCart,
        AnalyticsPayloadGenerator.viewCartPayload(analyticsState)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsState.basketId]);

  useEffect(() => {
    removeOrderIsInProgressValueFromLocalStorage();
    if (menuStructure) {
      FavouriteModules.updateFavouriteToState(
        store,
        menuStructure,
        isAuthSuccess,
        userConfirmedOrderSetup,
        favourites
      );
    }
  }, [menuStructure, favourites, isAuthSuccess, userConfirmedOrderSetup]);

  // Initial create order call to API.
  useEffect(() => {
    return () => {
      dispatch(FavouriteReduxAction.resetStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!userConfirmedOrderSetup) navigate(Screens.Dashboard);
  }, [userConfirmedOrderSetup, navigate]);

  /**
   * Maps basket to cart.
   */
  useEffect(() => {
    if (getOrderResponse && !firstUpdate.current) {
      if (getOrderResponse.basket.total < 0) {
        dispatch(LoyaltyActions.updateRedeemDollars(null));
      }
    }
    if (orderError) {
      dispatch(OrderReduxAction.clearOrderError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderResponse, firstUpdate.current]);

  useEffect(() => {
    if (getOrderResponse && !firstUpdate.current) {
      setHasInvalidProducts(
        getOrderResponse.basket?.storeInvalidProducts?.length > 0 &&
          !getOrderLoading &&
          !updateOrderLoading
      );
    } else {
      setHasInvalidProducts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getOrderResponse,
    getOrderLoading,
    updateOrderLoading,
    firstUpdate.current,
  ]);

  /**
   * Triggers create order on active reward state change.
   */
  useEffect(() => {
    if (
      isAuthSuccess &&
      !firstUpdate.current &&
      !getOrderLoading &&
      !updateOrderLoading
    ) {
      if (
        OrderModules.OrderUtils.needToSyncCoupons(
          getOrderResponse,
          activeReward
        )
      ) {
        dispatch(OrderReduxAction.setShouldUpdateOrder(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReward, isAuthSuccess, getOrderResponse, firstUpdate.current]);

  /**
   * Clears error state when cart item gets empty.
   */
  useEffect(() => {
    if (!cartItems.length) {
      dispatch(OrderReduxAction.clearOrderError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems.length]);

  // Set the cart deletion flag to false
  // when the cart item length is less than before
  useEffect(() => {
    if (
      prevCartItemsLength.current !== cartItems.length &&
      prevCartItemsLength.current > cartItems.length
    ) {
      isCartItemDeletionInProgress.current = false;
    }

    // update the value for cart length if it changes
    if (prevCartItemsLength.current !== cartItems.length) {
      prevCartItemsLength.current = cartItems.length;
    }
  }, [cartItems.length]);

  // keep it as last useEffect
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    return;
  });

  const onOpenAllergenStatementURL = () => {
    if (config.allergenStatementSiteUrl) {
      window.open(config.allergenStatementSiteUrl, "_blank");
    }
  };

  const goToLogin = () => {
    navigate(Screens.Login, { state: { loginNavigatesTo: Screens.Order } });
  };

  return (
    <>
      <AlertMessage
        isVisible={cartItemIdToDelete != null}
        onClose={() => {
          setCartItemIdToDelete(null);
        }}
        title={t("CheckoutPayment:deleteConfirmationTitle")}
        body={t("CheckoutPayment:deleteItemAlert")}
        actionButtonProps={{
          buttonName: t("CheckoutPayment:deleteItemButton"),
          loading: getOrderLoading,
          onClick: () => {
            // do not enable Delete button until order response has come back!
            // when user spams delete button until it hits 1, followed up pressing
            // delete button - we're sending 2 HTTP PATCH calls which
            // returns a cached response causing cart items to incorrectly reappear
            if (cartItemIdToDelete !== null && !getOrderLoading) {
              isCartItemDeletionInProgress.current = true;
              // instead of cancelling the debounce, flush all remaining calls which
              // will be skipped due to the isCartItemDeletionInProgress flag being true
              // for some reason debounce cancel does not consistently cancel
              // all debounces
              debouncedUpdateCartItemQuantity.flush();
              deleteCartItem(cartItemIdToDelete);
            }
          },
        }}
        cancelButtonProps={{
          title: t("CheckoutPayment:cancelDeletionButton"),
          onClick: () => {
            setCartItemIdToDelete(null);
          },
        }}
      />

      <ModalWithButton
        isVisible={saveOrderModal}
        small={true}
        title={t("CheckoutPayment:saveOrderTitle")}
        messageBold={t("CheckoutPayment:saveOrderLabel")}
        primaryButton={{
          name: t("CheckoutPayment:saveButton"),
          action: saveOrder,
        }}
        secondaryButton={{
          name: t("CheckoutPayment:abandonButton"),
          action: abandonOrder,
        }}
        image={questionImage as ImageSourcePropType}
        onModalClose={onCloseSaveOrderModal}
        modalId='order-save-prompt'
      />

      <ModalWithInput
        isVisible={showRenameModal}
        webModalSize={WebModalSize.LARGE}
        testId={t("Favourite:renameTitle")}
        primaryButton={{
          name: t("Favourite:saveButton"),
          action: onRenameSavePress,
        }}
        title={t("Favourite:renameTitle")}
        message={t("Favourite:renameBody")}
        inputLabel={t("Favourite:renameLabel")}
        inputValue={updateFavouritePayload.label}
        onTextChange={(text) =>
          setUpdateFavouritePayload({ ...updateFavouritePayload, label: text })
        }
        onModalClose={() => setShowRenameModal(false)}
        inputErrorMessage={t("Favourite:renameError")}
      />

      <Order
        loyalty={loyalty ?? undefined}
        guestLoyalty={guestLoyalty}
        coffeeLoyalty={coffeeLoyalty}
        loyaltyBonusPoints={getOrderResponse?.loyalty?.totalBonusPoints ?? 0}
        loyaltyBonusDollars={getOrderResponse?.loyalty?.totalBonusDollars ?? 0}
        orderCollectionType={orderCollectionType}
        driveThruOptionAvailable={driveThruOptionAvailable}
        enableCutlery={enableCutlery}
        isLoggedIn={isAuthSuccess}
        isCartItemDeletionInProgress={isCartItemDeletionInProgress.current}
        currentUser={currentUser ?? null}
        isSavePayment={isSavePayment}
        cutlery={includeCutlery}
        basketErrors={getOrderResponse?.errors}
        orderError={orderError}
        data={cartItems}
        favouriteCartItems={favouriteCartItems}
        basketValue={orderReceiptInfo.basketValue}
        total={orderReceiptInfo.total}
        totalBeforeDiscount={orderReceiptInfo.totalBeforeDiscount}
        subtotal={orderReceiptInfo.subtotal}
        surcharges={orderReceiptInfo.surcharges}
        tax={orderReceiptInfo.tax}
        displayTax={orderReceiptInfo.displayTax}
        getOrderLoading={
          getOrderLoading || updateOrderLoading || isFavouriteUpdateLoading
        }
        isInLoadingState={getOrderLoading}
        isFavouriteUpdateError={isCRUDFavouriteError}
        isFavouriteUpdateErrorMessage={crudFavouriteErrorMessage}
        hasOrderInvalidProducts={hasInvalidProducts}
        newPointsAndDollars={newPointsAndDollars()}
        redeemDollars={orderReceiptInfo.redeemDollars}
        redeemDollarsBalance={orderReceiptInfo.redeemDollarsBalance}
        redeemDollarsErrorMessage={orderReceiptInfo.redeemDollarsErrorMessage}
        updateCartItemQuantity={updateCartItemQuantity}
        onChangePickupType={onChangePickupType}
        onCutleryChange={onCutleryChange}
        deleteItem={deleteItem}
        orderNow={orderNow}
        onSavePayment={onSavePayment}
        onCreateORUpdateFavourite={onCreateORUpdateFavourite}
        onResetFavouriteStatus={onResetFavouriteStatus}
        onRenamePress={onRenameIconPress}
        onItemEdit={openProductTemplateToEdit}
        onAddSomethingElse={onAddSomethingElse}
        onRedeemDollars={onRedeemDollars}
        onOpenAllergenLink={onOpenAllergenStatementURL}
        goToLogin={goToLogin}
        discountAmount={orderReceiptInfo.discountAmount}
        deliveryFee={delivery?.fee}
      />

      {selectedCartItem && (
        <ProductModal
          commonSection={selectedCartItem.commonSection}
          editMode={ProductConst.EditMode.MODIFY_CART_ITEM}
          productType={selectedCartItem.productType}
          cartItem={selectedCartItem.cartItem}
          isVisible={showProductModal}
          onClose={onCloseModifyProduct}
          category={selectedCartItem.menuItem}
          index={selectedCartItem.index}
          enableGoBackMode={false}
        />
      )}
    </>
  );
};

export default OrderContainer;
