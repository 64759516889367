import { Platform } from "react-native";

import config from "../../../config";
import {
  CheckoutResponse,
  PaymentPayload,
} from "../../../redux_store/checkout/model";
import { httpClient } from "../../httpClient";
import { ClientTokenReponse, GetClientTokenPayload } from "./model";

const isPaymentInProgressLocalStorageKey = "isPaymentInProgressLocalStorageKey";

const setOrderIsInProgressValueToLocalStorage = (orderId: string) => {
  if (Platform.OS === "web") {
    localStorage.setItem(isPaymentInProgressLocalStorageKey, orderId);
  }
};

export const getOrderIdInProgressValueFromLocalStorage = () => {
  if (Platform.OS === "web") {
    return localStorage.getItem(isPaymentInProgressLocalStorageKey);
  }
};

export const removeOrderIsInProgressValueFromLocalStorage = () => {
  if (Platform.OS === "web") {
    localStorage.removeItem(isPaymentInProgressLocalStorageKey);
  }
};

let abortController = new AbortController();
let operationIsRunning = false;

export async function getClientToken(
  data: GetClientTokenPayload
): Promise<ClientTokenReponse> {
  try {
    // console.debug(`API: GET orders/${data.orderId}/accesstoken`);

    const response = await httpClient({
      url: `orders/${data.orderId}/accesstoken`,
      method: "GET",
      headers: config.accessToken
        ? {
            Authorization: `${config.accessToken}`,
          }
        : undefined,
      queryParams: { ...data },
    });
    return response.data as ClientTokenReponse;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(
    //   e,
    //   `GET orders/<orderId>/accesstoken`
    // );
    throw e;
  }
}

export async function initPayment(
  data: PaymentPayload
): Promise<CheckoutResponse> {
  const orderId = data.id;

  setOrderIsInProgressValueToLocalStorage(orderId);
  if (operationIsRunning) {
    abortController.abort();
    abortController = new AbortController();
  }
  operationIsRunning = true;
  try {
    const url = `orders/${orderId}/async-checkout`;
    // console.debug(`API: POST ${url}`);

    const response = await httpClient({
      url,
      method: "POST",
      data: { ...data },
      useFQDN: true,
      abortController: abortController,
    });
    if (response) {
      operationIsRunning = false;
      removeOrderIsInProgressValueFromLocalStorage();
    }
    return response.data as CheckoutResponse;
  } catch (e) {
    operationIsRunning = false;
    // SentryLoggerInstance.sentryEndpointError(
    //   e,
    //   `POST orders/<orderId>/async-checkout`
    // );
    throw e;
  }
}

export async function initPaymentAuthorized(
  data: PaymentPayload
): Promise<CheckoutResponse> {
  const orderId = data.id;

  setOrderIsInProgressValueToLocalStorage(orderId);
  if (operationIsRunning) {
    abortController.abort();
    abortController = new AbortController();
  }
  operationIsRunning = true;
  try {
    const url = `orders/${orderId}/async-checkout`;
    // console.debug(`API: POST ${url}`);

    const response = await httpClient({
      url,
      method: "POST",
      data: { ...data },
      useFQDN: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${config.accessToken}`,
      },
    });
    if (response) {
      operationIsRunning = false;
      removeOrderIsInProgressValueFromLocalStorage();
    }
    return response.data as CheckoutResponse;
  } catch (e) {
    operationIsRunning = false;
    // SentryLoggerInstance.sentryEndpointError(
    //   e,
    //   `POST orders/<orderId>/async-checkout`
    // );
    throw e;
  }
}
