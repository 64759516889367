import { Dispatch } from "redux";

import { loyaltyActions } from "../../redux_store/loyalty/loyalty.slice";
import { IRewards } from "../../redux_store/loyalty/models";
import { Reward } from "./models";

export const adjustRewardsIfNeeded = (
  rewards: Reward[],
  activeReward: IRewards,
  dispatch: Dispatch
) => {
  const isActiveRewardIncluded = rewards.find((reward) => {
    return (
      reward.userCouponCode === activeReward.userCouponCode &&
      !reward.errorMessage
    );
  });
  if (!isActiveRewardIncluded) {
    const reduxRewards = rewards.map((t) => {
      const { errorMessage, ...other } = t;
      return other;
    });
    dispatch(loyaltyActions.clearActiveReward());
    dispatch(loyaltyActions.getUserRewardsSuccess(reduxRewards));
  }
};
