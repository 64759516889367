import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxAction,
  CartReduxModels,
  FavouriteModels,
  FavouriteReduxAction,
  MenuModuleUtils,
  RootState,
} from "gyg_common";
import { FavouriteCard } from "gyg_common/components/Favourites/FavouriteCard";

interface FavouriteCardContainerProps {
  favouriteCartItem: FavouriteModels.FavouriteCartItem;
  onRenamePress: (selectedFavourite: FavouriteModels.FavouriteCartItem) => void;
}

export const FavouriteCardContainer: React.FC<FavouriteCardContainerProps> = ({
  favouriteCartItem,
  onRenamePress,
}) => {
  const dispatch = useDispatch();
  const { menuStructure, validMenuSection } = useSelector(
    (s: RootState) => s.menu
  );
  const allMenuIDs = menuStructure
    ? MenuModuleUtils.getAllMenuIDs(
        validMenuSection?.subSections ?? [],
        menuStructure.commonSections
      )
    : [];

  const [isFavouriteItem, setFavouriteItem] = useState<boolean>(true);

  //LoggedIn users can add or remove Favourite items
  const onCreateORUpdateFavourite = (
    productId: number,
    productName: string,
    type: FavouriteModels.CRUDType
  ) => {
    if (type === FavouriteModels.CRUDType.add) {
      const createFavRequestBody: FavouriteModels.AddFavouriteRequest = {
        basketItems: [{ id: productId, name: productName }],
        label: productName,
      };
      dispatch(FavouriteReduxAction.createFavourite(createFavRequestBody));
    } else {
      dispatch(FavouriteReduxAction.deleteFavourite(productId));
    }
  };

  const setYourFavourite = (type: FavouriteModels.CRUDType) => {
    setFavouriteItem(!isFavouriteItem);

    onCreateORUpdateFavourite(
      type === FavouriteModels.CRUDType.delete
        ? favouriteCartItem?.favouriteId
        : favouriteCartItem?.cartItem.productId,
      favouriteCartItem?.label,
      type
    );
  };

  const onAddToCart = (cartItem: CartReduxModels.CartItem) => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.AddToOrder, {
      source: AnalyticsConst.AddToOrderSource.FAVOURITES,
    });
    dispatch(CartReduxAction.addItemToCart({ cartItems: [cartItem] }));
  };

  return (
    <FavouriteCard
      allMenuIDs={allMenuIDs}
      favouriteCartItem={favouriteCartItem}
      isFavouriteItem={isFavouriteItem}
      onRenameTagPress={onRenamePress}
      onAddToCart={onAddToCart}
      setYourFavourite={setYourFavourite}
      menuStructure={menuStructure}
    />
  );
};
