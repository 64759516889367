import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import config from "../../../config";
import * as RegistrationUtils from "../../../modules/Registration/utils";
import { RootState } from "../../../redux_store/rootReducer";
import { userActions as UserReduxAction } from "../../../redux_store/user/user.slice";
import questionImg from "../../assets/images/illustration_error_question.png";
import { WebModalSize } from "../../modals/MiddleModal";
import ModalWithButton from "../../modals/ModalWithButton";
import ModalWithInput from "../../modals/ModalWithInput";

interface DeleteAccountModalProps {
  isDeleteConfirmVisible: boolean;
  isDeleteUserLoading?: boolean;
  setIsDeleteConfirmVisible: (isVisible: boolean) => void;
  onDisplayLoading?: (loading: boolean) => void;
  onDeleteAccount: () => void;
}

export const DeleteAccountModal = ({
  isDeleteConfirmVisible,
  isDeleteUserLoading,
  setIsDeleteConfirmVisible,
  onDisplayLoading,
  onDeleteAccount,
}: DeleteAccountModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    profile,
    errorMessage,
    isAccountDeleted,
    isAccountVerificationCodeSent,
  } = useSelector((s: RootState) => s.user);

  const [verificationCode, setVerificationCode] = useState<string>("");
  const [verificationCodeError, setVerificationCodeError] =
    useState<boolean>(false);
  const [isVerifyMobileVisible, setIsVerifyMobileVisible] =
    useState<boolean>(false);
  const [proceedWithDelete, setProceedWithDelete] = useState<boolean>(false);

  const mobileNumber = profile?.phoneNumber ?? "";

  /**
   * init delete account
   * 1. api call 2. show verify mobile modal
   */
  const deleteAccount = () => {
    setVerificationCode("");
    dispatch(UserReduxAction.deleteAccountVerificationCode());
    setIsDeleteConfirmVisible(false);
    setIsVerifyMobileVisible(true);
    if (errorMessage) {
      dispatch(UserReduxAction.resetError());
    }
  };
  const cancelDeleteAccount = () => {
    setIsDeleteConfirmVisible(false);
  };
  const onDeleteAccountModalClose = () => {
    setIsDeleteConfirmVisible(false);
  };

  /**
   * handler for verification code
   * @param text
   */
  const verificationCodeHandler = (text: string) => {
    setVerificationCode(text);
    setVerificationCodeError(false);
    if (errorMessage) {
      dispatch(UserReduxAction.resetError());
    }
  };
  const onVerifyModalClose = () => {
    setIsVerifyMobileVisible(false);
  };
  /**
   * init delete account process
   * send BE verification code
   */
  const onConfirmDelete = () => {
    if (verificationCode === "") {
      setVerificationCodeError(true);
    } else {
      if (onDisplayLoading) {
        onDisplayLoading(true);
      }
      dispatch(
        UserReduxAction.verifyDeleteAccountCode({
          code: Number(verificationCode),
        })
      );
    }
  };

  useEffect(() => {
    if (isAccountDeleted) {
      onDeleteAccount();
      setIsVerifyMobileVisible(false);
      dispatch(
        UserReduxAction.setMessageToast({
          iconType: "check",
          title: t("UserManagement:deleteSuccessModal"),
          showCloseButton: true,
        })
      );

      //reset isAccountDeleted flag
      dispatch(UserReduxAction.verifyDeleteAccountCodeSuccess(false));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isAccountDeleted]);

  /**
   * Shows verify modal only when API responded.
   */
  useEffect(() => {
    if (
      (!verificationCode && isDeleteUserLoading && isVerifyMobileVisible) ||
      !isAccountVerificationCodeSent
    ) {
      setProceedWithDelete(false);
    } else {
      setProceedWithDelete(true);
    }
  }, [
    verificationCode,
    isDeleteUserLoading,
    isVerifyMobileVisible,
    isAccountVerificationCodeSent,
  ]);

  /**
   * Shows alert that verification code was not send, if trigger/mobile API fails.
   */
  useEffect(() => {
    if (
      errorMessage &&
      isVerifyMobileVisible &&
      !proceedWithDelete &&
      !isAccountVerificationCodeSent
    ) {
      dispatch(
        UserReduxAction.setMessageToast({
          iconType: "alert",
          title: errorMessage.message,
          showCloseButton: true,
        })
      );
    }
  }, [
    errorMessage,
    isVerifyMobileVisible,
    isAccountVerificationCodeSent,
    proceedWithDelete,
    dispatch,
  ]);

  return (
    <>
      <ModalWithButton
        modalId={"DeleteAccountModal"}
        image={questionImg as ImageSourcePropType}
        isVisible={isDeleteConfirmVisible}
        title={t("UserManagement:deleteAccountConfirm")}
        messageBold={t("UserManagement:deleteAccountDesc")}
        primaryButton={{
          name: t("UserManagement:deleteAccountDel"),
          action: deleteAccount,
        }}
        secondaryButton={{
          name: t("UserManagement:deleteAccountCancel"),
          action: cancelDeleteAccount,
        }}
        onModalClose={onDeleteAccountModalClose}
        small={true}
      />

      <ModalWithInput
        webModalSize={WebModalSize.SMALL}
        testId={"VerifyDeleteAccountModal"}
        isVisible={
          isVerifyMobileVisible && proceedWithDelete && !isDeleteUserLoading
        }
        isInputError={!!errorMessage || verificationCodeError}
        title={t("UserManagement:deleteAccountVerify")}
        inputLabel={t("UserManagement:deleteVerifyInput")}
        inputPlaceholder={t("UserManagement:deleteVerifyPlaceholder")}
        inputErrorMessage={errorMessage ? errorMessage.message : ""}
        inputValue={verificationCode ?? ""}
        verifyMobile
        messageBold={`${t(
          "UserManagement:deleteVerifyLabel"
        )}\n${RegistrationUtils.getMobileNumberWithDialCode(
          mobileNumber,
          config.version
        )}`}
        message={t("UserManagement:deleteVerifyDesc")}
        primaryButton={{
          name: t("UserManagement:deleteVerifyConfirm"),
          action: onConfirmDelete,
        }}
        onTextChange={verificationCodeHandler}
        onRequestCode={deleteAccount}
        onModalClose={onVerifyModalClose}
      />
    </>
  );
};
