import React from "react";
import { useTranslation } from "react-i18next";
import {
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import * as ProductModuleModel from "../../../modules/Products/model";
import defaultImage from "../../assets/images/no_image_available_menu.png";
import MenuImage from "../../Menu/MenuImage";
import { Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";

const styles = StyleSheet.create({
  split: {
    flex: 1,
  },
  optionView: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: Spacing.ExtraThin,
  },
  optionIcon: {
    width: 76,
    height: 76,
    marginLeft: Platform.OS === "web" ? Spacing.Thin : Spacing.Light,
    resizeMode: "cover",
  },
  textGroup: {
    padding: Spacing.Light,
    flexShrink: 1,
  },
});

interface OptionProps {
  data: ProductModuleModel.OptionDataItemProps;
}

const Option: React.FC<OptionProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <View key={data.id} style={styles.optionView}>
      {!data.withoutImage && (
        <MenuImage
          style={styles.optionIcon}
          source={
            data.imageTopDownUrl?.length
              ? (data.imageTopDownUrl as ImageSourcePropType)
              : defaultImage
          }
        />
      )}

      <View style={styles.textGroup}>
        <Text style={Typography.titleThree}>{data.name}</Text>
        {!!data.nutritionalInfo && (
          <Text style={Typography.captionOne}>
            {data.nutritionalInfo}
            {t("OrderManagement:energyUnit")}
          </Text>
        )}
      </View>
      <View style={styles.split} />
      {!!data.price && (
        <Text style={Typography.captionOne}>
          {`+ $${data.price.toFixed(2)}`}
        </Text>
      )}
    </View>
  );
};

export default Option;
