import "./style.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import config from "config";
import {
  InputType,
  StringBuilders,
  StringValidators,
  UserReduxModels,
} from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { EditModeType } from "views/containers/UserManagement/UserProfileContainer";

import FormInputWithButton from "../shared/FormInput/FormInputWithButton";

export interface UpdateDateOfBirthProps {
  userProfile: UserReduxModels.UserProfile;
  onEditMode: EditModeType | undefined;
  setEditMode: (type: EditModeType | undefined) => void;
  onUpdateUserAccount: (
    userAccountRequest: UserReduxModels.UserAccountRequest
  ) => void;
}

export const UpdateDateOfBirth: React.FC<UpdateDateOfBirthProps> = ({
  userProfile,
  onEditMode,
  setEditMode,
  onUpdateUserAccount,
}) => {
  const { t } = useTranslation();
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(
    userProfile?.dateOfBirth ?? ""
  );
  const isOnEditMode = onEditMode === EditModeType.BIRTHDAY;

  const birthdayHandler = (text: string) => {
    setDateOfBirth(
      StringBuilders.birthdayBuilder(dateOfBirth, text, config.version)
    );
    setDateOfBirthError(false);
  };

  const checkValidField = (): boolean => {
    if (dateOfBirth === "") return true;
    setDateOfBirthError(StringValidators.validateBirthday(dateOfBirth));

    if (StringValidators.validateBirthday(dateOfBirth)) {
      return false;
    } else {
      return true;
    }
  };

  const onEditDOB = () => {
    setEditMode(EditModeType.BIRTHDAY);
  };

  const onSaveDOB = () => {
    setDateOfBirthError(false);
    if (checkValidField()) onUpdateUserAccount({ dateOfBirth });
  };

  //Cancel button click event
  const onCancel = () => {
    setDateOfBirth(userProfile?.dateOfBirth || "");
    setDateOfBirthError(false);
    setEditMode(undefined);
  };

  //Reloads the DOB display value on the form
  useEffect(() => {
    setDateOfBirth(userProfile?.dateOfBirth || "");
  }, [userProfile?.dateOfBirth]);

  //Email label left Cancel on right side
  const BirthDayLabelWithCancelButton = () => {
    return (
      <div className='user-profile-main__titlewrapper'>
        <div className='user-profile-main__label'>
          <p className='input-label'> {t("UserManagement:birthdayLabel")}</p>
        </div>

        <div className='user-profile-main__cancel'>
          <a className='input-label__cancel' onClick={onCancel}>
            {t("UserManagement:cancelLabel")}{" "}
          </a>
        </div>
      </div>
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSaveDOB();
  };

  return (
    <div>
      <form
        style={{ display: "contents" }}
        autoComplete='on'
        onSubmit={handleSubmit}>
        {!isOnEditMode && (
          <FormInputWithButton
            inputLabel={t("UserManagement:birthdayLabel")}
            onChangeText={birthdayHandler}
            error={dateOfBirthError}
            value={dateOfBirth}
            isBorderBottomOnly={true}
            onEdit={onEditDOB}
            placeHolder={t("UserManagement:birthdayFormat")}
            disabled={!!onEditMode}
          />
        )}

        {isOnEditMode && (
          <div>
            <BirthDayLabelWithCancelButton />
            <div className='user-profile-main__wrapper'>
              <div className='user-profile-main__threequartercol'>
                <FormInputWithButton
                  inputLabel={t("UserManagement:birthdayLabel")}
                  onChangeText={birthdayHandler}
                  error={dateOfBirthError}
                  value={dateOfBirth}
                  isBorderBottomOnly={false}
                  onEdit={onEditDOB}
                  isShowLabel={false}
                  placeHolder={t("UserManagement:birthdayFormat")}
                  errorMsg={t("UserManagement:dateError")}
                  type={InputType.BIRTHDAY}
                />
              </div>

              <div className={"user-profile-main__onequartercol"}>
                <PrimaryBlackButton
                  disable={dateOfBirth === userProfile.dateOfBirth}
                  border={false}
                  onClick={onSaveDOB}
                  buttonName={t("UserManagement:saveLabel")}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
