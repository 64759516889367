import React from "react";
import Iterate from "react-native-iterate";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BASE_URL } from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  AuthReduxAction,
  RootState,
} from "gyg_common";
import LoadingScreen from "gyg_common/components/LoadingScreen";
import HeaderAccountButton from "views/components/shared/Header/HeaderAccountButton";

const HeaderAccountContainer: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthSuccess, currentUser } = useSelector(
    (state: RootState) => state.login
  );
  const { rewards } = useSelector((state: RootState) => state.loyalty);

  const { profile, isDeleteUserLoading } = useSelector(
    (state: RootState) => state.user
  );

  const logoutGoogleUser = () => {
    if (profile?.authenticationSourceType === AnalyticsConst.LoginType.Google) {
      window.location.replace(
        `${BASE_URL}/auth/socialLogout?redirect_uri=https://${window.location.hostname}/authenticate`
      );
    }
  };

  const logoutCurrentDevice = () => {
    localStorage.removeItem("CurrentUser");
    Iterate.reset();
    dispatch(AuthReduxAction.logoutCurrentDevice());
    logoutGoogleUser();
  };

  const onLoginClick = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.LoginOrJoin, {
      screen_origin: location.pathname,
    });
  };

  const onRegisterClick = () => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.Registration, {
      screen_origin: location.pathname,
    });
  };

  const logoutAllDevices = () => {
    localStorage.removeItem("CurrentUser");
    Iterate.reset();
    dispatch(AuthReduxAction.logoutAllDevices());
    logoutGoogleUser();
  };

  return (
    <>
      <LoadingScreen loading={isDeleteUserLoading} />
      <HeaderAccountButton
        isUserAuthenticated={isAuthSuccess}
        userName={(profile?.firstName || currentUser?.firstName) ?? ""}
        onLogOut={logoutCurrentDevice}
        onLoginClick={onLoginClick}
        onRegisterClick={onRegisterClick}
        onLogOutAll={logoutAllDevices}
        countOfNotifications={rewards.length}
      />
    </>
  );
};

export default HeaderAccountContainer;
