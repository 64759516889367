import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  AnalyticsConst,
  AnalyticsInstance,
  CartReduxAction,
  CartReduxModels,
  MenuModels,
  ProductConst,
  RootState,
} from "gyg_common";
import ProductBundle from "views/components/Products/ProductBundle";

export interface ProductBundleContainerProps {
  multiPart: MenuModels.MultiPart;
  editMode: ProductConst.EditMode;
  index?: number;
  quantity?: number;
  isUpsell?: boolean;
  goBack: () => void;
}

const ProductBundleContainer: React.FC<ProductBundleContainerProps> = ({
  multiPart,
  goBack,
  editMode,
  index,
  quantity,
  isUpsell,
}) => {
  const { bundleItem } = useSelector((state: RootState) => state.cart);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  const dispatch = useDispatch();

  const handleResetBundleItem = (index: number) => {
    dispatch(
      CartReduxAction.updateBundleItem({ cartItem: undefined, index: index })
    );
  };

  const handleUpdateBundleItem = (
    cartItem: CartReduxModels.CartItem,
    index: number
  ) => {
    dispatch(
      CartReduxAction.updateBundleItem({ cartItem: cartItem, index: index })
    );
  };

  useEffect(() => {
    AnalyticsInstance.trackEvent(AnalyticsConst.Events.ProductBundle, {
      bundle_id: multiPart.id,
      bundle_name: multiPart.name,
      product_quantity: 1,
      view_mode: editMode,
      store_id: selectedStore?.id,
      store_name: selectedStore?.name,
    });
  }, [
    multiPart.id,
    multiPart.name,
    selectedStore?.id,
    selectedStore?.name,
    editMode,
  ]);

  return (
    <ProductBundle
      editMode={editMode}
      handleResetBundleItem={handleResetBundleItem}
      handleUpdateBundleItem={handleUpdateBundleItem}
      bundleItem={bundleItem}
      multiPart={multiPart}
      goBack={goBack}
      cartIndex={index}
      quantity={quantity}
      isUpsell={isUpsell}
    />
  );
};

export default ProductBundleContainer;
