import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  Platform,
  Text,
  TouchableHighlight,
  View,
} from "react-native";

import { OrderReduxModels } from "../../";
import * as OrderCardModels from "../../modules/OrderCard/model";
import * as PlatformUtils from "../../modules/platformUtils";
import locationIcon from "../assets/icons/icon_location.png";
import deliveryIcon from "../assets/icons/services/icon_delivery_no_bg.png";
import pickupIcon from "../assets/icons/services/icon_pickup.png";
import tableServiceIcon from "../assets/icons/services/icon_table.png";
import SecondaryButton from "../Buttons/SecondaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import CardSticker from "../CardSticker";
import OrderStatusLabel from "../OrderStatusLabel";
import colours from "../styles/colours";
import { statusBarStyle, styles } from "./OrderCard.styles";

export interface OrderCardProps {
  isFullWidth?: boolean;
  orderCardViewProps: OrderCardModels.OrderCardViewProps;
  isOlderThanActiveOrderThreshold: boolean;
  primaryButtonText: string;
  secondaryButtonText: string;
  onPrimaryButtonPress: () => void;
  onSecondaryButtonPress: () => void;
  goToOrderStatus: () => void;
}

// Width threshold for displaying buttons below order details
const minWidth = 767;
const windowWidth = Dimensions.get("window").width;

export const OrderCard = (props: OrderCardProps): JSX.Element => {
  const {
    isFullWidth,
    isOlderThanActiveOrderThreshold,
    orderCardViewProps,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonPress,
    onSecondaryButtonPress,
    goToOrderStatus,
  } = props;

  const {
    orderNo,
    pickupDate,
    pickupTime,
    orderStatus,
    numOfOtherItems,
    price,
    productName,
    pickupLocation,
    isTableService,
    checkoutResponse,
    orderCollectionType,
  } = orderCardViewProps;

  const { t } = useTranslation();

  const [isTabletScreen, setIsTabletScreen] = useState(windowWidth > minWidth);

  const orderStatusLabel =
    checkoutResponse?.order?.orderProgressStatus?.orderProgressDisplayName;

  const bgColor = isOlderThanActiveOrderThreshold
    ? colours.darkGrey
    : checkoutResponse?.order?.orderProgressStatus?.orderProgressDisplayColour;

  const isDelivery =
    orderCollectionType == OrderReduxModels.CollectionType.DELIVERY;

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({ window }) => {
      setIsTabletScreen(window.width > minWidth);
    });
    return () => subscription?.remove();
  });

  const isFullWidthWeb = Platform.OS === "web" && isFullWidth && isTabletScreen;

  const { buttonContainer, container, productNameStyle } = useMemo(() => {
    if (Platform.OS === "web") {
      return {
        container:
          isFullWidth && isTabletScreen ? styles.desktopContainer : null,
        buttonContainer:
          isFullWidth && isTabletScreen ? styles.desktopButtonContainer : null,
        productNameStyle:
          isFullWidth && isTabletScreen
            ? styles.productNameDesktop
            : styles.productName,
      };
    }

    return {
      container: null,
      buttonContainer: styles.mobileButtonContainer,
      productNameStyle: styles.productName,
    };
  }, [isFullWidth, isTabletScreen]);

  const orderDetails = (webStyle?: boolean) => {
    return (
      <View style={webStyle ? styles.orderDetailsWeb : styles.orderDetails}>
        {isTableService ? (
          <Image
            style={styles.icon}
            source={tableServiceIcon as ImageSourcePropType}
            resizeMode='contain'
          />
        ) : isDelivery ? (
          <Image
            style={styles.deliveryIcon}
            source={deliveryIcon as ImageSourcePropType}
            resizeMode='contain'
          />
        ) : (
          <Image
            style={styles.icon}
            source={pickupIcon as ImageSourcePropType}
            resizeMode='contain'
          />
        )}

        <Text style={styles.orderDetailText}>
          {isTableService
            ? t("RecentOrders:tableServiceLabel", {
                tableNumber: checkoutResponse.order.tableNumber,
              })
            : isDelivery
              ? t("RecentOrders:deliveryLabel")
              : t("RecentOrders:pickupAtLabel")}
        </Text>
      </View>
    );
  };

  return (
    <CardSticker>
      <TouchableHighlight
        underlayColor={colours.lightestGrey}
        onPress={goToOrderStatus}
        {...PlatformUtils.generateTestID(Platform.OS, "OrderCard" + orderNo)}
        accessible={true}>
        <View style={styles.cardContainer}>
          <View style={statusBarStyle(bgColor, isTabletScreen).statusBar} />
          <View style={styles.container}>
            <View style={styles.titleContainer}>
              <Text
                style={styles.title}
                {...PlatformUtils.generateTestID(
                  Platform.OS,
                  "OrderNumber" + orderNo
                )}>
                {t("RecentOrders:orderNoLabel", {
                  orderNo,
                })}
              </Text>
              <Text style={styles.price}>
                ${price ? price?.toFixed(2) : "0.00"}
              </Text>
            </View>

            {orderStatus && !isOlderThanActiveOrderThreshold && (
              <View style={styles.orderStatus}>
                <OrderStatusLabel bgColor={bgColor} title={orderStatusLabel} />
              </View>
            )}

            <View style={container}>
              <View style={styles.orderInfoContainer}>
                <View style={styles.orderDetailsContainer}>
                  <View style={styles.orderDetails}>
                    <Text style={styles.orderDetailText}>
                      {`${pickupTime}  ${pickupDate}`}
                    </Text>
                  </View>

                  {!isFullWidthWeb && orderDetails()}
                </View>

                {!isOlderThanActiveOrderThreshold && (
                  <View style={styles.orderDetails}>
                    <Image
                      style={styles.locationIcon}
                      source={locationIcon as ImageSourcePropType}
                    />
                    <Text style={styles.orderDetailText}>{pickupLocation}</Text>
                  </View>
                )}

                {isOlderThanActiveOrderThreshold && (
                  <Text
                    numberOfLines={
                      Platform.OS === "web" && isFullWidth ? undefined : 1
                    }
                    style={productNameStyle}>
                    {productName +
                      (numOfOtherItems > 0
                        ? t("RecentOrders:plusOneItemLabel", {
                            numOfOtherItems: numOfOtherItems,
                          }) + (numOfOtherItems > 1 ? "s" : "")
                        : "")}
                  </Text>
                )}
              </View>
              <View style={buttonContainer}>
                {isFullWidthWeb && orderDetails(true)}
                {isOlderThanActiveOrderThreshold && (
                  <View style={styles.buttonContainer}>
                    <View style={styles.secondaryButton}>
                      <TertiaryButton
                        testId='OrderCardSecondaryButton'
                        title={secondaryButtonText}
                        onClick={onSecondaryButtonPress}
                      />
                    </View>

                    <View style={styles.primaryButton}>
                      <SecondaryButton
                        testId='OrderCardPrimaryButton'
                        buttonName={primaryButtonText}
                        onClick={onPrimaryButtonPress}
                      />
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </TouchableHighlight>
    </CardSticker>
  );
};
