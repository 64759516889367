import React from "react";
import { useTranslation } from "react-i18next";
import {
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import * as MenuModuleUtils from "../modules/Menu/utils";
import * as ProductConst from "../modules/Products/const";
import * as ProductUtils from "../modules/Products/utils";
import { Category, Product } from "../redux_store/menu/models";
import defaultImage from "./assets/images/no_image_available_menu.png";
import CardSticker from "./CardSticker";
import MenuImage from "./Menu/MenuImage";
import ModalListOptions from "./ModalListOptions";
import colours from "./styles/colours";
import { BorderRadius, Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

export interface ProductMultipartSectionSelectionProps {
  selectionData: (Category | Product)[];
  onPress: (data: Category) => void;
  onAddtoBundleItem: (data: Product) => void;
  onBack?: () => void;
}
export interface MultipartSectionItem {
  item: Category | Product;
  index: number;
}

interface StyleProps {
  hovered?: boolean;
  pressed?: boolean;
}

const styles = StyleSheet.create({
  icon: {
    width: 110,
    height: 88,
    resizeMode: "cover",
    borderRadius: BorderRadius.Medium,
  },
  gap: {
    paddingBottom: Spacing.Thin,
  },
  right: {
    paddingVertical: Spacing.Regular,
    flex: 1,
    justifyContent: "center",
  },
  price: {
    paddingRight: Spacing.Light,
    justifyContent: "center",
  },
  container: {
    flexDirection: "row",
    borderRadius: BorderRadius.Medium,
  },
  listItem: {
    paddingHorizontal: Spacing.Light,
    borderRadius: BorderRadius.Medium,
  },
});

const buttonStyle = (props: StyleProps) =>
  StyleSheet.create({
    menuItem: {
      backgroundColor:
        props.hovered || props.pressed ? colours.lightestGrey : colours.white,
      ...styles.container,
    },
  });

const ProductMultipartSectionSelection: React.FC<
  ProductMultipartSectionSelectionProps
> = ({ onPress, selectionData, onAddtoBundleItem, onBack }) => {
  const { t } = useTranslation();
  const handlePress = (item: Product | Category) => {
    if (
      "templateId" in item &&
      ProductUtils.getProductRoute(item.templateId as string) !=
        ProductConst.ProductRoute.NonCustomization
    ) {
      onPress(item);
    } else {
      onAddtoBundleItem(item as Product);
    }
  };
  const minPrice = React.useMemo(() => {
    return ProductUtils.getMinPriceInList(selectionData);
  }, [selectionData]);
  const renderItem = ({ item, index }: MultipartSectionItem) => {
    let title = item.name;
    let description;
    let price = 0;
    if (!("templateId" in item)) {
      const data = MenuModuleUtils.getProductSizeAndTitle(item as Product);
      title = data.title as string;
      description = data.size;
      if (item.price) price = item.price - minPrice;
    }

    return (
      <View
        key={item.name}
        style={{
          ...styles.listItem,
          paddingBottom:
            index === selectionData.length - 1
              ? Platform.OS === "web"
                ? Spacing.Light
                : 0
              : Spacing.Thin,
          marginTop: index != 0 ? 0 : Platform.OS === "web" ? Spacing.Light : 0,
        }}>
        <CardSticker>
          <Pressable
            key={item.name}
            style={({ hovered, pressed }) =>
              buttonStyle({ hovered, pressed }).menuItem
            }
            onPress={() => handlePress(item)}>
            <View>
              <MenuImage
                style={styles.icon}
                source={
                  item.imageTopDownUrl?.length
                    ? (item.imageTopDownUrl as ImageSourcePropType)
                    : defaultImage
                }
              />
            </View>
            <View style={styles.right}>
              <Text style={Typography.smallProductTitle}>
                {title.toUpperCase()}
              </Text>
              {description && (
                <>
                  <View style={styles.gap} />
                  <Text style={Typography.captionOne}>{description}</Text>
                </>
              )}
            </View>
            {item.price && !!price && (
              <View style={styles.price}>
                <Text style={Typography.captionOne}>{`+ $${price.toFixed(
                  2
                )}`}</Text>
              </View>
            )}
          </Pressable>
        </CardSticker>
      </View>
    );
  };

  return (
    <ModalListOptions
      title={t("OrderManagement:bundleMultipartSectionSelection")}
      optionData={selectionData}
      renderItem={renderItem}
      onBack={onBack}
    />
  );
};

export default ProductMultipartSectionSelection;
