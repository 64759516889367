import React from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";

import ModalContent, { ModalSize } from "../modals/ModalContent";

interface OrderUpdatedViewProps {
  onConfirm: () => void;
}

const OrderUpdatedView: React.FC<OrderUpdatedViewProps> = ({ onConfirm }) => {
  const { t } = useTranslation();
  return (
    <ModalContent
      title={t("OrderManagement:OrderUpdated")}
      description={t("OrderManagement:OrderDescription")}
      actionButtonTitle={t("OrderManagement:GotIt")}
      onActionTap={onConfirm}
      size={Platform.OS == "web" ? ModalSize.small : ModalSize.default}
    />
  );
};

export default OrderUpdatedView;
