import type dropin from "braintree-web-drop-in";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import { Store as ReduxStore } from "redux";

import config from "config";
import { orderTimeSelector } from "gyg_common/redux_store/order/selectors";

import {
  AnalyticsAction,
  ApiOrderModel,
  BraintreeReduxActions,
  CheckoutAction,
  CheckoutModels,
  locales,
  OrderModules,
  RootState,
} from "../";

interface SheetItem {
  title: string;
  value: string;
}

export interface BrainTreeProps {
  clientToken: string;
  items: SheetItem[];
  orderTotal: string;
  currency: string;
  customer: CheckoutModels.Customer;
  orderId: string;
  isExistingPayMethod: boolean;
  existingPayType?: FetchDropInResult | undefined;
  merchantName: string;
  isSavePayment: boolean;
  includeCutlery: boolean;
  partPayment?: CheckoutModels.Payment;
  analyticsPaymentType: (paymentType: string) => void;
  initPayment: (payload: CheckoutModels.PaymentPayload) => void;
  clientPickUpTime: string;
}

interface FetchDropInResult {
  nonce: string;
  type: string;
  deviceData: string;
}

interface WebOrAppBrainTree {
  appInitBrainTree?: (props: BrainTreeProps) => void;
  appPreviousPayType?: FetchDropInResult;
  webBrainTreeInstance?: dropin.Dropin;
  webSetBrainTreeInstance?: React.Dispatch<
    React.SetStateAction<dropin.Dropin | undefined>
  >;
}

export const useInitPayment = (
  store: ReduxStore,
  orderTotal: number,
  braintree: WebOrAppBrainTree
) => {
  const { t } = useTranslation();
  const dispatch = store.dispatch;
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const authFirstName = useSelector(
    (state: RootState) => state.user?.profile?.firstName
  );
  const authLastName = useSelector(
    (state: RootState) => state.user?.profile?.lastName
  );
  const authEmail = useSelector(
    (state: RootState) => state.user?.profile?.email
  );
  const authPhoneNumber = useSelector(
    (state: RootState) => state.user?.profile?.phoneNumber
  );

  const firstName = useSelector((state: RootState) => state.guest?.firstName);
  const lastName = useSelector((state: RootState) => state.guest?.lastName);
  const phoneNumber = useSelector(
    (state: RootState) => state.guest?.phoneNumber
  );
  const email = useSelector((state: RootState) => state.guest?.email);

  const activeReward = useSelector(
    (state: RootState) => state.loyalty?.activeReward
  );
  const loyalty = useSelector((state: RootState) => state.loyalty?.loyalty);
  const redeemDollars = useSelector(
    (state: RootState) => state.loyalty?.redeemDollars
  );

  const getOrderResponse = useSelector(
    (state: RootState) => state.order?.getOrderResponse
  );
  const includeCutlery = useSelector(
    (state: RootState) => state.order?.includeCutlery
  );

  const clientTokenResponse = useSelector(
    (state: RootState) => state.checkout?.clientTokenResponse
  );
  const isSavePayment = useSelector(
    (state: RootState) => state.checkout?.isSavePayment
  );

  const selectedStore = useSelector(
    (state: RootState) => state.store?.selectedStore
  );

  const orderTime = useSelector(orderTimeSelector);

  const trackPaymentType = useCallback(
    (paymentType: string) => {
      dispatch(AnalyticsAction.setPaymentType(paymentType));
    },
    [dispatch]
  );

  const performCheckout = useCallback(
    (paymentPayload: CheckoutModels.PaymentPayload) => {
      if (selectedStore) {
        dispatch(
          BraintreeReduxActions.savePaymentPayload({
            paymentPayload,
            store: selectedStore,
          })
        );
      }

      const action = isAuthSuccess
        ? CheckoutAction.initPaymentAuthorized
        : CheckoutAction.initPayment;

      dispatch(action({ ...paymentPayload, async: true }));
    },
    [dispatch, isAuthSuccess, selectedStore]
  );

  const initPaymentForWeb = useCallback(
    (
      payment: CheckoutModels.Payment,
      customer: CheckoutModels.Customer,
      orderId?: string
    ) => {
      const instance = braintree.webBrainTreeInstance;

      const reinitBraintree = () => {
        instance.teardown();
        braintree.webSetBrainTreeInstance?.(undefined);
        //Request new client token will trigger braintree initialization again
        dispatch(
          CheckoutAction.getClientToken({ orderId: getOrderResponse.orderId })
        );
      };
      if (instance && instance.isPaymentMethodRequestable()) {
        instance.requestPaymentMethod((braintreeError, payload) => {
          if (braintreeError) {
            // Handle Braintree error (e.g., log the error)
          }

          if (payload) {
            payment.braintree = {
              checkoutType: 0,
              nonce: payload.nonce,
              amount: orderTotal.toFixed(2),
              storeCard: isSavePayment,
              paymentToken: clientTokenResponse.clientToken,
              bTAFT: payload.deviceData || "",
            };

            if (orderId) {
              const newPayload: CheckoutModels.PaymentPayload = {
                id: orderId,
                customer,
                payment,
                addCutlery: includeCutlery,
                clientPickUpTime: orderTime,
              };
              dispatch(AnalyticsAction.setPaymentType(payload.type));
              performCheckout(newPayload);
            } else {
              // Handle scenario where order ID is not found
            }
          } else {
            reinitBraintree();
          }
        });
      } else {
        reinitBraintree();
      }
    },
    [
      getOrderResponse?.orderId,
      braintree,
      clientTokenResponse?.clientToken,
      dispatch,
      includeCutlery,
      isSavePayment,
      orderTotal,
      performCheckout,
      orderTime,
    ]
  );

  const initPaymentCb = useCallback(
    (isExistingPayMethod = false) => {
      const payment: CheckoutModels.Payment = {};
      const customer: CheckoutModels.Customer = {
        firstName: isAuthSuccess ? (authFirstName ?? "") : firstName,
        lastName: isAuthSuccess ? (authLastName ?? "") : lastName,
        emailAddress: isAuthSuccess ? (authEmail ?? "") : email,
        phoneNumber: isAuthSuccess ? (authPhoneNumber ?? "") : phoneNumber,
      };
      const orderId = getOrderResponse?.orderId;

      if (isAuthSuccess && redeemDollars && loyalty?.cardNumber) {
        payment.gygDollar = {
          cardNumber: loyalty?.cardNumber,
          amount: redeemDollars,
        };
      }

      if (isAuthSuccess && activeReward?.userCouponCode) {
        payment.coupon = { code: activeReward?.userCouponCode };
      }

      if (
        (!clientTokenResponse.clientToken ||
          clientTokenResponse.clientToken !== "") &&
        orderTotal > 0
      ) {
        if (Platform.OS === "web") {
          initPaymentForWeb(payment, customer, orderId);
        } else {
          const items: SheetItem[] =
            getOrderResponse?.basket.basketItems.map((item) => ({
              title: `${item.quantity} x ${item.name}`,
              value: item.price.toFixed(2),
            })) || [];

          const deliveryFee: number = getOrderResponse?.delivery?.fee ?? 0;
          if (deliveryFee > 0) {
            items.push({
              title: t("CheckoutPayment:checkoutDeliveryFeeTitle"),
              value: deliveryFee.toFixed(2),
            });
          }

          if (redeemDollars) {
            items.push({
              title: t("CheckoutPayment:GYGdollarsLabel"),
              value: `-${redeemDollars.toFixed(2)}`,
            });
          }

          getOrderResponse?.basket?.surcharges?.forEach(
            (surcharge: ApiOrderModel.Surcharge) => {
              items.push({
                title: surcharge.name,
                value: surcharge.amount.toFixed(2),
              });
            }
          );

          const basketDiscountAmount: number =
            getOrderResponse?.basket.discountAmount ?? 0;
          if (basketDiscountAmount > 0) {
            items.push({
              title: t("CheckoutPayment:rewardsLabel"),
              value: `-${basketDiscountAmount.toFixed(2)}`,
            });
          }

          const showTax = OrderModules.OrderUtils.displayTax(
            config.version,
            getOrderResponse?.basket.salesTax
          );
          if (showTax) {
            items.push({
              title: t("CheckoutPayment:subTotalLabel"),
              value: getOrderResponse?.basket.subtotal.toFixed(2),
            });
            items.push({
              title: `${t("CheckoutPayment:taxLabel")} ${getOrderResponse?.basket.taxRate}%`,
              value: getOrderResponse?.basket.salesTax.toFixed(2),
            });
          }
          const brainTreeProps: BrainTreeProps = {
            merchantName: t("CheckoutPayment:storeLabel"),
            clientToken: clientTokenResponse.clientToken,
            items,
            orderTotal: orderTotal.toFixed(2),
            currency: config.version === locales.AU ? "AUD" : "USD",
            customer,
            orderId: orderId ?? "",
            isExistingPayMethod,
            existingPayType: braintree.appPreviousPayType,
            isSavePayment,
            partPayment: payment,
            includeCutlery,
            analyticsPaymentType: trackPaymentType,
            initPayment: performCheckout,
            clientPickUpTime: orderTime,
          };

          braintree.appInitBrainTree?.(brainTreeProps);
        }
      } else if (payment && orderTotal === 0) {
        const paymentPayload: CheckoutModels.PaymentPayload = {
          id: orderId ?? "",
          customer,
          payment,
          addCutlery: includeCutlery,
          clientPickUpTime: orderTime,
        };
        const action = isAuthSuccess
          ? CheckoutAction.initPaymentAuthorized
          : CheckoutAction.initPayment;

        dispatch(action({ ...paymentPayload, async: true }));
      } else if (
        (!clientTokenResponse.clientToken ||
          clientTokenResponse.clientToken === "") &&
        getOrderResponse?.orderId
      ) {
        //Request new client token, since the existing one is empty, this should not happen but let's have it here to handle edge cases
        dispatch(
          CheckoutAction.getClientToken({ orderId: getOrderResponse.orderId })
        );
      }
    },
    [
      isAuthSuccess,
      authFirstName,
      authLastName,
      authEmail,
      authPhoneNumber,
      firstName,
      lastName,
      email,
      phoneNumber,
      getOrderResponse?.orderId,
      getOrderResponse?.delivery?.fee,
      getOrderResponse?.basket.basketItems,
      getOrderResponse?.basket.discountAmount,
      getOrderResponse?.basket.surcharges,
      getOrderResponse?.basket.subtotal,
      getOrderResponse?.basket.salesTax,
      getOrderResponse?.basket.taxRate,
      redeemDollars,
      loyalty?.cardNumber,
      activeReward?.userCouponCode,
      clientTokenResponse.clientToken,
      orderTotal,
      dispatch,
      braintree,
      isSavePayment,
      includeCutlery,
      performCheckout,
      t,
      trackPaymentType,
      initPaymentForWeb,
      orderTime,
    ]
  );

  // Cleanup function to teardown the Braintree instance and reset the state when the component unmounts
  useEffect(() => {
    return () => {
      if (Platform.OS === "web") {
        braintree.webBrainTreeInstance?.teardown();
        braintree.webSetBrainTreeInstance?.(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initPaymentCb;
};
