import React from "react";
import { Animated, ScrollView } from "react-native";

import {
  CartReduxModels,
  LoyaltyModels,
  MenuModels,
  ProductConst,
  ProductModuleModel,
  ProductUtils,
} from "../../../";
import ProductDetail from "../../../components/Products/ProductDetail";
import { ProductDetailProps } from "../../../modules/Products/model";

export interface CustomizableProductListHeaderProps {
  customizableProductInfoProps: ProductDetailProps;
  fadeAnimationProductDetail: Animated.Value | undefined;
  onReset: () => void;
  customizeOptions: ProductModuleModel.CustomizableOptionState;
  category: MenuModels.Category;
  editMode: ProductConst.EditMode;
  setQuantity: (qty: number) => void;
  goBack: () => void;
  quantityDisabled?: boolean;
  allergenText?: string;
  cartItems?: CartReduxModels.CartItem[];
  coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null | undefined;
}

const CustomizableProductListHeader = (
  props: CustomizableProductListHeaderProps
): JSX.Element => {
  const {
    customizableProductInfoProps,
    fadeAnimationProductDetail,
    onReset,
    customizeOptions,
    category,
    editMode,
    setQuantity,
    goBack,
    quantityDisabled,
    allergenText,
    cartItems,
    coffeeLoyalty,
  } = props;

  const isResetValid = (
    optionsCustomise: ProductModuleModel.CustomizableOptionState
  ) => {
    for (const option in optionsCustomise) {
      if (optionsCustomise[option]?.length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <ScrollView>
      <ProductDetail
        fadeAnimationProductDetail={fadeAnimationProductDetail}
        onReset={onReset}
        resetDisabled={Boolean(!isResetValid(customizeOptions))}
        key={category.name}
        gobackMode={true}
        setQuantity={
          editMode === ProductConst.EditMode.UPDATE_BUNDLE_ITEM ||
          editMode === ProductConst.EditMode.UPDATE_TACO_ITEM ||
          ProductUtils.getProductRoute(category?.templateId as string) ===
            ProductConst.ProductRoute.ComplexCustomisableWithMeal
            ? undefined
            : setQuantity
        }
        onGoBack={goBack}
        quantityDisabled={quantityDisabled}
        hidePrice={editMode === ProductConst.EditMode.UPDATE_MIAM_ITEM}
        {...customizableProductInfoProps}
        allergenText={allergenText}
        cartItems={cartItems}
        category={category}
        editMode={editMode}
        coffeeLoyalty={coffeeLoyalty}
      />
    </ScrollView>
  );
};

export default React.memo(CustomizableProductListHeader);
