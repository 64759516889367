import "./styles.scss";

import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import config from "config";
import CloseIcon from "gyg_common/components/assets/icons/icon_close.svg";
import Badge from "gyg_common/components/Badge";
import IconButton from "gyg_common/components/IconButton";
import { Screens } from "navigation/const";
import { AuthMode } from "views/containers/Auth/LoginContainer";

export interface HeaderDropdownProps {
  isUserAuthenticated: boolean;
  onClose: (e?: React.MouseEvent<HTMLDivElement>) => void;
  showLogoutModal: () => void;
  onLoginClick: () => void;
  onRegisterClick: () => void;
  showDeleteAccountModal: () => void;
  countOfNotifications?: number | undefined;
}

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  onClose,
  isUserAuthenticated,
  showLogoutModal,
  onLoginClick,
  onRegisterClick,
  showDeleteAccountModal,
  countOfNotifications,
}): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [listHeight, setListHeight] = useState<string>("100%");
  const { isTabletScreen } = useMediaQuery();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  /**
   * Gets exact height of the list so it can be scrollable inside of absolute parent.
   */
  const getListHeight = () => {
    const list = dropdownRef.current?.children[2].children[0];
    const listChildrenLenght = list?.getElementsByClassName(
      "header-dropdown__item"
    ).length;
    if (listChildrenLenght) {
      const height = listChildrenLenght * 64;
      setListHeight(`${height}px`);
    }
  };

  useEffect(() => {
    getListHeight();
  }, []);

  return (
    <div
      className={`header-dropdown ${
        isTabletScreen && pathname === Screens.Order
          ? "header-dropdown--left"
          : ""
      }`}
      ref={dropdownRef}>
      <div className='header-dropdown__extra' />
      <div className='header-dropdown__close'>
        <IconButton
          testID='header-dropdown-close'
          iconImage={CloseIcon}
          onPress={(e) => onClose(e)}
        />
      </div>
      <div className='header-dropdown__wrapper'>
        <ul style={{ minHeight: listHeight }}>
          {isUserAuthenticated ? (
            <>
              <li className='header-dropdown__item'>
                <Link
                  className='header-dropdown__link'
                  to={Screens.UserProfile}>
                  {t("Dashboard:dropdownAccount")}
                </Link>
              </li>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <Link
                  className='header-dropdown__link'
                  to={Screens.Notifications}>
                  {t("Dashboard:dropdownNotifications")}
                </Link>
              </li>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <Link className='header-dropdown__link' to={Screens.MyGomex}>
                  {t("Dashboard:dropdownMyGomex")}
                  {!!countOfNotifications && (
                    <Badge label={`${countOfNotifications}`} />
                  )}
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className='header-dropdown__item'>
                <Link
                  onClick={onLoginClick}
                  className='header-dropdown__link'
                  to={{
                    pathname: `${Screens.Login}/${AuthMode.LOGIN}`,
                  }}
                  state={{
                    loginNavigatesTo: pathname,
                  }}>
                  {t("Dashboard:dropdownLogin")}
                </Link>
              </li>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <Link
                  onClick={onRegisterClick}
                  className='header-dropdown__link'
                  to={{
                    pathname: `${Screens.Login}/${AuthMode.REGISTER}`,
                  }}
                  state={{
                    registrationNavigatesTo: pathname,
                  }}>
                  {t("Dashboard:dropdownJoin")}
                </Link>
              </li>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <a
                  className='header-dropdown__link'
                  target='_blank'
                  rel='noreferrer'
                  href={config.brandSiteUrl}>
                  {t("Dashboard:dropdownAboutGomex")}
                </a>
              </li>
            </>
          )}
          <div className='gradient_line--dark' />
          <li className='header-dropdown__item'>
            <Link className='header-dropdown__link' to={Screens.RecentOrders}>
              {t("Dashboard:dropdownOrders")}
            </Link>
          </li>
          <div className='gradient_line--dark' />
          <li className='header-dropdown__item'>
            <Link
              className='header-dropdown__link'
              to={{
                pathname: Screens.Restaurants,
              }}
              state={{
                previousScreen: pathname,
              }}>
              {t("Dashboard:dropdownLocations")}
            </Link>
          </li>
          <div className='gradient_line--dark' />
          <li className='header-dropdown__item'>
            <a
              className='header-dropdown__link'
              target='_blank'
              rel='noreferrer'
              href={config.allergenStatementSiteUrl}>
              {t("Dashboard:dropdownNutrition")}
            </a>
          </li>
          <div className='gradient_line--dark' />
          <li className='header-dropdown__item'>
            <a
              className='header-dropdown__link'
              target='_blank'
              rel='noreferrer'
              href={config.contactUsUrl}>
              {t("Dashboard:dropdownSupport")}
            </a>
          </li>
          {isUserAuthenticated && (
            <>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <button
                  className='header-dropdown__link'
                  onClick={showLogoutModal}>
                  {t("Dashboard:dropdownLogout")}
                </button>
              </li>
              <div className='gradient_line--dark' />
              <li className='header-dropdown__item'>
                <button
                  className='header-dropdown__link'
                  onClick={showDeleteAccountModal}>
                  {t("UserManagement:deleteAccountHeader")}
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderDropdown;
