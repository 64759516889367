import { DependencyList, useEffect, useState } from "react";

/**
 * Similar to `useEffect`, the only difference is that the effect will be called until `true` is returned.
 */
export function useUntilHandled(effect: () => boolean, deps: DependencyList) {
  const [isProcessed, setProcessed] = useState(false);
  useEffect(() => {
    if (isProcessed) return;
    setProcessed(effect());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect, isProcessed, ...deps]);
}
