import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ApiUpsellModel,
  CartReduxAction,
  MenuModels,
  ProductConst,
  ProductUtils,
  RootState,
  UpsellReduxActions,
  UpsellUtils,
} from "gyg_common";
import { UpsellSource } from "gyg_common/modules/Upsell/model";
import { trackUpsellItem } from "gyg_common/modules/Upsell/utils";
import { UpsellSection } from "views/components/Order/UpsellSection";
import ProductModal from "views/components/Products/ProductModal";

interface UpsellSectionContainerProps {
  smallCards?: boolean;
}

export const UpsellSectionContainer: React.FC<UpsellSectionContainerProps> = ({
  smallCards,
}) => {
  const dispatch = useDispatch();

  const { currentUser } = useSelector((s: RootState) => s.login);
  const { currentOrderId } = useSelector((s: RootState) => s.order);
  const { menuStructure, validMenuSection, menuSectionEnabled } = useSelector(
    (s: RootState) => s.menu
  );
  const { upsellItems, upsellItemsFromConfigInCart } = useSelector(
    (s: RootState) => s.upsell
  );

  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProductType, setSelectedProductType] =
    useState<ProductConst.ProductRoute>(
      ProductConst.ProductRoute.SimpleCustomisable
    );
  const [selectedMenuItem, setSelectedMenuItem] = useState<
    MenuModels.Category | MenuModels.Product | MenuModels.MultiPart
  >();

  const onItemPress = (item: MenuModels.MenuListItemProps) => {
    trackUpsellItem({
      userId: currentUser?.brazeId as string,
      basketId: currentOrderId,
      upsellItems: [item],
      source: UpsellSource.InCart,
    });

    if (item.category) {
      const productRoute = ProductUtils.getProductRoute(
        item.category?.templateId as string
      );
      if (productRoute) {
        dispatch(CartReduxAction.clearMIAMItem());
        setSelectedProductType(productRoute);
        setSelectedMenuItem(item.category);
        setShowProductModal(true);
      }
    } else if (item.multiPart) {
      dispatch(CartReduxAction.clearBundleItem());
      setSelectedProductType(ProductConst.ProductRoute.Bundles);
      setSelectedMenuItem(item.multiPart);
      setShowProductModal(true);
    } else {
      setSelectedProductType(ProductConst.ProductRoute.NonCustomization);
      setSelectedMenuItem(item.product);
      setShowProductModal(true);
    }
  };
  useEffect(() => {
    dispatch(
      UpsellReduxActions.getUpsellFromConfig(ApiUpsellModel.UpsellType.INCART)
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(UpsellReduxActions.setUpsellItems([]));

    if (
      validMenuSection &&
      menuSectionEnabled &&
      upsellItemsFromConfigInCart?.length
    ) {
      const convertedUpsellItems = UpsellUtils.convertToMenuSection(
        upsellItemsFromConfigInCart,
        validMenuSection,
        menuSectionEnabled
      );
      if (convertedUpsellItems.length > 0) {
        dispatch(UpsellReduxActions.setUpsellItems(convertedUpsellItems));
        dispatch(UpsellReduxActions.setUserShownUpsell(true));
      }
    }
  }, [
    validMenuSection,
    menuSectionEnabled,
    upsellItemsFromConfigInCart,
    dispatch,
  ]);

  return upsellItems.length > 0 ? (
    <>
      <UpsellSection
        smallCards={smallCards}
        upsellItems={upsellItems}
        onItemPress={onItemPress}
      />
      <ProductModal
        isUpsell
        commonSection={
          menuStructure
            ? menuStructure.commonSections.find(
                (n) =>
                  n.id ===
                  (selectedMenuItem as MenuModels.Category)?.commonSection?.[0]
              )
            : undefined
        }
        editMode={ProductConst.EditMode.ADD_CART_ITEM}
        productType={selectedProductType}
        isVisible={showProductModal}
        onClose={() => {
          setShowProductModal(false);
        }}
        category={selectedMenuItem}
      />
    </>
  ) : null;
};
