import "./style.scss";

import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputType } from "gyg_common";
import editIcon from "gyg_common/components/assets/icons/icon_edit.png";

export interface FormInputWithButtonProps {
  inputLabel: string;
  onChangeText: (text: string) => void;
  error: boolean;
  errorMsg?: string;
  value?: string;
  isBorderBottomOnly?: boolean;
  placeHolder?: string;
  onEdit?: () => void;
  isShowLabel?: boolean;
  isLabelBold?: boolean;
  disabled?: boolean;
  type?: InputType;
  hideEdit?: boolean;
}

// TODO: Port
const FormInputWithButton: React.FC<FormInputWithButtonProps> = ({
  inputLabel,
  error,
  errorMsg,
  value,
  isBorderBottomOnly,
  placeHolder,
  isShowLabel,
  isLabelBold,
  disabled,
  type,
  onChangeText,
  onEdit,
  hideEdit,
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div>
      {isShowLabel && (
        <p
          data-testid={"input-label"}
          className={
            error
              ? "input-label__error"
              : isLabelBold
                ? "input-label__bold"
                : "input-label"
          }>
          {inputLabel}
        </p>
      )}
      <div
        className={
          error
            ? "input-container__error"
            : isBorderBottomOnly
              ? "input-container-with-button "
              : focus
                ? "input-container__focus"
                : "input-container"
        }>
        <input
          placeholder={placeHolder}
          className={
            isBorderBottomOnly ? "input input__zeroleftpadding" : "input"
          }
          data-testid={inputLabel}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeText(e.target.value)
          }
          onFocus={() => {
            setFocus(true);
          }}
          aria-label={inputLabel}
          disabled={isBorderBottomOnly}
          onBlur={() => setFocus(false)}
          defaultValue={value}
          value={value}
          maxLength={type === InputType.BIRTHDAY ? 10 : undefined}
        />

        {isBorderBottomOnly && !hideEdit && (
          <button
            className='button'
            onClick={() => {
              if (!disabled && onEdit) {
                onEdit();
              }
            }}>
            <img
              className={disabled ? "logo--pencil-disabled" : "logo--pencil"}
              data-testid={"edit-" + inputLabel}
              src={editIcon}
            />

            <a className={disabled ? "button-label-disabled" : "button-label"}>
              {!value
                ? t("UserManagement:addLabel")
                : t("UserManagement:editLabel")}
            </a>
          </button>
        )}
      </div>

      {error ? (
        <p className='error-message' data-testid={"error"}>
          {errorMsg ? errorMsg : t("CheckoutPayment:mandatoryField")}
        </p>
      ) : (
        <div className='error-message__invisible' />
      )}
    </div>
  );
};

FormInputWithButton.defaultProps = {
  isShowLabel: true,
};

export default FormInputWithButton;
