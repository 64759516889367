import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import config from "config";
import {
  AnalyticsConst,
  AnalyticsInstance,
  BraintreeReduxActions,
  CartReduxAction,
  CheckoutAction,
  LoyaltyActions,
  LoyaltyModels,
  NavigationReduxAction,
  OrderReduxAction,
  RootState,
  StoreReduxAction,
} from "gyg_common";
import { ErrorModal } from "gyg_common/components/Error/ErrorView/ErrorModal";
import useInterval from "gyg_common/hooks/useInterval";
import {
  OrderFinalisationStatusType,
  OrderFulfilmentStatusType,
  OrderProgressEnum,
} from "gyg_common/redux_store/checkout/model";
import { CollectionType } from "gyg_common/redux_store/order/models";
import { removeOrderIsInProgressValueFromLocalStorage } from "gyg_common/services/api/checkout";
import { GetOrderResponse } from "gyg_common/services/api/order/model";
import { Screens } from "navigation/const";
import { StoreLinkModal } from "views/components/Stores/StoreLinkModal";

import colours from "@/styles/colours";
import OrderDeliveryStatus from "@/views/components/OrderDeliveryStatus";
import OrderStatus from "@/views/components/OrderStatus";
import StoreDetails from "@/views/components/Stores/NotWithDelivery/StoreDetails";

const STATUS_POLL_TIMEOUT_DELAY = 5000;
const STATUS_POLL_TIMEOUT_DELAY_WHEN_IN_PROGRESS = 30000;
const GET_LOYALTY_DELAY = 5000; //Delay to get loyalty after order become in progress

export interface OrderStatusContainerProps {
  previousScreen?: string;
  futureLoyalty?: LoyaltyModels.Loyalty | null;
  futureCoffeeLoyalty?: LoyaltyModels.CoffeeLoyalty | null;
}

const OrderStatusContainer: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { paymentResponse } = useSelector((s: RootState) => s.checkout);
  const { isAuthSuccess } = useSelector((s: RootState) => s.login);
  const {
    getOrderError,
    getOrderLoading,
    orderStatus: status,
    addToAccountLoading,
    currentOrderId,
    getOrderByIdError,
  } = useSelector((s: RootState) => s.order);
  const [orderStatus, setOrderStatus] = useState<GetOrderResponse | null>(null);
  const { guestLoyalty } = useSelector((s: RootState) => s.guest);
  const { loyalty, coffeeLoyalty } = useSelector((s: RootState) => s.loyalty);
  const { showStoreInfo, selectedStore } = useSelector(
    (s: RootState) => s.store
  );
  const { isBrazeInitialised } = useSelector((s: RootState) => s.user);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(
    !!getOrderError?.statusCode
  );
  const [loyaltySource, setLoyaltySource] =
    useState<LoyaltyModels.Loyalty | null>(null);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [storeShareLink, setStoreShareLink] = useState<string>("");

  const [orderStatusLabel, setOrderStatusLabel] = useState(
    t("OrderStatus:loading")
  );
  const [canShowOrderNumber, setCanShowOrderNumber] = useState(false);
  const [bgColor, setBgColor] = useState(colours.yellow);
  const [shouldPoll, setShouldPoll] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [statusPollTimeOutDelay, setStatusPollTimeOutDelay] = useState(
    STATUS_POLL_TIMEOUT_DELAY
  );

  const isCompletedStatus = orderStatus
    ? orderStatus.orderProgressStatus.orderProgressCode ===
        OrderProgressEnum.READY ||
      orderStatus.orderProgressStatus.orderProgressCode ===
        OrderProgressEnum.COMPLETED
    : false;

  const isDelivery =
    paymentResponse?.orderCollectionType === CollectionType.DELIVERY;
  const trackingUrlExpiresAt = orderStatus?.delivery?.trackingUrlExpiresAt;

  const futureLoyalty = state?.futureLoyalty;
  const futureCoffeeLoyalty = state?.futureCoffeeLoyalty;

  const [isOrderInProgress, setOrderInProgress] = useState(false);
  const [loyaltyInProgressRequested, setLoyaltyInProgressRequested] =
    useState(false);

  const onOpenModal = () => {
    dispatch(StoreReduxAction.setStoreInfo(selectedStore));
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const handleOrder = () => {
    navigate(Screens.Menu);
  };

  const onShareLinkClick = (link: string) => {
    setShowLinkModal(true);
    setStoreShareLink(link);
    setShowModal(false);
  };

  const goToLogin = () => {
    navigate(Screens.Login, {
      state: { loginNavigatesTo: Screens.OrderStatus },
    });
  };

  const refreshUserLoyalty = useCallback(() => {
    if (isAuthSuccess && !isCompletedStatus) {
      dispatch(LoyaltyActions.getUserLoyalty());
    }
  }, [dispatch, isAuthSuccess, isCompletedStatus]);

  const refreshUserLoyaltyAndRecentOrders = useCallback(() => {
    if (isAuthSuccess) {
      if ((isDelivery && !isCompletedStatus) || !isDelivery) {
        dispatch(OrderReduxAction.getAuthenticatedOrders());
      }
      dispatch(LoyaltyActions.getUserRewards());
      refreshUserLoyalty();
    }
  }, [
    dispatch,
    isAuthSuccess,
    isCompletedStatus,
    isDelivery,
    refreshUserLoyalty,
  ]);

  const getOrderStatus = useCallback(() => {
    if (paymentResponse) {
      dispatch(
        OrderReduxAction.getOrderStatus({
          orderId: paymentResponse.order.orderId,
        })
      );
    }
  }, [dispatch, paymentResponse]);

  /**
   * Analytics view
   */
  useEffect(() => {
    if (isBrazeInitialised) {
      AnalyticsInstance.trackView({
        page_name: "order_status",
        page_type: "order_status_view",
      });
    }
  }, [isBrazeInitialised]);

  useEffect(() => {
    if (!isFailed && isDelivery) {
      AnalyticsInstance.trackEvent(AnalyticsConst.Events.OrderStatus, {});
    }
  }, [isFailed, isDelivery]);

  useEffect(() => {
    const componentWillUnmount = () => {
      dispatch(OrderReduxAction.clearOrderResponse());
      dispatch(CheckoutAction.clearClientToken());
      dispatch(OrderReduxAction.clearOrderStatus());
      refreshUserLoyaltyAndRecentOrders();
    };

    return componentWillUnmount;
  }, [dispatch, refreshUserLoyaltyAndRecentOrders]);

  useEffect(() => {
    removeOrderIsInProgressValueFromLocalStorage();
  }, []);

  useEffect(() => {
    if (status && !getOrderLoading && !orderStatus) {
      setOrderStatus(status);
      dispatch(LoyaltyActions.updateRedeemDollars(null));
      dispatch(CartReduxAction.clearCart());
      dispatch(OrderReduxAction.clearOrderStatus());
      dispatch(OrderReduxAction.clearOrderResponse());
      dispatch(CheckoutAction.resetPayment());
      dispatch(CheckoutAction.clearClientToken());
      dispatch(BraintreeReduxActions.clearPaymentPayload());
      dispatch(NavigationReduxAction.resetNavigationState());
    }
  }, [dispatch, getOrderLoading, isAuthSuccess, orderStatus, status]);

  // Gets the most up to date loyalty balance.
  useEffect(() => {
    if (loyalty) {
      setLoyaltySource(loyalty);
    }
    if (loyaltyInProgressRequested && loyalty) {
      setLoyaltyInProgressRequested(false);
      setOrderInProgress(true);
    }
  }, [loyalty, loyaltyInProgressRequested]);

  // Claiming orders in login view depends on currentOrderId.
  // When entering order status from dashboard, currentOrderId needs to be updated.
  useEffect(() => {
    //initial get order status when screen is just opened
    getOrderStatus();
    if (paymentResponse?.order.orderId) {
      dispatch(
        OrderReduxAction.updateCurrentOrderId(paymentResponse?.order.orderId)
      );
    }
  }, [dispatch, getOrderStatus, paymentResponse]);

  /**
   * Gets order details when no payment response in the redux state.
   * Scenario after social login/register.
   * Or redirects to Dashboard.
   */
  useEffect(() => {
    if (
      isAuthSuccess &&
      !!currentOrderId &&
      !paymentResponse &&
      !getOrderByIdError
    ) {
      if (!addToAccountLoading) {
        dispatch(OrderReduxAction.getOrderById(currentOrderId));
      }
    } else if (!paymentResponse) {
      // redirect to dashboard when entering order status and no payment response in the state
      // scenario when navigating directly from the url
      // or getting order by id fails
      navigate(Screens.Dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrderId,
    paymentResponse,
    isAuthSuccess,
    getOrderByIdError,
    addToAccountLoading,
  ]);

  useInterval(
    async () => {
      getOrderStatus();
    },
    shouldPoll ? statusPollTimeOutDelay : null
  );

  useEffect(() => {
    return () => {
      setShouldPoll(false);
    };
  }, [setShouldPoll]);

  const setOrderFailedState = () => {
    setShouldPoll(false);
    setCanShowOrderNumber(true);
    setIsFailed(true);
    setBgColor(colours.red);
  };

  useEffect(() => {
    if (!status) {
      return;
    }
    setOrderStatus(status);
    setOrderStatusLabel(status.orderProgressStatus.orderProgressDisplayName);
    setBgColor(status.orderProgressStatus.orderProgressDisplayColour);

    if (
      status.orderFinalisationStatus !== OrderFinalisationStatusType.CREATED &&
      status.orderFinalisationStatus !== OrderFinalisationStatusType.RECEIVED &&
      !isCompletedStatus
    ) {
      //Clear loyalty state before getting new loyalty
      if (futureLoyalty && isAuthSuccess) {
        dispatch(LoyaltyActions.setUserLoyaltyToState(null));
      }
      setTimeout(() => {
        refreshUserLoyalty();
        setLoyaltyInProgressRequested(true);
      }, GET_LOYALTY_DELAY);
    }

    switch (status.orderProgressStatus.orderProgressCode) {
      case OrderProgressEnum.READY:
      case OrderProgressEnum.COMPLETED:
        setShouldPoll(false);
        setCanShowOrderNumber(true);
        break;
      case OrderProgressEnum.FAILED:
        setOrderFailedState();
        break;
      case OrderProgressEnum.ABANDONED:
        setShouldPoll(false);
        setCanShowOrderNumber(true);
        setIsFailed(true);
        break;
      case OrderProgressEnum.CREATED:
        setStatusPollTimeOutDelay(STATUS_POLL_TIMEOUT_DELAY);
        setShouldPoll(true);
        setCanShowOrderNumber(true);
        break;
      case OrderProgressEnum.INPROGRESS:
        setShouldPoll(true);
        if (
          !status.delivery &&
          [
            OrderFulfilmentStatusType.RECEIVED,
            OrderFulfilmentStatusType.RECEIVED_AT_SITE,
          ].includes(status.orderFulfilmentStatus)
        ) {
          setCanShowOrderNumber(true);
          setStatusPollTimeOutDelay(STATUS_POLL_TIMEOUT_DELAY_WHEN_IN_PROGRESS);
        } else {
          setCanShowOrderNumber(false);
        }
        break;
      case OrderProgressEnum.RECEIVED:
        setShouldPoll(true);
        setCanShowOrderNumber(true);
        refreshUserLoyalty();
        break;
      case OrderProgressEnum.RECEIVEDATSITE:
        setShouldPoll(true);
        setCanShowOrderNumber(true);
        refreshUserLoyalty();
        break;
      case OrderProgressEnum.AWAITING_PICKUP:
        setShouldPoll(false);
        setCanShowOrderNumber(true);
        break;
    }

    if (status.orderFinalisationStatus === OrderFinalisationStatusType.FAILED) {
      setOrderFailedState();
    }
  }, [
    status,
    dispatch,
    isCompletedStatus,
    futureLoyalty,
    isAuthSuccess,
    refreshUserLoyalty,
  ]);

  const getLoyaltyValue = (
    isOrderInProgress: boolean,
    futureLoyalty: LoyaltyModels.Loyalty | null | undefined,
    loyaltySource: LoyaltyModels.Loyalty | null
  ) => {
    return !isOrderInProgress && futureLoyalty
      ? (futureLoyalty ?? loyaltySource)
      : loyaltySource;
  };

  const getCoffeeLoyaltyValue = (
    isOrderInProgress: boolean,
    futureCoffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null | undefined,
    coffeeLoyalty: LoyaltyModels.CoffeeLoyalty | null
  ) => {
    return !isOrderInProgress && futureCoffeeLoyalty
      ? (futureCoffeeLoyalty ?? coffeeLoyalty)
      : coffeeLoyalty;
  };

  const currentLoyalty = getLoyaltyValue(
    isOrderInProgress,
    futureLoyalty,
    loyaltySource
  );
  const currentCoffeeLoyalty = getCoffeeLoyaltyValue(
    isOrderInProgress,
    futureCoffeeLoyalty,
    coffeeLoyalty
  );

  return isDelivery && !isFailed ? (
    <OrderDeliveryStatus
      failure={getOrderError}
      trackingUrlExpiresAt={trackingUrlExpiresAt}
      orderStatus={orderStatus}
    />
  ) : (
    <>
      <ErrorModal
        heading={getOrderError?.heading}
        message={getOrderError?.message}
        isVisible={showError}
        onClose={() => setShowError(false)}
      />

      <OrderStatus
        loyaltyBonusPoints={orderStatus?.loyalty?.totalBonusPoints ?? 0}
        loyaltyBonusDollars={orderStatus?.loyalty?.totalBonusDollars ?? 0}
        data={paymentResponse ?? undefined}
        loyalty={currentLoyalty ?? undefined}
        coffeeLoyalty={currentCoffeeLoyalty}
        guestLoyalty={guestLoyalty}
        locale={config.version}
        orderStatusLabel={{
          bgColor: bgColor,
          title: orderStatusLabel,
          titleColor: isFailed ? "white" : undefined,
        }}
        canShowOrderNumber={canShowOrderNumber}
        isFailed={isFailed}
        status={orderStatus}
        isLoggedIn={isAuthSuccess}
        navigateToStoreDetails={() => onOpenModal()}
        goToLogin={goToLogin}
      />

      {/* Only if clipboard is not available */}
      <StoreLinkModal
        isVisible={showLinkModal}
        link={storeShareLink}
        onClose={() => setShowLinkModal(false)}
      />
      <StoreDetails
        showModal={showModal}
        store={showStoreInfo}
        handleOrderFromRestaurant={() => handleOrder()}
        onCloseModal={onCloseModal}
        onShareLinkClick={onShareLinkClick}
      />
    </>
  );
};

export default OrderStatusContainer;
