import ShareIcon from "assets/icons/icon_share.png";
import branch, { BranchError, DeepLinkData } from "branch-sdk";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";

import {
  AnalyticsConst,
  AnalyticsInstance,
  BranchConst,
  StoreModules,
  StoreReduxModels,
} from "gyg_common";
import CloseIcon from "gyg_common/components/assets/icons/icon_close.svg";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import IconButton from "gyg_common/components/IconButton";
import MiddleModal from "gyg_common/components/modals/MiddleModal";
import { GetDirections, StaticMap } from "gyg_common/components/StaticMap";
import {
  ServicesList,
  StoreAddress,
  StoreHead,
  StoreTradingHours,
} from "gyg_common/components/StoreDetails";
import { Screens } from "navigation/const";
import AnimatedButton from "views/components/shared/Buttons/AnimatedButton";

export interface StoreDetailsProps {
  showModal: boolean;
  store: StoreReduxModels.Store | null;
  handleOrderFromRestaurant: (store: StoreReduxModels.Store) => void;
  onCloseModal: () => void;
  onShareLinkClick: (link: string) => void;
}

interface LinkState {
  saved: boolean;
  copy: string;
}

const StoreDetails: React.FC<StoreDetailsProps> = ({
  store,
  showModal,
  onCloseModal,
  handleOrderFromRestaurant,
  onShareLinkClick,
}): JSX.Element => {
  const { t } = useTranslation();
  const { isTabletScreen } = useMediaQuery();
  const [linkState, setLinkState] = useState<LinkState>({
    saved: false,
    copy: t("StoreSearch:link"),
  });
  const [viewHeight, setViewHeight] = useState<string>("auto");

  const storeOpenStatus: StoreReduxModels.StoreOpenTime | null =
    store &&
    StoreModules.StoreUtils.checkStoreOpenHours(
      store?.tradingHours ?? [],
      store.timeZoneInfo.storeTimeZone
    );

  const linkData: DeepLinkData = {
    data: {
      path: BranchConst.BranchPaths.Restaurant,
      id: store?.id,
      $og_title: t("Branch:shareRestaurantDetailsTitle"),
      $og_description:
        t("Branch:shareRestaurantDetailsDescription") + store?.name,
      $fallback_url: `${window.location.origin}${Screens.Restaurants}/${store?.id}`,
    },
  };

  /**
   * Creates restaurant branch link and copies it to clipboard.
   */
  const copyStoreLink = () => {
    try {
      branch.link(linkData, function (_: BranchError, link: string | null) {
        if (link) {
          navigator.clipboard
            .writeText(link)
            .then(() => {
              setLinkState({
                saved: true,
                copy: t("StoreSearch:linkCopied"),
              });
            })
            .catch(() => {
              onShareLinkClick(link);
            });

          AnalyticsInstance.trackEvent(AnalyticsConst.Events.ShareRestaurant, {
            store_id: store?.id,
            store_name: store?.name,
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const setModalHeight = () => {
    setViewHeight(`${window.innerHeight}px`);
  };

  const onOrderFromRestaurantClick = () => {
    if (store) {
      handleOrderFromRestaurant(store);
    }
    onCloseModal();
  };

  const onShareClick = () => {
    try {
      branch.link(linkData, function (_: BranchError, link: string | null) {
        if (link) {
          onShareLinkClick(link);

          AnalyticsInstance.trackEvent(AnalyticsConst.Events.ShareRestaurant, {
            store_id: store?.id,
            store_name: store?.name,
          });
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Restores default link state after animation is done.
   */
  useEffect(() => {
    if (linkState.saved) {
      const linkTimeout = setTimeout(() => {
        setLinkState({ saved: false, copy: t("StoreSearch:link") });
      }, 2000);

      return () => clearTimeout(linkTimeout);
    }
    // TODO: To check and revist later
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [linkState.saved]);

  useEffect(() => {
    setModalHeight();
    window.addEventListener("scroll", setModalHeight);
    window.addEventListener("resize", setModalHeight);
    return () => {
      window.removeEventListener("scroll", setModalHeight);
      window.removeEventListener("resize", setModalHeight);
    };
  }, []);

  return (
    <MiddleModal isVisible={!!(showModal && store)} testId='store-details'>
      {store && (
        <div
          style={{ maxHeight: viewHeight }}
          className='restaurants__details'
          id={StoreModules.StoreModels.StoresTestingId.detailsModal}
          data-testid={StoreModules.StoreModels.StoresTestingId.detailsModal}>
          <div className='restaurants__details-header'>
            <div>
              <StoreHead store={store} />
            </div>
            <IconButton
              iconImage={CloseIcon}
              onPress={onCloseModal}
              withShadow={false}
            />
          </div>
          <div className='restaurants__details-row'>
            <div className='restaurants__details-col'>
              {store.tags.length > 0 && (
                <div>
                  <p className='restaurants__details-headline'>
                    {t("StoreSearch:services")}
                  </p>
                  <ServicesList tags={store.tags} />
                </div>
              )}
              {store?.tradingHours?.length > 0 && (
                <StoreTradingHours
                  tradinghours={store.tradingHours}
                  isStoreOpen={storeOpenStatus?.isOpen ?? false}
                  isStoreClosingSoon={storeOpenStatus?.closingSoon ?? false}
                  isStoreOpeningSoon={storeOpenStatus?.openingSoon ?? false}
                />
              )}
            </div>
            <div className='restaurants__details-col'>
              <div>
                <p className='restaurants__details-headline'>
                  {t("StoreSearch:location")}
                </p>
                <StoreAddress
                  address1={store.address1}
                  address2={store.address2}
                  city={store.city}
                  postCode={store.postCode}
                  state={store.state}
                />
                <div className='restaurants__details-map'>
                  <StaticMap
                    lat={parseFloat(store.latitude)}
                    lng={parseFloat(store.longitude)}
                  />
                </div>
                <div className='restaurants__details-buttonsRow'>
                  <div className='restaurants__details-button'>
                    {navigator.clipboard ? (
                      <AnimatedButton
                        icon={ShareIcon as ImageSourcePropType}
                        onClick={copyStoreLink}
                        defaultTitle={t("StoreSearch:link")}
                        animatedTitle={t("StoreSearch:linkCopied")}
                        testId={
                          StoreModules.StoreModels.StoresTestingId
                            .shareStoreButton
                        }
                        animate={linkState.saved}
                      />
                    ) : (
                      <TertiaryButton
                        icon={ShareIcon as ImageSourcePropType}
                        title={"Share Link"}
                        onClick={onShareClick}
                      />
                    )}
                  </div>
                  <div className='restaurants__details-button'>
                    <GetDirections
                      latitude={`${store.latitude}`}
                      longitude={`${store.longitude}`}
                      id={store.id}
                      name={store.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='restaurants__details-footer'>
            <div className='restaurants__details-col' />
            <div className='restaurants__details-col'>
              <div className='sticky-button'>
                <PrimaryBlackButton
                  border={!isTabletScreen}
                  buttonName={t("StoreSearch:orderStoreBtn")}
                  onClick={onOrderFromRestaurantClick}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </MiddleModal>
  );
};

export default StoreDetails;
