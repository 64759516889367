import cartIcon from "assets/icons/icon_cart.png";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useNavigate } from "react-router-dom";

import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import CardSticker from "gyg_common/components/CardSticker";
import { Screens } from "navigation/const";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  contentWrapper: {
    padding: 16,
  },
  outer: {
    marginBottom: 16,
  },
  container: {
    flex: 1,
  },
  imageWrapper: {
    width: 44,
    height: 44,
    borderRadius: 22,
    backgroundColor: "#ffd204",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
  wrapperRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  cartImage: {
    width: 30,
    height: 30,
  },
  bodyTextStyle: {
    ...Typography.body,
    marginBottom: 16,
  },
});
export const EmptyCartViewMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToMenu = () => navigate(Screens.Menu);

  return (
    <View style={styles.outer}>
      <CardSticker>
        <View style={styles.contentWrapper}>
          <View style={styles.wrapperRow}>
            <View style={styles.imageWrapper}>
              <Image
                source={cartIcon as ImageSourcePropType}
                style={styles.cartImage}
              />
            </View>
            <Text style={Typography.titleThree}>
              {t("CheckoutPayment:emptyCartTitle")}
            </Text>
          </View>
          <Text style={styles.bodyTextStyle}>
            {t("CheckoutPayment:emptyCartBodyText")}
          </Text>
          <PrimaryBlackButton
            testId='ViewMenuButton'
            buttonName={t("CheckoutPayment:viewMenu")}
            onClick={goToMenu}
          />
        </View>
      </CardSticker>
    </View>
  );
};
