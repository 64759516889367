import "./style.scss";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { StringValidators, UserReduxModels } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { EditModeType } from "views/containers/UserManagement/UserProfileContainer";

import FormInputWithButton from "../shared/FormInput/FormInputWithButton";

import { getFullName } from "@/modules/utils";

export interface UpdateUserNameProps {
  userProfile: UserReduxModels.UserProfile;
  onEditMode: EditModeType | undefined;
  setEditMode: (type: EditModeType | undefined) => void;
  onUpdateUserAccount: (
    userAccountRequest: UserReduxModels.UserAccountRequest
  ) => void;
}

export const UpdateUserName: React.FC<UpdateUserNameProps> = ({
  userProfile,
  onEditMode,
  setEditMode,
  onUpdateUserAccount,
}) => {
  const { t } = useTranslation();
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(userProfile?.firstName);
  const [lastName, setLastName] = useState<string>(userProfile?.lastName);

  const isOnEditMode = onEditMode === EditModeType.NAME;

  const firstNameHandler = (text: string) => {
    setFirstName(text);
    setFirstNameError(false);
  };

  const lastNameHandler = (text: string) => {
    setLastName(text);
    setLastNameError(false);
  };

  const checkValidField = (): boolean => {
    setFirstNameError(StringValidators.validateName(firstName));
    setLastNameError(StringValidators.validateName(lastName));

    if (
      StringValidators.validateName(firstName) ||
      StringValidators.validateName(lastName)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onEditFullName = () => {
    setEditMode(EditModeType.NAME);
  };

  const onSaveFullname = () => {
    if (checkValidField()) onUpdateUserAccount({ firstName, lastName });
  };

  const onCancel = () => {
    setFirstName(userProfile.firstName);
    setLastName(userProfile.lastName);
    setFirstNameError(false);
    setLastNameError(false);
    setEditMode(undefined);
  };

  return (
    <div className='space-between-rows '>
      <form style={{ display: "contents" }} autoComplete='on'>
        {!isOnEditMode && (
          <FormInputWithButton
            inputLabel={t("UserManagement:nameLabel")}
            onChangeText={firstNameHandler}
            error={firstNameError}
            value={getFullName(firstName, lastName)}
            isBorderBottomOnly={true}
            onEdit={onEditFullName}
            disabled={!!onEditMode}
          />
        )}

        {isOnEditMode && (
          <div>
            <div className='user-profile-main__wrapper'>
              <div className='user-profile-main__threecol'>
                <div className='user-profile-main__titlewrapper'>
                  <div className='user-profile-main__label'>
                    <p className='input-label'>
                      {t("UserManagement:firstNameLabel")}
                    </p>
                  </div>

                  <div className='user-profile-main__cancel'>
                    <div>
                      <p className='input-label__namecancel input-label__right'>
                        <a onClick={onCancel}>
                          {t("UserManagement:cancelLabel")}{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <FormInputWithButton
                  inputLabel={t("UserManagement:firstNameLabel")}
                  onChangeText={firstNameHandler}
                  error={firstNameError}
                  value={firstName}
                  isBorderBottomOnly={false}
                  onEdit={onEditFullName}
                  isShowLabel={false}
                  errorMsg={t("LoginRegistration:nameError")}
                />
              </div>

              <div className='user-profile-main__threecol'>
                <p className='input-label'>
                  {t("UserManagement:lastNameLabel")}
                </p>
                <FormInputWithButton
                  inputLabel={t("UserManagement:lastNameLabel")}
                  onChangeText={lastNameHandler}
                  error={lastNameError}
                  value={lastName}
                  isBorderBottomOnly={false}
                  onEdit={onEditFullName}
                  isShowLabel={false}
                  errorMsg={t("LoginRegistration:nameError")}
                />
              </div>

              <div className={"user-profile-main__onecol"}>
                <p className='input-label__cancelnamerow input-label__right'>
                  <a onClick={onCancel}> {t("UserManagement:cancelLabel")} </a>
                </p>

                <PrimaryBlackButton
                  disable={
                    (firstName === "" && lastName === "") ||
                    (firstName === userProfile?.firstName &&
                      lastName === userProfile?.lastName)
                  }
                  border={false}
                  onClick={onSaveFullname}
                  buttonName={t("UserManagement:saveLabel")}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
