import config from "../../../config";
import {
  BrazeSubscriptionGroup,
  NotificationsCenterSuccess,
} from "../../../redux_store/notificationsCenter/models";
import { httpClient } from "../../httpClient";

export async function getNotificationsCenter(): Promise<
  BrazeSubscriptionGroup[]
> {
  try {
    // console.debug("API: GET notification-center");

    const response = await httpClient({
      url: "notification-center",
      method: "GET",
      headers: {
        Authorization: `${config.accessToken}`,
      },
    });
    return response.data as BrazeSubscriptionGroup[];
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "GET notification-center");
    throw e;
  }
}

export async function postNotificationsCenter(
  data: BrazeSubscriptionGroup[]
): Promise<NotificationsCenterSuccess> {
  try {
    // console.debug("API: POST notification-center");

    const userToken = config.accessToken;

    const response = await httpClient({
      url: "notification-center",
      method: "POST",
      data: data.map((group) => group),
      headers: {
        Authorization: `${userToken}`,
      },
    });
    return response.data as NotificationsCenterSuccess;
  } catch (e) {
    // SentryLoggerInstance.sentryEndpointError(e, "POST notification-center");
    throw e;
  }
}
