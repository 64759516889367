import { Map as MapView, Marker as MapMarker } from "mapkit-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import config from "config";
import { StoreModules, StoreReduxModels } from "gyg_common";
import BasicStoreDetails from "gyg_common/components/Stores/BasicStoreDetails";

import colours from "@/styles/colours";

const TRACKING_USER_LOCATION_INTERVAL = 2000;
const { getDefaultCoordinates, getInitialCoordinates } =
  StoreModules.StoreUtils;

export interface StoresMapProps {
  stores: StoreReduxModels.Store[];
  showToast: (message: string | null) => void;
  handleOrder: (store: StoreReduxModels.Store) => void;
  withDelivery?: boolean;
  isUserLocationKnown: boolean;
  latLong?: StoreModules.StoreUtils.Coordinate;
}

const styles = StyleSheet.create({
  mapContainer: {
    flex: 1,
  },
  map: {
    height: 1, //fixes [MapKit] The `scaleFactor` parameter passed to `MapRect.scale()` is not a number
  },
});

const StoresMap: React.FC<StoresMapProps> = ({
  stores,
  showToast,
  handleOrder,
  withDelivery,
  isUserLocationKnown,
  latLong,
}) => {
  const [mapHeight, setMapHeight] = useState<number>(0);
  const defaultCoordinates = getDefaultCoordinates(config.version);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [previewStore, setPreviewStore] =
    useState<StoreReduxModels.Store | null>();
  const [coordinates, setCoordinates] =
    useState<StoreModules.StoreUtils.MapCoordinates>({
      lat: defaultCoordinates.center[0],
      long: defaultCoordinates.center[1],
      latDelta: defaultCoordinates.latDelta,
      longDelta: defaultCoordinates.longDelta,
      zoom: defaultCoordinates.zoom,
    });

  const [mapContainerViewKey, setMapContainerViewKey] = useState<string>(
    `${defaultCoordinates.center[0]}`
  );
  const [trackUserLocation, setTrackUserLocation] = useState<boolean>(false);
  const initialRender = useRef<boolean>(false);

  const handleShowToast = useCallback(showToast, [showToast]);

  const onShowMarkerDetails = (markerId: string) => {
    const store = stores.find(
      (currentStore) => currentStore.id === parseInt(markerId)
    );
    setPreviewStore(store);
    setShowModal(true);
  };

  const onMapClick = () => {
    setShowModal(false);
    // hides toast when map pressed
    handleShowToast(null);
  };

  useEffect(() => {
    if (latLong && isUserLocationKnown) {
      const newCoords = getInitialCoordinates(
        config.version,
        isUserLocationKnown,
        latLong
      );
      setCoordinates(newCoords);
      setMapContainerViewKey(`${newCoords.lat}`);
    } else if (!initialRender.current) {
      initialRender.current = true;
      setTrackUserLocation(true);
      setTimeout(() => {
        setTrackUserLocation(false);
      }, TRACKING_USER_LOCATION_INTERVAL);
    }
  }, [latLong, isUserLocationKnown]);

  if (!config.mapsJsToken) {
    return (
      <View
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Text>No map token</Text>
      </View>
    );
  }

  return (
    <div
      className='restaurants__map'
      data-testid={StoreModules.StoreModels.StoresTestingId.storesMapContainer}>
      <View
        style={styles.mapContainer}
        onLayout={(event) => {
          setMapHeight(event.nativeEvent.layout.height); //fixes [MapKit] The `scaleFactor` parameter passed to `MapRect.scale()` is not a number
        }}>
        <View
          style={[styles.map, mapHeight > 0 && { height: mapHeight }]}
          key={mapContainerViewKey}>
          <MapView
            token={config.mapsJsToken}
            initialRegion={{
              centerLatitude: coordinates.lat,
              centerLongitude: coordinates.long,
              latitudeDelta: coordinates.latDelta,
              longitudeDelta: coordinates.longDelta,
            }}
            onClick={onMapClick}
            showsUserLocation={true}
            showsPointsOfInterest={false}
            showsMapTypeControl={true}
            tracksUserLocation={trackUserLocation}
            showsUserLocationControl={true}
            showsZoomControl={true}>
            {stores.map((store: StoreReduxModels.Store) => {
              return (
                <MapMarker
                  collisionMode={"Rectangle"}
                  data-testid={`${StoreModules.StoreModels.StoresTestingId.mapPin}_${store.id}`}
                  key={store.id}
                  glyphImage={{
                    1: require("gyg_common/components/assets/images/logo_black_big.png"),
                  }}
                  selectedGlyphImage={{
                    1: require("gyg_common/components/assets/images/logo_black_big.png"),
                  }}
                  glyphColor={colours.black}
                  color={colours.yellow}
                  latitude={parseFloat(store.latitude)}
                  longitude={parseFloat(store.longitude)}
                  selected={false}
                  onSelect={() => onShowMarkerDetails(`${store.id}`)}
                />
              );
            })}
          </MapView>
        </View>
      </View>
      <div
        data-testid={StoreModules.StoreModels.StoresTestingId.detailsPopup}
        className={`restaurants__map-popup ${
          previewStore && showModal ? "show" : "hide"
        }`}>
        <div className='card-sticker'>
          {previewStore && showModal && (
            <BasicStoreDetails
              store={previewStore}
              buttonVersion={"primary"}
              showAddress
              withDelivery={withDelivery}
              handleOrder={() => handleOrder(previewStore)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StoresMap;
