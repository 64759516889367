import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, Switch, Text, View } from "react-native";

import { Reward } from "modules/Loyalty/models";

import { PressableOpacity } from "../../../components/BaseComponents/PressableOpacity/PressableOpacity";
import CardSticker from "../../../components/CardSticker";
import GomexHeader from "../../../components/Gomex/GomexHeader";
import config from "../../../config";
import { locales } from "../../../locales/locales.enum";
import { generateTestID } from "../../../modules/platformUtils";
import * as LoyaltyModels from "../../../redux_store/loyalty/models";
import PayWithGomex from "../../Order/PayWithGomex";
import colours from "../../styles/colours";
import { ids, styles } from "./styles";

export interface ICartRewardsProps {
  rewards: Reward[];
  activeReward: LoyaltyModels.IRewards | null;
  onRewardPressed: (newActiveReward: Reward) => void;
  isLoading: boolean;
  title: string;
  dollars?: number;
  total: number;
  subtotal: number;
  totalBeforeDiscount: number;
  redeemDollars?: number | null;
  redeemDollarsBalance?: number | null;
  onRedeemDollars: (value: number | null) => void;
  errorMessage?: string;
}

export const CartRewards: React.FC<ICartRewardsProps> = ({
  rewards,
  activeReward,
  onRewardPressed,
  isLoading,
  title,
  dollars,
  total,
  subtotal,
  totalBeforeDiscount,
  onRedeemDollars,
  redeemDollars,
  redeemDollarsBalance,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const maxApplicableGygDollars =
    config.version === locales.US ? subtotal : total;

  const renderRewardsList = () =>
    rewards.map((reward, index) => {
      const disabled =
        activeReward !== null &&
        activeReward?.userCouponCode !== reward.userCouponCode;

      const onReward = () => {
        if (!disabled && !isLoading)
          onRewardPressed(reward as LoyaltyModels.IRewards);
      };
      return (
        <>
          <PressableOpacity
            style={styles.rewardItemWrapper}
            onPress={onReward}
            key={`${reward.userCouponCode}-${reward.id}-${index}`}>
            <Text style={styles.rewardsText}>{reward.name}</Text>
            <Switch
              {...generateTestID(Platform.OS, `Switch-${reward.id}-${index}`)}
              trackColor={{
                false: colours.switchGrey,
                true: colours.lightGreen,
              }}
              value={activeReward?.userCouponCode === reward.userCouponCode}
              disabled={disabled || isLoading}
              onValueChange={onReward}
            />
          </PressableOpacity>
          {reward.errorMessage && (
            <Text
              {...generateTestID(Platform.OS, "RewardError")}
              style={styles.errorMsg}>
              {reward.errorMessage}
            </Text>
          )}
        </>
      );
    });

  return (
    <View
      {...generateTestID(Platform.OS, "CartRewards")}
      style={styles.outer}
      dataSet={{ media: ids.outer }}>
      <CardSticker>
        <View style={[styles.header, styles.titleWrapper]}>
          <GomexHeader title={title} />
        </View>
        {!!dollars ? (
          <View style={styles.titleWrapper}>
            <Text style={styles.rewardsText}>
              {t("Loyalty:payWithMyGygDollars")}
            </Text>

            <PayWithGomex
              dollars={dollars}
              isLoading={isLoading}
              onRedeemDollars={onRedeemDollars}
              total={maxApplicableGygDollars}
              totalBeforeDiscount={totalBeforeDiscount}
              redeemDollars={redeemDollars}
              redeemDollarsBalance={redeemDollarsBalance}
              errorMessage={errorMessage}
            />
          </View>
        ) : (
          <View style={styles.topSpace} />
        )}
        <View style={styles.rewardsWrapper}>{renderRewardsList()}</View>
      </CardSticker>
    </View>
  );
};
