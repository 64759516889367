import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Linking, StyleSheet, View } from "react-native";
import QRCode from "react-qr-code";

import config from "config";
import { AnalyticsConst } from "gyg_common";
import { LinkButton } from "gyg_common/components/Buttons/LinkButton";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { Spacing } from "gyg_common/components/styles/number";

export const styles = StyleSheet.create({
  container: {
    paddingTop: Spacing.Light,
  },
});

export interface JoinGYGProps {
  onGoRegister: () => void;
  onSocialLogin: (loginType: AnalyticsConst.LoginType) => void;
}

const JoinGYG: React.FC<JoinGYGProps> = () => {
  const { t } = useTranslation();
  const { isDesktopScreen } = useMediaQuery();

  const openAboutGomex = () => {
    const url = config.brandSiteUrl;
    const win = window.open(url, "_blank");
    if (win) {
      win.focus();
    }
  };

  const downloadNowDidTap = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(config.appDeepLink);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(config.appDeepLink);
    }
  }, []);

  return (
    <div>
      <View style={styles.container}>
        <div>
          <p className='join-gyg__desc'>
            {t("LoginRegistration:downloadAppDescription")}
          </p>
        </div>
        <View style={styles.container}>
          {!isDesktopScreen ? (
            <PrimaryBlackButton
              testId={t("LoginRegistration:downloadApp")}
              buttonName={t("LoginRegistration:downloadApp")}
              onClick={downloadNowDidTap}
            />
          ) : (
            config.appDeepLink && (
              <View style={{ alignItems: "center" }}>
                <QRCode size={200} value={config.appDeepLink} />
              </View>
            )
          )}
        </View>
      </View>

      <div className='grey-line divider' />

      <p className='join-gyg__title'>
        {t("LoginRegistration:learnGomexMoreLabel")}
      </p>
      <p className='join-gyg__desc'>
        {t("LoginRegistration:learnGomexMoreDesc")}
        <LinkButton
          onPress={openAboutGomex}
          testId='loyaltyProgramLink'
          buttonName={t("LoginRegistration:loyaltyProgramLink")}
          boldText
        />
      </p>

      <div className='grey-line divider' />
    </div>
  );
};

export default JoinGYG;
