import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState, StoreUtils } from "gyg_common";
import NutritionalInfoDisclaimer from "views/components/Menu/NutritionalInfoDisclaimer";
import Surcharges from "views/components/Menu/Surcharges";

export interface StickyBannerContainerProps {
  showSurcharges?: boolean;
  showNutritionInfo?: boolean;
  position: number;
}

const StickyBannerContainer: React.FC<StickyBannerContainerProps> = ({
  showSurcharges = true,
  showNutritionInfo,
  position,
}) => {
  const { t } = useTranslation();

  const { orderTime } = useSelector((s: RootState) => s.order);
  const { selectedStore } = useSelector((s: RootState) => s.store);

  const [surcharges, setSurcharges] = useState<string[]>([]);

  useEffect(() => {
    if (selectedStore) {
      setSurcharges(
        StoreUtils.getSurchargesTextArray(orderTime, selectedStore.surcharges)
      );
    }
  }, [selectedStore, orderTime]);

  return (
    <div
      id='sticky-banner'
      className='surcharges'
      style={{ top: `${position}px` }}>
      {surcharges?.length && showSurcharges ? (
        <Surcharges surcharges={surcharges} />
      ) : null}

      {showNutritionInfo && (
        <NutritionalInfoDisclaimer
          displayPosition='relative'
          text={t("OrderManagement:nutrionalInfo")}
        />
      )}
    </div>
  );
};

export default StickyBannerContainer;
