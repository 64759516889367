import "./style.scss";

import { useFeatureIsOn } from "@growthbook/growthbook-react";
import locationIcon from "assets/icons/icon_location_small.png";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { NavigationReduxAction } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { Screens } from "navigation/const";

export const NoStoresNearby = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  return (
    <div className='location'>
      <div className='location__title location__title--no-restaurants'>
        <img src={locationIcon} className='location__icon' />
        <p
          data-testid='NoRestaurantsNearbyTitle'
          className='location__title-text'>
          {t("OrderManagement:noRestaurantsNearbyTitle")}
        </p>
      </div>

      <div className='location__content'>
        <p data-testid='NoRestaurantsNearbyBody' className='location__body'>
          {t("OrderManagement:noRestaurantsNearbyBody")}
        </p>

        <div
          data-testid='ChooseRestaurantButton'
          className='location__button location__button--no-store'>
          <PrimaryBlackButton
            testId='ChooseRestaurantPrimayButton'
            onClick={() => {
              if (hasDelivery) {
                dispatch(NavigationReduxAction.setOpenOrderSetup(true));
              } else {
                navigate(Screens.Restaurants, {
                  state: {
                    previousScreen: pathname,
                  },
                });
              }
            }}
            buttonName={t("OrderManagement:chooseRestaurant")}
          />
        </div>
      </div>
    </div>
  );
};
